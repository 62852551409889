import React from 'react'

import CustomerTransport from 'app/Apps/OrderManagement/Orders/CustomerTransport'
import DTOSpec from 'app/services/dtoSpec'

import {
    useTheme,
} from '@mui/material/styles'
import getInvoiceConfig from './invoice.config'

const Invoices = () => {
    const theme = useTheme()

    const invoiceConfig = getInvoiceConfig({
        theme,
    })

    return (
        <CustomerTransport
            config={invoiceConfig}
            defaultTab="invoices"
            name={DTOSpec.OrderInvoices.name}
        />
    )
}

export default Invoices
