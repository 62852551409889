import {
    useContext, createContext,
} from 'react'
import {
    AccountQuote,
} from '../AccountQuotes.types'

export type AccountQuoteContextType = AccountQuote & {
    quoteId: number,
}

export const AccountQuoteContext = createContext<AccountQuoteContextType>(null)

const useAccountQuoteContext = () => {
    return useContext<AccountQuoteContextType>(AccountQuoteContext)
}

export default useAccountQuoteContext
