const injectParseResponse = (parse) => {
    return (cb) => {
        return (params) => {
            return cb(params).then((response) => {
                if (parse) {
                    return parse(response)
                }
                return response
            })
        }
    }
}

export default injectParseResponse
