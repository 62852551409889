import React, {
    useCallback,
    useMemo,
} from 'react'

import useShareContext from 'app/shared-components/SharedContext/useSharedContext'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    Order,
    SelectedContainer,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useLabel from 'app/hooks/useLabel'
import {
    RichTableData,
} from 'app/types/richtable.types'

import getColumns from './selectedContainers.columns'
import useStyles from './SelectedContainers.style'

type Props = {
    value?: number[],
    onChange: (val: number[]) => void,
}

const defaultProps: Partial<Props> = {
    value: undefined,
}

const SelectedContainers = ({
    value,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()

    const {
        order,
    } = useShareContext<{ order: Order }>()

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const load = useCallback((): Promise<RichTableData<SelectedContainer[]>> => {
        return Promise.resolve({
            data: order.selectedContainers.filter(({
                isRejected,
            }) => {
                return !isRejected
            }),
        })
    }, [order.selectedContainers])

    return (
        <RichTable
            configName="SelectedContainers"
            name="SelectedContainers"
            columns={columns}
            load={load}
            selectedRows={value}
            onSelectRow={onChange}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            uniqField="containerId"
            showSelectAll
        />
    )
}

SelectedContainers.defaultProps = defaultProps

export default SelectedContainers
