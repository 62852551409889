/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'
import useLabel from 'app/hooks/useLabel'
import getComponentByName from 'app/utils/components/getComponentByName'

import {
    FormField,
} from 'app/types/form.types'
import withFieldsCalc from '../withFieldsCalc'
import FormBuilder from '../../FormBuilder'

type Props = {
    disabled?: boolean,
    name: string,
    fields: FormField<any>[]
}

const defaultProps = {
    disabled: false,
}

const Form = (props: Props) => {
    const {
        disabled,
        name,
        fields,
    } = props
    const l = useLabel()

    const fieldsWidthTypes = useMemo(() => {
        return fields
            .map(({
                name: fieldName,
                disabled: fieldDisabled,
                required: fieldRequired,
                componentName,
                title,
                label,
                labelKey,
                noLabel,
                ...rest
            }) => {
                const fieldDisabledLocal = fieldDisabled === undefined
                    ? disabled : fieldDisabled

                const labelKeyLocal = labelKey || `${name}.${fieldName}`

                return {
                    ...rest,
                    disabled: fieldDisabledLocal,
                    isRequired: fieldRequired,
                    name: fieldName,
                    customComponent: getComponentByName(componentName),
                    title: noLabel ? undefined : title || label
                            || (labelKeyLocal && l(labelKeyLocal)),
                }
            })
    }, [
        disabled,
        fields,
        name,
        l,
    ])

    return (
        <FormBuilder
            {...props}
            fields={fieldsWidthTypes}
        />
    )
}

Form.defaultProps = defaultProps

export default withFieldsCalc(Form)
