import React, {
    useCallback,
    useState,
} from 'react'
import clsx from 'clsx'
import noop from 'lodash/noop'
import DeleteIcon from '@mui/icons-material/Delete'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import Tooltip from 'app/shared-components/Tooltip'
import useHasPermission from 'app/hooks/useHasPermission'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    InvoiceDesignation,
} from 'app/types/enums'

import useStyles from './InvoiceCardContainer.styles'

type Props = {
    children: JSX.Element | JSX.Element[],
    isCanceled?: boolean,
    selected?: boolean,
    title: string,
    handleClick: () => void,
    removeInvoice?: () => void,
    createCreditNote?: (e: React.SyntheticEvent<EventTarget>) => void,
    invoiceId?: number,
    canBeDeleted?: boolean,
    invoiceDesignation?: InvoiceDesignation
}

const defaultProps = {
    selected: false,
    isCanceled: false,
    canBeDeleted: false,
    removeInvoice: noop,
    createCreditNote: noop,
    invoiceId: null,
    invoiceDesignation: undefined,
}

const InvoiceCardContainer = ({
    children,
    selected,
    handleClick,
    title,
    canBeDeleted,
    removeInvoice,
    createCreditNote,
    isCanceled,
    invoiceId,
    invoiceDesignation,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const outgoingInvoiceCreate = useHasPermission(['outgoing_invoice_create'])
    const dataTestId: string = `invoice-card-container-${(invoiceId !== null) ? invoiceId : 'new'}`
    const [
        showConfirmation,
        setShowConfirmation,
    ] = useState(false)
    const allowedValues = useEnumValues()(InvoiceDesignation)

    const onConfirm = useCallback((positive) => {
        if (positive) {
            removeInvoice()
        }
        setShowConfirmation(false)
    }, [removeInvoice])

    return (
        <div className={classes.root}>
            <div
                className={clsx(classes.invoiceCardContainer, classes.clickableCard)}
                data-testid={dataTestId}
            >
                <div
                    data-testid="select-container"
                    className={clsx(classes.selectContainer,
                        selected && classes.selected,
                        selected && 'selected')}
                >
                    <div
                        className={classes.invoiceCard}
                        onClick={handleClick}
                        onKeyPress={handleClick}
                    >
                        <div
                            className={clsx(
                                classes.titleContainer,
                                isCanceled && classes.headerGray,
                                !isCanceled && classes.headerBlue,
                            )}
                            data-testid="title-container-invoice"
                        >
                            {Boolean(invoiceId) && outgoingInvoiceCreate && (
                                <Tooltip
                                    title="Credit Invoice"
                                >
                                    <div
                                        onMouseDown={createCreditNote}
                                        className={classes.creditNoteIconContainer}
                                        data-testid="invoice-create-credit-note"
                                    >
                                        <img
                                            className={classes.creditNoteIcon}
                                            src="assets/images/money_off.svg"
                                            alt="credit note"
                                        />
                                    </div>
                                </Tooltip>

                            )}
                            {title}
                            {canBeDeleted
                                && (
                                    <div
                                        className={classes.iconContainer}
                                    >
                                        <DeleteIcon
                                            data-testid="invoice-delete-icon"
                                            onClick={() => {
                                                return setShowConfirmation(true)
                                            }}
                                        />
                                        <ConfirmationDialog
                                            dialogTitle="Delete Invoice"
                                            open={showConfirmation}
                                            dialogContent={`Are you sure you want to delete this invoice?
                                            ${allowedValues[invoiceDesignation]}`}
                                            handleClose={onConfirm}
                                            classNames={{
                                                content: classes.title,
                                            }}
                                        />
                                    </div>
                                )}
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

InvoiceCardContainer.defaultProps = defaultProps

export default InvoiceCardContainer
