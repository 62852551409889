import React, {
    useCallback,
} from 'react'
import {
    Chip,
} from '@mui/material'

import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import Label from 'app/shared-components/Label'
import {
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useStyles from './RequiredContainers.style'

type ChipValue = {
    value: number,
    label: string,
}

type Props = {
    value: (RequiredContainersInternal | RequiredContainersCustomer)[],
    chipValues?: ChipValue[],
    children: JSX.Element,
    disabled?: boolean,
    onChange: (changeValue: (RequiredContainersInternal | RequiredContainersCustomer)[]) => void,
}

const defaultProps = {
    chipValues: [],
    disabled: false,
}

const RequiredContainers = (props: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        value,
        chipValues,
        children,
        onChange,
        disabled,
    } = props

    const onDeleteRequiredContainer = useCallback((val: number) => {
        const newValue = value.filter((item, i) => { return i !== val })

        onChange(newValue)
    }, [
        onChange,
        value,
    ])

    return (
        <>
            <Label title="Required Containers" />
            <div className={classes.root}>
                <div className={classes.chipsWrapper}>
                    {!value.length && (
                        <div className={classes.text}>
                            Please add the required containers.
                        </div>
                    )}
                    {chipValues.map(({
                        label,
                        value: chipValue,
                    }) => {
                        return (
                            <Chip
                                className={classes.chip}
                                label={label}
                                onDelete={
                                    !disabled && (() => { onDeleteRequiredContainer(chipValue) })
                                }
                                key={`required-container-${chipValue}`}
                            />
                        )
                    })}
                </div>
                <div className={classes.selectorContainer}>
                    {!disabled && children}
                </div>
            </div>
        </>
    )
}

RequiredContainers.defaultProps = defaultProps

export default RequiredContainers
