import {
    useCallback,
} from 'react'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

export default (uniqField = 'id') => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    return useCallback((obj) => {
        addTableElement(obj)
        selectDomainObject(obj?.[uniqField])
    }, [
        addTableElement,
        selectDomainObject,
        uniqField,
    ])
}
