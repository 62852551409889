import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            minHeight: '100%',
        },
        collapse: {
            backgroundColor: theme.palette.secondary[50],
        },
        header: {
            paddingLeft: theme.spacing(2),
        },
        tableContent: {
            boxShadow: 'none !important',
            overflow: 'auto',
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            top: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
        },
        deleteHistoryCard: {
            marginTop: theme.spacing(2),
        },
    }
})

export default useStyles
