import React, {
    useMemo,
    useCallback,
} from 'react'
import {
    Icon,
} from '@mui/material'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import omit from 'lodash/omit'

import InputBase from 'app/shared-components/InputBase'
import {
    GeneralFreight as GeneralFreightType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useStyles from './Inputs.styles'

type Props = {
    value?: GeneralFreightType,
    onChange: (newVal: GeneralFreightType) => void,
    onDelete: (generalFreightId: number | string) => void,
    columns: {
        label: string,
        fieldName: string,
        isRequired: boolean,
        id: number,
    }[],
    disabled?: boolean,
}

const defaultProps = {
    value: undefined,
    disabled: false,
}

const Inputs = (props: Props) => {
    const {
        value,
        onChange,
        onDelete,
        columns,
        disabled,
    } = props

    const {
        classes,
    } = useStyles()

    const Input = useMemo((): {
        name: string,
        val: any,
        type: string,
    }[] => {
        if (!value) {
            return []
        }
        return Object.entries(omit(value, 'id')).map(([
            name,
            val,
        ]) => {
            return {
                name,
                val,
                type: [
                    'quantity',
                    'weightKg',
                ].includes(name) ? 'number' : 'text',
            }
        })
    }, [value])

    const handleChange = useCallback((change, {
        target: {
            name,
        },
    }): void => {
        const update = {
            ...value,
            [name]: change,
        }

        onChange(update)
    }, [
        value,
        onChange,
    ])

    const handleOnDelete = useCallback((): void => {
        onDelete(value.id)
    }, [
        value,
        onDelete,
    ])

    return (
        <TableRow>
            {value && columns.map(({
                fieldName,
                isRequired,
            }) => {
                const currentInput = Input.find(({
                    name,
                }) => { return name === fieldName })

                return (
                    <TableCell
                        classes={{
                            root: classes.tableCell,
                        }}
                        key={currentInput?.name}
                    >
                        <InputBase
                            value={currentInput?.val}
                            name={currentInput?.name}
                            onChange={handleChange}
                            disabled={disabled}
                            type={currentInput?.type}
                            autoComplete="off"
                            data-testid={`inputBase-${currentInput?.name}`}
                            inputProps={{
                                className: isRequired ? classes.isRequired : classes.input,
                            }}
                        />
                    </TableCell>
                )
            })}
            <TableCell
                classes={{
                    root: classes.tableCell,
                }}
            >
                {!disabled
                    && (
                        <Icon
                            className={classes.deleteIcon}
                            onClick={handleOnDelete}
                        >
                            delete
                        </Icon>
                    )}
            </TableCell>
        </TableRow>
    )
}

Inputs.defaultProps = defaultProps

export default Inputs
