export const SET_VALUE = 'SET_VALUES'
export const SET_TIME_ZONES = 'SET_TIME_ZONES'
export const SET_TIME_ZONE = 'SET_TIME_ZONE'
export const SET_TIME_ZONE_STATE = 'SET_TIME_ZONE_STATE'
export const SET_TIME_ZONE_CITY_STATE = 'SET_TIME_ZONE_CITY_STATE'

export function setValue(value) {
    return {
        type: SET_VALUE,
        value,
    }
}

export function setTimeZones(timeZones) {
    return {
        type: SET_TIME_ZONES,
        timeZones,
    }
}

export function setTimeZone(timeZone) {
    return {
        type: SET_TIME_ZONE,
        timeZone,
    }
}

export function setTimeZoneAndState(timeZone, stateId) {
    return {
        type: SET_TIME_ZONE_STATE,
        timeZone,
        stateId,
    }
}

export function setTimeZoneCityAndState(timeZone, city, stateId) {
    return {
        type: SET_TIME_ZONE_CITY_STATE,
        timeZone,
        stateId,
        city,
    }
}
