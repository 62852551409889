import React from 'react'
import moment from 'moment'

import {
    strToDate,
} from 'app/utils/date'
import {
    MainMeansOfTransport,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useStyles from './RequestedDeliveryAddInfo.style'

const executionTime = {
    SEA: 72,
    AIR: 48,
}

type Props = {
    value: string,
    rowProps: {
        mainMeansOfTransport: MainMeansOfTransport,
        actualDeliveryFirstStep: string,
    }
}

const RequestedDeliveryAddInfo = ({
    value,
    rowProps,
}: Props) => {
    const {
        classes,
    } = useStyles()

    if (!rowProps?.actualDeliveryFirstStep) {
        return value ? (<div className={classes.grey}>{value}</div>) : null
    }
    const diff = moment().diff(moment(strToDate(rowProps?.actualDeliveryFirstStep), 'X'), 'hours')

    const color = diff > executionTime[rowProps?.mainMeansOfTransport]
        ? classes.red
        : classes.grey

    return (
        <div className={color}>{value}</div>
    )
}

export default RequestedDeliveryAddInfo
