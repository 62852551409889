// eslint-disable-next-line import/prefer-default-export
import Fees from './FeesContainer'
import {
    Fee,
} from '../../Apps/Pricing/Pricing.types'

export enum FeesType {
    Lane = 'fees',
    Additional = 'laneIndependentPricingFees',
    OtherFees = 'otherFees',
}

export enum AdditionalFeesAllowed {
    BASIC_READOUT = 'BASIC_READOUT',
    CANCELLATION = 'CANCELLATION',
    DAMAGE_DEDUCTIBLE = 'DAMAGE_DEDUCTIBLE',
    EXPRESS_ORDER = 'EXPRESS_ORDER',
    EXTRA_DAY = 'EXTRA_DAY',
    INSURANCE = 'INSURANCE',
    LATE_DELIVERY = 'LATE_DELIVERY',
    MILESTONE_READOUT = 'MILESTONE_READOUT',
    POSTPONEMENT = 'POSTPONEMENT',
    WEEKEND_DELIVERY = 'WEEKEND_DELIVERY',
    MANUAL_CONTAINER_FEE_A = 'MANUAL_CONTAINER_FEE_A',
    MANUAL_CONTAINER_FEE_B = 'MANUAL_CONTAINER_FEE_B',
    MANUAL_SHIPMENT_FEE_A = 'MANUAL_SHIPMENT_FEE_A',
    MANUAL_SHIPMENT_FEE_B = 'MANUAL_SHIPMENT_FEE_B',
}

export enum LaneFeesAllowed {
    COLLECTION = 'COLLECTION',
    BASE_LEASE = 'BASE_LEASE',
    CARGO_STRAPS = 'CARGO_STRAPS',
    DAMAGE_WAIVER = 'DAMAGE_WAIVER',
    DELIVERY_FTL_TRUCK = 'DELIVERY_FTL_TRUCK',
    DELIVERY_FTL_VAN = 'DELIVERY_FTL_VAN',
    DELIVERY_LTL = 'DELIVERY_LTL',
    DRY_ICE = 'DRY_ICE',
    HANDOVER = 'HANDOVER',
    RETURN_DROPOFF = 'RETURN_DROPOFF',
}

export enum OtherFeesAllowed {
    CARGO_STRAPS = 'CARGO_STRAPS',
    DAMAGE_WAIVER = 'DAMAGE_WAIVER',
    DRY_ICE = 'DRY_ICE',
}

const Component = {
    Fees,
}

export type FeesConfig = {
    componentName:keyof typeof Component,
    fxRate?: { id?: number },
    currency?: string,
    tempRange?: string,
    showSkyNetSpreadSheet?: boolean,
    feeValueLabel?: string,
}

export type FeeCells = Fee & {
    calcEUR: number,
    feeValue: number,
}
