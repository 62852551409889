import React, {
    useState, useMemo, useCallback,
} from 'react'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import clsx from 'clsx'
import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LaneGroupHandoverData,
} from '../../Pricing.types'
import getFields from './LaneGroupHandoverDataUpdate.fields'
import useUpdateHandoverData from '../hooks/useUpdateLaneGroupHandoverData'
import useStyles from './LaneGroupHandoverDataUpdate.styles'

const defaultProps = {
    data: null,
}

const LaneGroupHandoverDataUpdate = ({
    data,
    close,
    onSuccess,
}: {
    data?: LaneGroupHandoverData,
    close: () => void,
    onSuccess: () => void,
}) => {
    const getAllowedValues = useEnumValues()
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState<LaneGroupHandoverData>(null)

    const updatedData = useMemo(() => {
        return {
            ...data,
            ...value,
        }
    }, [
        data,
        value,
    ])

    const updateHandoverData = useUpdateHandoverData({
        onSuccess: () => {
            onSuccess()
            close()
        },
    })

    const proceed = useCallback((positive) => {
        if (positive) {
            updateHandoverData(updatedData)
            return
        }
        close()
    }, [
        updatedData,
        close,
        updateHandoverData,
    ])

    const fields = useFieldsWithClassName((getFields({
        getAllowedValues,
        showFTL: [
            TransportModeRoad.FTL,
            TransportModeRoad.FTL_OR_LTL,
        ].includes(updatedData?.transportModeRoad),
        showLTL: [
            TransportModeRoad.LTL,
            TransportModeRoad.FTL_OR_LTL,
        ].includes(updatedData?.transportModeRoad),
    })), classes)

    return (
        <ConfirmationDialog
            open={Boolean(data)}
            handleClose={proceed}
            positiveLabel="Update"
            negativeLabel="Cancel"
            dialogTitle="Update Lane"
        >
            <DomainObjectForm
                name="LaneGroupHandoverDataUpdate"
                value={updatedData}
                fields={fields}
                exists
                onChange={setValue}
                className={clsx(classes.gridWrapper)}
            />

        </ConfirmationDialog>
    )
}

LaneGroupHandoverDataUpdate.defaultProps = defaultProps

export default LaneGroupHandoverDataUpdate
