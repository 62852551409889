import React, {
    useCallback, useEffect,
    useState,
} from 'react'

import {
    InvoiceDesignation,
} from 'app/types/enums'
import {
    OutgoingInvoice,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import NewInvoiceCard from './NewInvoiceCard'
import useCreateInvoice from '../../hooks/useCreateInvoice'

type Props = {
    orderNumber?: string,
    selected?: boolean,
    setSelectedInvoice: (invoice: OutgoingInvoice) => void,
    onSuccess: (params: OutgoingInvoice) => void,
    selectFirstInvoice: () => void,
}

const defaultProps = {
    selected: false,
    orderNumber: null,
}

const NewInvoiceCardContainer = ({
    setSelectedInvoice,
    selected,
    orderNumber,
    onSuccess,
    selectFirstInvoice,
}: Props) => {
    const [
        invoiceDesignation,
        setInvoiceDesignation,
    ] = useState<InvoiceDesignation>(null)

    const handleClick = useCallback((): void => {
        if (!selected) {
            setInvoiceDesignation(null)
            setSelectedInvoice(null)
        }
    }, [
        selected,
        setSelectedInvoice,
    ])

    const onError = useCallback((): void => {
        setInvoiceDesignation(null)
        selectFirstInvoice()
    }, [selectFirstInvoice])

    const {
        mutate: createInvoice,
    } = useCreateInvoice({
        orderNumber,
        onSuccess,
        onError,
    })

    useEffect(() => {
        if (invoiceDesignation) {
            setInvoiceDesignation(null)
            createInvoice({
                invoiceDesignation,
            })
        }
    }, [
        createInvoice,
        invoiceDesignation,
        orderNumber,
    ])

    return (
        <NewInvoiceCard
            selected={selected}
            handleClick={handleClick}
            invoiceDesignation={invoiceDesignation}
            onInvoiceDesignationChange={setInvoiceDesignation}
        />
    )
}

NewInvoiceCardContainer.defaultProps = defaultProps

export default NewInvoiceCardContainer
