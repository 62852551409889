import isNil from 'lodash/isNil'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    useCallback,
    useState,
} from 'react'

import useRichtableLoadWithSpinner from './useRichtableLoadWithSpinner'

type Params = {
    config: (args: Record<string, any>) => RequestConfig,
    refetchInterval?: number | false | ((any) => number | false),
    key: RequestKeys,
    params: Record<string, any>,
    defaultValue?: any,
    keepPreviousData?:boolean,
    showArchived?: boolean,
    enabled?: boolean,
    withPagination?: boolean,
}

const useRequestTable = <DataType>({
    refetchInterval,
    defaultValue,
    config,
    params,
    key,
    keepPreviousData,
    showArchived,
    enabled = true,
    withPagination = false,
}: Params) => {
    const [
        requestParams,
        setParams,
    ] = useState(() => {
        return defaultValue || {
            typeMapping: {},
        }
    })

    // TODO: discuss why we need typeMapping
    const [defaultFilter] = useState(defaultValue?.filter)

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        invalidate,
    } = useRequest<DataType>({
        key: RequestKeys[key],
        params: {
            ...params,
            params: {
                ...requestParams,
                archived: showArchived,
            },
        },
        requestFunc: RequestFn.table(config, {
            withPagination,
        }),
        refetchInterval,
        keepPreviousData,
        enabled,
    })

    const onParamsChange = useCallback((newParamsRequest) => {
        setParams((prevState) => {
            let newState = {
                ...prevState,
                ...newParamsRequest,
                filter: {
                    ...prevState.filter,
                    ...newParamsRequest.filter,
                },
            }

            if (prevState?.filter && isNil(newParamsRequest?.filter)) {
                newState = {
                    ...newState,
                    filter: defaultFilter || undefined,
                }
            }

            if (newParamsRequest?.filter && defaultFilter) {
                newState = {
                    ...newState,
                    filter: {
                        ...defaultFilter,
                        ...newParamsRequest.filter,
                    },
                }
            }

            return newState
        })
    }, [defaultFilter])

    const loadData = useRichtableLoadWithSpinner<DataType>({
        data,
        isDataReady,
    })

    return {
        onParamsChange,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        data,
        loadData,
        invalidate,
    }
}

export default useRequestTable
