import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            height: '100%',
            position: 'relative',
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'unset',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        tableHeaderWrapper: {
            position: 'relative',
        },
        root: {
            position: 'relative',
            marginBottom: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        cardContent: {
            padding: `0 0 ${theme.spacing(2)} 0 !important`,
        },
    }
})

export default useStyles
