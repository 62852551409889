import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, params, classes) => {
    return {
        cardBody: {
            [`&:hover .${classes.icon}`]: {
                width: 70,
                padding: theme.spacing(2),
            },
        },
        column: {
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
            flexDirection: 'column',
        },
        icon: {
            width: 100,
            padding: theme.spacing(4),
            transform: 'transition(all 0.2s linear)',
            transition: 'all 0.2s linear',
        },
    }
})

export default useStyles
