import {
    RequestConfig,
} from 'app/types/request.types'

type CustomerExecutionSelectorRequests = {
    request: ({
        id,
        params,
    }) => RequestConfig,
}

export default {
    request: ({
        id,
        params,
    }) => {
        return {
            url: `contractbasis/${id}/customerexecution/all`,
            method: 'GET',
            ...params,
        }
    },
} as CustomerExecutionSelectorRequests
