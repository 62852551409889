import {
    createContext, useContext,
} from 'react'

export type SkyNetSpreadSheetContextType = {
    focusChanging: boolean,
    rowHeight?: number
    setFocusChanging: (focusChanging: boolean) => void,
    forceEditQuit: () => void,
    configId: string,
}

export const SkyNetSpreadSheetContext = createContext<SkyNetSpreadSheetContextType>(null)

const useSkyNetSpreadSheetContext = () => {
    return useContext<SkyNetSpreadSheetContextType>(SkyNetSpreadSheetContext)
}

export default useSkyNetSpreadSheetContext
