import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {},
        optionsWrapper: {
            zIndex: 9000,
            position: 'absolute',
            display: 'none',
            '&.active': {
                display: 'block',
            },
        },
        options: {
            position: 'absolute',
            width: 'auto',
            border: `1px solid ${theme.palette.secondary[200]}`,
            borderRadius: 4,
            boxShadow: '0 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            background: theme.palette.common.white,
            height: 200,
            overflow: 'auto',
            display: 'block',
        },
        option: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            textAlign: 'left',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.typography.body1.color,
            fontSize: theme.typography.body1.fontSize,
            padding: theme.spacing(1),
        },
        'option &:hover': {
            background: theme.palette.primary[30],
        },
        optionNone: {
            fontStyle: 'italic',
        },
    }
})

export default useStyles
