import React, {
    useCallback,
} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

import useRemovePosition from '../hooks/useRemovePosition'
import useStyles from './DeleteButton.style'

type Props = {
    onSuccess: () => void,
    invoiceId: number,
    disabled: boolean,
    canBeDeleted: boolean,
    id: number,
}

const DeleteButton = ({
    canBeDeleted,
    invoiceId,
    onSuccess,
    disabled,
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        mutate,
    } = useRemovePosition(onSuccess)

    const handleOnClick = useCallback((event: React.SyntheticEvent<EventTarget>) => {
        event.stopPropagation()
        mutate({
            invoiceId,
            positionId: id,
        })
    }, [
        id,
        invoiceId,
        mutate,
    ])

    return (
        <div className={classes.wrapper}>
            {!disabled && canBeDeleted && (
                <DeleteIcon
                    onClick={handleOnClick}
                />
            )}
        </div>
    )
}

export default DeleteButton
