import loggerTypes from 'app/Apps/utils/loggerTypes'
import {
    LoggerType,
    SensorCode,
} from 'app/types/enums'
import {
    ConfigLogger,
} from 'app/Apps/Loggers/Loggers.types'

export const AMBIENT = 'AMBIENT'
export const INTERNAL = 'INTERNAL'
export const DOOR = 'DOOR'
const TOP = 'TOP'
const BOTTOM = 'BOTTOM'
const LEFT = 'LEFT'
const RIGHT = 'RIGHT'
const BACK = 'BACK'
const UNSPECIFIED = 'UNSPECIFIED'

const mapOptions = {
    AMBIENT: 'Ambient Temperature',
    INTERNAL: 'Internal Temperature',
    TOP: 'Top Temperature',
    BOTTOM: 'Bottom Temperature',
    LEFT: 'Left Temperature',
    RIGHT: 'Right Temperature',
    BACK: 'Back Temperature',
    DOOR: 'Door Temperature',
    UNSPECIFIED: 'Unspecified Temperature',
}

export const sensorOptions = {
    AMBIENT: {
        value: AMBIENT,
        label: mapOptions.AMBIENT,
    },
    INTERNAL: {
        value: INTERNAL,
        label: mapOptions.INTERNAL,
    },
    TOP: {
        value: TOP,
        label: mapOptions.TOP,
    },
    BOTTOM: {
        value: BOTTOM,
        label: mapOptions.BOTTOM,
    },
    LEFT: {
        value: LEFT,
        label: mapOptions.LEFT,
    },
    RIGHT: {
        value: RIGHT,
        label: mapOptions.RIGHT,
    },
    BACK: {
        value: BACK,
        label: mapOptions.BACK,
    },
    DOOR: {
        value: DOOR,
        label: mapOptions.DOOR,
    },
    UNSPECIFIED: {
        value: UNSPECIFIED,
        label: mapOptions.UNSPECIFIED,
    },
}

// TODO-JM: Why we need it like this?
export const loggerTypeOptions = {
    [LoggerType.CARTASENSE]: {
        value: LoggerType.CARTASENSE,
        label: loggerTypes.cartaSense.title,
    },
    [LoggerType.SKYCELL_SAVY_BLE]: {
        value: LoggerType.SKYCELL_SAVY_BLE,
        label: loggerTypes.skyCellSavy.title,
    },
    [LoggerType.CL_TT_7100]: {
        value: LoggerType.CL_TT_7100,
        label: loggerTypes.clTT7100.title,
    },
    [LoggerType.MR_810T]: {
        value: LoggerType.MR_810T,
        label: loggerTypes.mr810t.title,
    },
    [LoggerType.MR_813]: {
        value: LoggerType.MR_813,
        label: loggerTypes.mr813.title,
    },
}

const LOGGER_POSITIONS = 'Sensor Position'

export const LOGGER_POSITIONS_NAME = 'loggerPositions'
export const SENSOR_CODES_NAME = 'sensorCodes'

const wrapWithLabels = (value) => {
    return {
        label: value, value,
    }
}

export const mapLoggerPositions = (value) => {
    return {
        value,
        label: mapOptions[value],
    }
}

export const getLoggerPositionsConfig = ({
    positions, loggerType,
}: {
    positions: string[],
    loggerType?: LoggerType
}): ConfigLogger => {
    return {
        title: LOGGER_POSITIONS,
        name: LOGGER_POSITIONS_NAME,
        positions,
        loggerType,
        options: positions.map(mapLoggerPositions),
    }
}

export const loggerOptions = {
    loggerTypes: loggerTypeOptions,
    loggerPositions: sensorOptions,
    sensorCodes: Object.values(SensorCode).map(wrapWithLabels),
}
