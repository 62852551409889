import {
    RequestConfig,
} from 'app/types/request.types'

type Request = {
    domainName: string,
    filterLanesRequest: ({
        id,
        params,
    }) => RequestConfig,
    filterCountLanesRequest: ({
        id,
    }) => {
        customUrl: string
    },
}

const request: Request = {
    domainName: 'Account',
    filterLanesRequest: ({
        id,
        params,
    }) => {
        return {
            url: `opportunity/${id}/lane/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    filterCountLanesRequest: ({
        id,
    }) => {
        return {
            customUrl: `opportunity/${id}/lane/filter-request/all`,
        }
    },
}

export default request
