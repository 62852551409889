import {
    useMemo,
} from 'react'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import {
    LocationType,
} from 'app/types/enums'
import LocationsRequests from 'app/Apps/ContactManagement/Locations/Locations.request'
import {
    airportsConvertionFn,
} from '../utils'

export default (filter?: Record<string, (string|boolean|number)[]>) => {
    const locationAirportConfigParams = useMemo(() => {
        return {
            ...LocationsRequests.selector,
            convertion: airportsConvertionFn,
            includeFilters: {
                ...filter || {},
                locationType: [LocationType.AIRPORT],
            },
        }
    }, [filter])

    return useDelayedLoadOptions(locationAirportConfigParams)
}
