import React, {
    useCallback,
} from 'react'
import {
    FormHelperText,
} from '@mui/material'
import {
    MobileDatePicker,
} from '@mui/x-date-pickers/MobileDatePicker'
import clsx from 'clsx'

import Label from 'app/shared-components/Label'
import {
    dateToDateStr,
    strToMoment,
} from 'app/utils/date'

import useStyles from './DateSelect.styles'

import ToolbarWithKeyboardViewSwitch from '../DateTimeZonedSelect/components/ToolbarWithKeyboardViewSwitch'
import LayoutWithKeyboardView from '../DateTimeZonedSelect/components/LayoutWithKeyboardView'

type Props = {
    title?: string,
    name?: string,
    hasError?: string,
    onChange: (value: string, event: any) => void,
    disabled?: boolean,
    required?: boolean,
    value?: string,
    className?: string,
}

const defaultProps: Partial<Props> = {
    title: '',
    name: '',
    hasError: '',
    disabled: false,
    required: false,
    value: null,
    className: '',
}

const DateSelect = (props: Readonly<Props>) => {
    const {
        classes,
    } = useStyles()
    const {
        title,
        name,
        hasError,
        onChange,
        value,
        disabled,
        required,
        className,
    } = props

    const handleOnChange = useCallback((date) => {
        const newValue = dateToDateStr(date)

        onChange(newValue, {
            target: {
                name,
                value: newValue,
            },
        })
    }, [
        name,
        onChange,
    ])

    return (
        <div
            className={className}
            data-testid={`dateSelect-${name}`}
        >
            <Label
                title={title}
                hasError={hasError}
            />
            <div
                className={clsx(
                    classes.dateField,
                    {
                        [classes.disabled]: disabled,
                        [classes.isRequired]: required,
                    },
                )}
            >
                {required && <div className={classes.requiredMark} />}
                <MobileDatePicker
                    className={classes.input}
                    value={strToMoment(value)}
                    format="DD.MM.YYYY"
                    onChange={handleOnChange}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            fullWidth: true,
                        },
                    }}
                    slots={{
                        layout: LayoutWithKeyboardView,
                        toolbar: ToolbarWithKeyboardViewSwitch,
                    }}
                    disabled={disabled}
                    disableOpenPicker
                />
                <FormHelperText
                    error={Boolean(hasError)}
                    className={classes.errorMessage}
                >
                    {hasError}
                </FormHelperText>
            </div>
        </div>
    )
}

DateSelect.defaultProps = defaultProps

export default DateSelect
