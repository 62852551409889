import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        iconWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            pointerEvents: 'all',
        },
        spanWrapper: {
            display: 'block',
        },
        details: {
            color: theme.palette.error.main,
            padding: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
        message: {
            overflow: 'auto',
            pointerEvents: 'auto',
            maxHeight: 200,
        },
    }
})

export default useStyles
