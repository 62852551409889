import React, {
    useCallback,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    GeneralFreight,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    RichTableData,
} from 'app/types/richtable.types'

import columns from './BookedFreight.columns'
import useStyles from './BookedFreight.style'

const defaultProps = {
    value: [],
}

const BookedFreight = ({
    value,
}: {value?: GeneralFreight[]}) => {
    const {
        classes,
    } = useStyles()

    const load = useCallback((): Promise<RichTableData<GeneralFreight[]>> => {
        return Promise.resolve({
            data: value,
        })
    }, [value])

    return (
        <RichTable
            configName="BookedFreight"
            name="BookedFreight"
            columns={columns}
            load={load}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
        />
    )
}

BookedFreight.defaultProps = defaultProps

export default BookedFreight
