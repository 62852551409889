import generateFilters from 'app/utils/generateFilters'
import {
    removeSelfFromKeys,
} from './utils/removeSelf'

const applyFilter = (cb) => {
    return (params) => {
        const {
            filter,
            data: paramsData,
            typeMapping,
            excludeFilters,
            includeFilters,
            filters: defaultFilters,
        } = params

        let data = {
            ...paramsData,
            excludeFilters,
            includeFilters,
        }

        const filters = generateFilters(
            removeSelfFromKeys(
                filter,
            ),
            typeMapping,
        )

        if (filters) {
            data = {
                filters: defaultFilters,
                ...data,
                ...filters,
            }
        }

        return cb({
            ...params,
            data: {
                filters: defaultFilters,
                ...data,
            },
        })
    }
}

export default applyFilter
