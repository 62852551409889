import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        messageWrapper: {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: 1301,
            maxHeight: '50%',
            overflow: 'auto',
            padding: theme.spacing(2.5),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            pointerEvents: 'none',
        },
    }
})

export default useStyles
