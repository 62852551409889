import React from 'react'
import {
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import Label from 'app/shared-components/Label'

import useStyles from './TextSingleline.style'

const propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    className: PropTypes.string,
    name: PropTypes.string,
}

const defaultProps = {
    title: '',
    value: '',
    className: '',
    name: '',
}

const TextSingleline = (props) => {
    const {
        title,
        value,
        className,
        name,
    } = props
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <div className={className}>
            {title && (
                <Label
                    title={title}
                />
            )}
            <Typography
                className={classes.text}
                data-testid={`textSingleLine-${name}`}
            >
                {value || value === 0 ? value : '-'}
            </Typography>
        </div>
    )
}

TextSingleline.propTypes = propTypes
TextSingleline.defaultProps = defaultProps

export default TextSingleline
