import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    LocationAreas,
} from '../LocationAreas.types'

const locationAreasFields:DTOCardFormField<keyof LocationAreas>[] = [{
    id: 'generalInformation',
    title: 'General Information',
    className: 'generalInformation',
    fields: [
        {
            id: 'column1',
            fields: [{
                name: 'areaName',
                labelKey: 'Gateway.areaName',
                componentName: 'InputSingleline',
            }],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'latitude',
                    labelKey: 'Gateway.latitude',
                    className: 'gatewayType',
                    componentName: 'InputSingleline',
                },
                {
                    name: 'longitude',
                    labelKey: 'Gateway.longitude',
                    componentName: 'InputSingleline',
                },
            ],
        },
        {
            id: 'column3',
            fields: [{
                name: 'description',
                labelKey: 'AdditionalFeesCreateDto.comment',
                componentName: 'LongText',
            }],
        },
        {
            id: 'column4',
            fields: [{
                name: 'supportingDocuments',
                labelKey: 'ContractBasisUpdateDto.supportingDocuments',
                componentName: 'AttachmentsObjectSelector',
            }],
        },
    ],
}]

export default locationAreasFields
