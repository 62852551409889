import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LeaseType,
} from 'app/types/enums'

import {
    AssignPricingLaneGroupsTable,
} from '../AccountQuotes.types'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof AssignPricingLaneGroupsTable>[] => {
    return [
        {
            id: 'laneGroupNumber',
            headerProps: {
                children: l('Lane.laneGroup'),
            },
            mapCellProps: 'laneGroupNumber',
        },
        {
            id: 'deliveryServiceType',
            headerProps: {
                children: l('LaneGroup.deliveryServiceType'),
            },
            mapCellProps: 'deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
        },
        {
            id: 'handoverPointLocationName',
            headerProps: {
                children: l('LaneGroup.handoverPoint'),
            },
            mapCellProps: 'handoverPointLocationName',
        },
        {
            id: 'collectionServiceType',
            headerProps: {
                children: l('LaneGroup.collectionServiceType'),
            },
            mapCellProps: 'collectionServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: CollServiceType,
        },
        {
            id: 'collectionDropoffPointLocationName',
            headerProps: {
                children: l('LaneGroup.collectionDropoffPoint'),
            },
            mapCellProps: 'collectionDropoffPointLocationName',
        },
        {
            id: 'leaseType',
            headerProps: {
                children: l('LaneGroup.leaseType'),
            },
            mapCellProps: 'leaseType',
            componentName: 'EnumValue',
            allowedValuesEnum: LeaseType,
        },
        {
            id: 'originAirportsIataCode',
            headerProps: {
                children: l('AccountQuotes.laneFrom'),
            },
            mapCellProps: 'originAirportsIataCode',
            componentName: 'List',
        },
        {
            id: 'destinationAirportsIataCode',
            headerProps: {
                children: l('AccountQuotes.laneTo'),
            },
            mapCellProps: 'destinationAirportsIataCode',
            componentName: 'List',
        },
        {
            id: 'tempRange',
            headerProps: {
                children: l('AccountQuotes.laneRange'),
            },
            mapCellProps: 'tempRange',
        },
        {
            id: 'additionalFeesValidTo',
            headerProps: {
                children: l('LaneGroupView.additionalFeesValidTo'),
            },
            mapCellProps: 'additionalFeesValidTo',
            componentName: 'LaneDateEnriched',
        },
        {
            id: 'laneFeesValidTo',
            headerProps: {
                children: l('LaneGroupView.laneFeesValidTo'),
            },
            mapCellProps: 'laneFeesValidTo',
            componentName: 'LaneDateEnriched',
        },
    ]
}

export default columns
