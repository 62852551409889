import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        formControl: {
            width: '100%',
            border: 0,
        },
        select: {
            padding: '0 4px',
            color: `${theme.palette.secondary[600]} !important`,
        },
        selectContainer: {
            position: 'relative',
        },
        wrapper: {
            width: '100%',
        },
    }
})

export default useStyles
