import {
    OpportunityBulkEditType, OpportunityType,
} from '../opportunities.types'

export default function convertResponseToOpportunitiesSpreadsheetData(
    data: OpportunityBulkEditType[],
): OpportunityType[] {
    return (data || []).map((item) => {
        return {
            account: item.accountId ? {
                id: item.accountId,
                label: item.accountName,
            } : undefined,
            regionalGroup: item?.regionalGroupId ? {
                id: item.regionalGroupId,
                label: item.regionalGroupNumber,
            } : undefined,
            originRegion: item.regionalGroupOriginRegion,
            destinationRegion: item.regionalGroupDestinationRegion,
            temperatureRange: item.temperatureRange,
            frequency: item.frequency,
            airline: item.airlineId ? {
                id: item.airlineId,
                label: item.airlineName,
            } : undefined,
            businessStart: item.businessStart,
            reprOriginAirport: item.reprOriginAirportId ? {
                id: item.reprOriginAirportId,
                label: item.reprOriginAirport,
            } : undefined,
            reprDestinationAirport: item.reprDestinationAirportId ? {
                id: item.reprDestinationAirportId,
                label: item.reprDestinationAirport,
            } : undefined,
            annualPalletVolume: item.annualPalletVolume,
            competitorsPackaging: item.competitorsPackaging,
            currentPackagingSolution: item.currentPackagingSolution,
            palletType: item.palletType,
            validationNeeded: item.validationNeeded,
            id: item.id,
            existNonCanceledLanes: item.existNonCanceledLanes,
            collaborators: (item.collaborators || []).map(({
                id, userName,
            }) => {
                return {
                    id,
                    value: id,
                    label: userName,
                }
            }),
        } as unknown as OpportunityType
    })
}
