import {
    makeStyles,
} from 'app/tss'

const collectionDropoffPoint = 'collectionDropoffPoint'
const returnedOn = 'returnedOn'
const containers = 'containers'

const useStyles = makeStyles()((theme) => {
    return {
        collectionDropoffPoint: {
            gridArea: collectionDropoffPoint,
        },
        returnedOn: {
            gridArea: returnedOn,
        },
        containers: {
            gridArea: containers,
        },
        paper: {
            '&&': {
                width: theme.spacing(86),
                maxWidth: theme.spacing(86),
                position: 'static',
            },
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${collectionDropoffPoint} ${returnedOn}"
                "${containers} ${containers}"
                `,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    }
})

export default useStyles
