import React from 'react'

import useGetOrderByNumber from 'app/Apps/OrderManagement/Orders/hooks/useGetOrderByNumber'
import StatusHandler from 'app/shared-components/StatusHandler'

import ContainerSelection from './ContainerSelection'

const ContainerSelectionContainer = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetOrderByNumber(orderNumber)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContainerSelection
                data={data}
                onSuccess={invalidate}
            />
        </StatusHandler>
    )
}

export default ContainerSelectionContainer
