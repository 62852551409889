const request = {
    SavedSimulations: {
        url: 'transportsimulation/filter-request/all',
    },
    SimulationById: {
        requestFn: ({
            simulationId,
        }) => {
            return {
                url: `transportsimulation/${simulationId}`,
            }
        },
    },
}

export default request
