import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import requests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'

export default ({
    onSuccess,
}: {
    onSuccess: (...args: any[]) => void,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessLaneFeesStatusUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Lane Fees was rejected',
        id: 'RejectBulkLaneFees',
        onSuccess,
    })

    const {
        mutate: awardedBulk,
    } = useMutation({
        mutationFn: (data: {
            laneFees: { id: number }[],
            rejectedReason: string,
            rejectedReasonComment: string
        }) => {
            return simpleApi({
                ...requests.rejectedBulk(data),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLaneFeesStatusUpdate,
    })

    return useCallback((data) => {
        awardedBulk(data)
    }, [awardedBulk])
}
