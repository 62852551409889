import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        selectedTemperatureRange: {
            justifyContent: 'center !important',
        },
    }
})

export default useStyles
