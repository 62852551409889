import React from 'react'

import Card from 'app/shared-components/Card'
import OrderChangeSummary from 'app/Apps/OrderManagement/Orders/Components/OrderChangeSummary'

import OrderPostponements from './OrderPostponements'
import RequiredContainersChanges from './RequiredContainersChanges'
import useStyles from './OrderChanges.style'

const OrderChanges = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        classes,
    } = useStyles()

    return (
        <>
            <Card
                className={classes.root}
                contentClass={classes.wrapper}
                titleClass={classes.title}
                title="Postponements"
            >
                <OrderPostponements orderNumber={orderNumber} />
            </Card>
            <Card
                className={classes.root}
                contentClass={classes.wrapper}
                titleClass={classes.title}
                title="Required Containers Changes"
            >
                <RequiredContainersChanges orderNumber={orderNumber} />
            </Card>
            <Card
                className={classes.root}
                contentClass={classes.wrapper}
                titleClass={classes.title}
                title="Order Change Summary"
            >
                <OrderChangeSummary orderNumber={orderNumber} />
            </Card>
        </>
    )
}

export default OrderChanges
