import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    Countries: {
        requestFn: () => {
            return {
                url: 'country/filter-request/all',
                method: 'POST',
                data: {
                    start: 0,
                    rows: 400,
                },
            }
        },
    },
    States: {
        requestFn: (params) => {
            return {
                url: `country/${params.country?.id}`,
                method: 'GET',
                ...params,
            }
        },
    },
    TimeZone: {
        requestFn: (params) => {
            return {
                url: `timezone/timezonebyposition?lat=${params.latitude}&lng=${params.longitude}`,
                method: 'GET',
                ...params,
            }
        },
    },
}

export default config
