import React, {
    useCallback,
} from 'react'
import {
    FormControl,
    Switch as MaterialSwitch,
} from '@mui/material'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import noop from 'lodash/noop'

import Label from 'app/shared-components/Label'

import useStyles from './Switch.styles'

const propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.string,
}

const defaultProps = {
    onChange: noop,
    className: '',
    title: '',
    name: '',
    value: false,
    disabled: false,
    hasError: '',
}

const Switch = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        title,
        disabled,
        hasError,
        onChange,
        className,
        value,
        name,
    } = props

    const handleChange = useCallback(() => {
        const newValue = !value

        onChange(newValue, {
            target: {
                name,
                value: newValue,
            },
        })
    }, [
        name,
        onChange,
        value,
    ])

    return (
        <FormControl
            error={Boolean(hasError)}
            className={clsx(classes.formControl, className)}
        >
            <Label
                title={title || name}
            />
            <div>
                <MaterialSwitch
                    checked={value}
                    onChange={handleChange}
                    color="primary"
                    name={name}
                    value={value}
                    disabled={disabled}
                />
            </div>
        </FormControl>
    )
}

Switch.propTypes = propTypes
Switch.defaultProps = defaultProps

export default Switch
