import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import Typography from '@mui/material/Typography'

import Card from 'app/shared-components/Card'
import replaceTempIds from 'app/utils/replaceTempIds'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import {
    ENABLED_ORDER_STATUSES,
    ENABLED_CONTAINER_ORDER_STATUSES,
    ENABLED_CONTAINER_ORDER_STATUSES_INTERNAL,
    DISABLED_BOOKED_CONTAINERS_STATUSES,
    NOT_ALLOWED_CANCEL_MODIFY_ORDER_STATUSES,
} from 'app/Apps/OrderManagement/Orders/utils/orderStatus'
import {
    FreightTypes,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'

import ModifyRequiredContainersCTDialog from './ModifyRequiredContainersCTDialog'
import useSaveContainerSelection from './hooks/useSaveContainerSelection'
import getContainerSelectionFields from './containerSelection.fields'
import getFreightSelectionFields from './freightSelection.fields'
import OrderNotifications from '../OrderNotifications'
import AvailableContainers from './AvailableContainers'
import BookedContainers from './BookedContainers'
import useStyles from './ContainerSelection.styles'
import {
    Order,
    OrderTypes,
    OrderSubType,
    RequiredContainersInternal,
} from '../../orders.types'
import {
    RequestData,
} from './containerSelection.types'

const ALL_CONTAINERS_SELECTED = 'ALL_CONTAINERS_SELECTED'
const CONTAINERS_SELECTED_UPDATED = 'CONTAINERS_SELECTED_UPDATED'
const CONTAINERS_SELECTED_REJECTED = 'CONTAINERS_SELECTED_REJECTED'
const DOMAIN_NAME = orderManagementRequest.UpdateOrderContainerSelection.domainName

const ContainerSelection = ({
    data,
    onSuccess,
}: {
    data: Order,
    onSuccess: () => void
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const [
        state,
        setState,
    ] = useState<{
        value: Order,
        edited: boolean}>({
            value: data,
            edited: false,
        })

    const [
        openModificationDialog,
        setModificationDialogOpen,
    ] = useState<boolean>(false)

    const {
        orderNumber,
        version,
        freightType,
        originLocation: location,
        allowedProductTypes,
        selectedContainers,
        temperatureRange,
        id: orderId,
        orderStatus,
        orderType,
        orderSubType,
        requiredContainers,
        requiredCargoComment,
    } = state.value

    const isInternalType: boolean = useMemo(() => {
        return orderType === OrderTypes.INTERNAL_TRANSPORT
    }, [orderType])

    const isPreSelectContainers: boolean = useMemo(() => {
        return orderSubType !== OrderSubType.STOCK_TRANSFER
    }, [orderSubType])

    const [requiredContainersDefault] = useState<RequiredContainersInternal[]
        | RequiredContainersCustomer[]>(
            isInternalType
                ? state.value.requiredContainersInternal
                : state.value.requiredContainers,
        )

    const isGeneralFreight: boolean = useMemo(() => {
        return freightType === FreightTypes.GENERAL_FREIGHT
    }, [freightType])

    const disabled: boolean = useMemo(() => {
        return !ENABLED_ORDER_STATUSES.includes(orderStatus)
    }, [orderStatus])

    const fields = useMemo(() => {
        return isGeneralFreight
            ? getFreightSelectionFields(disabled)
            : getContainerSelectionFields({
                isInternalType,
                temperatureRange,
                allowedProductTypes,
                getAllowedValues,
            })
    }, [
        isGeneralFreight,
        disabled,
        isInternalType,
        temperatureRange,
        allowedProductTypes,
        getAllowedValues,
    ])

    const fieldsWithClasses = useFieldsWithClassName(fields, classes)

    const modifyDataBeforeSend = useCallback((modifiedOrder: Order): RequestData => {
        const {
            requiredContainersInternal,
            requiredCargoComment: requiredCargoCommentNewValue,
            generalFreight,
        } = modifiedOrder

        if (isGeneralFreight) {
            return {
                generalFreight: replaceTempIds(generalFreight),
                version,
            }
        }

        return {
            requiredContainersInternal,
            requiredCargoComment: requiredCargoCommentNewValue,
            version,
        }
    }, [
        isGeneralFreight,
        version,
    ])

    const isEnabledContainers: boolean = useMemo(() => {
        return isInternalType
            ? ENABLED_CONTAINER_ORDER_STATUSES_INTERNAL.includes(orderStatus)
            : ENABLED_CONTAINER_ORDER_STATUSES.includes(orderStatus)
    }, [
        orderStatus,
        isInternalType,
    ])

    const isDisabledBookedContainers: boolean = useMemo(() => {
        return DISABLED_BOOKED_CONTAINERS_STATUSES.includes(orderStatus)
    }, [orderStatus])

    const isShowContainers: boolean = useMemo(() => {
        return !isGeneralFreight && Boolean(requiredContainersDefault?.length)
    }, [
        isGeneralFreight,
        requiredContainersDefault?.length,
    ])

    const {
        mutate,
        isPending,
    } = useSaveContainerSelection(onSuccess)

    const save = (): void => {
        mutate({
            data: modifyDataBeforeSend(state.value),
            orderNumber: state.value.orderNumber,
            isGeneralFreight,
        })
    }

    const reset = useCallback(() => {
        setState({
            edited: false,
            value: data,
        })
    }, [data])

    const change = useCallback((update) => {
        setState({
            edited: true,
            value: update,
        })
    }, [])

    const isModifyCT = orderType === OrderTypes.CUSTOMER_TRANSPORT
        && !NOT_ALLOWED_CANCEL_MODIFY_ORDER_STATUSES.includes(orderStatus)

    return (
        <>
            <DomainObjectForm
                name={DOMAIN_NAME}
                value={state.value}
                onChange={change}
                fields={fieldsWithClasses}
                className={isGeneralFreight
                    ? classes.gridWrapperGeneralFreight
                    : classes.gridWrapperContainers}
                exists
                disabled={disabled}
            />
            {isModifyCT && (
                <ButtonContainer>
                    <Button
                        label="modify required containers"
                        secondary={false}
                        onClick={() => { setModificationDialogOpen(true) }}
                        data-testid="btn-modify-required-containers"
                    />
                </ButtonContainer>
            ) }
            {!disabled && isInternalType
                && (
                    <ButtonContainer>
                        <Button
                            label="Modify (SkyCell)"
                            secondary={false}
                            onClick={save}
                            disabled={!state.edited}
                            saving={isPending}
                            data-testid="btn-save"
                        />
                        <Button
                            label="Cancel"
                            secondary
                            onClick={reset}
                            disabled={!state.edited}
                        />
                    </ButtonContainer>
                ) }
            {
                isShowContainers && (
                    <>
                        <Card
                            className={classes.root}
                            contentClass={classes.wrapper}
                        >
                            <Typography
                                variant="h3"
                                className={classes.title}
                            >
                                Available Containers
                            </Typography>
                            <AvailableContainers
                                location={location.id}
                                selectedContainers={selectedContainers}
                                requiredContainers={requiredContainersDefault}
                                orderNumber={orderNumber}
                                onSuccess={onSuccess}
                                orderVersion={version}
                                isInternalType={isInternalType}
                                isPreSelectContainers={isPreSelectContainers}
                                disabled={!isEnabledContainers}
                                exists
                            />
                        </Card>
                        <Card
                            className={classes.root}
                            contentClass={classes.wrapper}
                        >
                            <Typography
                                variant="h3"
                                className={classes.title}
                            >
                                Booked Containers
                            </Typography>
                            <BookedContainers
                                value={selectedContainers}
                                requiredContainers={requiredContainersDefault}
                                orderNumber={orderNumber}
                                disabled={isDisabledBookedContainers}
                                onSuccess={onSuccess}
                                orderVersion={version}
                                isInternalType={isInternalType}
                            />
                        </Card>
                    </>
                )
            }
            <Card
                className={classes.root}
                contentClass={classes.wrapper}
            >
                <Typography
                    variant="h3"
                    className={classes.title}
                >
                    Notifications
                </Typography>
                <OrderNotifications
                    includeFilters={{
                        orderId: [orderId],
                        triggerType: [
                            ALL_CONTAINERS_SELECTED,
                            CONTAINERS_SELECTED_UPDATED,
                            CONTAINERS_SELECTED_REJECTED,
                        ],
                    }}
                    disabled={disabled}
                    orderNumber={orderNumber}
                    url={orderManagementRequest.Notifications.ContainerSelection.url(orderNumber)}
                />
            </Card>
            <ModifyRequiredContainersCTDialog
                open={openModificationDialog}
                setOpen={setModificationDialogOpen}
                onSuccess={onSuccess}
                orderNumber={orderNumber}
                requiredContainers={requiredContainers}
                requiredCargoComment={requiredCargoComment}
                allowedProductTypes={allowedProductTypes}
                version={version}
            />
        </>
    )
}

export default ContainerSelection
