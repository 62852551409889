import {
    FormPart,
} from 'app/types/form.types'

import {
    ContactsSelection,
} from './PrintForm.types'

const getContactSelectionFields = ({
    value,
    disabledAll,
}: {
    value: ContactsSelection,
    disabledAll: boolean,
}): FormPart<keyof ContactsSelection>[] => {
    return [{
        title: 'Contact Selection',
        className: 'contacts',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'keyAccountManager',
                    componentName: 'ContactObjectSelector',
                    label: 'Key Account Manager',
                    required: true,
                    disabled: Boolean(value.businessDeveloper) || disabledAll,
                }],
            },
            {
                id: 'column2',
                fields: [{
                    name: 'businessDeveloper',
                    componentName: 'ContactObjectSelector',
                    label: 'Business Developer',
                    required: true,
                    disabled: Boolean(value.keyAccountManager) || disabledAll,
                }],
            },
            {
                id: 'column3',
                fields: [{
                    name: 'customerQuotationContact',
                    componentName: 'ContactObjectSelector',
                    label: 'Customer Quotation Contact',
                    disabled: Boolean(value.customerExecutionContact) || disabledAll,
                }],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'customerExecutionContact',
                    componentName: 'ContactObjectSelector',
                    label: 'Customer Execution Contact',
                    disabled: Boolean(value.customerQuotationContact) || disabledAll,
                }],
            },
        ],
    }]
}

export default getContactSelectionFields
