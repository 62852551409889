import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    SharedPositioningType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import sharedPositioningRequest from '../sharedPositioning.request'

const useGetSharePositioning = (orderNumber) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<SharedPositioningType[]>({
        key: RequestKeys.getSharePositioning,
        params: {
            orderNumber,
        },
        requestFunc: RequestFn.getEntity(sharedPositioningRequest.SharePositioning.requestFn),
    })

    return {
        sharedPositioning: data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetSharePositioning
