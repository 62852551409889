import useHasPermission from 'app/hooks/useHasPermission'

const useCheckTransportPlanerRoles = (): {
    isRolePlannedUpdate: boolean,
    isRolePrimeUpdate: boolean,
    isRoleAvailableUpdate: boolean,
} => {
    const isRoleAvailableUpdate: boolean = useHasPermission(['transport_planner_available_update'])
    const isRolePlannedUpdate: boolean = useHasPermission(['transport_planner_planed_update'])
    const isRolePrimeUpdate: boolean = useHasPermission(['transport_planner_prime_update'])

    return {
        isRolePlannedUpdate,
        isRolePrimeUpdate,
        isRoleAvailableUpdate,
    }
}

export default useCheckTransportPlanerRoles
