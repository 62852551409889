import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        button: {
            whiteSpace: 'nowrap',
            minWidth: '150px !important',
            maxWidth: '200px !important',
            backgroundColor: `${theme.palette.primary.light} !important`,
            color: 'white !important',
            height: '36px !important',
            fontSize: `${theme.typography.body1.fontSize} !important`,
            margin: `${theme.spacing(0.7)} !important`,
            textTransform: 'none !important',
        },
        layerContent: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            display: 'flex',
        },
        link: {
            display: 'inline-block',
            width: '100%',
            height: '100%',
            lineHeight: '24px',
            color: theme.palette.common.white,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }
})

export default useStyles
