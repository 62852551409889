import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useCallback,
} from 'react'

import config from './CountOrdersAdditionalFee.requests'

type OrderCount = {
    orderCount: number
}

export default (id: number, options: {
    validFrom?: string,
    validTo?: string,
    openConfirmDialog?: boolean
} = {
    validFrom: null,
    validTo: null,
    openConfirmDialog: true,
}) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getOrderCountForAdditionalFee],
        })
    }, [queryClient])

    const {
        validFrom, validTo, openConfirmDialog,
    } = options

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<OrderCount>({
        key: RequestKeys.getOrderCountForAdditionalFee,
        params: {
            id,
            data: {
                validTo, validFrom,
            },
        },
        requestFunc: RequestFn.getEntity(config.count),
        enabled: Boolean(id) && openConfirmDialog,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}
