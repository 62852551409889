import React from 'react'

import EnumChipSelector from 'app/shared-components/EnumChipSelector'
import useGetProductTypesFromTempRange from 'app/shared-components/ProductTypeSelector/hooks/useGetProductTypesFromTempRange'
import {
    TemperatureRangeAll,
} from 'app/types/enums'

import useEnumValues from 'app/hooks/useEnumValues'

type Props = {
    value?: string[],
    onChange: (newValue: number[]) => void,
    title?: string,
    disabled?: boolean,
    required?: boolean,
    temperatureRange?: TemperatureRangeAll,
}
const defaultProps: Partial<Props> = {
    value: [],
    title: undefined,
    disabled: false,
    required: false,
    temperatureRange: undefined,
}

const MultipleProductTypeSelector = ({
    value,
    onChange,
    title,
    disabled,
    required,
    temperatureRange,
}: Props) => {
    const getAllowedValues = useEnumValues()
    const ProductTypes = useGetProductTypesFromTempRange(temperatureRange)
    const allowedValuesProductType = getAllowedValues(ProductTypes)

    return (
        <EnumChipSelector
            value={value}
            onChange={onChange}
            title={title}
            disabled={disabled}
            required={required}
            allowedValues={allowedValuesProductType}
            menuShouldBlockScroll
            menuPortalTarget={document.body}
        />
    )
}

MultipleProductTypeSelector.defaultProps = defaultProps

export default MultipleProductTypeSelector
