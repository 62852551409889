import {
    FieldInCard,
} from 'app/types/form.types'
import {
    ContainerStatus,
} from 'app/types/enums'
import {
    OverviewFields,
} from './Overview.types'

const getFields = (getAllowedValues): FieldInCard<OverviewFields>[] => {
    return [{
        title: 'Summary',
        fields: [
            {
                name: 'serialNumber',
                componentName: 'InputSingleline',
                labelKey: 'Container.serialNumber',
            },
            {
                name: 'containerStatus',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(ContainerStatus),
                labelKey: 'Container.containerStatus',
            },
            {
                name: 'jypId',
                componentName: 'InputSingleline',
                labelKey: 'Container.jypId',
            },
            {
                name: 'containerDescription',
                componentName: 'InputSingleline',
                labelKey: 'Container.containerDescription',
            },
            {
                name: 'location',
                labelKey: 'Container.location',
                componentName: 'LocationObjectSelector',
            },
            {
                name: 'containerModel',
                componentName: 'IntegerInput',
                labelKey: 'ContainerType.containerModel',
            },
        ],
    }]
}

export default getFields
