const replaceId = (id) => {
    if (!id) return undefined
    return typeof id === 'string' && id.includes('temp-') ? undefined : id
}

const replaceTempIds = (data) => {
    if (Array.isArray(data)) {
        return data.map(replaceTempIds)
    }

    if (data && typeof data === 'object') {
        return {
            ...Object.entries(data).reduce((res, [
                key,
                value,
            ]) => {
                return {
                    ...res,
                    [key]: replaceTempIds(value),
                }
            }, {}),
            id: replaceId(data.id),
        }
    }
    return data
}

export default replaceTempIds
