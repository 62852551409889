import React, {
    useCallback,
} from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
    FormControlLabel,
    Checkbox as MaterialCheckbox,
} from '@mui/material'

import useStyles from './Checkbox.styles'

const propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.bool,
    className: PropTypes.string,
}

const defaultProps = {
    title: '',
    disabled: false,
    indeterminate: false,
    onChange: noop,
    name: '',
    value: false,
    className: '',
}

const Checkbox = ({
    title,
    disabled,
    indeterminate,
    onChange,
    name,
    value,
    className,
}) => {
    const handleChange = useCallback(() => {
        const newValue = !value

        onChange(newValue, {
            target: {
                name,
                value: newValue,
            },
        })
    }, [
        name,
        onChange,
        value,
    ])

    const {
        classes,
    } = useStyles()

    return (
        <div className={clsx(className, classes.checkboxWrapper)}>
            <div>
                <div className={classes.requiredMark} />
                <FormControlLabel
                    control={
                        (
                            <MaterialCheckbox
                                data-testid={`checkbox-${name || title}`}
                                disabled={disabled}
                                checked={value}
                                color="primary"
                                indeterminate={indeterminate}
                                onChange={handleChange}
                                value={value}
                                name={name}
                                classes={{
                                    root: classes.checkboxRoot,
                                }}
                            />
                        )
                    }
                    label={title}
                />
            </div>
        </div>
    )
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
