import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        input: {
            display: 'none !important',
        },
        label: {
            cursor: 'pointer',
        },
    }
})

export default useStyles
