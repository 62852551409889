import React from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import useStyles from './StepProgress.style'

const propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({
        stepType: PropTypes.string.isRequired,
        stepStatus: PropTypes.string.isRequired,
    })),
}

const defaultProps = {
    value: [],
}

const IN_PROGRESS = 'IN_PROGRESS'

const StepProgress = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        value,
    } = props

    return (
        <>
            {value.map((item, index) => {
                return (
                    <div
                        className={classes.root}
                        key={`${item.stepType}-${index}`} /* eslint-disable-line react/no-array-index-key */
                    >
                        <div className={classes.wrapper}>
                            <img
                                src={`assets/images/orderSteps/${item.stepType?.toLowerCase()}.svg`}
                                alt={item.stepType}
                            />
                            {item.stepStatus === IN_PROGRESS
                                ? (
                                    <img
                                        className={classes.iconStatus}
                                        src="assets/images/orderSteps/in_progress.svg"
                                        alt={item.stepType}
                                    />
                                ) : (
                                    <img
                                        className={classes.iconStatus}
                                        src="assets/images/orderSteps/completed.svg"
                                        alt={item.stepType}
                                    />
                                )}
                        </div>
                        {index < (value.length - 1) && (
                            <ArrowForwardIosIcon className={classes.iconArrow} />
                        )}
                    </div>
                )
            })}
        </>
    )
}

StepProgress.propTypes = propTypes
StepProgress.defaultProps = defaultProps

export default StepProgress
