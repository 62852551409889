import React, {
    useMemo, CSSProperties, useCallback,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import StatusHandler from 'app/shared-components/StatusHandler'
import Card from 'app/shared-components/Card'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import SkyNetMap, {
    SkyNetMapProvider,
} from 'app/shared-components/SkyNetMap'
import useGetLastGeolocation from './hooks/useGetLastGeoLocation'
import useStyles from './Geolocation.styles'
import fields from './Geolocation.fields'

const Geolocation = ({
    id: serialNumber,
}: {
    id: string,
}) => {
    const {
        classes,
    } = useStyles()
    const {
        isDataReady,
        data,
        isFetching,
        isError,
        error,
    } = useGetLastGeolocation(serialNumber)

    const value = useMemo(() => {
        if (!isDataReady) return {}
        return {
            ...data,
            timestamp: data?.timestamp ? `Measured ${moment(data?.timestamp).fromNow()}` : '',
        }
    }, [
        data,
        isDataReady,
    ])

    const fieldsWithClassName = useFieldsWithClassName(fields, classes)
    const renderSkyNetMap = useCallback((styles: CSSProperties) => {
        return (
            <SkyNetMap
                longitude={data?.longitude}
                latitude={data?.latitude}
                mapContainerStyles={styles}
            />
        )
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <DataContentWrapper isEmpty={isEmpty(data)}>
                <div
                    className={classes.container}
                    data-testid="geolocation"
                >

                    <Card
                        className={classes.location}
                        title="Location"
                        data-testid="location"
                    >
                        <SkyNetMapProvider>
                            { renderSkyNetMap }
                        </SkyNetMapProvider>
                    </Card>
                    <div
                        className={classes.information}
                        data-testid="information"
                    >
                        <DomainObjectForm
                            name="Information"
                            value={value}
                            classNames={{
                                gridCardWrapper: classes.informationCard,
                            }}
                            fields={fieldsWithClassName}
                            disabled
                            exists
                        />
                    </div>

                </div>
            </DataContentWrapper>
        </StatusHandler>
    )
}

export default Geolocation
