import React, {
    useEffect, useRef,
} from 'react'
import isEqual from 'lodash/isEqual'

import RichTable from 'app/shared-components/RichTableReduxWrapper'

import useGetAvailableLanesForCTCreation from './hooks/useGetAvailableLanesForCTCreation'
import useStyles from './AvailableLanes.style'
import columns from './AvailableLanes.columns'
import {
    AvailableLanesFilters,
} from './AvailableLanes.types'

type Props = {
    value: number[],
    onChange: (newVal: number[]) => void,
    includeFilters: AvailableLanesFilters,
    disabled: boolean,
}

const DOMAIN_NAME = 'LaneCustomerTransportCreationView'

const AvailableLanes = ({
    value,
    onChange,
    includeFilters,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const defaultIncludeFilters = useRef(includeFilters)

    const {
        onParamsChange,
        loadData,
    } = useGetAvailableLanesForCTCreation()

    const onSelectRow = (id: number[]) => {
        onChange(id)
    }

    useEffect(() => {
        if (!isEqual(defaultIncludeFilters.current, includeFilters)) {
            defaultIncludeFilters.current = includeFilters
            onChange(null)
        }
    }, [
        includeFilters,
        onChange,
    ])

    return (
        <RichTable
            configName="AvailableLanes"
            name={DOMAIN_NAME}
            load={loadData}
            onParamsChange={onParamsChange}
            filter={includeFilters}
            columns={columns}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            radioSelect
            onSelectRow={onSelectRow}
            selectedRows={value}
            disabled={disabled}
        />
    )
}

export default AvailableLanes
