import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import simpleApi from 'app/services/simpleApi'

const useSimpleApiRequest = ({
    onSuccess,
    onError,
    name,
    requestParams,
    title,
}: {
    onSuccess: (resp) => void,
    onError: (err) => void,
    name: string,
    requestParams: {url: string, method: string},
    title?: string,
}): UseMutationResult<unknown, unknown, Record<string, any>> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessHandler,
        onError: onErrorHandler,
    } = useHandleStatusRequest({
        id: name,
        title,
        onSuccess,
        onError,
    })

    return useMutation({
        mutationFn: (data) => {
            return simpleApi({
                data,
                token,
                ...requestParams,
            })
        },
        onSuccess: onSuccessHandler,
        onError: onErrorHandler,
    })
}

export default useSimpleApiRequest
