import {
    RichTableColumns,
} from 'app/types/richtable.types'

type LoggerTypes = {
    oldLoggerNumber: string,
    newLoggerNumber: string,
    loggerType: string,
}

const columns = (loggerTypes): RichTableColumns<keyof LoggerTypes>[] => {
    return [
        {
            id: 'oldLoggerNumber',
            headerProps: {
                children: 'Old Logger',
            },
            mapCellProps: 'oldLoggerNumber',
        },
        {
            id: 'newLoggerNumber',
            headerProps: {
                children: 'New Logger',
            },
            mapCellProps: 'newLoggerNumber',
        },
        {
            id: 'loggerType',
            headerProps: {
                children: 'Type',
            },
            mapCellProps: ({
                loggerType,
            }) => {
                return {
                    children: loggerTypes[loggerType],
                }
            },
        },
    ]
}

export default columns
