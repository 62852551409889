import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderStatuses,
} from 'app/types/enums'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const pqOrdersConfig: SkyNetTableConfig<DailyOpsFields> = {
    name: 'DailyOps.PQ Orders',
    tabs: [{
        name: 'PQ Orders',
        url: 'all',
    }],
    fields: [
        {
            name: 'orderNumber',
            filterField: 'orderNumber',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'orderStatus',
            filterField: 'orderStatus',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: OrderStatuses,
        },
        {
            name: 'customerLocationName',
            filterField: 'customerLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'pricingAccountCompanyName',
            filterField: 'pricingAccountCompanyName',
            labelKey: 'Prebooking.pricingAccount',
            filterType: FilterType.Array,
        },
        {
            name: 'forwarderLocationName',
            filterField: 'forwarderLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'originLocationLocationName',
            filterField: 'originLocationLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'handoverPointLocationName',
            filterField: 'handoverPointLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'requestedHandover',
            filterField: 'requestedHandover',
            filterType: FilterType.Array,
        },
        {
            name: 'baseLeaseUntilTimestamp',
            filterField: 'baseLeaseUntilTimestamp',
            filterType: FilterType.DateRange,
        },
    ],
}

export default pqOrdersConfig
