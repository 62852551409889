/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'

import Tooltip from 'app/shared-components/Tooltip'
import {
    useTheme,
} from '@mui/material/styles'

import Filter from 'app/shared-components/Filter'

import useStyles from './VisibleColumns.style'

const propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    visible: PropTypes.arrayOf(PropTypes.string).isRequired,
    setVisible: PropTypes.func.isRequired,
    className: PropTypes.string,
}

const defaultProps = {
    className: '',
}

const VisibleColumns = ({
    columns,
    visible,
    setVisible,
    ...props
}) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <Filter
            {...props}
            className={classes.link}
            filter={visible}
            loadFilters={() => {
                return Promise.resolve(columns.map(({
                    id, label,
                }) => {
                    return {
                        value: id,
                        label: label || id,
                    }
                }))
            }}
            set={setVisible}
            button={(
                <Tooltip title="Change Columns">
                    <div className={classes.iconWrapper}>
                        <ViewWeekIcon className={classes.icon}>
                            view_column
                        </ViewWeekIcon>
                    </div>
                </Tooltip>
            )}
        />
    )
}

VisibleColumns.propTypes = propTypes
VisibleColumns.defaultProps = defaultProps

export default VisibleColumns
