import React from 'react'
import get from 'lodash/get'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    LaneFeesStatus,
} from 'app/types/enums'
import useStyles from './LaneFeesStatusEnriched.style'

type Props = {
    rowProps: {
        laneFeesStatus: string,
        isMostRecentPricing: boolean,
    }
}

const LaneFeesStatusEnriched = (props: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        rowProps: {
            laneFeesStatus,
            isMostRecentPricing,
        },
    } = props

    const laneFeesStatuses = useEnumValues()(LaneFeesStatus)

    const iconPath = `/assets/images/icons/${laneFeesStatus === laneFeesStatuses.AWARDED ? 'checked' : 'unchecked'}.svg`

    return (
        <div>
            <div className={classes.laneFeesStatus}>
                {get(laneFeesStatuses, laneFeesStatus, laneFeesStatus)}
                {isMostRecentPricing
                    && (
                        <img
                            className={classes.icon}
                            src={iconPath}
                            alt="None"
                        />
                    )}
            </div>
        </div>
    )
}

export default LaneFeesStatusEnriched
