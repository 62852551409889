import SkyNetLink from './SkyNetLink'

const Component = {
    SkyNetLink,
}

export enum SizeLink {
    S = 'small',
    M = 'medium',
}

export enum TypeLink {
    INTERNAL = 'internal',
    INTERNAL_WITH_ICON = 'internalWithIcon',
    EXTERNAL = 'external',
}

type SkyNetLinkConfig = {
    componentName:keyof typeof Component,
    link: string,
    text?: string,
    type?: TypeLink,
    size?: SizeLink,
    newTab?: boolean,
}

export default SkyNetLinkConfig
