import React from 'react'
import {
    Avatar as AvatarM,
} from '@mui/material'

import profile from './profile.jpg'

type AvatarProps = {
    img?: string,
    alt?: string,
}

const defaultProps: Partial<AvatarProps> = {
    img: '',
    alt: 'User Photo',
}

const Avatar = ({
    img,
    alt,
}: AvatarProps) => {
    return (
        <AvatarM
            alt={alt}
            src={img || profile}
        />
    )
}

Avatar.defaultProps = defaultProps

export default Avatar
