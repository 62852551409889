import {
    DTOCardFormField,
    FieldInCard,
} from 'app/types/form.types'
import {
    AdditionalFeesStatus,
    RangeType,
} from 'app/types/enums'
import {
    AdditionalFeeValue,
} from './AdditionalFee.types'

type FeesData = {
    ccy?: string,
    fxRate?: {
        id: number,
    },
    originalStatus?: string,
}
type Params = FeesData & {
    fees: {
        id: number,
    }[],
    hidefxRate: boolean,
    exists: boolean,
    originalStatus?: string,
    editingAllowed: boolean,
    editingCommentAllowed: boolean,
    getAllowedValues: (param: Record<string, any>) => Record<string, string>
}

type AdditionalFeeFields = keyof AdditionalFeeValue

const additionalFeeComponents = ({
    ccy,
    fxRate,
    fees,
    hidefxRate,
    exists,
    originalStatus,
    editingAllowed,
    editingCommentAllowed,
    getAllowedValues,
}: Params) => {
    const fxRateWidget: FieldInCard<AdditionalFeeFields> = {
        title: 'FX Rates',
        className: 'fxRate',
        fields: [{
            componentName: 'FxRateObjectWithSelect',
            labelKey: 'AdditionalFeesUpdateDto.fees',
            name: 'fxRate',
            ccy,
            disabled: editingAllowed,
        }],
    }
    const feesRow:FieldInCard<AdditionalFeeFields> = {
        title: 'Fees',
        className: 'fees',
        fields: [{
            name: 'fees',
            labelKey: 'AdditionalFeesUpdateDto.fees',
            componentName: 'Fees',
            fxRate,
            currency: ccy,
            value: fees,
            disabled: editingAllowed,
            showSkyNetSpreadSheet: true,
        }],
    }

    const defaultFields: DTOCardFormField<AdditionalFeeFields>[] = [{
        title: 'General',
        className: 'general',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'additionalFeesStatus',
                    labelKey: 'AdditionalFeesUpdateDto.additionalFeesStatus',
                    originalStatus,
                    componentName: 'StatusTransitionSelector',
                    useDropdown: true,
                    allowedValues: getAllowedValues(AdditionalFeesStatus),
                    disabled: true,
                }],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'validFrom',
                        labelKey: 'AdditionalFeesUpdateDto.validFrom',
                        componentName: 'DateSelect',
                        disabled: editingAllowed,
                        required: true,
                    },
                    {
                        name: 'validTo',
                        labelKey: 'AdditionalFeesUpdateDto.validTo',
                        componentName: 'DateSelect',
                        disabled: editingAllowed,
                        required: true,
                    },
                    {
                        name: 'requiresManualBillingReview',
                        componentName: 'Checkbox',
                        labelKey: 'OrderBillingInformationView.requiresManualBillingReview',
                        disabled: editingAllowed,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'cancellationFeeTimeline',
                        labelKey: 'AdditionalFeesCreateDto.cancellationFeeTimeline',
                        componentName: 'IntegerInput',
                        disabled: editingAllowed,
                        required: true,
                    },
                    {
                        name: 'cancellationDaysType',
                        labelKey: 'AdditionalFeesCreateDto.cancellationDaysType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RangeType),
                        useDropdown: true,
                        disabled: editingAllowed,
                        required: true,
                    },
                ],
            },
            {
                id: 'column4',
                className: 'commentColumn',
                fields: [{
                    name: 'comment',
                    labelKey: 'AdditionalFeesCreateDto.comment',
                    componentName: 'LongText',
                    disabled: editingCommentAllowed && editingAllowed,
                }],
            },
        ],
    }]

    const fields = hidefxRate ? defaultFields
        : [
            ...defaultFields,
            fxRateWidget,
        ]

    return exists ? [
        ...fields,
        feesRow,
    ] : fields
}

export default additionalFeeComponents
