import {
    useCallback,
    useState,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import simpleApi from 'app/services/simpleApi'

type Params = {
    requestParamFn: (id)=> Record<string, any>,
    notificationId: string,
    title: string,
    actionDone: string,
    data?: Record<string, any>,
    onSuccess?: (props?: any) => void
}

const useRequestWithConfirmation = ({
    requestParamFn,
    notificationId,
    title,
    data,
    onSuccess,
    actionDone,
}: Params) => {
    const token = useJWTToken()
    const [
        openConfirmDialog,
        setOpenConfirmDialog,
    ] = useState(false)

    const {
        onSuccess: onSuccessAction,
        onError,
    } = useHandleStatusRequest({
        id: notificationId,
        title: `${title} ${actionDone}`,
        onSuccess,
    })

    const mutation = useMutation({
        mutationFn: ({
            id,
        }: {id: number}) => {
            return simpleApi({
                ...requestParamFn({
                    id,
                }),
                data,
                token,
            })
        },
        onSuccess: onSuccessAction,
        onError,
    })

    const askConfirmation = useCallback(() => {
        setOpenConfirmDialog(true)
    }, [])

    const doAction = useCallback((confirm) => {
        if (confirm) {
            mutation.mutate({
                id: data.id,
            })
        }
        setOpenConfirmDialog(false)
    }, [
        mutation,
        data,
    ])

    return {
        askConfirmation,
        openConfirmDialog,
        doAction,
        onSuccess: onSuccessAction,
    }
}

export default useRequestWithConfirmation
