import {
    LocationType,
} from 'app/types/enums'
import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    DeliveryServiceType,
    HandoverTime,
    IncoTerm,
    PreliminaryInvoiceCurrency,
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

const getFields = ({
    getAllowedValues,
    reqCustomsCoord,
    isDropOffSkyCell,
    deliveryServiceType,
}:{
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    reqCustomsCoord: boolean,
    isDropOffSkyCell: boolean,
    deliveryServiceType: DeliveryServiceType,
}): DTOCardFormField<keyof Lane>[] => {
    const isNotDropOffSkyCellFields: NestedField<keyof Lane>[] = [
        {
            name: 'fwdExport',
            componentName: 'LocationObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExport',
        },
        {
            name: 'fwdExportContacts',
            componentName: 'MultipleContactObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExportContacts',
        },
        {
            name: 'fwdExportBackup',
            componentName: 'LocationObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExportBackup',
        },
        {
            name: 'fwdExportBackupContacts',
            componentName: 'MultipleContactObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExportBackupContacts',
        },
    ]

    return [
        {
            title: 'Lane Information',
            className: 'laneInformation',
            fields: [
                {
                    id: 'laneInformation1',
                    fields: [
                        {
                            name: 'laneNumber',
                            componentName: 'InputSingleline',
                            disabled: true,
                            labelKey: 'OrderLaneView.laneNumber',
                        },
                        {
                            name: 'customerExecutionContactsOps',
                            componentName: 'MultipleContactObjectSelector',
                            required: true,
                            labelKey: 'LaneOpsInformationDto.customerExecutionContactsOps',
                        },
                    ],
                },
                {
                    id: 'laneInformation2',
                    fields: [
                        {
                            name: 'incoTerm',
                            componentName: 'Select',
                            allowedValues: getAllowedValues(IncoTerm),
                            required: true,
                            labelKey: 'LaneOpsInformationDto.incoTerm',
                        },
                        {
                            name: 'preliminaryInvoiceCurrency',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(PreliminaryInvoiceCurrency),
                            useDropdown: true,
                            required: true,
                            labelKey: 'LaneOpsInformationDto.preliminaryInvoiceCurrency',
                        },
                    ],
                },
                {
                    id: 'laneInformation3',
                    fields: [
                        {
                            name: 'handoverPointContacts',
                            componentName: 'MultipleContactObjectSelector',
                            labelKey: 'LaneOpsInformationDto.handoverPointContacts',
                            required: true,
                        },
                        {
                            name: 'endOfLeaseReminderDelay',
                            componentName: 'IntegerInput',
                            labelKey: 'LaneOpsInformationDto.endOfLeaseReminderDelay',
                        },
                    ],
                },
                {
                    id: 'laneInformation4',
                    fields: [
                        {
                            name: 'collectionDropoffPointContacts',
                            componentName: 'MultipleContactObjectSelector',
                            labelKey: 'LaneOpsInformationDto.collectionDropoffPointContacts',
                            required: true,
                        },
                        {
                            name: 'laneCommentOps',
                            componentName: 'LongText',
                            labelKey: 'LaneOpsInformationDto.laneCommentOps',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Positioning',
            className: 'positioning',
            fields: [
                {
                    id: 'positioning1',
                    fields: [
                        {
                            name: 'handoverEarliest',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(HandoverTime),
                            labelKey: 'LaneOpsInformationDto.handoverEarliest',
                        },
                        {
                            name: 'handoverLatest',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(HandoverTime),
                            labelKey: 'LaneOpsInformationDto.handoverLatest',
                        },
                        {
                            name: 'containerSelectionMax',
                            componentName: 'IntegerInput',
                            labelKey: 'LaneOpsInformationDto.containerSelectionMax',
                        },
                        {
                            name: 'orderInstructions',
                            componentName: 'LongText',
                            labelKey: 'LaneOpsInformationDto.orderInstructions',
                        },
                        ...deliveryServiceType === DeliveryServiceType.PICKUP ? []
                            : [
                                {
                                    name: 'fwdPos',
                                    componentName: 'LocationObjectSelector',
                                    labelKey: 'LaneOpsInformationDto.fwdPos',
                                },
                                {
                                    name: 'fwdPosContacts',
                                    componentName: 'MultipleContactObjectSelector',
                                    labelKey: 'LaneOpsInformationDto.fwdPosContacts',
                                },
                            ] as NestedField<keyof Lane>[],
                    ],
                },
                {
                    id: 'positioning2',
                    fields: [
                        {
                            name: 'originSc',
                            componentName: 'LocationObjectSelector',
                            filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'LaneOpsInformationDto.originSc',
                        },
                        {
                            name: 'originScContacts',
                            componentName: 'MultipleContactObjectSelector',
                            labelKey: 'LaneOpsInformationDto.originScContacts',
                        },
                        {
                            name: 'originScBackup',
                            componentName: 'LocationObjectSelector',
                            filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'LaneOpsInformationDto.originScBackup',
                        },
                        {
                            name: 'originScBackupContacts',
                            componentName: 'MultipleContactObjectSelector',
                            labelKey: 'LaneOpsInformationDto.originScBackupContacts',
                        },
                        ...deliveryServiceType === DeliveryServiceType.PICKUP ? []
                            : [
                                {
                                    name: 'fwdPosBackup',
                                    componentName: 'LocationObjectSelector',
                                    labelKey: 'LaneOpsInformationDto.fwdPosBackup',
                                },
                                {
                                    name: 'fwdPosBackupContacts',
                                    componentName: 'MultipleContactObjectSelector',
                                    labelKey: 'LaneOpsInformationDto.fwdPosBackupContacts',
                                },
                            ] as NestedField<keyof Lane>[],
                    ],
                },
            ],
        },
        {
            title: 'Reverse Logistics',
            className: 'reverseLogistics',
            fields: [
                {
                    id: 'reverseLogistics1',
                    fields: [
                        {
                            name: 'reqCustomsCoord',
                            componentName: 'Checkbox',
                            labelKey: 'LaneOpsInformationDto.reqCustomsCoord',
                        },
                        {
                            name: 'fwdCustoms',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            required: reqCustomsCoord,
                            labelKey: 'LaneOpsInformationDto.fwdCustoms',
                        },
                        {
                            name: 'fwdCustomsContacts',
                            componentName: 'MultipleContactObjectSelector',
                            required: reqCustomsCoord,
                            labelKey: 'LaneOpsInformationDto.fwdCustomsContacts',
                        },
                    ],
                },
                {
                    id: 'reverseLogistics2',
                    fields: isDropOffSkyCell ? [] : isNotDropOffSkyCellFields,
                },
            ],
        },
    ]
}

export default getFields
