import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        checkbox: {
            padding: `0 0 0 ${theme.spacing(2)} !important`,
        },
        input: {
            backgroundColor: theme.palette.secondary[50],
            paddingLeft: theme.spacing(0.25),
        },
        inputLabel: {
            display: 'none',
        },
    }
})

export default useStyles
