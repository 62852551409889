import {
    TableResponse,
} from 'app/types/request.types'

const responseParser: Record<string, any> = {
    table: (
        promise: Promise<{data: {matches?: any, items: any, hasNextPage?: boolean }}>,
    ): Promise<TableResponse> => {
        return promise.then((resp) => {
            return {
                meta: {
                    matchedresults: resp.data?.matches,
                },
                hasNextPage: resp.data?.hasNextPage,
                data: resp.data.items,
                matches: resp.data?.matches,
            }
        })
    },
    entity: (promise: Promise<{data: any}>): Promise<Record<string, any>> => {
        return promise.then((resp) => {
            return resp.data
        })
    },
}

export default responseParser
