import getSelectedAssetsMap from '../BookedContainers/ValidationBookedContainers/getSelectedAssetsMap'

const getSelectedContainers = (selectedQuantities, selectedContainers, containers) => {
    const selectedAssetsMap = getSelectedAssetsMap({
        selectedContainers,
        isInternalType: true,
    })

    const selectedQuantitiesMap = selectedQuantities.reduce((acc, item) => {
        const {
            containerTypeContainerModel,
            quantity,
        } = item

        const quantitySelectedAssets = selectedAssetsMap[containerTypeContainerModel]
            ? selectedAssetsMap[containerTypeContainerModel]
            : 0

        return {
            ...acc,
            [containerTypeContainerModel]: quantity - quantitySelectedAssets,
        }
    }, {})

    return containers
        .filter(({
            containerTypeContainerModel,
        }) => {
            if (selectedQuantitiesMap[containerTypeContainerModel] > 0) {
                selectedQuantitiesMap[containerTypeContainerModel] -= 1

                return true
            }
            return false
        })
        .map(({
            id,
        }) => { return id })
}

export default getSelectedContainers
