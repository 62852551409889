const getCategoryTypePermission = (
    accessRights,
    permissionsList,
    permissionType,
) => {
    if (accessRights) {
        const listOfCategoriesTypes = permissionsList.map((permission) => {
            return Object.prototype.hasOwnProperty.call(accessRights, permission)
                ? Object.entries(accessRights[permission]).map((category) => {
                    return category[1][permissionType] ? category[0] : null
                }).filter(Boolean)
                : null
        }).filter(Boolean).flat(1)

        const uniqueCategoriesTypes = [...new Set(listOfCategoriesTypes)]

        return uniqueCategoriesTypes
    }
    return false
}

export default getCategoryTypePermission
