import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        fee: {
            padding: '5px !important',
            width: '10%',
        },
        feeChargeability: {
            padding: '5px !important',
            width: '20%',
        },
        productType: {
            padding: '5px !important',
            width: '10%',
        },
        delete: {
            padding: '5px !important',
            width: '3%',
        },
        descriptionCell: {
            padding: '5px !important',
        },
        tableCell: {
            padding: '5px !important',
        },
        inputCentered: {
            textAlign: 'center',
        },
        cellSmall: {
            maxWidth: 80,
        },
        deleteIcon: {
            cursor: 'pointer',
            color: theme.palette.secondary[600],
        },
        input: {
            '&&': {
                backgroundColor: theme.palette.secondary[50],
                paddingLeft: theme.spacing(0.25),
            },
        },
        description: {
            width: '100%',
        },
    }
})

export default useStyles
