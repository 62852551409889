const config = {
    requestFn: () => {
        return ({
            data, url,
        }) => {
            return {
                url,
                method: 'POST',
                data: {
                    ...data,
                },
            }
        }
    },
}

export default config
