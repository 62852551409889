import React, {
    useCallback, useState,
} from 'react'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import {
    RejectedReasons,
} from 'app/shared-components/LaneFees/LaneFeesForm/reasons.types'
import Label from 'app/shared-components/Label'
import EnumSelector from 'app/shared-components/EnumSelector'
import useEnumValues from 'app/hooks/useEnumValues'
import InputSingleline from 'app/shared-components/InputSingleline'
import useLabel from 'app/hooks/useLabel'
import useStyles from './RejectLaneFeesConfirmationDialog.styles'

const RejectLaneFeesConfirmationDialog = ({
    open,
    close,
    laneFees,
    action,
}: {
    laneFees: {id: number}[],
    open: boolean,
    close: () => void,
    action: (a: {
        laneFees: { id: number }[],
        rejectedReason: string,
        rejectedReasonComment: string
    }) => void
}) => {
    const {
        classes,
    } = useStyles()
    const rejectedReasons = useEnumValues()(RejectedReasons)
    const l = useLabel()

    const [
        rejectedReason,
        setRejectedReason,
    ] = useState(null)

    const [
        rejectedReasonComment,
        setRejectedReasonComment,
    ] = useState(null)

    const handleClose = useCallback((positive) => {
        if (positive) {
            action({
                rejectedReason,
                rejectedReasonComment,
                laneFees,
            })
        }
        close()
    }, [
        close,
        action,
        rejectedReason,
        rejectedReasonComment,
        laneFees,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle="Add Rejected Reason"
            positiveLabel="Save"
            positiveLabelDisabled={!rejectedReason || !rejectedReasonComment}
            negativeLabel="Cancel"
        >
            <div className={classes.container}>
                <div className={classes.child}>
                    <Label
                        title={l('LaneFees.rejectedReason')}
                    />
                    <EnumSelector
                        required
                        value={rejectedReason}
                        allowedValues={rejectedReasons}
                        onChange={setRejectedReason}
                        name="rejectedReason"
                    />
                </div>
                <div className={classes.child}>
                    <InputSingleline
                        multiline
                        required
                        rows={4}
                        title={l('LaneFeesUpdateDto.rejectedReasonComment')}
                        value={rejectedReasonComment}
                        onChange={setRejectedReasonComment}
                    />
                </div>
            </div>
        </ConfirmationDialog>
    )
}

export default RejectLaneFeesConfirmationDialog
