import {
    makeStyles,
} from 'app/tss'

const origin = 'origin'
const destination = 'destination'
const orderInfo = 'orderInfo'
const orderStatus = 'orderStatus'
const previousOrders = 'previousOrders'
const selectedAsset = 'selectedAsset'
const orderStatusHistory = 'orderStatusHistory'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapperPreviousOrders: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${orderInfo} ${selectedAsset} ${orderStatusHistory} ${orderStatus}"
                "${origin} ${origin} ${destination} ${destination}"
                "${previousOrders} ${previousOrders} ${previousOrders} ${previousOrders}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${orderInfo} ${selectedAsset} ${orderStatusHistory} ${orderStatus}"
                "${origin} ${origin} ${destination} ${destination}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        previousOrders: {
            gridArea: previousOrders,
            minHeight: 300,
        },
        origin: {
            gridArea: origin,
        },
        selectedAsset: {
            gridArea: selectedAsset,
            minHeight: 310,
        },
        destination: {
            gridArea: destination,
        },
        orderInfo: {
            gridArea: orderInfo,
        },
        wrapper: {
            '&&': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        root: {
            minHeight: 300,
            marginTop: theme.spacing(2),
            '&:not(:last-child)': {
                marginBottom: theme.spacing(2),
            },
        },
        title: {
            '&&': {
                paddingLeft: theme.spacing(2),
            },
        },
    }
})

export default useStyles
