import {
    FieldInCard,
} from 'app/types/form.types'

import {
    OrderCommentType,
} from '../orderComment.types'

const fields: FieldInCard<keyof OrderCommentType>[] = [{
    title: 'New Comment',
    fields: [{
        name: 'comment',
        componentName: 'LongText',
        required: true,
        labelKey: 'OrderComment.comment',
    }],
}]

export default fields
