import React, {
    useMemo, useRef,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import Card from 'app/shared-components/Card'
import ChipSelect from 'app/shared-components/ChipSelect'
import {
    SensorCode,
} from 'app/types/enums'

type Props = {
    value: {
        options: {
            value: string,
            label: string,
        }[],
        title: string,
        name: string,
    }[],
    onChange: (...args: any[]) => void,
    title?: string,
    className?: string,
    allowedSensorCodes?: SensorCode[],
}

const defaultProps = {
    title: '',
    className: '',
    allowedSensorCodes: undefined,
}

const LoggerOptions = (props: Props) => {
    const {
        value,
        onChange,
        title: titleCard,
        className,
        allowedSensorCodes,
    } = props

    const getAllowedValues = useEnumValues()(SensorCode)

    const defaultValue = useRef(value)

    const loggerOptions = useMemo(() => {
        return {
            loggerTypes: defaultValue.current.find((a) => {
                return a.name === 'loggerTypes'
            })?.options,
            loggerPositions: defaultValue.current.find((a) => {
                return a.name === 'loggerPositions'
            })?.options,
            sensorCodes: allowedSensorCodes ? allowedSensorCodes.map((code) => {
                return {
                    value: code,
                    label: getAllowedValues[code],
                }
            }) : Object.entries(getAllowedValues).map(([
                key,
                label,
            ]) => {
                return {
                    value: key,
                    label,
                }
            }),
        }
    }, [
        getAllowedValues,
        allowedSensorCodes,
    ])

    return (
        <Card
            title={titleCard}
            className={className}
        >
            {value.map(({
                name,
                title,
                options,
            }) => {
                return (
                    <ChipSelect
                        key={`${name}-${title}`}
                        data-testid={`chip-${name}`}
                        title={title}
                        name={name}
                        options={loggerOptions[name]}
                        value={options}
                        onChange={onChange}
                    />
                )
            })}
        </Card>
    )
}

LoggerOptions.defaultProps = defaultProps

export default LoggerOptions
