import React from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
} from '@mui/material'
import {
    Link,
} from 'react-router-dom'
import clsx from 'clsx'

import useStyles from './Menu.style'

const propTypes = {
    selectedId: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([
            PropTypes.shape({
                render: PropTypes.func.isRequired,
            }),
            PropTypes.func,
        ]).isRequired,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ).isRequired,
    })).isRequired,
}

const defaultProps = {
    selectedId: '',
}

const Menu = ({
    selectedId,
    items,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className="h-full">
            {items.map(({
                id,
                icon: Icon,
                links,
                className,
            }) => {
                return (
                    <div
                        key={`menu-item-${id}`}
                        className={clsx(
                            classes.item,
                            selectedId === id && classes.activeLink,
                        )}
                    >
                        <Icon
                            className={clsx(
                                classes.icon,
                                classes[className],
                            )}
                        />
                        <div className={classes.linksContainer}>
                            {links.map(({
                                title,
                                url,
                            }) => {
                                return (
                                    <div
                                        key={`menu-link-${title}`}
                                        className={classes.linkContainer}
                                    >
                                        <Link
                                            className={classes.link}
                                            to={url}
                                        >
                                            <Typography
                                                variant="h5"
                                                className={classes.linkLabel}
                                            >
                                                {title}
                                            </Typography>
                                        </Link>
                                        <div className={classes.underline} />
                                    </div>
                                )
                            }).flat()}
                        </div>
                    </div>
                )
            })}
            <div className={classes.logoContainer}>
                <Link to="/">
                    <img
                        className={classes.logo}
                        src="assets/images/logos/logo_negative.png"
                        alt="logo"
                    />
                </Link>
            </div>
        </div>
    )
}

Menu.propTypes = propTypes
Menu.defaultProps = defaultProps

export default Menu
