const temperatureRangeColumns = [
    [
        'number',
        'Temperature Range Minimum',
    ],
    [{
        type: 'string', role: 'annotation',
    }],
    [
        'number',
        'Temperature Range Maximum',
    ],
    [{
        type: 'string', role: 'annotation',
    }],
]

export default temperatureRangeColumns
