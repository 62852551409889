import useDelayedLoadOptions, {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import {
    LaneStatus,
} from 'app/types/enums'

import requestConfig from '../laneSelector.request'

const useLaneRequest = (): { loadOptions: (options: DelayedLoadOptions) => Promise<any> } => {
    const loadOptions = useDelayedLoadOptions({
        url: requestConfig.Selector,
        includeFilters: {
            laneStatus: [LaneStatus.IMPLEMENTED],
        },
    })

    return {
        loadOptions,
    }
}

export default useLaneRequest
