import simpleApi from 'app/services/simpleApi'
import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    RejectContainers: {
        mutationFn: ({
            token,
            orderNumber,
            orderVersion,
            selectedContainers,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/selected-container/batch-rejections`,
                method: 'PATCH',
                data: {
                    version: orderVersion,
                    selectedContainers,
                },
                token,
            })
        },
    },
    RebookContainers: {
        mutationFn: ({
            token,
            orderNumber,
            orderVersion,
            selectedContainers,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/selected-container/batch-unrejections`,
                method: 'PATCH',
                data: {
                    version: orderVersion,
                    selectedContainers,
                },
                token,
            })
        },
    },
}

export default config
