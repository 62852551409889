import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission'

import ActivityRecordOverview from './ActivityRecordOverview'
import ActivityRecordUpdate from './ActivityRecordUpdate'
import ActivityRecordCreate from './ActivityRecordCreate'
import tableConfig from './activityRecords.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: ActivityRecordOverview,
}
const updateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data-update',
    Component: ActivityRecordUpdate,
}

const ActivityRecords = () => {
    const canCreate = useHasPermission([
        'relationships_ops',
        'relationships_sales',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: updateTab,
            permission: true,
        },
    ])

    return (
        <SkyNetTable
            createForm={canCreate ? ActivityRecordCreate : null}
            name="ActivityRecord"
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
        />
    )
}

export default ActivityRecords
