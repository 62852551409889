import {
    makeStyles,
} from 'app/tss'

const handover = 'handover'
const collection = 'collection'
const additionalDetails = 'additionalDetails'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${handover} ${handover} ${collection} ${collection}"
                "${additionalDetails} ${additionalDetails} ${additionalDetails} ${additionalDetails}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        handover: {
            gridArea: handover,
        },
        additionalDetails: {
            gridArea: additionalDetails,
        },
        collection: {
            gridArea: collection,
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
        radio: {
            flexDirection: 'column',
        },
    }
})

export default useStyles
