import React from 'react'

import Select from 'app/shared-components/Select'
import containerTemperatureRanges from 'app/utils/containerTemperatureRanges'
import useCheckTransportPlanerRoles from 'app/Apps/TransportPlanner/hooks/useCheckTransportPlanerRoles'
import {
    ContainerTypeSelectionItemType,
} from 'app/Apps/TransportPlanner/Simulation/simulation.types'

import amountCoolingGelOptionsMap from '../amountCoolingGelOptions'
import useStyles from './ContainerTypeSelectionSpecialItem.styles'
import getTemperatureString from '../utils/getTemperatureString'

type Props = {
    value: ContainerTypeSelectionItemType,
    onChange: (newVal: ContainerTypeSelectionItemType, isFormChange: boolean) => void,
    isSelected: boolean,
}

const ContainerTypeSelectionSpecialItem = ({
    value,
    onChange,
    isSelected,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        tempRange,
    } = value

    const {
        isRolePlannedUpdate,
    }: {
        isRolePlannedUpdate: boolean,
    } = useCheckTransportPlanerRoles()

    const setAmountCoolingGel = (newValue: number): void => {
        onChange({
            ...value,
            amountCoolingGel: newValue,
        }, true)
    }

    const dryIceOptions: [number, string][] = amountCoolingGelOptionsMap[value.productType].options

    if (!isSelected) {
        return (
            <div>
                {getTemperatureString(containerTemperatureRanges[tempRange])}
            </div>
        )
    }

    return (
        <div className={classes.buttons}>
            <div className={classes.selectContainer}>
                <div className={classes.noWrapText}>
                    Dry Ice:
                </div>
                <div>
                    <Select
                        data={dryIceOptions}
                        value={value.amountCoolingGel}
                        name="amountCoolingGel"
                        disabled={!isRolePlannedUpdate}
                        disableHelperText
                        disableSorting
                        onChange={setAmountCoolingGel}
                        className={{
                            overrideSelect: classes.selectInput,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ContainerTypeSelectionSpecialItem
