import {
    UseRequestConfig,
} from 'app/types/request.types'

const requestConfig: Record<string, UseRequestConfig> = {
    GetOptions: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'order/internal-transport/reverse-logistic/options',
                method: 'POST',
                ...params,
            }
        },
    },
    GetOrderSteps: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'order/internal-transport/reverse-logistic/population/orderstep',
                method: 'POST',
                data: params,
            }
        },
    },
}

export default requestConfig
