import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            cursor: 'pointer',
            color: theme.palette.secondary[600],
        },
    }
})

export default useStyles
