import {
    NotificationTypes,
} from 'app/types/enums'
import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    NotificationTableViewFields,
} from '../utils/OrderNotifications.types'

const getFields = ({
    notificationType,
    notificationName,
    orderNumber,
    id,
}: {
    notificationType: NotificationTypes,
    notificationName: string,
    orderNumber: string,
    id: number,
}): DTOCardFormField<NotificationTableViewFields>[] => {
    const optionalSendTimeFields: NestedField<NotificationTableViewFields>[] = [
        {
            name: 'sendSecondTime',
            componentName: 'SendTime',
            orderNumber,
            notificationId: id,
            labelKey: 'NotificationTableView.sendSecondTime',
        },
        {
            name: 'sendThirdTime',
            componentName: 'SendTime',
            orderNumber,
            notificationId: id,
            labelKey: 'NotificationTableView.sendThirdTime',
        },
    ]

    const optionalSentTimeOnFields: NestedField<NotificationTableViewFields>[] = [
        {
            name: 'sentSecondTimeOn',
            componentName: 'DateTimeSelect',
            disabled: true,
            labelKey: 'NotificationTableView.sentSecondTimeOn',
        },
        {
            name: 'sentThirdTimeOn',
            componentName: 'DateTimeSelect',
            disabled: true,
            labelKey: 'NotificationTableView.sentThirdTimeOn',
        },
    ]

    return [
        {
            title: 'Notification Details',
            className: 'bookingConfirmation',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'sendFirstTime',
                            componentName: 'SendTime',
                            orderNumber,
                            notificationId: id,
                            labelKey: 'NotificationTableView.sendFirstTime',
                        },
                        ...notificationType === NotificationTypes.NOTIFICATION
                            ? []
                            : optionalSendTimeFields,
                        {
                            name: 'recipients',
                            componentName: 'RecipientSelector',
                            labelKey: 'NotificationTableView.recipients',
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'sentFirstTimeOn',
                            componentName: 'SentFirstTimeOn',
                            notificationName,
                            orderNumber,
                            disabled: true,
                            id,
                            labelKey: 'NotificationTableView.sentFirstTimeOn',
                        },
                        ...notificationType === NotificationTypes.NOTIFICATION
                            ? []
                            : optionalSentTimeOnFields,
                    ],
                },
                {
                    id: 'column3',
                    fields: [{
                        name: 'customAttachments',
                        componentName: 'AttachmentsObjectSelector',
                        labelKey: 'NotificationTableView.customAttachments',
                    }],
                },
            ],
        },
        {
            title: 'Default Attachments',
            className: 'defaultAttachments',
            fields: [{
                name: 'defaultAttachments',
                componentName: 'DefaultAttachments',
                labelKey: '',
            }],
        },
    ]
}

export default getFields
