import {
    useCallback,
    useMemo,
} from 'react'
import {
    useJWTToken,
} from 'app/Auth'

import skyMindDtoApi from '../services/skyMindDtoApi'
import getBaseRequestUrl from './utils/getBaseRequestUrl'

/* WARNING: This hook not supposed to be used without SkyNetTable */
const ROWS_PER_PAGE = 25

type Params = {
    api?: (params: any) => any,
    customUrl?: string,
    method?: string,
    modifier?: (params: any) => any,
    name: string,
    rowsPerPage?: number,
    version?: string,
    paginationModeMatches?: boolean,
}

const useLoadDtoForSkyNetTable = ({
    paginationModeMatches,
    api = skyMindDtoApi,
    customUrl,
    method,
    modifier,
    name,
    rowsPerPage = ROWS_PER_PAGE,
    version = 'v1',
}: Params) => {
    const token = useJWTToken()
    const url = useMemo(() => {
        return getBaseRequestUrl(name, customUrl)
    }, [
        customUrl,
        name,
    ])

    return useCallback((params: {
        page: number,
        urlQuery?: string,
    } = {
        page: 1,
    }) => {
        const {
            urlQuery = 'all',
            page,
        } = params

        const request = api({
            method: method || 'POST',
            url: customUrl || `${url}/${urlQuery}`,
            token,
            rows: page === 1 ? (rowsPerPage * 2) : rowsPerPage,
            version,
            data: {
                paginationModeMatches,
            },
            ...params,
        })

        if (modifier) {
            return request.then(modifier)
        }

        return request
    }, [
        api,
        method,
        customUrl,
        url,
        token,
        rowsPerPage,
        version,
        paginationModeMatches,
        modifier,
    ])
}

export default useLoadDtoForSkyNetTable
