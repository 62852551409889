import React, {
    useState,
} from 'react'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'

import FormWithControls from 'app/shared-components/FormWithControls'
import fields from './createContainer.fields'
import {
    CreateContainerForm,
} from '../../containers.types'
import useStyles from './CreateContainer.style'
import containerRequests from '../../Containers.request'

type Props = {
    onClose: () => void
}

const INFO_MSG = 'The counter is the second part of the serial number: 012-10202. Please note that the container should not yet exist in the system, otherwise it will not be created.'

const CreateContainer = ({
    onClose,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const [
        value,
        setValue,
    ] = useState<CreateContainerForm>()

    const fieldsWithClasses = useFieldsWithClassName(fields, classes)

    return (
        <>
            <div className={classes.infoMsg}>
                <img
                    src="assets/images/info.svg"
                    alt="info"
                />
                <div className={classes.infoText}>{INFO_MSG}</div>
            </div>
            <FormWithControls
                name="ContainerAutoCreateForm"
                fields={fieldsWithClasses}
                value={value}
                setExternalValue={setValue}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                requestParams={containerRequests.batchCreate()}
                onSuccess={onClose}
            />
        </>
    )
}

export default CreateContainer
