import {
    useMemo,
} from 'react'

import {
    StepProgress,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    SubstepName,
} from 'app/types/enums'

const useGetCurrentStepProgress = (stepProgress: StepProgress[]): SubstepName => {
    return useMemo(() => {
        return (stepProgress.find(({
            substepCompleted,
        }) => {
            return !substepCompleted
        })?.substepName || stepProgress[stepProgress.length - 1].substepName)
    }, [stepProgress])
}

export default useGetCurrentStepProgress
