import React, {
    useCallback,
    useMemo, useState,
} from 'react'
import Button from 'app/shared-components/Button'
import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useDeleteLoggerPairing from '../hooks/useDeleteLoggerPairing'
import {
    LoggerFormData,
} from '../LoggerForm/LoggerForm.types'
import loggerPairingRequests from '../LoggerExchange.requests'
import getFields from './LoggerUpdateForm.fields'
import useStyles from './LoggerUpdateForm.styles'

const CONFIRM_MSG = 'You are about to delete this item permanently,\nare you sure?'

const LoggerUpdateForm = ({
    serialNumber,
    data,
    refresh,
}: {
    data: LoggerFormData,
    serialNumber: string,
    refresh: () => void
}) => {
    const {
        classes,
    } = useStyles()
    const getEnumValues = useEnumValues()
    const [
        confirmation,
        setConfirmation,
    ] = useState(false)
    const value = useMemo(() => {
        return {
            ...data,
            loggerNumber: data?.logger?.loggerNumber,
            loggerType: data?.logger?.loggerType,
        }
    }, [data])

    const fields = useFieldsWithClassName(getFields({
        getEnumValues,
        removedOnDisabled: Boolean(data.removedOn),
        loggerNumber: data?.logger?.loggerNumber,
    }), classes)

    const {
        mutate: deleteLoggerPairing,
    } = useDeleteLoggerPairing(refresh)

    const onConfirm = useCallback((positive) => {
        if (positive) {
            deleteLoggerPairing({
                serialNumber, id: data?.id,
            })
        }
        setConfirmation(false)
    }, [
        deleteLoggerPairing,
        serialNumber,
        data?.id,
    ])

    const customButtons = useMemo(() => {
        return [<Button
            label="Delete"
            name="delete"
            onClick={() => { return setConfirmation(true) }}
        />]
    }, [])

    return (
        <>
            <FormWithControls
                name="LoggerInformationDto"
                value={value}
                fields={fields}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                requestParams={loggerPairingRequests.loggerPairingUpdate({
                    serialNumber,
                })}
                customButtons={customButtons}
                exists
                createEnabled={!data.removedOn}
                onSuccess={refresh}
            />
            <ConfirmationDialog
                dialogTitle="Delete Confirmation"
                open={confirmation}
                dialogContent={CONFIRM_MSG}
                handleClose={onConfirm}
                classNames={{
                    title: classes.title,
                    content: classes.title,
                }}
            />
        </>

    )
}

export default LoggerUpdateForm
