import {
    useCallback,
} from 'react'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

export default function usePasteEvent(configId: string) {
    const {
        onError,
    } = useHandleStatusRequest({
        id: `${configId}-spreadsheet`,
    })

    return useCallback((ref: { eventHandlers: { pasteHandler: (e: ClipboardEvent) => void} }) => {
        const oldPasteEventHandler = ref?.eventHandlers?.pasteHandler

        if (ref?.eventHandlers) {
            // eslint-disable-next-line no-param-reassign
            ref.eventHandlers.pasteHandler = (event) => {
                try {
                    const dataToPaste = event.clipboardData.getData('text/plain')
                    const htmlDataToPaste = event.clipboardData.getData('text/html')

                    const isWindows = navigator.platform.includes('Win')

                    if (htmlDataToPaste.includes('xmlns:v="urn:schemas-microsoft-com:vml"') && isWindows) {
                        const slicedData = dataToPaste.slice(0, -2)

                        event.clipboardData.setData('text/plain', slicedData)
                    }

                    if (oldPasteEventHandler) oldPasteEventHandler(event)
                } catch (e) {
                    onError({
                        response: {
                            data: ['Paste was not successful, please try again or report the issue'],
                            statusCode: 500,
                        },
                    })
                }
            }
        }
    }, [onError])
}
