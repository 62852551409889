/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import GroupWithCard from './GroupWithCard'
import GroupNoCard from './GroupNoCard'
import GroupSimple from './GroupSimple'
import withFieldsCalc from '../withFieldsCalc'

const fieldTypes = PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    titleLink: PropTypes.string,
    className: PropTypes.string,
    fields: PropTypes.any, // eslint-disable-line
}))

const propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRrequired,
        title: PropTypes.string,
        className: PropTypes.string,
        fields: fieldTypes,
    })).isRequired,
    classNames: PropTypes.shape({
        gridCardWrapper: PropTypes.string,
        title: PropTypes.string,
    }),
    noCard: PropTypes.bool,
    wrapper: PropTypes.oneOf([
        'noCard',
        'simple',
        'card',
    ]),
}

const defaultProps = {
    classNames: {},
    noCard: false,
    wrapper: 'card',
}

const WRAPPER_MAP = {
    noCard: GroupNoCard,
    simple: GroupSimple,
    card: GroupWithCard,
}

const Group = (props) => {
    const {
        wrapper,
    } = props

    const Comp = WRAPPER_MAP[wrapper]

    return <Comp {...props} />
}

Group.propTypes = propTypes
Group.defaultProps = defaultProps

export default withFieldsCalc(Group)
