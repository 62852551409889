import {
    makeStyles,
} from 'app/tss'

const containers = 'containers'
const location = 'location'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapperContainers: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${containers} ${containers} ${containers} ${location}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
            marginBottom: theme.spacing(2),
        },

        gridWrapperGeneralFreight: {
            display: 'grid',
            marginBottom: theme.spacing(2),
        },
        requiredContainers: {
            gridArea: containers,
            gridTemplateColumns: '1fr 1fr 1fr',
        },

        root: {
            minHeight: 300,
            '&:not(:last-child)': {
                marginBottom: theme.spacing(2),
            },
        },
        wrapper: {
            '&&': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        title: {
            '&&': {
                paddingLeft: theme.spacing(2),
            },
        },
    }
})

export default useStyles
