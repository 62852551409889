import {
    UseRequestConfig,
} from 'app/types/request.types'
import simpleApi from 'app/services/simpleApi'

const request: Record<string, UseRequestConfig> = {
    BillingCompany: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `billingcompany/${id}`,
            }
        },
    },
    Archive: {
        mutationFn: ({
            id,
            token,
        }) => {
            return simpleApi({
                url: `billingcompany/${id}/archive`,
                method: 'PATCH',
                token,
            })
        },
    },
    Restore: {
        mutationFn: ({
            id,
            token,
        }) => {
            return simpleApi({
                url: `billingcompany/${id}/restore`,
                method: 'PATCH',
                token,
            })
        },
    },
}

export default request
