import moment from 'moment'

import dateTimeZonedMask from './dateTimeZonedMask'

const strToMoment = (str: string, mask: string = dateTimeZonedMask) => {
    if (!str) {
        return null
    }
    const momentObj = moment(str, mask)

    return momentObj.isValid() ? momentObj : null
}

export default strToMoment
