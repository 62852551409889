import {
    RequestConfig,
} from 'app/types/request.types'

type Request = {
archive: ({
    id,
}: {
    id: number,
}) => RequestConfig,
restore: ({
    id,
}: {
    id: number,
}) => RequestConfig,
}

const request: Request = {

    archive: ({
        id,
    }) => {
        return {
            url: `quote/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `quote/${id}/restore`,
            method: 'PATCH',
        }
    },
}

export default request
