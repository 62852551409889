import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    PositionFields,
    PositionType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getFields = (
    {
        positionCodeAllowedValues,
        getAllowedValues,
    }: {positionCodeAllowedValues: Record<string, any>,
        getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
    },
): DTOCardFormField<PositionFields>[] => {
    return [{
        id: 'positions',
        title: 'Positions',
        className: 'positions',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'positionType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(PositionType),
                        useDropdown: true,
                        required: true,
                        labelKey: 'Position.positionType',
                    },
                    {
                        name: 'positionCode',
                        componentName: 'EnumSelector',
                        allowedValues: positionCodeAllowedValues,
                        useDropdown: true,
                        required: true,
                        labelKey: 'Position.positionCode',
                        showOutOfRangeOption: true,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [{
                    name: 'quantity',
                    className: 'quantity',
                    componentName: 'IntegerInput',
                    min: 0,
                    required: true,
                    labelKey: 'Position.quantity',
                }],
            },
            {
                id: 'column3',
                fields: [{
                    name: 'unitPrice',
                    className: 'unitPrice',
                    componentName: 'IntegerInput',
                    min: 0,
                    required: true,
                    labelKey: 'Position.unitPrice',
                }],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'positionDescription',
                    className: 'positionDescription',
                    componentName: 'LongText',
                    rows: 5,
                    labelKey: 'Position.positionDescription',
                }],
            },
        ],
    }]
}

export default getFields
