import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

import Simulation from '../Simulation'

const NewSimulation = () => {
    const {
        state,
    } = useLocation()

    return (
        <Simulation data={state || undefined} />
    )
}

export default NewSimulation
