import React, {
    useCallback,
} from 'react'
import RichTable from 'app/shared-components/RichTableReduxWrapper'

import SharedContextProvider from 'app/shared-components/SharedContext'

import columns from './OrderNotifications.columns'
import useStyles from './OrderNotifications.style'
import OrderNotificationForm from './OrderNotificationForm'
import useGetOrderNotifications from './hooks/useGetOrderNotifications'

type Props = {
    className?: string,
    disabled?: boolean,
    orderNumber: string
    url?: string,
    includeFilters?: {
        orderId?: number[],
        triggerType?: string[],
    },
}

const defaultProps: Partial<Props> = {
    className: '',
    includeFilters: undefined,
    disabled: false,
    url: undefined,
}

const DOMAIN_NAME = 'NotificationTableView'

const OrderNotifications = ({
    className,
    includeFilters,
    orderNumber,
    disabled,
    url,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        onParamsChange,
        load,
        refetch,
        refresh,
    } = useGetOrderNotifications({
        url, includeFilters,
    })

    const getDetailPanel = useCallback(({
        rowProps,
        closeOpenedRow,
    }): JSX.Element => {
        return (
            <OrderNotificationForm
                rowProps={rowProps}
                closeOpenedRow={closeOpenedRow}
                onSuccess={refresh}
                orderNumber={orderNumber}
                disabled={disabled}
            />
        )
    }, [
        disabled,
        refresh,
        orderNumber,
    ])

    return (
        <SharedContextProvider
            value={{
                refetch,
            }}
        >
            <RichTable
                configName="OrderNotificationsTable"
                name={DOMAIN_NAME}
                load={load}
                onParamsChange={onParamsChange}
                columns={columns}
                className={classes.tableContent}
                classNames={{
                    root: className,
                    contentWrapper: classes.tableContentWrapper,
                }}
                detailPanel={getDetailPanel}
            />
        </SharedContextProvider>
    )
}

OrderNotifications.defaultProps = defaultProps

export default OrderNotifications
