import React, {
    useCallback,
} from 'react'

import Typeahead from 'app/shared-components/Typeahead'
import useGetOpportunityById from 'app/Apps/AccountManagement/Opportunities/hooks/useGetOpportunityById'
import useOpportunityLoadOptions from './hooks/useOpportunityLoadOptions'

const defaultProps = {
    name: '',
    value: undefined,
    title: 'Opportunity Number',
    disabled: false,
    className: '',
    required: false,
}

const OpportunitiesSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
}: {
    name?: string,
    value?: {
        id: number,
    },
    onChange: (value: {id: number}) => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
}) => {
    const {
        data: labelData,
    } = useGetOpportunityById(value?.id)

    const loadOptions = useOpportunityLoadOptions()

    const onChangeValue = useCallback((newValue) => {
        const convertedValue = newValue ? {
            id: newValue,
        } : undefined

        onChange(convertedValue)
    }, [onChange])

    return (
        <Typeahead
            name={name}
            title={title}
            className={className}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="opportunityNumber"
            value={value?.id}
            disabled={disabled}
            isRequired={required}
        />
    )
}

OpportunitiesSelector.defaultProps = defaultProps

export default OpportunitiesSelector
