import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    OrderCommentType,
} from '../orderComment.types'
import requestConfig from '../orderComment.request'

const useGetOrderComments = (orderNumber) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<OrderCommentType>({
        key: RequestKeys.getOrderComments,
        params: {
            orderNumber,
        },
        config: requestConfig.filterRequestAll,
        keepPreviousData: true,
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}

export default useGetOrderComments
