import getUniqueId from 'app/utils/getUniqueId'
import {
    Column,
} from 'app/types/simpletable.types'
import {
    Location,
} from '../Locations.types'

export type LocationDuplication = Pick<Location, 'locationName' | 'addressLine1'> & {
    companyName: string
}

const columns = (l): Column<keyof LocationDuplication>[] => {
    return [
        {
            fieldName: 'locationName',
            label: l('Location.locationName'),
            id: getUniqueId(),
        },
        {
            fieldName: 'addressLine1',
            label: l('Location.addressLine1'),
            id: getUniqueId(),
        },
        {
            fieldName: 'companyName',
            label: l('Location.company'),
            id: getUniqueId(),
        },
    ]
}

export default columns
