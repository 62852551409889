import React from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import ChangeHistory from 'app/shared-components/ChangeHistory'

import config from '../Damages.requests'

const DamageChangeHistory = ({
    id,
}: {id: number}) => {
    return (
        <ChangeHistory
            id={id}
            key={RequestKeys.getDamageModifications}
            config={config.modifications}
            configName="DamageChangeHistoryTable"
            name="DamageChangeHistory"
            labelPrefixForChangedColumn={['Damages']}
        />
    )
}

export default DamageChangeHistory
