import EnumListMultipleColored from './EnumListMultipleColored'

export const MultipleEnumComponents = {
    EnumListMultipleColored,
}

export type MultipleEnumComponentConfig = {
    componentName: keyof typeof MultipleEnumComponents,
    enumsConfig: {
        name: string,
        allowedValuesEnum: Record<string, any>,
        colorConfig?: Record<string, any>,
        renderAs: 'tooltip' | 'chip'
    }[]
}
