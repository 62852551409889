import {
    RequestConfig,
} from 'app/types/request.types'

type AreaSelectorRequests = {
    domainName: string,
    filterRequest: ({
        id,
        params,
    }: {id:number, params: any}) => RequestConfig,
}
const locationRequests: AreaSelectorRequests = {
    domainName: 'Area',
    filterRequest: ({
        id,
        params,
    }) => {
        return {
            url: `location/${id}/area/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
}

export default locationRequests
