import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        messageContainer: {
            marginTop: theme.spacing(5),
        },
        messageFont: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.typography.caption.color,
            textAlign: 'center',
        },
    }
})

export default useStyles
