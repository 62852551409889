import isEmpty from 'lodash/isEmpty'
import removeEmptyFields from 'app/utils/removeEmptyFields'
import 'array-flat-polyfill'
import moment from 'moment'

const valueSerializer = (values) => {
    return {
        includeFilters: [`*${values}*`],
    }
}

const exactValue = (values) => {
    return {
        includeFilters: [values],
    }
}

const arraySerializer = (values) => {
    return {
        includeFilters: values,
    }
}

const range = ([
    from,
    to,
]) => {
    return {
        includeFilters: {
            from,
            to,
        },
    }
}

const dateRange = ({
    from,
    to,
}) => {
    return {
        includeFilters: {
            from,
            to,
        },
    }
}

const dayRange = ([
    from,
    to,
]) => {
    const toTimestampSec = moment().add(to, 'd').unix()
    const fromTimestampSec = moment().add(from, 'd').unix()

    return {
        includeFilters: {
            from: fromTimestampSec,
            to: toTimestampSec,
        },
    }
}

const typeSerializers = {
    value: valueSerializer,
    exactValue,
    range,
    array: arraySerializer,
    dayRange,
    'date-range': dateRange,
}

function generateFilters(filter, typeMapping = {}) {
    const filterCleanedUp = removeEmptyFields(filter)

    if (isEmpty(filterCleanedUp)) {
        return undefined
    }

    return Object.entries(filterCleanedUp).reduce((res, [
        key,
        value,
    ]) => {
        const fieldType = typeMapping[key] || 'array'

        const filterValue = typeSerializers[fieldType](value) || {}

        return removeEmptyFields({
            ...res,
            rangeFilters: removeEmptyFields({
                ...res.rangeFilters,
                [filterValue.rangeFilters ? key : undefined]: filterValue.rangeFilters,
            }),
            includeFilters: removeEmptyFields({
                ...res.includeFilters,
                [filterValue.includeFilters ? key : undefined]: filterValue.includeFilters,
            }),
        })
    }, [])
}

export default generateFilters
