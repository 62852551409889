/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import clsx from 'clsx'
import omit from 'lodash/omit'

import Select from 'app/shared-components/Select'

import useStyles from './StateSelector.styles'

export type State = {
    id: string | number,
    stateName: string
}

type Props = {
    value?: { id: string | number },
    country?: {
        states: State[]
    },
    onChange?: ({
        id: number,
    }, ...args: any[]) => void,
    className?: string
}

const defaultProps = {
    value: {},
    onChange: noop,
    country: {
        states: [],
    },
    className: '',
}

const StateSelector = (props: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        onChange,
        className,
        country: {
            states,
        },
        value,
    } = props

    const allowedValues = useMemo(() => {
        const options = states.map(({
            id,
            stateName,
        }) => {
            return [
                id,
                stateName,
            ]
        })

        return Object.fromEntries(options)
    }, [states])

    const handleOnChange = useCallback((val, ...args) => {
        if (onChange) {
            onChange({
                id: val,
            }, ...args)
        }
    }, [onChange])

    return (
        <div className={clsx(classes.root, className)}>
            <Select
                {...omit(props, 'className')}
                onChange={handleOnChange}
                value={value?.id}
                allowedValues={allowedValues}
            />
        </div>
    )
}

StateSelector.defaultProps = defaultProps

export default StateSelector
