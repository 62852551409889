import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: theme.spacing(5),
        },
        emptyMsg: {
            padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        },
        root: {
            maxHeight: 545,
            height: '100%',
            minHeight: 315,
        },
    }
})

export default useStyles
