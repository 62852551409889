import {
    RequestConfig,
} from 'app/types/request.types'

type AdditionalFeesRequests = {
    domainName: string,
    create: ({
        contractId,
    }) => RequestConfig,
    update: ({
        id,
    }) => RequestConfig,
    editableData: ({
        id,
    }) => RequestConfig,
    get: ({
        id,
    }) => RequestConfig,
    duplicate: ({
        id,
    }) => RequestConfig,
    filterRequest: ({
        contractId,
        params,
    }) => RequestConfig,
    cancellation: ({
        id,
    }) => RequestConfig,
    validity: ({
        id,
    }) => RequestConfig,
    validation: ({
        id,
    }) => RequestConfig,
    archive: ({
        id,
    }:{
        id: number,
    }) => RequestConfig,
    restore: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
}

const requests: AdditionalFeesRequests = {
    domainName: 'AdditionalFees',
    create: ({
        contractId,
    }) => {
        return {
            url: `contractbasis/${contractId}/additionalfees`,
            method: 'POST',
            domainName: 'AdditionalFees',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}`,
            method: 'PATCH',
            domainName: 'AdditionalFees',
        }
    },
    editableData: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}/editable-data`,
            method: 'PATCH',
            domainName: 'AdditionalFees',
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}`,
            method: 'GET',
        }
    },
    duplicate: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}/duplication`,
            method: 'POST',
            domainName: 'AdditionalFees',
        }
    },
    filterRequest: ({
        contractId,
        params,
    }) => {
        return {
            url: `contractbasis/${contractId}/additionalfees/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    cancellation: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}/cancellation`,
            method: 'PATCH',
            domainName: 'AdditionalFees',
        }
    },
    validity: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}/validity`,
            method: 'PATCH',
            domainName: 'AdditionalFees',
        }
    },
    validation: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}/additionalfeesstatus/valid`,
            method: 'PATCH',
            domainName: 'AdditionalFees',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `additionalfees/${id}/restore`,
            method: 'PATCH',
        }
    },
}

export default requests
