import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useCallback,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import request from '../Notifications.request'
import {
    NotificationsSettingsType,
} from '../../lanes.types'

const useGetNotificationsSettings = (laneId: number) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getLaneNotificationsSettings],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<NotificationsSettingsType>({
        key: RequestKeys.getLaneNotificationsSettings,
        params: {
            id: laneId,
        },
        requestFunc: RequestFn.getEntity(request.GetNotificationsSettings.requestFn),
        enabled: Boolean(laneId),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetNotificationsSettings
