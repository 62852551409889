import React, {
    useMemo,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import Card from 'app/shared-components/Card'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import request from '../../Containers.request'
import {
    DamageByContainerType,
} from '../../containers.types'
import columns from './damages.columns'
import useStyles from './Damages.style'

const Damages = ({
    id: serialNumber,
}: {
    id: string,
}) => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<{data: DamageByContainerType[]}>({
        config: request.getDamageByContainer,
        params: {
            serialNumber,
        },
        key: RequestKeys.getDamageByContainer,
        keepPreviousData: true,
        defaultValue: {
            rows: 100,
        },
    })

    const injectLoadFilters = useLoadFilters(request.damageByContainerCountRequest(serialNumber))
    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [injectLoadFilters])

    const onRowClick = ({
        id,
    }: {
        id: number,
    }) => {
        history.push(`/apps/damagetracking/damages/all/edit/${id}/data`)
    }

    return (
        <Card
            contentClass={classes.wrapper}
            title="Damages"
            titleClass={classes.title}
        >
            <RichTable
                configName="DamageTable"
                name="Damages"
                load={loadData}
                onParamsChange={onParamsChange}
                columns={columnsWithFilters}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
                onRowClick={onRowClick}
            />
        </Card>
    )
}

export default Damages
