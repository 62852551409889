import {
    useCallback,
} from 'react'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import config from './requests'

export type Country = {
    countryName: string,
    id: number,
}

type Response = {
    items: Country[],
    hasNextPage: boolean,
}

const useGetCountries = () => {
    const {
        data: countries,
    } = useRequest<Response>({
        key: RequestKeys.getCountries,
        requestFunc: RequestFn.getEntity(config.Countries.requestFn),
        keepPreviousData: true,
        staleTime: Infinity,
    })

    return useCallback((addressComponents: google.maps.GeocoderAddressComponent[]) => {
        const countryComponent = addressComponents.find((item) => {
            return item.types.includes('country')
        })?.long_name

        return countries?.items.find((currCountry) => {
            return currCountry.countryName.includes(countryComponent)
        })
    }, [countries?.items])
}

export default useGetCountries
