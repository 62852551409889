import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        item: {
            display: 'flex',
        },
        containerIcon: {
            paddingRight: theme.spacing(1.5),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        circle: {
            width: 14,
            height: 14,
        },
        verticalLine: {
            height: 12,
            width: 3,
            margin: '2px 0',
            background: theme.palette.primary[200],
        },
        control: {
            width: 23,
            height: 23,
            borderRadius: '50%',
            background: theme.palette.primary[400],
            color: theme.palette.common.white,
            fontSize: theme.typography.h3.fontSize,
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        label: {
            paddingTop: theme.spacing(0.7),
        },
        locationInput: {
            width: '100%',
            paddingRight: theme.spacing(1.5),
        },
    }
})

export default useStyles
