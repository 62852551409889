import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    PositionPrePopulationType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const useLoadAutoPopulatePosition = ({
    invoiceId,
    positionCode,
    enabled,
}:
    {
        invoiceId: number,
        positionCode: string,
        enabled: boolean
    }) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<PositionPrePopulationType>({
        key: RequestKeys.getAutoPopulatePosition,
        params: {
            invoiceId,
            positionCode,
        },
        requestFunc: RequestFn.getEntity(orderManagementRequest.AutoPopulatePosition),
        enabled: Boolean(enabled && positionCode),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useLoadAutoPopulatePosition
