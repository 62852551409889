import {
    Location,
} from 'app/shared-components/LocationSelector'
import {
    Contact,
} from 'app/types/common.types'

import {
    Area,
} from 'app/shared-components/AreaSelector'

export type GatewayLocation = {
    locationRemark?: string,
    latitude?: number,
    location?: Location,
    locationId?: number | string,
    longitude?: number,
}

export enum GatewayStatus {
    TO_BE_INSTALLED = 'TO_BE_INSTALLED',
    STOCK = 'STOCK',
    INSTALLED = 'INSTALLED',
    EXPERIMENTAL = 'EXPERIMENTAL',
    UNKNOWN = 'UNKNOWN',
    DECOMMISSIONED = 'DECOMMISSIONED'
}

export enum GatewayType {
    CARTASENSE_ETHERNET = 'CARTASENSE_ETHERNET',
    CARTASENSE_GSM = 'CARTASENSE_GSM',
    CARTASENSE_WIFI = 'CARTASENSE_WIFI',
    CARTASENSE_2G = 'CARTASENSE_2G',
    SAVY_STATIONARY = 'SAVY_STATIONARY',
    SAVY_MOBILE ='SAVY_MOBILE',
    LORA_MIROMICO = 'LORA_MIROMICO'
}

export enum GatewayStorageArea {
    C = 'C',
    CRT = 'CRT',
    DRY = 'DRY',
    OPERATIONAL = 'OPERATIONAL',
    RECEPTION = 'RECEPTION',
    FREEZER = 'FREEZER',
    OFFICE = 'OFFICE',
    BACKUP = 'BACKUP',
    PRECONUNIT = 'PRECONUNIT',
    TRAILER = 'TRAILER',
    UNKNOWN = 'UNKNOWN'
}

export enum GatewayBoxConfiguration {
    V_1_0 = 'V_1_0',
    V_2_0 = 'V_2_0',
    V_3_0 = 'V_3_0',
    V_4_0 = 'V_4_0',
    V_5_0 = 'V_5_0'
}

export enum GatewayDataPlan {
    LAN = 'LAN',
    WLAN = 'WLAN',
    LOCALSIM = 'LOCALSIM',
    TELECOM26 = 'TELECOM26',
    SWISSCOM = 'SWISSCOM',
}

export enum GatewayLocationCategory {
    SERVICECENTER = 'SERVICECENTER',
    AIRPORTSERVICECENTER = 'AIRPORTSERVICECENTER',
    CUSTOMER = 'CUSTOMER',
    REVERSELOGISTICS = 'REVERSELOGISTICS',
    INTERNAL = 'INTERNAL',
    FREIGHTFORWARDER = 'FREIGHTFORWARDER',
}

export type Gateway = {
    createdBy?: string,
    createdOn?: string,
    deleted?: boolean,
    gatewayBoxConfiguration?: GatewayBoxConfiguration,
    gatewayBoxNumber: string,
    gatewayConnectionType: GatewayDataPlan,
    gatewayImeiMac?: string,
    gatewayLocationCategories?: GatewayLocationCategory[],
    gatewayNumber?: string,
    gatewayStatus?: GatewayStatus,
    gatewayStorageArea?: GatewayStorageArea,
    gatewaySimCardNumber?: string,
    gatewaySimCardNumberBackup?: string,
    gatewayType: GatewayType,
    gatewayUuid: string,
    hardwareVersion?: string,
    id: number,
    installedOn?: string,
    installedBy?: Contact,
    isConnectedLora?: boolean,
    supportingDocuments?: {id: number}[]
    lastConnectedOn?: number,
    remark?: string,
    softwareVersion?: string,
    upTimeSeconds?: string,
    loggerConnections?: number,
    locationLocationName?: string,
    locationCity?: string,
    locationPortOfReferenceIataCode?: string,
    area?: Area,
    project?: string,
    gatewayContacts?: Contact[],
} & GatewayLocation

export type GatewayData = {
    data: Gateway,
}

export type GatewayOverview = GatewayData & GatewayLogger & GatewayContactInformation

export type GatewayLogger = {
    showConnectedLogger: boolean,
}

export type GatewayContactInformation = {
    showContactInformation: boolean,
}

export type GatewayValue = Gateway & GatewayLogger & GatewayContactInformation

export type GatewayFormProps = {
    name: string,
    data?: {
        id?: number
    } & GatewayLocation,
    exists?: boolean,
    onSuccess?: (props: any) => void,
    showContactInformation?: boolean,
}
