import {
    FilterType,
} from 'app/types/common.enums'
import {
    RichTableColumns,
} from 'app/types/richtable.types'

const additionalFeesColumns: RichTableColumns[] = [
    {
        id: 'additionalFeesNumber',
        mapCellProps: 'additionalFeesNumber',
        headerProps: {
            children: 'Additional Fee',
        },
    },
    {
        headerProps: {
            children: 'Status',
        },
        id: 'additionalFeesStatus',
        mapCellProps: 'additionalFeesStatus',
        filterField: 'additionalFeesStatus',
        filterType: FilterType.Array,
        componentName: 'AdditionalFeesStatusEnriched',
    },
    {
        headerProps: {
            children: 'Valid From',
        },
        id: 'validFrom',
        mapCellProps: 'validFrom',
        filterField: 'validFrom',
        filterType: FilterType.DateRange,
    },
    {
        headerProps: {
            children: 'Valid To',
        },
        id: 'validTo',
        mapCellProps: 'validTo',
        filterField: 'validTo',
        filterType: FilterType.DateRange,
        componentName: 'LaneDateEnriched',
    },
    {
        headerProps: {
            children: 'Changed On',
        },
        id: 'changedOn',
        mapCellProps: 'changedOn',
        filterField: 'changedOn',
    },
    {
        headerProps: {
            children: 'Changed By',
        },
        id: 'changedBy',
        mapCellProps: 'changedBy',
        filterField: 'changedBy',
    },
]

export default additionalFeesColumns
