import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    AirportsLaneGroupsFiltered: {
        requestFn: (params) => {
            return {
                url: 'lanegroup/origindestinationairport/all',
                method: 'POST',
                data: params,
            }
        },
    },
}

export default config
