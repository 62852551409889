import {
    LeaseType, ProductType,
} from 'app/types/enums'
import {
    DTOCardFormField, NestedField,
} from 'app/types/form.types'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    TransportModeRoad,
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    BookingDetailsStep,
    Selection,
} from '../../createDirectLease.types'

const getFields = ({
    getAllowedValues,
    selectedLane,
    isDeliveryRoad,
    transportModeForwarder,
    allowedProductTypes,
    defaultTimeZone,
    disabled,
}: {
    getAllowedValues: (enumType: any) => Record<string, string>,
    selectedLane: Lane,
    isDeliveryRoad: boolean,
    transportModeForwarder: TransportModeRoad,
    allowedProductTypes: ProductType[],
    defaultTimeZone: string,
    disabled: boolean,
}): DTOCardFormField<keyof BookingDetailsStep>[] => {
    const transportModeRoadField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        disabled: selectedLane?.transportModeRoad === TransportModeRoad.LTL
            || selectedLane?.transportModeRoad === TransportModeRoad.FTL || disabled,
        labelKey: 'Order.transportModeRoad',
    }]

    const transportModeForwarderField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'transportModeForwarder',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        required: true,
        labelKey: 'Order.transportModeForwarder',
    }]

    const numberOfVansTracksFields: NestedField<keyof BookingDetailsStep>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'CustomerTransportCreateDto.numberOfTrucks',
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'CustomerTransportCreateDto.numberOfVans',
        },
    ]

    const originScSelectionField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'originScSelection',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(Selection),
        noSort: true,
        disabled: !selectedLane?.originScBackup || disabled,
        labelKey: 'CustomerTransportCreateDto.originScSelection',
    }]

    const isDeliveryRoadFirstSetFields: NestedField<keyof BookingDetailsStep>[] = [
        ...selectedLane?.originScBackup ? originScSelectionField : [],
        {
            name: 'originServiceCenter',
            componentName: 'LocationObjectSelector',
            disabled: true,
            labelKey: 'Lane.originSc',
        },
        {
            name: 'estimatedPickup',
            componentName: 'DateTimeZonedSelect',
            labelKey: 'CustomerTransportCreateDto.estimatedPickup',
        },
    ]

    const requestedPickupField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'requestedPickup',
        componentName: 'DateTimeZonedSelect',
        defaultTimeZone,
        required: true,
        labelKey: 'CustomerTransportCreateDto.requestedPickup',
    }]

    const leaseStartTimestampField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'leaseStartTimestamp',
        componentName: 'DateTimeZonedSelect',
        required: true,
        labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
    }]

    const freightForwarderField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'freightForwarder',
        componentName: 'CompanyObjectSelector',
        required: true,
        labelKey: 'OrderByNumberView.freightForwarder',
    }]

    const fwdPosSelectionField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'fwdPosSelection',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(Selection),
        noSort: true,
        labelKey: 'CustomerTransportCreateDto.fwdPosSelection',
    }]

    const isDeliveryRoadSecondSetFields: NestedField<keyof BookingDetailsStep>[] = [
        ...selectedLane?.fwdPosBackup ? fwdPosSelectionField : [],
        {
            name: 'forwarder',
            componentName: 'LocationObjectSelector',
            disabled: true,
            labelKey: 'Order.forwarderPositioning',
        },
        {
            name: 'requestedDelivery',
            componentName: 'DateTimeZonedSelect',
            required: true,
            defaultTimeZone,
            labelKey: 'Order.requestedDelivery',
        },
        {
            name: 'leaseStartTimestamp',
            componentName: 'DateTimeZonedSelect',
            required: true,
            labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
        },
    ]

    const originServiceCenterField: NestedField<keyof BookingDetailsStep>[] = [{
        name: 'originServiceCenter',
        componentName: 'LocationObjectSelector',
        disabled: true,
        labelKey: 'Lane.originSc',
    }]

    return [
        {
            title: 'Positioning Setup',
            className: 'positioningSetup',
            id: 'positioningSetup',
            fields: [
                {
                    id: 'column6',
                    fields: [
                        {
                            name: 'leaseType',
                            componentName: 'EnumSelector',
                            value: selectedLane?.leaseType,
                            allowedValues: getAllowedValues(LeaseType),
                            disabled: true,
                            labelKey: 'Order.leaseType',
                        },
                        {
                            name: 'deliveryServiceType',
                            componentName: 'EnumSelector',
                            value: selectedLane?.deliveryServiceType,
                            allowedValues: getAllowedValues(DeliveryServiceType),
                            disabled: true,
                            labelKey: 'Order.deliveryServiceType',
                        },
                        {
                            name: 'laneCommentOps',
                            componentName: 'LongText',
                            disabled: true,
                            labelKey: 'Lane.laneCommentOps',
                        },
                        ...isDeliveryRoad ? transportModeRoadField : [],
                        ...isDeliveryRoad ? transportModeForwarderField : [],
                        ...transportModeForwarder === TransportModeRoad.FTL && isDeliveryRoad
                            ? numberOfVansTracksFields
                            : [],
                        {
                            name: 'orderReceived',
                            componentName: 'DateTimeZonedSelect',
                            required: true,
                            labelKey: 'Order.orderReceived',
                        },
                        {
                            name: 'orderEntered',
                            componentName: 'DateTimeZonedSelect',
                            disabled: true,
                            labelKey: 'Order.orderEntered',
                        },
                        {
                            name: 'mawbNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'Order.mawbNumber',
                        },
                    ],
                },
                {
                    id: 'column7',
                    fields: [
                        ...isDeliveryRoad ? isDeliveryRoadFirstSetFields : requestedPickupField,
                        {
                            name: 'customerReference',
                            componentName: 'InputSingleline',
                            required: true,
                            labelKey: 'Order.customerReference',
                        },
                        ...isDeliveryRoad ? [] : leaseStartTimestampField,
                        {
                            name: 'hawbNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'CustomerTransportCreateDto.hawbNumber',
                        },
                        ...selectedLane?.leaseType === LeaseType.AIRLINE_LEASE
                            ? freightForwarderField
                            : [],
                        {
                            name: 'customerExecutionContactsOps',
                            componentName: 'MultipleContactObjectSelector',
                            disabled: true,
                            labelKey: 'Lane.customerExecutionContactsOps',
                        },
                    ],
                },
                {
                    id: 'column8',
                    fields: [
                        ...isDeliveryRoad
                            ? isDeliveryRoadSecondSetFields
                            : originServiceCenterField,
                        {
                            name: 'commentForBillingOps',
                            componentName: 'LongText',
                            labelKey: 'OrderByNumberView.commentForBillingOps',
                        },
                        {
                            name: 'personInCharge',
                            componentName: 'InputSingleline',
                            labelKey: 'Order.personInCharge',
                        },
                        {
                            name: 'isPerformanceQualification',
                            componentName: 'Checkbox',
                            labelKey: 'Order.isPerformanceQualification',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Required Containers',
            className: 'requiredContainers',
            id: 'requiredContainers',
            fields: [{
                id: 'column5',
                fields: [
                    {
                        name: 'minOrder',
                        value: selectedLane?.minOrder,
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.minOrder',
                    },
                    {
                        name: 'requiredCargoComment',
                        componentName: 'LongText',
                        labelKey: 'Order.requiredCargoComment',
                    },
                    {
                        name: 'requiredContainers',
                        componentName: 'RequiredContainersByProductType',
                        allowedProductTypes,
                        disabled: !allowedProductTypes || disabled,
                        required: true,
                        labelKey: 'Order.requiredContainers',
                    },
                ],
            }],
        },
    ]
}

export default getFields
