const getDataChart = (co2Calculation, theme): any[][] => {
    return [
        [
            'number',
            '',
            {
                role: 'style',
            },
        ],
        [
            'SkyCell',
            co2Calculation?.SKYCELL_HYBRID || 0,
            theme.palette.primary[400],
        ],
        [
            'Active',
            co2Calculation?.ACTIVE || 0,
            theme.palette.common.purple,
        ],
        [
            'Passive',
            co2Calculation?.PASSIVE || 0,
            theme.palette.secondary[400],
        ],
    ]
}

export default getDataChart
