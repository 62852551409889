import React, {
    useCallback,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import LocationForm from '../LocationForm'
import {
    defaultProps,
} from '../LocationForm/LocationForm'

import {
    useCreateLocation,
} from '../hooks'
import {
    ROLES_NOPROSPECT_AND_PROSPECT_ALLOWED,
    ROLES_PROSPECT_ALLOWED,
} from '../Locations.types'

const LocationCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((location) => {
        addTableElement(location)
        selectDomainObject(location.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    const createLocation = useCreateLocation({
        onSuccess,
    })

    const canCreateProspect = useHasPermission(ROLES_PROSPECT_ALLOWED)
    const canCreateNonProspectAndProspect = useHasPermission(ROLES_NOPROSPECT_AND_PROSPECT_ALLOWED)
    const canCreateOnlyProspectPermissions = canCreateProspect && !canCreateNonProspectAndProspect
    const defaultData = {
        ...defaultProps.data,
        isProspect: canCreateOnlyProspectPermissions,
    }

    return (
        <LocationForm
            action={createLocation}
            data={defaultData}
        />
    )
}

export default LocationCreate
