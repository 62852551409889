import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'static',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        cardContent: {
            paddingRight: 0,
            paddingLeft: 0,
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        icon: {
            cursor: 'pointer',
        },
        gridCardWrapper: {
            height: '100%',
            minHeight: 300,
        },
    }
})

export default useStyles
