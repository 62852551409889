import {
    makeStyles,
} from 'app/tss'

const addressDetails = 'addressDetails'
const location = 'location'
const shippingLocation = 'shippingLocation'
const billingDetails = 'billingDetails'
const contactDetails = 'contactDetails'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapperBilling: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
            " ${location} ${addressDetails} ${addressDetails} ${billingDetails}"
            " ${contactDetails} ${contactDetails} ${contactDetails} ${contactDetails}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        containerData: {
            gridArea: addressDetails,
        },
        location: {
            gridArea: location,
            display: 'flow-root',
        },
        shippingLocation: {
            gridArea: shippingLocation,
        },
        locationType: {
            flexDirection: 'column',
        },
        addressDetails: {
            gridArea: addressDetails,
            minWidth: 290,
            overflow: 'auto !important',
        },
        billingDetails: {
            gridArea: billingDetails,
        },
        contactDetails: {
            gridArea: contactDetails,
            margin: 0,
            padding: 0,
            overflow: 'auto',
        },
        paymentTerms: {
            flexDirection: 'column',
        },
    }
})

export default useStyles
