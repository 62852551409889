import {
    useMemo,
} from 'react'

import {
    SkyNetSpreadSheetConfigType, SkyNetSpreadSheetColumn,
} from '../SkyNetSpreadSheet.types'

const ROW_COUNTER_WIDTH = 15

// creates columns array applicable for ReactGrid
export default <F>({
    fields,
    rowsCounter,
}: SkyNetSpreadSheetConfigType<F>, ref): {
    columns: SkyNetSpreadSheetColumn[],
    recalculationIndex: number
} => {
    const containerWidth = ref?.current?.clientWidth - ref?.current?.offsetLeft - ROW_COUNTER_WIDTH

    const totalColumnsWidth = fields.reduce((acc, {
        width = 150, // default value
        hidden,
    }) => {
        let accValue = acc

        if (!hidden) {
            accValue += width
        }
        return accValue
    }, rowsCounter ? ROW_COUNTER_WIDTH : 0)

    const recalculationIndex: number = containerWidth / totalColumnsWidth

    const calcItemWidth = (itemWidth, totalWidth, hidden) => {
        if (hidden) {
            return 1
        }
        if ((totalWidth - itemWidth) > 0) {
            return itemWidth
        }

        return totalWidth > 0 ? totalWidth : 0
    }

    const columns = useMemo(() => {
        const rowCounterWidth = recalculationIndex > 1
            ? recalculationIndex * ROW_COUNTER_WIDTH : ROW_COUNTER_WIDTH

        const {
            items,
        } = fields.reduce((acc, {
            name,
            width,
            reorderable,
            resizable,
            calcValue,
            hidden,
        }) => {
            const itemWidth = calcItemWidth(
                recalculationIndex > 1 ? width * recalculationIndex : width,
                acc.width,
                hidden,
            )

            return {
                items: [
                    ...acc.items,
                    {
                        columnId: name,
                        width: recalculationIndex < 1 ? width : itemWidth,
                        reorderable,
                        resizable,
                        calcValue,
                    },
                ],
                width: acc.width - itemWidth,
            }
        }, {
            items: [] as SkyNetSpreadSheetColumn[],
            width: containerWidth - rowCounterWidth,
        })

        return [
            ...rowsCounter ? [{
                columnId: 'rowsCounter',
                width: rowCounterWidth,
            }] : [],
            ...items,
        ] as SkyNetSpreadSheetColumn[]
    }, [
        fields,
        rowsCounter,
        recalculationIndex,
        containerWidth,
    ])

    return useMemo(() => {
        return {
            columns,
            recalculationIndex,
        }
    }, [
        columns,
        recalculationIndex,
    ])
}
