import {
    NotificationRecipientType,
    NotificationTypes,
} from 'app/types/enums'

export enum SentStatus {
    FAIL = 'FAIL',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
}

export type DefaultAttachment = {
    id: number,
    fileName: string,
}

export type NotificationTableView = {
    id: number,
    sentFirstTimeOn: string,
    sentThirdTimeOn: string,
    sentSecondTimeOn: string,
    customAttachments: {
        id: number
    }[],
    notificationName: string,
    notificationType: NotificationTypes,
    recipientTypes: NotificationRecipientType,
    recipients: string[],
    sendFirstTime: boolean,
    sendSecondTime: boolean,
    sendThirdTime: boolean,
    triggerDescription: string,
    lastSentOn: string,
    optional: boolean,
    sentFirstStatus: SentStatus,
    sentSecondStatus: SentStatus,
    sentThirdStatus: SentStatus,
    defaultAttachments: DefaultAttachment[]
}

export type NotificationTableViewFields = keyof NotificationTableView
