import useGetPermissions from 'app/hooks/useGetPermissions'
import useGetRightsByName from 'app/hooks/useGetRightsByName'

import getCategoryTypePermission from './getCategoryTypePermission'

import accessRoles from './accessRoles'

/**
* Use this to get a list of category or type that a user has edit or create access to
* @param   {String}  accessRightType permission type to load
* @param    {string}  type to match permission
* @return   {Array}  list of permission that the user has access to
*/

const useGetAllowedCategoryType = (accessRightType, type) => {
    const accessRights = useGetRightsByName(accessRightType)

    const permissionsList = useGetPermissions(accessRoles)

    return getCategoryTypePermission(accessRights, permissionsList, type)
}

export default useGetAllowedCategoryType
