import useAuthContext from './useAuthContext'

const useAuth = () => {
    const context = useAuthContext()

    return {
        user: {
            firstName: context.userInfo?.firstName,
            lastName: context.userInfo?.lastName,
            email: context.userInfo?.email,
            userName: context.userInfo?.username,
        },
        microsoftData: {
            access_token: context.microsoftData?.access_token,
        },
    }
}

export default useAuth
