import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            minHeight: 100,
        },
        currencyValue: {
            paddingTop: theme.spacing(1),
            color: theme.palette.secondary[600],
            fontSize: theme.typography.h1.fontSize,
        },
    }
})

export default useStyles
