import {
    useContext,
} from 'react'
import AuthContext from '../AuthProvider/AuthContext'
import {
    AuthContextValues,
} from '../AuthProvider/Auth.types'

const useAuthContext = (): AuthContextValues => {
    return useContext(AuthContext)
}

export default useAuthContext
