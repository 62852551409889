import {
    createContext, useContext, ChangeEvent,
} from 'react'

export type SkyNetPaginationType = {
    rows: number,
    start: number,
    matches: number,
    page: number,
    onChange: (e: ChangeEvent<HTMLInputElement>, page: number) => void,
    setMatches: (value: number) => void,
}

export const SkyNetPaginationContext = createContext<SkyNetPaginationType>(null)

const useSkyNetPaginationContext = () => {
    return useContext<SkyNetPaginationType>(SkyNetPaginationContext)
}

export default useSkyNetPaginationContext
