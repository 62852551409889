import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    ModificationFields,
} from './ChangeHistory.types'

const getColumns = (labelPrefixKeys: string[]): RichTableColumns<ModificationFields>[] => {
    return [
        {
            id: 'fieldName',
            headerProps: {
                children: 'Change',
            },
            mapCellProps: 'fieldName',
            componentName: 'ModifiedField',
            labelPrefixKeys,
        },
        {
            id: 'previousValue',
            headerProps: {
                children: 'Previous Value',
            },
            mapCellProps: 'previousValue',
            componentName: 'ModifiedValue',
        },
        {
            id: 'updatedValue',
            headerProps: {
                children: 'Updated Value',
            },
            mapCellProps: 'updatedValue',
            componentName: 'ModifiedValue',
        },
        {
            id: 'modifiedBy',
            headerProps: {
                children: 'Modified By',
            },
            mapCellProps: 'modifiedBy',
            componentName: 'ModifiedValue',
        },
        {
            id: 'modifiedOn',
            headerProps: {
                children: 'Modified On',
            },
            mapCellProps: 'modifiedOn',
            componentName: 'ModifiedValue',
        },
    ]
}

export default getColumns
