import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tableCell: {
            padding: '5px !important',
        },
        selectIcon: {
            top: '-2px !important',
        },
        inputCentered: {
            textAlign: 'center',
        },
        cellSmall: {
            maxWidth: 80,
        },
        productType: {
            width: '30%',
        },
        input: {
            '&&': {
                backgroundColor: theme.palette.secondary[50],
                paddingLeft: theme.spacing(0.25),
                color: `${theme.palette.secondary[600]} !important`,
                fontSize: theme.typography.body1.fontSize,
            },
        },
        inputBase: {
            width: '100%',
        },
        deleteIcon: {
            cursor: 'pointer',
            color: theme.palette.secondary[600],
        },
        containerSelector: {
            '& .MuiInput-underline:before': {
                border: 'none',
            },
        },
    }
})

export default useStyles
