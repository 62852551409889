import {
    FieldInCard,
} from 'app/types/form.types'
import {
    LoggerExchangeNote,
} from '../../../ContainerNotes.types'
import {
    AuditTrailConfig, ChangeConfig,
} from './notesConfigs'

type Fields = keyof LoggerExchangeNote

const fields: FieldInCard<Fields>[] = [
    ChangeConfig,
    {
        title: 'Loggers',
        className: 'logger',
        fields: [{
            name: 'loggerChange',
            componentName: 'LoggerSelector',
        }],
    },
    AuditTrailConfig,
]

export default fields
