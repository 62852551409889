import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    QuotesPdf,
} from './AccountQuotePdf.types'

type QuotesPdfTable = keyof (QuotesPdf & {
    documentId: number,
})
const columns = ({
    l,
    quotePdfDelete,
    quotePdfUpdate,
}: {
    l: (l:string) => string,
    quotePdfDelete: boolean,
    quotePdfUpdate: boolean,
}): RichTableColumns<QuotesPdfTable>[] => {
    const QuotePdfFields: RichTableColumns<QuotesPdfTable>[] = [
        {
            id: 'filename',
            headerProps: {
                children: l('Document.documentName'),
            },
            mapCellProps: 'filename',
        },
        {
            id: 'createdBy',
            headerProps: {
                children: l('AccountQuotes.createdBy'),
            },
            mapCellProps: 'createdBy',
        },
        {
            id: 'createdOn',
            headerProps: {
                children: l('Accounts.createdOn'),
            },
            mapCellProps: 'createdOn',
        },
        {
            id: 'description',
            headerProps: {
                children: l('Attachment.description'),
            },
            mapCellProps: 'description',
        },
    ]
    const deleteField: RichTableColumns<QuotesPdfTable> = {
        id: 'id',
        mapCellProps: 'id',
        componentName: 'QuotePdfDelete',
        width: '50px',
    }
    const updateField: RichTableColumns<QuotesPdfTable> = {
        id: 'documentId',
        mapCellProps: 'id',
        componentName: 'QuotePdfDescriptionEdit',
        width: '35px',
    }
    const attachmentField: RichTableColumns<QuotesPdfTable> = {
        id: 'attachmentUrl',
        mapCellProps: 'attachmentUrl',
        componentName: 'DownloadDocument',
        width: '50px',
    }

    return [
        ...QuotePdfFields,
        quotePdfUpdate ? updateField : undefined,
        quotePdfDelete ? deleteField : undefined,
        attachmentField,
    ].filter(Boolean)
}

export default columns
