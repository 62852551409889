import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
            height: '100%',
            position: 'relative',
            flexShrink: 1,
        },
        controlsWrapper: {
            zIndex: 1,
            position: 'absolute',
            height: theme.shape.tabHeight,
            right: 0,
            top: 2,
            display: 'flex',
            justifyContent: 'right',
            '@media print': {
                display: 'none',
            },
        },
        controls: {
            display: 'flex',
            backgroundColor: theme.palette.secondary[50],
            padding: theme.spacing(1),
            whiteSpace: 'nowrap',
            borderRadius: '10px 10px 0px 0px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.secondary[300],
            borderBottom: `2px solid ${theme.palette.secondary[50]}`,
            color: theme.palette.secondary[600],
            '@media print': {
                display: 'none',
            },
        },
        contentWrapper: {
            overflow: 'auto',
            height: '100%',
            width: '100%',
        },
    }
})

export default useStyles
