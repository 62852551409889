import React from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import requests from '../../Logger.request'

type Props = {
    value?:{
        label: string,
        value: string,
    }[],
    onChange: (value: any[]) =>void,
}

const defaultProps: Partial<Props> = {
    value: [],
}

const OtherLoggersSelect = ({
    value,
    onChange,
}: Props) => {
    const loadOptions = useDelayedLoadOptions(requests.selector)

    return (
        <div data-testid="chip-select-logger">
            <ChipSelect
                title="Logger"
                value={value}
                onChange={onChange}
                loadOptions={loadOptions}
            />
        </div>
    )
}

OtherLoggersSelect.defaultProps = defaultProps

export default OtherLoggersSelect
