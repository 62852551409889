import React from 'react'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './ButtonContainer.style'

const propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.bool,
    ]),
}

const defaultProps = {
    children: null,
}

const ButtonContainer = ({
    children,
}) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <div className={classes.buttonContainer}>
            {children}
        </div>
    )
}

ButtonContainer.propTypes = propTypes
ButtonContainer.defaultProps = defaultProps

export default ButtonContainer
