export enum Library {
    DRAWING = 'drawing',
    GEOMETRY = 'geometry',
    LOCAL_CONTEXT = 'localContext',
    PLACES = 'places',
    VISUALIZATION = 'visualization',
    CORE = 'core',
    MAPS = 'maps',
    MARKER = 'marker',
    GEOCODING = 'geocoding'
}

export default {
    Library,
}
