import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        item: {
            display: 'flex',
        },
        containerIcon: {
            paddingRight: theme.spacing(1.5),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },

        originAirportIcon: {
            paddingTop: theme.spacing(2.5),
        },
        circle: {
            width: 14,
            height: 14,
            border: `4px solid ${theme.palette.primary[200]}`,
            borderRadius: '50%',
        },
        verticalLine: {
            height: 12,
            width: 3,
            margin: '2px 0',
            background: theme.palette.primary[200],
        },
        iconLocation: {
            width: 22,
        },
        label: {
            paddingTop: theme.spacing(0.7),
        },
        locationInput: {
            width: '100%',
            paddingRight: theme.spacing(1.5),
        },
    }
})

export default useStyles
