import React, {
    useRef,
} from 'react'
import useScrollOptions from 'app/hooks/useScrollOptions'
import {
    SpreadsheetCellOption,
} from '../SkyNetSpreadSheet.types'
import useStyles from './Dropdown.styles'
import SearchNotification from '../SearchNotification'

const defaultProps = {
    pending: false,
    noneOption: true,
}

const Dropdown = ({
    rowHeight,
    onChange,
    options,
    pending,
    noneOption,
}: {
    rowHeight: number,
    onChange: (...args: any[]) => void,
    options: SpreadsheetCellOption[],
    pending?: boolean
    noneOption?: boolean,
}) => {
    const {
        classes,
        cx,
    } = useStyles()

    const ref = useRef(null)

    useScrollOptions(ref)

    if (pending) return <SearchNotification msg="Please, wait..." />

    return (
        (options || []).length > 0 ? (
            <div
                ref={ref}
                data-testid="typeahead-options-wrapper"
                className={cx(classes.options)}
                style={{
                    top: rowHeight,
                }}
            >
                {noneOption ? (
                    <button
                        type="button"
                        data-testid="button-no-value"
                        tabIndex={0}
                        onClick={() => {
                            return onChange({
                                value: null, label: null, textLabel: null,
                            })
                        }}
                        className={cx(classes.option, classes.optionNone)}
                        data-value={undefined}
                        title="None"
                    >
                        None
                    </button>
                ) : null}
                {(options || []).map(({
                    value, label, textLabel, ...rest
                }, i) => {
                    return (
                        <button
                            type="button"
                            data-testid={`button-${value}`}
                            tabIndex={i + 1}
                            key={`option-${value}`}
                            onClick={() => {
                                return onChange({
                                    value, label, textLabel, ...rest,
                                })
                            }}
                            className={classes.option}
                            data-value={value}
                        >
                            {label}
                        </button>
                    )
                })}
            </div>
        ) : <SearchNotification msg="No search result" />
    )
}

Dropdown.defaultProps = defaultProps

export default Dropdown
