import {
    FormPart,
} from 'app/types/form.types'

import {
    Lane,
} from '../../lanes.types'

const getSubFields = (arrayField) => {
    return arrayField.fields?.map((subFieldsData) => {
        return subFieldsData.name
            ? subFieldsData.name
            : subFieldsData?.fields.map((subSubField) => {
                return subSubField.name
            })
    })
}

const getVisibleFields = (fields: FormPart<keyof Lane>[]): string[] => {
    const allFields = fields.map((field) => {
        if (Array.isArray(field)) {
            return field.map((arrayField) => {
                return arrayField.name
                    ? arrayField.name
                    : getSubFields(arrayField)
            })
        }
        return field.fields.map((fieldData) => {
            return fieldData.name
                ? fieldData.name
                : fieldData.fields?.map((subFieldsData) => { return subFieldsData.name })
        })
    }).flat()

    return allFields.map((field) => {
        return Array.isArray(field) ? field.flat() : field
    }).flat()
}

export default getVisibleFields
