import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            alignItems: 'center',
            position: 'relative',
        },
        linkInput: {
            paddingLeft: theme.spacing(0.25),
            color: 'inherit',
        },
        wrapper: {
            color: theme.palette.secondary[600],
            fontSize: theme.typography.body1.fontSize,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
        },
        link: {
            verticalAlign: 'middle',
        },
    }
})

export default useStyles
