import React from 'react'
import Typography from '@mui/material/Typography'

import useStyles from './FormBlockItem.style'

type Props = {
    children: JSX.Element,
    title: string,
    number: number,
}

const FormBlockItem = ({
    children,
    title,
    number,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.blockItem}>
            <div className={classes.root}>
                <Typography
                    variant="h3"
                    className={classes.headerTitle}
                >
                    {number}
                    {' | '}
                    {title}
                </Typography>
                <div className={classes.content}>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default FormBlockItem
