import React from 'react'
import noop from 'lodash/noop'
import clsx from 'clsx'

import Label from 'app/shared-components/Label'
import DropZone from 'app/shared-components/DropZone'

import AttachmentLink from './AttachmentLink'
import AttachmentType from './Attachment.types'

import useStyles from './Attachment.styles'

type Props = {
    title?: string,
    name?: string,
    value?: AttachmentType[],
    onChange: (...args: any) => void,
    className?: string,
    upload?: boolean,
    editable?: boolean,
    disabled?: boolean,
    onDelete?: (id: number) => void,
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    title: 'Attachments',
    name: 'attachments',
    value: [],
    className: '',
    upload: true,
    editable: false,
    disabled: false,
    onDelete: noop,
    required: false,
}
const Attachment = ({
    title,
    name,
    value,
    className,
    onChange,
    upload,
    editable,
    disabled,
    onDelete,
    required,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div
            className={clsx(classes.root, className)}
            data-testid={`attachment-${name}`}
        >
            <Label
                title={title}
            />
            {(upload)
            && (

                <DropZone
                    required={required}
                    disabled={disabled}
                    onChange={onChange}
                />
            )}
            <div className={classes.linksContainer}>
                {value.map((file) => {
                    return (
                        <AttachmentLink
                            key={`menu-link-${file.id}`}
                            file={file}
                            editable={editable}
                            onDelete={onDelete}
                        />
                    )
                })}
            </div>
        </div>
    )
}

Attachment.defaultProps = defaultProps

export default Attachment
