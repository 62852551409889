export const defaultTaxCodes = [
    {
        currency: 'EUR', taxCode: '401',
    },
    {
        currency: 'CHF', taxCode: '401',
    },
    {
        currency: 'GBP', taxCode: '401',
    },
    {
        currency: 'USD', taxCode: '401',
    },
]

export const defaultEmptyTaxCodes = [
    {
        currency: 'EUR',
    },
    {
        currency: 'CHF',
    },
    {
        currency: 'GBP',
    },
    {
        currency: 'USD',
    },
]
