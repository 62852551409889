/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import PendingMessage from './PendingMessage'
import FinishedMessage from './FinishedMessage'

import useStyles from './FormStatuses.styles'

const propTypes = {
    finished: PropTypes.arrayOf(PropTypes.any).isRequired,
    pending: PropTypes.arrayOf(PropTypes.any).isRequired,
}

const FormStatuses = ({
    finished,
    pending,
}) => {
    const {
        classes,
    } = useStyles()

    if (!finished.length && !pending.length) {
        return null
    }

    return (
        <div
            data-testid="form-statuses"
            className={classes.messageWrapper}
        >
            <div>
                {pending.map((pendProps) => {
                    return (
                        <PendingMessage
                            {...pendProps}
                            key={pendProps.id}
                        />
                    )
                })}
                {finished.map((finishProps) => {
                    return (
                        <FinishedMessage
                            {...finishProps}
                            key={`finished-${finishProps.id}`}
                        />
                    )
                })}
            </div>
        </div>
    )
}

FormStatuses.propTypes = propTypes

export default FormStatuses
