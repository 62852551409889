import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
        },
        closeIcon: {
            cursor: 'pointer',
        },
        searchFont: {
            padding: 0,
            fontSize: theme.typography.h4.fontSize,
            width: 180,
            height: 30,
            borderBottom: 'solid 1px #747474',
        },
        adorment: {
            margin: 0,
            padding: 0,
        },
        clear: {
            position: 'absolute',
            right: 6,
            top: 4,
        },
        close: {
            padding: '0 !important',
            height: 20,
            width: 20,
        },
        searchIcon: {
            '&&': {
                height: 20,
                width: 20,
            },
        },
    }
})

export default useStyles
