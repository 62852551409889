import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        cardRoot: {
            cursor: 'default',
            width: '100%',
            margin: theme.spacing(1),
        },
        container: {
            backgroundColor: theme.palette.secondary[50],
            padding: theme.spacing(1),
        },
        gridWrapper: {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        gridCardWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateColumns: 'repeat(3, 1fr) !important',
            [theme.breakpoints.down('xl')]: {
                gridTemplateColumns: 'repeat(1, 1fr)',
            },
        },
        internalLogger: {
            position: 'relative',
        },
        exchangeComment: {
            gridRowEnd: 'span 2',
        },
        exchangeDocumentations: {
            gridRowEnd: 'span 3',
        },
    }
})

export default useStyles
