import React, {
    useState,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import SharedContextProvider from 'app/shared-components/SharedContext'

import SelectMethod from './SelectMethod'
import CreateManuallyForm from './CreateManuallyForm'
import CreateAutomaticallyForm from './CreateAutomaticallyForm'

export enum SelectedMethod {
    AUTOMATICALLY = 'AUTOMATICALLY',
    MANUALLY = 'MANUALLY'
}

const CreateInternalTransportForm = () => {
    const {
        state = {},
    } = useLocation()

    const [
        method,
        setMethod,
    ] = useState<SelectedMethod>()

    const [
        formData,
        setFormData,
    ] = useState<SelectedMethod>()

    const {
        collectionDropoffPointId,
        selectedPreviousOrderId,
    } = state

    return (
        <SharedContextProvider value={{
            formData,
            setFormData,
            setMethod,
            collectionDropoffPointId,
            selectedPreviousOrderId,
        }}
        >
            <>
                <SelectMethod
                    value={method}
                    setValue={setMethod}
                />
                {
                    method === SelectedMethod.MANUALLY && <CreateManuallyForm />
                }
                {
                    method === SelectedMethod.AUTOMATICALLY && <CreateAutomaticallyForm />
                }
            </>
        </SharedContextProvider>
    )
}

export default CreateInternalTransportForm
