import {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import useEnumValues from 'app/hooks/useEnumValues'
import request from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.request'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'

import {
    RegionFirstLevel,
} from 'app/types/enums'

export default (filters?: Record<string, (string|number|boolean)[]>) => {
    const getAllowedValues = useEnumValues()(RegionFirstLevel)
    const params = useMemo(() => {
        return {
            url: request.RegionalGroups.requestFn({
                params: {},
            }).url,
            convertion: (rgs) => {
                return rgs.map(({
                    regionalGroupNumber,
                    id, originRegion,
                    destinationRegion,
                    tempRange, companyName,
                    accountId,
                }) => {
                    return {
                        textLabel: regionalGroupNumber,
                        label: `${regionalGroupNumber} | ${getAllowedValues[originRegion]} | ${getAllowedValues[destinationRegion]} | ${tempRange} | ${companyName}`,
                        value: id,
                        id,
                        originRegion,
                        destinationRegion,
                        tempRange,
                        companyName,
                        accountId,
                    }
                })
            },
            includeFilters: isEmpty(filters) ? undefined : filters,
        }
    }, [
        filters,
        getAllowedValues,
    ])

    return useDelayedLoadOptions(params)
}
