import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, params, classes: Record<string, any>) => {
    return {
        radio: {
            '&&': {
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
            },
        },
        radioRoot: {
            padding: '1px 3px 1px 10px !important',
        },
        radioSmall: {
            fontSize: `${theme.typography.button.fontSize}px !important`,
            marginRight: theme.spacing(0),
            marginLeft: '0 !important',
        },
        formControlStyle: {
            marginLeft: theme.spacing(-0.625),
        },
        showInColumns: {
            '&&&': {
                flexDirection: 'column',
            },
        },
        radioItemLabel: {},
        isRequired: {
            position: 'relative',
            backgroundColor: theme.palette.primary[30],
            [`& .${classes.requiredMark}`]: {
                display: 'block',
                height: '100%',
            },
        },
        requiredMark: {
            position: 'absolute',
            left: -9,
            width: 6,
            height: 27,
            backgroundColor: theme.palette.primary.main,
            display: 'none',
            borderRadius: 3,
        },
    }
})

export default useStyles
