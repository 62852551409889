import useShareContext from 'app/shared-components/SharedContext/useSharedContext'

type WithPriningNameType = {
    refetch: () => void,
}

const useRefetch = () => {
    const context = useShareContext<WithPriningNameType>()
    const {
        refetch,
    } = context

    return refetch
}

export default useRefetch
