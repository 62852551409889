import {
    makeStyles,
} from 'app/tss'

export default makeStyles()((theme, params, classes) => {
    return {
        buttonContainer: {
            position: 'relative',
            boxSizing: 'border-box',
            [`&:hover .${classes.layer}`]: {
                marginTop: 105,
                paddingTop: theme.spacing(0.5),
                display: 'flex',
            },
            borderRadius: 4,
            border: `1px solid ${theme.palette.secondary[50]}`,
            overflow: 'hidden',
            boxShadow: `
                0px 2px 1px -1px ${theme.palette.secondary[400]},
                0px 1px 1px 0px ${theme.palette.secondary[300]},
                0px 1px 3px 0px ${theme.palette.secondary[200]}
            `,
        },
        layer: {
            width: '100%',
            paddingTop: 0,
            height: 190,
            overflow: 'hidden',
            transform: 'transition(all 0.2s linear)',
            transition: 'all 0.2s linear',
            marginTop: 300,
            position: 'absolute',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            top: 0,
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            webkitBoxAlign: 'center',
            alignItems: 'center',
        },
    }
})
