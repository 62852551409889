import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    ContainerCode, ContainerVolume, TemperatureRange,
} from 'app/types/enums'

const config: SkyNetTableConfig = {
    name: 'ContainerTypes',
    tabs: [{
        name: 'Container Type',
        url: 'all',
    }],
    fields: [
        {
            name: 'containerModel',
            reduced: true,
        },
        {
            name: 'containerCode',
            reduced: true,
            componentName: 'EnumValue',
            allowedValuesEnum: ContainerCode,
            filterType: FilterType.Array,
            filterField: 'containerCode',
        },
        {
            name: 'containerVolume',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: ContainerVolume,
            filterField: 'containerVolume',
        },
        {
            name: 'temperatureRange',
            reduced: true,
            componentName: 'EnumValue',
            allowedValuesEnum: TemperatureRange,
        },
        {
            name: 'isPrototype',
        },
    ],
}

export default config
