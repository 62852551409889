import React from 'react'

import {
    OrderTemperatureRange,
} from 'app/types/enums'

import useStyles from './ContainerTempRange.style'

const ContainerTempRange = ({
    value,
}: {value: OrderTemperatureRange}) => {
    const {
        classes,
    } = useStyles()
    const tempIcon = `temperature_${value}`

    return (
        <div className={classes.wrapper}>
            <div
                className={classes.iconWrapper}
            >
                <img
                    alt="logo"
                    className={classes.tempImg}
                    src={`assets/images/${tempIcon}.svg`}
                />
            </div>
            <div>
                {value}
            </div>
        </div>
    )
}

export default ContainerTempRange
