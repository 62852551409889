import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            height: 'calc(100% - 56px)',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            minWidth: 280,
            margin: '20px auto 20px',
            paddingBottom: '20px',
            alignItems: 'center',
        },
        errorMsg: {
            paddingLeft: theme.spacing(3),
            color: theme.palette.secondary[600],
        },
        skeletonRoot: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
    }
})

export default useStyles
