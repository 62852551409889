import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        link: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.secondary[600],
            padding: '3px 5px 3px 2px !important',
        },
        iconWrapper: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '5px',
        },
        icon: {
            color: theme.palette.secondary[600],
            height: '20px !important',
            width: '20px !important',
        },
        'icon &:first-of-type': {
            marginRight: '-5px',
        },
    }
})

export default useStyles
