import React, {
    useState,
    useMemo,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import StatusHandler from 'app/shared-components/StatusHandler'
import useEnumValues from 'app/hooks/useEnumValues'
import useGetContainer from 'app/shared-components/ContainerSelector/hooks/useGetContainer'
import useHasPermission from 'app/hooks/useHasPermission'

import useGetDamage from '../hooks/useGetDamage'
import requests from '../Damages.requests'
import getDamageUpdateFields from './DamageUpdate.fields'
import useStyles from './DamageUpdate.styles'

type Props = {
    id: string
}

const DamageUpdate = ({
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const updatePermission = useHasPermission(['damage_tracking_update'])
    const {
        data,
        isDataReady,
        error,
        isError,
        invalidate,
        isFetching,
    } = useGetDamage(id)

    const [
        value,
        setValue,
    ] = useState(null)

    const getAllowedValues = useEnumValues()

    const {
        data: container,
    } = useGetContainer(data?.container?.id)

    const formData = useMemo(() => {
        return {
            ...data,
            ...value,
            containerModel: container?.containerType?.containerModel,
        }
    }, [
        data,
        value,
        container,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                onSuccess={invalidate}
                value={formData}
                setExternalValue={setValue}
                name={requests.domainName}
                disabled={!updatePermission}
                fields={getDamageUpdateFields({
                    getAllowedValues,
                    editable: updatePermission,
                    damageStatus: formData?.damageStatus,
                })}
                requestParams={requests.update({
                    id,
                })}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                exists
            />
        </StatusHandler>
    )
}

export default DamageUpdate
