import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from '../Logger.request'

const useGetLogger = (
    id: number | string,
) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest({
        key: RequestKeys.getLogger,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(id),
    })

    return {
        logger: data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetLogger
