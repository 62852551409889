/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {
    useHistory,
} from 'react-router-dom'

import PrintLabel from './PrintLabel'

const propTypes = {
    to: PropTypes.string.isRequired,
}

const PrintLabelContainer = (props) => {
    const history = useHistory()
    const {
        to,
    } = props

    const openPrintDialog = useCallback(() => {
        history.push(to)
    }, [
        history,
        to,
    ])

    return (
        <PrintLabel
            {...props}
            onClick={openPrintDialog}
        />
    )
}

PrintLabelContainer.propTypes = propTypes

export default PrintLabelContainer
