import React, {
    useMemo,
} from 'react'

import LaneFees, {
    LaneFeesCreate, LaneFeesUpdate, LaneFeesCopy,
} from 'app/shared-components/LaneFees'

const LaneFeesTable = () => {
    const actionComponents = useMemo(() => {
        return {
            Copy: LaneFeesCopy,
            Update: LaneFeesUpdate,
            Create: LaneFeesCreate,
        }
    }, [])

    return (
        <LaneFees
            actionComponents={actionComponents}
            showGeneralInfoCard
        />
    )
}

export default LaneFeesTable
