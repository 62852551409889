import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LocationType,
} from 'app/types/enums'

import {
    User,
} from '../Users.types'

const getUserFields = (editable): DTOCardFormField<keyof User | 'hint'>[] => {
    return [{
        title: 'General Information',
        className: 'generalInformation',
        fields: [{
            id: 'column1',
            fields: [
                {
                    name: 'userName',
                    labelKey: 'Users.userName',
                    required: true,
                    disabled: !editable,
                    componentName: 'InputSingleline',
                },
                {
                    name: 'hint',
                    componentName: 'InputSingleline',
                    disabled: true,
                    value: 'Please add both a name and surname',
                },
                {
                    name: 'email',
                    labelKey: 'Contact.email',
                    componentName: 'InputSingleline',
                    required: true,
                    disabled: !editable,
                },
                {
                    name: 'location',
                    labelKey: 'Contact.location',
                    componentName: 'LocationObjectSelector',
                    required: true,
                    disabled: !editable,
                    filterLocationType: LocationType.ADDRESS,
                },
            ],
        }],
    }]
}

export default getUserFields
