import React, {
    useCallback,
} from 'react'

import {
    useLocation,
} from 'react-router-dom'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import BillingCompanyForm from '../BillingCompanyForm'
import {
    BillingCompany,
} from '../BillingCompanyForm.types'
import requests from '../BillingCompanies.request'

const BillingCompanyCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const {
        search,
    } = useLocation()

    const companyId = parseInt(search.substring(search.indexOf('=') + 1, 99), 10) || undefined

    const onSuccess = useCallback((location) => {
        addTableElement(location)
        selectDomainObject(location.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <BillingCompanyForm
            data={{
                company: {
                    id: companyId,
                },
            } as BillingCompany}
            onSuccess={onSuccess}
            request={requests.create()}
        />
    )
}

export default BillingCompanyCreate
