import {
    useEffect,
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import pick from 'lodash/pick'

import {
    useAppDispatch,
} from 'app/store/hooks'
import {
    Domain,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    addToDomainConfigs,
    setSpreadSheetConfig,
} from './SpreadSheetConfigs.slice'

const storeFieldConfig = [
    'allowedValues',
    'format',
]
const storeDomainConfig = ['options']

const useStoreSpreadSheetConfig = (
    spreadSheetConfig: Record<string, any>,
    initValue: Record<string, any>[],
): void => {
    const dispatch = useAppDispatch()

    const config = useMemo(() => {
        if (!spreadSheetConfig.name) {
            return null
        }

        return spreadSheetConfig.fields.reduce((acc, fieldConfig) => {
            const fieldStoredProps = pick(fieldConfig, storeFieldConfig)
            let domainConfig = {}
            let fieldNameConfig = {}

            if (fieldConfig.domain) {
                const domainStoredProps = pick(fieldConfig, storeDomainConfig)

                domainConfig = {
                    [fieldConfig.domain]: {
                        ...acc.domains[fieldConfig.domain],
                        [fieldConfig.name]: domainStoredProps,
                    },
                }
            }

            if (!isEmpty(fieldStoredProps)) {
                fieldNameConfig = {
                    [fieldConfig.name]: {
                        config: fieldStoredProps,
                    },
                }
            }

            return {
                ...acc,
                domains: {
                    ...acc.domains,
                    ...domainConfig,
                },
                configs: {
                    ...acc.configs,
                    ...fieldNameConfig,
                },
            }
        }, {
            domains: {},
            configs: {},
        })
    }, [spreadSheetConfig])

    useEffect(() => {
        if (!isEmpty(config)) {
            dispatch(setSpreadSheetConfig({
                [spreadSheetConfig.name]: config,
            }))
        }
    }, [
        config,
        dispatch,
        spreadSheetConfig.name,
    ])

    // this effect needed to add the existing values to redux store
    // this allows to copy the values of cells by pulling the marker
    useEffect(() => {
        if (!isEmpty(initValue)) {
            Object.entries(config?.domains || {}).forEach(([
                domain,
                domainConfig,
            ]: [Domain, Record<string, Record<string, any>>
            ]) => {
                Object.keys(domainConfig || {}).forEach((name) => {
                    const values = initValue
                        .map((item) => { return item?.[name] })
                        .filter((item) => {
                            return !isEmpty(item)
                        })

                    if (!isEmpty(values)) {
                        dispatch(addToDomainConfigs({
                            name: spreadSheetConfig.name,
                            domain,
                            field: name,
                            params: {
                                options: flatten(values),
                            },
                        }))
                    }
                })
            })
        }
    }, [
        config,
        dispatch,
        initValue,
        spreadSheetConfig.name,
    ])
}

export default useStoreSpreadSheetConfig
