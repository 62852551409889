import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        iconWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
    }
})

export default useStyles
