import axios from 'axios'
import bearerToken from 'app/utils/api/bearerToken'
import {
    DataRequest,
} from 'app/types/request.types'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const requestApiUrl = ({
    token,
    headers = {},
    ...rest
}: DataRequest): Promise<{data: any}> => {
    return bearerToken(axios.request)({
        token,
        headers,
        ...rest,
        baseURL: `${apiUrl}`,
    })
}

export default requestApiUrl
