const filterLabelConverter = (getLabels: (string)=> string) => {
    return (({
        filters,
    }: {
        filters: {
            count: number,
            label: string,
            value: string
        }[]
    }) => {
        return filters.map(({
            value,
            label,
            ...rest
        }) => {
            return {
                value,
                ...rest,
                label: getLabels(value) || label || value,
            }
        })
    })
}

export default filterLabelConverter
