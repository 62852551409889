import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import EngagementCreateForm from './EngagementCreateForm'
import EngagementOverview from './EngagementOverview'
import EngagementUpdate from './EngagementUpdate'
import tableConfig from './engagement.config'
import useTabsByPermission from '../../../hooks/useTabsByPermission/useTabsByPermission'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: EngagementOverview,
}
const updateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data-update',
    Component: EngagementUpdate,
}

const Engagement = () => {
    const canCreate = useHasPermission(['relationships_sales'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: updateTab,
            permission: true,
        },
    ])

    return (
        <SkyNetTable
            name="Engagement"
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            createForm={canCreate ? EngagementCreateForm : null}
            tabs={tabs}
        />
    )
}

export default Engagement
