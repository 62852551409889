import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        icon: {
            cursor: 'pointer',
            float: 'right',
        },
    }
})

export default useStyles
