import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import DomainObjectCreate from 'app/Apps/DomainObject/DomainObjectCreate'
import PreBookings from 'app/Apps/PreBooking'

import Pooling from './Pooling'
import DailyOps from './DailyOps'
import OpenBookings from './OpenBookings'
import Lanes from './Lanes'
import CustomerTransport from './Orders/CustomerTransport'
import InternalTransport from './Orders/InternalTransport'
import CreateAirlineLease from './Orders/CustomerTransport/CreateAirlineLease'
import CreateInternalTransportForm from './Orders/InternalTransport/CreateInternalTransportForm'
import CreateDirectLease from './Orders/CustomerTransport/CreateDirectLease'

const OrderManagement = () => {
    const {
        path,
    } = useRouteMatch<{path: string}>()

    return (
        <Switch>
            <Route path={`${path}/daily-ops`}>
                <DailyOps />
            </Route>
            <Route path={`${path}/pooling`}>
                <Pooling />
            </Route>
            <Route path={`${path}/open-bookings`}>
                <OpenBookings />
            </Route>
            <Route path={`${path}/lanes`}>
                <Lanes />
            </Route>
            <Route path={`${path}/customer-orders/all/create-airline-lease`}>
                <DomainObjectCreate
                    backUrl={`${path}/customer-orders`}
                    form={CreateAirlineLease}
                    name="Order"
                />
            </Route>
            <Route path={`${path}/customer-orders/all/create-direct-lease`}>
                <DomainObjectCreate
                    backUrl={`${path}/customer-orders`}
                    form={CreateDirectLease}
                    name="Order"
                />
            </Route>
            <Route path={`${path}/internal-orders/all/create`}>
                <DomainObjectCreate
                    backUrl={`${path}/internal-orders`}
                    form={CreateInternalTransportForm}
                    name="Order"
                />
            </Route>
            <Route path={`${path}/customer-orders`}>
                <CustomerTransport />
            </Route>
            <Route path={`${path}/internal-orders`}>
                <InternalTransport />
            </Route>
            <Route path={`${path}/pre-bookings`}>
                <PreBookings />
            </Route>
            <Redirect
                exact
                path={path}
                to={`${path}/orders`}
            />
        </Switch>
    )
}

export default OrderManagement
