/* eslint-disable complexity */
import React, {
    useCallback,
    useMemo,
} from 'react'
import get from 'lodash/get'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from 'app/shared-components/Card'
import DateRangeSelector from 'app/shared-components/DateRangeSelector'

import TemperatureChart from 'app/shared-components/TemperatureChart'
import TemperatureChartOptions from 'app/shared-components/TemperatureChartOptions'
import LoggerOptions from 'app/shared-components/LoggerOptions'
import useDoorEventForSensorData from 'app/hooks/useDoorEventForSensorData'
import {
    DOOR,
} from 'app/utils/optionsLoggers'
import {
    LoggerType,
} from 'app/types/enums'
import {
    LoggerPosition,
} from 'app/Apps/Loggers/Loggers.types'

import useGetSensorDataForContainer from 'app/hooks/useGetSensorData/useGetSensorDataForContainer'
import TemperatureInfoLogger from './TemperatureInfoLogger'
import useStyles from './SensorData.style'

type Props = {
    timeRange: {
        from: any,
        to: any,
    },
    printChart: () => void,
    productType?: string,
    lastMeasuredTempInternal?: number,
    lastMeasuredTempAmbient?: number,
    lastMeasuredOnUtcInternal?: number,
    lastMeasuredOnUtcAmbient?: number,
    loggerExchangedOnUtcAmbient?: number,
    loggerExchangedOnUtcInternal?: number,
    showTempRange: boolean,
    onCheckShowTempRange: (value: boolean) => void,
    setTimeRange: (args: any) => void,
    serialNumber?: string,
    configLoggers?: any[],
    setConfigLoggers: (args: any) => void,
    temperatureRange: {
        low: number,
        high: number,
    },
    isDateRange: boolean,
    setDateRange: (value: boolean) => void,
    showDoorEvent: boolean,
    onCheckShowDoorEvent: (value: boolean) => void,
    isChartPrinting: boolean,
    temperatureChartFullscreen: boolean,
    setTemperatureChartFullscreen: (value: boolean) => void,
    showVirtualLogger?: boolean,
    setShowVirtualLogger?: (value: boolean) => void,
    loggerContainerInformation: any,
}

const defaultProps: Partial<Props> = {
    serialNumber: '',
    productType: undefined,
    configLoggers: [],
    lastMeasuredTempInternal: undefined,
    lastMeasuredTempAmbient: undefined,
    lastMeasuredOnUtcInternal: undefined,
    lastMeasuredOnUtcAmbient: undefined,
    loggerExchangedOnUtcAmbient: undefined,
    loggerExchangedOnUtcInternal: undefined,
    showVirtualLogger: false,
    setShowVirtualLogger: undefined,
}

const SensorData = ({
    timeRange,
    setTimeRange,
    productType,
    serialNumber,
    lastMeasuredTempInternal,
    lastMeasuredTempAmbient,
    lastMeasuredOnUtcInternal,
    lastMeasuredOnUtcAmbient,
    loggerExchangedOnUtcAmbient,
    loggerExchangedOnUtcInternal,
    showTempRange,
    onCheckShowTempRange,
    configLoggers,
    setConfigLoggers,
    printChart,
    temperatureRange,
    isDateRange,
    setDateRange,
    showDoorEvent,
    onCheckShowDoorEvent,
    isChartPrinting,
    temperatureChartFullscreen,
    setTemperatureChartFullscreen,
    showVirtualLogger,
    setShowVirtualLogger,
    loggerContainerInformation,
}: Props) => {
    const {
        sensorData: sensorInfo,
    } = useGetSensorDataForContainer({
        serialNumber,
        from: timeRange.from,
        to: timeRange.to,
        configLoggers,
        isDateRange,
        showDoorEvents: showDoorEvent,
        showVirtualLogger,
        productType,
    })

    const {
        data: {
            sensorData,
            sensorLabels,
            sensorCodes,
        } = {} as Record<string, any>,
    } = sensorInfo || {} as Record<string, any>

    const {
        classes,
    } = useStyles()

    const {
        userOptions,
        filteredSensorData,
        customColumns,
        customData,
    } = useDoorEventForSensorData({
        sensorData,
        sensorLabels,
        sensorCodes,
        showTempRange,
        temperatureRange,
        showDoorEvent,
    })

    const getLogger = useCallback((loggerPosition) => {
        return get(find(loggerContainerInformation, (item) => {
            return item.sensorPositions.includes(loggerPosition)
                && item.isCurrentLogger && (item.logger.loggerType === LoggerType.CARTASENSE
                    || item.logger.loggerType === LoggerType.MR_813)
        }), 'logger')
    }, [loggerContainerInformation])

    const hasDoorEventOption = useMemo(() => {
        if (!configLoggers || !configLoggers[0]?.options) {
            return false
        }
        const [selectedloggerTypes] = configLoggers
        const allowedLoggerTypes = selectedloggerTypes.options.map((item) => { return item.value })
        const filterByUsedTypeLogger = loggerContainerInformation?.filter((item) => {
            return item.isCurrentLogger && allowedLoggerTypes.includes(item.logger.loggerType)
        })
        const selectedLoggerDataTypes = flatten(filterByUsedTypeLogger?.map((item) => {
            return item.logger.loggerTypeDetails.dataTypes
        }))

        return selectedLoggerDataTypes.includes(DOOR)
    }, [
        configLoggers,
        loggerContainerInformation,
    ])

    return (
        <Grid
            container
            spacing={3}
            className={classes.contentWrapper}
        >
            <Grid
                item
                xs={9}
                className={classes.chartWrapper}
            >
                <Card
                    className={classes.chartContainer}
                >
                    <Typography variant="h3">
                        Temperature Readout Container
                        {' '}
                        {serialNumber}
                        {' '}
                        (°C / UTC Time)
                    </Typography>
                    <DateRangeSelector
                        value={timeRange}
                        onChange={setTimeRange}
                        setDateRange={setDateRange}
                        showTimeRange
                        mini
                    />
                    <TemperatureChart
                        sensorData={filteredSensorData}
                        sensorLabels={sensorLabels}
                        customData={customData}
                        customColumns={customColumns}
                        isDateRange={isDateRange}
                        userOptions={userOptions}
                        isChartPrinting={isChartPrinting}
                        temperatureChartFullscreen={temperatureChartFullscreen}
                        setTemperatureChartFullscreen={setTemperatureChartFullscreen}
                    />
                </Card>
            </Grid>
            <Grid
                item
                xs={3}
                className={classes.tools}
            >
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <TemperatureChartOptions
                            sensorData={sensorData}
                            sensorCodes={sensorCodes}
                            showTempRange={showTempRange}
                            onCheckShowTempRange={onCheckShowTempRange}
                            printChart={printChart}
                            serialNumber={serialNumber}
                            showTemperatureRangeOption
                            showPdfButton
                            showCsvButton
                            isContainer
                            showDoorEventOption={hasDoorEventOption}
                            showDoorEvent={showDoorEvent}
                            onCheckShowDoorEvent={onCheckShowDoorEvent}
                            showVirtualLogger={showVirtualLogger}
                            setShowVirtualLogger={setShowVirtualLogger}
                        />
                    </Grid>
                    {!showVirtualLogger && (
                        <Grid
                            item
                            xs={12}
                        >
                            <LoggerOptions
                                value={configLoggers}
                                onChange={setConfigLoggers}
                                title="Loggers"
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                    >
                        <Card title="Internal Temperature">
                            <TemperatureInfoLogger
                                logger={getLogger(LoggerPosition.INTERNAL)}
                                lastMeasured={lastMeasuredOnUtcInternal}
                                temperature={lastMeasuredTempInternal}
                                loggerExchanged={loggerExchangedOnUtcInternal}
                            />
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Card title="Ambient Temperature">
                            <TemperatureInfoLogger
                                logger={getLogger(LoggerPosition.AMBIENT)}
                                lastMeasured={lastMeasuredOnUtcAmbient}
                                temperature={lastMeasuredTempAmbient}
                                loggerExchanged={loggerExchangedOnUtcAmbient}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

SensorData.defaultProps = defaultProps

export default SensorData
