import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        text: {
            color: theme.palette.secondary[600],
            width: '100%',
        },
    }
})

export default useStyles
