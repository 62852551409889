/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import SingleObjectSelectorConverter from 'app/shared-components/SingleObjectSelectorConverter'

import FxRateWithSelect from './FxRateWithSelect'

const FxRateObjectWithSelect = (props) => {
    return (
        <SingleObjectSelectorConverter
            {...props}
            selector={FxRateWithSelect}
        />
    )
}

export default FxRateObjectWithSelect
