import React, {
    useCallback,
} from 'react'

import useGetAccountLane from 'app/Apps/AccountManagement/AccountLanes/hooks/useGetAccountLane'
import StatusHandler from 'app/shared-components/StatusHandler'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    LaneStatus,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import SkyNetLink, {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    App,
} from 'app/types/common.enums'
import {
    Location,
} from 'app/shared-components/LocationSelector'
import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'
import Button from 'app/shared-components/Button'
import AwardRejectLaneForm from './AwardRejectLaneForm'
import useAwardLane from './hooks/useAwardLane'
import useRejectLane from './hooks/useRejectLane'
import useCancelLane from '../../hooks/useCancelLane'

const AwardRejectLane = ({
    id, refetch,
}: {
    id: number,
    refetch: (...args: any[]) => void
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAccountLane(id)

    const onSuccess = useCallback(() => {
        refetch()
        invalidate()
    }, [
        refetch,
        invalidate,
    ])

    const award = useAwardLane({
        onSuccess,
    })

    const reject = useRejectLane({
        onSuccess,
    })

    const cancelLane = useCancelLane({
        id,
        onSuccess,
    })

    const hasCompanyReadPermission = useHasPermission(['company_read'])
    const hasEditAddressPermission = useHasPermission(['location_cs'])
    const showAddressesLinks = ![
        LaneStatus.AWARDED,
        LaneStatus.IMPLEMENTED,
    ].includes(data?.laneStatus)

    const getContactManagementLink = useCallback(() => {
        return showAddressesLinks && hasCompanyReadPermission ? [<SkyNetLink
            link={`/apps/${App.CONTACT_MANAGEMENT}/companies/all`}
            text="Contact Management"
            type={TypeLink.EXTERNAL}
            size={SizeLink.S}
        />] : undefined
    }, [
        hasCompanyReadPermission,
        showAddressesLinks,
    ])

    const getEditAddressLink = useCallback(({
        id: addressId,
    }: Location) => {
        return showAddressesLinks && hasEditAddressPermission ? [<SkyNetLink
            link={`/apps/${App.CONTACT_MANAGEMENT}/locations/all/edit/${addressId}/data-update`}
            text="Edit Address"
            type={TypeLink.EXTERNAL}
            size={SizeLink.S}
        />] : undefined
    }, [
        hasEditAddressPermission,
        showAddressesLinks,
    ])

    const getCustomButtons = useCallback(({
        edited,
        isDisabledOnError,
    }) => {
        return [
            <Button
                onClick={() => { return award(id) }}
                key="award"
                name="award"
                label="Award Lane"
                disabled={edited || isDisabledOnError}
            />,
            <Button
                onClick={() => { return reject(id) }}
                key="reject"
                name="reject"
                label="Reject Lane"
                disabled={edited}
            />,
            <Button
                onClick={cancelLane}
                key="cancel_lane"
                name="cancel_lane"
                label="Cancel Lane"
                disabled={edited}
            />,
        ]
    }, [
        award,
        id,
        reject,
        cancelLane,
    ])

    return (
        <StatusHandler
            isError={isError}
            error={error}
            isFetching={isFetching}
            isSuccess={isDataReady}
        >
            <AwardRejectLaneForm
                data={data}
                disabled={data?.laneStatus === LaneStatus.AWARDED}
                onSuccess={onSuccess}
                onCancel={invalidate}
                getCustomButtons={getCustomButtons}
                requestParam={requests.updateAwardRejectLaneData({
                    id,
                })}
                getContactManagementLink={getContactManagementLink}
                getEditHandoverAddressLink={
                    () => { return getEditAddressLink(data?.handoverPoint) }
                }
                getEditCollectionAddressLink={
                    () => { return getEditAddressLink(data?.collectionDropoffPoint) }
                }
            />

        </StatusHandler>
    )
}

export default AwardRejectLane
