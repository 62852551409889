const DashboardRequest = {
    name: 'Dashboard',
    domainName: 'LaneGroup',
    uniqField: 'quoteNumber',
    url: {
        pricingBeingPriced: 'quote/filter-request/pricing-being-priced',
        pricingCompleted: 'quote/filter-request/pricing-completed',
        newQuotes: 'quote/filter-request/pricing-created',
        lanes: 'lane/filter-request/to-be-priced',
    },
}

export default DashboardRequest
