import {
    FieldInCard,
} from 'app/types/form.types'
import {
    LocationType,
} from 'app/types/enums'

import {
    UpdateLocationType,
} from '../../OrderSteps.types'

const fields: FieldInCard<keyof UpdateLocationType>[] = [
    {
        id: 'column1',
        className: 'collectionDropoffPoint',
        fields: [{
            name: 'collectionDropoffPoint',
            componentName: 'LocationObjectSelector',
            filterLocationType: LocationType.ADDRESS,
            labelKey: 'Order.collectionDropoffPoint',
            required: true,
        }],
    },
    {
        id: 'column2',
        className: 'returnedOn',
        fields: [{
            name: 'returnedOn',
            componentName: 'DateTimeZonedSelect',
            labelKey: 'Container.returnedOn',
        }],
    },
    {
        id: 'column3',
        className: 'containers',
        fields: [{
            name: 'containers',
            componentName: 'SelectedContainersUpdateLocation',
            labelKey: '',
        }],
    },
]

export default fields
