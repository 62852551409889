import {
    useCallback,
} from 'react'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import DTOSpec from 'app/services/dtoSpec'
import {
    useJWTToken,
} from 'app/Auth'
import simpleApi from 'app/services/simpleApi'
import countriesRequest from 'app/Apps/Masterdata/Countries/Countries.request'

export default () => {
    const token = useJWTToken()
    const loadOptions = useDelayedLoadOptions(countriesRequest.selector)

    const loadLabel = useCallback((id) => {
        return simpleApi({
            url: DTOSpec.Countries.Get(id),
            token,
        })
    }, [token])

    return {
        loadOptions,
        loadLabel,
    }
}
