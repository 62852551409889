import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import config from '../BookedContainers.config'
import {
    RequestParams,
} from '../bookedContainers.types'

const useRebookContainers = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'SelectedContainer',
    })

    return useMutation({
        mutationFn: ({
            orderNumber,
            orderVersion,
            selectedContainers,
        }: RequestParams) => {
            return config.RebookContainers.mutationFn({
                token,
                orderNumber,
                orderVersion,
                selectedContainers,
            })
        },
        onSuccess,
        onError,
    })
}

export default useRebookContainers
