import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    ContactType,
} from 'app/types/enums'
import {
    Contact,
} from '../Contacts/Contacts.types'

const billingAddressColumns: RichTableColumns<Partial<keyof Contact>>[] = [
    {
        id: 'contactName',
        headerProps: {
            children: 'Contact Name',
        },
        mapCellProps: 'contactName',
    },
    {
        id: 'email',
        headerProps: {
            children: 'E-mail',
        },
        mapCellProps: 'email',
    },
    {
        id: 'isMainContact',
        headerProps: {
            children: 'Main Contact',
        },
        mapCellProps: 'isMainContact',
        componentName: 'ChipCheckMark',
    },
    {
        id: 'type',
        headerProps: {
            children: 'Role',
        },
        mapCellProps: 'type',
        componentName: 'EnumList',
        allowedValuesEnum: ContactType,
    },
]

export default billingAddressColumns
