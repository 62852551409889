import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requests from 'app/Apps/ContainerManagement/Containers/Containers.request'
import {
    ContainerGeoLocation,
} from 'app/Apps/ContainerManagement/Containers/containers.types'

const useGetLastGeolocation = (
    serialNumber,
) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<ContainerGeoLocation>({
        key: RequestKeys.getContainerLastGeolocation,
        params: {
            serialNumber,
        },
        requestFunc: RequestFn.getEntity(requests.getLastGeolocation),
        enabled: Boolean(serialNumber),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetLastGeolocation
