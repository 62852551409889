import React from 'react'

import FormStatuses from 'app/shared-components/FormStatuses'
import MainMenu from 'app/MainMenu'

import useStyles from './Layout.style'

type Props = {
    children?: JSX.Element[],
}

const defaultProps: Partial<Props> = {
    children: null,
}

const Layout = ({
    children,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div
            id="layout"
            className={classes.layout}
        >
            <MainMenu>
                {children}
            </MainMenu>
            <FormStatuses />
        </div>
    )
}

Layout.defaultProps = defaultProps

export default Layout
