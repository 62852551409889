import React from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import useLoadDtoForSkyNetTable from 'app/shared-components/SkyNetTable/hooks/useLoadDtoForSkyNetTable'

import columns from './orderChangeSummary.columns'
import useStyles from './OrderChangeSummary.style'
import {
    Modification,
} from './OrderChangeSummary.types'

const OrderChangeSummary = ({
    orderNumber,
}: {orderNumber: string}) => {
    const {
        classes,
    } = useStyles()

    const load: (params: any) => Promise<{
        items: Modification,
        matches: number
    }> = useLoadDtoForSkyNetTable(
        orderManagementRequest.OrderChangeSummary.request(orderNumber),
    )

    return (
        <RichTable
            configName="OrderChangeSummaryTable"
            name={orderManagementRequest.OrderChangeSummary.name}
            load={load}
            columns={columns}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            uniqField="id"
        />
    )
}

export default OrderChangeSummary
