import React, {
    useMemo,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import requests, {
    accountConversion,
} from 'app/Apps/AccountManagement/AccountManagement.request'
import useGetAccounts from 'app/Apps/AccountManagement/hooks/useGetAccounts'

type Props = {
    value?: {
        id: number,
    }[],
    onChange: (newValue: number[]) => void,
    title?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    value: [],
    title: 'Users',
    disabled: false,
    required: false,
}

const MultipleAccountSelector = ({
    value,
    onChange,
    title,
    disabled,
    required,
}: Props) => {
    const selectorParams = useMemo(() => {
        return requests.selector()
    }, [])

    const loadOptions = useDelayedLoadOptions(selectorParams)

    const userIds = useMemo(() => {
        return value.map(({
            id,
        }) => {
            return id
        })
    }, [value])

    const accounts = useGetAccounts(userIds)

    const enhancedValue = useMemo(() => {
        if (!accounts.data) return []
        return accountConversion(accounts.data)
    }, [accounts])

    return (
        <ChipSelect
            title={title}
            value={enhancedValue}
            onChange={onChange}
            loadOptions={loadOptions}
            showTitleOptions
            disabled={disabled}
            required={required}
        />
    )
}

MultipleAccountSelector.defaultProps = defaultProps

export default MultipleAccountSelector
