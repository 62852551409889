import React from 'react'

import List from 'app/Apps/DomainObject/ComponentMapper/List'

import useStyles from './Recipients.style'

type Props = {
    value?: string[]
}

const defaultProps: Partial<Props> = {
    value: undefined,
}

const Recipients = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()

    if (!value?.length) {
        return (
            <div className={classes.wrapper}>
                No Recipients
                <img
                    className={classes.icon}
                    src="assets/images/warning.svg"
                    alt="warning"
                />
            </div>
        )
    }

    return (<List value={value} />)
}

Recipients.defaultProps = defaultProps

export default Recipients
