import React from 'react'
import clsx from 'clsx'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
    Link,
} from 'react-router-dom'

import Label from 'app/shared-components/Label'
import LabelPanel from 'app/shared-components/LabelPanel'

import useStyles from './LinkTo.styles'
import {
    NotificationIconProps,
} from '../NotificationIcon/notificationIcon.types'

const defaultProps = {
    value: undefined,
    link: undefined,
    title: '',
    customIcon: undefined,
    newTab: false,
    classNames: undefined,
    state: undefined,
    notificationIconProps: undefined,
}

const LinkTo = ({
    title,
    link,
    value,
    customIcon: CustomIcon,
    newTab,
    classNames,
    state,
    notificationIconProps,
}: {
    title?: string,
    link?: string,
    value?: string,
    state?: Record<string, any>,
    newTab?: boolean,
    customIcon?: () => JSX.Element,
    classNames?: Record<string, string>,
    notificationIconProps?: NotificationIconProps,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.root}>
            {title
            && (
                <LabelPanel notificationIconProps={notificationIconProps}>
                    <Label title={title} />
                </LabelPanel>
            )}
            {link
                ? (
                    <div className={clsx(classNames?.text, classes.wrapper)}>
                        <div>{value}</div>
                        <Link
                            to={{
                                pathname: link,
                                state,
                            }}
                            className={clsx(classes.linkInput)}
                            target={newTab ? '_blank' : undefined}
                            rel={newTab ? 'noopener noreferrer' : undefined}
                        >
                            { CustomIcon
                                ? <CustomIcon />
                                : (
                                    <ArrowForwardIcon className={classes.link} />
                                )}
                        </Link>
                    </div>
                ) : '-'}
        </div>
    )
}

LinkTo.defaultProps = defaultProps

export default LinkTo
