import React, {
    useCallback,
    useState,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    CancellationInfo,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useCancelOrderRequest from './hooks/useCancelOrderRequest'
import getFields from './CancellationOrderDialog.fields'
import useStyles from './CancellationOrderDialog.style'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    orderNumber: string,
    onSuccess: () => void
}

const DIALOG_TITLE = 'CANCELLATION CONFIRMATION'

const CancellationOrderDialog = ({
    open,
    setOpen,
    orderNumber,
    onSuccess,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const [
        value,
        setValue,
    ] = useState<CancellationInfo>()

    const onSuccessCancellation = useCallback((): void => {
        setOpen(false)
        onSuccess()
    }, [
        onSuccess,
        setOpen,
    ])

    const {
        isPending: isLoading,
        mutate,
    } = useCancelOrderRequest(onSuccessCancellation)

    const cancelOrder = useCallback((): void => {
        mutate({
            orderNumber,
            data: value,
        })
    }, [
        mutate,
        orderNumber,
        value,
    ])

    const handleClose = useCallback((action: boolean) => {
        if (!action) {
            setOpen(false)
            setValue(undefined)

            return
        }

        cancelOrder()
    }, [
        cancelOrder,
        setOpen,
    ])

    const fields = getFields(getAllowedValues)

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="cancel order"
            negativeLabel="do not cancel order"
            classNames={{
                paper: classes.paper,
            }}
            isLoading={isLoading}
        >
            <DomainObjectForm
                fields={fields}
                name="OrderByNumberView"
                value={value}
                onChange={setValue}
                className={classes.gridWrapper}
                wrapper="simple"
            />
        </ConfirmationDialog>
    )
}

export default CancellationOrderDialog
