import React from 'react'
import PropTypes from 'prop-types'

import useStyles from '../ContactForm/ContactForm.style'

const propTypes = {
    disabled: PropTypes.bool,
}

const defaultProps = {
    disabled: false,
}
const ContactNameComment = ({
    disabled,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        disabled ? null
            : (
                <span className={classes.contactNameComment}>
                    Please add both a name and surname as
                    this helps Ops to find the correct person in an organization.
                </span>
            )
    )
}

ContactNameComment.propTypes = propTypes
ContactNameComment.defaultProps = defaultProps

export default ContactNameComment
