import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

const config:SkyNetTableConfig = {
    name: 'UserTable',
    tabs: [{
        name: 'All Users',
        url: 'all',
    }],
    fields: [
        {
            name: 'userName',
            labelKey: 'Users.userName',
            reduced: true,
        },
        {
            name: 'email',
            labelKey: 'Contact.email',
        },
        {
            name: 'locationLocationName',
            labelKey: 'Contact.location',
            filterType: FilterType.Array,
        },
    ],
}

export default config
