import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        noWrap: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        containerStatusEnriched: {
            borderRadius: 16,
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
            background: theme.palette.common.beige,
            color: theme.palette.common.orange,
            lineHeight: 'normal',
        },
        containerStatus: {
            borderRadius: 8,
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: 'normal',
            marginLeft: -5,
            background: theme.palette.secondary[200],
            color: theme.palette.secondary[600],
            padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
        },
        icon: {
            width: 14,
            height: 14,
            marginLeft: -5,
        },
    }
})

export default useStyles
