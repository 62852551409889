import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        title: {
            '&&': {
                marginBottom: theme.spacing(3),
            },
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'static',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
