import {
    RichTableColumns,
} from 'app/types/richtable.types'
import formatCurrencyValue from 'app/utils/formatCurrencyValue'

import {
    BestOptionType,
} from '../../../createAutomaticallyForm.types'

const getRoadColumns = (l: (l:string) => string): RichTableColumns<keyof BestOptionType>[] => {
    return [
        {
            id: 'forwarder',
            headerProps: {
                children: l('OrderStep.forwarder'),
            },
            mapCellProps: 'forwarder',
        },
        {
            id: 'destinationLocation',
            headerProps: {
                children: l('BestOptions.destinationLocation'),
            },
            mapCellProps: 'destinationLocation',
        },
        {
            id: 'containerLoad',
            headerProps: {
                children: l('BestOptions.containerLoad'),
            },
            mapCellProps: 'containerLoad',
        },
        {
            id: 'leadTime',
            headerProps: {
                children: l('BestOptions.leadTime'),
            },
            mapCellProps: 'leadTime',
        },
        {
            id: 'price',
            headerProps: {
                children: l('BestOptions.price'),
            },
            mapCellProps: ({
                price,
            }) => {
                return {
                    children: price ? `${formatCurrencyValue(price)} $` : '',
                }
            },
        },
        {
            id: 'validUntil',
            headerProps: {
                children: l('BestOptions.validUntil'),
            },
            mapCellProps: 'validUntil',
            componentName: 'LaneDateEnriched',
        },
    ]
}

export default getRoadColumns
