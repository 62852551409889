import simpleApi from 'app/services/simpleApi'

const configRequest = {
    ModifyGeneralFreights: {
        mutationFn: ({
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/general-freights?modificationType=INTERNAL`,
                data,
                method: 'PUT',
                token,
            })
        },
    },
    ModifyRequiredContainers: {
        mutationFn: ({
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/required-containers-internal?modificationType=INTERNAL`,
                data,
                method: 'PATCH',
                token,
            })
        },
    },
}

export default configRequest
