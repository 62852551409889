import React from 'react'

import useLabel from 'app/hooks/useLabel'
import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'

type Props = {
    value?: string,
    configName: string,
    mapCellProps: string,
}

const defaultProps = {
    value: null,
}

const getFieldLabel = (
    fieldName: string,
    l: (key: string)=> string,
    labelPrefixKeys: string[],
): null | string => {
    let i = 0
    let label = null

    while (!label && i < labelPrefixKeys.length) {
        label = l(`${labelPrefixKeys[i]}.${fieldName}`)
        i += 1
    }
    return label
}

const ModifiedField = ({
    value,
    configName,
    mapCellProps,
}: Props) => {
    const l = useLabel()
    const fieldConfig = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })
    const labelPrefixKeys = fieldConfig?.labelPrefixKeys || []

    if (!value) {
        return null
    }
    const label = getFieldLabel(value, l, labelPrefixKeys)

    return (
        <div>{label || String(value).charAt(0).toUpperCase() + String(value).slice(1)}</div>
    )
}

ModifiedField.defaultProps = defaultProps

export default ModifiedField
