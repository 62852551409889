import {
    UseRequestConfig,
    RequestConfig,
} from 'app/types/request.types'

type SimulationRequests = {
    name: string,
    ProductTypes: UseRequestConfig,
    Simulation: RequestConfig,
    TemperatureSimulation: UseRequestConfig,
}

const config: SimulationRequests = {
    name: 'TransportSimulation',
    ProductTypes: {
        requestFn: () => {
            return {
                url: 'transportsimulation/producttype/all',
            }
        },
    },
    Simulation: {
        url: 'transportsimulation',
    },
    TemperatureSimulation: {
        requestFn: (params) => {
            return {
                url: 'temperature-simulation/v3',
                method: 'POST',
                data: params,
            }
        },
    },
}

export default config
