import {
    FieldInCard,
} from 'app/types/form.types'

import {
    SimulationStep,
    SimulationStepType,
} from '../../simulation.types'

const allowedValues = {
    70: '70kg pellets',
    110: '110kg blocks',
}

const reIcingFields: FieldInCard<keyof SimulationStep>[] = [{
    name: 'amountCoolingGel',
    componentName: 'Select',
    allowedValues,
    labelKey: 'SimulationStep.amountCoolingGel',
}]
const palletFields: FieldInCard<keyof SimulationStep>[] = [
    {
        name: 'palletTemperature',
        componentName: 'IntegerInput',
        suffix: '°C',
        labelKey: 'SimulationStep.palletTemperature',
    },
    {
        name: 'palletWeight',
        componentName: 'IntegerInput',
        suffix: 'kg',
        labelKey: 'SimulationStep.palletWeight',
    },
]
const defaultFields: FieldInCard<keyof SimulationStep>[] = [
    {
        name: 'ambientTemperature',
        className: 'ambientTemperature',
        componentName: 'IntegerInput',
        suffix: '°C',
        labelKey: 'SimulationStep.ambientTemperature',
        required: true,
    },
    {
        name: 'durationHours',
        className: 'durationHours',
        componentName: 'IntegerInput',
        suffix: 'h',
        min: 0,
        labelKey: 'SimulationStep.durationHours',
        required: true,
    },
    {
        name: 'durationMinutes',
        className: 'durationMinutes',
        componentName: 'IntegerInput',
        suffix: 'm',
        step: 5,
        min: 0,
        max: 55,
        onKeyPress: (e) => { e.preventDefault() },
        labelKey: 'SimulationStep.durationMinutes',
        required: true,
    },
]

const getFieldsByType = (type: SimulationStepType) => {
    if (type === SimulationStepType.LOAD_PALLET) {
        return palletFields
    }

    if (type === SimulationStepType.RE_ICING) {
        return reIcingFields
    }

    return defaultFields
}

const getFields = (
    type: SimulationStepType,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): FieldInCard<keyof SimulationStep>[] => {
    return [
        ...getFieldsByType(type),
        {
            name: 'type',
            componentName: 'Select',
            allowedValues: getAllowedValues(SimulationStepType),
            labelKey: 'SimulationStep.type',
            required: true,
        },
        {
            name: 'location',
            componentName: 'InputSingleline',
            labelKey: 'SimulationStep.location',
        },
    ]
}

export default getFields
