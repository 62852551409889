import {
    FieldInCard,
} from 'app/types/form.types'

import {
    ContainerStatusChangeNote,
} from '../../../ContainerNotes.types'

import {
    ChangeConfig, AuditTrailConfig, StatusChangeConfig,
} from './notesConfigs'

type Fields = keyof ContainerStatusChangeNote

const fields: FieldInCard<Fields>[] = [
    ChangeConfig,
    StatusChangeConfig,
    {
        title: 'Location',
        className: 'location',
        fields: [{
            name: 'location',
            componentName: 'LocationObjectSelector',
            labelKey: 'Note.location',
        }],
    },
    AuditTrailConfig,
]

export default fields
