import React from 'react'
import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectFormStatuses,
} from './store'

import FormStatuses from './FormStatuses'

const FormStatusesContainer = () => {
    const {
        pending,
        finished,
    } = useAppSelector(selectFormStatuses)

    return (
        <FormStatuses
            pending={pending}
            finished={finished}
        />
    )
}

export default FormStatusesContainer
