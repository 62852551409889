import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    PostponeInfo,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import request from '../../customerTransport.request'

type RequestParams = {
    orderNumber: string,
    data: PostponeInfo,
}

const usePostponeRequest = (onSuccess: () => void)
    : UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessPostpone,
        onError,
    } = useHandleStatusRequest({
        id: 'PostponementView',
        title: 'Order Postpone',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            orderNumber,
            data,
        }: RequestParams) => {
            return request.PostponeOrder.mutationFn({
                orderNumber,
                data,
                token,
            })
        },
        onSuccess: onSuccessPostpone,
        onError,
    })
}

export default usePostponeRequest
