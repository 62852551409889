import React, {
    useCallback,
} from 'react'

import Card from 'app/shared-components/Card'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'

import {
    TypeWithId,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'
import useEnumValues from 'app/hooks/useEnumValues'
import printFormFields from './printForm.fields'
import {
    PrintFilterValues,
    QUOTATION,
    ContactsSelection,
} from './PrintForm.types'

import useStyles from './PrintFiltersForm.style'
import getContactSelectionFields from './contactSelection.fields'

type Props = {
    contractId: number,
    laneGroups: TypeWithId[],
    state: Partial<PrintFilterValues>,
    setState: (value:Partial<PrintFilterValues>)=>void,
    disabled?: boolean,
    contactsSelections: ContactsSelection,
    onContactsSelectionsChange: (newValue: any) => void,
    showContactsSelections?: boolean,
}

const defaultProps: Partial<Props> = {
    disabled: false,
    showContactsSelections: true,
}

const PrintFiltersForm = ({
    contractId,
    laneGroups,
    state,
    setState,
    disabled,
    contactsSelections,
    onContactsSelectionsChange,
    showContactsSelections,
}:Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const fields = useFieldsWithClassName(printFormFields({
        state,
        contractId,
        disabledAll: disabled,
        laneGroups,
        getAllowedValues,
    }), classes)

    const onChange = useCallback((newValue: Partial<PrintFilterValues>) => {
        const modifiedNewValue = {
            ...newValue,
            isCustomerQuotation: newValue.customerType === QUOTATION,
            selectedAirports: newValue.restrictAirports ? newValue.selectedAirports : undefined,
            selectedCustomerExecution: newValue.selectedCustomerExecution,
        }

        setState(modifiedNewValue)
    }, [setState])

    const contactsSelectionsFields = useFieldsWithClassName(getContactSelectionFields({
        value: contactsSelections,
        disabledAll: disabled,
    }), classes)

    return (
        <>
            <Card
                fullHeight
                contentClass={classes.cardContent}
            >
                <DomainObjectForm
                    name="LaneQuoteRequest"
                    value={state}
                    fields={fields}
                    classNames={{
                        gridWrapper: classes.gridWrapper,
                    }}
                    wrapper="noCard"
                    onChange={onChange}
                    className={classes.fields}
                    disabled={disabled}
                />
            </Card>
            { showContactsSelections && (
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="createquote-additionalFees-card"
                >
                    <DomainObjectForm
                        value={contactsSelections}
                        name="CreateQuote.PrintPreviewDataForm.ContactSelection"
                        fields={contactsSelectionsFields}
                        classNames={{
                            gridWrapper: classes.contactsGridWrapper,
                        }}
                        wrapper="noCard"
                        className={classes.fields}
                        onChange={onContactsSelectionsChange}
                        disabled={disabled}
                    />
                </Card>
            )}
        </>
    )
}

PrintFiltersForm.defaultProps = defaultProps

export default PrintFiltersForm
