import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        contactsContainer: {
            padding: theme.spacing(2, 0, 0, 2),
            width: '100%',
        },
        cardRoot: {
            overflow: 'auto !important',
        },
        cardContent: {
            paddingRight: 0,
            paddingLeft: 0,
        },
        container: {
            minHeight: 200,
            width: '100%',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: theme.spacing(2),
        },
        selectedLocationType: {
            flexDirection: 'column',
        },
        overlay: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0, 0.2)',
        },
        text: {
            color: theme.palette.secondary[600],
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            marginBottom: theme.spacing(3),
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        table: {
            minHeight: 200,
        },
    }
})

export default useStyles
