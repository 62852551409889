import React, {
    useMemo,
} from 'react'
import {
    useUserRoles,
} from 'app/Auth'

import mainMenuConfig from 'app/mainMenuConfig'

import Dashboard from './Dashboard'

const DashboardContainer = () => {
    const roles = useUserRoles()

    const widgets = useMemo(() => {
        return mainMenuConfig
            .reduce((acum, item) => {
                return [
                    ...acum,
                    {
                        ...item,
                        links: item.links.filter(({
                            auth,
                        }) => {
                            if (!auth) {
                                return true
                            }
                            return auth.some((role) => {
                                return roles.includes(role)
                            })
                        }),
                    },
                ]
            }, [])
            .filter(({
                links,
            }) => {
                return links.length > 0
            })
    }, [roles])

    return (
        <Dashboard
            widgets={widgets}
        />
    )
}

export default DashboardContainer
