import React from 'react'
import {
    Checkbox,
} from '@mui/material'

import useStyles from './OptionCheckboxDropdown.style'

type Props = {
    label: string,
    checked: boolean,
    value: string | number,
    index: number,
    onClick: (e: React.SyntheticEvent<EventTarget>) => void,
}

const OptionCheckboxDropdown = ({
    label,
    checked,
    onClick,
    value,
    index,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div
            role="button"
            data-testid={`button-${value}`}
            tabIndex={index + 1}
            key={`option-${value}`}
            onClick={onClick}
            onKeyDown={onClick}
            className={classes.optionWrapper}
            data-value={value}
        >
            <Checkbox
                value={checked}
                checked={checked}
                onChange={onClick}
                size="small"
                classes={{
                    root: classes.checkbox,
                }}
            />
            <span className={classes.option}>{label}</span>
        </div>
    )
}

export default OptionCheckboxDropdown
