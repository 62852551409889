import {
    FieldInCard,
} from 'app/types/form.types'
import {
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'

type Args = {
    baseLeaseUntilTimestamp: string,
    informationEntered: string,
    locationId: number,
    exists: boolean,
}

type ConfirmationForm = OrderStep & {baseLeaseUntilTimestamp: string}

type ConfirmationFormFields = keyof ConfirmationForm

const getInternalConfirmationFormFields = ({
    baseLeaseUntilTimestamp,
    informationEntered,
    locationId,
    exists,
}: Args): FieldInCard<ConfirmationFormFields>[] => {
    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'actualExecution',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId,
                    labelKey: 'OrderStep.actualExecution',
                },
                {
                    name: 'informationReceived',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.informationReceived',
                },
                {
                    name: 'informationEntered',
                    componentName: 'DateTimeZonedSelect',
                    value: informationEntered,
                    labelKey: 'OrderStep.informationEntered',
                },
                {
                    name: 'baseLeaseUntilTimestamp',
                    componentName: 'InputSingleline',
                    value: baseLeaseUntilTimestamp,
                    disabled: true,
                    labelKey: 'OrderBilling.baseLeaseUntilTimestamp',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'location',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderStep.location',
                },
                {
                    name: 'locationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: exists,
                    selectedLocation: locationId,
                    labelKey: 'OrderStep.locationContacts',
                },
            ],
        },
        {
            id: 'column3',
            fields: [{
                name: 'comment',
                componentName: 'LongText',
                labelKey: 'OrderStep.comment',
            }],
        },
    ]
}

export default getInternalConfirmationFormFields
