const containerTemperatureRanges = {
    C: {
        low: 2,
        high: 8,
    },
    CRT: {
        low: 15,
        high: 25,
    },
    F: {
        low: -25,
        high: -15,
    },
    DF: {
        low: -90,
        high: -20,
    },
}

export default containerTemperatureRanges
