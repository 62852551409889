import React, {
    useCallback, useMemo,
} from 'react'

import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import ChipSelect from 'app/shared-components/ChipSelect'
import DTOSpec from 'app/services/dtoSpec'

type Recipient = {
    label: string,
    value: string
}

type Props = {
    value?: string[],
    onChange: (newValue: string[]) => void
    title?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps = {
    value: [],
    title: 'Recipients',
    disabled: false,
    required: false,
}

const RecipientSelector = ({
    value,
    onChange,
    title,
    disabled,
    required,
}: Props) => {
    const recipients: Recipient[] = useMemo(() => {
        return value.map((item) => {
            return {
                label: item,
                value: item,
            }
        })
    }, [value])

    const loadOptions = useDelayedLoadOptions({
        url: DTOSpec.Contact.Selector,
        convertion: (contacts) => {
            return contacts.map(({
                email,
            }) => {
                return {
                    value: email,
                    label: email,
                }
            })
        },
    })

    const onChangeValue = useCallback((newValue: Recipient[]) => {
        onChange(newValue ? newValue.map((item) => {
            return item.value
        }) : [])
    }, [onChange])

    return (
        <ChipSelect
            title={title}
            value={recipients}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            showTitleOptions
            disabled={disabled}
            required={required}
        />
    )
}

RecipientSelector.defaultProps = defaultProps

export default RecipientSelector
