import {
    RequestConfig,
    RequestForDomainForm,
} from 'app/types/request.types'

type ContainerNoteRequests = {
    filterRequest: ({
        containerId,
        sorting,
        rows,
        start,
    }) => RequestConfig,
    get: ({
        id,
    }) => RequestConfig,
    create: RequestForDomainForm<undefined>
}

export default {
    domainName: 'ContainerNoteView',
    filterRequest: ({
        containerId,
        sorting,
        rows,
        start,
    }) => {
        return {
            url: 'container/containernote/filter-request/all',
            method: 'POST',
            data: {
                includeFilters: {
                    containerId: [containerId],
                },
                ...sorting,
                rows,
                start,
            },
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `container/containernote/${id}`,
            method: 'GET',
        }
    },
    create: {
        name: 'ContainerNoteView',
        request: () => {
            return {
                url: 'container/containernote',
                method: 'POST',
                domainName: 'ContainerNoteView',
            }
        },
    },
} as ContainerNoteRequests
