import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

import useAddedItem from 'app/shared-components/SkyNetTable/hooks/useAddedItem'
import AccountLaneForm from '../AccountLaneForm'
import request from '../AccountLanes.requests'

const AccountLaneCreate = () => {
    const onSuccess = useAddedItem()
    const {
        state,
    } = useLocation()

    return (
        <AccountLaneForm
            data={{
                ...state?.autofill,
            }}
            requestParam={request.create()}
            onSuccess={onSuccess}
        />
    )
}

export default AccountLaneCreate
