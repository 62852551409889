import {
    DefaultTruckingComment,
    TransportModeRoad,
    OrderStepFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LocationType,
} from 'app/types/enums'
import {
    FieldInCard,
} from 'app/types/form.types'

type Args = {
    forwarderId: number,
    pickupLocationId: number,
    deliveryLocationId: number,
    filterLocationType: LocationType,
    transportModeRoad: TransportModeRoad,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    freightRateServiceProviderCode: string,
}

const getInternalAirFormFields = ({
    pickupLocationId,
    deliveryLocationId,
    forwarderId,
    transportModeRoad,
    getAllowedValues,
    freightRateServiceProviderCode,
}: Args): FieldInCard<OrderStepFields>[] => {
    const trucksAndVans: FieldInCard<OrderStepFields>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'OrderStep.numberOfTrucks',
            disabled: true,
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'OrderStep.numberOfVans',
            disabled: true,
        },
    ]

    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'forwarder',
                    componentName: 'LocationObjectSelector',
                    freightRateServiceProviderCode,
                    required: true,
                    labelKey: 'OrderStep.forwarder',
                },
                {
                    name: 'forwarderContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    labelKey: 'OrderStep.forwarderContacts',
                    existedForm: true,
                    selectedLocation: forwarderId,
                },
                {
                    name: 'mawbReceived',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.mawbReceived',
                    disabled: true,
                },
                {
                    name: 'mawbNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.mawbNumber',
                    disabled: true,
                },
                {
                    name: 'mawbDocumentCopy',
                    componentName: 'AttachmentsObjectSelector',
                    labelKey: 'OrderStep.mawbDocumentCopy',
                    disabled: true,
                },
                {
                    name: 'transportModeRoad',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'OrderStep.transportModeRoad',
                    disabled: true,
                },
                ...transportModeRoad === TransportModeRoad.FTL ? trucksAndVans as [] : [],
                {
                    name: 'isTemperatureControlled',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.isTemperatureControlled',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'pickupLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.pickupLocation',
                    disabled: true,
                },
                {
                    name: 'pickupLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: true,
                    selectedLocation: pickupLocationId,
                    labelKey: 'OrderStep.pickupLocationContacts',
                },
                {
                    name: 'requestedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.requestedPickup',
                },
                {
                    name: 'estimatedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.estimatedPickup',
                },
                {
                    name: 'pickupComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.pickupComment',
                    disabled: true,
                },
                {
                    name: 'defaultTruckingComment',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DefaultTruckingComment),
                    useDropdown: true,
                    labelKey: 'OrderStep.defaultTruckingComment',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'deliveryLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.deliveryLocation',
                    disabled: true,
                },
                {
                    name: 'deliveryLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: true,
                    selectedLocation: deliveryLocationId,
                    labelKey: 'OrderStep.deliveryLocationContacts',
                },
                {
                    name: 'requestedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.requestedDelivery',
                },
                {
                    name: 'estimatedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.estimatedDelivery',
                },
                {
                    name: 'deliveryComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.deliveryComment',
                },
                {
                    name: 'truckingComment',
                    componentName: 'LongText',
                    className: 'truckingComment',
                    labelKey: 'OrderStep.truckingComment',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column4',
            fields: [
                {
                    name: 'airline',
                    componentName: 'CompanyObjectSelector',
                    labelKey: 'OrderStep.airline',
                },
                {
                    name: 'originAirport',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderStep.originAirport',
                    disabled: true,
                },
                {
                    name: 'destinationAirport',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderStep.destinationAirport',
                    disabled: true,
                },
                {
                    name: 'flightComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.flightComment',
                },
            ],
        },
    ]
}

export default getInternalAirFormFields
