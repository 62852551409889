import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        item: {
            display: 'flex',
            fontSize: theme.typography.h5.fontSize,
            backgroundColor: theme.palette.secondary[200],
            padding: '5px 10px 5px 10px',
            whiteSpace: 'nowrap',
            borderRadius: '6px 6px 0px 0px',
            color: theme.palette.secondary[600],
            textDecoration: 'none',
            marginRight: theme.spacing(1.25),
            verticalAlign: 'middle',
            lineHeight: 2,
        },
        'item &:hover': {
            cursor: 'pointer',
        },
        active: {
            borderWidth: 1,
            zIndex: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.secondary[300],
            backgroundColor: theme.palette.secondary[50],
            borderBottom: `2px solid ${theme.palette.secondary[50]}`,
        },
        counter: {
            background: theme.palette.secondary[600],
            padding: theme.spacing(0.5, 1),
            borderRadius: 5,
            marginLeft: theme.spacing(0.5),
            color: theme.palette.common.white,
            display: 'flex',
            alignItems: 'center',
        },
    }
})

export default useStyles
