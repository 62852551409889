import {
    useMemo,
} from 'react'

import {
    StepConfiguration,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import getFieldsWithConfiguration from '../utils/getFieldsWithConfiguration'

const useGetFieldsWithConfiguration = (
    relationIdFieldsData: StepConfiguration,
    fieldsWithClassName: {
        id: string,
        fields: Record<string, any>[],
    }[],
    disabled: boolean,
) => {
    return useMemo((): {
        id: string,
        fields: Record<string, any>,
    }[] => {
        if (!relationIdFieldsData?.fields) {
            return fieldsWithClassName
        }

        const {
            fields: fieldsWithSettings,
        } = relationIdFieldsData

        return fieldsWithClassName
            .map((item) => {
                return {
                    ...item,
                    fields: getFieldsWithConfiguration(
                        item.fields,
                        fieldsWithSettings,
                        disabled,
                    ),
                }
            })
            .filter(({
                fields,
            }) => {
                return fields.length
            })
    }, [
        disabled,
        fieldsWithClassName,
        relationIdFieldsData,
    ])
}

export default useGetFieldsWithConfiguration
