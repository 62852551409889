import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    App,
} from 'app/types/common.enums'

import useStyles from './KamDashboard.style'
import kamUpcomingConfig from './configs/kamUpcoming.config'
import kamToBeCompletedConfig from './configs/kamToBeCompleted.config'
import kamDashboardConfig from './configs/kamCompleted.config'
import preBookingsConfig from './configs/preBookings.config'
import dashboardRequest from './KamDashboard.requests'
import ToBeAssignedLanes from './ToBeAssignedLanes'
import orderManagementRequest from '../../OrderManagement/services/orderManagement.request'

const DOMAIN_NAME = 'KamDashboard'
const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const KamDashboard = () => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()

    const onRowClick = useCallback(({
        id: idQuote,
    }: {id: number}) => {
        return history.push(`/apps/${App.ACCOUNT_MANAGEMENT}/quotes/all/edit/${idQuote}/overview`)
    }, [history])

    const onRowClickPreBookings = ({
        id,
    }: {id: number}): void => {
        history.push(orderManagementRequest.PreBookings.moveToUrl(id))
    }

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Dashboard
                <div className={classes.actionWrapper} />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.upcoming}
                    tableConfig={kamUpcomingConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.toBeCompleted}
                    tableConfig={kamToBeCompletedConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.completed}
                    tableConfig={kamDashboardConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>Unassigned Lanes</div>
            <div className={classes.tableWrapper}>
                <ToBeAssignedLanes />
            </div>
            <div className={classes.headerTitle}>
                Pre Bookings
                <div className={classes.actionWrapper} />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClickPreBookings}
                    customUrl={dashboardRequest.url.preBookings}
                    tableConfig={preBookingsConfig}
                    tableControls={tableControls}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                    data-testid="preBookingsTransports"
                />
            </div>
        </div>
    )
}

export default KamDashboard
