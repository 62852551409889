import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '& .MuiStepContent-root': {
                borderLeft: `1px solid ${theme.palette.secondary[200]}`,
            },
            '& .MuiStepConnector-line': {
                borderLeft: `1px solid ${theme.palette.secondary[200]}`,
            },
        },
        label: {
            '& .MuiStepIcon-text': {
                fontSize: theme.typography.body1.fontSize,
                fill: theme.palette.common.white,
            },
            '& .MuiStepLabel-label': {
                fontSize: theme.typography.h3.fontSize,
            },
        },
    }
})

export default useStyles
