import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        h3: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
