import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            flexBasis: '25%',
            flexGrow: 0,
            maxWidth: '25%',
            padding: theme.spacing(2, 0, 0, 2),
            boxSizing: 'border-box',
            flexDirection: 'row',
        },
        invoiceCardContainer: {
            padding: theme.spacing(1),
        },
        selectContainer: {
            padding: theme.spacing(2),
        },
        selected: {
            backgroundColor: theme.palette.primary.light,
        },
        invoiceCard: {
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.common.white,
            boxShadow: `0px 1px 3px ${theme.palette.secondary.main}`,
            borderRadius: 4,
            height: 300,
            overflow: 'visible !important',
            position: 'relative',
            textAlign: 'center',
        },
        clickableCard: {
            cursor: 'pointer',
        },
        titleContainer: {
            borderRadius: '4px 4px 0 0',
            color: theme.palette.common.white,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        headerBlue: {
            backgroundColor: theme.palette.primary[400],
        },
        headerGray: {
            backgroundColor: theme.palette.secondary[600],
        },
        iconContainer: {
            position: 'absolute',
            right: 5,
            top: 6,
        },
        creditNoteIconContainer: {
            position: 'absolute',
            left: 5,
            top: 6,
        },
        creditNoteIcon: {
            height: 28,
            width: 24,
        },
        title: {
            textAlign: 'center',
            maxWidth: 290,
            margin: 'auto',
        },
    }
})

export default useStyles
