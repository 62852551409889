import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import requestConfig from '../../../createAutomaticallyForm.request'
import {
    AutomaticallyFormType,
} from '../../../createAutomaticallyForm.types'

const useGetOrderSteps = ({
    bestOption,
    ...rest
}: Partial<AutomaticallyFormType>) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<OrderStep[]>({
        key: RequestKeys.getPopulationOrderStep,
        params: {
            params: {
                optionSelected: bestOption,
                request: rest,
            },
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetOrderSteps.requestFn),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetOrderSteps
