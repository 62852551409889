export enum DamageType {
    SERIAL_NUMBER_FRONT_SIDE = 'SERIAL_NUMBER_FRONT_SIDE',
    SERIAL_NUMBER_RIGHT_SIDE = 'SERIAL_NUMBER_RIGHT_SIDE',
    SERIAL_NUMBER_LEFT_SIDE = 'SERIAL_NUMBER_LEFT_SIDE',
    SERIAL_NUMBER_REAR_SIDE = 'SERIAL_NUMBER_REAR_SIDE',
    IATA_LABEL_FRONT_SIDE = 'IATA_LABEL_FRONT_SIDE',
    IATA_LABEL_RIGHT_SIDE = 'IATA_LABEL_RIGHT_SIDE',
    IATA_LABEL_LEFT_SIDE = 'IATA_LABEL_LEFT_SIDE',
    IATA_LABEL_REAR_SIDE = 'IATA_LABEL_REAR_SIDE',
    PROTECTIVE_FOIL_FRONT_SIDE = 'PROTECTIVE_FOIL_FRONT_SIDE',
    PROTECTIVE_FOIL_RIGHT_SIDE = 'PROTECTIVE_FOIL_RIGHT_SIDE',
    PROTECTIVE_FOIL_LEFT_SIDE = 'PROTECTIVE_FOIL_LEFT_SIDE',
    PROTECTIVE_FOIL_REAR_SIDE = 'PROTECTIVE_FOIL_REAR_SIDE',
    FEET_DAMAGED_1 = 'FEET_DAMAGED_1',
    FEET_DAMAGED_2 = 'FEET_DAMAGED_2',
    FEET_DAMAGED_3 = 'FEET_DAMAGED_3',
    FEET_DAMAGED_4 = 'FEET_DAMAGED_4',
    FLOOR_PANEL_SEPARATION = 'FLOOR_PANEL_SEPARATION',
    DIE_CAST_FEET_DAMAGED_1 = 'DIE_CAST_FEET_DAMAGED_1',
    DIE_CAST_FEET_DAMAGED_2 = 'DIE_CAST_FEET_DAMAGED_2',
    DIE_CAST_FEET_DAMAGED_3 = 'DIE_CAST_FEET_DAMAGED_3',
    DIE_CAST_FEET_DAMAGED_4 = 'DIE_CAST_FEET_DAMAGED_4',
    DIE_CAST_FEET_DAMAGED_5 = 'DIE_CAST_FEET_DAMAGED_5',
    DIE_CAST_FEET_DAMAGED_6 = 'DIE_CAST_FEET_DAMAGED_6',
    DIE_CAST_FEET_DAMAGED_7 = 'DIE_CAST_FEET_DAMAGED_7',
    DIE_CAST_FEET_DAMAGED_8 = 'DIE_CAST_FEET_DAMAGED_8',
    SMALL_STRESS_CRACK_RIGHT_SIDEWALL = 'SMALL_STRESS_CRACK_RIGHT_SIDEWALL',
    LARGE_STRESS_CRACK_RIGHT_SIDEWALL = 'LARGE_STRESS_CRACK_RIGHT_SIDEWALL',
    LARGE_AND_DEEP_SCRATCH_RIGHT_SIDEWALL = 'LARGE_AND_DEEP_SCRATCH_RIGHT_SIDEWALL',
    SMALL_SCRATCH_OR_DENT_RIGHT_SIDEWALL = 'SMALL_SCRATCH_OR_DENT_RIGHT_SIDEWALL',
    IMPACT_DAMAGE_RIGHT_SIDEWALL = 'IMPACT_DAMAGE_RIGHT_SIDEWALL',
    FORKLIFT_PUNCTURE_RIGHT_SIDEWALL = 'FORKLIFT_PUNCTURE_RIGHT_SIDEWALL',
    SMALL_STRESS_CRACK_LEFT_SIDEWALL = 'SMALL_STRESS_CRACK_LEFT_SIDEWALL',
    LARGE_STRESS_CRACK_LEFT_SIDEWALL = 'LARGE_STRESS_CRACK_LEFT_SIDEWALL',
    LARGE_AND_DEEP_SCRATCH_LEFT_SIDEWALL = 'LARGE_AND_DEEP_SCRATCH_LEFT_SIDEWALL',
    SMALL_SCRATCH_OR_DENT_LEFT_SIDEWALL = 'SMALL_SCRATCH_OR_DENT_LEFT_SIDEWALL',
    IMPACT_DAMAGE_LEFT_SIDEWALL = 'IMPACT_DAMAGE_LEFT_SIDEWALL',
    FORKLIFT_PUNCTURE_LEFT_SIDEWALL = 'FORKLIFT_PUNCTURE_LEFT_SIDEWALL',
    LARGE_AND_DEEP_SCRATCH_REAR_SIDE = 'LARGE_AND_DEEP_SCRATCH_REAR_SIDE',
    SMALL_SCRATCH_OR_DENT_REAR_SIDE = 'SMALL_SCRATCH_OR_DENT_REAR_SIDE',
    IMPACT_DAMAGE_REAR_SIDE = 'IMPACT_DAMAGE_REAR_SIDE',
    FORKLIFT_PUNCTURE_REAR_SIDE = 'FORKLIFT_PUNCTURE_REAR_SIDE',
    LOGGER_LABEL_MISSING_REAR_SIDE = 'LOGGER_LABEL_MISSING_REAR_SIDE',
    LOGGER_MISSING_REAR_SIDE = 'LOGGER_MISSING_REAR_SIDE',
    SMALL_SCRATCH_OR_DENT_TOP_SIDE = 'SMALL_SCRATCH_OR_DENT_TOP_SIDE',
    LARGE_AND_DEEP_SCRATCH_TOP_SIDE = 'LARGE_AND_DEEP_SCRATCH_TOP_SIDE',
    LARGE_DENT_TOP_SIDE = 'LARGE_DENT_TOP_SIDE',
    SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_FRONT_LEFT = 'SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_FRONT_LEFT',
    CRACKED_ALUMINUM_FRAME_FRONT_LEFT = 'CRACKED_ALUMINUM_FRAME_FRONT_LEFT',
    SCREWS_MISSING_ALUMINUM_FRAME_FRONT_LEFT = 'SCREWS_MISSING_ALUMINUM_FRAME_FRONT_LEFT',
    BENT_INWARDS_ALUMINUM_FRAME_FRONT_LEFT = 'BENT_INWARDS_ALUMINUM_FRAME_FRONT_LEFT',
    BENT_OUTWARDS_ALUMINUM_FRAME_FRONT_LEFT = 'BENT_OUTWARDS_ALUMINUM_FRAME_FRONT_LEFT',
    SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_FRONT_RIGHT = 'SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_FRONT_RIGHT',
    CRACKED_ALUMINUM_FRAME_FRONT_RIGHT = 'CRACKED_ALUMINUM_FRAME_FRONT_RIGHT',
    SCREWS_MISSING_ALUMINUM_FRAME_FRONT_RIGHT = 'SCREWS_MISSING_ALUMINUM_FRAME_FRONT_RIGHT',
    BENT_INWARDS_ALUMINUM_FRAME_FRONT_RIGHT = 'BENT_INWARDS_ALUMINUM_FRAME_FRONT_RIGHT',
    BENT_OUTWARDS_ALUMINUM_FRAME_FRONT_RIGHT = 'BENT_OUTWARDS_ALUMINUM_FRAME_FRONT_RIGHT',
    SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_REAR_LEFT = 'SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_REAR_LEFT',
    CRACKED_ALUMINUM_FRAME_REAR_LEFT = 'CRACKED_ALUMINUM_FRAME_REAR_LEFT',
    SCREWS_MISSING_ALUMINUM_FRAME_REAR_LEFT = 'SCREWS_MISSING_ALUMINUM_FRAME_REAR_LEFT',
    BENT_INWARDS_ALUMINUM_FRAME_REAR_LEFT = 'BENT_INWARDS_ALUMINUM_FRAME_REAR_LEFT',
    BENT_OUTWARDS_ALUMINUM_FRAME_REAR_LEFT = 'BENT_OUTWARDS_ALUMINUM_FRAME_REAR_LEFT',
    SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_REAR_RIGHT = 'SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_REAR_RIGHT',
    CRACKED_ALUMINUM_FRAME_REAR_RIGHT = 'CRACKED_ALUMINUM_FRAME_REAR_RIGHT',
    SCREWS_MISSING_ALUMINUM_FRAME_REAR_RIGHT = 'SCREWS_MISSING_ALUMINUM_FRAME_REAR_RIGHT',
    BENT_INWARDS_ALUMINUM_FRAME_REAR_RIGHT = 'BENT_INWARDS_ALUMINUM_FRAME_REAR_RIGHT',
    BENT_OUTWARDS_ALUMINUM_FRAME_REAR_RIGHT = 'BENT_OUTWARDS_ALUMINUM_FRAME_REAR_RIGHT',
    SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_TOP = 'SMALL_SCRATCH_OR_DENT_ALUMINUM_FRAME_TOP',
    CRACKED_ALUMINUM_FRAME_TOP = 'CRACKED_ALUMINUM_FRAME_TOP',
    BENT_INWARDS_ALUMINUM_FRAME_TOP = 'BENT_INWARDS_ALUMINUM_FRAME_TOP',
    BENT_OUTWARDS_ALUMINUM_FRAME_TOP = 'BENT_OUTWARDS_ALUMINUM_FRAME_TOP',
    ADHESIVE_JOINTS_LEFT_SIDE = 'ADHESIVE_JOINTS_LEFT_SIDE',
    ADHESIVE_JOINTS_RIGHT_SIDE = 'ADHESIVE_JOINTS_RIGHT_SIDE',
    ADHESIVE_JOINTS_TOP_SIDE = 'ADHESIVE_JOINTS_TOP_SIDE',
    BOTTOM_OF_DOOR_BENT_EXTERIOR_DOOR = 'BOTTOM_OF_DOOR_BENT_EXTERIOR_DOOR',
    BOTTOM_OF_DOOR_DAMAGED_EXTERIOR_DOOR = 'BOTTOM_OF_DOOR_DAMAGED_EXTERIOR_DOOR',
    LARGE_AND_DEEP_SCRATCH_EXTERIOR_DOOR_OUTSIDE = 'LARGE_AND_DEEP_SCRATCH_EXTERIOR_DOOR_OUTSIDE',
    SMALL_SCRATCH_OR_DENT_EXTERIOR_DOOR_OUTSIDE = 'SMALL_SCRATCH_OR_DENT_EXTERIOR_DOOR_OUTSIDE',
    FORKLIFT_PUNCTURE_EXTERIOR_DOOR = 'FORKLIFT_PUNCTURE_EXTERIOR_DOOR',
    SEAL_LATCH_EXTERIOR_DOOR = 'SEAL_LATCH_EXTERIOR_DOOR',
    DOOR_HANDLE_EXTERIOR_DOOR = 'DOOR_HANDLE_EXTERIOR_DOOR',
    HANDLE_COVER_EXTERIOR_DOOR = 'HANDLE_COVER_EXTERIOR_DOOR',
    DOOR_HINGES_EXTERIOR_DOOR = 'DOOR_HINGES_EXTERIOR_DOOR',
    OPENS_CLOSES_NOT_CORRECTLY_EXTERIOR_DOOR = 'OPENS_CLOSES_NOT_CORRECTLY_EXTERIOR_DOOR',
    OUTER_SILICONE_RING_EXTERIOR_DOOR = 'OUTER_SILICONE_RING_EXTERIOR_DOOR',
    INNER_SILICONE_RING_EXTERIOR_DOOR = 'INNER_SILICONE_RING_EXTERIOR_DOOR',
    INNER_SIDE_SCRATCHED_EXTERIOR_DOOR = 'INNER_SIDE_SCRATCHED_EXTERIOR_DOOR',
    INNER_SIDE_DAMAGED_EXTERIOR_DOOR = 'INNER_SIDE_DAMAGED_EXTERIOR_DOOR',
    EXCESSIVE_PLAY_TOP_HOOK_EXTERIOR_DOOR = 'EXCESSIVE_PLAY_TOP_HOOK_EXTERIOR_DOOR',
    EXCESSIVE_PLAY_BOTTOM_HOOK_EXTERIOR_DOOR = 'EXCESSIVE_PLAY_BOTTOM_HOOK_EXTERIOR_DOOR',
    SMALL_SCRATCH_OR_DENT_LEFT_INNER_DOOR = 'SMALL_SCRATCH_OR_DENT_LEFT_INNER_DOOR',
    LARGE_AND_DEEP_SCRATCH_LEFT_INNER_DOOR = 'LARGE_AND_DEEP_SCRATCH_LEFT_INNER_DOOR',
    FORKLIFT_PUNCTURE_LEFT_INNER_DOOR = 'FORKLIFT_PUNCTURE_LEFT_INNER_DOOR',
    SILICONE_SEAL_LEFT_INNER_DOOR = 'SILICONE_SEAL_LEFT_INNER_DOOR',
    DOOR_BENT_RIVETS_PRESENT_LEFT_INNER_DOOR = 'DOOR_BENT_RIVETS_PRESENT_LEFT_INNER_DOOR',
    DOOR_BENT_RIVETS_MISSING_LEFT_INNER_DOOR = 'DOOR_BENT_RIVETS_MISSING_LEFT_INNER_DOOR',
    COMPOSITE_BOLT_DAMAGED_LEFT_INNER_DOOR = 'COMPOSITE_BOLT_DAMAGED_LEFT_INNER_DOOR',
    TOP_DOORSTOP_LEFT_INNER_DOOR = 'TOP_DOORSTOP_LEFT_INNER_DOOR',
    BOTTOM_DOORSTOP_LEFT_INNER_DOOR = 'BOTTOM_DOORSTOP_LEFT_INNER_DOOR',
    MISALIGNED_LEFT_INNER_DOOR = 'MISALIGNED_LEFT_INNER_DOOR',
    DOOR_HINGE_LEFT_INNER_DOOR = 'DOOR_HINGE_LEFT_INNER_DOOR',
    BLUE_COLOR_CODED_STICKER_LEFT_INNER_DOOR = 'BLUE_COLOR_CODED_STICKER_LEFT_INNER_DOOR',
    GREEN_COLOR_CODED_STICKER_LEFT_INNER_DOOR = 'GREEN_COLOR_CODED_STICKER_LEFT_INNER_DOOR',
    PCM_LEAKAGE_LEFT_INNER_DOOR = 'PCM_LEAKAGE_LEFT_INNER_DOOR',
    DELAMINATION_LEFT_INNER_DOOR = 'DELAMINATION_LEFT_INNER_DOOR',
    SMALL_SCRATCH_OR_DENT_RIGHT_INNER_DOOR = 'SMALL_SCRATCH_OR_DENT_RIGHT_INNER_DOOR',
    LARGE_AND_DEEP_SCRATCH_RIGHT_INNER_DOOR = 'LARGE_AND_DEEP_SCRATCH_RIGHT_INNER_DOOR',
    FORKLIFT_PUNCTURE_RIGHT_INNER_DOOR = 'FORKLIFT_PUNCTURE_RIGHT_INNER_DOOR',
    SILICONE_SEAL_RIGHT_INNER_DOOR = 'SILICONE_SEAL_RIGHT_INNER_DOOR',
    DOOR_BENT_RIVETS_PRESENT_RIGHT_INNER_DOOR = 'DOOR_BENT_RIVETS_PRESENT_RIGHT_INNER_DOOR',
    DOOR_BENT_RIVETS_MISSING_RIGHT_INNER_DOOR = 'DOOR_BENT_RIVETS_MISSING_RIGHT_INNER_DOOR',
    COMPOSITE_BOLT_DAMAGED_RIGHT_INNER_DOOR = 'COMPOSITE_BOLT_DAMAGED_RIGHT_INNER_DOOR',
    TOP_DOORSTOP_RIGHT_INNER_DOOR = 'TOP_DOORSTOP_RIGHT_INNER_DOOR',
    BOTTOM_DOORSTOP_RIGHT_INNER_DOOR = 'BOTTOM_DOORSTOP_RIGHT_INNER_DOOR',
    MISALIGNED_RIGHT_INNER_DOOR = 'MISALIGNED_RIGHT_INNER_DOOR',
    DOOR_HINGE_RIGHT_INNER_DOOR = 'DOOR_HINGE_RIGHT_INNER_DOOR',
    BLUE_COLOR_CODED_STICKER_RIGHT_INNER_DOOR = 'BLUE_COLOR_CODED_STICKER_RIGHT_INNER_DOOR',
    GREEN_COLOR_CODED_STICKER_RIGHT_INNER_DOOR = 'GREEN_COLOR_CODED_STICKER_RIGHT_INNER_DOOR',
    PCM_LEAKAGE_RIGHT_INNER_DOOR = 'PCM_LEAKAGE_RIGHT_INNER_DOOR',
    DELAMINATION_RIGHT_INNER_DOOR = 'DELAMINATION_RIGHT_INNER_DOOR',
    HAIRLINE_CRACK_TEIL_H = 'HAIRLINE_CRACK_TEIL_H',
    LARGE_CRACK_TEIL_H = 'LARGE_CRACK_TEIL_H',
    DENT_TEIL_H = 'DENT_TEIL_H',
    DOOR_LOCK_TOP = 'DOOR_LOCK_TOP',
    DOOR_LOCK_BOTTOM = 'DOOR_LOCK_BOTTOM',
    SILICONE_SEAL_RING_TEIL_H = 'SILICONE_SEAL_RING_TEIL_H',
    LEFT_L_SHAPED_SILICONE_PROFILE = 'LEFT_L_SHAPED_SILICONE_PROFILE',
    RIGHT_L_SHAPED_SILICONE_PROFILE = 'RIGHT_L_SHAPED_SILICONE_PROFILE',
    TOP_L_SHAPED_SILICONE_PROFILE = 'TOP_L_SHAPED_SILICONE_PROFILE',
    BOTTOM_L_SHAPED_SILICONE_PROFILE = 'BOTTOM_L_SHAPED_SILICONE_PROFILE',
    SMALL_SCRATCH_OR_DENT_ALUMINUM_SHELL = 'SMALL_SCRATCH_OR_DENT_ALUMINUM_SHELL',
    LARGE_AND_DEEP_SCRATCH_ALUMINUM_SHELL = 'LARGE_AND_DEEP_SCRATCH_ALUMINUM_SHELL',
    DELAMINATION_INNER_SHELL = 'DELAMINATION_INNER_SHELL',
    WEBBING_LOOPS_DAMAGED_INNER_SHELL = 'WEBBING_LOOPS_DAMAGED_INNER_SHELL',
    MOLD_OR_EXCESSIVE_DIRT_ALUMINUM_SHELL = 'MOLD_OR_EXCESSIVE_DIRT_ALUMINUM_SHELL',
    OTHER_DAMAGE_ALUMINUM_SHELL = 'OTHER_DAMAGE_ALUMINUM_SHELL',
    CORROSION_ALUMINUM_SHELL = 'CORROSION_ALUMINUM_SHELL',
    LABEL_MISSING_INTERNAL_LOGGER = 'LABEL_MISSING_INTERNAL_LOGGER',
    MISSING_INTERNAL_LOGGER = 'MISSING_INTERNAL_LOGGER',
    RUBBER_PADS_DAMAGED_1 = 'RUBBER_PADS_DAMAGED_1',
    RUBBER_PADS_DAMAGED_2 = 'RUBBER_PADS_DAMAGED_2',
    RUBBER_PADS_DAMAGED_3 = 'RUBBER_PADS_DAMAGED_3',
    RUBBER_PADS_DAMAGED_4 = 'RUBBER_PADS_DAMAGED_4',
    RUBBER_PADS_DAMAGED_5 = 'RUBBER_PADS_DAMAGED_5',
    RUBBER_PADS_DAMAGED_6 = 'RUBBER_PADS_DAMAGED_6',
    RUBBER_PADS_DAMAGED_7 = 'RUBBER_PADS_DAMAGED_7',
    RUBBER_PADS_DAMAGED_8 = 'RUBBER_PADS_DAMAGED_8',
    LOGGER_COMPARTMENT_ISSUE_LEFT_SIDE = 'LOGGER_COMPARTMENT_ISSUE_LEFT_SIDE',
    LOGGER_COMPARTMENT_ISSUE_RIGHT_SIDE = 'LOGGER_COMPARTMENT_ISSUE_RIGHT_SIDE',
    LOGGER_MISSING_AMBIENT_LEFT_SIDE = 'LOGGER_MISSING_AMBIENT_LEFT_SIDE',
    LOGGER_MISSING_AMBIENT_RIGHT_SIDE = 'LOGGER_MISSING_AMBIENT_RIGHT_SIDE',
    HEAT_SHIELD_MISSING = 'HEAT_SHIELD_MISSING',
    HEAT_SHIELD_DAMAGED = 'HEAT_SHIELD_DAMAGED',
    DRAWER_MISSING_OR_DAMAGED_1 = 'DRAWER_MISSING_OR_DAMAGED_1',
    DRAWER_MISSING_OR_DAMAGED_2 = 'DRAWER_MISSING_OR_DAMAGED_2',
    DRAWER_MISSING_OR_DAMAGED_3 = 'DRAWER_MISSING_OR_DAMAGED_3',
    DRAWER_MISSING_OR_DAMAGED_4 = 'DRAWER_MISSING_OR_DAMAGED_4',
    DRAWER_MISSING_OR_DAMAGED_5 = 'DRAWER_MISSING_OR_DAMAGED_5',
    DRAWER_MISSING_OR_DAMAGED_6 = 'DRAWER_MISSING_OR_DAMAGED_6',
    DRAWER_MISSING_OR_DAMAGED_7 = 'DRAWER_MISSING_OR_DAMAGED_7',
    DRAWER_MISSING_OR_DAMAGED_8 = 'DRAWER_MISSING_OR_DAMAGED_8',
    DRAWER_MISSING_OR_DAMAGED_9 = 'DRAWER_MISSING_OR_DAMAGED_9',
    DRAWER_MISSING_OR_DAMAGED_10 = 'DRAWER_MISSING_OR_DAMAGED_10',
    DRAWER_MISSING_OR_DAMAGED_11 = 'DRAWER_MISSING_OR_DAMAGED_11',
    DRAWER_MISSING_OR_DAMAGED_12 = 'DRAWER_MISSING_OR_DAMAGED_12',
    DRAWER_COMPARTMENT_DAMAGED = 'DRAWER_COMPARTMENT_DAMAGED',
    REINFORCEMENT_MISSING_LEFT_INNER_DOOR = 'REINFORCEMENT_MISSING_LEFT_INNER_DOOR',
    REINFORCEMENT_MISSING_RIGHT_INNER_DOOR = 'REINFORCEMENT_MISSING_RIGHT_INNER_DOOR',
}

export default DamageType
