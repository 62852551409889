function pageRowToStartRows({
    page = 1,
    rows = 50,
}) {
    return {
        start: (page === 1 ? (page - 1) : page) * rows,
        rows,
    }
}

export default pageRowToStartRows
