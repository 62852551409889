/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Unknown from './Unknown'

const UnknownContainer = (props) => {
    return (
        <Unknown
            {...props}
        />
    )
}

export default UnknownContainer
