import {
    useCallback,
    useMemo,
} from 'react'
import uniq from 'lodash/uniq'
import toUpper from 'lodash/toUpper'

import {
    ConfigLogger,
    LoggerPosition,
} from 'app/Apps/Loggers/Loggers.types'
import {
    LOGGER_POSITIONS_NAME,
} from 'app/utils/optionsLoggers'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    LoggerType,
} from 'app/types/enums'

import useGetSensorData from './useGetSensorData'

type Params = {
    serialNumber: string,
    from: Date,
    to: Date,
    configLoggers?: ConfigLogger[],
    isDateRange: boolean,
    showDoorEvents?: boolean,
    showVirtualLogger?: boolean,
    productType?: string,
}

const getAllLoggers = (loggerType: string[]) => {
    return loggerType.map((type) => {
        return {
            loggerType: type,
        }
    })
}

const useGetSensorDataForContainer = ({
    serialNumber,
    configLoggers,
    from,
    to,
    isDateRange,
    showDoorEvents,
    showVirtualLogger,
    productType,
}:Params) => {
    const getAllowedValues = useEnumValues()
    const allowedSensorLabels = getAllowedValues(LoggerPosition)
    const allowedLoggerTypes = getAllowedValues(LoggerType)

    const positions = useMemo(() => {
        return configLoggers.reduce((acc, {
            options: positionsConfig,
            name,
        }) => {
            if (name === LOGGER_POSITIONS_NAME) {
                return [
                    ...acc,
                    ...(positionsConfig || []).map(({
                        value,
                    }) => { return value }),
                ]
            }
            return uniq(acc)
        }, [])
    }, [configLoggers])

    const customResponseParser = useCallback((result, responseSensorData) => {
        const {
            data: {
                positions: sensorLabels,
                loggerTypes,
                loggerNumbers,
                dataTypes,
            },
        } = responseSensorData

        return {
            ...result,
            sensorLabels: sensorLabels.map((item, index) => {
                return `${allowedSensorLabels[item]} ${toUpper(loggerNumbers[index])} (${allowedLoggerTypes[loggerTypes[index]]})`
            }),
            sensorCodes: sensorLabels.map((item, index) => {
                return `${item}_${dataTypes[index]}_${loggerNumbers[index]}`
            }),
        }
    }, [
        allowedLoggerTypes,
        allowedSensorLabels,
    ])

    return useGetSensorData({
        from,
        to,
        configLoggers,
        isDateRange,
        showDoorEvents,
        showVirtualLogger,
        getAllLoggers,
        url: `sensordata/container/${serialNumber}`,
        additionalQueryData: {
            positions,
        },
        customResponseParser,
        productType,
    })
}

export default useGetSensorDataForContainer
