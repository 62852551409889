import React from 'react'
import clsx from 'clsx'

import Select from 'app/shared-components/Fees/FeeInputs/Select'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    TemperatureRangeAll,
} from 'app/types/enums'

import useGetProductTypesFromTempRange from './hooks/useGetProductTypesFromTempRange'

import useStyles from './ProductTypeSelector.styles'

type Props = {
    value?: string,
    onChange: (val: string, event: React.ChangeEvent<HTMLSelectElement>) => void,
    tempRange?: TemperatureRangeAll,
    className?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    value: '',
    tempRange: undefined,
    className: '',
    disabled: false,
    required: undefined,
}

const ProductTypeSelector = ({
    value,
    onChange,
    tempRange,
    className,
    disabled,
    required,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const ProductTypes = useGetProductTypesFromTempRange(tempRange)
    const allowedValuesProductType = getAllowedValues(ProductTypes)

    return (
        <Select
            allowedValues={allowedValuesProductType}
            value={value}
            name="productType"
            className={clsx(className, classes.selector)}
            onChange={onChange}
            disableUnderline
            disableHelperText
            disabled={disabled}
            required={required}
        />
    )
}

ProductTypeSelector.defaultProps = defaultProps

export default ProductTypeSelector
