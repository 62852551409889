import {
    useMutation,
} from '@tanstack/react-query'

import {
    useJWTToken,
} from 'app/Auth'
import simpleApi from 'app/services/simpleApi'

import {
    Country,
} from './useGetCountries'
import config from './requests'

const useGetState = () => {
    const token = useJWTToken()
    const {
        mutateAsync,
    } = useMutation({
        mutationFn: ({
            country, addressComponents,
        }:{
            country: Country,
            addressComponents: google.maps.GeocoderAddressComponent[],
        }) => {
            return simpleApi(config.States.requestFn({
                country,
                token,
            }))
                .then((response) => {
                    if (response?.states) {
                        const stateComponent = addressComponents.find((item) => {
                            return item.types.includes('administrative_area_level_1')
                        })
                        const state = response?.states.find((currCountry) => {
                            const current = currCountry.stateName.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()
                            const stateNameToSearch = stateComponent?.long_name.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()

                            return current.includes(stateNameToSearch)
                        })

                        return state?.id
                    }
                    return null
                })
        },
    })

    return mutateAsync
}

export default useGetState
