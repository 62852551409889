import {
    makeStyles,
} from 'app/tss'

const sharePositioning = 'sharePositioning'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `${sharePositioning} . . .`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        sharePositioning: {
            gridArea: sharePositioning,
        },
    }
})

export default useStyles
