import {
    makeStyles,
} from 'app/tss'

const loggers = 'loggers'
const gateway = 'gateway'
const domainObject = 'domainObject'
const temperatureChart = 'temperatureChart'
const sideBar = 'sideBar'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2.5),
            gridTemplateAreas: `
            "${domainObject} ${domainObject} ${domainObject} ${domainObject}"
            "${temperatureChart} ${temperatureChart} ${temperatureChart} ${sideBar}"
        `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },

        domainObject: {
            gridArea: domainObject,
        },
        chartContainer: {
            paddingTop: '2rem',
        },
        temperatureChart: {
            gridArea: temperatureChart,
        },
        sideBar: {
            gridArea: sideBar,
        },

        cardRoot: {
            marginBottom: theme.spacing(2.5),
        },

        gridWrapperForm: {
            display: 'grid',
            gridGap: theme.spacing(2.5),
            gridTemplateAreas: `"${loggers} ${loggers} ${gateway} ${gateway}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        loggers: {
            gridArea: loggers,
        },
        gateway: {
            gridArea: gateway,
        },
        overlay: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0, 0.2)',
        },
        gridCardWrapperForm: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: `0px ${theme.spacing(2.5)}`,
        },
        chartHeading: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        chartControls: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        temperatureInfo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
    }
})

export default useStyles
