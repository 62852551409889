import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            marginTop: theme.spacing(2),
            height: 600,
        },
        containerRoute: {
            width: '20%',
            marginRight: 12,
        },
        containerMap: {
            width: '80%',
        },
        wrapper: {
            paddingTop: theme.spacing(3),
        },
        tab: {
            textDecoration: 'none',
            color: theme.typography.body1.color,
            fontSize: theme.typography.subtitle1.fontSize,
            padding: '0 10px',
        },
        activeTab: {
            borderBottom: `3px solid ${theme.palette.primary[400]}`,
        },
    }
})

export default useStyles
