import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../Notifications.request'
import {
    NotificationsSettingsType,
} from '../../lanes.types'

const useUpdateNotificationsSettings = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessActivation,
        onError,
    } = useHandleStatusRequest({
        id: 'ActivateNotifications',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            laneId,
            notificationsSettings,
        }: {
        laneId: number,
        notificationsSettings: NotificationsSettingsType,
    }) => {
            return request.UpdateNotificationsSettings.mutationFn({
                token,
                laneId,
                notificationsSettings,
            })
        },
        onError,
        onSuccess: onSuccessActivation,
    })
}

export default useUpdateNotificationsSettings
