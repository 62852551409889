import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        citySuggestions: {
            padding: theme.spacing(1),
            minWidth: 260,
        },
        city: {
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.secondary[100],
            },
        },
        popover: {
            overflow: 'visible',
        },
    }
})

export default useStyles
