import {
    RequestConfig,
} from 'app/types/request.types'

type DamagesRequests = {
    domainName: string,
    filterRequest: ({
        params: any,
    }) => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    update: ({
        id: number,
    }) => RequestConfig,
    create: () => RequestConfig,
    modifications: ({
        id: number,
    }) => RequestConfig
}

const damagesRequests: DamagesRequests = {
    domainName: 'Damage',
    filterRequest: ({
        params,
    }) => {
        return {
            method: 'POST',
            url: 'damage/filter-request/all',
            ...params,
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `damage/${id}`,
            method: 'GET',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `damage/${id}`,
            method: 'PUT',
        }
    },
    create: () => {
        return {
            url: 'damage',
            method: 'POST',
        }
    },
    modifications: ({
        id,
    }) => {
        return {
            url: `damage/${id}/modifications`,
            method: 'GET',
        }
    },
}

export default damagesRequests
