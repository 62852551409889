import {
    createContext,
    useContext,
} from 'react'
import noop from 'lodash/noop'

const OrdersContext = createContext({
    selectedInvoiceId: undefined,
    setSelectedInvoiceId: noop,
})

export const useOrdersContext = () => {
    return useContext(OrdersContext)
}

export default OrdersContext
