import {
    makeStyles,
} from 'app/tss'

export default makeStyles()((theme) => {
    return {
        card: {
            margin: `${theme.spacing(2)} 0`,
            padding: 0,
        },
    }
})
