import moment from 'moment'

const getDateToIso = (date, isRange) => {
    if (isRange) {
        return moment(date).format('YYYY-MM-DDTHH:mm')
    }
    return moment.utc(date).format('YYYY-MM-DDTHH:mm')
}

export default getDateToIso
