import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {
            width: '100%',
        },
        json: {
            overflow: 'auto',
        },
    }
})

export default useStyles
