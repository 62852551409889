import getPoints from './utils/getPoints'

const formatterDegreeCelsius = (val) => {
    return `${val}°C`
}

const chartOptions = ({
    showTemperatureRange,
    simulationResults,
    showEnergyLevel,
    maxTemperature,
    minTemperature,
    excursionTime,
    showLocation,
    showStepType,
    dataPoints,
    categories,
    theme,
}): Record<string, any> => {
    return {
        chart: {
            type: 'line',
        },
        grid: {
            padding: {
                right: 30,
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        yaxis: [
            {
                seriesName: 'Internal Temperature',
                axisTicks: {
                    show: true,
                },
                title: {
                    text: 'Temperature',
                    style: {
                        fontWeight: 'normal',
                    },
                },
                labels: {
                    formatter: formatterDegreeCelsius,
                },
                axisBorder: {
                    show: true,
                    color: theme.palette.secondary[800],
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            {
                seriesName: 'Internal Temperature',
                show: false,
                labels: {
                    formatter: formatterDegreeCelsius,
                },
            },
            ...showTemperatureRange ? [
                {
                    seriesName: 'Internal Temperature',
                    labels: {
                        formatter: formatterDegreeCelsius,
                    },
                    show: false,
                },
                {
                    seriesName: 'Internal Temperature',
                    labels: {
                        formatter: formatterDegreeCelsius,
                    },
                    show: false,
                },
            ] : [],
            ...showEnergyLevel ? [{
                seriesName: 'Remaining Energy Level',
                opposite: true,
                title: {
                    text: 'Energy Level',
                    style: {
                        fontWeight: 'normal',
                    },
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    formatter(val) {
                        return `${Math.floor(val * 100) / 100}%`
                    },
                },
            }] : [],
        ],
        xaxis: {
            type: 'numeric',
            title: {
                text: 'Hours',
                style: {
                    fontWeight: 'normal',
                },
            },
            tickPlacement: 'on',
            axisBorder: {
                show: true,
                color: theme.palette.secondary[800],
                offsetX: 0,
                offsetY: 0,
            },
            categories,
            labels: {
                showDuplicates: false,
                formatter(val) {
                    return Math.trunc(val / 60)
                },
                rotate: 0,
                hideOverlappingLabels: true,
            },
        },
        tooltip: {
            shared: true,
            x: {
                formatter: (val) => {
                    const hours = Math.trunc(val / 60)
                    const minutes = val - hours * 60

                    return `Time: ${hours || ''}${hours ? 'h' : ''} ${minutes}min`
                },
            },
        },
        ...simulationResults ? {
            stroke: {
                width: 2,
                curve: 'straight',
                dashArray: showTemperatureRange ? [
                    0,
                    0,
                    3,
                    3,
                ] : [],
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
            },
            annotations: {
                points: getPoints({
                    dataPoints,
                    showStepType,
                    showLocation,
                    theme,
                }),
                xaxis: [excursionTime && {
                    x: excursionTime,
                    strokeDashArray: 5,
                    borderColor: '#C73840',
                    borderWidth: 2,
                    label: {
                        style: {
                            background: 'none',
                        },
                        textAnchor: 'start',
                        borderWidth: 0,
                        text: 'Excursion',
                    },
                }],
                yaxis: [
                    showTemperatureRange && {
                        y: minTemperature,
                        borderWidth: 0,
                        label: {
                            style: {
                                fontSize: '15px',
                                color: theme.palette.common.yellow,
                                background: 'none',
                            },
                            offsetX: 27,
                            offsetY: 7,
                            textAnchor: 'end',
                            borderWidth: 0,
                            text: 'Min',
                        },
                    },
                    showTemperatureRange && {
                        y: maxTemperature,
                        borderWidth: 0,
                        label: {
                            style: {
                                fontSize: '15px',
                                color: theme.palette.common.yellow,
                                background: 'none',
                            },
                            offsetX: 30,
                            offsetY: 7,
                            textAnchor: 'end',
                            borderWidth: 0,
                            text: 'Max',
                        },
                    },
                ],
            },
            colors: [
                theme.palette.primary[400],
                theme.palette.common.purple,
                ...showTemperatureRange ? [
                    theme.palette.common.yellow,
                    theme.palette.common.yellow,
                ] : [],
                theme.palette.secondary[400],
            ],
        } : {},
    }
}

export default chartOptions
