import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

const getServiceCenterMap = (lane: Lane) => {
    return {
        origin: {
            DEFAULT: {
                switchContacts: false,
                data: {
                    originServiceCenter: lane?.originSc,
                    originServiceCenterContacts: lane?.originScContacts,
                },
            },
            BACKUP: {
                switchContacts: false,
                data: {
                    originServiceCenter: lane?.originScBackup,
                    originServiceCenterContacts: lane?.originScBackupContacts,
                },
            },
            OTHER: {
                switchContacts: true,
                data: {
                    originServiceCenter: null,
                    originServiceCenterContacts: null,
                },
            },
        },
        forwarder: {
            DEFAULT: {
                switchContacts: false,
                data: {
                    forwarderPositioning: lane?.fwdPos,
                    forwarderPositioningContacts: lane?.fwdPosContacts,
                },
            },
            BACKUP: {
                switchContacts: false,
                data: {
                    forwarderPositioning: lane?.fwdPosBackup,
                    forwarderPositioningContacts: lane?.fwdPosBackupContacts,
                },
            },
            OTHER: {
                switchContacts: true,
                data: {
                    forwarderPositioning: null,
                    forwarderPositioningContacts: null,
                },
            },
        },
    }
}

export default getServiceCenterMap
