import {
    SimulationDataChart,
    SimulationTempResult,
} from 'app/Apps/TransportPlanner/Simulation/simulation.types'

const getSimulationData = ({
    isDurationByHours,
    simulationResults = [],
}: {
    isDurationByHours: boolean,
    simulationResults: SimulationTempResult[],
}): SimulationDataChart => {
    return simulationResults.reduce((acc, item) => {
        return {
            internalTemperature: [
                ...acc.internalTemperature,
                item.simulatedTemperature,
            ],
            remainingEnergyLevel: [
                ...acc.remainingEnergyLevel,
                item.remainingEnergyLevel * 100,
            ],
            min: [
                ...acc.min,
                item.minTemperature,
            ],
            max: [
                ...acc.max,
                item.maxTemperature,
            ],
            time: [
                ...acc.time,
                isDurationByHours ? (item.time * 60) : (item.time * 5),
            ],
        }
    },
    {
        internalTemperature: [],
        remainingEnergyLevel: [],
        min: [],
        max: [],
        time: [],
    })
}

export default getSimulationData
