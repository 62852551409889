import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        numberOfTrucks: {
            '&&': {
                width: '50%',
                paddingRight: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        numberOfVans: {
            '&&': {
                width: '50%',
                paddingLeft: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        root: {
            marginBottom: theme.spacing(2),
        },
        title: {
            display: 'flex',
        },
        iconTitle: {
            paddingRight: theme.spacing(1.5),
        },
    }
})

export default useStyles
