import React, {
    useCallback,
} from 'react'
import Link from '@mui/material/Link'
import {
    useRouteMatch, useHistory,
} from 'react-router-dom'
import useStyles from './BulkCreateIcon.style'

const defaultProps = {
    onClick: undefined,
}

const BulkCreateIcon = () => {
    const {
        classes,
    } = useStyles()

    const {
        url,
    } = useRouteMatch()
    const history = useHistory()

    const redirectToBulkCreate = useCallback(() => {
        history.push(`${url}/bulk-create`)
    }, [
        url,
        history,
    ])

    return (
        <Link
            className={classes.link}
            component="button"
            color="secondary"
            onClick={redirectToBulkCreate}
        >
            <img
                className={classes.icon}
                src="assets/images/icons/bulk_create.svg"
                alt="Bulk Create"
            />
        </Link>
    )
}

BulkCreateIcon.defaultProps = defaultProps

export default BulkCreateIcon
