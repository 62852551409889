import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import type {
    RootState,
} from 'app/store'
import {
    RequestStatuses,
} from 'app/types/common.enums'

type FormStatus = {
    id: string
}

type PendingStatus = FormStatus & {
    title: string
}

type FinishedStatus = FormStatus & {
    status: RequestStatuses,
}

type FormStatusesState = {
    pending: PendingStatus [],
    finished: FinishedStatus[],
}

type FormFinishedParams = {
    id: string,
    status: RequestStatuses,
    title?: string,
    errors?: any[],
    statusCode?: number,
}

const formfinishedReducer = (state: FormStatusesState, {
    id,
    status,
    title,
    errors = [],
    statusCode,
}: FormFinishedParams) => {
    const {
        pending,
        finished,
    } = state

    const indexOfForm = pending.findIndex((form) => {
        return form.id === id
    })

    return {
        ...state,
        finished: [
            ...finished,
            {
                id,
                ...pending[indexOfForm],
                title,
                status,
                errors,
                statusCode,
            },
        ],
        pending: [
            ...pending.slice(0, indexOfForm),
            ...pending.slice(indexOfForm + 1),
        ],
    }
}

const initialState: FormStatusesState = {
    pending: [],
    finished: [],
}

const formStatusesSlice = createSlice({
    name: 'formStatuses',
    initialState,
    reducers: {
        hideMessage(state, {
            payload: {
                id,
            },
        }: PayloadAction<{id: string}>) {
            return {
                ...state,
                finished: state.finished.filter((message) => {
                    return message.id !== id
                }),
            }
        },
        startToSaveForm(state, {
            payload: {
                id,
                title,
            },
        }: PayloadAction<{id: string, title?: string}>) {
            return {
                ...state,
                pending: [{
                    id, title,
                }],
            }
        },
        failedToLoadSaveForm(state, {
            payload,
        }: PayloadAction<{
            id: string,
            errors: any[],
            statusCode?: number,
        }>) {
            return formfinishedReducer(state, {
                ...payload,
                status: RequestStatuses.FAILURE,
            })
        },
        successedToSaveForm(state, {
            payload,
        }: PayloadAction<{
            id: string,
            title?: string,
        }>) {
            return formfinishedReducer(state, {
                ...payload,
                status: RequestStatuses.SUCCESS,
            })
        },
        removePending(state, {
            payload: {
                id,
            },
        }: PayloadAction<{id: string}>) {
            return {
                ...state,
                pending: state.pending.filter((message) => {
                    return message.id !== id
                }),
            }
        },
    },
})

export const {
    hideMessage,
    startToSaveForm,
    failedToLoadSaveForm,
    successedToSaveForm,
    removePending,
} = formStatusesSlice.actions

export const selectFormStatuses = (state: RootState) => {
    return state.formStatuses
}

export default formStatusesSlice.reducer
