import React, {
    useMemo,
} from 'react'
import useEnumValues from 'app/hooks/useEnumValues'
import List from 'app/Apps/DomainObject/ComponentMapper/List'

import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'

type Props = {
    configName?: string,
    mapCellProps: string,
    value?: string[],
}

const defaultProps = {
    value: [],
    configName: null,
}

const EnumList = ({
    configName,
    mapCellProps,
    value,
}: Props) => {
    const fieldConfig = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })

    const allowedValues = useEnumValues()(fieldConfig?.allowedValuesEnum)

    if (!fieldConfig?.allowedValuesEnum) {
        // eslint-disable-next-line no-console
        console.error(`No allowed values config found for "${mapCellProps}" field in "${configName}"`)
    }

    const labels = useMemo(() => {
        return value.map((item) => {
            return allowedValues[item]
        })
    }, [
        allowedValues,
        value,
    ])

    if (!value.length) {
        return null
    }

    return (
        <List value={labels} />
    )
}

EnumList.defaultProps = defaultProps

export default EnumList
