/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Tooltip from './Tooltip'

const defaultProps = {
    ownTooltipProps: {},
    styles: undefined,
}

type Props = {
    value: string | JSX.Element,
    ownTooltipProps?: Record<string, any>,
    children: JSX.Element,
    styles?: React.CSSProperties,
}

const TooltipWrapper = ({
    value,
    ownTooltipProps,
    children,
    styles,
}: Props) => {
    return value ? (
        <Tooltip
            title={value}
            {...ownTooltipProps}
        >
            <span style={styles}>
                {children}
            </span>
        </Tooltip>
    ) : children
}

TooltipWrapper.defaultProps = defaultProps

export default TooltipWrapper
