import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    SharePositioningList: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/matching-positioning-orders`,
            }
        },
    },
}

export default config
