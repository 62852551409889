import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        formControl: {
            width: '100%',
        },
        link: {
            color: theme.palette.primary.dark,
        },
        noFile: {
            color: theme.palette.secondary.main,
            fontSize: theme.typography.h5.fontSize,
        },
        container: {
            display: 'flex',
            flexFlow: 'row nowrap',
            gap: '10px',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }
})

export default useStyles
