import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useStyles from './ContainerTemp.style'

const propTypes = {
    value: PropTypes.number,
    lowerTempBoundary: PropTypes.number,
    higherTempBoundary: PropTypes.number,
}

const defaultProps = {
    value: null,
    lowerTempBoundary: null,
    higherTempBoundary: null,
}

const ContainerTemp = (props) => {
    const {
        value,
        lowerTempBoundary,
        higherTempBoundary,
    } = props

    const {
        classes,
    } = useStyles()

    const isInRange = useMemo(() => {
        return lowerTempBoundary
            && higherTempBoundary
            && value >= lowerTempBoundary
            && value <= higherTempBoundary
    }, [
        value,
        lowerTempBoundary,
        higherTempBoundary,
    ])

    return (
        <div className={classes.root}>
            <div
                className={clsx({
                    [classes.inRange]: isInRange,
                })}
                data-testid="temperature-div"
            >
                {value || value === 0 ? `${value.toFixed(1)}°C` : ''}
            </div>
        </div>
    )
}

ContainerTemp.propTypes = propTypes
ContainerTemp.defaultProps = defaultProps

export default ContainerTemp
