const getTemperatureRange = (temperatureList: number[]): {
    min: number,
    max: number,
} | null => {
    if (!temperatureList.length) {
        return null
    }

    const minTemperature = temperatureList.reduce((previousValue, currentItem) => {
        return previousValue < currentItem ? previousValue : currentItem
    }, temperatureList[0])

    const maxTemperature = temperatureList.reduce((previousValue, currentItem) => {
        return previousValue > currentItem ? previousValue : currentItem
    }, temperatureList[0])

    return {
        min: minTemperature,
        max: maxTemperature,
    }
}

export default getTemperatureRange
