import React from 'react'

import useLabel from 'app/hooks/useLabel'

import useStyles from './CustomTooltip.styles'

const CustomTooltip = ({
    date,
    minPreconditioned,
    ...rest
}: {
    date: string,
    minPreconditioned: number,
    processed: number,
    process: number,
    preconditioned: number,
}) => {
    const {
        classes,
        cx,
    } = useStyles()

    const {
        processed,
        process,
        preconditioned,
    } = rest

    const total = Object.values(rest).reduce((acc, item) => {
        return item ? acc + item : acc
    }, 0)

    const l = useLabel()

    return (
        <div className={classes.tooltipWrapper}>
            <div className={classes.wrapper}>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.date')}</div>
                    <div>{date}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.total')}</div>
                    <div>{total}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.minPreconditioned')}</div>
                    <div>{minPreconditioned}</div>
                </div>
            </div>
            <div className={classes.wrapper}>
                {(Boolean(processed) || processed === 0) && (
                    <div className={classes.item}>
                        <div className={classes.label}>{l('Monitoring.toBeProcessed')}</div>
                        <div className={classes.valueWrapper}>
                            <div
                                data-testid="processed"
                                className={cx(classes.marker,
                                    processed < 0 ? classes.error : classes.processed)}
                            />
                            <div
                                className={classes.value}
                            >
                                {processed}
                            </div>
                        </div>
                    </div>
                )}
                { (Boolean(process) || process === 0) && (
                    <div className={classes.item}>
                        <div className={classes.label}>{l('Monitoring.inProcess')}</div>
                        <div className={classes.valueWrapper}>
                            <div
                                data-testid="process"
                                className={cx(classes.marker,
                                    process < 0 ? classes.error : classes.process)}
                            />
                            <div
                                className={classes.value}
                            >
                                {process}
                            </div>
                        </div>
                    </div>
                )}
                { (Boolean(preconditioned) || preconditioned === 0) && (
                    <div className={classes.item}>
                        <div className={classes.label}>{l('Monitoring.preconditioned')}</div>
                        <div className={classes.valueWrapper}>
                            <div
                                data-testid="preconditioned"
                                className={cx(
                                    classes.marker,
                                    preconditioned < minPreconditioned
                                        ? classes.error
                                        : classes.preconditioned,
                                )}
                            />
                            <div
                                className={classes.value}
                            >
                                {preconditioned}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CustomTooltip
