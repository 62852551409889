import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        btn: {
            height: 24,
            width: 24,
            alignSelf: 'flex-start',
            backgroundColor: `${theme.palette.secondary[100]} !important`,
            color: `${theme.palette.secondary[600]} !important`,
            borderRadius: '4px !important',
            boxShadow: `0px 1px 3px ${theme.palette.secondary[400]}`,
            margin: '5px !important',
        },
    }
})

export default useStyles
