import removeUnauthorizedField from 'app/utils/removeUnauthorizedField'

import {
    NestedField,
    DTOCardFormField,
} from 'app/types/form.types'

import {
    PaymentTerms,
} from 'app/types/enums'
import {
    LocationFieldDetails,
    AddressFieldDetails,
    BillingFieldDetails,
} from '../BillingCompanyForm.types'

const getLocationFieldDetails = (): NestedField<keyof LocationFieldDetails>[] => {
    return [
        {
            name: 'billingLocationName',
            labelKey: 'BillingCompanyDto.billingLocationName',
            componentName: 'InputSingleline',
            required: true,
        },
        {
            name: 'instructionsForBilling',
            componentName: 'LongText',
            labelKey: 'OrderBillingInformationView.instructionsForBilling',
        },
        {
            name: 'salesResponsibleContacts',
            componentName: 'MultipleContactObjectSelector',
            labelKey: 'BillingCompanyDto.salesResponsible',
        },
    ]
}
const emailsPlacehodler = 'example1@example.com;example2@example.com;'

const getAddressFieldDetails = ({
    timeZones,
    setAutocomplete,
    currentPlaceId,
    value,
    isGoogleMapsServiceLoaded,
}): NestedField<keyof AddressFieldDetails>[] => {
    return [
        isGoogleMapsServiceLoaded
            ? {
                name: 'additional',
                componentName: 'GoogleAddress',
                setAutocomplete,
                currentPlaceId,
                formData: value,
                isGoogleMapsServiceLoaded,
                labelKey: 'BillingCompanyDto.additional',
            } : {
                name: 'additional',
                required: true,
                componentName: 'InputSingleline',
                labelKey: 'BillingCompanyDto.additional',
            },
        {
            name: 'street',
            componentName: 'InputSingleline',
            title: 'Street',
            required: true,
            labelKey: 'BillingCompanyDto.street',
        },
        {
            name: 'addressLine2',
            componentName: 'InputSingleline',
            labelKey: 'BillingCompanyDto.addressLine2',
        },
        {
            name: 'company',
            labelKey: 'BillingCompanyDto.company',
            componentName: 'CompanyObjectSelector',
            required: true,
        },
        {
            name: 'isMainAddress',
            componentName: 'Switch',
            labelKey: 'BillingCompanyDto.isMainAddress',
        },
        {
            name: 'zip',
            componentName: 'InputSingleline',
            labelKey: 'BillingCompanyDto.zip',
        },
        {
            name: 'country',
            labelKey: 'BillingCompanyDto.country',
            componentName: 'CountryDataSelector',
            required: true,
        },
        {
            componentName: 'CitySuggestions',
            name: 'city',
            timeZones,
            required: true,
            labelKey: 'BillingCompanyDto.city',
        },
        {
            name: 'emailContacts',
            componentName: 'LongText',
            required: true,
            labelKey: 'BillingCompanyView.emailContacts',
            placeholder: emailsPlacehodler,
            rows: 2,
        },
        {
            name: 'emailReminderContacts',
            componentName: 'LongText',
            labelKey: 'BillingCompanyView.emailReminderContacts',
            placeholder: emailsPlacehodler,
            rows: 2,
        },
    ]
}

const getBillingFieldDetails = ({
    isSyncedWithAbacus, isNew, edited, hasPermissions, getAllowedValues,
}): NestedField<keyof BillingFieldDetails>[] => {
    return [
        {
            name: 'hasBulkInvoice',
            componentName: 'Switch',
            labelKey: 'BillingCompanyDto.hasBulkInvoice',
        },
        {
            name: 'paymentTerms',
            className: 'paymentTerms',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(PaymentTerms),
            labelKey: 'BillingCompanyDto.paymentTerms',
            required: true,
        },
        {
            name: 'markUp',
            componentName: 'InputSingleline',
            labelKey: 'BillingCompanyDto.markUp',
        },
        {
            name: 'defaultTaxCode',
            componentName: 'TaxCodesSelector',
            labelKey: 'BillingCompanyDto.defaultTaxCode',
            isNew,
        },
        {
            name: 'isSyncedWithAbacus',
            componentName: 'Checkbox',
            labelKey: 'BillingCompanyDto.isSyncedWithAbacus',
            disabled: (!edited && isSyncedWithAbacus)
            || (!isNew && !hasPermissions),
        },
        {
            name: 'abacusKeyCustomerNumber',
            componentName: 'InputSingleline',
            disabled: true,
            labelKey: 'BillingCompanyDto.abacusKeyCustomerNumber',
        },
        {
            name: 'abacusKeyAddressNumber',
            componentName: 'InputSingleline',
            disabled: true,
            labelKey: 'BillingCompanyDto.abacusKeyAddressNumber',
        },
    ]
}

const getBillingFormFields = (
    {
        timeZones,
        isNew,
        isSyncedWithAbacus,
        isSyncedWithAbacusEdited,
        setAutocomplete,
        currentPlaceId,
        value,
        isGoogleMapsServiceLoaded,
        hasPermissions,
        getAllowedValues,
    },
) => {
    const location: DTOCardFormField<keyof LocationFieldDetails> = {
        id: 'location',
        title: 'Billing Company',
        className: 'location',
        'data-testid': 'card-location',
        fields: [{
            id: 'column1',
            fields: getLocationFieldDetails(),
        }],
    }

    const address: DTOCardFormField<keyof AddressFieldDetails> = {
        id: 'addressDetails',
        title: 'Address Details',
        className: 'addressDetails',
        'data-testid': 'card-addressDetails',
        fields: [{
            id: 'column2',
            fields: getAddressFieldDetails({
                timeZones,
                setAutocomplete,
                currentPlaceId,
                value,
                isGoogleMapsServiceLoaded,
            }),
        }],
    }

    const billing: DTOCardFormField<BillingFieldDetails> = {
        id: 'billingDetails',
        title: 'Billing Information',
        className: 'billingDetails',
        'data-testid': 'card-billingDetails',
        fields: [{
            id: 'column2',
            fields: removeUnauthorizedField(getBillingFieldDetails({
                isSyncedWithAbacus,
                isNew,
                edited: isSyncedWithAbacusEdited,
                hasPermissions,
                getAllowedValues,
            }), ['isSyncedWithAbacus'], !isNew),
        }],
    }

    return [
        location,
        address,
        billing,
    ].filter(Boolean)
}

export default getBillingFormFields
