import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import evaluateExpression from './evaluateExpression'

const propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.shape({}),
    ).isRequired,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
    value: undefined,
}

const extractExpression = (str) => {
    const res = str.match(/^calc\((.+)\)$/)

    if (!res) {
        return ''
    }

    return res[1]
}

const withFieldsCalc = (Comp) => {
    const WrappedComponent = (props) => {
        const {
            fields, // eslint-disable-line
            value,
        } = props

        const fieldsWithCalc = fields
            .map((field) => {
                const updatedField = Object.fromEntries(
                    Object.entries(field)
                        .filter(([
                            _key, // eslint-disable-line @typescript-eslint/no-unused-vars
                            expression,
                        ]) => {
                            return typeof expression === 'string' && extractExpression(expression)
                        })
                        .map(([
                            key,
                            expression,
                        ]) => {
                            return [
                                key,
                                evaluateExpression(expression, value),
                            ]
                        }),
                )

                if (isEmpty(updatedField)) {
                    return field
                }

                return {
                    ...field,
                    ...updatedField,
                }
            }, [])
            .filter(({
                hidden,
            }) => {
                return !hidden
            })

        return (
            <Comp
                {...props} // eslint-disable-line react/jsx-props-no-spreading
                fields={fieldsWithCalc}
            />
        )
    }

    WrappedComponent.propTypes = propTypes
    WrappedComponent.defaultProps = defaultProps

    return WrappedComponent
}

export default withFieldsCalc
