import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        numberOfTrucks: {
            width: '50%',
            paddingRight: theme.spacing(1),
            boxSizing: 'border-box',
            display: 'inline-block',
        },
        numberOfVans: {
            width: '50%',
            paddingLeft: theme.spacing(1),
            boxSizing: 'border-box',
            display: 'inline-block',
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
