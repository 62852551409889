import React from 'react'
import PropTypes from 'prop-types'

import Alert from '@mui/material/Alert'

const propTypes = {
    err: PropTypes.any, // eslint-disable-line
}

const FailureMessageDefault = ({
    err,
}) => {
    return (
        <Alert severity="error">
            {err ? JSON.stringify(err) : 'Some error happened'}
        </Alert>
    )
}

FailureMessageDefault.propTypes = propTypes

export default FailureMessageDefault
