import React from 'react'

import noop from 'lodash/noop'
import CountrySelector from './CountrySelector'
import useCountryRequests from './hooks/useCountryRequests'

type Props = {
    title?: string,
    value?: number,
    onChange?: () => void,
    className?: string,
    required?: boolean,
    disabled?: boolean,
}
const defaultProps: Partial<Props> = {
    title: '',
    value: undefined,
    onChange: noop,
    className: '',
    required: false,
    disabled: false,
}

const CountrySelectorContainer = ({
    title,
    value,
    onChange,
    className,
    required,
    disabled,
}: Props) => {
    const {
        loadOptions,
    } = useCountryRequests()

    return (
        <CountrySelector
            title={title}
            loadOptions={loadOptions}
            onChange={onChange}
            isRequired={required}
            disabled={disabled}
            value={value}
            className={className}
        />
    )
}

CountrySelectorContainer.defaultProps = defaultProps

export default CountrySelectorContainer
