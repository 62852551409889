import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './List.style'

const propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
    value: [],
}

const List = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        value,
    } = props

    if (!value.length) {
        return null
    }

    return (
        <div className={classes.wrapper}>
            {value.map((item, index) => {
                return (
                    <div
                        className={classes.chip}
                        key={`${item}-item-${index}`} /* eslint-disable-line react/no-array-index-key */
                        title={item}
                    >
                        {item}
                    </div>
                )
            })}
        </div>
    )
}

List.propTypes = propTypes
List.defaultProps = defaultProps

export default List
