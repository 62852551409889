const getTemperatureString = ({
    low,
    high,
}: {
    low: number,
    high: number,
}): string => {
    return `${low >= 0 ? '+' : ''}${low}°C to ${high >= 0 ? '+' : ''}${high}°C`
}

export default getTemperatureString
