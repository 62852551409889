import {
    makeStyles,
} from 'app/tss'

const generalInfo = 'generalInfo'
const handover = 'handover'
const collection = 'collection'
const lanes = 'lanes'
const laneFeesSimple = 'laneFeesSimple'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${generalInfo} ${generalInfo} ${handover} ${handover}"
            "${generalInfo} ${generalInfo} ${handover} ${handover}"
            "${generalInfo} ${generalInfo} ${collection} ${collection}"
            "${lanes} ${lanes} ${lanes} ${lanes}"
            "${laneFeesSimple} ${laneFeesSimple} ${laneFeesSimple} ${laneFeesSimple}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        generalInfo: {
            gridArea: generalInfo,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        handover: {
            gridArea: handover,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        collection: {
            gridArea: collection,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        lanes: {
            gridArea: lanes,
        },
        laneFeesSimple: {
            gridArea: laneFeesSimple,
        },
        cardRoot: {
            overflow: 'auto !important',
            margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
        },
        tlCheckbox: {
            padding: 0,
        },
        laneGroupNumber: {
            paddingBottom: theme.spacing(1),
        },
    }
})

export default useStyles
