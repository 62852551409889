import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    LocationAreas,
} from '../LocationAreas.types'
import requests from '../LocationAreas.requests'

export default (locationId) => {
    const {
        onParamsChange,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        data,
        loadData,
    } = useRequestTable<LocationAreas>({
        key: RequestKeys.getLocationAreaDetailsByLocationId,
        params: {
            locationId,
        },
        config: requests.getLocationAreaByLocation,
        keepPreviousData: true,
    })

    return {
        onParamsChange,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        data,
        loadData,
    }
}
