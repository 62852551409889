import {
    RequestForForm,
} from 'app/types/request.types'

type FxRateRequests = {
    create: RequestForForm<undefined>,
    update: RequestForForm<undefined>,
}

const request: FxRateRequests = {
    create: {
        request: () => {
            return {
                url: 'fxrate',
                method: 'POST',
            }
        },
    },
    update: {
        request: () => {
            return {
                url: 'fxrate',
                method: 'PUT',
            }
        },
    },
}

export default request
