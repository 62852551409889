import React from 'react'

import MultipleContactObjectSelector from 'app/shared-components/MultipleContactSelector/MultipleContactObjectSelector'

type Value = {
    id: number,
}

type Props = {
    value?: Value[],
    onChange: (newValue: Value[]) => void,
    title?: string,
    className?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    value: [],
    title: 'Contacts',
    disabled: false,
    required: false,
    className: '',
}

const MultipleBusinessDevelopersSelect = ({
    value,
    onChange,
    title,
    disabled,
    required,
    className,
}: Props) => {
    return (
        <MultipleContactObjectSelector
            value={value}
            onChange={onChange}
            title={title}
            disabled={disabled}
            required={required}
            className={className}
            includeFilters={{
                type: ['SKYCELL_COMMERCIAL'],
            }}
            getValueLabel={({
                contactName,
            }) => { return contactName }}
        />
    )
}

MultipleBusinessDevelopersSelect.defaultProps = defaultProps

export default MultipleBusinessDevelopersSelect
