import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    OrderStatuses, OrderTemperatureRange,
} from 'app/types/enums'
import {
    TransportModeRoad,
    TransportModeSea,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    FilterType,
} from 'app/types/common.enums'
import formatCurrencyValue from 'app/utils/formatCurrencyValue'

type PreviousOrderView = {
    orderNumber: string,
    from: string,
    to: string,
    pricingAccountCompanyName: string,
    customerLocationName: string,
    consigneeLocationName: string,
    leaseEndDate: string,
    reverseLogisticsBudget: number,
    requiredContainers: string[],
    transportModeSea: TransportModeSea,
    transportModeRoad: TransportModeRoad,
    temperatureRange: OrderTemperatureRange,
    orderStatus: OrderStatuses,
}

const config: SkyNetTableConfig<keyof PreviousOrderView> = {
    name: 'NewInternalTransport.PreviousOrders',
    fields: [
        {
            name: 'orderNumber',
            headerProps: {
                children: 'Order',
            },
            width: '100px',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            headerProps: {
                children: 'From',
            },
            width: '80px',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            headerProps: {
                children: 'To',
            },
            width: '80px',
        },
        {
            name: 'pricingAccountCompanyName',
            filterField: 'pricingAccountCompanyName',
            filterType: FilterType.Array,
            width: '330px',
            labelKey: 'Prebooking.pricingAccount',
        },
        {
            name: 'customerLocationName',
            filterField: 'customerLocationName',
            filterType: FilterType.Array,
            width: '400px',
            labelKey: 'PreviousOrderView.customerLocationName',
        },
        {
            name: 'consigneeLocationName',
            filterField: 'consigneeLocationName',
            filterType: FilterType.Array,
            width: '330px',
            labelKey: 'PreviousOrderView.consigneeLocationName',
        },
        {
            name: 'leaseEndDate',
            headerProps: {
                children: 'Lease End Date',
            },
            width: '150px',
        },
        {
            name: 'requiredContainers',
            headerProps: {
                children: 'Containers',
            },
            componentName: 'List',
            width: '120px',
        },
        {
            name: 'reverseLogisticsBudget',
            mapCellProps: ({
                reverseLogisticsBudget,
                billingCurrency,
            }) => {
                return {
                    children: reverseLogisticsBudget ? `${formatCurrencyValue(reverseLogisticsBudget)} ${billingCurrency}` : '',
                }
            },
            width: '100px',
            labelKey: 'Order.RLBudget',
        },
        {
            name: 'transportModeRoad',
            headerProps: {
                children: 'Mode',
            },
            mapCellProps: 'transportModeRoad',
            componentName: 'EnumValue',
            allowedValuesEnum: TransportModeRoad,
            width: '120px',
        },
        {
            name: 'temperatureRange',
            headerProps: {
                children: 'Range',
            },
            componentName: 'EnumValue',
            allowedValuesEnum: OrderTemperatureRange,
            width: '80px',
        },
        {
            name: 'orderStatus',
            headerProps: {
                children: 'Status',
            },
            componentName: 'EnumValue',
            allowedValuesEnum: OrderStatuses,
            width: '120px',
        },
    ],
}

export default config
