import React from 'react'
import Typography from '@mui/material/Typography'

import StepTile from '../StepTile'

import useStyles from './SelectMethod.style'
import {
    SelectedMethod,
} from '../CreateInternalTransportForm'

const SelectMethod = ({
    value,
    setValue,
}: {
    value: SelectedMethod,
    setValue: (newVal: SelectedMethod) => void,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.root}>
            <Typography
                variant="h3"
                className={classes.title}
            >
                Select a Method
            </Typography>
            <div className={classes.wrapper}>
                <StepTile
                    title="Create Automatically"
                    onClick={() => { setValue(SelectedMethod.AUTOMATICALLY) }}
                    selected={value === SelectedMethod.AUTOMATICALLY}
                />
                <StepTile
                    title="Create manually"
                    onClick={() => { setValue(SelectedMethod.MANUALLY) }}
                    selected={value === SelectedMethod.MANUALLY}
                />
            </div>
        </div>
    )
}

export default SelectMethod
