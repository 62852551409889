import React, {
    useMemo,
    useState,
    useReducer,
    useCallback,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import SelectLaneStep from './Steps/SelectLaneStep'
import BookingDetailsStep from './Steps/BookingDetailsStep'
import FinalizeBookingStep from './Steps/FinalizeBookingStep'
import AdditionalLaneStep from './Steps/AdditionalLaneStep'
import LaneImplementationStep from './Steps/LaneImplementationStep'
import {
    AdditionalLaneStepType,
    BookingDetailsStepType,
    CreateFormType,
    FinalizeBookingStepType,
    SelectLaneStepType,
} from './createCustomerTransportForm.types'
import reducer from './store/reducer'

import {
    resetSecondStep,
    setFirstStep,
    setSecondStep,
    resetThirdStep,
    setThirdStep,
    setFourthStep,
    resetFourthStep,
    setFifthStep,
    resetFifthStep,
} from './store/actions'
import ClosePrebookingStep from './Steps/ClosePreBookingStep'

const defaultState = {
    firstStep: {},
    secondStep: {},
    thirdStep: {},
    fourthStep: {},
    fifthStep: {},
}

const getSteps = ({
    state,
    activeStep,
    setActiveStep,
    onChangeFirstStep,
    onChangeSecondStep,
    onResetSecondStep,
    onChangeThirdStep,
    onResetThirdStep,
    onChangeFourthStep,
    onResetFourthStep,
    onChangeFifthStep,
    onResetFifthStep,
    setIsEdited,
}) => {
    const selectedLaneId = state.firstStep.selectedLaneId?.[0]

    return [
        {
            key: '0',
            label: 'Select Lane',
            expanded: true,
            renderContent: ({
                index,
            }: {index: number}) => {
                return (
                    <SelectLaneStep
                        value={state.firstStep}
                        onChange={onChangeFirstStep}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        onEdit={onResetSecondStep}
                        index={index}
                    />
                )
            },
        },
        {
            key: '1',
            label: selectedLaneId ? 'Additional Lane Details' : 'Submit Details For Automated Lane Quotation',
            expanded: (currentStep) => {
                return currentStep > 1
            },
            renderContent: ({
                index,
            }: {index: number}) => {
                return (
                    <AdditionalLaneStep
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        index={index}
                        value={state.secondStep}
                        onChange={onChangeSecondStep}
                        onReset={onResetSecondStep}
                        onEdit={onResetThirdStep}
                        selectLaneData={state.firstStep}
                    />
                )
            },
        },
        {
            key: '2',
            label: 'Enter Booking Details',
            expanded: (currentStep) => {
                return currentStep > 2
            },
            renderContent: ({
                index,
            }: {index: number}) => {
                return (
                    <BookingDetailsStep
                        value={state.thirdStep}
                        onChange={onChangeThirdStep}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        index={index}
                        selectedLaneId={selectedLaneId}
                        onReset={onResetThirdStep}
                        onEdit={onResetFourthStep}
                        laneData={state.secondStep}
                    />
                )
            },
        },
        {
            key: '3',
            label: 'Lane Implementation Details',
            expanded: (currentStep) => {
                return currentStep > 3
            },
            renderContent: ({
                index,
            }: {index: number}) => {
                return (
                    <LaneImplementationStep
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        index={index}
                        value={state.fourthStep}
                        onChange={onChangeFourthStep}
                        onReset={onResetFourthStep}
                        onEdit={onResetFifthStep}
                        laneData={selectedLaneId ? state.secondStep : state.thirdStep.createdLane}
                    />
                )
            },
        },
        {
            key: '4',
            label: 'Finalize Booking',
            expanded: (currentStep) => {
                return currentStep > 4
            },
            renderContent: ({
                index,
            }: {index: number}) => {
                return (
                    <FinalizeBookingStep
                        value={state.fifthStep}
                        onChange={onChangeFifthStep}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                        index={index}
                        laneId={selectedLaneId || state.thirdStep.createdLane?.id}
                        onReset={onResetFifthStep}
                    />
                )
            },
        },
        {
            key: '5',
            label: 'Close Pre Booking',
            expanded: false,
            renderContent: ({
                index,
            }) => {
                return (
                    <ClosePrebookingStep
                        index={index}
                        laneData={selectedLaneId ? state.secondStep : state.thirdStep.createdLane}
                        setIsEdited={setIsEdited}
                        bookingDetails={state.thirdStep}
                        order={state.fifthStep}
                        setActiveStep={setActiveStep}
                    />
                )
            },
        },
    ]
}

const CreateCustomerTransportForm = () => {
    const [
        state,
        dispatch,
    ]: [
        CreateFormType,
        (args: {
            type: string,
            newValue?:
                | Partial<SelectLaneStepType>
                | Partial<AdditionalLaneStepType>
                | Partial<BookingDetailsStepType>
                | Partial<Lane>
        }) => void,
    ] = useReducer(reducer, defaultState)

    useBeforeUnloadDialog(state !== defaultState)

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    const onChangeFirstStep = (newValue: Partial<SelectLaneStepType>) => {
        setIsEdited(true)
        dispatch(setFirstStep(newValue))
    }

    const onChangeSecondStep = (newValue: Partial<AdditionalLaneStepType>) => {
        dispatch(setSecondStep(newValue))
    }

    const onResetSecondStep = () => {
        dispatch(resetSecondStep())
    }

    const onChangeThirdStep = (newValue: Partial<BookingDetailsStepType>) => {
        dispatch(setThirdStep(newValue))
    }

    const onResetThirdStep = useCallback(() => {
        dispatch(resetThirdStep(state.firstStep.selectedLaneId))
    }, [state.firstStep.selectedLaneId])

    const onChangeFourthStep = (newValue: Partial<Lane>) => {
        dispatch(setFourthStep(newValue))
    }

    const onResetFourthStep = () => {
        dispatch(resetFourthStep())
    }

    const onChangeFifthStep = (newValue: Partial<FinalizeBookingStepType>) => {
        dispatch(setFifthStep(newValue))
    }

    const onResetFifthStep = () => {
        dispatch(resetFifthStep())
    }

    const stepperSteps = useMemo(() => {
        return getSteps({
            state,
            activeStep,
            setActiveStep,
            onChangeFirstStep,
            onChangeSecondStep,
            onResetSecondStep,
            onResetThirdStep,
            onChangeThirdStep,
            onChangeFourthStep,
            onResetFourthStep,
            onChangeFifthStep,
            onResetFifthStep,
            setIsEdited,
        })
    }, [
        activeStep,
        onResetThirdStep,
        state,
    ])

    return (
        <>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </>
    )
}

export default CreateCustomerTransportForm
