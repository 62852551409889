import {
    ContainerGeoLocation,
} from 'app/Apps/ContainerManagement/Containers/containers.types'
import {
    DTOCardFormField,
} from 'app/types/form.types'

const geolocationFields: DTOCardFormField<keyof ContainerGeoLocation>[] = [{
    title: 'Information',
    fields: [
        {
            id: 'column1',
            fields: [
                {
                    name: 'latitude',
                    componentName: 'InputSingleline',
                    labelKey: 'Location.latitude',
                },
                {
                    name: 'timestamp',
                    componentName: 'InputSingleline',
                    noLabel: true,
                    className: 'timestamp',
                },
            ],
        },
        {
            id: 'column2',
            fields: [{
                name: 'longitude',
                componentName: 'InputSingleline',
                labelKey: 'Location.longitude',
            }],
        },
    ],
}]

export default geolocationFields
