import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useStyles from './CardDashboard.style'

const propTypes = {
    header: PropTypes.element.isRequired,
    smallContent: PropTypes.element.isRequired,
    layer: PropTypes.element,
    className: PropTypes.string,
}

const defaultProps = {
    className: '',
    layer: null,
}

const CardDashboard = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        header,
        smallContent,
        layer,
        className,
    } = props

    return (
        <div
            data-testid="card-dashboard"
            className={clsx(classes.buttonContainer, className)}
        >
            <div>
                {header}
            </div>
            <div>
                {smallContent}
            </div>
            {layer
                && (
                    <div className={clsx(classes.layer, className)}>
                        {layer}
                    </div>
                )}
        </div>
    )
}

CardDashboard.propTypes = propTypes
CardDashboard.defaultProps = defaultProps

export default CardDashboard
