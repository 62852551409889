import {
    useContext, createContext, Dispatch, SetStateAction,
} from 'react'

export type SkyNetSpreadsheetValidationContextType = {
    showError: boolean,
    setShowError: Dispatch<SetStateAction<boolean>>,
    valid: boolean,
    setValid: Dispatch<SetStateAction<boolean>>,
}

// eslint-disable-next-line max-len
export const SkyNetSpreadsheetValidationContext = createContext<SkyNetSpreadsheetValidationContextType>(null)

export default () => {
    const context = useContext(SkyNetSpreadsheetValidationContext)

    return context ?? {
        showError: false,
        setShowError: () => {},
        valid: true,
        setValid: () => {},
    }
}
