import {
    useEffect,
    useRef,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

const useScrollToForm = (
    refIdentifier: string,
    scrollToLastPath?: boolean,
): React.MutableRefObject<HTMLInputElement> => {
    const {
        pathname: location,
    } = useLocation()

    const elRef = useRef(null)

    useEffect(() => {
        const locationArray = location.split('/')
        const lastParamFromLocation = locationArray[locationArray.length - 1]

        if (scrollToLastPath && lastParamFromLocation !== refIdentifier) {
            return
        }
        elRef.current?.scrollIntoView({
            behavior: 'smooth',
        })
    }, [
        elRef,
        location,
        refIdentifier,
        scrollToLastPath,
    ])
    return elRef
}

export default useScrollToForm
