import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    dateToStrNoTime,
} from 'app/utils/date'

import requestConfig from '../../co2simulations.request'
import {
    SelectedContainerType,
    ShipmentCalculationType,
} from '../../co2simulations.types'

const getShipmentLegs = (shipmentLegs: number[]): {
    originLocation: {id: number},
    destinationLocation: {id: number},
    transportationMode: string,
}[] => {
    return shipmentLegs.reduce((acc, element, index, data) => {
        if (index === data.length - 1) {
            return acc
        }

        return [
            ...acc,
            {
                originLocation: {
                    id: element,
                },
                destinationLocation: {
                    id: data[index + 1],
                },
                transportationMode: 'AIR',
            },
        ]
    }, [])
}

const useLoadCo2Calculation = (
    selectedContainer: SelectedContainerType,
    filteredRoutes: number[],
) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<ShipmentCalculationType>({
        key: RequestKeys.getCo2Calculation,
        params: {
            modelParameterAsOf: dateToStrNoTime(new Date()),
            selectedMethodologies: ['MY_CLIMATE'],
            selectedTechnologies: [
                'SKYCELL_HYBRID',
                'PASSIVE',
                'ACTIVE',
            ],
            selectedQuantities: [{
                containerType: {
                    id: selectedContainer?.id,
                },
                containerAmount: selectedContainer?.containerAmount,
            }],
            shipmentLegs: getShipmentLegs(filteredRoutes),
        },
        requestFunc: RequestFn.getEntity(requestConfig.Co2Calculation.requestFn),
        enabled: Boolean(selectedContainer?.id && filteredRoutes.length > 1),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useLoadCo2Calculation
