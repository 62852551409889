const injectSearchQuery = (searchQuery) => {
    return (cb) => {
        return (params) => {
            if (!searchQuery) {
                return cb(params)
            }

            return cb({
                ...params,
                searchAll: searchQuery,
            })
        }
    }
}

export default injectSearchQuery
