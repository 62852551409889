import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'

import useHasPermission from 'app/hooks/useHasPermission'
import GatewayRequests from './Gateways.request'
import Overview from './Overview'
import GatewayCreate from './GatewayCreate'
import GatewayUpdate from './GatewayUpdate'
import GatewayChangeHistory from './GatewayChangeHistory'
import tableConfig from './Gateway.config'

const tableControls: TableControlTypes[] = [TableControlTypes.ClearSorting]

const overviewTab: SidebarTab = {
    url: 'overview',
    label: 'Overview',
    Component: Overview,
}

const Gateways = () => {
    const gatewayCreate = useHasPermission(['gateway_create'])
    const gatewayUpdate = useHasPermission(['gateway_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: {
                url: 'data-update',
                label: 'Data Update',
                Component: GatewayUpdate,
            },
            permission: gatewayUpdate,
        },
        {
            tab: {
                label: 'Change History',
                url: 'change-history',
                Component: GatewayChangeHistory,
            },
            permission: true,
        },
    ])

    return (
        <SkyNetTable
            name="GatewayView"
            moduleName={GatewayRequests.domainName}
            createForm={gatewayCreate ? GatewayCreate : undefined}
            tabs={tabs}
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Gateways
