import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(1.5),
            },
        },
        pointer: {
            cursor: 'pointer',
        },
        wrapper: {
            marginTop: theme.spacing(4),
        },
    }
})

export default useStyles
