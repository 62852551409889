import moment from 'moment'

import dateTimeZonedMask from './dateTimeZonedMask'

const dateToStr = (date, mask = dateTimeZonedMask) => {
    if (!date) {
        return undefined
    }
    const momentDate = moment(date)

    if (!momentDate.isValid()) {
        return undefined
    }
    return momentDate.format(mask)
}

export default dateToStr
