import {
    makeStyles,
} from 'app/tss'

const general = 'general'
const handover = 'handover'
const collection = 'collection'
const productTypes = 'productTypes'
const consignee = 'consignee'
const customerExecution = 'customerExecution'

const useStyles = makeStyles<{showProductTypes: boolean}>()((theme, {
    showProductTypes,
}) => {
    const gridTemplateAreas = showProductTypes ? `
                "${general} ${handover}"
                "${general} ${collection}"
                "${general} ${productTypes}"
                ` : `
                "${general} ${handover}"
                "${general} ${collection}"
                `

    const gridTemplateAreasReviewGroup = `
                "${general} ${handover}"
                "${consignee} ${handover}"
                "${customerExecution} ${collection}"
                `

    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas,
            gridTemplateColumns: 'repeat(2, 2fr)',
        },
        gridWrapperReviewGroup: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: gridTemplateAreasReviewGroup,
            gridTemplateColumns: 'repeat(2, 2fr)',
        },
        general: {
            gridArea: general,
            height: 'fit-content',
        },
        handover: {
            gridArea: handover,
        },
        collection: {
            gridArea: collection,
        },
        productTypes: {
            gridArea: productTypes,
        },
        consignee: {
            gridArea: consignee,
        },
        customerExecution: {
            gridArea: customerExecution,
        },
    }
})

export default useStyles
