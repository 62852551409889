import React, {
    useMemo,
    useState,
    useCallback,
    useEffect,
} from 'react'
import {
    Grid,
} from '@mui/material'
import pick from 'lodash/pick'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'

import StatusHandler from 'app/shared-components/StatusHandler'
import FormWithControls from 'app/shared-components/FormWithControls'
import changeStatusFields from './ChangeStatus.fields'
import useStyles from './ChangeStatus.styles'
import containerRequests from '../../Containers.request'
import useGetContainerBySerialNumber from '../../hooks/useGetContainerBySerialNumber'

type ContainerChangeStatusProps = {
    id: string,
}

const TO_BE_LOGGER_EXCHANGED = 'TO_BE_LOGGER_EXCHANGED'

const ChangeStatus = ({
    id: serialNumber,
}: ContainerChangeStatusProps) => {
    const {
        classes,
    } = useStyles()

    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetContainerBySerialNumber(serialNumber)

    const getAllowedValues = useEnumValues()
    const [
        isToBeLoggerExchanged,
        setIsToBeLoggerExchanged,
    ] = useState(false)

    const onChange = useCallback(({
        containerStatus: newContainerStatus,
    }) => {
        setIsToBeLoggerExchanged(newContainerStatus === TO_BE_LOGGER_EXCHANGED)
    }, [])

    useEffect(() => {
        if (isDataReady) {
            setIsToBeLoggerExchanged(data?.containerStatus === TO_BE_LOGGER_EXCHANGED)
        }
    }, [
        data?.containerStatus,
        isDataReady,
    ])

    const fieldsWithClasses = useFieldsWithClassName(
        changeStatusFields(isToBeLoggerExchanged, getAllowedValues),
        classes,
    )

    const value = useMemo(() => {
        return pick(data, changeStatusFields(
            data?.containerStatus, getAllowedValues,
        )[0].fields.map(({
            name,
        }) => {
            return name
        }))
    }, [
        data,
        getAllowedValues,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                { data?.id ? (
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <FormWithControls
                                name="ContainerStatusChangeDto"
                                value={value}
                                fields={fieldsWithClasses}
                                onSuccess={invalidate}
                                classNames={{
                                    gridWrapper: classes.gridWrapper,
                                    gridCardWrapper: classes.gridCardWrapper,
                                }}
                                setExternalValue={onChange}
                                exists
                                requestParams={containerRequests.statusChange({
                                    serialNumber,
                                })}
                            />
                        </Grid>
                    </Grid>
                ) : null }
            </>

        </StatusHandler>
    )
}

export default ChangeStatus
