import {
    RequestConfig, SelectorConfig,
} from 'app/types/request.types'
import {
    LoggerType,
} from 'app/types/enums'
import {
    LoggerPosition,
} from './Loggers.types'

type LoggerParams = {
    loggerNumber: string,
    loggerType: LoggerType,
    allowedSensorPositions: LoggerPosition[]
}

type LoggerRequests = {
    domainName: string,
    get: ({
        id,
    }) => RequestConfig,
    getByLoggerNumber: ({
        loggerNumber,
    }) => RequestConfig,
    create: () => RequestConfig,
    update: ({
        serialNumber,
    })=> RequestConfig,
    modifications: ({
        id,
    }:{
        id: number,
    }) => RequestConfig,
    selector: SelectorConfig<LoggerParams[], LoggerParams>,
}

const loggerRequest: LoggerRequests = {
    domainName: 'Logger',
    selector: {
        url: 'logger/filter-request/selector',
        convertion: (items) => {
            return items.map(({
                loggerNumber, loggerType, allowedSensorPositions,
            }) => {
                return {
                    label: loggerNumber,
                    loggerNumber,
                    loggerType,
                    allowedSensorPositions,
                    value: loggerNumber,
                }
            })
        },
    },
    get: ({
        id,
    }) => {
        return {
            url: `logger/${id}`,
        }
    },
    getByLoggerNumber: ({
        loggerNumber,
    }) => {
        return {
            url: `logger/loggernumber/${loggerNumber}`,
            method: 'GET',
        }
    },
    create: () => {
        return {
            url: 'logger',
            method: 'POST',
        }
    },
    update: ({
        serialNumber,
    }) => {
        return {
            url: `logger/${serialNumber}/data`,
            method: 'PATCH',
        }
    },
    modifications: ({
        id,
    }) => {
        return {
            url: `logger/${id}/modifications`,
            method: 'GET',
        }
    },
}

export default loggerRequest
