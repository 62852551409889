/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    MenuItem,
} from '@mui/material'
import PropTypes from 'prop-types'

const propTypes = {
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any, // eslint-disable-line
        }),
    ]),
    innerProps: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.elementType,
        }),
    ]),
    isFocused: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    children: PropTypes.string,
    selectProps: PropTypes.shape({
        showTitleOptions: PropTypes.bool,
    }).isRequired,
}

const defaultProps = {
    innerRef: {},
    innerProps: {},
    children: '',
}

function Option(props) {
    const {
        innerRef,
        innerProps,
        isFocused,
        isSelected,
        children,
        selectProps,
    } = props

    const {
        showTitleOptions,
    } = selectProps

    return (
        <MenuItem
            title={(showTitleOptions && children) || ''}
            buttonRef={innerRef}
            selected={isFocused}
            component="div"
            style={{
                fontWeight: isSelected ? 600 : 400,
                textOverflow: 'ellipsis',
                display: 'block',
                overflow: 'hidden',
            }}
            {...innerProps}
        >
            {children}
        </MenuItem>
    )
}

Option.propTypes = propTypes
Option.defaultProps = defaultProps

export default Option
