import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    Company,
} from '../Companies.types'

const getSkyMindSyncFields = (
    skyMindId: boolean,
    availableInSkyMind: boolean,
): DTOCardFormField<keyof Company>[] => {
    return [{
        id: 'SkyMindSync',
        title: 'SkyMind Sync',
        fields: [
            {
                name: 'availableInSkyMind',
                componentName: 'Switch',
                disabled: skyMindId,
                labelKey: 'Company.availableInSkyMind',
            },
            {
                name: 'skyMindId',
                className: 'skyMindId',
                componentName: 'InputSingleline',
                disabled: !availableInSkyMind || skyMindId,
                labelKey: 'Company.skyMindId',
            },
        ],
    }]
}

export default getSkyMindSyncFields
