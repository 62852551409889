import React from 'react'

import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom'
import Damages from './Damages'

const DamageTracking = () => {
    const {
        path,
    } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/damages`}>
                <Damages />
            </Route>

            <Redirect
                exact
                path={path}
                to={`${path}/damages`}
            />

            <Route path="/">
                <div>
                    Not found
                </div>
            </Route>
        </Switch>
    )
}

export default DamageTracking
