import React, {
    useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'
import Button from 'app/shared-components/Button'
import Tooltip from 'app/shared-components/Tooltip'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    LeaseType,
} from 'app/types/enums'

import {
    OrderDetailsStep,
} from '../../createDirectLease.types'
import getFields from './orderDetails.fields'
import useStyles from './OrderDetails.styles'

const OrderDetails = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    onEdit,
    index,
}: {
    value: OrderDetailsStep,
    onChange: (newVal: OrderDetailsStep) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    onEdit: () => void,
    index: number,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const includeFilters = useMemo(() => {
        return {
            leaseType: LeaseType.DIRECT_LEASE,
            customerExecutionId: value?.customerExecution && [value?.customerExecution.id],
            consigneeId: value?.consignee && [value?.consignee.id],
            originAirportId: value?.originAirport && [value?.originAirport.id],
            destAirportId: value?.destinationAirport && [value?.destinationAirport.id],
            pricingAccountId: value?.pricingAccount && [value?.pricingAccount.id],
            laneGroupTempRange: value?.temperatureRange && [value?.temperatureRange],
            laneGroupHandoverPointId: value?.handoverPoint && [value?.handoverPoint?.id],
            laneGroupCollectionDropoffPointId: value?.collectionDropoffPoint
                && [value?.collectionDropoffPoint?.id],
        }
    }, [value])

    const fields = useFieldsWithClassName(getFields({
        includeFilters,
        getAllowedValues,
    }), classes)

    const onclickEdit = () => {
        onEdit()
        setActiveStep(index)
    }

    return (
        <>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                className={classes.gridWrapper}
                disabled={activeStep > index}
                name="OrderInformation"
            />
            <div className={classes.actions}>
                {activeStep === index && (
                    <Tooltip
                        title="Please select a lane"
                        disabled={Boolean(value?.selectedLaneId)}
                    >
                        <div>
                            <Button
                                label="Continue"
                                onClick={() => {
                                    setActiveStep(index + 1)
                                }}
                                disabled={!value?.selectedLaneId}
                                data-testid="btn-continue"
                            />
                        </div>
                    </Tooltip>
                )}
                {activeStep > index && (
                    <Button
                        label="Edit"
                        onClick={onclickEdit}
                        className={classes.button}
                        data-testid="btn-edit"
                    />
                )}
            </div>
        </>
    )
}

export default OrderDetails
