import React, {
    useState,
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'

import InputSingleline from 'app/shared-components/InputSingleline'
import Select from 'app/shared-components/Select'

import useStyles from './AddForm.styles'

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.string),
    allowedValues: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
    }),
    addRequiredContainers: PropTypes.func.isRequired,
    required: PropTypes.bool,
}

const defaultProps = {
    data: [],
    allowedValues: [],
    required: false,
}

const AddForm = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        data,
        allowedValues,
        addRequiredContainers,
        required,
    } = props

    const options = useMemo(() => {
        return data.reduce((acc, item) => {
            return {
                ...acc,
                [item]: allowedValues[item],
            }
        }, {})
    }, [
        allowedValues,
        data,
    ])

    const [
        formValue,
        setFormValue,
    ] = useState({
        amount: 1,
        productType: data[0],
    })

    const onAmountChange = useCallback((value) => {
        setFormValue({
            ...formValue,
            amount: Number(value),
        })
    }, [formValue])

    const onContainerTypeChange = useCallback((productType) => {
        setFormValue({
            ...formValue,
            productType,
        })
    }, [formValue])

    const add = useCallback(() => {
        const {
            productType,
            amount,
        } = formValue

        if (amount && productType) {
            addRequiredContainers({
                quantity: amount,
                productType,
            })
        }
    }, [
        addRequiredContainers,
        formValue,
    ])

    return (
        <div className={classes.root}>
            <div className={classes.amount}>
                <InputSingleline
                    name="amount"
                    classNames={{
                        label: classes.label,
                    }}
                    type="number"
                    min={1}
                    centerAligned
                    onChange={onAmountChange}
                    value={formValue.amount}
                    variant="standard"
                    required={required}
                    InputProps={{
                        inputProps: {
                            className: classes.amountInput,
                            min: 1,
                        },
                    }}
                />
            </div>
            <span className={classes.cross}>x</span>
            <Select
                name="containerType"
                className={{
                    formControl: classes.select,
                }}
                allowedValues={options}
                value={formValue.productType}
                onChange={onContainerTypeChange}
                required={required}
            />
            <div
                className={classes.control}
                data-testid="add-btn"
                onMouseDown={add}
            >
                +
            </div>
        </div>
    )
}

AddForm.propTypes = propTypes
AddForm.defaultProps = defaultProps

export default AddForm
