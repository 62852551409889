import {
    makeStyles,
} from 'app/tss'

const origin = 'origin'
const handoverPoint = 'handoverPoint'
const dropOffPoint = 'dropOffPoint'
const destination = 'destination'
const orderInfo = 'orderInfo'
const summary = 'summary'
const cancellation = 'cancellation'
const consignee = 'consignee'
const customer = 'customer'
const orderStatus = 'orderStatus'
const selectedAsset = 'selectedAsset'
const orderStatusHistory = 'orderStatusHistory'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${orderInfo} ${summary} ${summary} ${orderStatus}"
                "${consignee} ${orderStatusHistory} ${selectedAsset} ${selectedAsset}"
                "${customer} ${orderStatusHistory} ${selectedAsset} ${selectedAsset}"
                "${origin} ${handoverPoint} ${dropOffPoint} ${destination}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapperCancellation: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${orderInfo} ${summary} ${summary} ${orderStatus}"
                "${cancellation} ${orderStatusHistory} ${selectedAsset} ${selectedAsset}"
                "${consignee} ${orderStatusHistory} ${selectedAsset} ${selectedAsset}"
                "${customer} ${orderStatusHistory} ${selectedAsset} ${selectedAsset}"
                "${origin} ${handoverPoint} ${dropOffPoint} ${destination}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        origin: {
            gridArea: origin,
        },
        handoverPoint: {
            gridArea: handoverPoint,
        },
        dropOffPoint: {
            gridArea: dropOffPoint,
        },
        selectedAsset: {
            gridArea: selectedAsset,
            minHeight: 310,
        },
        destination: {
            gridArea: destination,
        },
        orderInfo: {
            gridArea: orderInfo,
        },
        summary: {
            gridArea: summary,
        },
        cancellation: {
            gridArea: cancellation,
        },
        consignee: {
            gridArea: consignee,
        },
        customer: {
            gridArea: customer,
        },
        orderStatusHistory: {
            gridArea: orderStatusHistory,
        },
        wrapper: {
            '&&': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        root: {
            minHeight: 300,
        },
        'root &:not(:first-of-type)': {
            marginTop: theme.spacing(2),
        },
        'root &:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
        title: {
            '&&': {
                paddingLeft: theme.spacing(2),
            },
        },
    }
})

export default useStyles
