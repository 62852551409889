import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        buttonWrapper: {
            height: '100%',
            display: 'flex',
            opacity: 0.5,
            alignItems: 'center',
        },
        active: {
            opacity: 1,
        },
    }
})

export default useStyles
