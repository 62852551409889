import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {
            width: 250,
            padding: 20,
            paddingTop: 30,
        },
    }
})

export default useStyles
