import React from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import useGetLaneById from 'app/Apps/OrderManagement/hooks/useGetLaneById'

import {
    AdditionalLaneStepType,
    SelectLaneStepType,
} from '../../createCustomerTransportForm.types'
import AdditionalLaneStep from './AdditionalLaneStep'

type Props = {
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
    selectLaneData: SelectLaneStepType,
    value: AdditionalLaneStepType,
    onChange: (newVal: AdditionalLaneStepType) => void,
    onReset: () => void,
    onEdit: () => void,
}

const AdditionalLaneStepContainer = ({
    activeStep,
    setActiveStep,
    index,
    selectLaneData,
    value,
    onChange,
    onReset,
    onEdit,
}: Props) => {
    const {
        selectedLaneId,
    } = selectLaneData

    const {
        data: selectedLane,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLaneById(selectedLaneId)

    if (selectedLaneId) {
        return (
            <StatusHandler
                isSuccess={isDataReady && !!value}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <AdditionalLaneStep
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    index={index}
                    selectLaneData={selectedLane}
                    value={value}
                    onChange={onChange}
                    onReset={onReset}
                    onEdit={onEdit}
                />
            </StatusHandler>
        )
    }

    return (
        <AdditionalLaneStep
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            index={index}
            selectLaneData={selectLaneData}
            value={value}
            onChange={onChange}
            onReset={onReset}
            onEdit={onEdit}
        />
    )
}

export default AdditionalLaneStepContainer
