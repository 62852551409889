import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    Domain,
} from '../SkyNetSpreadSheet.types'

import requests, {
    SpreadsheetMappingRequest, SpreadsheetMappingResponse,
} from '../SkyNetSpreadSheet.requests'

export default ({
    onSuccess,
    configId,
}: {
    configId: string,
    onSuccess: (p?: boolean) => void
}) => {
    const token = useJWTToken()

    const {
        onStartSaving,
        removePendingStatus,
        onError,
    } = useHandleStatusRequest({
        id: `${configId}-spreadsheet`,
    })

    const onSuccessAction = useCallback((positive: boolean) => {
        removePendingStatus()
        onSuccess(positive)
    }, [
        removePendingStatus,
        onSuccess,
    ])

    const {
        mutateAsync: getSpreadsheetMapping,
    } = useMutation({
        mutationFn: (params: SpreadsheetMappingRequest) => {
            return simpleApi({
                ...requests.getSpreadsheetMapping(params),
                token,
            })
        },
        onError,
    })

    return useCallback(async (unresolvedValues: Map<Domain, Set<string>>) => {
        onStartSaving('Checking Database for results')
        onSuccess(false)
        const mapResult = new Map()

        return Promise.all(Array.from(unresolvedValues, async ([
            domain,
            data,
        ]) => {
            return mapResult.set(domain, await getSpreadsheetMapping({
                domain, data: Array.from(data),
            }))
        })).then(() => {
            onSuccessAction(true)
            return mapResult as Map<Domain, SpreadsheetMappingResponse>
        }).catch(() => {
            onSuccessAction(true)
            return mapResult as Map<Domain, SpreadsheetMappingResponse>
        })
    }, [
        onStartSaving,
        onSuccess,
        getSpreadsheetMapping,
        onSuccessAction,
    ])
}
