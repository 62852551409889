import React, {
    useRef,
} from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
} from '@mui/material'
import {
    useTheme,
} from '@mui/material/styles'
import Avatar from 'app/shared-components/Avatar'

import AlertNotifications from 'app/shared-components/AlertNotifications'
import SharedContext from 'app/shared-components/SharedContext'
import ActiveTab from './ActiveTab'

import useStyles from './Panel.style'

const propTypes = {
    userName: PropTypes.string,
    userAvatar: PropTypes.string,
}

const defaultProps = {
    userName: '',
    userAvatar: null,
}

const Panel = ({
    userName,
    userAvatar,
}) => {
    const theme = useTheme()
    const containerRef = useRef(null)
    const {
        classes,
    } = useStyles(theme)

    return (
        <div
            className={classes.root}
            ref={containerRef}
        >
            <div className={classes.activeTabContainer}>
                <ActiveTab />
            </div>
            <div
                className={classes.controls}
            >
                <SharedContext value={containerRef?.current?.offsetHeight}>
                    <AlertNotifications />
                </SharedContext>
                <div
                    className={classes.userContainer}
                >
                    <div
                        className={classes.control}
                    >
                        <Avatar img={userAvatar} />
                    </div>
                    <div
                        className={classes.control}
                    >
                        <Typography
                            data-testid="username"
                            variant="h5"
                            className={classes.userName}
                        >
                            {userName}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

Panel.propTypes = propTypes
Panel.defaultProps = defaultProps

export default Panel
