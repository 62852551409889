/* eslint-disable react/destructuring-assignment */
import React from 'react'
import {
    Moment,
} from 'moment/moment'

import Box from '@mui/material/Box'
import {
    pickersLayoutClasses,
    PickersLayoutContentWrapper,
    PickersLayoutProps,
    PickersLayoutRoot,
    usePickerLayout,
} from '@mui/x-date-pickers/PickersLayout'
import {
    DateView,
} from '@mui/x-date-pickers/models'
import {
    DateField,
} from '@mui/x-date-pickers/DateField'
import {
    DateTimeField,
} from '@mui/x-date-pickers/DateTimeField'
import {
    DateOrTimeView,
} from '@mui/x-date-pickers/models/views'

import {
    dateTimeMask,
    dateMask,
} from 'app/utils/date'

/*  Custom Layout component to show a button to switch between
the date/time picker view and the keyboard view,
that was removed in 7v version of @mui/x-date-pickers
*/
function LayoutWithKeyboardView(
    props: Readonly<PickersLayoutProps<Moment | null, Moment, DateView>>,
) {
    const {
        value, onChange,
    } = props
    const [
        showKeyboardView,
        setShowKeyboardView,
    ] = React.useState(false)

    const {
        toolbar, tabs, content, actionBar,
    } = usePickerLayout({
        ...props,
        slotProps: {
            ...props.slotProps,
            toolbar: {
                ...props.slotProps?.toolbar,
                // @ts-ignore
                showKeyboardViewSwitch: props.wrapperVariant === 'mobile',
                showKeyboardView,
                setShowKeyboardView,
            },
        },
    })

    const showTime = (props.views as unknown as DateOrTimeView).includes('hours')
        || (props.views as unknown as DateOrTimeView).includes('minutes')

    return (
        <PickersLayoutRoot ownerState={props}>
            {toolbar}
            {actionBar}
            <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
                {tabs}
                {showKeyboardView ? (
                    <Box sx={{
                        mx: 3, my: 2, width: 272,
                    }}
                    >
                        { showTime ? (
                            <DateTimeField
                                format={dateTimeMask}
                                value={value}
                                onChange={onChange}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        ) : (
                            <DateField
                                format={dateMask}
                                value={value}
                                onChange={onChange}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        )}
                    </Box>
                ) : (
                    content
                )}
            </PickersLayoutContentWrapper>
        </PickersLayoutRoot>
    )
}

export default LayoutWithKeyboardView
