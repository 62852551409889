import React from 'react'
import moment from 'moment'

import {
    dateTimeMask,
} from 'app/utils/date'

import LoggerAge, {
    LoggerAgeType,
} from './LoggerAge'

type Props = {
    mapCellProps: string,
    rowProps: LoggerAgeType,
}

const LoggerAgeFromDate = ({
    mapCellProps,
    rowProps,
}: Props) => {
    return (
        <LoggerAge
            mapCellProps={mapCellProps}
            rowProps={{
                ...rowProps,
                [mapCellProps]: moment(rowProps[mapCellProps], dateTimeMask).format('X'),
            }}
        />
    )
}

export default LoggerAgeFromDate
