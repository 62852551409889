import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'

import useEnumValues from 'app/hooks/useEnumValues'
import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    LoggerConfiguration,
} from 'app/types/enums'
import {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import loggerComponents from './LoggerForm.fields'
import loggerFormRequest from './LoggerForm.request'
import useStyles from './LoggerForm.styles'

import {
    LoggerFormContainerProps,
    LoggerValue,
} from './LoggerForm.types'

type LoggerFormProps = LoggerFormContainerProps & {
    value: LoggerValue,
    loadOptions?: (o: DelayedLoadOptions) => void,
    setExternalValue?: (value: LoggerValue) => void,
    loadLabel?: (id: number | string) => void,
}

const defaultProps: Partial<LoggerFormProps> = {
    loadOptions: noop,
    setExternalValue: noop,
    loadLabel: noop,
}

const LoggerForm = ({
    loadOptions,
    loadLabel,
    value,
    serialNumber,
    onSuccess,
    setExternalValue,
}: LoggerFormProps) => {
    const {
        classes,
    } = useStyles()
    const loggerLabels = useEnumValues()(LoggerConfiguration)

    const fields = useFieldsWithClassName(useMemo(() => {
        return loggerComponents({
            loadOptions,
            loadLabel,
            value,
            loggerLabels,
        })
    }, [
        loadOptions,
        loadLabel,
        value,
        loggerLabels,
    ]), classes)

    return (
        <div className={classes.container}>
            <FormWithControls
                name="LoggerInformationDto"
                setExternalValue={setExternalValue}
                value={value}
                fields={fields}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                    gridCardWrapper: classes.gridCardWrapper,
                }}
                requestParams={loggerFormRequest.request({
                    serialNumber,
                })}
                onSuccess={onSuccess}
            />
        </div>
    )
}

LoggerForm.defaultProps = defaultProps

export default LoggerForm
