import {
    SimulationDataChart,
} from 'app/Apps/TransportPlanner/Simulation/simulation.types'

const getDataChart = ({
    simulationData,
    ambientTemperature,
    showEnergyLevel,
    showTemperatureRange,
}: {
    simulationData: SimulationDataChart,
    ambientTemperature: number[],
    showEnergyLevel: boolean,
    showTemperatureRange: boolean,
}): {
    name: string,
    data: number[]
}[] => {
    return [
        {
            name: 'Internal Temperature',
            data: simulationData?.internalTemperature,
        },
        {
            name: 'Ambient Temperature',
            data: ambientTemperature,
        },
        ...showTemperatureRange ? [
            {
                name: 'Temperature Range Minimum',
                data: simulationData?.min,
            },
            {
                name: 'Temperature Range Maximum',
                data: simulationData?.max,
            },
        ] : [],
        ...showEnergyLevel ? [{
            name: 'Remaining Energy Level',
            data: simulationData?.remainingEnergyLevel || [],
        }] : [],
    ]
}

export default getDataChart
