import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        cloudDoneIcon: {
            '&&': {
                color: theme.palette.primary[200],
                width: theme.spacing(2),
                height: theme.spacing(2),
            },
        },
        cloudOffIcon: {
            '&&': {
                color: theme.palette.common.black,
                width: theme.spacing(2),
                height: theme.spacing(2),
            },
        },
    }
})

export default useStyles
