import React from 'react'
import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'
import tableConfig from './Damages.config'
import request from '../Damages.requests'
import DamageCreate from '../DamageCreate'
import DamageUpdate from '../DamageUpdate'
import DamageChangeHistory from '../DamageChangeHistory'

const updateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data',
    Component: DamageUpdate,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Damages = () => {
    const createPermission = useHasPermission(['damage_tracking_create'])
    const readPermission = useHasPermission(['damage_tracking_read'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: updateTab,
            permission: readPermission,
        },
        {
            tab: {
                label: 'Change History',
                url: 'change-history',
                Component: DamageChangeHistory,
            },
            permission: readPermission,
        },
    ])

    return (
        <SkyNetTable
            createForm={createPermission
                ? DamageCreate
                : undefined}
            name={tableConfig.name}
            moduleName={request.domainName}
            tabs={tabs}
            defaultTab="data"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Damages
