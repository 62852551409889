import {
    useMutation,
} from '@tanstack/react-query'

import axios from 'axios'

const useGetMicrosoftToken = () => {
    const {
        mutateAsync,
    } = useMutation({
        mutationFn: ({
            token, authServerUrl, realm,
        }:{token: string, authServerUrl: string, realm: string, }) => {
            return axios.get(`${authServerUrl}/realms/${realm}/broker/microsoft/token`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                return res.data
            }).catch((err) => {
                // eslint-disable-next-line no-console
                console.log('Error during requesting microsoft token', err)
                return {}
            })
        },
    })

    return mutateAsync
}

export default useGetMicrosoftToken
