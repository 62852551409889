import {
    ProductType,
} from 'app/types/enums'

const availableUpdateMapContainerTypes: {
    productType: ProductType,
    imgSrc: string,
}[] = [
    {
        productType: ProductType.VAL_1500XCOL,
        imgSrc: 'assets/images/containerTypes/tp_1500X.png',
    },
    {
        productType: ProductType.VAL_1500XCRT,
        imgSrc: 'assets/images/containerTypes/tp_1500X.png',
    },
    {
        productType: ProductType.VAL_1500C,
        imgSrc: 'assets/images/containerTypes/tp_1500C.png',
    },
    {
        productType: ProductType.VAL_1500CRT,
        imgSrc: 'assets/images/containerTypes/tp_1500CRT.png',
    },
    {
        productType: ProductType.VAL_2500C,
        imgSrc: 'assets/images/containerTypes/tp_2500C.png',
    },
    {
        productType: ProductType.VAL_2500CRT,
        imgSrc: 'assets/images/containerTypes/tp_2500CRT.png',
    },
    {
        productType: ProductType.VAL_1500DF_EUROMAX,
        imgSrc: 'assets/images/containerTypes/tp_1500DF.png',
    },
]

export default availableUpdateMapContainerTypes
