import React, {
    useCallback,
} from 'react'
import {
    useDispatch,
} from 'react-redux'
import moment from 'moment'
import kebabCase from 'lodash/kebabCase'
import downloadDocument from 'app/utils/downloadDocument'

import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import {
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import ContractBasisCreateQuote from 'app/Apps/Pricing/ContractBasis/ContractBasisCreateQuote/ContractBasisCreateQuote'
import useGetContractBasis from 'app/Apps/Pricing/ContractBasis/hooks/useGetContractBasis'
import useQuoteGenerate from 'app/Apps/Pricing/ContractBasis/hooks/useQuoteGenerate'
import DTOSpec from 'app/Apps/Pricing/ContractBasis/dtoSpec'

import {
    AccountQuote,
} from '../../AccountQuotes.types'
import requests from '../../AccountQuotes.requests'
import useQuotePDFPreview from '../../hooks/useQuotePDFPreview'

import useStyles from './CreateQuotePDF.style'

type Props = {
    quoteId: number,
    afterCreate: () => void,
}
const CreateQuotePDF = ({
    quoteId,
    afterCreate,
}: Props) => {
    const dispatch = useDispatch()
    const {
        classes,
    } = useStyles()

    const {
        data: accountQuote,
    } = useRequest<AccountQuote>({
        key: RequestKeys.getAccountQuote,
        params: {
            id: quoteId,
        },
        enabled: false,
        requestFunc: RequestFn.getEntity(requests.get),
        keepPreviousData: true,
    })

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContractBasis(accountQuote?.plannedContractBasis.id)

    const onGenerateSuccess = useCallback(() => {
        dispatch(
            successedToSaveForm({
                id: 'Notification',
                title: 'The Lane Quote have been successfully created.',
            }),
        )
        afterCreate()
    }, [
        dispatch,
        afterCreate,
    ])

    const {
        mutate: generate,
    } = useQuoteGenerate({
        locationName: data?.customerQuotation?.locationName,
        companyName: data?.account?.company?.companyName,
        request: DTOSpec.Quote.QuotePrint.GenerateRequest(quoteId),
        onSuccess: onGenerateSuccess,
    })
    const generatePDFCall = useCallback((params) => {
        generate({
            ...params,
        })
    }, [generate])

    const onPreviewSuccess = useCallback((responseData, locationName, companyName) => {
        const fileName = `${moment().format('YYYYMMDD')}${locationName ? `_${kebabCase(locationName)}` : ''}${companyName ? `_${kebabCase(companyName)}` : ''}`

        downloadDocument(responseData, fileName)
        dispatch(
            successedToSaveForm({
                id: 'Notification',
                title: 'The Quote PDF have been successfully downloaded.',
            }),
        )
    }, [dispatch])

    const {
        mutate: preview,
    } = useQuotePDFPreview(data?.customerQuotation?.locationName,
        data?.account?.company?.companyName,
        onPreviewSuccess)

    const previewPDFCall = useCallback((params) => {
        preview({
            ...params,
        })
    }, [preview])

    const renderButtons = useCallback((params) => {
        return (
            <div className={classes.doubleButtons}>
                <Button
                    label="Submit"
                    onClick={() => { return generatePDFCall(params) }}
                />
                <Button
                    label="Preview"
                    onClick={() => { return previewPDFCall(params) }}
                    className={classes.lastButton}
                />
            </div>
        )
    }, [
        generatePDFCall,
        previewPDFCall,
        classes.lastButton,
        classes.doubleButtons,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContractBasisCreateQuote
                id={accountQuote?.plannedContractBasis.id}
                quoteId={Number(quoteId)}
                showContactsSelections={false}
                renderActions={renderButtons}
            />
        </StatusHandler>
    )
}

export default CreateQuotePDF
