function insertAfter(arr, elem, newElem) {
    const index = arr.findIndex((item) => {
        return item === elem
    })

    return [
        ...arr.slice(0, index + 1),
        newElem,
        ...arr.slice(index + 1),
    ]
}

export default insertAfter
