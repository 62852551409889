/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react'
import Typography from '@mui/material/Typography'
import AddRemoveIcon from 'app/shared-components/CollapsableAddRemovePanel/AddRemoveIcon'
import TableControls, {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import useStyles from './useRenderTableControlPanel.styles'

const tableControlsDefaults: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
]
const useRenderTableControlPanel = ({
    title,
    stateName,
    createFormOpened,
    openCreateForm,
    closeCreateForm,
    tableControls = tableControlsDefaults,
    createEnabled = true,
    children,
    applySearch,
}:{
    title: string,
    stateName: string,
    createFormOpened?: boolean,
    openCreateForm?: () => void,
    closeCreateForm?: () => void,
    tableControls?: TableControlTypes[],
    createEnabled?: boolean,
    children?: JSX.Element,
    applySearch?: (s: string) => void
}) => {
    const {
        classes,
    } = useStyles()

    const tableControl = (controlPanelProps) => {
        return (
            <div
                className={classes.tableControlRoot}
            >
                <div>
                    <Typography variant="h3">
                        {title}
                    </Typography>
                </div>
                <div className={classes.tableControls}>
                    <TableControls
                        {...controlPanelProps}
                        simple
                        controls={tableControls}
                        storeName={stateName}
                        applySearch={applySearch}
                    />
                    {createEnabled ? (
                        <AddRemoveIcon
                            formOpened={createFormOpened}
                            onOpenForm={openCreateForm}
                            onCloseForm={closeCreateForm}
                        />
                    ) : null}
                    {children}
                </div>
            </div>
        )
    }

    return tableControl
}

export default useRenderTableControlPanel
