import {
    useCallback,
} from 'react'

type Params<DataType> = {
    data: DataType,
    isDataReady: boolean,
    delayTimeout?: number,
}

const DELAY_TIMEOUT = 200

const useRichtableLoadWithSpinner = <DataType>({
    data,
    isDataReady,
    delayTimeout = DELAY_TIMEOUT,
}: Params<DataType>) => {
    return useCallback((): Promise<DataType> => {
        if (!isDataReady) {
            return new Promise(() => {})
        }

        return new Promise((resolve) => {
            return setTimeout(() => {
                return resolve(data)
            }, delayTimeout)
        })
    }, [
        data,
        isDataReady,
        delayTimeout,
    ])
}

export default useRichtableLoadWithSpinner
