import React, {
    useMemo,
} from 'react'

import injectClass from 'app/utils/injectClass'

import {
    ContainerNoteType,
} from 'app/types/enums'
import clsx from 'clsx'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import getFields from './ContainerNoteDetails.fields'
import getClassNames from './utils/getClassNameByNoteType'
import useStyles from './ContainerNoteDetails.styles'

type Props = {
    name: string,
    data: {
        noteText: string,
        noteType: ContainerNoteType,
    },
}

export const ContainerNoteDetails = ({
    name,
    data: note,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const modifiedNote = useMemo(() => {
        const modifiedNoteText = note.noteText?.includes('\n')
            ? note.noteText.split('\n').map((item) => {
                return (
                    <span key={`noteText-${item}`}>
                        {item}
                        <br />
                    </span>
                )
            }) : note.noteText

        return {
            ...note,
            noteText: modifiedNoteText,
        }
    }, [note])

    const {
        gridWrapper, gridAreaTemplate, ...restClasses
    } = getClassNames(note.noteType, classes)

    return (
        <div className={classes.rowFormContainer}>
            <DomainObjectForm
                name={name}
                className={clsx(
                    gridWrapper, gridAreaTemplate,
                )}
                classNames={restClasses}
                value={modifiedNote}
                fields={injectClass(getFields(note.noteType), classes)}
                exists
                disabled
            />
        </div>
    )
}

export default ContainerNoteDetails
