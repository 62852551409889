import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        step: {
            marginRight: theme.spacing(1.5),
            padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(3)}`,
            borderRadius: 4,
            background: theme.palette.common.white,
            width: 150,
            boxShadow: `0px 1px 3px ${theme.palette.secondary[200]}`,
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            height: 285,
        },
        titleStep: {
            fontSize: theme.typography.h3.fontSize,
            display: 'flex',
            justifyContent: 'space-between',
        },
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            color: theme.palette.secondary[400],
            marginRight: theme.spacing(-1),
        },
        deleteBtn: {
            width: 23,
            height: 23,
            borderRadius: '50%',
            background: theme.palette.primary[400],
            color: theme.palette.common.white,
            fontSize: theme.typography.h3.fontSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: theme.spacing(1),
            cursor: 'pointer',
        },
        draggedCard: {
            boxShadow: `0 8px 14px ${theme.palette.secondary[400]}, 0 10px 10px ${theme.palette.secondary[400]}`,
            transform: 'scale(1.01)',
        },
    }
})

export default useStyles
