import React, {
    useMemo,
} from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import Tooltip from 'app/shared-components/Tooltip'
import {
    NotificationTypes,
} from 'app/types/enums'
import {
    NotificationTableView,
    SentStatus,
} from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/utils/OrderNotifications.types'

type Props = {
    value?: boolean,
    mapCellProps: string,
    rowProps: NotificationTableView,
}

const defaultProps: Partial<Props> = {
    value: undefined,
}

const FIELD_STATUS = {
    sendFirstTime: 'sentFirstStatus',
    sendSecondTime: 'sentSecondStatus',
    sendThirdTime: 'sentThirdStatus',
}

const STATUS_MAP = {
    SUCCESS: {
        iconName: 'circle_tick_mark_blue',
        title: 'Sent',
        className: 'Blue',
    },
    IN_PROGRESS: {
        title: 'Loading',
        className: 'Blue',
    },
    FAIL: {
        iconName: 'circle_cross_red',
        title: 'Failed',
        className: 'Red',
    },
}

const SendTimeCell = ({
    value,
    rowProps,
    mapCellProps,
}: Props) => {
    const {
        notificationType,
    } = rowProps

    const iconData: {
        iconName: string,
        title: string,
        className: string,
    } = useMemo(() => {
        if (STATUS_MAP[rowProps[FIELD_STATUS[mapCellProps]]]) {
            return STATUS_MAP[rowProps[FIELD_STATUS[mapCellProps]]]
        }

        if (value) {
            return {
                iconName: 'circle_tick_mark_grey',
                title: 'Planned',
                className: 'Grey',
            }
        }

        return {
            iconName: 'circle_cross_grey',
            title: 'Deactivated',
            className: 'Grey',
        }
    }, [
        value,
        rowProps,
        mapCellProps,
    ])

    return (
        <>
            {(mapCellProps === 'sendFirstTime' || notificationType !== NotificationTypes.NOTIFICATION) && (
                <Tooltip
                    title={iconData.title}
                >
                    {
                        rowProps[FIELD_STATUS[mapCellProps]] === SentStatus.IN_PROGRESS ? (
                            <CircularProgress size={22} />
                        ) : (
                            <img
                                src={`assets/images/${iconData.iconName}.svg`}
                                alt={iconData.title}
                            />
                        )
                    }
                </Tooltip>
            )}
        </>
    )
}

SendTimeCell.defaultProps = defaultProps

export default SendTimeCell
