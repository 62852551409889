import {
    RequestConfig,
} from 'app/types/request.types'

type AlLeasePricingRequests = {
    domainName: string,
    create: () => RequestConfig,
    update: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    get: ({
        id,
    }:{
        id: number,
    }) => RequestConfig,
}

const alLeasePricingRequests: AlLeasePricingRequests = {
    domainName: 'pharmaleasepricing',
    create: () => {
        return {
            url: 'pharmaleasepricing',
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `pharmaleasepricing/${id}`,
            method: 'PUT',
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `pharmaleasepricing/${id}`,
            method: 'GET',
        }
    },
}

export default alLeasePricingRequests
