import React from 'react'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import useRouteStateFilter from 'app/hooks/useRouteStateFilter'

import tableConfig from './LaneGroups.config'
import request from './LaneGroup.requests'
import LaneGroupOverview from './LaneGroupOverview'
import LaneFees from './LaneFees'
import Lanes from './Lanes'
import LaneGroupChangeHistory from './LaneGroupChangeHistory'

const updateTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: LaneGroupOverview,
    activeComparer: (pathname) => {
        return pathname.split('/').includes('overview')
    },
}

const lanesTab: SidebarTab = {
    label: 'Lanes',
    url: 'lanes',
    Component: Lanes,
    activeComparer: (pathname) => {
        return pathname.split('/').includes('lanes')
    },
}

const laneFeesTab: SidebarTab = {
    label: 'Lane Fees',
    url: 'lane-fees',
    Component: LaneFees,
    activeComparer: (pathname) => {
        return pathname.split('/').includes('lane-fees')
    },
}

const laneGroupsChangeHistory: SidebarTab = {
    label: 'Change History',
    url: 'change-history',
    Component: LaneGroupChangeHistory,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const LaneGroups = () => {
    const filter = useRouteStateFilter()

    return (
        <SkyNetTable
            name={tableConfig.name}
            moduleName={request.domainName}
            filter={filter}
            tabs={[
                updateTab,
                lanesTab,
                laneFeesTab,
                laneGroupsChangeHistory,
            ]}
            defaultTab="data"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default LaneGroups
