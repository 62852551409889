import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tableCell: {
            padding: '5px !important',
        },
        root: {
            position: 'relative',
        },
        crossLine: {
            width: '100%',
            left: 0,
            height: 1,
            top: '50%',
            position: 'absolute',
            background: theme.palette.secondary[600],
        },
    }
})

export default useStyles
