import {
    useContext, createContext,
} from 'react'
import {
    LaneFeesDetail,
} from 'app/Apps/Pricing/Pricing.types'
import {
    FormPart,
} from 'app/types/form.types'

export type LaneFeesFormType = {
    getFields: (...args: any[]) => FormPart<keyof LaneFeesDetail>[]
}

export const LaneFeesFormContext = createContext<LaneFeesFormType>(null)

const useLaneFeesFormContext = () => {
    return useContext<LaneFeesFormType>(LaneFeesFormContext)
}

export default useLaneFeesFormContext
