import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        rowFormContainer: {
            backgroundColor: theme.palette.secondary[100],
        },
        gridWrapper: {
            display: 'grid',
            padding: 10,
            gridTemplateColumns: '1fr',
        },
        gridCardWrapper: {
            gridTemplateColumns: '1fr',
        },

    }
})

export default useStyles
