import {
    RequestConfig,
} from 'app/types/request.types'

type CountOrdersAdditionalFeeRequests = {
    count: ({
        id, data,
    }) => RequestConfig
}

const countOrderAdditionalFeeRequests: CountOrdersAdditionalFeeRequests = {
    count: ({
        id,
        data,
    }) => {
        return {
            url: `order/billing/count/additionalfees/${id}`,
            method: 'POST',
            data,
        }
    },
}

export default countOrderAdditionalFeeRequests
