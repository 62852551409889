import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetLaneOpsInformation from '../../../hooks/useGetLaneOpsInformation'
import {
    BookingDetailsStep,
} from '../../createDirectLease.types'
import BookingDetails from './BookingDetails'

const BookingDetailsContainer = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    onEdit,
    onReset,
    index,
    selectedLaneId,
}: {
    value: BookingDetailsStep,
    onChange: (newVal: BookingDetailsStep) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    onEdit: () => void,
    onReset: () => void,
    index: number,
    selectedLaneId: number,
}) => {
    const {
        lane: selectedLane,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLaneOpsInformation({
        selectedLaneId,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady && !!value}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <BookingDetails
                value={value}
                onChange={onChange}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                onEdit={onEdit}
                onReset={onReset}
                index={index}
                selectedLane={selectedLane}
            />
        </StatusHandler>
    )
}

export default BookingDetailsContainer
