import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        formControl: {
            width: '100%',
        },
    }
})

export default useStyles
