import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import config from 'app/Apps/ContactManagement/Locations/Locations.request'

type Response = {
    old: Location,
    actual: Location,
}

const useGetLocations = ({
    locationOldId,
    locationNewId,
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Response>({
        key: RequestKeys.getLocations,
        params: {
            ids: [
                locationOldId,
                locationNewId,
            ],
        },
        requestFunc: RequestFn.getEntity(config.multiByIds, (promise) => {
            return promise.then((resp) => {
                return {
                    old: resp?.data?.items?.find((i) => {
                        return i.id === locationOldId
                    }),
                    actual: resp?.data?.items?.find((i) => {
                        return i.id === locationNewId
                    }),
                }
            })
        }),
        enabled: Boolean(locationOldId) && Boolean(locationNewId),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetLocations
