import React, {
    useEffect,
    useState,
} from 'react'
import noop from 'lodash/noop'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import SkyNetTable from 'app/shared-components/SkyNetTable'

import config from './PreviousOrdersITCreationForm.config'
import useStyles from './PreviousOrdersITCreationForm.style'

const DOMAIN_NAME = orderManagementRequest.PreviousOrders.name

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

type Props = {
    value?: number[],
    onChange?: (arg: number[]) => void,
    location: number,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    value: [],
    onChange: noop,
    disabled: false,
}

const PreviousOrdersITCreationForm = ({
    value,
    onChange,
    location,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const [
        defaultLocation,
        setDefaultLocation,
    ] = useState(location)

    useEffect(() => {
        if (defaultLocation !== location) {
            onChange([])
            setDefaultLocation(location)
        }
    }, [
        defaultLocation,
        location,
        onChange,
    ])

    return (
        <SkyNetTable
            name={DOMAIN_NAME}
            tableConfig={config}
            tableControls={tableControls}
            selectedRows={value}
            onSelectRow={onChange}
            customUrl={orderManagementRequest.PreviousOrders.url(location)}
            className={classes.root}
            simple
            noDetails
            showSearch
            disabled={disabled}
        />
    )
}

PreviousOrdersITCreationForm.defaultProps = defaultProps

export default PreviousOrdersITCreationForm
