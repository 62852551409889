import i18n from 'i18next'
import {
    initReactI18next,
} from 'react-i18next'
import Backend from 'i18next-http-backend'

const {
    REACT_APP_ASSETS_STORAGE: assetsStorageUrl,
} = process.env

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        returnEmptyString: true,
        parseMissingKeyHandler: () => {
            return ''
        },
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
        ns: [
            'translations',
            'enums',
        ],
        defaultNS: 'translations',
        backend: {
            allowMultiLoading: true,
            loadPath: (lng: string, ns: string[]) => {
                if (ns[0] === 'enums') {
                    return `${assetsStorageUrl}/skynet/DTOMetadata/enumsLabels.json`
                }
                return `${assetsStorageUrl}/skynet/DTOMetadata/dtoLabels.json`
            },
        },
    })

export default i18n
