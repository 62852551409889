import React, {
    useCallback,
} from 'react'
import get from 'lodash/get'

import useHasPermission from 'app/hooks/useHasPermission'
import {
    Currency,
} from 'app/types/enums'
import {
    OutgoingInvoice,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import InvoiceCard from './InvoiceCard'
import NewInvoiceCard from './NewInvoiceCard'
import useStyles from './InvoiceSelector.style'

type Props = {
    orderNumber?: string,
    onSuccess: (params: OutgoingInvoice) => void
    currency?: Currency,
    invoices?: OutgoingInvoice[] | [],
    selectedInvoice?: OutgoingInvoice,
    setSelectedInvoice: (invoice: OutgoingInvoice) => void
    disabled: boolean,
}

const defaultProps = {
    invoices: [],
    currency: undefined,
    selectedInvoice: undefined,
    orderNumber: undefined,
}
const InvoiceSelector = ({
    invoices,
    currency,
    selectedInvoice,
    setSelectedInvoice,
    orderNumber,
    onSuccess,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const outgoingInvoiceCreate = useHasPermission(['outgoing_invoice_create'])

    const selectFirstInvoice = useCallback((): void => {
        return setSelectedInvoice(get(invoices, '[0]', null))
    }, [
        invoices,
        setSelectedInvoice,
    ])

    return (
        <div className={classes.root}>
            {invoices.map((invoice) => {
                return (
                    <InvoiceCard
                        invoice={invoice}
                        selectInvoice={setSelectedInvoice}
                        currency={currency}
                        selected={invoice.id === selectedInvoice?.id}
                        onSuccess={onSuccess}
                        key={`invoice-${invoice.id}`}
                        disabled={disabled}
                        orderNumber={orderNumber}
                    />
                )
            })}
            {outgoingInvoiceCreate
            && (
                <NewInvoiceCard
                    orderNumber={orderNumber}
                    setSelectedInvoice={setSelectedInvoice}
                    onSuccess={onSuccess}
                    selected={!selectedInvoice}
                    selectFirstInvoice={selectFirstInvoice}
                />
            )}
        </div>
    )
}

InvoiceSelector.defaultProps = defaultProps

export default InvoiceSelector
