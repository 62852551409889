import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

import useAddedItem from 'app/shared-components/SkyNetTable/hooks/useAddedItem'
import {
    OpportunityStatus,
} from 'app/types/enums'

import OpportunitiesForm from '../OpportunitiesForm'

const CreateOpportunity = () => {
    const onSuccess = useAddedItem()
    const {
        state,
    } = useLocation()

    return (
        <OpportunitiesForm
            data={{
                ...state?.autofill,
                opportunityStatus: OpportunityStatus.OPEN,
            }}
            onSuccess={onSuccess}
        />
    )
}

export default CreateOpportunity
