import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    TruckCapacity,
} from '../serviceCenters.types'
import requestConfig from '../serviceCenters.request'

const useGetTruckCapacity = ({
    serviceCenter,
}: {
    serviceCenter: {id: number}
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<TruckCapacity>({
        key: RequestKeys.getTruckCapacity,
        params: {
            params: {
                serviceCenter,
            },
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetTruckCapacity.requestFn),
        enabled: Boolean(serviceCenter?.id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetTruckCapacity
