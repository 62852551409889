import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        warningMsg: {
            display: 'flex',
            background: theme.palette.warning.main,
            color: theme.palette.common.red,
            alignItems: 'center',
            borderRadius: 10,
            padding: theme.spacing(0.5, 1),
            marginBottom: theme.spacing(1.5),
        },
        warningText: {
            paddingLeft: theme.spacing(0.5),
        },
    }
})

export default useStyles
