import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import CheckCircle from '@mui/icons-material/CheckCircle'

import useStyles from './SuccessMessage.styles'

const propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
}

const defaultProps = {
    title: undefined,
}

const SuccessMessage = ({
    id, title,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <Alert
            key={`success-${id}`}
            variant="filled"
            severity="success"
            data-testid="alert-success"
            icon={(
                <div className={classes.iconWrapper}>
                    <CheckCircle fontSize="inherit" />
                </div>
            )}
        >
            {title || 'We have saved your updates'}
        </Alert>
    )
}

SuccessMessage.propTypes = propTypes
SuccessMessage.defaultProps = defaultProps

export default SuccessMessage
