import useGetPermissions from 'app/hooks/useGetPermissions'
import useGetRightsByName from 'app/hooks/useGetRightsByName'

import getPermission from './getPermission'

import accessRoles from './accessRoles'

/**
* Use this to check a permission type
* @param    {Array}  categories to match permission
* @param   {String}  accessRightType accessRightType to check
* @param   {String}  type permission type to load
* @return   {Boolean}  true if user has matching permission, false if not
*/

const useHasPermissionType = (categories, accessRightType, type = 'read') => {
    const accessRights = useGetRightsByName(accessRightType)

    const permissionsList = useGetPermissions(accessRoles)

    return getPermission(accessRights, permissionsList, categories, type)
}

export default useHasPermissionType
