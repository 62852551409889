import simpleApi from 'app/services/simpleApi'
import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    RemovePdfQuote: {
        mutationFn: ({
            quoteId,
            fileId,
            token,
        }) => {
            return simpleApi({
                url: `quote/${quoteId}/quotedocument/${fileId}/removal`,
                method: 'POST',
                token,
            })
        },
    },
}

export default config
