import React, {
    useCallback,
} from 'react'
import Collapse from '@mui/material/Collapse'
import get from 'lodash/get'

import Card from 'app/shared-components/Card'
import {
    useArchivedTableControl,
} from 'app/shared-components/TableControls/ShowArchived'
import useOpenRowWithPath from 'app/hooks/useOpenRowWithPath'
import AdditionalFeesTable from 'app/Apps/Pricing/ContractBasis/components/AdditionalFeesTable'
import {
    AdditionalFeesStatus, RangeType,
} from 'app/types/enums'
import useRenderTableControlPanel from 'app/shared-components/CollapsibleTable/useRenderTableControlPanel'

import StatusHandler from 'app/shared-components/StatusHandler'
import useCollapsablePanel from 'app/shared-components/CollapsibleTable/hooks/useCollapsablePanel'
import AdditionalFeeCopyPanel from './AdditionalFeesCopyPanel'
import useStyles from './AdditionalFees.style'
import AdditionalFeeDetailPanel from './AdditionalFeeDetailPanel'
import useGetContractBasis from '../hooks/useGetContractBasis'
import AdditionalFeesRequests from './AdditionalFees.requests'

type Props = {
    id: number,
}

const PARAM_UNIQUE = 'additionalFeesNumber'

// TODO-DK reuse CollapsibleTable the same way as for LaneGroups and Lanes

const AdditionalFees = ({
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
        invalidate,
    } = useGetContractBasis(id)

    const currency = data?.currency

    const {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    } = useCollapsablePanel(currency)

    const {
        redirectToUrl,
        onOpenCloseDetail,
        paramValue,
        redirectToCopyForm,
        openCopyForm,
        closeCopyForm,
    } = useOpenRowWithPath(PARAM_UNIQUE, 'additional-fees', null, null, true)

    const onDetailPanelSuccess = useCallback((resp:Record<string, any>): void => {
        invalidate()
        closeCreateForm()
        redirectToUrl(get(resp, `${PARAM_UNIQUE}`))
    }, [
        invalidate,
        redirectToUrl,
        closeCreateForm,
    ])

    const onClose = useCallback(() => {
        invalidate()
        onOpenCloseDetail(null)
    }, [
        invalidate,
        onOpenCloseDetail,
    ])

    const showArchived = useArchivedTableControl(AdditionalFeesRequests.domainName)

    const renderTableControlPanel = useRenderTableControlPanel({
        title: 'Additional Fees',
        stateName: AdditionalFeesRequests.domainName,
        createFormOpened,
        openCreateForm,
        closeCreateForm,
    })

    const getDetailPanel = useCallback(({
        rowProps,
    }) => {
        return (
            <AdditionalFeeDetailPanel
                onSuccess={onDetailPanelSuccess}
                redirectToCopyForm={redirectToCopyForm}
                closeForm={onClose}
                rowProps={{
                    ...rowProps,
                    currency,
                    contractId: id,
                }}
                exists
            />
        )
    }, [
        currency,
        onClose,
        onDetailPanelSuccess,
        id,
        redirectToCopyForm,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <div
                data-testid="additionalFees"
                className={classes.root}
            >
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="additionalFees-card"
                >
                    {!!openCopyForm()
                && (
                    <AdditionalFeeCopyPanel
                        value={{
                            additionalFeeId: openCopyForm(),
                            contractId: id,
                            currency,
                        }}
                        closeCopyForm={closeCopyForm}
                        onDetailPanelCreateSuccess={onDetailPanelSuccess}
                        redirectToCopyForm={redirectToCopyForm}

                    />
                )}
                    <div className={classes.container}>
                        <Collapse in={createFormOpened}>
                            <AdditionalFeeDetailPanel
                                closeForm={closeCreateForm}
                                rowProps={{
                                    currency,
                                    contractId: id,
                                    additionalFeesStatus: AdditionalFeesStatus.IN_PROGRESS,
                                    cancellationFeeTimeline: 5,
                                    cancellationDaysType: RangeType.CALENDAR_DAYS,
                                }}
                                redirectToCopyForm={redirectToCopyForm}
                                onSuccess={onDetailPanelSuccess}
                                onDetailPanelCreateSuccess={onDetailPanelSuccess}
                            />
                        </Collapse>
                        <AdditionalFeesTable
                            contractId={id}
                            getDetailPanel={getDetailPanel}
                            onRowClick={onOpenCloseDetail}
                            renderControlPanel={renderTableControlPanel}
                            openRowId={createFormOpened ? undefined : paramValue}
                            showArchived={showArchived}
                        />
                    </div>
                </Card>
            </div>
        </StatusHandler>
    )
}

export default AdditionalFees
