import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        cardWrapper: {
            marginTop: theme.spacing(2),
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'static',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        headerWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        actionWrapper: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: theme.palette.primary.main,
        },
        opportunity: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(0.5),
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    }
})

export default useStyles
