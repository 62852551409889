import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tooltipWrapper: {
            width: 365,
            height: 100,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        item: {
            flexGrow: 1,
            flexBasis: 0,
        },
        label: {
            color: theme.palette.secondary[600],
            fontSize: 12,
            paddingBottom: theme.spacing(1),
        },
    }
})

export default useStyles
