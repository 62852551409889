import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import useStyles from './AddRemoveIcon.style'

type Props = {
    onOpenForm: ()=> void,
    onCloseForm: ()=> void,
    formOpened: boolean,
}
const AddRemoveIcon = ({
    onOpenForm,
    onCloseForm,
    formOpened,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div
            className={classes.icon}
        >
            {formOpened
                ? (
                    <RemoveIcon
                        onClick={onCloseForm}
                    />
                )
                : (
                    <AddIcon
                        onClick={onOpenForm}
                    />
                )}
        </div>
    )
}

export default AddRemoveIcon
