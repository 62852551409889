import getAnnotation from './getAnnotation'

const getPoints = ({
    dataPoints,
    showStepType,
    showLocation,
    theme,
}: {
    dataPoints: {
        x: number,
        y: number,
        location: string,
        type: string,
    }[],
    showStepType: boolean,
    showLocation: boolean,
    theme: Record<string, any>,
}): {
    x: number,
    y: number,
    marker: {strokeColor: string, size: number},
    label: {
        borderColor: string,
        text: string,
    },
}[] => {
    return dataPoints.map(({
        x,
        y,
        location,
        type,
    }) => {
        const annotation = getAnnotation({
            showStepType,
            showLocation,
            location,
            type,
        })

        if (!annotation) {
            return null
        }

        return {
            x,
            y,
            marker: {
                strokeColor: theme.palette.common.purple,
                size: 3,
            },
            label: {
                borderColor: 'none',
                text: annotation,
            },
        }
    })
}

export default getPoints
