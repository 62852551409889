/* eslint-disable class-methods-use-this */

import React from 'react'
import {
    Cell, CellTemplate, Compatible, getCellProperty, Uncertain,
} from '@silevis/reactgrid'
import {
    SkyNetSpreadSheetCellType,
} from '../../SkyNetSpreadSheet.types'

export interface IdCell extends Cell {
    type: SkyNetSpreadSheetCellType.ID,
    id: number | string | undefined,
    nonEditable?: boolean
}

export default class RowCounterTemplate implements CellTemplate<IdCell> {
    getCompatibleCell(uncertainCell: Uncertain<IdCell>): Compatible<IdCell> {
        let id

        try {
            id = getCellProperty(uncertainCell, 'id', 'number')
        } catch (e) {
            id = undefined
        }

        return {
            ...uncertainCell,
            id,
            nonEditable: true,
            text: '',
            value: id,
        }
    }

    isFocusable(): boolean {
        return false
    }

    render(): React.ReactNode {
        return null
    }
}
