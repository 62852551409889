/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Draggable,
} from 'react-beautiful-dnd'
import clsx from 'clsx'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import useStyles from './StepItemDnd.style'
import StepItem from '../StepItem'
import {
    SimulationStep,
} from '../../simulation.types'

type Props = {
    onChangeStep: (step: SimulationStep) => void,
    value: SimulationStep,
    index: number,
    deleteStep: (index: number) => void,
    canBeDeleted: boolean,
}

const StepItemDnd = ({
    onChangeStep,
    value,
    index,
    deleteStep,
    canBeDeleted,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        id,
    } = value

    return (
        <Draggable
            key={id}
            draggableId={id.toString()}
            index={index}
        >
            {/* eslint-disable-next-line no-shadow */}
            {(provided, snapshot) => {
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={clsx(classes.step, snapshot.isDragging && classes.draggedCard)}
                    >
                        <div className={classes.titleStep}>
                            <div className={classes.wrapper}>
                                {`Step ${index + 1}`}
                                {canBeDeleted && (
                                    <div
                                        className={classes.deleteBtn}
                                        onMouseDown={() => {
                                            deleteStep(index)
                                        }}
                                    >
                                        -
                                    </div>
                                )}
                            </div>
                            <DragIndicatorIcon className={classes.icon} />
                        </div>
                        <StepItem
                            value={value}
                            onChange={onChangeStep}
                        />
                    </div>
                )
            }}
        </Draggable>
    )
}

export default StepItemDnd
