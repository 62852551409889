import moment from 'moment'

const thresholdsMap = {
    new: 60,
    old: 1440,
}

export const READING_THRESHOLD_TYPE = {
    OLD: 'oldReading',
    LIVE: 'liveReading',
    RECENT: 'recentReading',
}

const getReadingThresholdType = (dateOnUtc) => {
    if (!dateOnUtc) {
        return undefined
    }

    const minutesPassed = moment().diff(moment(dateOnUtc, 'X'), 'minutes')

    if (minutesPassed >= thresholdsMap.old) {
        return READING_THRESHOLD_TYPE.OLD
    }
    if (minutesPassed < thresholdsMap.new) {
        return READING_THRESHOLD_TYPE.LIVE
    }

    return READING_THRESHOLD_TYPE.RECENT
}

export default getReadingThresholdType
