import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useCallback,
} from 'react'

import {
    LaneFeesDetail,
} from '../../../Pricing.types'
import requests from '../LaneFees.requests'

export default (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getContractBasisLaneFeeDetails],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<LaneFeesDetail>({
        key: RequestKeys.getContractBasisLaneFeeDetails,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.getLaneFee),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}
