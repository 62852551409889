import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapper: {
            display: 'grid',
            Gap: theme.spacing(2),
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    }
})

export default useStyles
