import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        options: {
            width: 450,
        },
        chip: {
            '&&': {
                margin: `${theme.spacing(0.2)} ${theme.spacing(0.2)} ${theme.spacing(0.2)} 0`,
                backgroundColor: theme.palette.secondary[200],
                color: theme.palette.common.black,
                fontSize: theme.typography.caption.fontSize,
                fontHeight: 10,
            },
        },
        companyIcon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        secondRow: {
            display: 'block',
            fontSize: theme.typography.caption.fontSize,
        },
    }
})

export default useStyles
