/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import clsx from 'clsx'

import Button from 'app/shared-components/Button'

import useStyles from './DropZone.styles'

type Props = {
    getRootProps: () => object,
    getInputProps: () => object,
    isDragActive: boolean,
    isDragReject: boolean,
    open: () => void,
    required: boolean,
    disabled: boolean,
}

function DropZone({
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    open,
    required,
    disabled,
}: Props) {
    const {
        classes,
    } = useStyles()

    return (
        <div
            className={classes.container}
            data-testid="attachment-drop-zone"
        >
            <div
                className={clsx(classes.baseStyle, {
                    [classes.activeStyle]: isDragActive,
                    [classes.rejectStyle]: isDragReject,
                    [classes.required]: required,
                    [classes.disabled]: disabled,
                })}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <span className={clsx(classes.text, {
                    [classes.activeStyleText]: isDragActive,
                    [classes.textRequired]: required,
                    [classes.textDisabled]: disabled,
                })}
                >
                    Drop files here
                </span>
                <span className={clsx(classes.text, {
                    [classes.activeStyleText]: isDragActive,
                    [classes.textRequired]: required,
                    [classes.textDisabled]: disabled,
                })}
                >
                    or
                </span>
                <Button
                    label="Select Files"
                    key="select-files"
                    onClick={open}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default DropZone
