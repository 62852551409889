import React from 'react'
import noop from 'lodash/noop'
import {
    RequestConfig,
} from 'app/types/request.types'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import FormWithControls from 'app/shared-components/FormWithControls'

import useEnumValues from 'app/hooks/useEnumValues'

import AlLogisticPricingRequests from '../AlLogisticPricing.requests'
import alLogisticPricingFields from './alLogisticPricing.fields'
import useStyles from './AlLogisticPricingForm.styles'
import {
    AlLogisticPricing,
} from '../AlLogisticPricing.types'

type Props = {
    data?: AlLogisticPricing,
    onSuccess?: (props: any) => void,
    onCancel?: (...args: any[]) => void,
    modifyDataBeforeSend?: (newValue: any) => any,
    customButtons?: JSX.Element[],
    requestParam: RequestConfig,
}

const defaultProps: Partial<Props> = {
    data: {} as AlLogisticPricing,
    onSuccess: noop,
    onCancel: noop,
    modifyDataBeforeSend: undefined,
    customButtons: undefined,
}

const AlLogisticPricingForm = ({
    data,
    onSuccess,
    onCancel,
    modifyDataBeforeSend,
    customButtons,
    requestParam,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const exists = !!data?.id

    const fields = useFieldsWithClassName(
        alLogisticPricingFields(
            {
                originalStatus: data?.status,
                getAllowedValues,
                exists,
            },
        ),
        classes,
    )

    return (
        <div
            className={classes.root}
        >
            <FormWithControls
                name={AlLogisticPricingRequests.create.name}
                value={data}
                onSuccess={onSuccess}
                fields={fields}
                modifyDataBeforeSend={modifyDataBeforeSend}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                customButtons={customButtons}
                requestParams={requestParam}
                exists={exists}
                onCancel={onCancel}
            />
        </div>
    )
}

AlLogisticPricingForm.defaultProps = defaultProps

export default AlLogisticPricingForm
