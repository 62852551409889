const injectArchived = (archived: boolean) => {
    return (cb) => {
        return (params) => {
            return cb({
                ...params,
                archived,
            })
        }
    }
}

export default injectArchived
