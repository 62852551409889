/* eslint-disable no-underscore-dangle */
import {
    ContainerCode,
} from 'app/types/enums'

const mapContainerTypes: {
    containerCode: ContainerCode,
    imgSrc: string,
}[] = [
    {
        containerCode: ContainerCode._101,
        imgSrc: 'assets/images/containerTypes/tp_1500X.png',
    },
    {
        containerCode: ContainerCode._011,
        imgSrc: 'assets/images/containerTypes/tp_1500C.png',
    },
    {
        containerCode: ContainerCode._012,
        imgSrc: 'assets/images/containerTypes/tp_1500CRT.png',
    },
    {
        containerCode: ContainerCode._031,
        imgSrc: 'assets/images/containerTypes/tp_2500C.png',
    },
    {
        containerCode: ContainerCode._032,
        imgSrc: 'assets/images/containerTypes/tp_2500CRT.png',
    },
    {
        containerCode: ContainerCode._013,
        imgSrc: 'assets/images/containerTypes/tp_1500F.png',
    },
]

export default mapContainerTypes
