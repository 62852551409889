const request = {
    domainName: 'Engagement',
    create: {
        request: () => {
            return {
                url: 'engagement',
                method: 'POST',
            }
        },
    },
    get: ({
        id,
    }) => {
        return {
            url: `engagement/${id}`,
        }
    },
    update: ({
        id,
    }: {
        id: number
    }) => {
        return {
            url: `engagement/${id}`,
            method: 'PUT',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `engagement/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `engagement/${id}/restore`,
            method: 'PATCH',
        }
    },
}

export default request
