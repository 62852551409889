import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

import deliveryViaRoadFields from './deliveryViaRoad.fields'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const deliveryViaRoadInfoConfig: SkyNetTableConfig<DailyOpsFields> = {
    name: 'DeliveryViaRoadInfo',
    tabs: [{
        name: 'Delivery: Info',
        url: 'all',
    }],
    fields: deliveryViaRoadFields,
}

export default deliveryViaRoadInfoConfig
