const toLowerCaseEntityName = (cb) => {
    return (data) => {
        const {
            url,
        } = data

        const regex = /^(.*\/v[1-9]+\/)([a-zA-Z0-9]+)((([/?]).*)?)/
        const urlParams = url.match(regex)

        const lowerCaseUrl = `${urlParams[1]}${urlParams[2].toLowerCase()}${urlParams[3]}`

        return cb({
            ...data,
            url: lowerCaseUrl,
            originUrl: url,
        })
    }
}

export default toLowerCaseEntityName
