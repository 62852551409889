import React from 'react'
import {
    BillingCompany,
} from 'app/Apps/ContactManagement/BillingCompanies/BillingCompanyForm.types'

const getBillingCompanyData = ({
    street,
    city,
    zip,
}) => {
    return [
        street,
        city,
        zip,
    ].filter(Boolean)
}

const getBillingCompanyOptionsLabel = ({
    billingLocationName,
    street,
    city,
    countryCountryName,
    emailContacts,
}: BillingCompany, classes?: Record<string, any>) => {
    return (
        <>
            {`${billingLocationName}, ${street} ${city}, ${countryCountryName}`}
            <div className={classes?.secondRow}>{emailContacts || 'Email contacts not available'}</div>
        </>
    )
}

const getBillingCompanyLabel = ({
    billingLocationName,
    city,
}: BillingCompany) => {
    return {
        label: `${billingLocationName}, ${city}`,
    }
}

const getBillingCompanyExpandedLabel = (billingCompany: BillingCompany) => {
    const {
        billingLocationName,
        emailContacts,
        street,
        city,
        zip,
    } = billingCompany

    return {
        label: [
            billingLocationName,
            street,
            city,
            zip,
            emailContacts || 'Email contacts not available',
        ]
            .filter(Boolean)
            .join('\n'),
    }
}

const getBillingCompanyTooltip = (billingCompany: BillingCompany) => {
    if (!billingCompany) {
        return null
    }

    const {
        id,
        billingLocationName,
    } = billingCompany

    return (
        <>
            {[
                billingLocationName,
                ...getBillingCompanyData(billingCompany),
            ].map((el) => {
                return (<div key={`billingCompany${id}-${el}`}>{el}</div>)
            })}
        </>
    )
}

export {
    getBillingCompanyLabel,
    getBillingCompanyExpandedLabel,
    getBillingCompanyOptionsLabel,
    getBillingCompanyTooltip,
}
