import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        checkbox: {
            padding: '0px !important',
        },
        inputLabel: {
            display: 'none',
        },
        tableCellHead: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    }
})

export default useStyles
