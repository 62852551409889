import React, {
    useState,
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import DateRangeSelector from 'app/shared-components/DateRangeSelector'

import {
    dateToStr,
} from 'app/utils/date'

import FilterPopover from '../FilterPopover'

type FilterType = {
    from: string,
    to: string,
}

type Props = {
    filter?: FilterType,
    setFilter: (filter: FilterType) => void,
    dateMask?: string,
}

const defaultProps = {
    filter: {
        from: null,
        to: null,
    },
    dateMask: undefined,
}

const DateRangeSelectorFilter = ({
    filter,
    setFilter,
    dateMask,
}: Props) => {
    const [
        value,
        setValue,
    ] = useState<FilterType>(() => {
        return {
            to: filter.to ? filter.to : null,
            from: filter.from ? filter.from : null,
        }
    })

    const [
        hasChanges,
        setHasChanges,
    ] = useState<boolean>(false)

    const commitChanges = (): void => {
        if (hasChanges) {
            setFilter({
                from: value.from ? dateToStr(value.from, dateMask) : undefined,
                to: value.to ? dateToStr(value.to, dateMask) : undefined,
            })
        }
    }

    const localOnChange = (newValue): void => {
        setValue({
            ...newValue,
        })
        setHasChanges(true)
    }

    const active = useMemo((): boolean => {
        return !isEmpty(filter.from) || !isEmpty(filter.to)
    }, [filter])

    return (
        <FilterPopover
            active={active}
            onClose={commitChanges}
        >
            <DateRangeSelector
                mini
                showTimeRange
                setDateRange={noop}
                onChange={localOnChange}
                value={value}
            />
        </FilterPopover>
    )
}

DateRangeSelectorFilter.defaultProps = defaultProps

export default DateRangeSelectorFilter
