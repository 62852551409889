/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'
import clsx from 'clsx'
import noop from 'lodash/noop'

import ClearFilters from './ClearFilters'
import ClearSorting from './ClearSorting'
import VisibleColumns from './VisibleColumns'
import SearchControl from './SearchControl'
import ShowArchived from './ShowArchived'
import BulkCreateIcon from './BulkCreateIcon'
import BulkEditIcon from './BulkEditIcon'

import {
    TABLE_CONTROL_TYPES,
    TableControlTypes,
    VISIBLE_COLUMNS,
} from './TableControls.types'

import useStyles from './TableControls.style'

const defaultProps = {
    selectedRows: undefined,
    simple: false,
    storeName: undefined,
    applySearch: noop,
}

const specialControls = {
    [TABLE_CONTROL_TYPES.clearFilters]: ClearFilters,
    [TABLE_CONTROL_TYPES.clearSorting]: ClearSorting,
    [VISIBLE_COLUMNS]: VisibleColumns,
    [TABLE_CONTROL_TYPES.search]: SearchControl,
    [TABLE_CONTROL_TYPES.showArhived]: ShowArchived,
    [TABLE_CONTROL_TYPES.bulkCreate]: BulkCreateIcon,
    [TABLE_CONTROL_TYPES.bulkEdit]: BulkEditIcon,
}

const defaultControls = [VISIBLE_COLUMNS]

const TableControls = ({
    controls,
    rerenderInfinitList,
    matchedResults,
    allColumns,
    setVisible,
    visible,
    removeFilter,
    removeSort,
    name,
    selectedRows,
    applySearch,
    simple,
    storeName,
}: {
    controls: TableControlTypes[],
    rerenderInfinitList: (...args: any[]) => void,
    matchedResults: number,
    allColumns: Record<string, any>[],
    setVisible: (...args: any[]) => void,
    visible: string[],
    removeFilter: (...args: any[]) => void,
    removeSort: (...args: any[]) => void,
    name: string,
    selectedRows?: string[],
    applySearch?: (...args: any[]) => void
    simple?: boolean,
    storeName?: string,
}) => {
    const {
        classes,
    } = useStyles()

    const controlProps = {
        columns: allColumns,
        rerenderTable: rerenderInfinitList,
        matchedresults: matchedResults,
        visible,
        setVisible,
        removeFilter,
        removeSort,
        name,
        selectedRows,
        applySearch,
        storeName,
    }

    const tableControls = useMemo(() => {
        return [
            ...controls,
            ...defaultControls,
        ]
    }, [controls])

    return (
        <div className={classes.controlsWrapper}>
            <div className={clsx({
                [classes.withBorder]: !simple,
                [classes.controls]: true,
            })}
            >
                {tableControls.map((control) => {
                    const Component = specialControls[control]
                    const key = `control-${control}`

                    return Component ? (
                        <Component
                            key={key}
                            id={key}
                            {...controlProps}
                        />
                    ) : null
                })}
            </div>
        </div>
    )
}

TableControls.defaultProps = defaultProps

export default TableControls
