/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'
import omit from 'lodash/omit'
import get from 'lodash/get'

import useEnumValues from 'app/hooks/useEnumValues'

import {
    LoggerType,
} from 'app/types/enums'
import Select from 'app/shared-components/Select'
import TextSingleline from 'app/shared-components/TextSingleline'

import useGetLoggerTypeDetails from './hook/useGetLoggerTypeDetails'

type Props = {
    value?: string | number,
    disabled?: boolean,
    allowedValues?: Record<string, string>,
}

const defaultProps: Partial<Props> = {
    value: '',
    disabled: false,
    allowedValues: {},
}

const getLabel = (value, data, allowedValues) => {
    if (data.length > 0) {
        return get(data.find((item) => {
            return item[0] === value
        }), '[1]')
    }

    return get(allowedValues, `${value}`, '')
}

const LoggerTypeSelector = (props: Props) => {
    const {
        value,
        disabled,
        allowedValues,
    } = props
    const loggerLabels = useEnumValues()(LoggerType)
    const data = useGetLoggerTypeDetails()

    const loggerTypes = useMemo(() => {
        return data
            ? data.map(((loggerDetails: {loggerType: string}) => {
                const {
                    loggerType,
                } = loggerDetails

                return [
                    loggerType,
                    loggerLabels[loggerType],
                ]
            }))
            : []
    }, [
        data,
        loggerLabels,
    ])

    if (disabled) {
        return (
            <TextSingleline
                {...props}
                value={getLabel(value, loggerTypes, allowedValues)}
            />
        )
    }

    return (
        <Select
            {...omit(props, 'className')}
            data={loggerTypes}
            disabled={disabled}
            value={value}
        />
    )
}

LoggerTypeSelector.defaultProps = defaultProps

export default LoggerTypeSelector
