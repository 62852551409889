import {
    useEffect,
} from 'react'

export default function useOutsideClick(ref, handler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (handler) handler()
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside, {
            capture: true,
        })
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [
        ref,
        handler,
    ])
}
