import {
    useState,
    useMemo,
    useEffect,
} from 'react'
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom'

import last from 'lodash/last'
import isEqual from 'lodash/isEqual'

import useGetLoggersByLoggerNumbers from './useGetLoggersByLoggerNumbers'
import {
    LoggerResponse,
} from '../Loggers.types'

type LoggersRouteMatch = {
    params: {
        otherLoggersNumbers?: string,
        id: string,
    },
    url: string,
}

const mapByLoggerNumber = ({
    loggerNumber,
}) => { return loggerNumber }

const updateRouteParams = (
    selectedLoggerNumbers: string[],
    routeMatch: LoggersRouteMatch,
    history,
) => {
    const {
        params: {
            otherLoggersNumbers,
        }, url,
    } = routeMatch

    const newLoggerParams = selectedLoggerNumbers ? `/${selectedLoggerNumbers.join(',')}` : ''

    if (otherLoggersNumbers) {
        return history.replace(url.replace(`/${otherLoggersNumbers}`, newLoggerParams))
    }

    return history.replace(`${url}${newLoggerParams}`)
}

export default () => {
    const history = useHistory()
    const [
        parsedUrl,
        setParsedUrl,
    ] = useState(false)

    const [
        loggersToRequest,
        setLoggersToRequest,
    ] = useState([])

    const routeMatch = useRouteMatch('/apps/loggers/all/edit/:id/overview/:otherLoggersNumbers?')

    const otherLoggersNumbers = routeMatch?.params?.otherLoggersNumbers

    useEffect(() => {
        const urlLoggerNumbers = otherLoggersNumbers?.split(',') || []

        if (!parsedUrl && urlLoggerNumbers.length) {
            setLoggersToRequest(urlLoggerNumbers)
        }
        setParsedUrl(true)
    }, [
        otherLoggersNumbers,
        parsedUrl,
    ])

    const [
        otherLoggers,
        setOtherLoggers,
    ] = useState<LoggerResponse[]>([])

    const onOtherLoggersChange = (loggers) => {
        const selectedLoggerNumbers = loggers?.map(mapByLoggerNumber)

        updateRouteParams(selectedLoggerNumbers, routeMatch, history)

        if (selectedLoggerNumbers?.length > otherLoggers.length) {
            const loggerNumber = last(selectedLoggerNumbers)

            setLoggersToRequest([loggerNumber])
        } else {
            setLoggersToRequest([])
            setOtherLoggers(
                [...otherLoggers.filter((
                    {
                        loggerNumber,
                    },
                ) => {
                    return selectedLoggerNumbers?.includes(loggerNumber)
                })],
            )
        }
    }

    const {
        response, isDataReady,
    } = useGetLoggersByLoggerNumbers(loggersToRequest)

    const otherLoggersResponse = useMemo(() => {
        if (isDataReady) {
            return response.reduce((acc, {
                data,
            }) => {
                return {
                    otherLoggersData: [
                        ...acc.otherLoggersData,
                        {
                            ...data,
                            value: data.loggerNumber,
                            label: data.loggerNumber,
                        },
                    ],
                    responsedLoggerNumbers: [
                        ...acc.responsedLoggerNumbers,
                        data.loggerNumber,
                    ],
                }
            }, {
                otherLoggersData: [],
                responsedLoggerNumbers: [],
            })
        }
        return null
    }, [
        response,
        isDataReady,
    ])

    useEffect(() => {
        if (otherLoggersResponse) {
            const {
                otherLoggersData,
                responsedLoggerNumbers,
            } = otherLoggersResponse as {
                otherLoggersData: LoggerResponse[],
                responsedLoggerNumbers: string[]
            }

            if (loggersToRequest.length && isEqual(loggersToRequest, responsedLoggerNumbers)) {
                setOtherLoggers([
                    ...otherLoggers,
                    ...otherLoggersData,
                ])
                setLoggersToRequest([])
            }
        }
    }, [
        otherLoggers,
        loggersToRequest,
        otherLoggersResponse,
    ])

    return {
        onOtherLoggersChange,
        otherLoggers,
    }
}
