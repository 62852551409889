import React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import {
    useTheme,
} from '@mui/material/styles'

import Tooltip from 'app/shared-components/Tooltip'

import FilterIcon from '../../FilterIcon'
import useStyles from './ClearFilters.style'

const propTypes = {
    removeFilter: PropTypes.func.isRequired,
}

const ClearFilters = ({
    removeFilter,
}) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <Tooltip title="Clear Filters">
            <Link
                className={classes.link}
                component="button"
                color="textSecondary"
                variant="body1"
                onClick={removeFilter}
            >
                <FilterIcon
                    className={classes.icon}
                />
            </Link>
        </Tooltip>
    )
}

ClearFilters.propTypes = propTypes

export default ClearFilters
