import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            marginLeft: theme.spacing(1.5),
            flexGrow: 1,
            flexShrink: 1,
        },
        chart: {
            height: 775,
        },
    }
})

export default useStyles
