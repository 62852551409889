import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        paper: {
            width: theme.spacing(66),
        },
        title: {
            '&&': {
                background: theme.palette.primary[200],
                color: theme.palette.common.white,
                padding: theme.spacing(1, 1.5),
                fontSize: theme.typography.h5.fontSize,
            },
        },
        dialogContent: {
            padding: `${theme.spacing(2)} !important`,
        },
        dialogActions: {
            '&&': {
                justifyContent: 'center',
                padding: theme.spacing(0, 0, 4),
            },
        },
    }
})

export default useStyles
