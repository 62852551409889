import Checkbox from 'app/shared-components/Checkbox'
import MultipleProductTypeSelector from 'app/shared-components/MultipleProductTypeSelector'
import {
    Column,
} from 'app/types/simpletable.types'

import getUniqueId from 'app/utils/getUniqueId'
import InputSingleline from 'app/shared-components/InputSingleline'
import {
    OpportunityTableType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    ProductType,
} from 'app/types/enums'

export type QuoteOpportunityTable = OpportunityTableType & {
    selected: boolean,
    productTypes: ProductType[],
}

const getColumns = ({
    classes,
    disabled,
    l,
    handleChange,
}: {
    classes: Record<string, any>,
    disabled: boolean,
    l: (l:string) => string,
    handleChange: (...a: any[]) => void,
}):
    Column<keyof QuoteOpportunityTable>[] => {
    return [
        {
            label: '',
            component: Checkbox,
            fieldName: 'selected',
            getProps: ({
                id,
                existed,
            }) => {
                return {
                    className: classes.checkbox,
                    disabled: disabled || existed,
                    onChange: (selected) => {
                        handleChange({
                            id,
                            selected,
                        })
                    },
                }
            },
            width: 50,
            id: getUniqueId(),
        },
        {
            label: l('Opportunities.opportunityNumber'),
            component: InputSingleline,
            fieldName: 'opportunityNumber',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.opportunityFrom'),
            component: InputSingleline,
            fieldName: 'reprOriginAirport',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.opportunityTo'),
            component: InputSingleline,
            fieldName: 'reprDestinationAirport',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.opportunityTempRange'),
            component: InputSingleline,
            fieldName: 'temperatureRange',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.productTypes'),
            component: MultipleProductTypeSelector,
            fieldName: 'productTypes',
            required: true,
            getProps: ({
                id,
                temperatureRange,
                existed,
            }) => {
                return {
                    className: classes.input,
                    temperatureRange,
                    disabled: disabled || existed,
                    required: true,
                    title: undefined,
                    onChange: (productTypes) => {
                        handleChange({
                            id,
                            productTypes,
                        })
                    },
                }
            },
            width: 350,
            id: getUniqueId(),
        },
    ]
}

export default getColumns
