/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import inputComponent from './InputComponent'
import useStyles from './ChipSelect.style'

const propTypes = {
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.elementType,
        }),
    ]),
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    innerProps: PropTypes.objectOf(PropTypes.func).isRequired,
    selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
}

const defaultProps = {
    innerRef: {},
}

function Control(props) {
    const {
        innerRef,
        children,
        innerProps,
        selectProps,
    } = props
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.chipSelectInput,
                    inputRef: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...selectProps.textFieldProps}
        />
    )
}

Control.propTypes = propTypes
Control.defaultProps = defaultProps

export default Control
