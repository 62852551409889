/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import TemperatureInfo from './TemperatureInfo'

const TemperatureInfoContainer = (props) => {
    return (
        <TemperatureInfo {...props} />
    )
}

export default TemperatureInfoContainer
