import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import Containers from './Containers'
import ContainerSelection from './ContainerSelection'
import ContainerTypes from './ContainerTypes'
import ServiceCenters from './ServiceCenters'

const ContainerManagement = () => {
    const {
        path,
    } = useRouteMatch<{path: string}>()

    return (
        <Switch>
            <Route path={`${path}/containers`}>
                <Containers />
            </Route>
            <Route path={`${path}/containers-selection`}>
                <ContainerSelection />
            </Route>
            <Route path={`${path}/container-type`}>
                <ContainerTypes />
            </Route>
            <Route path={`${path}/service-centers`}>
                <ServiceCenters />
            </Route>
            <Redirect
                exact
                path={path}
                to={`${path}/containers`}
            />
        </Switch>
    )
}

export default ContainerManagement
