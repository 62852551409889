import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tableCell: {
            padding: '5px !important',
        },
        selectIcon: {
            top: '-2px !important',
        },
        inputCentered: {
            textAlign: 'center',
        },
        cellSmall: {
            maxWidth: 80,
        },
        deleteIcon: {
            cursor: 'pointer',
            color: theme.palette.secondary[600],
        },
        input: {
            '&&': {
                backgroundColor: theme.palette.secondary[50],
                paddingLeft: theme.spacing(0.25),
            },
        },
        isRequired: {
            '&&': {
                backgroundColor: theme.palette.primary[30],
                paddingLeft: theme.spacing(0.25),
            },
        },
    }
})

export default useStyles
