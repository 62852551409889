const accessRoles = [
    'location_sales',
    'location_airlines',
    'location_cs',
    'location_cs_super',
    'location_bil',
    'location_bil_super',
    'location_ops',
    'location_ops_super',
    'location_ts',
    'location_ts_super',
    'location_prd',
    'location_rnd',
    'location_read',
]

export default accessRoles
