import React from 'react'
import {
    createRoot,
} from 'react-dom/client'
import 'typeface-muli'

import App from 'app/App'

import * as serviceWorker from './serviceWorker'

import './app/i18n'

import './styles/index.css'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <App />,
)

serviceWorker.unregister()
