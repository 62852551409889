import React, {
    useCallback,
} from 'react'

import {
    AlertNotificationListItem,
} from '../alertNotifications.types'
import useStyles from './AlertCard.styles'
import useAlertChangeStatus from '../hooks/useAlertChangeStatus'

const AlertCard = ({
    id,
    createdOn,
    message,
    triggeredByUserUserName,
    isRead,
    onChangeStatus,
}: Readonly<Partial<AlertNotificationListItem> & {
    onChangeStatus: (id: number) => void
}>) => {
    const {
        classes, cx,
    } = useStyles()

    const onSuccessStatusChange = useCallback(() => {
        return onChangeStatus(id)
    }, [
        id,
        onChangeStatus,
    ])

    const iconPath = `/assets/images/icons/notification_bell_grey${isRead ? '' : '_unread'}.svg`
    const markAsRead = useAlertChangeStatus({
        id,
        isRead,
        onSuccess: onSuccessStatusChange,
    })

    return (
        <div
            className={classes.alertContainer}
        >
            <div className={classes.alertHeader}>
                {id ? (
                    <>
                        <span className={cx(classes.lightGrey, classes.spaceAfter)}>
                            {createdOn}
                        </span>
                        <span className={cx(classes.lightGrey, classes.spaceAfter)}>&#183;</span>
                        <span className={classes.lightGrey}>
                            {'From: '}
                            &nbsp;
                        </span>
                        <span>{triggeredByUserUserName}</span>
                    </>
                ) : null}
            </div>
            <div className={classes.alertBody}>
                <span className={classes.alertMessage}>{message}</span>
                <span
                    data-testid="AlertIcon"
                    className={classes.alertIcon}
                    onClick={markAsRead}
                    onKeyDown={markAsRead}
                >
                    {id ? (
                        <img
                            data-testid="IconSvg"
                            src={iconPath}
                            alt="alert"
                        />
                    ) : null}
                </span>
            </div>
        </div>
    )
}

export default AlertCard
