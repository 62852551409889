import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, _, classes) => {
    return {
        dateField: {
            position: 'relative',
        },
        input: {
            '& input': {
                cursor: 'pointer',
            },
            '& .MuiInputBase-inputAdornedEnd': {
                textTransform: 'lowercase',
            },
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: -8,
                width: 6,
                height: 26,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 3,
            },
        },
        isRequired: {
            '& .MuiInputBase-input': {
                backgroundColor: theme.palette.primary[30],
            },
        },
        disabled: {
            [`& .${classes.requiredMark}`]: {
                backgroundColor: 'transparent !important',
            },
            '& .MuiInputBase-input': {
                backgroundColor: 'transparent !important',
            },
            [`& .${classes['MuiInput-underline:before']}`]: {
                border: '0 !important',
            },
        },
        errorMessage: {
            height: 10,
        },
    }
})

export default useStyles
