import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        tableConfig: {
            width: 'auto',
            minWidth: 800,
            display: 'flex',
        },
        tableConfigSimple: {
            width: 'auto',
            display: 'flex',
        },
        tabs: {
            maxWidth: '100%',
            overflow: 'auto',
            overflowY: 'hidden',
        },
    }
})

export default useStyles
