import React, {
    useCallback,
} from 'react'

import Typeahead from 'app/shared-components/Typeahead'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import useLocationSearch from '../hooks/useLocationSearch'
import useLocationRequests from '../hooks/useLocationRequests'

import {
    getExpandedInfoByLocation,
} from '../utils'

import {
    LocationLabelIconType,
    LocationSelectorDefaultProps, LocationSelectorProps,
} from '../types/LocationSelector.types'

import useStyles from '../LocationSelector.style'
import useValidateItem from '../../../hooks/useValidateItem'

const LocationExpandedViewSelector = ({
    value,
    filterLocationType,
    filterAddressCategory,
    filterAddressMainCategory,
    filterByCompanyId,
    isShippingLocation,
    isApprovedSubContractor,
    onChange,
    name,
    title,
    disabled,
    isRequired,
    className,
    classNames,
    'data-testid': dataTestId,
    allowedLocationTypes,
    includeFilters,
    validate,
    setErrors,
}: LocationSelectorProps) => {
    const {
        classes,
    } = useStyles()

    const getLabelConfig = useCallback<(location: Location) => LocationLabelIconType>(
        getExpandedInfoByLocation, [])

    const {
        location,
        labelData,
    } = useLocationRequests(value, getLabelConfig)

    const loadOptions = useLocationSearch({
        allowedLocationTypes,
        filterLocationType,
        filterByCompanyId,
        filterAddressCategory,
        filterAddressMainCategory,
        isApprovedSubContractor,
        isShippingLocation,
        classes,
        includeFilters,
    })

    const errors = useValidateItem({
        validate, value: location, setFormError: setErrors, key: name,
    })

    return (
        <div
            className={className}
            data-testid={dataTestId}
        >
            <Typeahead
                name={name}
                value={value}
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={labelData}
                labelField="label"
                className={className}
                classNames={{
                    options: classes.options,
                    ...classNames,
                }}
                disabled={disabled}
                isRequired={isRequired}
                errors={errors}
                validated={Boolean(validate)}
                multiline
            />
        </div>
    )
}

LocationExpandedViewSelector.defaultProps = LocationSelectorDefaultProps

export default LocationExpandedViewSelector
