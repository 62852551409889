import flow from 'lodash/flow'
import Axios from 'axios'

import bearerToken from 'app/utils/api/bearerToken'
import addHostUrl from 'app/utils/api/addHostUrl'
import DOMAIN_SUBDIRECTORY from 'app/utils/api/constants'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const simpleFullResponseApi = (params) => {
    const modifiedQuery = flow([
        bearerToken,
        addHostUrl(`${apiUrl}/${DOMAIN_SUBDIRECTORY}`),
    ])(Axios)

    return modifiedQuery(params)
}

export default simpleFullResponseApi
