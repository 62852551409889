import React from 'react'

import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useHasPermission from 'app/hooks/useHasPermission/useHasPermission'

import Overview from './Overview'
import tableConfig from './regionalGroups.config'
import request from './regionalGroups.request'
import UpdateData from './UpdateData'
import Opportunities from './Opportunities'
import RegionalGroupCreate from './RegionalGroupCreate'

const overview: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: Overview,
}

const updateTab: SidebarTab = {
    label: 'Update Data',
    url: 'update',
    Component: UpdateData,
}

const opportunitiesTab: SidebarTab = {
    label: 'Opportunities',
    url: 'opportunities',
    Component: Opportunities,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const RegionalGroups = () => {
    const updatePermission = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])
    const canCreateRegionGroup = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overview,
            permission: true,
        },
        {
            tab: updateTab,
            permission: updatePermission,
        },
        {
            tab: opportunitiesTab,
            permission: true,
        },
    ])

    return (
        <SkyNetTable
            name={tableConfig.name}
            moduleName={request.domainName}
            createForm={canCreateRegionGroup ? RegionalGroupCreate : undefined}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default RegionalGroups
