import {
    useEffect, useState,
} from 'react'

const useSidebarContainer = () => {
    const [
        container,
        setContainer,
    ] = useState()

    useEffect(() => {
        const sidebarContainer = document.getElementById('sidebarContainer')

        sidebarContainer.style.width = '100%'
        setContainer(sidebarContainer)

        return () => {
            sidebarContainer.style.width = '0'
        }
    }, [])

    return container
}

export default useSidebarContainer
