import React from 'react'

import {
    ChangeValidityConfirmationDialog,
} from 'app/shared-components/ChangeValidity'
import StatusHandler from 'app/shared-components/StatusHandler'
import useCountOrdersAdditionalFee from '../hooks/useCountOrdersAdditionalFee'

type Props = {
    id: number,
    changeValidity: {
        openConfirmDialog: boolean,
        handleChangeValidityConfirm: (...args: any[]) => void,
        changeValidityState: {
            reason: string,
            validFrom: string,
            validTo: string,
        },
        setChangeValidityState: (...args: any[]) => void
    }
}

const AdditionalFeeChangeValidity = ({
    id,
    changeValidity,
}: Props) => {
    const {
        data,
        isError,
        error,
    } = useCountOrdersAdditionalFee(id, {
        validFrom: changeValidity?.changeValidityState.validFrom,
        validTo: changeValidity?.changeValidityState.validTo,
        openConfirmDialog: changeValidity?.openConfirmDialog,
    })

    return (
        <StatusHandler
            isSuccess
            isFetching={false}
            isError={isError}
            error={error}
        >
            <ChangeValidityConfirmationDialog
                title="Additional Fee"
                openConfirmDialog={changeValidity.openConfirmDialog}
                handleClose={changeValidity.handleChangeValidityConfirm}
                changeValidityState={changeValidity.changeValidityState}
                setChangeValidityState={changeValidity.setChangeValidityState}
                orderCount={data?.orderCount}
            />
        </StatusHandler>

    )
}

export default AdditionalFeeChangeValidity
