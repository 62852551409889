import React from 'react'

import useStyles from './DataContentWrapper.styles'

const defaultProps = {
    emptyDataReplacer: 'No data available',
}

const DataContentWrapper = ({
    children,
    emptyDataReplacer,
    isEmpty,
}: {
    children: JSX.Element,
    emptyDataReplacer?: string | JSX.Element,
    isEmpty: boolean,
}) => {
    const {
        classes,
    } = useStyles()

    if (isEmpty) {
        return <div className={classes.content}>{emptyDataReplacer}</div>
    }
    return children
}

DataContentWrapper.defaultProps = defaultProps

export default DataContentWrapper
