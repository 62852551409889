import {
    NestedField,
} from 'app/types/form.types'
import {
    LocationFormFields,
} from '../LocationForm.types'

export const getShippingFieldDetails = (): NestedField<LocationFormFields>[] => {
    return [
        {
            name: 'latitude',
            required: true,
            componentName: 'InputSingleline',
            labelKey: 'Location.latitude',
        },
        {
            name: 'longitude',
            required: true,
            componentName: 'InputSingleline',
            labelKey: 'Location.longitude',
        },
        {
            name: 'requiresTruckDetails',
            componentName: 'Switch',
            labelKey: 'Location.requiresTruckDetails',
        },
    ]
}

export default getShippingFieldDetails
