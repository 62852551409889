import {
    LaneStatus,
} from '../../AccountLanes/AccountLanes.types'
import useGetAccountLanesByOpportunities from './useGetAccountLanesByOpportunities'
import useGetLanesByQuote from './useGetLanesByQuote'
import {
    AccountQuote,
} from '../AccountQuotes.types'

const useGetLanesQuoteStepper = (accountQuote: AccountQuote) => {
    const {
        data: lanesByOpportunities,
        isDataReady: isDataReadyLanesByOpportunities,
    } = useGetAccountLanesByOpportunities({
        opportunities: accountQuote.requestedOpportunities.map(({
            opportunity,
        }) => {
            return opportunity?.opportunityNumber
        }),
        leaseType: accountQuote.leaseType,
        laneStatus: LaneStatus.TO_BE_QUOTED,
        quoteId: accountQuote?.id,
    })

    const {
        data: lanesByQuote,
        isDataReady: isDataReadyLanesByQuote,
    } = useGetLanesByQuote({
        opportunityIds: accountQuote.requestedOpportunities.map(({
            opportunity,
        }) => {
            return opportunity.id
        }),
        leaseType: accountQuote.leaseType,
        quoteId: accountQuote?.id,
    })

    return {
        data: accountQuote?.id ? lanesByQuote : lanesByOpportunities,
        isDataReady: accountQuote?.id ? isDataReadyLanesByQuote : isDataReadyLanesByOpportunities,
    }
}

export default useGetLanesQuoteStepper
