import React, {
    useMemo,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useRequestTable from 'app/hooks/useRequestTable'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import Card from 'app/shared-components/Card'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    TableResponse,
} from 'app/types/request.types'
import useLabel from 'app/hooks/useLabel'
import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'
import useRenderTableControlPanel from 'app/shared-components/CollapsibleTable/useRenderTableControlPanel'
import {
    App,
} from 'app/types/common.enums'

import request from '../../AccountManagement.request'
import columns from './contacts.columns'
import useStyles from './Contacts.style'
import useGetAccount from '../../hooks/useGetAccount'

const Contacts = ({
    id,
}: {id: number}) => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()
    const l = useLabel()

    const {
        data: account,
    } = useGetAccount(id)

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<TableResponse<Contact>>({
        key: RequestKeys.getRegionalGroupsByAccount,
        params: {
            accountId: id,
        },
        config: request.getContactsByAccount,
        enabled: Boolean(id),
    })

    const injectLoadFilters = useLoadFilters(
        request.filterCountRequestContacts({
            accountId: id,
        }),
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns({
            l,
        }))
    }, [
        injectLoadFilters,
        l,
    ])

    const onOpenForm = () => {
        history.push(`/apps/${App.CONTACT_MANAGEMENT}/contacts/all/create`, {
            company: account.company,
        })
    }

    const renderTableControlPanel = useRenderTableControlPanel({
        title: 'Contacts',
        stateName: 'Contacts',
        createEnabled: false,
        children: <AddIcon
            onClick={onOpenForm}
            className={classes.addIcon}
        />,
    })

    return (
        <Card>
            <RichTable
                configName="ContactsTable"
                onParamsChange={onParamsChange}
                name="Contact"
                load={loadData}
                columns={columnsWithFilters}
                uniqField="id"
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
                renderControlPanel={renderTableControlPanel}
            />
        </Card>
    )
}

export default Contacts
