import React, {
    useState,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'

import {
    BookingDetailsStep, ClosePreBookingStep,
    DirectLeaseFormType,
    OrderDetailsStep,
} from './createDirectLease.types'
import OrderDetails from './Steps/OrderDetails'
import BookingDetails from './Steps/BookingDetails'
import ClosePrebookingStep from './Steps/ClosePrebookingStep'

const CreateDirectLease = () => {
    const [
        value,
        setValue,
    ] = useState<DirectLeaseFormType>({
        firstStep: {},
        secondStep: {},
        thirdStep: {},
    } as DirectLeaseFormType)

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    useBeforeUnloadDialog(isEdited)

    const onChangeFirstStep = (newVal: OrderDetailsStep) => {
        setIsEdited(true)
        setValue({
            ...value,
            firstStep: newVal,
        })
    }
    const onChangeSecondStep = (newVal: BookingDetailsStep) => {
        setValue({
            ...value,
            secondStep: newVal,
        })
    }
    const onEditFirstStep = () => {
        setValue({
            firstStep: value.firstStep,
            secondStep: {} as BookingDetailsStep,
            thirdStep: {} as ClosePreBookingStep,
        })
    }
    const onEditSecondStep = () => {
        setValue({
            firstStep: value.firstStep,
            secondStep: value.secondStep,
            thirdStep: {} as ClosePreBookingStep,
        })
    }

    const steps = [
        {
            key: '0',
            label: 'Order Details',
            expanded: true,
            renderContent: ({
                index,
            }) => {
                return (
                    <OrderDetails
                        value={value?.firstStep}
                        onChange={onChangeFirstStep}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        onEdit={onEditFirstStep}
                        index={index}
                    />
                )
            },
        },
        {
            key: '1',
            label: 'Booking Details',
            expanded: (currentStep) => {
                return currentStep > 1
            },
            renderContent: ({
                index,
            }) => {
                return (
                    <BookingDetails
                        value={value?.secondStep}
                        onChange={onChangeSecondStep}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        onEdit={onEditSecondStep}
                        onReset={onEditFirstStep}
                        index={index}
                        selectedLaneId={value.firstStep?.selectedLaneId?.[0]}
                    />
                )
            },
        },
        {
            key: '2',
            label: 'Close Pre Booking',
            expanded: false,
            renderContent: ({
                index,
            }) => {
                return (
                    <ClosePrebookingStep
                        value={
                            {
                                ...value.secondStep,
                                lane: {
                                    id: value.firstStep?.selectedLaneId?.[0],
                                },
                            }
                        }
                        setActiveStep={setActiveStep}
                        setIsEdited={setIsEdited}
                        index={index}
                    />
                )
            },
        },
    ]

    return (
        <>
            <SkyNetStepper
                activeStep={activeStep}
                steps={steps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </>
    )
}

export default CreateDirectLease
