import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        messageDate: {
            textAlign: 'center',
        },
        new: {
            color: `${theme.palette.common.green} !important`,
        },
        medium: {
            color: `${theme.palette.common.yellow} !important`,
        },
        old: {
            color: `${theme.palette.common.red} !important`,
        },
        label: {
            padding: 0,
        },
        sensorInfo: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        sensorInfoItem: {
            display: 'flex',
            padding: `${theme.spacing(2.5)} ${theme.spacing(1.25)} 0 ${theme.spacing(2.5)}`,
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
        sesnsorInfoTitle: {
            color: theme.palette.secondary[500],
            fontWeight: '800',
        },
    }
})

export default useStyles
