import omit from 'lodash/omit'

import {
    GeneralFreight,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getFilteredGeneralFreight = (
    generalFreight: GeneralFreight[],
): GeneralFreight[] | undefined => {
    const filteredGeneralFreight = generalFreight?.map((item) => {
        return omit(item, ['id'])
    })
        .filter((item) => {
            return Object.values(omit(item, ['description'])).every((el) => { return Boolean(el) })
        })

    return filteredGeneralFreight?.length ? filteredGeneralFreight : undefined
}

export default getFilteredGeneralFreight
