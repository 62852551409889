import React, {
    useMemo,
} from 'react'

import {
    TypeaheadObjectContainer,
} from 'app/shared-components/Typeahead'
import {
    BillingCompany,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useGetBillingCompany from 'app/Apps/ContactManagement/BillingCompanies/hooks/useGetBillingCompany'

import useBillingCompanySearch from '../hooks/useBillingCompanySearch'
import {
    getBillingCompanyExpandedLabel,
} from '../utils'
import {
    BillingCompanySelectorDefaultProps,
    BillingCompanySelectorProps,
} from '../types/BillingCompanySelector.types'
import useStyles from '../BillingCompanySelector.styles'

const BillingCompanyExpandedViewSelector = ({
    name,
    title,
    value,
    onChange,
    className,
    classNames,
    isRequired,
    'data-testid': dataTestId,
    disabled,
}: BillingCompanySelectorProps & {value: BillingCompany}) => {
    const {
        classes,
    } = useStyles()

    const {
        data,
    } = useGetBillingCompany(value?.id)

    const labelData = useMemo(() => {
        if (data) {
            return getBillingCompanyExpandedLabel(data)
        }

        return null
    }, [data])

    const loadOptions = useBillingCompanySearch(classes)

    return (
        <div
            className={className}
            data-testid={dataTestId}
        >
            <TypeaheadObjectContainer
                name={name}
                value={value?.id}
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={labelData}
                labelField="label"
                classNames={{
                    options: classes.options,
                    ...classNames,
                }}
                className={className}
                disabled={disabled}
                isRequired={isRequired}
                multiline
            />
        </div>
    )
}

BillingCompanyExpandedViewSelector.defaultProps = BillingCompanySelectorDefaultProps

export default BillingCompanyExpandedViewSelector
