import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const missingInformationConfig: SkyNetTableConfig<DailyOpsFields> = {
    name: 'MissingInformation',
    tabs: [{
        name: 'Open Orders',
        url: 'all',
    }],
    fields: [
        {
            name: 'orderNumber',
            filterField: 'orderNumber',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'orderSubType',
            filterField: 'orderSubType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: OrderSubType,
        },
        {
            name: 'originLocationLocationName',
            filterField: 'originLocationLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'destinationLocationLocationName',
            filterField: 'destinationLocationLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'estimatedDelivery',
            filterField: 'estimatedDelivery',
            filterType: FilterType.DateRange,
        },
    ],
}

export default missingInformationConfig
