/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import ChangeData from '../ChangeData'

const Overview = (props) => {
    return (
        <ChangeData
            {...props}
            disabled
        />
    )
}

export default Overview
