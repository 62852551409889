import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, _, classes) => {
    return {
        margin: {
            margin: 8,
        },
        pointer: {
            cursor: 'pointer',
        },
        calendar: {
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            width: '100%',
            whiteSpace: 'nowrap',
            position: 'relative',
            minWidth: 120,
        },
        calendarInput: {
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary[600],
            fontWeight: theme.typography.body1.fontWeight,
            overflow: 'hidden',
        },
        icon: {
            position: 'absolute',
            top: 20,
            right: 5,
            width: 20,
            height: 20,
            color: theme.palette.secondary[400],
            [`& .${classes['MuiSvgIcon-root']}`]: {
                width: 20,
                height: 20,
            },
        },
        iconActive: {
            color: theme.palette.primary.main,
        },
        container: {
            display: 'flex',
        },
        button: {
            '&&': {
                height: 26,
                letterSpacing: 'normal !important',
                padding: '0 !important',
                [`& .${classes['MuiButton-label']}`]: {
                    justifyContent: 'flex-start',
                },
                width: '100%',
                justifyContent: 'flex-start',
            },
        },
        disabledInput: {
            display: 'flex',
            alignItems: 'center',
        },
        field: {
            borderBottom: `1px solid ${theme.palette.secondary[500]}`,
            width: '90%',
            '&:hover': {
                borderBottom: `1px solid ${theme.palette.secondary[900]}`,
            },
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: -8,
                width: 6,
                height: 28,
                backgroundColor: theme.palette.primary.main,
                display: 'none',
                borderRadius: 3,
            },
        },
        isRequired: {
            [`& .${classes.requiredMark}`]: {
                display: 'block',
            },
            backgroundColor: theme.palette.primary[30],
        },
        disabled: {
            [`& .${classes.requiredMark}`]: {
                backgroundColor: 'transparent !important',
            },
            backgroundColor: 'transparent !important',
        },
    }
})

export default useStyles
