import React, {
    useCallback, useMemo, CSSProperties,
} from 'react'
import {
    GoogleMap, MarkerF,
} from '@react-google-maps/api'
import {
    DEFAULT_ZOOM_100_M, DEFAULT_MAP_OPTIONS, ZRH_LONGITUDE, ZRH_LATITIDUE,
} from './SkyNetMap.constants'

const defaultProps = {
    zoom: DEFAULT_ZOOM_100_M,
    longitude: ZRH_LONGITUDE,
    latitude: ZRH_LATITIDUE,
    mapOptions: {},
    mapContainerStyles: undefined,
}

const SkyNetMap = ({
    longitude,
    latitude,
    zoom,
    mapContainerStyles,
    mapOptions,
}: {
    longitude?: number,
    latitude?: number,
    zoom?: number,
    mapOptions?: google.maps.MapOptions,
    mapContainerStyles?: CSSProperties,
}) => {
    const center: google.maps.LatLngLiteral = useMemo(() => {
        return {
            lng: longitude,
            lat: latitude,
        }
    }, [
        latitude,
        longitude,
    ])

    const onLoad = useCallback((currentMap: google.maps.Map) => {
        currentMap.setZoom(zoom)
    }, [zoom])

    const options = useMemo(() => {
        return {
            ...DEFAULT_MAP_OPTIONS,
            ...mapOptions,
        }
    }, [mapOptions])

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyles}
            center={center}
            zoom={zoom || DEFAULT_ZOOM_100_M}
            onLoad={onLoad}
            options={options}
        >
            <MarkerF
                position={center}
            />
        </GoogleMap>
    )
}

SkyNetMap.defaultProps = defaultProps

export default SkyNetMap
