import {
    useCallback,
} from 'react'
import {
    CellChange,
} from '@silevis/reactgrid'
import {
    Domain, ExtendedCellTypes,
} from '../SkyNetSpreadSheet.types'

export default (unresolvedValues: Map<Domain, Set<string>>) => {
    const findUnresolvedValue = useCallback((cell: ExtendedCellTypes) => {
        if ('undeterminedValue' in cell && 'domain' in cell
            && cell.undeterminedValue && Object.values(Domain).includes(cell.domain)) {
            if (unresolvedValues.has(cell.domain)) {
                unresolvedValues.get(cell.domain).add(cell.undeterminedValue)
                return
            }
            unresolvedValues.set(cell.domain, new Set([cell.undeterminedValue]))
        }
    }, [unresolvedValues])

    return useCallback((newValues: CellChange[]) => {
        newValues.forEach(({
            newCell,
        }) => {
            findUnresolvedValue(newCell)
        })

        return unresolvedValues
    }, [
        findUnresolvedValue,
        unresolvedValues,
    ])
}
