import simpleApi from 'app/services/simpleApi'

import filterLabelConverter from './filterLabelConverter'

export type LoadFiltersParams = {
    params: {
        token: string,
        props: Record<string, any>
        columnInfo: {
            filterField?: string,
        }
    },
    customUrl: string,
    getLabels: (string) => string,
}

const loadFilters = ({
    params,
    customUrl,
    getLabels,
}: LoadFiltersParams) => {
    const {
        token,
        columnInfo: {
            filterField,
        },
        props: {
            ...data
        },
    } = params

    const url = `${customUrl}/count/${filterField}`

    return simpleApi({
        url,
        method: 'POST',
        token,
        data,
    })
        .then(filterLabelConverter(getLabels))
}

export default loadFilters
