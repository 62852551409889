import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        rowFormContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        gridWrapper: {
            width: 1436,
        },
        blockItem: {
            '&&': {
                height: 'auto',
                background: 'none !important',
                boxShadow: 'none !important',
            },
        },

        headerTitle: {
            color: `${theme.palette.secondary[900]} !important`,
            fontWeight: `${theme.typography.h1.fontWeight} !important`,
            fontSize: `${theme.typography.h1.fontSize}px !important`,
            borderBottom: `1px solid ${theme.palette.primary[200]}`,
            paddingBottom: theme.spacing(1.5),
            marginBottom: `${theme.spacing(1)} !important`,
        },
    }
})

export default useStyles
