import {
    useCallback,
} from 'react'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

// adds empty data objects to the end of data array
export default ({
    rowsLimit, setData, dataLength, createEmptyDataRow,
}: {
    rowsLimit?: number,
    setData: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
    dataLength: number,
    createEmptyDataRow: () => Record<string, any>
}) => {
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'SkyNetSpreadsheet',
    })

    return useCallback((
        rowsQty: number = 1,
    ) => {
        if (rowsLimit && rowsLimit < rowsQty + dataLength) {
            onError({
                response: {
                    data: [`The number of rows is limited to ${rowsLimit}`],
                    statusCode: 500,
                },
            })
            return
        }

        setData((prev) => {
            const next = JSON.parse(JSON.stringify(prev))

            return [
                ...next,
                ...Array.from(Array(rowsQty), () => {
                    return createEmptyDataRow()
                }),
            ]
        })
    }, [
        setData,
        rowsLimit,
        dataLength,
        onError,
        createEmptyDataRow,
    ])
}
