import {
    RequestConfig,
} from 'app/types/request.types'

type AttachmentRequest = {
    get: ({
        id,
    }) => RequestConfig
}

const attachmentRequest: AttachmentRequest = {
    get: ({
        id,
    }) => {
        return {
            url: `attachment/${id}`,
        }
    },
}

export default attachmentRequest
