import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from '../Simulation/simulation.request'
import {
    ProductTypeSimulation,
} from '../Simulation/simulation.types'

const useGetProductTypes = () => {
    const {
        data,
    } = useRequest<ProductTypeSimulation[]>({
        key: RequestKeys.getProductTypesTransportSimulation,
        requestFunc: RequestFn.getEntity(config.ProductTypes.requestFn),
    })

    return data
}

export default useGetProductTypes
