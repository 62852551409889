import React, {
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'

import Overview from './Overview'
import ChangeData from './ChangeData'

import tableConfig from './ContainerType.config'
import requests from './ContainerType.request'

const overviewTab: SidebarTab = {
    url: 'overview',
    label: 'Overview',
    Component: Overview,
}

const changeData: SidebarTab = {
    url: 'changedata',
    label: 'Change Data',
    Component: ChangeData,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ContainerType = () => {
    const containerUpdate = useHasPermission(['dev_team'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: changeData,
            permission: containerUpdate,
        },
    ])

    return (
        <SkyNetTable
            name="ContainerTypeView"
            moduleName={requests.name}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default ContainerType
