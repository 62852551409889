/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import MultipleSelect from 'app/shared-components/MultipleSelect'

const MultipleSelectUnselectable = (props) => {
    return (
        <MultipleSelect
            {...props}
        />
    )
}

export default MultipleSelectUnselectable
