import React from 'react'
import noop from 'lodash/noop'

import Typeahead from 'app/shared-components/Typeahead'
import useGetCountry from 'app/Apps/Masterdata/Countries/hooks/useGetCountry'
import {
    DelayedLoadOptions,
} from '../../hooks/useDelayedLoadOptions'

type Props = {
    title?: string,
    value?: number | string,
    loadOptions?: (o: DelayedLoadOptions) => Promise<any>,
    onChange?: () => void,
    className?: string,
    isRequired?: boolean,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    title: '',
    value: '',
    onChange: noop,
    loadOptions: noop,
    className: '',
    isRequired: false,
    disabled: false,
}

const CountrySelector = ({
    title,
    value,
    onChange,
    loadOptions,
    className,
    isRequired,
    disabled,
}: Props) => {
    const {
        data: labelData,
    } = useGetCountry(value)

    return (
        <div className={className}>
            <Typeahead
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={labelData}
                isRequired={isRequired}
                disabled={disabled}
                labelField="countryName"
                value={value}
                variant="standard"
            />
        </div>
    )
}

CountrySelector.defaultProps = defaultProps

export default CountrySelector
