import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    DocumentLogger,
} from './documents.types'

const columns = ({
    l,
    canLoggerDelete,
}: {
    l: (l:string) => string,
    canLoggerDelete: boolean,
}): RichTableColumns<keyof DocumentLogger>[] => {
    return [
        {
            id: 'filename',
            headerProps: {
                children: l('Document.documentName'),
            },
            mapCellProps: 'filename',
        },
        {
            id: 'createdBy',
            headerProps: {
                children: l('LaneGroup.createdBy'),
            },
            mapCellProps: 'createdBy',
        },
        {
            id: 'createdOn',
            headerProps: {
                children: l('Accounts.createdOn'),
            },
            mapCellProps: 'createdOn',
        },
        ...canLoggerDelete ? [{
            id: 'id',
            mapCellProps: 'id',
            componentName: 'LoggerDocumentDelete',
            width: '50px',
        }] as RichTableColumns<keyof DocumentLogger>[] : [],
        {
            id: 'attachmentUrl',
            mapCellProps: 'attachmentUrl',
            componentName: 'DownloadDocument',
            width: '50px',
        },
    ]
}

export default columns
