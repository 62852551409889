import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        dateTimeInput: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 12,
        },
    }
})

export default useStyles
