import {
    FieldInCard,
    NestedField,
} from 'app/types/form.types'
import {
    PostponeInfoFields,
    PostponementReason,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getFields = (isPickUp: boolean, getAllowedValues): FieldInCard<PostponeInfoFields>[] => {
    const fieldsIsNotPickUp: NestedField<PostponeInfoFields>[] = [
        {
            name: 'postponementReason',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(PostponementReason),
            useDropdown: true,
            required: true,
        },
        {
            name: 'requestedDelivery',
            componentName: 'DateTimeZonedSelect',
            required: true,
        },
        {
            name: 'requestedPickup',
            componentName: 'DateTimeZonedSelect',
            required: true,
        },
    ]

    const fieldsIsPickUp: NestedField<PostponeInfoFields>[] = [
        {
            name: 'postponementReason',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(PostponementReason),
            useDropdown: true,
            required: true,
        },
        {
            name: 'requestedPickup',
            componentName: 'DateTimeZonedSelect',
            required: true,
        },
    ]

    return [
        {
            id: 'column1',
            fields: [...isPickUp ? fieldsIsPickUp : fieldsIsNotPickUp],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'postponementRequestedOn',
                    componentName: 'DateTimeZonedSelect',
                    required: true,
                },
                {
                    name: 'leaseStartTimestamp',
                    componentName: 'DateTimeZonedSelect',
                    required: true,
                },
            ],
        },
    ]
}

export default getFields
