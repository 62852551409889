import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    CreateContainerFields,
} from '../../containers.types'

const fields: DTOCardFormField<CreateContainerFields>[] = [
    {
        title: 'Create Container',
        className: 'createContainer',
        id: 'createContainer',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'containerType',
                    componentName: 'SelectContainerTypeByCode',
                    labelKey: 'Container.containerType',
                    required: true,
                }],
            },
            {
                id: 'column2',
                fields: [{
                    name: 'serialNumberSuffixFrom',
                    labelKey: 'ContainerAutoCreateForm.serialNumberSuffixFrom',
                    componentName: 'IntegerInput',
                    required: true,
                }],
            },
            {
                id: 'column3',
                fields: [{
                    name: 'numberOfContainers',
                    componentName: 'IntegerInput',
                    required: true,
                    labelKey: 'ContainerAutoCreateForm.numberOfContainers',
                }],
            },
        ],
    },
    {
        title: 'Additional Information',
        className: 'additionalInformation',
        id: 'additionalInformation',
        fields: [
            {
                name: 'location',
                componentName: 'LocationObjectSelector',
                labelKey: 'Container.location',
                required: true,
            },
            {
                name: 'financingContract',
                componentName: 'InputSingleline',
                labelKey: 'Container.financingContract',
            },
            {
                name: 'containerModel',
                componentName: 'IntegerInput',
                labelKey: 'ContainerType.containerModel',
                required: true,
            },
        ],
    },
]

export default fields
