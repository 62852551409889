import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'

import WrapperComponent from './WrapperComponent'
import Unknown from './Unknown'

const propTypes = {
    onChange: PropTypes.func,
    uploadFiles: PropTypes.func,
    value: PropTypes.any, // eslint-disable-line
    classNames: PropTypes.objectOf(PropTypes.string),
    fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        customComponent: PropTypes.func,
        isRequired: PropTypes.bool,
        value: PropTypes.any, // eslint-disable-line
        id: PropTypes.string,
    })).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    exists: PropTypes.bool,
    setErrors: PropTypes.func,
}

const defaultProps = {
    value: {},
    exists: false,
    classNames: {},
    onChange: noop,
    uploadFiles: noop,
    errors: [],
    setErrors: undefined,
}

const FormBuilder = (props) => {
    const {
        onChange,
        uploadFiles,
        value,
        classNames,
        fields,
        errors,
        exists,
        setErrors,
    } = props

    const defaultFieldValues = useMemo(() => {
        return fields.filter(({
            value: defaultFieldValue,
        }) => {
            return defaultFieldValue !== undefined
        }).reduce((prev, curr) => {
            return {
                ...prev,
                [curr.name]: curr.value,
            }
        }, {})
    }, [fields])

    return (
        <>
            {fields.map((fieldProps) => {
                const {
                    name,
                    isRequired,
                    customComponent,
                    value: customComponentValue,
                    id,
                } = fieldProps

                const defaultValue = get(value, name)

                const Component = customComponent || Unknown
                const componentValue = !defaultValue && defaultValue !== 0
                    ? customComponentValue
                    : defaultValue

                return (
                    <WrapperComponent
                        component={Component}
                        fieldProps={fieldProps}
                        classNames={classNames}
                        key={`${name}-${id}`}
                        onChange={onChange}
                        uploadFiles={uploadFiles}
                        value={{
                            ...value,
                            ...defaultFieldValues,
                        }}
                        componentValue={componentValue}
                        exists={exists}
                        isRequired={isRequired}
                        name={name}
                        errors={errors}
                        setErrors={setErrors}
                    />
                )
            })}
        </>
    )
}

FormBuilder.propTypes = propTypes
FormBuilder.defaultProps = defaultProps

export default FormBuilder
