import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    PreBooking, PreBookingStatus,
} from 'app/Apps/PreBooking/PreBooking.types'
import requests from 'app/Apps/PreBooking/PreBookings.requests'

type PrebookingFilters = {
    preBookingStatus?: PreBookingStatus[],
}

export default (filters: PrebookingFilters, enabled: boolean) => {
    const {
        loadData,
        data,
        onParamsChange,
    } = useRequestTable<TableResponse<PreBooking[]>>({
        defaultValue: {
            filter: {
                ...filters,
            },
        },
        params: {},
        config: requests.filterRequest,
        key: RequestKeys.getPreBookings,
        keepPreviousData: true,
        enabled,
    })

    return {
        loadData,
        data,
        onParamsChange,
    }
}
