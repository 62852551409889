import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

const defaultProps = {
    value: undefined,
}

const TemperatureCelsius = (props) => {
    const {
        value,
    } = props

    if (value === undefined) {
        return null
    }

    return (
        <div>
            {`${value}°C`}
        </div>
    )
}

TemperatureCelsius.propTypes = propTypes
TemperatureCelsius.defaultProps = defaultProps

export default TemperatureCelsius
