import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'

import quotesPdfRequests from '../AccountQuotes.requests'

const REQUEST_KEY = RequestKeys.getQuoteDocuments

const useAccountQuoteDocuments = (id) => {
    const queryClient = useQueryClient()

    const refresh = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [REQUEST_KEY],
        })
    }, [queryClient])

    const {
        onParamsChange,
        loadData: load,
    } = useRequestTable({
        key: REQUEST_KEY,
        params: {
            id,
        },
        config: quotesPdfRequests.getQuoteDocuments,
        enabled: Boolean(id),
    })

    return {
        onParamsChange,
        load,
        refresh,
    }
}

export default useAccountQuoteDocuments
