import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        priceContainer: {
            borderBottom: `1px solid ${theme.palette.secondary[400]}`,
            padding: theme.spacing(1, 0),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: theme.palette.secondary[600],
            height: 30,
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(0, 1.25),
        },
        price: {
            fontSize: theme.typography.h3.fontSize,
        },
        info: {
            fontSize: theme.typography.caption.fontSize,
        },
        statusRoot: {
            display: 'flex',
            flexGrow: 1,
            borderRadius: '0 0 4px 4px',
            position: 'relative',
            color: theme.palette.secondary[600],
        },
        border: {
            flexDirection: 'row',
            width: 32,
        },
        borderBlue: {
            borderRight: `1px solid ${theme.palette.primary[400]}`,
        },
        borderGray: {
            borderRight: `1px solid ${theme.palette.secondary[600]}`,
        },
        statusContainer: {
            position: 'relative',
            width: '100%',
            paddingTop: theme.spacing(2),
        },
        invoiceStatus: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            position: 'absolute',
            left: -11,
        },
        wrapperStatus: {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: theme.spacing(1),
        },
        icon: {
            width: 22,
            height: 22,
            paddingRight: theme.spacing(1),
        },
        invoiceDate: {
            position: 'absolute',
            bottom: 5,
            right: 10,
            fontSize: theme.typography.caption.fontSize,
        },
    }
})

export default useStyles
