import React from 'react'
import clsx from 'clsx'

import {
    FilterPayload,
} from 'app/hooks/useFilter'
import {
    ComponentsFormCollectionNames,
} from 'app/utils/components/ComponentsFormCollection'
import {
    FilterType,
} from 'app/types/common.enums'
import getComponentByName from 'app/utils/components/getComponentByName'
import useStyles from '../SkyNetSpreadSheetControlPanel.styles'

const PreselectFilterTabs = ({
    preselectTabs,
    setFilter,
}: {
    preselectTabs: {
        name: string,
        filterField: string,
        filterType: FilterType,
        value: (string|number|boolean)[],
        filterToValueConverter?: (f: (string|number|boolean)[]) => any,
        onChangeConverter?: (v: any) => (string|number|boolean)[],
        placeholder?: string,
        componentName: ComponentsFormCollectionNames,
    }[],
    setFilter: (payload: FilterPayload) => void
}) => {
    const {
        classes,
    } = useStyles()
    const elements = preselectTabs.map((fieldConfig) => {
        const {
            name,
            filterField,
            value,
            filterToValueConverter,
            onChangeConverter,
            componentName,
            ...rest
        } = fieldConfig
        const Component = getComponentByName(componentName)
        const compValue = filterToValueConverter ? filterToValueConverter(value) : value
        const onChange = (selectedValue: any) => {
            let result = selectedValue

            if (onChangeConverter) {
                result = onChangeConverter(selectedValue)
            }

            setFilter({
                filterField,
                value: result,
            })
        }

        return (
            <div
                key={name}
                className={clsx(classes.controls, classes.selectors)}
            >
                <Component
                    {...rest} // eslint-disable-line react/jsx-props-no-spreading
                    title={null}
                    value={compValue}
                    onChange={onChange}
                />
            </div>
        )
    })

    return <div className={classes.tabsWrapper}>{elements}</div>
}

export default PreselectFilterTabs
