import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import request from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateAirlineLease/createCustomerTransportForm.request'
import {
    CustomerTransportRequestParams,
} from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateAirlineLease/createCustomerTransportForm.types'

const useCreateCustomerTransportOrder = (onSuccess: ({
    orderNumber,
}: {orderNumber: string}) => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'CreateOrder',
    })

    return useMutation({
        mutationFn: (data: CustomerTransportRequestParams) => {
            return request.CreateCTOrder.mutationFn({
                data,
                token,
            })
        },
        onSuccess,
        onError,
    })
}

export default useCreateCustomerTransportOrder
