import {
    makeStyles,
} from 'app/tss'

const change = 'change'
const gStatus = 'gStatus'
const location = 'location'
const locationOld = 'locationOld'
const locationNew = 'locationNew'
const logger = 'logger'
const audit = 'audit'

const useStyles = makeStyles()((theme) => {
    return {
        rowFormContainer: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.secondary[50],
        },
        returnIcon: {
            height: 28,
            width: 28,
            cursor: 'pointer',
            padding: theme.spacing(1),
        },
        contentWrapper: {
            width: '100%',
            top: 15,
            position: 'relative',
            padding: 0,
            border: 'none',
        },
        gridTemplateNote: {
            gridTemplateAreas: `"${change} ${change} ${change} ${audit}"`,
        },
        gridTemplateGeneralStatus: {
            gridTemplateAreas: `"${change} ${change} ${gStatus} ${audit}"`,
        },
        gridTemplateContainerStatus: {
            gridTemplateAreas: `"${change} ${gStatus} ${location} ${audit}"`,
        },
        gridTemplateLoggerExchange: {
            gridTemplateAreas: `"${change} ${logger} ${logger} ${audit}"`,
        },
        gridTemplateLocationChange: {
            gridTemplateAreas: `"${change} ${locationOld} ${locationNew} ${audit}"`,
        },
        gridTemplateLoggerRemoval: {
            gridTemplateAreas: `"${change} ${audit}"`,
        },
        gridTemplateTaskClosed: {
            gridTemplateAreas: `"${change} ${audit}"`,
        },
        gridTemplateTaskAdded: {
            gridTemplateAreas: `"${change} ${audit}"`,
        },

        gridWrapper: {
            width: '100%',
            display: 'grid',
            gridGap: theme.spacing(1.5),
            backgroundColor: theme.palette.secondary[50],
            gridAutoColumns: 'minmax(280px, auto)',
        },
        gridCardWrapper: {
            gridTemplateColumns: '1fr',
        },
        change: {
            gridArea: change,
        },
        gStatus: {
            gridArea: gStatus,
        },
        logger: {
            gridArea: logger,
        },
        location: {
            gridArea: location,
        },
        locationOld: {
            gridArea: locationOld,
        },
        locationNew: {
            gridArea: locationNew,
        },
        audit: {
            gridArea: audit,
        },
    }
})

export default useStyles
