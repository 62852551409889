import React from 'react'

import useStyles from './HeaderWarning.style'

const HeaderWarning = ({
    children,
}:{
    children: string,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.warning}>
            <img
                src="assets/images/warning.svg"
                alt="warning"
            />
            <div className={classes.warningText}>
                {children}
            </div>
            <img
                src="assets/images/warning.svg"
                alt="warning"
                className={classes.iconRight}
            />
        </div>
    )
}

export default HeaderWarning
