import {
    TransportModeRoad,
    OrderStepFields,
    DefaultTruckingComment,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LocationType,
} from 'app/types/enums'
import {
    FieldInCard,
    NestedField,
} from 'app/types/form.types'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

type Args = {
    forwarderId: number,
    pickupLocationId: number,
    deliveryLocationId: number,
    transportModeRoad: TransportModeRoad,
    transportModeForwarder: TransportModeRoad,
    exists: boolean,
    isFirstStep: boolean,
    locationId: number,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    isRoadDelivery: boolean,
}

const getInternalRoadFormFields = ({
    forwarderId,
    pickupLocationId,
    deliveryLocationId,
    transportModeRoad,
    transportModeForwarder,
    exists,
    isFirstStep,
    locationId,
    getAllowedValues,
    isRoadDelivery,
}: Args): FieldInCard<OrderStepFields>[] => {
    const transportMode = isRoadDelivery ? transportModeForwarder : transportModeRoad

    const trucksAndVans: FieldInCard<OrderStepFields>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'OrderStep.numberOfTrucks',
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'OrderStep.numberOfVans',
        },
    ]

    const pickupLocationContactsByLocationField: NestedField<OrderStepFields>[] = [{
        name: 'pickupLocationContacts',
        componentName: 'MultipleContactByLocationObjectSelector',
        existedForm: exists,
        selectedLocation: pickupLocationId,
        labelKey: 'OrderStep.pickupLocationContacts',
    }]

    const pickupLocationContactsField: NestedField<OrderStepFields>[] = [{
        name: 'pickupLocationContacts',
        componentName: 'MultipleContactObjectSelector',
        labelKey: 'OrderStep.pickupLocationContacts',
    }]

    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'forwarder',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.ADDRESS,
                    filterAddressMainCategory: AddressMainCategory.FORWARDER,
                    labelKey: 'OrderStep.forwarder',
                },
                {
                    name: 'forwarderContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: exists,
                    selectedLocation: forwarderId,
                    labelKey: 'OrderStep.forwarderContacts',
                },
                {
                    name: 'forwarderAdHoc',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.forwarderAdHoc',
                },
                {
                    name: 'transportModeRoad',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'OrderStep.transportModeRoad',
                },
                ...isRoadDelivery ? [{
                    name: 'transportModeForwarder',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'Order.transportModeForwarder',
                }] as NestedField<OrderStepFields>[] : [],
                ...transportMode === TransportModeRoad.FTL ? trucksAndVans as [] : [],
                {
                    name: 'isTemperatureControlled',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.isTemperatureControlled',
                },
                {
                    name: 'defaultTruckingComment',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DefaultTruckingComment),
                    useDropdown: true,
                    labelKey: 'OrderStep.defaultTruckingComment',
                },
                {
                    name: 'truckingComment',
                    className: 'truckingComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.truckingComment',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'location',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderStep.location',
                },
                {
                    name: 'locationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: exists,
                    selectedLocation: locationId,
                    labelKey: 'OrderStep.locationContacts',
                },
                {
                    name: 'requestedExecution',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId,
                    labelKey: 'OrderStep.requestedExecution',
                },
                {
                    name: 'estimatedExecution',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId,
                    labelKey: 'OrderStep.estimatedExecution',
                },
                {
                    name: 'actualExecution',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId,
                    labelKey: 'OrderStep.actualExecution',
                },
                {
                    name: 'pickupLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.pickupLocation',
                },
                {
                    name: 'pickupLocationEvent',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.pickupLocationEvent',
                },
                ...isFirstStep
                    ? pickupLocationContactsByLocationField
                    : pickupLocationContactsField,
                {
                    name: 'requestedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.requestedPickup',
                },
                {
                    name: 'estimatedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.estimatedPickup',
                },
                {
                    name: 'actualPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.actualPickup',
                },
                {
                    name: 'pickupComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.pickupComment',
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'deliveryLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.deliveryLocation',
                },
                {
                    name: 'deliveryLocationEvent',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.deliveryLocationEvent',
                },
                {
                    name: 'deliveryLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: exists,
                    selectedLocation: deliveryLocationId,
                    labelKey: 'OrderStep.deliveryLocationContacts',
                },
                {
                    name: 'requestedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.requestedDelivery',
                },
                {
                    name: 'estimatedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.estimatedDelivery',
                },
                {
                    name: 'actualDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.actualDelivery',
                },
                {
                    name: 'deliveryComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.deliveryComment',
                },
            ],
        },
        {
            id: 'column4',
            fields: [
                {
                    name: 'truckingCompany',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.truckingCompany',
                },
                {
                    name: 'driverName',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.driverName',
                },
                {
                    name: 'driverContactNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.driverContactNumber',
                },
                {
                    name: 'licensePlateNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.licensePlateNumber',
                },
                {
                    name: 'cmrNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.cmrNumber',
                },
                {
                    name: 'cmrReceived',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.cmrReceived',
                },
                {
                    name: 'cmrDocumentCopy',
                    componentName: 'AttachmentsObjectSelector',
                    labelKey: 'OrderStep.cmrDocumentCopy',
                },
            ],
        },
    ]
}

export default getInternalRoadFormFields
