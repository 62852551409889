import {
    createContext, useContext,
} from 'react'
import {
    AccountQuote,
} from '../AccountQuotes.types'

export type QuoteInformationContextType = {
    activeStep: number,
    setActiveStep: (n: number) => void,
    value: AccountQuote,
    initialValue?: AccountQuote,
    setValue: (a: AccountQuote) => void,
    setIsEdited: (isEdited: boolean) => void,
}

export const AccountQuoteInformationContext = createContext<QuoteInformationContextType>(null)

const useAccountQuoteInformationContext = () => {
    return useContext<QuoteInformationContextType>(AccountQuoteInformationContext)
}

export default useAccountQuoteInformationContext
