import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import requestConfig from '../laneSelector.request'

const useGetLane = (id) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Lane>({
        key: RequestKeys.getLaneById,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requestConfig.get),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetLane
