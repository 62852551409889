import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        tabsWrapper: {
            top: '1px',
            left: 0,
            width: '100%',
            display: 'flex',
            overflow: 'hidden',
            position: 'absolute',
            overflowX: 'auto',
            justifyContent: 'space-between',
        },
        tabs: {
            display: 'flex',
            alignContent: 'center',
        },
    }
})

export default useStyles
