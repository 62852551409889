import {
    useMutation,
} from '@tanstack/react-query'

import {
    useJWTToken,
} from 'app/Auth'
import simpleApi from 'app/services/simpleApi'

import config from './requests'

const useGetTimeZoneByLatLng = () => {
    const token = useJWTToken()
    const {
        mutateAsync,
    } = useMutation({
        mutationFn: ({
            latitude, longitude,
        }: {
            latitude: number,
            longitude: number,
        }) => {
            return simpleApi(config.TimeZone.requestFn({
                latitude,
                longitude,
                token,
            }))
                .then((response) => {
                    return response?.timezone
                })
        },
    })

    return mutateAsync
}

export default useGetTimeZoneByLatLng
