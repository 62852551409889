import React, {
    useCallback, useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import {
    countFurtherMonths,
    dataToSpreadsheetColumnsMap,
} from '../../Forecast.utils'
import useGetForecast from '../../hooks/useGetForecast'
import ShortTermForecastForm from '../ShortTermForecastForm'
import {
    ForecastDataSpreadsheetColumnsConfig,
} from '../../Forecast.types'

const ShortTermForecastOverview = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetForecast(id)

    const forecastFields = useMemo(() => {
        return countFurtherMonths()
    }, [])

    const getFieldsMapper = useCallback(dataToSpreadsheetColumnsMap, [])

    const tmpFields: ForecastDataSpreadsheetColumnsConfig = useMemo(() => {
        return [...Array.from(forecastFields).sort().reverse().map(getFieldsMapper({
            disabled: false,
        }))]
    }, [
        forecastFields,
        getFieldsMapper,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ShortTermForecastForm
                data={data}
                tmpFields={tmpFields}
                fieldsMap={{
                    forecastFields,
                }}
                disabled
            />
        </StatusHandler>
    )
}

export default ShortTermForecastOverview
