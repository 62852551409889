import React from 'react'
import SingleObjectSelectorConverter from 'app/shared-components/SingleObjectSelectorConverter'

import UserSelector from './UserSelector'

type Props = {
    name?: string,
    value?: {
        id: number | string,
    },
    onChange: () => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
}

const defaultProps : Partial<Props> = {
    name: '',
    value: undefined,
    title: 'Users',
    disabled: false,
    className: '',
    required: false,
}

const ContactObjectSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
}: Props) => {
    return (
        <SingleObjectSelectorConverter
            selector={UserSelector}
            value={value}
            onChange={onChange}
            title={title}
            name={name}
            disabled={disabled}
            className={className}
            required={required}
        />
    )
}

ContactObjectSelector.defaultProps = defaultProps

export default ContactObjectSelector
