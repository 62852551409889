import {
    failedToLoadSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'
import {
    TableResponse,
    DataRequest,
    SpecRequest,
} from 'app/types/request.types'

interface Output {
    (dispatch: (any) => void): (args: any) => Promise<TableResponse | Record<string, any> | void>
}

type Props = {
    paramFn: (args: Record<string, any>) => SpecRequest,
    requestFn: (params: DataRequest) => Promise<{data: any}>,
    parserFn: (args: Promise<{data: any}>) => Promise<TableResponse | Record<string, any>>,
}

const requestFunctionBuilder = ({
    paramFn,
    requestFn,
    parserFn,
} : Props): Output => {
    return (dispatch) => {
        return ({
            queryKey: [
                _key, // eslint-disable-line @typescript-eslint/no-unused-vars
                {
                    token,
                    ...rest
                },
            ],
        }) => {
            return parserFn(requestFn({
                token,
                ...paramFn({
                    ...rest,
                }),
            })).catch((error) => {
                const {
                    response: {
                        data: errors,
                        status: statusCode,
                    },
                } = error

                dispatch(
                    failedToLoadSaveForm({
                        id: 'useRequestId',
                        errors,
                        statusCode,
                    }),
                )
            })
        }
    }
}

export default requestFunctionBuilder
