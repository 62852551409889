import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            alignItems: 'stretch',
            gap: 20,
        },
        child: {
            flex: 1,
            width: '50%',
        },
    }
})

export default useStyles
