import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    SelectedContainerFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getColumns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<SelectedContainerFields>[] => {
    return [
        {
            id: 'serialNumber',
            headerProps: {
                children: l('Container.serialNumber'),
            },
            mapCellProps: 'serialNumber',
        },
        {
            id: 'jypId',
            headerProps: {
                children: l('Container.jypId'),
            },
            mapCellProps: 'jypId',
        },
        {
            id: 'collectionDropoffPoint',
            headerProps: {
                children: l('LaneGroupView.collectionDropoffPointLocationName'),
            },
            mapCellProps: 'collectionDropoffPoint',
        },
        {
            id: 'returnedOn',
            headerProps: {
                children: l('Container.returnedOn'),
            },
            mapCellProps: 'returnedOn',
        },
    ]
}

export default getColumns
