import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles<{disabled: boolean}>()((theme, {
    disabled,
}) => {
    return {
        nonEditMode: {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
        },
        text: {
            width: disabled ? '100%' : 'calc(100% - 25px)',
            text: 'noWrap',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary[900],
        },
        dropdownIcon: {
            width: 25,
            display: 'inline-flex',
            color: theme.palette.secondary[500],
        },
    }
})

export default useStyles
