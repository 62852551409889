import {
    SimulationStep, StepDataChart,
} from 'app/Apps/TransportPlanner/Simulation/simulation.types'

const getStepsData = ({
    allowedValuesType,
    isDurationByHours,
    steps = [],
}: {
    allowedValuesType: Record<string, string>,
    isDurationByHours: boolean,
    steps: SimulationStep[],
}): StepDataChart => {
    const data = steps?.reduce((acc, {
        ambientTemperature,
        durationHours = 0,
        durationMinutes = 0,
        location,
        type,
    }, index) => {
        const duration = isDurationByHours
            ? durationHours
            : durationHours * 12 + durationMinutes / 5

        const time = index === 0 ? duration + 1 : duration
        const coefficient = isDurationByHours ? 60 : 5

        return {
            sumTime: acc.sumTime + time,
            points: [
                ...acc.points,
                {
                    x: index === 0
                        ? Math.round((time * coefficient) / 2)
                        : Math.round((acc.sumTime + (time / 2)) * coefficient),
                    y: ambientTemperature,
                    location,
                    type: allowedValuesType[type],
                },
            ],
            ambientTemp: [
                ...acc.ambientTemp,
                new Array(time).fill(ambientTemperature),
            ],
        }
    }, {
        points: [],
        ambientTemp: [],
        sumTime: 0,
    })

    return {
        ...data,
        ambientTemp: data.ambientTemp.flat() || [],
    }
}

export default getStepsData
