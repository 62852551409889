import React, {
    useMemo,
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import {
    Select as MaterialSelect,
    MenuItem,
    FormControl,
    FormHelperText,
} from '@mui/material'

import Label from 'app/shared-components/Label'
import sortAscending from 'app/utils/sortAscending'

import useStyles from './Select.style'

const propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    data: PropTypes.arrayOf(PropTypes.any),
    allowedValues: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
    }),
    hasError: PropTypes.string,
    required: PropTypes.bool,
    timeFormat: PropTypes.bool,
    disableUnderline: PropTypes.bool,
    disableHelperText: PropTypes.bool,
    disableSorting: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
}

const defaultProps = {
    title: '',
    name: '',
    data: [],
    allowedValues: {},
    onChange: noop,
    value: undefined,
    hasError: '',
    required: false,
    timeFormat: false,
    disableUnderline: false,
    disableSorting: false,
    disabled: false,
    disableHelperText: false,
    className: '',
}

const Select = (props) => {
    const {
        title,
        name,
        onChange,
        value,
        data,
        allowedValues,
        hasError,
        required,
        timeFormat,
        disableUnderline,
        disableHelperText,
        disableSorting,
        disabled,
        className,
    } = props
    const {
        classes,
    } = useStyles()

    const options = useMemo(() => {
        const result = data.length === 0
            ? Object.entries(allowedValues)
            : data

        return disableSorting ? result : sortAscending(result)
    }, [
        data,
        allowedValues,
        disableSorting,
    ])

    const handleChange = useCallback(({
        target,
    }) => {
        onChange(
            target.value,
            {
                target,
            },
        )
    }, [onChange])

    return (
        <>
            <FormControl
                error={Boolean(hasError)}
                className={clsx(
                    classes.formControl,
                    className,
                )}
                variant="standard"
            >
                {title && (
                    <Label
                        title={title}
                        hasError={hasError}
                    />
                )}
                <div className={clsx(
                    {
                        [classes.selectContainer]: true,
                        [classes.isRequired]: required,
                        [classes.timeFormat]: timeFormat,
                    },
                )}
                >
                    <MaterialSelect
                        data-testid={`select-${name}`}
                        className={clsx(
                            {
                                [classes.background]: true,
                                [classes.isRequiredBackground]: required,
                            },
                            className,
                        )}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        color="primary"
                        fullWidth
                        disableUnderline={disableUnderline}
                        classes={{
                            root: classes.input,
                            select: classes.select,
                        }}
                        disabled={disabled}
                    >
                        <MenuItem value={undefined}>
                            <em>None</em>
                        </MenuItem>
                        {options.map(([
                            key,
                            val,
                        ]) => {
                            return (
                                <MenuItem
                                    key={`menuitem-${key}`}
                                    value={key}
                                >
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </MaterialSelect>
                    {Boolean(hasError) && (
                        <FormHelperText
                            error
                            className={clsx(
                                {
                                    [classes.errorMessage]: true,
                                    [classes.hideHelperText]: disableHelperText,
                                },
                            )}
                        >
                            {hasError}
                        </FormHelperText>
                    )}
                </div>
            </FormControl>
        </>
    )
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
