import {
    getDuration,
    getDurationHoursStr,
    getDurationMinutesStr,
} from 'app/shared-components/AmountOfTime/utils'

const range = (start: number, stop: number, step: number) => {
    return Array.from({
        length: (stop - start) / step + 1,
    }, (_, i) => {
        return start + i * step
    })
}

const formDuration = (values: number[]) => {
    return Object.fromEntries(values.map((value) => {
        const {
            days,
            hrs,
            minutes,
        } = getDuration(value)
        const label = [
            getDurationHoursStr(days, hrs),
            getDurationMinutesStr(days, hrs, minutes),
        ].filter(Boolean).join('')

        return [
            value,
            label,
        ]
    }))
}

// eslint-disable-next-line import/prefer-default-export
export const allowedDurationValues = formDuration(range(15, 240, 15))
