import React, {
    useCallback,
    useMemo,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'

type ChipItem = {
    label: string,
    value: string
}

type Props = {
    title: string,
    value?: string[],
    disabled?: boolean,
    required?: boolean,
    allowedValues: Record<string, string>,
    onChange: (...args: any[]) => void,
    menuShouldBlockScroll?: boolean,
    menuPortalTarget?: HTMLElement,
}

const defaultProps = {
    disabled: false,
    required: false,
    value: [],
    menuShouldBlockScroll: false,
    menuPortalTarget: null,
}

const EnumChipSelector = ({
    title,
    value,
    disabled,
    allowedValues,
    onChange,
    required,
    menuShouldBlockScroll,
    menuPortalTarget,
}: Props) => {
    const convertToChips = useCallback((item) => {
        return {
            label: allowedValues[item],
            value: item,
        }
    }, [allowedValues])

    const handleOnChange = useCallback((items: ChipItem[]) => {
        onChange((items || []).map(({
            value: chipValue,
        }) => { return chipValue }))
    }, [onChange])

    const options = useMemo(() => {
        return (Object.keys(allowedValues).map(convertToChips))
    }, [
        allowedValues,
        convertToChips,
    ])

    return (
        <ChipSelect
            onChange={handleOnChange}
            disabled={disabled}
            title={title}
            value={value.map(convertToChips)}
            options={options}
            required={required}
            menuShouldBlockScroll={menuShouldBlockScroll}
            menuPortalTarget={menuPortalTarget}
        />
    )
}

EnumChipSelector.defaultProps = defaultProps

export default EnumChipSelector
