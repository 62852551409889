import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        containerBtn: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            '@media print': {
                display: 'none',
            },
        },
        btnControl: {
            paddingTop: 20,
        },
    }
})

export default useStyles
