import moment from 'moment/moment'

import {
    Duration,
} from './duration.types'

export const getDuration = (value?: number): Duration => {
    const duration = value && moment.duration(value, 'minutes')

    return {
        years: duration && duration.years(),
        months: duration && duration.months(),
        days: duration && duration.days(),
        hrs: duration && duration.hours(),
        minutes: duration && duration.minutes(),
    }
}

export const getDurationMinutesStr = (days, hrs, minutes): string => {
    return minutes !== 0 && `${days !== 0 || hrs !== 0 ? ` ${Math.abs(minutes)}` : `${minutes}`}min`
}

export const getDurationHoursStr = (days, hrs): string => {
    return hrs !== 0 && `${days !== 0 ? ` ${Math.abs(hrs)}` : `${hrs}`}h`
}
