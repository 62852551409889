import {
    RequestConfig, RequestForDomainForm,
} from 'app/types/request.types'

type GatewayRequests = {
    domainName: string,
    filterRequest: ({
        params: any,
    }) => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    update: RequestForDomainForm<undefined>,
    create: RequestForDomainForm<undefined>,
    modifications: ({
        id: number,
    }) => RequestConfig
}

const request: GatewayRequests = {
    domainName: 'Gateway',
    filterRequest: ({
        params,
    }) => {
        return {
            url: 'gateway/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `gateway/${id}`,
        }
    },
    update: {
        name: 'Gateway',
        request: () => {
            return {
                url: 'gateway',
                method: 'PUT',
                domainName: 'Gateway',
            }
        },
    },
    create: {
        name: 'Gateway',
        request: () => {
            return {
                url: 'gateway',
                method: 'POST',
                domainName: 'Gateway',
            }
        },
    },
    modifications: ({
        id,
    }) => {
        return {
            url: `gateway/${id}/modifications`,
            method: 'GET',
        }
    },
}

export default request
