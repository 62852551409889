import {
    useState, useMemo, useCallback, ChangeEvent,
} from 'react'
import {
    SkyNetPaginationType,
} from './SkyNetPaginationContext'

export default function useSkyNetPagination(rows: number): SkyNetPaginationType {
    const [
        matches,
        setMatches,
    ] = useState<number>(0)
    const [
        start,
        setStart,
    ] = useState<number>(0)
    const [
        page,
        setPage,
    ] = useState<number>(1)

    const onChange = useCallback((e: ChangeEvent<HTMLButtonElement>, el: number) => {
        setPage(el)
        setStart((el - 1) * rows)
    }, [rows])

    return useMemo(() => {
        return {
            page,
            rows,
            matches,
            setMatches,
            onChange,
            start,
        }
    }, [
        page,
        matches,
        onChange,
        start,
        rows,
    ])
}
