import {
    UseRequestConfig,
} from 'app/types/request.types'

const requestConfig: Record<string, UseRequestConfig> = {
    LaneById: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `lane/${id}/ops-information`,
            }
        },
    },
    RiskAssessment: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `lane/${id}/risk-assessment-billing`,
            }
        },
    },
    SetAsInactive: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `lane/${id}/lanestatus/inactive`,
                method: 'PATCH',
            }
        },
    },
    SetAsImplemented: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `lane/${id}/lanestatus/implemented`,
                method: 'PATCH',
            }
        },
    },
}

export default requestConfig
