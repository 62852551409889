import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton, useArchive, ArchiveConfirmationDialog,
} from 'app/shared-components/Archive'
import useGetContact from 'app/Apps/ContactManagement/Contacts/hooks/useGetContact'
import {
    App,
} from 'app/types/common.enums'

import EngagementForm from '../EngagementForm'
import useGetEngagement from '../hooks/useGetEngagement'
import request from '../engagement.request'

const EngagementUpdate = ({
    id,
}: { id: number }) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetEngagement(id)

    const {
        data: contact,
    } = useGetContact(data?.contact?.id)

    const archive = useArchive({
        requestParamFn: data?.archived ? request.restore
            : request.archive,
        notificationId: 'EngagementUpdate',
        title: 'Engagement',
        data,
        redirectionAfterSuccess: contact?.isProspect ? `/apps/${App.RELATIONSHIPS_MANAGEMENT}/engagements/prospect`
            : `/apps/${App.RELATIONSHIPS_MANAGEMENT}/engagements/all`,
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        archive,
        data?.archived,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <EngagementForm
                    requestParam={request.update({
                        id,
                    })}
                    data={data}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Engagement"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

export default EngagementUpdate
