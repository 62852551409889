import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import FxRate from './FxRate'
import useGetFxRate from './hooks/useGetFxRate'

const STARTED_FROM_VALUE = 100
const DESTINATIONS = {
    from: 'from',
    to: 'to',
}
const calcTo = (from, exchangeRate) => {
    return Number((from * exchangeRate).toFixed(2))
}
const calcFrom = (to, exchangeRate) => {
    return Number((to * (1 / exchangeRate)).toFixed(2))
}
const recalculate = (value, exchangeRate, calculateFrom) => {
    if (calculateFrom) {
        return calcFrom(value, exchangeRate)
    }
    return calcTo(value, exchangeRate)
}

type Props = {
    fxRateId?: string | number,
    keepInput?: boolean,
}

const defaultProps: Partial<Props> = {
    fxRateId: null,
    keepInput: false,
}

const FxRateContainer = ({
    fxRateId,
    keepInput,
}: Props) => {
    const [
        state,
        setState,
    ] = useState({
        lastInput: {
            origin: DESTINATIONS.from,
            calculated: DESTINATIONS.to,
            value: STARTED_FROM_VALUE,
        },
        lastExchangeRate: null,
    })

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetFxRate(fxRateId)

    useEffect(() => {
        if (data?.exchangeRate !== state.lastExchangeRate) {
            if (!keepInput) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        lastInput: {
                            origin: DESTINATIONS.from,
                            calculated: DESTINATIONS.to,
                            value: STARTED_FROM_VALUE,
                        },
                        lastExchangeRate: data.exchangeRate as number,
                    }
                })
            }
        }
    }, [
        data?.exchangeRate,
        state.lastExchangeRate,
        keepInput,
    ])

    const onChange = useCallback((val, {
        target: {
            name,
        },
    }) => {
        setState({
            ...state,
            lastInput: name === DESTINATIONS.from ? {
                origin: DESTINATIONS.from,
                calculated: DESTINATIONS.to,
                value: val,
            } : {
                origin: DESTINATIONS.to,
                calculated: DESTINATIONS.from,
                value: val,
            },
            lastExchangeRate: data?.exchangeRate as number,
        })
    }, [
        state,
        data?.exchangeRate,
    ])

    const exchangeValues = useMemo(() => {
        const {
            value,
            origin,
            calculated,
        } = state.lastInput

        return {
            [origin]: value,
            [calculated]:
                recalculate(value, data?.exchangeRate, calculated === DESTINATIONS.from),
        }
    }, [
        data?.exchangeRate,
        state.lastInput,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FxRate
                exchangeValues={exchangeValues}
                onChange={onChange}
                value={data}
            />
        </StatusHandler>
    )
}

FxRateContainer.defaultProps = defaultProps

export default FxRateContainer
