import {
    makeStyles,
} from 'app/tss'

const reason = 'reason'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `"${reason}"`,
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        reason: {
            gridArea: reason,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    }
})

export default useStyles
