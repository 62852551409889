import {
    FormPart,
} from 'app/types/form.types'
import {
    LeaseType, OrderTemperatureRange,
} from 'app/types/enums'
import {
    CollectionServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

type Values = {
    tempRange?: string,
    leaseType?: string,
    deliveryServiceType?: string,
    collectionServiceType?: string,
}

const generalInfoFields = ({
    getAllowedValues,
}): FormPart<keyof Values>[] => {
    return [{
        title: 'General Information',
        className: 'general',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'tempRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(OrderTemperatureRange),
                    disabled: true,
                }],
            },
            {
                id: 'column2',
                fields: [{
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                }],
            },
            {
                id: 'column3',
                fields: [{
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                }],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'collectionServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(CollectionServiceType),
                }],
            },
        ],
    }]
}

export default generalInfoFields
