import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    RegionZeroLevel,
    RegionFirstLevel,
    RegionSecondLevel,
} from 'app/types/enums'
import {
    Country,
} from '../Countries.types'

const countryOverviewFields = (getAllowedValues): DTOCardFormField<keyof Country>[] => {
    return [{
        id: 'country',
        title: 'Country',
        fields: [
            {
                name: 'countryName',
                componentName: 'InputSingleline',
                labelKey: 'Country.countryName',
            },
            {
                name: 'countryIsoAlpha2Code',
                componentName: 'InputSingleline',
                labelKey: 'Country.countryIsoAlpha2Code',
            },
            {
                name: 'zipCodePattern',
                componentName: 'InputSingleline',
                disabled: true,
                labelKey: 'Country.zipCodePattern',
            },
            {
                name: 'zipCodePatternDescription',
                componentName: 'InputSingleline',
                disabled: true,
                labelKey: 'Country.zipCodePatternDescription',
            },
            {
                name: 'regionZeroLevel',
                labelKey: 'Country.regionZeroLevel',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(RegionZeroLevel),
                disabled: true,
            },
            {
                name: 'regionFirstLevel',
                labelKey: 'Country.regionFirstLevel',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(RegionFirstLevel),
                disabled: true,
            },
            {
                name: 'regionSecondLevel',
                labelKey: 'Country.regionSecondLevel',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(RegionSecondLevel),
                disabled: true,
            },
        ],
    }]
}

export default countryOverviewFields
