import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        cardContent: {
            height: 700,
            marginTop: theme.spacing(3),
        },
    }
})

export default useStyles
