import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    OrderFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getFreightSelectionFields = (disabled: boolean): DTOCardFormField<OrderFields>[] => {
    return [{
        title: 'General Freight',
        className: 'generalFreight',
        id: 'generalFreight',
        fields: [{
            name: 'generalFreight',
            componentName: 'GeneralFreight',
            disabled,
            labelKey: '',
        }],
    }]
}

export default getFreightSelectionFields
