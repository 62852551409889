import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
        },
        iconWrapper: {
            height: theme.typography.h2.lineHeight,
            padding: theme.spacing(0, 0.5, 0, 0),
            display: 'flex',
            alignItems: 'center',
        },
        tempImg: {
            width: theme.spacing(2),
            height: 28,
        },
    }
})

export default useStyles
