import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import AlLogisticPricingCreate from './AlLogisticPricingCreate'
import AlLogisticPricingOverview from './AlLogisticPricingOverview'

import tableConfig from './AlLogisticPricing.config'
import request from './AlLogisticPricing.requests'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const AlLogisticPricing = () => {
    const tabs: SidebarTab[] = [{
        url: 'al-logistic-pricing',
        label: 'Al Logistic Pricing',
        Component: AlLogisticPricingOverview,
    }]

    return (
        <SkyNetTable
            name={tableConfig.name}
            createForm={AlLogisticPricingCreate}
            tabs={tabs}
            moduleName={request.domainName}
            defaultTab="data"
            tableControls={tableControls}
            tableConfig={tableConfig}
        />
    )
}

export default AlLogisticPricing
