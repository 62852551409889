import React from 'react'

import Card from 'app/shared-components/Card'
import TextSingleline from 'app/shared-components/TextSingleline'
import StatusHandler from 'app/shared-components/StatusHandler'

import useStyles from './BillingCompanyOverview.styles'
import useGetBillingCompany from '../hooks/useGetBillingCompany'

const Overview = ({
    id,
}: { id: number }) => {
    const {
        classes,
    } = useStyles()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetBillingCompany(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            { data?.id
                ? (
                    <div className={classes.container}>
                        <Card
                            title="Location"
                        >

                            <TextSingleline
                                title="Location Name"
                                value={data?.billingLocationName}
                            />
                        </Card>
                    </div>
                )
                : null }
        </StatusHandler>
    )
}

export default Overview
