import {
    useEffect,
} from 'react'

const useIntersectionObserver = ({
    intersectionCallback, el,
}:{
    intersectionCallback: () => void,
    el: Element
}) => {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                intersectionCallback()
            }
        })

        if (el) {
            observer.observe(el)
        }

        return () => {
            if (el) {
                observer.unobserve(el)
            }
        }
    }, [
        el,
        intersectionCallback,
    ])
}

export default useIntersectionObserver
