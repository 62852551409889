const injectSort = (cb) => {
    return (params) => {
        const {
            sort,
        } = params

        return cb({
            ...params,
            sort: sort || {
                field: 'changedOn',
                direction: 'desc',
            },
        })
    }
}

export default injectSort
