import {
    useEffect, useState,
} from 'react'

export default (isFetching) => {
    const [
        loaded,
        setLoaded,
    ] = useState(false)

    useEffect(() => {
        if (!isFetching) {
            setLoaded(true)
        }
    }, [isFetching])

    return loaded
}
