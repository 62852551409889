import React from 'react'

import useLabel from 'app/hooks/useLabel'
import SimpleTable from 'app/shared-components/SimpleTable'
import getUniqueId from 'app/utils/getUniqueId'
import {
    GeneralFreight as GeneralFreightType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import Inputs from './Inputs'

type Props = {
    value?: GeneralFreightType[],
    deleteEntry: (generalFreightId: number | string) => void,
    onChange: (newVal: GeneralFreightType) => void,
    addEntry: () => void,
    disabled?: boolean,
}

const defaultProps = {
    value: undefined,
    disabled: false,
}

const GeneralFreight = ({
    value,
    onChange,
    addEntry,
    deleteEntry,
    disabled,
}: Props) => {
    const l = useLabel()

    const columns: {
        label: string,
        fieldName: string,
        isRequired: boolean,
        id: number,
    }[] = [
        {
            label: l('GeneralFreight.material'),
            fieldName: 'material',
            isRequired: true,
            id: getUniqueId(),
        },
        {
            label: l('GeneralFreight.description'),
            fieldName: 'description',
            isRequired: false,
            id: getUniqueId(),
        },
        {
            label: l('GeneralFreight.quantity'),
            fieldName: 'quantity',
            isRequired: true,
            id: getUniqueId(),
        },
        {
            label: l('GeneralFreight.unit'),
            fieldName: 'unit',
            isRequired: true,
            id: getUniqueId(),
        },
        {
            label: l('GeneralFreight.weightKg'),
            fieldName: 'weightKg',
            isRequired: true,
            id: getUniqueId(),
        },
        {
            label: l('GeneralFreight.dimensions'),
            fieldName: 'dimensions',
            isRequired: true,
            id: getUniqueId(),
        },
    ]

    return (
        <SimpleTable
            name="GeneralFreight"
            columns={columns}
            data={value}
            component={Inputs}
            addEntry={addEntry}
            onChange={onChange}
            onDelete={deleteEntry}
            disabled={disabled}
        />
    )
}

GeneralFreight.defaultProps = defaultProps

export default GeneralFreight
