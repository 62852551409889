import {
    RequestConfig,
} from 'app/types/request.types'

type LoggerFormRequests = {
    request: ({
        serialNumber,
    }: { serialNumber: string }) => RequestConfig,

}

const loggerFormRequests: LoggerFormRequests = {
    request: ({
        serialNumber,
    }) => {
        return {
            method: 'POST',
            url: `container/${serialNumber}/logger-pairing`,
            domainName: 'LoggerInformationDto',
        }
    },
}

export default loggerFormRequests
