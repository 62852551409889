import React, {
    useEffect,
    useState,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import FormWithControls from 'app/shared-components/FormWithControls'
import {
    SharedPositioningType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import sharedPositioningRequest from './sharedPositioning.request'
import getFields from './sharedPositioning.fields'
import useStyles from './SharedPositioning.style'
import useGetSharePositioning from './hooks/useGetSharePositioning'

const SharedPositioning = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        classes,
    } = useStyles()

    const [
        value,
        setValue,
    ] = useState<{sharedPositioning: SharedPositioningType[]}>()
    const {
        sharedPositioning,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetSharePositioning(orderNumber)

    useEffect(() => {
        setValue({
            sharedPositioning,
        })
    }, [sharedPositioning])

    const modifyDataBeforeSend = (requestData: {sharedPositioning: SharedPositioningType[]}) => {
        return {
            sharedPositioning: requestData.sharedPositioning?.map(({
                id,
            }) => {
                return {
                    id,
                }
            }),
        }
    }

    return (
        <StatusHandler
            isSuccess={isDataReady && Boolean(value)}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                value={value}
                name="SharedPositioning"
                fields={getFields(orderNumber)}
                requestParams={sharedPositioningRequest.Create.requestFn({
                    orderNumber,
                })}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                modifyDataBeforeSend={modifyDataBeforeSend}
                exists
            />
        </StatusHandler>
    )
}

export default SharedPositioning
