import simpleApi from 'app/services/simpleApi'

const internalTransportRequest = {
    all: {
        url: 'order/filter-request/all-internal-transport',
    },
    Create: {
        mutationFn: ({
            data,
            token,
        }) => {
            return simpleApi({
                url: 'order/internal-transport',
                data,
                method: 'POST',
                token,
            })
        },
    },
}

export default internalTransportRequest
