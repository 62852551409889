import {
    getUniqFieldId,
} from 'app/Apps/DomainObject'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    SkyNetTableField,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const deliveryViaRoadFields: SkyNetTableField<DailyOpsFields>[] = [
    {
        name: 'orderNumber',
        filterField: 'orderNumber',
        filterType: FilterType.Array,
        width: '100px',
    },
    {
        name: 'from',
        filterField: 'from',
        filterType: FilterType.Array,
        width: '100px',
    },
    {
        name: 'to',
        filterField: 'to',
        filterType: FilterType.Array,
        width: '100px',
    },
    {
        name: 'customerLocationName',
        filterField: 'customerLocationName',
        filterType: FilterType.Array,
    },
    {
        name: 'pricingAccountCompanyName',
        filterField: 'pricingAccountCompanyName',
        labelKey: 'Prebooking.pricingAccount',
        filterType: FilterType.Array,
    },
    {
        name: 'mawbNumber',
        filterField: 'mawbNumber',
        filterType: FilterType.Array,
    },
    {
        name: 'deliveryServiceType',
        filterField: 'deliveryServiceType',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        allowedValuesEnum: DeliveryServiceType,
    },
    {
        name: 'requestedHandover',
        filterField: 'requestedHandover',
        filterType: FilterType.DateRange,
        headerProps: {
            children: 'Requested Handover',
        },
    },
    {
        name: 'requestedHandover',
        id: getUniqFieldId('requestedHandover', 1),
        componentName: 'ContainerSelectionUntil',
        headerProps: {
            children: 'Requested Handover In',
        },
        sorting: false,
    },
]

export default deliveryViaRoadFields
