import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            margin: theme.spacing(0, -2),
        },
        container: {
            '&:first-of-type': {
                marginTop: theme.spacing(1.5),
                borderTop: `1px solid ${theme.palette.secondary[200]}`,
            },
            padding: `${theme.spacing(1.5)} 0 ${theme.spacing(1.5)} ${theme.spacing(2)}`,
            borderBottom: `1px solid ${theme.palette.secondary[200]}`,
            display: 'flex',
            alignItems: 'center',
        },
        iconStatus: {
            paddingRight: theme.spacing(2),
        },
        iconType: {
            paddingRight: theme.spacing(1),
            width: 50,
            height: 25,
        },
        text: {
            color: theme.palette.secondary[600],
        },
        wrapper: {
            paddingLeft: 0,
            paddingRight: 0,
            height: '100%',
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
    }
})

export default useStyles
