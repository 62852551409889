import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        loadingWrapper: {
            '&&': {
                position: 'relative !important',
                color: theme.palette.common.white,
            },
        },
        iconWrapper: {
            '&&': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        text: {
            color: theme.palette.common.white,
        },
    }
})

export default useStyles
