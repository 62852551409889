import {
    useCallback,
} from 'react'

import {
    useAppDispatch,
} from 'app/store/hooks'
import {
    addToDomainConfigs,
} from 'app/store/SpreadSheetConfigs'
import {
    SpreadsheetMappingResponse,
} from '../SkyNetSpreadSheet.requests'
import {
    Domain,
} from '../SkyNetSpreadSheet.types'

export default ({
    configId,
}: {
    configId: string,
}) => {
    const dispatch = useAppDispatch()

    return useCallback((mappedResults: Map<Domain, SpreadsheetMappingResponse>, field: string) => {
        Array.from(mappedResults, ([
            domain,
            results,
        ]) => {
            return dispatch(addToDomainConfigs({
                name: configId,
                domain,
                field,
                params: {
                    options: results.map(({
                        id, label,
                    }) => {
                        return {
                            id, label, textLabel: label, value: id,
                        }
                    }),
                },
            }))
        })
    }, [
        configId,
        dispatch,
    ])
}
