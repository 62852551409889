const removeUnauthorizedField = (fields, fieldToRemove, auth) => {
    if (!auth) {
        return fields.filter((currentField) => {
            return !fieldToRemove.includes(currentField.name)
        })
    }
    return fields
}

export default removeUnauthorizedField
