import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {},
    }
})

export default useStyles
