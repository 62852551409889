/* eslint-disable import/prefer-default-export */
const fieldErrorMessage = {
    required: 'This field is required',
    isEMAIL: 'Please enter a valid E-Mail address',
    isURL: 'Please enter a valid URL Address',
}

export function checkValidationError(errors, field) {
    if (errors?.[field] !== undefined) {
        return fieldErrorMessage[errors[field]]
    }

    return ''
}
