import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    keyCodes, Compatible, inNumericKey, isNavigationKey,
} from '@silevis/reactgrid'
import moment from 'moment'
import {
    KeyboardKeys,
} from 'app/types/common.enums'
import {
    DateTimeCell as DateTimeCellType,
} from './DateTimeCellTemplate'
import useStyles from './DateTimeCell.styles'

const defaultProps = {
    defaultDate: Date.now(),
}

const DateTimeCell = ({
    cell,
    onBlur,
    //  eslint-disable-next-line @typescript-eslint/no-unused-vars
    wasEscKeyPressed,
    defaultDate,
}: {
    cell: Compatible<DateTimeCellType>,
    onBlur: (...args: any[]) => void,
    wasEscKeyPressed: boolean,
    defaultDate?: Date
}) => {
    const {
        classes,
    } = useStyles()
    const [
        state,
        setState,
    ] = useState<string>(moment(cell.date || defaultDate).format('YYYY-MM-DDTHH:mm'))

    const inputRef = useRef(null)
    const stopPropagation = useCallback((e) => { return e.stopPropagation() }, [])
    const handleKeyDown = useCallback((e) => {
        if (
            inNumericKey(e.keyCode)
            || isNavigationKey(e.keyCode)
            || e.key === KeyboardKeys.COMMA
            || e.key === KeyboardKeys.PERIOD
            || ((e.ctrlKey || e.metaKey) && e.key === KeyboardKeys.A)
        ) {
            e.stopPropagation()
        }
        if (!inNumericKey(e.keyCode) && !isNavigationKey(e.keyCode)
            && (e.keyCode !== keyCodes.COMMA && e.keyCode !== keyCodes.PERIOD)) {
            e.preventDefault()
        }
        // eslint-disable-next-line
        if (e.keyCode === keyCodes.ESCAPE) wasEscKeyPressed = true
    }, [])

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus()

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (inputRef.current) inputRef.current.blur()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <input
            ref={inputRef}
            data-testid="datetimeInput"
            className={classes.dateTimeInput}
            type="datetime-local"
            value={state}
            onChange={(e) => {
                setState(e?.currentTarget?.value)
            }}
            onBlur={(e) => {
                const value = e?.currentTarget?.value

                if (value) {
                    onBlur(moment(value).toDate())
                    return
                }

                onBlur(null)
            }}
            onKeyDown={handleKeyDown}
            onCopy={stopPropagation}
            onCut={stopPropagation}
            onPaste={stopPropagation}
            onPointerDown={stopPropagation}
        />
    )
}

DateTimeCell.defaultProps = defaultProps

export default DateTimeCell
