import {
    RequestConfig,
} from 'app/types/request.types'
import {
    Domain,
} from './SkyNetSpreadSheet.types'

export type SpreadsheetMappingRequest ={
    domain: Domain,
    data: string[]
}

export type SpreadsheetMappingResponse = {
    id: number,
    label: string
}[]

type SkyNetSpreadSheetRequests = {
    getSpreadsheetMapping: ({
        domain, data,
    }: SpreadsheetMappingRequest) => RequestConfig
}

const requests: SkyNetSpreadSheetRequests = {
    getSpreadsheetMapping: ({
        domain,
        data,
    }) => {
        return {
            url: `${domain}/spreadsheet/mapping-data`,
            method: 'POST',
            data,
        }
    },
}

export default requests
