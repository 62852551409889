import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        card: {
            background: theme.palette.common.white,
            boxShadow: `0px 1px 3px ${theme.palette.secondary.main}`,
            borderRadius: 4,
            height: 'auto',
            overflow: 'visible !important',
            position: 'relative',
        },
        fullHeight: {
            '&&': {
                height: '100%',
            },
        },
        title: {
            '&&': {
                fontWeight: '400',
                fontSize: `${theme.typography.h3.fontSize}px !important`,
                letterSpacing: theme.typography.h3.letterSpacing,
                color: theme.palette.secondary.dark,
                display: 'flex',
            },
        },
        icon: {
            padding: `${theme.spacing(0.625)} 0 0 ${theme.spacing(0.625)}`,
            width: 30,
            height: 26,
            position: 'relative',
            top: 4,
        },
        loading: {
            position: 'relative',
            marginLeft: 6,
            width: 15,
        },
    }
})

export default useStyles
