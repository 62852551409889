import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import LocationForm from '../LocationForm'
import {
    useGetLocation, useUpdateLocation,
} from '../hooks'

type Props = {
    id: string | number,
}

const LocationUpdate = ({
    id,
}: Props) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLocation(id)

    const updateLocation = useUpdateLocation({
        onSuccess: invalidate,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <LocationForm
                action={updateLocation}
                data={data}
                onCancel={invalidate}
            />
        </StatusHandler>
    )
}

export default LocationUpdate
