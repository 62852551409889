import React, {
    useCallback,
    useState,
} from 'react'

import IconButton from '@mui/material/IconButton'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import InputSingleline from 'app/shared-components/InputSingleline'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import useShareContext from 'app/shared-components/SharedContext/useSharedContext'

import useQuotePdfDescriptionEdit from './hooks/useQuotePdfDescriptionEdit'

type Props = {
    rowProps: {
        id: number,
        description: string
    }
}
type WithPriningNameType = {
    quoteId: number,
}

const QuotePdfDelete = ({
    rowProps,
}: Props) => {
    const {
        id: documentId,
        description,
    } = rowProps

    const {
        quoteId,
    } = useShareContext<WithPriningNameType>()
    const [
        descriptionValue,
        setDescriptionValue,
    ] = useState(description)

    const {
        selectDomainObject,
    } = useDomainObjectContext()

    const onSuccess = useCallback(() => {
        selectDomainObject(quoteId)
    }, [
        quoteId,
        selectDomainObject,
    ])

    const {
        mutate: updateQuotePdfDescription,
    } = useQuotePdfDescriptionEdit(onSuccess)

    const onConfirm = useCallback((positive) => {
        if (positive) {
            updateQuotePdfDescription({
                quoteId,
                documentId,
                description: descriptionValue,
            })
        }
        setConfirmation(false)
    }, [
        descriptionValue,
        quoteId,
        documentId,
        updateQuotePdfDescription,
    ])

    const [
        confirmation,
        setConfirmation,
    ] = useState(false)

    return (
        <>
            <IconButton
                onClick={() => {
                    return setConfirmation(true)
                }}
            >
                {!description ? (
                    <img
                        src="assets/images/note_new.svg"
                        alt="info"
                    />
                ) : (
                    <img
                        src="assets/images/note.svg"
                        alt="info"
                    />
                )}
            </IconButton>
            <ConfirmationDialog
                open={confirmation}
                handleClose={onConfirm}
                dialogTitle="Add Description"
                positiveLabel="Save"
                negativeLabel="Cancel"
            >
                <InputSingleline
                    multiline
                    title="Description"
                    value={descriptionValue}
                    required
                    onChange={setDescriptionValue}
                    rows={3}
                />
            </ConfirmationDialog>
        </>
    )
}

export default QuotePdfDelete
