import React from 'react'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'

import config from './Contacts.request'
import Contacts from './Contacts'
import {
    Contact,
} from '../Contacts/Contacts.types'

type Props = {
    isWrapped: boolean,
    value: number,
}

const ContactsContainer = ({
    isWrapped,
    value,
}: Props) => {
    const {
        data,
        onParamsChange,
    } = useRequestTable<{ data:Contact[]}>({
        key: RequestKeys.getLocationContacts,
        params: {
            id: value,
        },
        config: config.get,
    })

    return (
        <Contacts
            isWrapped={isWrapped}
            value={data?.data}
            onParamsChange={onParamsChange}
        />
    )
}

export default ContactsContainer
