import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'
import noop from 'lodash/noop'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from './attachment.request'

const useUploadFile = (onSuccess: (id: number) => void = noop) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessHandler,
        onError,
        onStartSaving,
    } = useHandleStatusRequest({
        id: 'Attachment',
        onSuccess,
    })

    const {
        mutateAsync: uploadFile,
    } = useMutation({
        mutationFn: (data: FormData) => {
            onStartSaving()
            return request.mutationFn({
                token,
                data,
            }).then((id) => {
                return id
            })
        },
        onSuccess: onSuccessHandler,
        onError,
    })

    return uploadFile
}

export default useUploadFile
