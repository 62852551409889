import get from 'lodash/get'

const getLabelKeyValue = ({
    name, l, labelEntityName, labelKey, id,
}) => {
    let lableKeyLocal = `${labelEntityName || name}.${id}`

    if (labelKey) {
        lableKeyLocal = labelKey.indexOf('.') > 0 ? labelKey
            : `${labelEntityName || name}.${labelKey}`
    }

    return (lableKeyLocal && l(lableKeyLocal))
}
const replaceLabels = ({
    name,
    l,
    labelEntityName,
}:{
    name: string, l: (key: string) => void, labelEntityName?: string
}) => {
    return (columns: {id: string, labelKey?: string, }[]) => {
        return columns.map((col) => {
            const [
                model, // eslint-disable-line @typescript-eslint/no-unused-vars
                id,
            ] = col.id.split('.')

            const label = get(col, 'headerProps.children') || getLabelKeyValue({
                name, l, labelEntityName, labelKey: col.labelKey, id,
            })

            const oldHeaderProps = get(col, 'headerProps', {})

            return {
                ...col,
                headerProps: {
                    oldHeaderProps,
                    children: label,
                },
            }
        })
    }
}

export default replaceLabels
