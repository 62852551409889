import React from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    useGetFxRate,
} from 'app/shared-components/FxRate'
import StatusHandler from 'app/shared-components/StatusHandler'
import DTOSpec from 'app/services/dtoSpec'

import FxRatesRequests from '../FxRates.request'
import components from '../FxRate.fields'
import useStyles from '../FxRates.styles'

type Props = {
    id: number,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    disabled: false,
}

const ChangeData = ({
    id,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const fields = useFieldsWithClassName(components(getAllowedValues), classes)
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetFxRate(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                name={DTOSpec.FxRate.Update.refresh}
                value={data}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                fields={fields}
                requestParams={FxRatesRequests.update.request()}
                exists
                disabled={disabled}
                onSuccess={invalidate}
            />
        </StatusHandler>
    )
}

ChangeData.defaultProps = defaultProps

export default ChangeData
