import React, {
    useCallback,
} from 'react'

import Typeahead from 'app/shared-components/Typeahead'

import useLaneRequest from './hooks/useLaneRequest'
import useGetLane from './hooks/useGetLane'

type Props = {
    value?: {
        id: number,
    },
    title?: string,
    disabled?: boolean,
    required?: boolean,
    name?: string,
    onChange: (value: { id: number }) => void,
}

const defaultProps : Partial<Props> = {
    name: '',
    value: undefined,
    title: 'Lanes',
    disabled: false,
    required: false,
}

const LaneSelector = ({
    name,
    value,
    onChange,
    title,
    disabled,
    required,
}: Props) => {
    const {
        loadOptions,
    } = useLaneRequest()

    const {
        data: labelData,
    } = useGetLane(value?.id)

    const onChangeValue = useCallback((newValue) => {
        const convertedValue = newValue ? {
            id: newValue,
        } : undefined

        onChange(convertedValue)
    }, [onChange])

    return (
        <Typeahead
            name={name}
            value={value?.id}
            onChange={onChangeValue}
            title={title}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="laneNumber"
            disabled={disabled}
            isRequired={required}
            variant="standard"
        />
    )
}

LaneSelector.defaultProps = defaultProps

export default LaneSelector
