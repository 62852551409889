import {
    FieldInCard,
} from 'app/types/form.types'

import {
    Note,
} from '../../../ContainerNotes.types'
import {
    AuditTrailConfig, ChangeConfig,
} from './notesConfigs'

type Fields = keyof Note

const fields: FieldInCard<Fields>[] = [
    ChangeConfig,
    AuditTrailConfig,
]

export default fields
