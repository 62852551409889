const getOptions = ({
    categories,
    customTooltip,
    dryStorageCapacity,
}: {
    categories: string[],
    customTooltip: (args: any) => string,
    dryStorageCapacity: number,
}) => {
    const defineColor = ({
        value,
    }) => {
        return Math.round((value / (dryStorageCapacity || 100)) * 100) <= 100 ? '#EDAE49' : '#D44848'
    }

    return {
        legend: {
            horizontalAlign: 'left',
        } as ApexLegend,
        tooltip: {
            custom: customTooltip,
        },
        yaxis: [{
            labels: {
                formatter(val: number) {
                    return `${Math.round((val / (dryStorageCapacity || 100)) * 100)}%` // to avoid error if dryStorageCapacity is zero
                },
            },
        }],
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: true,
                offsetX: -60,
                offsetY: 0,
                tools: {
                    download: '<img src="/assets/images/download_button.svg" width="24px" height="24px" alt="download"/>',
                },
            },
        },
        xaxis: {
            categories,
        },
        colors: [defineColor],
        dataLabels: {
            enabled: false,
        },
        grid: {
            padding: {
                right: 70,
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
            strokeDashArray: 7,
        },
    }
}

export default getOptions
