import {
    useMemo,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/OrderManagement/Orders/CustomerTransport/Invoices/invoce.request'
import AllPositionTypeCodes from 'app/types/positionTypeCode.enum'

type PositionTypeMappingType = {
    CANCELLATION: AllPositionTypeCodes[],
    DAMAGE: AllPositionTypeCodes[],
    FEE: AllPositionTypeCodes[],
    LEASE: AllPositionTypeCodes[],
    LOSS: AllPositionTypeCodes[],
}

const useGetPositionTypeMapping = () => {
    const {
        t,
    } = useTranslation()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<PositionTypeMappingType>({
        key: RequestKeys.getPositionTypeMapping,
        requestFunc: RequestFn.getEntity(requests.GetPositionTypeMapping.requestFn),
    })

    const positionTypeMappingLabels = useMemo(() => {
        if (!data) {
            return {}
        }
        return Object.fromEntries(Object.entries(data).map(([
            key,
            value,
        ]) => {
            const newVal = value.reduce((acc, el) => {
                const label = t(el, {
                    ns: 'enums',
                })

                return {
                    ...acc,
                    [el]: label || el,
                }
            }, {})

            return [
                key,
                newVal,
            ]
        }))
    }, [
        data,
        t,
    ])

    return {
        positionTypeMapping: positionTypeMappingLabels,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetPositionTypeMapping
