import {
    useState, useEffect, useMemo,
} from 'react'
import isEqual from 'lodash/isEqual'
import {
    SpreadsheetCellOption, TypeaheadParams,
} from 'app/shared-components/SkyNetSpreadSheet/SkyNetSpreadSheet.types'
import {
    FilterState,
} from 'app/hooks/useFilter'
import {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'

export default ({
    load,
    filters,
    isFilterRequired,
}: {
    load: (options: DelayedLoadOptions) => Promise<any>,
    filters?: FilterState,
    isFilterRequired?: boolean
}):TypeaheadParams => {
    const [
        options,
        setOptions,
    ] = useState<SpreadsheetCellOption[]>([])

    const [
        filter,
        setFilter,
    ] = useState(filters)

    useEffect(() => {
        async function loadOptions() {
            const result = await load({
                phrase: '',
            }) as SpreadsheetCellOption[]

            setOptions(result)
        }

        if ((!options.length && !isFilterRequired)
            || (filters && Object.values(filters).length && !isEqual(filters, filter))
        ) {
            loadOptions()
            setFilter(filters)
        }
    }, [
        filter,
        filters,
        load,
        options.length,
        isFilterRequired,
    ])

    return useMemo(() => {
        return {
            loadOptions: load,
            options,
        }
    }, [
        load,
        options,
    ])
}
