import {
    LocationType,
} from 'app/types/enums'
import {
    FieldInCard,
    FormPart,
} from 'app/types/form.types'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import {
    DeliveryServiceType,
    HandoverTime,
    Lane,
} from '../../lanes.types'

const positioningFields = ({
    deliveryServiceType,
    getAllowedValues,
}: {
    deliveryServiceType: DeliveryServiceType,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
}): FormPart<keyof Lane> => {
    const isNotPickupFields: FieldInCard<keyof Lane>[] = [{
        id: 'positioning3',
        fields: [
            {
                name: 'fwdPos',
                componentName: 'LocationObjectSelector',
                labelKey: 'LaneOpsInformationDto.fwdPos',
            },
            {
                name: 'fwdPosContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'LaneOpsInformationDto.fwdPosContacts',
            },
            {
                name: 'fwdPosBackup',
                componentName: 'LocationObjectSelector',
                labelKey: 'LaneOpsInformationDto.fwdPosBackup',
            },
            {
                name: 'fwdPosBackupContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'LaneOpsInformationDto.fwdPosBackupContacts',
            },
        ],
    }]

    return {
        title: 'Positioning',
        className: 'positioning',
        fields: [
            {
                id: 'positioning1',
                fields: [
                    {
                        name: 'handoverEarliest',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(HandoverTime),
                        labelKey: 'LaneOpsInformationDto.handoverEarliest',
                    },
                    {
                        name: 'handoverLatest',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(HandoverTime),
                        labelKey: 'LaneOpsInformationDto.handoverLatest',
                    },
                    {
                        name: 'containerSelectionMax',
                        componentName: 'IntegerInput',
                        labelKey: 'LaneOpsInformationDto.containerSelectionMax',
                    },
                    {
                        name: 'orderInstructions',
                        componentName: 'LongText',
                        labelKey: 'LaneOpsInformationDto.orderInstructions',
                    },
                ],
            },
            {
                id: 'positioning2',
                fields: [
                    {
                        name: 'originSc',
                        componentName: 'LocationObjectSelector',
                        filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                        filterLocationType: LocationType.ADDRESS,
                        labelKey: 'LaneOpsInformationDto.originSc',
                    },
                    {
                        name: 'originScContacts',
                        componentName: 'MultipleContactObjectSelector',
                        labelKey: 'LaneOpsInformationDto.originScContacts',
                    },
                    {
                        name: 'originScBackup',
                        componentName: 'LocationObjectSelector',
                        filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                        filterLocationType: LocationType.ADDRESS,
                        labelKey: 'LaneOpsInformationDto.originScBackup',
                    },
                    {
                        name: 'originScBackupContacts',
                        componentName: 'MultipleContactObjectSelector',
                        labelKey: 'LaneOpsInformationDto.originScBackupContacts',
                    },
                ],
            },
            ...deliveryServiceType === DeliveryServiceType.PICKUP ? [] : isNotPickupFields,
        ],
    }
}

export default positioningFields
