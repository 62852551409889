import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import {
    RequestData,
} from '../containerSelection.types'
import configRequest from '../containerSelection.request'

type RequestParams = {
    data: RequestData,
    orderNumber: string,
    isGeneralFreight: boolean,
}

const useSaveContainerSelection = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessUpdate,
        onError,
    } = useHandleStatusRequest({
        id: 'SaveContainerSelection',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            data,
            orderNumber,
            isGeneralFreight,
        }: RequestParams) => {
            return isGeneralFreight
                ? configRequest.ModifyGeneralFreights.mutationFn({
                    orderNumber,
                    data,
                    token,
                }) : configRequest.ModifyRequiredContainers.mutationFn({
                    orderNumber,
                    data,
                    token,
                })
        },
        onSuccess: onSuccessUpdate,
        onError,
    })
}

export default useSaveContainerSelection
