import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import {
    App,
} from 'app/types/common.enums'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import tableConfig from './lanes.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Lanes = () => {
    const history = useHistory()
    const onRowClick = useCallback(({
        laneGroupId, id,
    }) => {
        history.push(`/apps/${App.PRICING}/lane-groups/all/edit/${laneGroupId}/lanes/${id}`)
    }, [history])

    return (
        <SkyNetTable
            name="LaneOpsOverView"
            onRowClick={onRowClick}
            tableControls={tableControls}
            tableConfig={tableConfig}
            customUrl="lane/filter-request/cs-overview"
            showSearch
        />
    )
}

export default Lanes
