import React, {
    ChangeEvent, useCallback, useMemo,
} from 'react'
import Pagination, {
    PaginationRenderItemParams,
} from '@mui/material/Pagination'
import Card from '@mui/material/Card'
import PaginationItem from '@mui/material/PaginationItem'
import useStyles from './SkyNetPagination.style'

export enum SkyNetPaginationMode {
    SIMPLE,
    NUMERICAL
}

const defaultProps = {
    total: 0,
    rowsPerPage: 1,
    mode: SkyNetPaginationMode.NUMERICAL,
    page: 1,
    onChange: undefined,
}

const SkyNetPagination = ({
    total,
    rowsPerPage,
    mode,
    page,
    onChange,
}: Readonly<{
    total?: number,
    rowsPerPage?: number,
    mode?: SkyNetPaginationMode,
    page?: number,
    onChange: (e: ChangeEvent, page: number) => void,
}>) => {
    const {
        classes,
    } = useStyles()

    const count = Number.parseInt(String(total / rowsPerPage), 10)
        + (total % rowsPerPage > 0 ? 1 : 0)

    const label = useMemo(() => {
        if (total === 0) {
            return '0 of 0'
        }

        const prefix = mode === SkyNetPaginationMode.NUMERICAL ? 'Records ' : ''

        const first = page * rowsPerPage - (rowsPerPage - 1)
        const last = (page * rowsPerPage) < total ? (page * rowsPerPage) : total

        if (first === last) return `${prefix}${first} of ${total}`
        return `${prefix}${first} - ${last} of ${total}`
    }, [
        mode,
        page,
        rowsPerPage,
        total,
    ])

    const renderItem = useCallback((item: PaginationRenderItemParams) => {
        if (mode === SkyNetPaginationMode.SIMPLE && ![
            'previous',
            'next',
        ].includes(item.type)) {
            return null
        }

        return (
            <PaginationItem
                {...item} // eslint-disable-line react/jsx-props-no-spreading
                className={classes.pgItem}
            />
        )
    }, [
        classes.pgItem,
        mode,
    ])

    return (
        <Card className={classes.container}>
            <span className={classes.label}>{label}</span>
            <Pagination
                count={count}
                page={page}
                onChange={onChange}
                renderItem={renderItem}
                inputMode="numeric"
                size="small"
                variant="outlined"
                classes={{
                    ul: classes.pgUl,
                }}
            />
        </Card>

    )
}

SkyNetPagination.defaultProps = defaultProps

export default SkyNetPagination
