import React, {
    useState,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'

import fields from './preBookingCommentCreateForm.fields'
import requestConfig from '../preBookingComment.request'
import useStyles from './PreBookingCommentCreateForm.style'

const PreBookingCommentCreateForm = ({
    id,
    onCancel,
    onSuccess,
}: {
    onSuccess: (...args: any[]) => void,
    onCancel: (...args: any[]) => void,
    id: number,
}) => {
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState<{comment: string}>(null)

    const onSuccessCreate = () => {
        setValue(null)
        onSuccess()
    }

    return (
        <FormWithControls
            name={requestConfig.domainName}
            fields={fields}
            value={value}
            setExternalValue={setValue}
            onCancel={onCancel}
            onSuccess={onSuccessCreate}
            requestParams={requestConfig.CreatePreBookingComment({
                id,
            })}
            className={classes.root}
            cancelEnabled
        />
    )
}

export default PreBookingCommentCreateForm
