import React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'

import useStyles from './SkyNetStepper.style'

type Step = {
    key: string,
    label: string,
    expanded: boolean | ((activeStep:number)=> boolean),
    renderContent?: ({
        activeStep,
        index,
    }: {
        activeStep:number,
        index: number
    }) => JSX.Element,
    content?: JSX.Element,
}

type Props = {
    activeStep: number,
    steps: Step[],
}

const SkyNetStepper = ({
    activeStep,
    steps,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className={classes.root}
        >
            {steps.map(({
                key, label, expanded, renderContent, content,
            }, index) => {
                const stepContent = renderContent ? renderContent({
                    activeStep,
                    index,
                }) : content
                const isExpanded = typeof expanded === 'function' ? expanded(activeStep) : expanded

                return (
                    <Step
                        key={key}
                        expanded={isExpanded}
                    >
                        <StepLabel
                            className={classes.label}
                        >
                            {label}
                        </StepLabel>
                        <StepContent>{stepContent}</StepContent>
                    </Step>
                )
            })}
        </Stepper>
    )
}

export default SkyNetStepper
