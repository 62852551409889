import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'

import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import DTOSpec from 'app/services/dtoSpec'

import useGetLogger from 'app/Apps/Loggers/hooks/useGetLogger'
import {
    LoggerType,
} from 'app/types/enums'

import {
    LoggerValue, LoggerData,
} from './LoggerForm.types'
import LoggerForm from './LoggerForm'

type LoggerFormContainerProps = {
    onSuccess?: (args: any) => void,
    serialNumber: string,
}

const defaultProps: Partial<LoggerFormContainerProps> = {
    onSuccess: undefined,
}

const defaultSelectedLogger: LoggerValue = {
    logger: {
        id: null,
    },
    loggerPositionConfiguration: null,
}

const LoggerFormContainer = ({
    serialNumber,
    onSuccess,
}: LoggerFormContainerProps) => {
    const [
        selectedLogger,
        setSelectedLogger,
    ] = useState<LoggerValue>(defaultSelectedLogger)

    const {
        logger,
    }: LoggerData = useGetLogger(selectedLogger?.logger.id)

    const loggerData: LoggerValue = useMemo(() => {
        return {
            ...selectedLogger,
            positionConfiguration: logger?.loggerTypeDetails?.positionConfiguration,
            loggerPositionConfiguration: selectedLogger.loggerPositionConfiguration,
            isDefaultLogger: selectedLogger.isDefaultLogger === undefined
                ? selectedLogger.logger.loggerType === LoggerType.MR_810T
                : selectedLogger.isDefaultLogger,
        }
    }, [
        logger,
        selectedLogger,
    ])
    const loadOptions = useDelayedLoadOptions({
        url: DTOSpec.Logger.Selector,
        params: {
            rows: 25,
            start: 0,
        },
    })

    const loadLabel = useCallback(() => {
        return Promise.resolve({
            loggerNumber: logger?.loggerNumber,
        })
    }, [logger?.loggerNumber])

    const onValueChange = useCallback((newValue: LoggerValue) => {
        const {
            logger: newlogger,
            loggerPositionConfiguration,
        } = newValue

        if (newlogger.id !== selectedLogger.logger.id) {
            setSelectedLogger({
                logger: newlogger,
            })
        }

        if (loggerPositionConfiguration !== selectedLogger.loggerPositionConfiguration) {
            setSelectedLogger({
                ...selectedLogger,
                ...newValue,
            })
        }
    }, [selectedLogger])

    const onSuccessLocal = useCallback((args) => {
        setSelectedLogger(defaultSelectedLogger)
        onSuccess(args)
    }, [onSuccess])

    return (
        <LoggerForm
            serialNumber={serialNumber}
            value={loggerData}
            loadOptions={loadOptions}
            loadLabel={loadLabel}
            onSuccess={onSuccessLocal}
            setExternalValue={onValueChange}
        />
    )
}

LoggerFormContainer.defaultProps = defaultProps

export default LoggerFormContainer
