const injectDefaultFilters = (filters) => {
    return (cb) => {
        return (params) => {
            if (!filters) {
                return cb(params)
            }

            return cb({
                ...params,
                filters,
            })
        }
    }
}

export default injectDefaultFilters
