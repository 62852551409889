import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import Companies from './Companies'
import Locations from './Locations'
import BillingCompanies from './BillingCompanies'
import Contacts from './Contacts'
import Users from './Users'

const ContactManagement = () => {
    const {
        path,
    } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/companies`}>
                <Companies />
            </Route>

            <Route
                path={`${path}/locations`}
            >
                <Locations />
            </Route>

            <Route
                path={`${path}/billing-companies`}
            >
                <BillingCompanies />
            </Route>

            <Route
                path={`${path}/contacts`}
            >
                <Contacts />
            </Route>

            <Route
                path={`${path}/users`}
            >
                <Users />
            </Route>

            <Redirect
                exact
                path={path}
                to={`${path}/locations`}
            />

            <Route path="/">
                <div>
                    Not found
                </div>
            </Route>
        </Switch>
    )
}

export default ContactManagement
