import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import requests from '../orderComment.request'

export default ({
    onSuccess,
}: { onSuccess?: () => void }) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdateCompany,
        onError,
    } = useHandleStatusRequest({
        title: 'Comment was successfully removed',
        id: 'CommentRemoved',
        onSuccess,
    })

    const {
        mutate: deleteComment,
    } = useMutation({
        mutationFn: (id: number) => {
            return simpleApi({
                ...requests.DeleteComment({
                    id,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessUpdateCompany,
    })

    return useCallback((id: number) => {
        deleteComment(id)
    }, [deleteComment])
}
