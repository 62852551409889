import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        input: {
            textAlign: 'right',
            '& > p': {
                display: 'none',
            },
            '& input': {
                textAlign: 'right',
            },
        },
    }
})

export default useStyles
