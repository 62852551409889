import React, {
    useMemo,
} from 'react'

import {
    App,
} from 'app/types/common.enums'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton, useArchive, ArchiveConfirmationDialog,
} from 'app/shared-components/Archive'

import AccountManagementRequests from '../../AccountManagement.request'
import AccountForm from '../AccountForm'
import useGetAccount from '../../hooks/useGetAccount'

type Props = {
    id: number,
    disabled?: boolean
}

const defaultProps = {
    disabled: false,
}

const AccountUpdate = ({
    id,
    disabled,
}: Props) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAccount(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? AccountManagementRequests.restore
            : AccountManagementRequests.archive,
        notificationId: 'AccountUpdateDto',
        title: 'Account',
        data,
        redirectionAfterSuccess: `/apps/${App.ACCOUNT_MANAGEMENT}/accounts/all`,
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        archive,
        data?.archived,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <AccountForm
                    onSuccess={invalidate}
                    data={data}
                    disabled={disabled}
                    requestParam={AccountManagementRequests.update.request({
                        id,
                    })}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Account"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

AccountUpdate.defaultProps = defaultProps

export default AccountUpdate
