import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'

function getEditedItems({
    allItems, initData,
}) {
    return allItems.reduce((acc, item) => {
        const uneditedData = (initData || []).find((el) => {
            return item.id === el.id
        })

        if (Object.keys(item).some((key) => {
            if (isNumber(item?.[key]) || isBoolean(item?.[key])) {
                return item?.[key] !== uneditedData?.[key]
            }
            if (isArray(item?.[key])) {
                return !isEqual(item?.[key], uneditedData?.[key])
            }
            if (isObject(item?.[key])) {
                return !isEqual(item?.[key]?.id, uneditedData?.[key]?.id)
            }
            if (isEmpty(item?.[key]) && isEmpty(uneditedData?.[key])) {
                return false
            }
            return !isEqual(item?.[key], uneditedData?.[key])
        })) {
            return [
                ...acc,
                item,
            ]
        }
        return acc
    }, [])
}

export default getEditedItems
