import React, {
    useCallback,
    useState,
} from 'react'
import noop from 'lodash/noop'

import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequestConfig,
} from 'app/types/request.types'
import useGetContact from 'app/Apps/ContactManagement/Contacts/hooks/useGetContact'
import useGetLocation from 'app/Apps/ContactManagement/Locations/hooks/useGetLocation'

import {
    EngagementType,
} from '../engagement.types'
import requestConfig from '../engagement.request'
import getFields from './engagementForm.fields'

type Props = {
    data?: EngagementType,
    onSuccess?: (props: any) => void,
    onCancel?: (...args: any[]) => void,
    requestParam?: RequestConfig,
    disabled?: boolean,
    customButtons?: JSX.Element[],
}

const defaultProps: Partial<Props> = {
    data: {} as EngagementType,
    onSuccess: noop,
    onCancel: noop,
    disabled: false,
    requestParam: null,
    customButtons: [],
}

const EngagementForm = ({
    data,
    onSuccess,
    onCancel,
    requestParam,
    disabled,
    customButtons,
}: Props) => {
    const getAllowedValues = useEnumValues()
    const exists = Boolean(data?.id)

    const [
        value,
        setValue,
    ] = useState<EngagementType>(data)

    const {
        data: contact,
    } = useGetContact(value.contact?.id)

    const {
        data: location,
    } = useGetLocation(contact?.location?.id)

    const fields = getFields({
        getAllowedValues,
        exists,
        disabled,
        company: location?.company || contact?.company,
        country: location?.country || contact?.country,
    })

    const onSuccessHandle = useCallback((args) => {
        onSuccess({
            ...args,
            isProspect: contact?.isProspect,
        })
    }, [
        contact?.isProspect,
        onSuccess,
    ])

    return (
        <FormWithControls
            onSuccess={onSuccessHandle}
            value={data}
            name={requestConfig.domainName}
            fields={fields}
            requestParams={requestParam}
            exists={exists}
            onCancel={onCancel}
            disabled={disabled}
            setExternalValue={setValue}
            customButtons={customButtons}
        />
    )
}

EngagementForm.defaultProps = defaultProps

export default EngagementForm
