import {
    makeStyles,
} from 'app/tss'

const generalInformation = 'generalInformation'
const information = 'information'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${generalInformation} ${generalInformation} ${information} ${information}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        generalInformation: {
            gridArea: generalInformation,
        },
        information: {
            gridArea: information,
        },
    }
})

export default useStyles
