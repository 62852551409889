import {
    LaneStatus,
} from 'app/types/enums'
import {
    FormPart,
} from 'app/types/form.types'

import {
    IncoTerm, Lane,
    PreliminaryInvoiceCurrency,
} from '../../lanes.types'

const laneInformationFields = ({
    originalStatus,
    getAllowedValues,
}: {
    originalStatus: LaneStatus,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
}): FormPart<keyof Lane> => {
    return {
        title: 'Lane Information',
        className: 'laneInformation',
        fields: [
            {
                id: 'laneInformation1',
                fields: [
                    {
                        name: 'laneStatus',
                        componentName: 'StatusTransitionSelector',
                        moduleName: 'laneImplementation',
                        allowedValues: getAllowedValues(LaneStatus),
                        originalStatus,
                        useDropdown: true,
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.laneStatus',
                    },
                    {
                        name: 'incoTerm',
                        componentName: 'Select',
                        allowedValues: getAllowedValues(IncoTerm),
                        labelKey: 'LaneOpsInformationDto.incoTerm',
                    },
                ],
            },
            {
                id: 'laneInformation2',
                fields: [
                    {
                        name: 'endOfLeaseReminderDelay',
                        componentName: 'IntegerInput',
                        labelKey: 'LaneOpsInformationDto.endOfLeaseReminderDelay',
                    },
                    {
                        name: 'preliminaryInvoiceCurrency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(PreliminaryInvoiceCurrency),
                        useDropdown: true,
                        labelKey: 'LaneOpsInformationDto.preliminaryInvoiceCurrency',
                    },
                ],
            },
            {
                id: 'laneInformation3',
                fields: [{
                    name: 'laneCommentOps',
                    componentName: 'LongText',
                    labelKey: 'LaneOpsInformationDto.laneCommentOps',
                }],
            },
        ],
    }
}

export default laneInformationFields
