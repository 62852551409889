import React, {
    useCallback,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import StatusHandler from 'app/shared-components/StatusHandler'
import useHasPermission from 'app/hooks/useHasPermission'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import useGetUser from '../hooks/useGetUser'
import requests from '../Users.request'
import getUserFields from './UserUpdate.fields'
import useStyles from './UserUpdate.styles'

type Props = {
    id: string
}

const UserUpdate = ({
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()
    /* ToDo we should rid off usage of DomainObjectContext in the future */
    const {
        updateTableElement,
    } = useDomainObjectContext()

    const updatePermission = useHasPermission(['user_create'])
    const {
        data,
        isDataReady,
        error,
        isError,
        invalidate,
        isFetching,
    } = useGetUser(id)

    const modifyDataBeforeSend = useCallback((update) => {
        updateTableElement(update)
        return {
            ...update,
            hint: undefined,
        }
    }, [updateTableElement])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                onSuccess={invalidate}
                value={data}
                name={requests.domainName}
                modifyDataBeforeSend={modifyDataBeforeSend}
                disabled={!updatePermission}
                fields={getUserFields({
                    editable: updatePermission,
                })}
                requestParams={requests.update({
                    id,
                })}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                exists
            />
        </StatusHandler>
    )
}

export default UserUpdate
