import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        optionsWrapper: {
            zIndex: 1000,
            position: 'absolute',
            display: 'none',
            '&.active': {
                display: 'block',
            },
        },
        options: {
            position: 'absolute',
            minWidth: 50,
            width: 'auto',
            border: `1px solid ${theme.palette.secondary[200]}`,
            background: theme.palette.common.white,
            minHeight: 50,
            maxHeight: 200,
            overflow: 'auto',
            display: 'block',
            left: 0,
        },
        option: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            textAlign: 'left',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.typography.body1.color,
            fontSize: theme.typography.body1.fontSize,
            padding: theme.spacing(1),
        },
        'option &:hover': {
            background: theme.palette.primary[30],
        },
        optionNone: {
            fontStyle: 'italic',
        },
    }
})

export default useStyles
