import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        paper: {
            '&&': {
                width: theme.spacing(115),
                maxWidth: theme.spacing(115),
                position: 'static',
            },
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'static',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        wrapper: {
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateColumns: 'repeat(4, 1fr)',
            marginBottom: theme.spacing(3),
        },
    }
})

export default useStyles
