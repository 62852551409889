import React from 'react'

import InputSingleline from 'app/shared-components/InputSingleline'

import useStyles from './DefaultAttachments.style'
import {
    DefaultAttachment,
} from '../../utils/OrderNotifications.types'

type Props = {
    value?: DefaultAttachment[],
}

const defaultProps = {
    value: [],
}

const DefaultAttachments = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <>
            {!value.length && (
                <div className={classes.emptyMsg}>
                    This notification does not have any documents attached.
                </div>
            )}
            {Boolean(value.length) && (
                value.map(({
                    id,
                    fileName,
                }) => {
                    return (
                        <InputSingleline
                            key={`def-attachment-${id}`}
                            value={fileName}
                            disabled
                        />
                    )
                })
            )}
        </>
    )
}

DefaultAttachments.defaultProps = defaultProps

export default DefaultAttachments
