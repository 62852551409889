import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'

import Typeahead from 'app/shared-components/Typeahead'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import useGetContact from 'app/Apps/ContactManagement/Contacts/hooks/useGetContact'
import contactsRequest from 'app/Apps/ContactManagement/Contacts/Contacts.request'

import {
    NotificationIconProps,
} from '../NotificationIcon/notificationIcon.types'
import emailNameCompanyOrLocationConverter from './utils/labelConverters'

type Props = {
    name?: string,
    value?: string | number,
    onChange?: (value: any, ...params: any[]) => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
    includeFilters?: Record<string, string[]>
    filters?: Record<string, string[]>
    notificationIconProps?: NotificationIconProps,
    showOptionLabelWithEmail?: boolean,
}

const defaultProps: Partial<Props> = {
    name: '',
    value: undefined,
    onChange: noop,
    title: 'Contacts',
    disabled: false,
    className: '',
    required: false,
    includeFilters: undefined,
    filters: undefined,
    notificationIconProps: undefined,
    showOptionLabelWithEmail: false,
}

const ContactSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
    includeFilters,
    filters,
    notificationIconProps,
    showOptionLabelWithEmail,
}: Props) => {
    const loadOptionsParams = useMemo(() => {
        return {
            ...contactsRequest.selector,
            includeFilters,
            filters,
            convertion: showOptionLabelWithEmail ? emailNameCompanyOrLocationConverter : undefined,
        }
    }, [
        filters,
        includeFilters,
        showOptionLabelWithEmail,
    ])

    const loadOptions = useDelayedLoadOptions(loadOptionsParams)

    const {
        data: labelData,
    } = useGetContact(value)

    return (
        <Typeahead
            name={name}
            title={title}
            className={className}
            onChange={onChange}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="contactName"
            value={value}
            disabled={disabled}
            isRequired={required}
            notificationIconProps={notificationIconProps}
        />
    )
}

ContactSelector.defaultProps = defaultProps

export default ContactSelector
