import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    ProductType,
} from 'app/types/enums'

import {
    RequiredContainersChange, PreBookingReasonForChange,
} from '../../PreBooking.types'

const getColumns = ({
    label,
}: {
    label: (text: string) => string
}): RichTableColumns<keyof RequiredContainersChange>[] => {
    return [
        {
            id: 'modificationReason',
            headerProps: {
                children: label('Prebooking.modificationReasons'),
            },
            mapCellProps: 'modificationReason',
            componentName: 'EnumValue',
            allowedValuesEnum: PreBookingReasonForChange,
        },
        {
            id: 'previousRequiredContainers',
            headerProps: {
                children: label('Prebooking.previousRequiredContainers'),
            },
            mapCellProps: 'previousRequiredContainers',
            componentName: 'RequiredContainersList',
            allowedValuesEnum: ProductType,
        },
        {
            id: 'requiredContainers',
            headerProps: {
                children: label('Prebooking.newRequiredContainers'),
            },
            mapCellProps: 'requiredContainers',
            componentName: 'RequiredContainersList',
            allowedValuesEnum: ProductType,
        },
        {
            id: 'modifiedBy',
            headerProps: {
                children: label('Prebooking.modifiedBy'),
            },
            mapCellProps: 'modifiedBy',
        },
        {
            id: 'modifiedOn',
            headerProps: {
                children: label('Prebooking.modifiedOn'),
            },
            mapCellProps: 'modifiedOn',
        },
    ]
}

export default getColumns
