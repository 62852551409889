import React, {
    useCallback,
} from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import useStyles from './SelectableElement.styles'

const defaultProps = {
    onClick: undefined,
    disabled: false,
}

const SelectableElement = ({
    text,
    name,
    id,
    editMode,
    onClick,
    disabled,
}: {
    disabled?: boolean
    text: string,
    id: number | string,
    name: string,
    editMode: boolean,
    onClick?: (...args: any) => void
}) => {
    const {
        classes,
    } = useStyles({
        disabled,
    })

    const renderArrowIcon = useCallback(() => {
        if (!disabled) {
            if (editMode) {
                return <ArrowDropUpOutlinedIcon className={classes.dropdownIcon} />
            }
            return <ArrowDropDownIcon className={classes.dropdownIcon} />
        }
        return null
    }, [
        classes.dropdownIcon,
        disabled,
        editMode,
    ])

    return (
        <div
            className={classes.nonEditMode}
            key={`${name}-${id}`}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <span className={classes.text}>{text || ''}</span>
            {renderArrowIcon()}
        </div>
    )
}

SelectableElement.defaultProps = defaultProps

export default SelectableElement
