import React, {
    useMemo, useCallback,
} from 'react'
import Button from 'app/shared-components/Button'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'

const defaultProps = {
    discard: undefined,
    positiveLabel: 'Save',
    isPositiveAlert: false,
    promptText: 'You are about to loose your changes, would you like to save them before?',
}

const UnsavedChangesPrompt = ({
    discard, close, action, open, promptText, positiveLabel, isPositiveAlert,
}: {
    open: boolean,
    close: (...a: any[]) => void,
    action: (...a: any[]) => Promise<any> | any,
    discard?: (...a: any[]) => void,
    promptText?: string,
    positiveLabel?: string,
    isPositiveAlert?: boolean,
}) => {
    const handlePositive = useCallback(
        async (callAction: (...a: any[]) => Promise<void> | void) => {
            try {
                callAction()
            } finally {
                close()
            }
        }, [close],
    )

    const customButtons = useMemo(() => {
        if (discard) {
            return [<Button
                onClick={() => {
                    handlePositive(discard)
                }}
                label="Discard"
                alert
                key="discard"
            />]
        }
        return []
    }, [
        discard,
        handlePositive,
    ])

    const handleClose = useCallback(async (positive) => {
        if (positive && action) {
            handlePositive(action)
        }
        close()
    }, [
        close,
        action,
        handlePositive,
    ])

    return (
        <ConfirmationDialog
            positiveLabel={positiveLabel}
            isPositiveAlert={isPositiveAlert}
            negativeLabel="Cancel"
            dialogTitle="Unsaved changes"
            open={open}
            dialogContent={promptText}
            handleClose={handleClose}
            customButtons={customButtons}
        />
    )
}

UnsavedChangesPrompt.defaultProps = defaultProps

export default UnsavedChangesPrompt
