import React from 'react'
import moment from 'moment-business-days'
import dateTimeFullZonedMask from 'app/utils/date/dateTimeFullZonedMask'
import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'
import useStyles from './WorkingDaysLeftAlert.styles'

const defaultProps = {
    configName: undefined,
    mapCellProps: undefined,
    title: undefined,
    alertDays: undefined,
}
const WorkingDaysLeftAlert = ({
    title,
    value,
    alertDays,
    configName,
    mapCellProps,
}: {
    configName?: string,
    mapCellProps?: string,
    title?: string,
    value: string,
    alertDays?: number,
}) => {
    const fieldConfig = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })

    const {
        classes,
    } = useStyles({
        isAlert: moment({})
            .businessDiff(
                moment(value, dateTimeFullZonedMask),
            ) <= (alertDays || fieldConfig?.alertDays),
    })

    return (
        <div>
            {title ? <div>{title}</div> : null}
            <span className={classes.alertColor}>{value}</span>
        </div>
    )
}

WorkingDaysLeftAlert.defaultProps = defaultProps

export default WorkingDaysLeftAlert
