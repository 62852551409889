import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        content: {
            padding: theme.spacing(2),
            width: `calc(100% - ${theme.spacing(2)})`,
            display: 'block',
            color: theme.palette.secondary[600],
            fontFamily: theme.typography.h4.fontFamily,
            textAlign: 'center',
        },
    }
})

export default useStyles
