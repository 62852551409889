import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tableCell: {
            padding: `0 ${theme.spacing(0.25)} 0 0 !important`,
        },
        container: {
            maxWidth: 120,
        },
        percentageSign: {
            position: 'absolute',
            right: -15,
            top: 5,
        },
        fieldWrapper: {
            width: '50%',
            margin: '0 auto',
            position: 'relative',
        },
        input: {
            backgroundColor: theme.palette.secondary[50],
            textAlign: 'right',
            paddingRight: theme.spacing(2),
        },
    }
})

export default useStyles
