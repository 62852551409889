/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {
    useHistory,
} from 'react-router-dom'

import AddEntry from './AddEntry'

const propTypes = {
    to: PropTypes.string.isRequired,
}

const AddEntryContainer = (props) => {
    const {
        to,
    } = props

    const history = useHistory()
    const goToPage = useCallback(() => {
        history.push(to)
    }, [
        history,
        to,
    ])

    return (
        <AddEntry
            {...props}
            onClick={goToPage}
        />
    )
}

AddEntryContainer.propTypes = propTypes

export default AddEntryContainer
