import {
    DTOCardFormField, NestedField,
} from 'app/types/form.types'
import {
    LeaseType,
} from 'app/types/enums'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import {
    DeliveryServiceType,
    TransportModeRoad,
} from '../../orders.types'
import {
    PositioningSetupType, PositioningSelection,
} from './PositioningSetup.types'

const getFields = ({
    transportModeForwarder,
    forwarderPositioningId,
    originServiceCenterId,
    originServiceCenterSelection,
    forwarderPositioningSelection,
    getAllowedValues,
    isDeliveryRoad,
    leaseType,
    switchOriginContacts,
    switchForwarderContacts,
}: {
    transportModeForwarder: TransportModeRoad,
    getAllowedValues: (enumType: any) => Record<string, string>,
    isDeliveryRoad: boolean,
    forwarderPositioningId: number,
    originServiceCenterId?: number,
    originServiceCenterSelection: PositioningSelection,
    forwarderPositioningSelection: PositioningSelection,
    leaseType: LeaseType,
    switchOriginContacts: boolean,
    switchForwarderContacts: boolean,
}): DTOCardFormField<keyof PositioningSetupType>[] => {
    const transportModeForwarderField: NestedField<keyof PositioningSetupType>[] = [{
        name: 'transportModeForwarder',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        labelKey: 'Order.transportModeForwarder',
    }]

    const transportModeRoadField: NestedField<keyof PositioningSetupType>[] = [{
        name: 'transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        labelKey: 'Order.transportModeRoad',
    }]

    const freightForwarderField: NestedField<keyof PositioningSetupType>[] = [{
        name: 'freightForwarder',
        componentName: 'CompanyObjectSelector',
        required: true,
        labelKey: 'OrderByNumberView.freightForwarder',
    }]

    const numberOfVansTracksFields: NestedField<keyof PositioningSetupType>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'CustomerTransportCreateDto.numberOfTrucks',
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'CustomerTransportCreateDto.numberOfVans',
        },
    ]

    const originScSelectionField: NestedField<keyof PositioningSetupType>[] = [{
        name: 'originServiceCenterSelection',
        componentName: 'EnumSelector',
        noSort: true,
        allowedValues: getAllowedValues(PositioningSelection),
        labelKey: 'CustomerTransportCreateDto.originScSelection',
    }]

    const forwarderFields: NestedField<keyof PositioningSetupType>[] = [
        {
            name: 'forwarderPositioningSelection',
            componentName: 'EnumSelector',
            noSort: true,
            allowedValues: getAllowedValues(PositioningSelection),
            labelKey: 'CustomerTransportCreateDto.fwdPosSelection',
        },
        {
            name: 'forwarderPositioning',
            componentName: 'LocationObjectSelector',
            filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
            disabled: !isDeliveryRoad
            || forwarderPositioningSelection !== PositioningSelection.OTHER,
            required: true,
            labelKey: 'LaneOpsInformationDto.fwdPos',
        },
        ...switchForwarderContacts ? [{
            name: 'forwarderPositioningContacts',
            componentName: 'MultipleContactByLocationObjectSelector',
            existedForm: false,
            required: true,
            selectedLocation: forwarderPositioningId,
            labelKey: 'LaneOpsInformationDto.fwdPosContacts',
        }] as NestedField<keyof PositioningSetupType>[] : [{
            name: 'forwarderPositioningContacts',
            componentName: 'MultipleContactObjectSelector',
            disabled: true,
            labelKey: 'LaneOpsInformationDto.fwdPosContacts',
        }] as NestedField<keyof PositioningSetupType>[],
    ]

    return [{
        id: 'positioningSetup',
        className: 'positioningSetup',
        title: 'Positioning Setup',
        fields: [
            {
                id: 'column_1',
                fields: [
                    {
                        name: 'leaseType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LeaseType),
                        disabled: true,
                        labelKey: 'Order.leaseType',
                    },
                    {
                        name: 'deliveryServiceType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(DeliveryServiceType),
                        disabled: true,
                        labelKey: 'Order.deliveryServiceType',
                    },
                    ...isDeliveryRoad ? transportModeRoadField : [],
                    ...isDeliveryRoad ? transportModeForwarderField : [],
                    ...transportModeForwarder === TransportModeRoad.FTL && isDeliveryRoad
                        ? numberOfVansTracksFields
                        : [],
                    {
                        name: 'orderReceived',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'Order.orderReceived',
                    },
                ],
            },
            {
                id: 'column_2',
                fields: [
                    ...isDeliveryRoad ? originScSelectionField : [],
                    {
                        name: 'originServiceCenter',
                        componentName: 'LocationObjectSelector',
                        filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                        disabled: !isDeliveryRoad
                            || originServiceCenterSelection !== PositioningSelection.OTHER,
                        required: true,
                        labelKey: 'Lane.originSc',
                    },
                    ...isDeliveryRoad ? [
                        ...switchOriginContacts ? [{
                            name: 'originServiceCenterContacts',
                            componentName: 'MultipleContactByLocationObjectSelector',
                            existedForm: false,
                            required: true,
                            selectedLocation: originServiceCenterId,
                            labelKey: 'LaneOpsInformationDto.originScContacts',
                        }] : [{
                            name: 'originServiceCenterContacts',
                            componentName: 'MultipleContactObjectSelector',
                            disabled: true,
                            labelKey: 'LaneOpsInformationDto.originScContacts',
                        }],
                        {
                            name: 'estimatedPickup',
                            componentName: 'DateTimeZonedSelect',
                            labelKey: 'CustomerTransportCreateDto.estimatedPickup',
                        },
                    ] as NestedField<keyof PositioningSetupType>[] : [],
                ],
            },
            {
                id: 'column_3',
                fields: [
                    ...isDeliveryRoad ? forwarderFields : [],
                    ...leaseType === LeaseType.AIRLINE_LEASE
                        ? freightForwarderField
                        : [],
                ],
            },
            {
                id: 'column_4',
                fields: [
                    {
                        name: 'mawbNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.mawbNumber',
                    },
                    {
                        name: 'hawbNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'CustomerTransportCreateDto.hawbNumber',
                    },
                    {
                        name: 'customerReference',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.customerReference',
                    },
                    {
                        name: 'personInCharge',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.personInCharge',
                    },
                ],
            },
        ],
    }]
}

export default getFields
