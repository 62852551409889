import {
    useEffect,
} from 'react'

export default function useClickHandler(keys, handler) {
    useEffect(() => {
        function handleClick(e) {
            if (keys.includes(e.key)) {
                handler()
            }
        }

        document.addEventListener('keydown', handleClick, {
            capture: true,
        })

        return () => {
            document.removeEventListener('keydown', handleClick)
        }
    }, [
        handler,
        keys,
    ])
}
