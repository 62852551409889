import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        contactsContainer: {
            padding: theme.spacing(2, 0, 0, 2),
            width: '100%',
        },
        contacts: {
            position: 'relative',
            right: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        container: {
            minHeight: 200,
            width: '100%',
        },
    }
})

export default useStyles
