import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridTemplate: 'auto / repeat(1, 1fr)',
            rowGap: theme.spacing(1.25),
        },
    }
})

export default useStyles
