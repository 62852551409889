import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            cursor: 'pointer',
            paddingTop: '5px',
            color: theme.palette.secondary[600],
        },
    }
})

export default useStyles
