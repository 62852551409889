import React, {
    useCallback,
    useMemo,
} from 'react'
import Link from '@mui/material/Link'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'

import {
    useAppDispatch,
    useAppSelector,
} from 'app/store/hooks'
import {
    selectShowArchived,
    toggleShowArchived,
} from 'app/shared-components/TableControls/store'
import Tooltip from 'app/shared-components/Tooltip'

import useStyles from './ShowArchived.styles'

type Props = {
    name: string,
    storeName?:string,
}

const defaultProps : Partial<Props> = {
    storeName: undefined,
}

const ShowArchived = ({
    name,
    storeName,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const dispatch = useAppDispatch()

    const showArchived = useAppSelector((state) => {
        return selectShowArchived(state, storeName || name)
    })

    const switchArhived = useCallback(() => {
        dispatch(toggleShowArchived(storeName || name))
    }, [
        dispatch,
        name,
        storeName,
    ])

    const title = useMemo(() => {
        return showArchived ? 'Hide Archived' : 'Show Archived'
    }, [showArchived])

    const IconComponent = useMemo(() => {
        return showArchived ? UnarchiveIcon : ArchiveIcon
    }, [showArchived])

    return (
        <Link
            className={classes.link}
            component="button"
            variant="body1"
            color="textSecondary"
            onClick={switchArhived}
        >
            <Tooltip title={title}>
                <div className={classes.iconWrapper}>
                    <IconComponent
                        className={classes.icon}
                    />
                </div>
            </Tooltip>
        </Link>
    )
}

ShowArchived.defaultProps = defaultProps

export default ShowArchived
