import React from 'react'
import ChangeHistory from 'app/shared-components/ChangeHistory'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from '../PreBookings.requests'

import RequiredContainersChanges from './RequiredContainersChanges'

const PrebookingChangeHistory = ({
    id,
}: {
    id: number
}) => {
    return (
        <>
            <ChangeHistory
                id={id}
                config={requests.modifications}
                configName="PrebookingChangeHistoryTable"
                name="PrebookingChangeHistory"
                key={RequestKeys.getPreBookingModifications}
                labelPrefixForChangedColumn={['Prebooking']}
            />

            <RequiredContainersChanges id={id} />
        </>

    )
}

export default PrebookingChangeHistory
