import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        selectionItemContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 6,
            marginRight: 6,
            '&:first-of-type': {
                marginLeft: 0,
            },
            '&:last-of-type': {
                marginRight: 0,
            },
        },
        selectionItemBtn: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'inherit',
            height: 174,
            padding: 0,
            cursor: 'pointer',
        },
        selectionItem: {
            display: 'flex',
            flexDirection: 'column',
            width: 'inherit',
            height: 'inherit',
            boxShadow: `0px 1px 3px ${theme.palette.secondary[400]}`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper,
            transform: 'scale(1)',
            transition: 'all 0.3s',
        },
        unselectedViewItem: {
            transform: 'scale(0.65)',
            transition: 'all 0.3s',
        },
        selectionItemContent: {
            flex: '1 0 auto',
        },
        itemTitle: {
            fontSize: theme.typography.h3.fontSize,
            width: '13rem',
            color: theme.palette.secondary[800],
            fontFamily: theme.typography.h5.fontFamily,
            padding: theme.spacing(1),
            textAlign: 'center',
        },
        unselectedItemTitle: {
            color: theme.palette.secondary[200],
            fontSize: theme.typography.h2.fontSize,
        },
        selectionItemImageContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        selectionItemImage: {
            width: 90,
            height: 90,
        },
        tempExcursion: {
            display: 'flex',
            justifyContent: 'space-evenly',
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary[500],
            fontFamily: theme.typography.h6.fontFamily,
        },
        unselectedItemTempExcursion: {
            fontSize: theme.typography.h3.fontSize,
            color: theme.palette.secondary[200],
            textAlign: 'center',
        },
        footer: {
            height: 15,
            flexShrink: 0,
            borderRadius: '0 0 4px 4px',
        },
        unselectedViewItemFooter: {
            backgroundColor: theme.palette.secondary[100],
            // height: 20,
        },
        colorTypeC: {
            backgroundColor: theme.palette.primary[400],
        },
        colorTypeCRT: {
            backgroundColor: theme.palette.success.light,
        },
        colorTypeF: {
            backgroundColor: theme.palette.common.lightYellow,
        },
        colorTypeDF: {
            backgroundColor: '#238082',
        },
        colorTypeBT: {
            backgroundColor: theme.palette.common.red,
        },
    }
})

export default useStyles
