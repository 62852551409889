export enum RejectedReasons {
    PRICING_POSITIONING = 'PRICING_POSITIONING',
    PRICING_COLLECTION = 'PRICING_COLLECTION',
    PRICING_BASE_LEASE = 'PRICING_BASE_LEASE',
    PRICING_PROCESS = 'PRICING_PROCESS',
    PRICING_OTHER = 'PRICING_OTHER',
    OPERATIONAL_CONTAINER_AVAILABILITY = 'OPERATIONAL_CONTAINER_AVAILABILITY',
    OPERATIONAL_SERVICE_CENTER = 'OPERATIONAL_SERVICE_CENTER',
    OPERATIONAL_OTHER = 'OPERATIONAL_OTHER',
    EXTERNAL_COMPETITION = 'EXTERNAL_COMPETITION',
    EXTERNAL_PARTNERS = 'EXTERNAL_PARTNERS',
    EXTERNAL_OTHER = 'EXTERNAL_OTHER',
    OTHER_REASON = 'OTHER_REASON',
}

export enum ReturnedReasons {
    PRICING_OTHER_REASON = 'PRICING_OTHER_REASON',
}
