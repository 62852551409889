/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import {
    useDispatch,
} from 'react-redux'

import {
    hideMessage,
} from 'app/shared-components/FormStatuses/store/actions'

import FailureMessage from './FailureMessage'

const propTypes = {
    id: PropTypes.string.isRequired,
}

const FailureMessageContainer = (props) => {
    const {
        id,
    } = props

    const [
        isDetailsOpen,
        setDetailsOpen,
    ] = useState(false)

    const dispatch = useDispatch()

    const close = useCallback(() => {
        dispatch(hideMessage({
            id,
        }))
    }, [
        id,
        dispatch,
    ])

    useEffect(() => {
        let timer

        if (!isDetailsOpen) {
            timer = setTimeout(() => {
                dispatch(hideMessage({
                    id,
                }))
            }, 10000)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [
        id,
        dispatch,
        isDetailsOpen,
    ])

    return (
        <FailureMessage
            {...props}
            isDetailsOpen={isDetailsOpen}
            setDetailsOpen={setDetailsOpen}
            close={close}
        />
    )
}

FailureMessageContainer.propTypes = propTypes

export default FailureMessageContainer
