import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        upperText: {
            textTransform: 'capitalize',
        },
        text: {
            color: theme.palette.secondary[600],
            fontSize: theme.typography.body1.fontSize,
        },
    }
})

export default useStyles
