import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    LaneFee,
} from 'app/Apps/Pricing/Pricing.types'
import requests from '../LoggerExchange.requests'

export default (serialNumber) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<LaneFee>({
        key: RequestKeys.getDeletedLoggerHistory,
        params: {
            serialNumber,
        },
        config: requests.deletedLoggerHistory,
        keepPreviousData: true,
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}
