import React, {
    useMemo,
} from 'react'
import {
    useRouteMatch,
} from 'react-router-dom'
import PropTypes from 'prop-types'

import Content from './Content'
import useStyles from './TabNavigationContent.styles'

const propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
    })).isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

const defaultProps = {
    id: null,
}

const TabNavigationContent = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        tabs,
        id,
    } = props
    const {
        params: {
            tab,
        },
    } = useRouteMatch()

    const {
        Component,
    } = useMemo(() => {
        return tabs.find(({
            url,
        }) => {
            return url === tab
        })
    }, [
        tab,
        tabs,
    ])

    return (
        <div className={classes.tabContentWrapper}>
            <div className={classes.tabContent}>
                <Content
                    contentComponent={Component}
                    id={id}
                />
            </div>
        </div>
    )
}

TabNavigationContent.propTypes = propTypes
TabNavigationContent.defaultProps = defaultProps

export default TabNavigationContent
