import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    PreviousOrderViewFields,
} from '../../../../orders.types'

const useGetPreviousOrdersByOrder = (orderNumber: string) => {
    const {
        onParamsChange,
        loadData,
    } = useRequestTable<TableResponse<PreviousOrderViewFields>>({
        config: orderManagementRequest.PreviousOrdersByOrder.requestFn,
        params: {
            orderNumber,
        },
        defaultValue: {
            sort: {
                field: 'changedOn',
                direction: 'desc',
            },
        },
        key: RequestKeys.getPreviousOrdersByOrder,
    })

    return {
        onParamsChange,
        loadData,
    }
}

export default useGetPreviousOrdersByOrder
