import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    ProductType,
} from 'app/types/enums'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import {
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import AddForm from './AddForm'
import RequiredContainers from '../RequiredContainers'

type Props = {
    value?: RequiredContainersCustomer[],
    allowedProductTypes?: ProductType[],
    onChange: (changeValue: (RequiredContainersInternal | RequiredContainersCustomer)[]) => void,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps = {
    value: [],
    disabled: false,
    required: false,
    allowedProductTypes: [],
}

const RequiredContainersByProductType = ({
    value,
    onChange,
    disabled,
    required,
    allowedProductTypes,
}: Props) => {
    const allowedValues = useEnumValues()(ProductType)

    const [
        initialAllowedTypes,
        setInitialAllowedTypes,
    ] = useState(allowedProductTypes)

    useEffect(() => {
        if (initialAllowedTypes !== allowedProductTypes) {
            setInitialAllowedTypes(allowedProductTypes)
            onChange([])
        }
    }, [
        allowedProductTypes,
        initialAllowedTypes,
        onChange,
    ])

    const chipValues = useMemo(() => {
        return value.map(({
            quantity,
            productType,
        }, i) => {
            return {
                value: i,
                label: `${quantity} x ${allowedValues[productType]}`,
            }
        })
    }, [
        allowedValues,
        value,
    ])

    const addRequiredContainers = useCallback((requiredContainer) => {
        const isExist = value.some(({
            productType,
        }) => {
            return productType === requiredContainer.productType
        })
        const newValue = isExist ? value.map((item) => {
            if (item.productType === requiredContainer.productType) {
                return {
                    ...requiredContainer,
                    quantity: item.quantity + requiredContainer.quantity,
                }
            }

            return item
        }) : [
            ...value,
            requiredContainer,
        ]

        onChange(newValue)
    }, [
        onChange,
        value,
    ])

    return (
        <RequiredContainers
            value={value}
            chipValues={chipValues}
            onChange={onChange}
            disabled={disabled}
        >
            <AddForm
                data={allowedProductTypes}
                allowedValues={allowedValues}
                addRequiredContainers={addRequiredContainers}
                required={required}
            />
        </RequiredContainers>
    )
}

RequiredContainersByProductType.defaultProps = defaultProps

export default RequiredContainersByProductType
