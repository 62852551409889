import React, {
    useCallback,
} from 'react'

import getUniqueId from 'app/utils/getUniqueId'

import RouteSteps from './RouteSteps'
import reorderElement from './utils/reorderElement'
import {
    SimulationStep,
} from '../simulation.types'

type Props = {
    value?: SimulationStep[],
    onChange: (newValue: SimulationStep[]) => void
}

const defaultProps = {
    value: [
        {
            id: getUniqueId(),
        },
        {
            id: getUniqueId(),
        },
        {
            id: getUniqueId(),
        },
    ],
}

const RouteStepsContainer = ({
    value,
    onChange,
}: Props) => {
    const onChangeStep = useCallback((step: SimulationStep): void => {
        const newValue = value.map((item) => {
            if (step.id === item.id) {
                return step
            }
            return item
        })

        onChange(newValue)
    }, [
        onChange,
        value,
    ])

    const addStep = useCallback((): void => {
        const newValue = [
            ...value,
            {
                id: getUniqueId(),
            },
        ]

        onChange(newValue)
    }, [
        onChange,
        value,
    ])

    const deleteStep = useCallback((index: number): void => {
        const newValue = value.filter((item, i) => { return i !== index })

        onChange(newValue)
    }, [
        onChange,
        value,
    ])

    const moveStep = useCallback((dragIndex: number, hoverIndex: number): void => {
        const steps = reorderElement(value, dragIndex, hoverIndex)

        onChange(steps)
    }, [
        value,
        onChange,
    ])

    return (
        <RouteSteps
            onChangeStep={onChangeStep}
            addStep={addStep}
            deleteStep={deleteStep}
            moveStep={moveStep}
            value={value}
        />
    )
}

RouteStepsContainer.defaultProps = defaultProps

export default RouteStepsContainer
