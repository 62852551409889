import {
    Location,
    OrderStep,
    StepStatus, StepType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    LocationDataType,
} from '../../OrderSteps.types'

const LOCATION_TITLE_MAP: Record<string, (location: Location) => string> = {
    ADDRESS: (location: Location) => { return `${location.locationName}` },
    SEAPORT: (location) => { return `${location.unloCode} | ${location.locationName}` },
    AIRPORT: (location) => { return `${location.iataCode} | ${location.locationName}` },
}

const getLocationData = ({
    pickupLocation,
    location,
    originAirport,
    originSeaport,
    deliveryLocation,
    actualPickup,
    actualDelivery,
    actualExecution,
    actualFlightDeparture,
    actualShipDeparture,
    actualFlightArrival,
    actualShipArrival,
    destinationAirport,
    destinationSeaport,
    stepStatus,
    stepType,
}: OrderStep): LocationDataType[] => {
    const data = []

    const formatLocation = (locationInfo: Location, timeStamp: string): LocationDataType => {
        const {
            locationType,
        } = locationInfo

        const isActual = Boolean(timeStamp) || stepStatus === StepStatus.CLOSED

        const iconName = isActual
            ? `${locationType?.toLowerCase()}Blue`
            : `${locationType?.toLowerCase()}`

        return {
            title: LOCATION_TITLE_MAP[locationType](locationInfo),
            iconPath: `assets/images/locationSelector/${iconName}.svg`,
            isActual,
        }
    }

    if (pickupLocation) {
        data.push(
            formatLocation(
                pickupLocation,
                actualPickup || actualFlightDeparture || actualShipDeparture,
            ),
        )
    }

    if (location) {
        data.push(formatLocation(location, actualExecution))
    }

    if (stepType === StepType.AIR && originAirport && destinationAirport) {
        data.push(formatLocation(originAirport, actualFlightDeparture))
        data.push(formatLocation(destinationAirport, actualFlightArrival))
    }

    if (stepType === StepType.SEA && originSeaport && destinationSeaport) {
        data.push(formatLocation(originSeaport, actualShipDeparture))
        data.push(formatLocation(destinationSeaport, actualShipArrival))
    }

    if (deliveryLocation) {
        data.push(
            formatLocation(
                deliveryLocation,
                actualDelivery || actualFlightArrival || actualShipArrival,
            ),
        )
    }

    return data
}

export default getLocationData
