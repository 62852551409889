import React, {
    useMemo,
} from 'react'
import {
    useTheme,
} from '@mui/material/styles'

import StatusHandler from 'app/shared-components/StatusHandler'
import GoogleMap from 'app/shared-components/GoogleMap'
import {
    MapConfig, MarkerConfig, PolylineConfig,
} from 'app/shared-components/GoogleMap/googleMap.types'

import options from './options'
import useGetAirportsById from '../../hooks/useGetAirportsById'
import {
    RouteSelectionType,
} from '../../simulation.types'

type Props = {
    value: RouteSelectionType,
}

const RoutePath = ({
    value,
}: Props) => {
    const theme = useTheme()

    const {
        routeOriginAirport,
        routeViaAirports,
        routeDestinationAirport,
    } = value

    const filteredAirports: number[] = useMemo(() => {
        return [
            routeOriginAirport,
            ...routeViaAirports || [],
            routeDestinationAirport,
        ]
            .map((item) => { return item?.id })
            .filter((item) => {
                return Boolean(item)
            })
    }, [
        routeDestinationAirport,
        routeOriginAirport,
        routeViaAirports,
    ])

    const {
        airports: airportsData,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetAirportsById(filteredAirports)

    const data: {
        lat: number,
        lng: number,
    }[] = useMemo(() => {
        return filteredAirports.reduce((acc, alem) => {
            if (!airportsData) {
                return acc
            }
            const {
                latitude,
                longitude,
            } = airportsData.find(({
                id,
            }) => {
                return id === alem
            })

            return [
                ...acc,
                {
                    lat: latitude,
                    lng: longitude,
                },
            ]
        }, [])
    }, [
        airportsData,
        filteredAirports,
    ])

    const mapConfig: MapConfig = useMemo(() => {
        const config = {
            styles: options,
        }

        if (data?.length > 0) {
            return {
                ...config,
                center: data[data.length - 1],
            }
        }

        return config
    }, [data])

    const polylineConfig: PolylineConfig = useMemo(() => {
        return {
            path: data,
            geodesic: true,
            strokeColor: theme.palette.primary[400],
            strokeOpacity: 1.0,
            strokeWeight: 3,
        }
    }, [
        theme,
        data,
    ])

    const markerConfig: MarkerConfig[] = useMemo(() => {
        if (!data?.length) {
            return null
        }

        return data.map((item, i) => {
            if (i === data.length - 1) {
                return {
                    position: item,
                    icon: '/assets/images/icons/map_location_destination.svg',
                }
            }

            return {
                position: item,
                icon: '/assets/images/icons/map_location_via.svg',
            }
        })
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady || !filteredAirports.length}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <GoogleMap
                mapConfig={mapConfig}
                polylineConfig={polylineConfig}
                markerConfig={markerConfig}
            />
        </StatusHandler>
    )
}

export default RoutePath
