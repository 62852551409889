import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            position: 'relative',
        },
        tableFont: {
            '& tbody tr': {
                '&:hover': {
                    backgroundColor: `${theme.palette.primary[50]} !important`,
                },
            },
            '& h6': {
                fontWeight: '400',
                fontSize: theme.typography.h3.fontSize,
                color: theme.palette.secondary.dark,
            },
        },
        gridCardWrapper: {
            height: '100%',
            minHeight: 300,
            display: 'grid',
            gridGap: `0 ${theme.spacing(1.875)}`,
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            padding: 8,
            flexGrow: 0,
            width: `calc(100% + ${theme.spacing(2)})`,
            margin: theme.spacing(-1),
            maxWidth: '100%',
            flexBasis: '100%',
        },
        cardContent: {
            paddingRight: 0,
            paddingLeft: 0,
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'static',
        },
        tableContent: {
            boxShadow: 'none !important',
            maxHeight: '1000px',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        icon: {
            cursor: 'pointer',
        },
    }
})

export default useStyles
