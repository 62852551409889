import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    OpportunityPalletType,
} from 'app/types/enums'
import {
    OpportunityType,
    Packaging,
} from '../opportunities.types'

export const getContainerInformationFields = (
    {
        getAllowedValues,
    }: {
        getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    },
): DTOCardFormField<keyof OpportunityType> => {
    return {
        id: 'containerInformation',
        title: 'Container Information',
        className: 'containerInformation',
        fields: [
            {
                name: 'annualPalletVolume',
                componentName: 'IntegerInput',
                labelKey: 'Opportunity.annualPalletVolume',
                required: true,
            },
            {
                name: 'competitorsPackaging',
                componentName: 'EnumChipSelector',
                allowedValues: getAllowedValues(Packaging),
                required: true,
                labelKey: 'Opportunity.competitorsPackaging',
            },
            {
                name: 'currentPackagingSolution',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(Packaging),
                required: true,
                labelKey: 'Opportunity.currentPackagingSolution',
            },
            {
                name: 'palletType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(OpportunityPalletType),
                useDropdown: true,
                required: true,
                labelKey: 'Opportunity.palletType',
            },
            {
                name: 'validationNeeded',
                componentName: 'Checkbox',
                labelKey: 'Opportunity.validationNeeded',
            },
        ],
    }
}

export default getContainerInformationFields
