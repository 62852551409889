import {
    createContext,
} from 'react'

const TabsContext = createContext({
    baseUrl: undefined,
    defaultTab: '',
    setDefaultTab: () => {},
})

export default TabsContext
