import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    ProductType,
} from 'app/types/enums'

import {
    PreBookingContainerStatus,
    PreBookingsTableType,
} from '../../../PreBooking/PreBooking.types'

const preBookingsConfig: SkyNetTableConfig<keyof PreBookingsTableType> = {
    name: 'Account.PreBookings',
    tabs: [{
        name: 'Pending Pre Bookings',
        url: 'all',
    }],
    fields: [
        {
            name: 'preBookingNumber',
            labelKey: 'Prebooking.number',
        },
        {
            name: 'originAirportIataCode',
            labelKey: 'OrderBaseTabView.from',
            filterField: 'originAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            name: 'destinationAirportIataCode',
            labelKey: 'OrderBaseTabView.to',
            filterField: 'destinationAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            name: 'pricingAccountCompanyName',
            labelKey: 'Prebooking.pricingAccount',
            filterField: 'pricingAccountCompanyName',
            filterType: FilterType.Array,
        },
        {
            name: 'customerExecution',
            labelKey: 'Prebooking.customerExecution',
            filterField: 'customerExecution',
            filterType: FilterType.Array,
        },
        {
            name: 'containersStatus',
            labelKey: 'Prebooking.containerStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: PreBookingContainerStatus,
            filterField: 'containersStatus',
            filterType: FilterType.Array,
        },
        {
            name: 'project',
            labelKey: 'Prebooking.project',
        },
        {
            name: 'destinationCountryName',
            labelKey: 'Prebooking.destinationCountry',
        },
        {
            name: 'requestedHandover',
            labelKey: 'Prebooking.requestedHandover',
            filterField: 'requestedHandover',
            filterType: FilterType.Array,
        },
        {
            name: 'requestedHandoverWeek',
            labelKey: 'Prebooking.requestedHandoverWeek',
        },
        {
            name: 'requiredContainers',
            componentName: 'RequiredContainersList',
            allowedValuesEnum: ProductType,
            sorting: false,
            labelKey: 'Prebooking.requiredContainers',
        },
    ],
}

export default preBookingsConfig
