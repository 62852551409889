import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            minWidth: 280,
            margin: '20px auto 20px',
            paddingBottom: '20px',
            '& > div': {
                marginLeft: '5px',
                marginRight: '5px',
            },
        },
    }
})

export default useStyles
