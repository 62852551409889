import React from 'react'
import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'
import AccountLaneUpdate from '../AccountLaneUpdate'
import AccountLaneCopy from '../AccountLaneCopy'

export default ({
    id,
}: {id: number}) => {
    const {
        url,
    } = useRouteMatch()

    return (
        <Switch>
            <Route
                exact
                path={url}
            >
                <AccountLaneUpdate
                    id={id}
                    disabled
                />
            </Route>
            <Route
                path={`${url}/:copy`}
            >
                <AccountLaneCopy id={id} />
            </Route>
        </Switch>
    )
}
