import React, {
    useCallback, useMemo,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'

import useGetLocationAreas from '../hooks/useGetLocationArea'
import LocationAreasForm from '../LocationAreasForm'
import LocationAreasRequests from '../LocationAreas.requests'

const defaultProps = {
    id: undefined,
    refetch: () => {},
}

const LocationAreasUpdate = ({
    id,
    refetch,
}: { id?: number, refetch?: (...args: any[]) => void }) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLocationAreas(id)

    const requestParam = useMemo(() => {
        return LocationAreasRequests.update({
            id: data?.id,
        })
    }, [data])

    const onSuccess = useCallback(() => {
        refetch()
    }, [refetch])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <LocationAreasForm
                data={data}
                onSuccess={onSuccess}
                requestParam={requestParam}
                exists
            />
        </StatusHandler>
    )
}

LocationAreasUpdate.defaultProps = defaultProps

export default LocationAreasUpdate
