import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'

import AddRemoveicon from './AddRemoveIcon'

import useStyles from './CollapsableAddRemovePanel.style'

const propTypes = {
    title: PropTypes.string.isRequired,
    onOpenForm: PropTypes.func.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    formOpened: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
}
const defaultProps = {
    disabled: false,
}

const CollapsableAddRemovePanel = ({
    title,
    onOpenForm,
    onCloseForm,
    children,
    formOpened,
    disabled,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <>
            <Collapse in={formOpened}>
                {children}
            </Collapse>
            <div className={classes.header}>
                <Typography variant="h3">
                    {title}
                </Typography>
                {!disabled && (
                    <AddRemoveicon
                        formOpened={formOpened}
                        onOpenForm={onOpenForm}
                        onCloseForm={onCloseForm}
                    />
                )}
            </div>
        </>
    )
}

CollapsableAddRemovePanel.propTypes = propTypes
CollapsableAddRemovePanel.defaultProps = defaultProps

export default CollapsableAddRemovePanel
