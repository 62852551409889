import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

export default (values: (string | number | undefined | null)[], delimiterValue: string): string => {
    return <string>values.reduce((acc, el, i) => {
        const delimiter = values.length - 1 === i ? '' : delimiterValue

        if (isNumber(el)) {
            return String(acc).concat(<string>el, delimiter)
        }
        return isEmpty(el) ? acc : String(acc).concat(<string>el, delimiter)
    }, '')
}
