import {
    RequestConfig,
} from 'app/types/request.types'

type LocationAreasRequests = {
    domainName: string,
    create: ({
        locationId,
    }) => RequestConfig,
    update: ({
        id,
    }) => RequestConfig,
    getLocationArea: ({
        id,
    }) => RequestConfig,
    filterRequest: ({
        locationId,
        params,
    }) => RequestConfig,
    filterCountRequest: ({
        locationId,
    }) => {
        customUrl: string
    },
    getLocationAreaByLocation: ({
        locationId,
        params,
    }: {
        locationId: number,
        params?: any
    }) => RequestConfig,
}

const requests: LocationAreasRequests = {
    domainName: 'LocationAreas',
    create: ({
        locationId,
    }) => {
        return {
            url: `location/${locationId}/area`,
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `area/${id}`,
            method: 'PATCH',
        }
    },
    getLocationArea: ({
        id,
    }) => {
        return {
            url: `area/${id}`,
            method: 'GET',
        }
    },
    filterRequest: ({
        locationId,
    }) => {
        return {
            url: `location/${locationId}/area/filter-request/all`,
            method: 'POST',
        }
    },
    filterCountRequest: ({
        locationId,
    }) => {
        return {
            customUrl: `location/${locationId}/area/filter-request/all`,
        }
    },
    getLocationAreaByLocation: ({
        locationId,
        params,
    }) => {
        return {
            url: `location/${locationId}/area/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
}

export default requests
