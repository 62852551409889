import React from 'react'
import {
    dateMask,
} from 'app/utils/date'

import DateRangeSelectorFilter from './DateRangeSelectorFilter'

type Props = {
    filter?:{
        from: string,
        to: string,
    },
    setFilter:()=> void
}

const defaultProps = {
    filter: {
        from: null,
        to: null,
    },
}

const TimeLessDateRangeSelectorFilter = ({
    filter,
    setFilter,
}: Props) => {
    return (
        <DateRangeSelectorFilter
            filter={filter}
            setFilter={setFilter}
            dateMask={dateMask}
        />
    )
}

TimeLessDateRangeSelectorFilter.defaultProps = defaultProps

export default TimeLessDateRangeSelectorFilter
