import {
    useLocation,
    useRouteMatch,
} from 'react-router-dom'

const useNextUrlParam = (): string[] => {
    const {
        url,
    } = useRouteMatch()
    const {
        pathname,
    } = useLocation()

    const diff = pathname.slice(url.length + 1)

    return diff.split('/').filter(Boolean)
}

export default useNextUrlParam
