import React from 'react'
import SharedContext from './SharedContext'

type Props = {
    value?: Record<string, any>,
    children: React.ReactNode,
}

const defaultProps = {
    value: {},
}

const SharedContextProvider = ({
    children,
    value,
}: Props) => {
    return (
        <SharedContext.Provider value={value}>
            {children}
        </SharedContext.Provider>
    )
}

SharedContextProvider.defaultProps = defaultProps

export default SharedContextProvider
