import {
    LeaseType,
} from 'app/types/enums'

import useGetOpportunitiesByQuote from './useGetOpportunitiesByQuote'
import useGetOpportunitiesByAccount from './useGetOpportunitiesByAccount'

const useGetOpportunitiesQuoteStepper = ({
    accountId,
    quoteId,
    filters,
}: {
    accountId: number,
    quoteId: number,
    filters?: {
        accountLaneGroupLeaseType?: LeaseType
    }
}) => {
    const {
        data: opportunitiesByAccount,
        isDataReady: isDataReadyByAccount,
    } = useGetOpportunitiesByAccount(quoteId ? null : accountId, {
        filters,
    })

    const {
        data: opportunitiesByQuote,
        isDataReady: isDataReadyByQuote,
    } = useGetOpportunitiesByQuote(quoteId, true)

    return {
        data: quoteId ? opportunitiesByQuote : opportunitiesByAccount,
        isDataReady: quoteId ? isDataReadyByQuote : isDataReadyByAccount,
    }
}

export default useGetOpportunitiesQuoteStepper
