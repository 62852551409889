import {
    RequestConfig,
} from 'app/types/request.types'

type ActivityRecordsRequests = {
    update: (
        id: number,
    ) => RequestConfig,
    archive: ({
        id,
    }: {id: number}) => RequestConfig,
    restore: ({
        id,
    }: {id: number}) => RequestConfig
}

const request: ActivityRecordsRequests = {
    update: (
        id,
    ) => {
        return {
            url: `activityrecord/${id}`,
            method: 'PUT',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `activityrecord/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `activityrecord/${id}/restore`,
            method: 'PATCH',
        }
    },
}

export default request
