/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import Tabs from 'app/shared-components/Tabs'
import AddEntry from 'app/shared-components/AddEntry'
import PrintLabel from 'app/shared-components/PrintLabel'

import ControlPanel from '../ControlPanel'

import useStyles from './DomainHeader.styles'

const propTypes = {
    reducedMode: PropTypes.bool,
    simple: PropTypes.bool,
    tableControls: PropTypes.arrayOf(PropTypes.string).isRequired,
    createForm: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.any),
    counter: PropTypes.number,
    url: PropTypes.string,
    selectedTab: PropTypes.string,
    printForm: PropTypes.func,
    applySearch: PropTypes.func.isRequired,
    controlPanelProps: PropTypes.any, // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
    reducedMode: false,
    simple: false,
    createForm: undefined,
    counter: undefined,
    printForm: undefined,
    controlPanelProps: undefined,
    tabs: undefined,
    url: undefined,
    selectedTab: undefined,
}

const DomainHeader = ({
    reducedMode,
    simple,
    tableControls,
    createForm,
    tabs,
    counter,
    url,
    selectedTab,
    printForm,
    applySearch,
    controlPanelProps,
}) => {
    const {
        classes,
    } = useStyles()

    if (reducedMode || (simple && !tableControls)) {
        return null
    }

    return (
        <>
            <div className={simple ? classes.tableConfigSimple : classes.tableConfig}>
                {!reducedMode && (
                    <>
                        <Tabs
                            className={classes.tabs}
                            items={tabs}
                            counter={counter}
                        />
                        {createForm && (
                            <AddEntry
                                to={`${url}/all/create`}
                                data-testid="add-entry-button"
                            />
                        )}
                        {printForm && (
                            <PrintLabel
                                to={`${url}/${selectedTab}/print`}
                                data-testid="print-label-button"
                            />
                        )}
                    </>
                )}
            </div>
            <ControlPanel
                {...controlPanelProps} // eslint-disable-line react/jsx-props-no-spreading
                applySearch={applySearch}
                tableControls={tableControls}
                simple={simple}
            />
        </>
    )
}

DomainHeader.propTypes = propTypes
DomainHeader.defaultProps = defaultProps

export default DomainHeader
