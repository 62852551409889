import {
    RequestConfig,
} from 'app/types/request.types'

type ContainerTypeRequest = {
    name: string,
    fetchAll: (params: Record<string, any>) => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    update: () => RequestConfig,
}

const containerTypeRequest: ContainerTypeRequest = {
    name: 'ContainerType',
    fetchAll: (params) => {
        return {
            url: 'containertype/filter-request/all',
            method: 'POST',
            data: {
                ...params,
                rows: 50,
            },
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `containertype/${id}`,
            method: 'GET',
        }
    },
    update: () => {
        return {
            url: 'containertype',
            method: 'PUT',
        }
    },
}

export default containerTypeRequest
