/* eslint-disable complexity */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Temperature from 'app/shared-components/Temperature'
import clsx from 'clsx'

import useStyles from './TemperatureInfo.style'

const propTypes = {
    lastMeasured: PropTypes.number,
    temperature: PropTypes.number,
    className: PropTypes.string,
}

const defaultProps = {
    lastMeasured: undefined,
    temperature: undefined,
    className: '',
}

const TemperatureInfo = (props) => {
    const {
        lastMeasured,
        temperature,
        className,
    } = props

    const {
        classes,
    } = useStyles()
    const tempValid = temperature === 0 || temperature

    return (
        <div className={className}>
            <div
                className={clsx(
                    {
                        [classes.temp]: tempValid,
                    },
                )}
            >
                {tempValid ? `${temperature}°C` : 'No data'}
            </div>
            <div
                className={classes.messageDate}
            >
                <Temperature
                    value={tempValid && (lastMeasured === 0 || lastMeasured) ? (
                        `Measured ${moment(moment(lastMeasured, 'X')).fromNow()}`
                    ) : (
                        'No data'
                    )}
                    lastMeasuredOnUtc={lastMeasured}
                />

            </div>
        </div>
    )
}

TemperatureInfo.propTypes = propTypes
TemperatureInfo.defaultProps = defaultProps

export default TemperatureInfo
