import {
    FieldInCard,
} from 'app/types/form.types'

type Values = {
    noteSubject: string,
    noteText: string,
    validDateTimestamp: string,
    attachments: number[],
}

type Fields = keyof Values

const noteFormFields: FieldInCard<Fields>[] = [{
    id: 'note',
    title: 'Note',
    fields: [
        {
            name: 'noteSubject',
            labelKey: 'Note.noteSubject',
            componentName: 'InputSingleline',
        },
        {
            name: 'noteText',
            labelKey: 'Note.noteText',
            componentName: 'LongText',
        },
        {
            name: 'validDateTimestamp',
            labelKey: 'Note.validDateTimestamp',
            className: 'validDateTimestamp',
            componentName: 'DateTimeZonedSelect',
        },
        {
            name: 'attachments',
            labelKey: 'Note.attachments',
            componentName: 'AttachmentsObjectSelector',
        },
    ],
}]

export default noteFormFields
