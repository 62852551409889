import React from 'react'

import LaneGroupContextWrapper from '../LaneGroupContextWrapper'
import LanesTable from './LanesTable'

const Lanes = ({
    id,
}: {id: number}) => {
    return (
        <LaneGroupContextWrapper id={id}>
            <LanesTable />
        </LaneGroupContextWrapper>
    )
}

export default Lanes
