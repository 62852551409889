import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'

import useGetLaneById from '../../hooks/useGetLaneById'
import getFields from './overview.fields'
import useStyles from './Overview.styles'

const Overview = ({
    id,
}: {id: number}) => {
    const getAllowedValues = useEnumValues()
    const {
        classes,
    } = useStyles()
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetLaneById(id)

    const fieldsWithClassName = useFieldsWithClassName(getFields(getAllowedValues), classes)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <DomainObjectForm
                name="LaneOpsInformationDto"
                disabled
                value={data}
                className={classes.gridWrapper}
                fields={fieldsWithClassName}
            />
        </StatusHandler>
    )
}

export default Overview
