import React from 'react'

import EnumValue from 'app/shared-components/EnumValue'
import {
    AttitudeToSkyCell as AttitudeToSkyCellEnum,
} from 'app/Apps/RelationshipsManagement/Engagement/engagement.types'
import MAP_COLOR from 'app/Apps/RelationshipsManagement/Engagement/EngagementForm/AttitudeToSkyCell/attitudeToSkyCellColorMap.utils'

import useStyles from './AttitudeToSkyCell.styles'

const AttitudeToSkyCell = ({
    configName,
    mapCellProps,
    value,
}: {
    configName: string,
    mapCellProps: string,
    value: AttitudeToSkyCellEnum,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <EnumValue
            value={value}
            configName={configName}
            mapCellProps={mapCellProps}
            className={classes[MAP_COLOR[value]]}
        />
    )
}

export default AttitudeToSkyCell
