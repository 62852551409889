import {
    FieldInCard,
} from 'app/types/form.types'
import {
    CancellationReasons,
    OrderFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getFields = (
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): FieldInCard<OrderFields>[] => {
    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'cancellationReason',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(CancellationReasons),
                    useDropdown: true,
                    required: true,
                    labelKey: 'OrderByNumberView.cancellationReason',
                },
                {
                    name: 'cancellationDate',
                    componentName: 'DateTimeZonedSelect',
                    required: true,
                    labelKey: 'OrderByNumberView.cancellationDate',
                },
            ],
        },
        {
            id: 'column2',
            fields: [{
                name: 'cancellationComment',
                componentName: 'LongText',
                multiline: true,
                labelKey: 'OrderByNumberView.cancellationComment',
            }],
        },
    ]
}

export default getFields
