import React, {
    useMemo,
} from 'react'
import moment from 'moment'

import {
    LoggerType,
} from 'app/types/enums'

import useStyles from './LoggerAge.style'

export type LoggerAgeType = {
    loggerExchangedOnUtcInternal?: string,
    loggerExchangedOnUtcAmbient?: string,
    defaultAmbientLoggerType?: string,
    defaultInternalLoggerType?: string,
    createdOn?: string,
}

const colorsMap = (sensorType: string) => {
    if (sensorType && sensorType === LoggerType.CARTASENSE) {
        return {
            new: 150,
            old: 180,
        }
    }
    return {
        new: 330,
        old: 360,
    }
}

const getClassName = (sensorAge, classes, sensorType) => {
    if (sensorAge === undefined) {
        return undefined
    }

    if (sensorAge >= colorsMap(sensorType).old) {
        return classes.oldReading
    }
    if (sensorAge < colorsMap(sensorType).new) {
        return classes.liveReading
    }

    return classes.recentReading
}

const loggerTypeMapper = {
    loggerExchangedOnUtcInternal: 'defaultInternalLoggerType',
    loggerExchangedOnUtcAmbient: 'defaultAmbientLoggerType',
}

type Props = {
    mapCellProps: string,
    rowProps?: LoggerAgeType,
}

const defaultProps: Partial<Props> = {
    rowProps: {},
}

const LoggerAge = ({
    mapCellProps,
    rowProps,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const sensorAge = useMemo(() => {
        return rowProps[mapCellProps] && moment().diff(moment(rowProps[mapCellProps], 'X'), 'days')
    }, [
        rowProps,
        mapCellProps,
    ])

    const sensorAgeTitle = useMemo(() => {
        return sensorAge !== undefined ? `${sensorAge} ${sensorAge === 1 ? 'Day' : 'Days'}` : ''
    }, [sensorAge])

    const className = useMemo(() => {
        return getClassName(sensorAge, classes, rowProps[loggerTypeMapper[mapCellProps]])
    }, [
        sensorAge,
        classes,
        mapCellProps,
        rowProps,
    ])

    return (
        <div
            data-testid="sensor-age-div"
            className={className}
        >
            {sensorAgeTitle}
        </div>
    )
}

LoggerAge.defaultProps = defaultProps

export default LoggerAge
