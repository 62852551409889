import reducer, {
    setConfig,
    isConfigSet,
    selectConfigByFieldName,
} from './Configs.slice'

import useStoreTableConfig from './useStoreTableConfig'

export {
    setConfig,
    isConfigSet,
    selectConfigByFieldName,
    useStoreTableConfig,
}

export default reducer
