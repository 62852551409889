import React, {
    useMemo, useState,
} from 'react'

import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'

import getFields from './selectLane.fields'
import useStyles from './SelectLaneStep.style'
import {
    AvailableLanesFilters,
    SelectLaneStepType,
} from '../../createCustomerTransportForm.types'
import {
    AirlineLeaseAvailableLane,
} from './AvailableLanes'

const SelectLaneStep = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    onEdit,
    index,
}: {
    value: SelectLaneStepType,
    onChange: (newVal: SelectLaneStepType) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    onEdit: () => void,
    index: number,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const [
        availableLanes,
        setAvailableLanes,
    ] = useState<{data:AirlineLeaseAvailableLane[]}>()

    const {
        account,
        deliveryServiceType,
        collectionServiceType,
        tempRange,
        handoverPoint,
        collectionDropoffPoint,
        originAirport,
        destinationAirport,
        customerExecution,
        selectedLaneId,
        consignee,
    } = value

    const includeFilters: AvailableLanesFilters = useMemo(() => {
        return {
            accountId: account && [account.id],
            deliveryServiceType: deliveryServiceType && [deliveryServiceType],
            collectionServiceType: collectionServiceType && [collectionServiceType],
            tempRange: tempRange && [tempRange],
            handoverPointId: handoverPoint && [handoverPoint.id],
            collectionDropoffPointId: collectionDropoffPoint && [collectionDropoffPoint.id],
            originAirportId: originAirport && [originAirport.id],
            destAirportId: destinationAirport && [destinationAirport.id],
            customerExecutionId: customerExecution && [customerExecution.id],
            consigneeId: consignee && [consignee.id],
        }
    }, [
        collectionServiceType,
        collectionDropoffPoint,
        customerExecution,
        deliveryServiceType,
        destinationAirport,
        handoverPoint,
        originAirport,
        account,
        tempRange,
        consignee,
    ])

    const onclickEdit = () => {
        onEdit()
        setActiveStep(index)
    }

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        includeFilters,
        availableLanes,
        setAvailableLanes,
    }), classes)

    return (
        <>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                className={classes.gridWrapper}
                name="OrderByNumberView"
                disabled={activeStep > index}
            />
            <div className={classes.actions}>
                {activeStep === index && (
                    <Button
                        label={!availableLanes?.data?.length ? 'Continue without lane' : 'Continue'}
                        onClick={() => { setActiveStep(index + 1) }}
                        disabled={!selectedLaneId && Boolean(availableLanes?.data?.length)}
                        data-testid="btn-continue"
                    />
                )}
                {activeStep > index && (
                    <Button
                        label="Edit"
                        onClick={onclickEdit}
                        className={classes.button}
                    />
                )}
            </div>
        </>
    )
}

export default SelectLaneStep
