import get from 'lodash/get'

const evaluateExpression = (expression, value) => {
    const expressionWithValues = []

    let nextIndex = 3

    let endIndex = 3

    while (true) { // eslint-disable-line no-constant-condition
        const newNextIndex = expression.indexOf(
            '{{',
            nextIndex + 1,
        )

        if (newNextIndex === -1) {
            break
        }

        const newEndIndex = expression.indexOf('}}', newNextIndex)

        const selector = expression.slice(
            newNextIndex + 2,
            newEndIndex,
        )

        const fieldValue = get(value, selector)

        expressionWithValues.push(
            expression.slice(endIndex + 2, newNextIndex) + fieldValue,
        )

        nextIndex = newNextIndex
        endIndex = newEndIndex
    }

    expressionWithValues.push(expression.slice(endIndex + 2, expression.length - 1))

    return eval(expressionWithValues.join('')) // eslint-disable-line no-eval
}

export default evaluateExpression
