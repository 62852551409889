import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            marginBottom: theme.spacing(3),
        },
        wrapper: {
            display: 'flex',
        },
        title: {
            '&&': {
                marginBottom: theme.spacing(2),
            },
        },
    }
})

export default useStyles
