import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tabsWrapper: {
            display: 'flex',
            marginLeft: theme.spacing(3),
            maxHeight: theme.shape.tabHeight + 1,
            '@media print': {
                display: 'none',
            },
        },
    }
})

export default useStyles
