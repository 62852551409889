import {
    useCallback,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

const useGetUntypedEnumLabel = () => {
    const {
        t,
    } = useTranslation()

    return useCallback((key) => {
        // TODO: this will result on message in console.log in debug mode
        //  if there are such key in enumsLabels.json,
        // alternative is to define if it's enum of table column level
        // but it's not in scope of this task
        const label = t(key, {
            ns: 'enums',
        })

        return label
    }, [t])
}

export default useGetUntypedEnumLabel
