import moment from 'moment'

import {
    PostponeInfo,
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getDefaultData = (orderSteps: OrderStep[], leaseStartTimestamp: string): PostponeInfo => {
    const [firstOrderStep] = orderSteps

    return {
        postponementReason: undefined,
        requestedPickup: firstOrderStep?.requestedPickup || firstOrderStep?.requestedExecution,
        requestedDelivery: firstOrderStep?.requestedDelivery,
        leaseStartTimestamp,
        postponementRequestedOn: moment().format('DD.MM.YYYY HH:mm ZZ'),
    }
}

export default getDefaultData
