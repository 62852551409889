import React from 'react'

import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import Typeahead from 'app/shared-components/Typeahead'
import useLabel from 'app/hooks/useLabel'
import {
    LaneFeeSelectorType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import config from '../../invoce.request'
import useLoadLabelLaneFee from '../hooks/useLoadLabelLaneFee'
import useStyles from './LaneFeeSelector.style'

const optionsParser = (laneFees: LaneFeeSelectorType[]) => {
    return laneFees.map(({
        laneFeesNumber, id,
    }) => {
        return {
            label: laneFeesNumber,
            value: id,
            id,
        }
    })
}

type Props = {
    value?: number,
    onChange: (value: any, ...params: any[]) => void,
    orderNumber: string,
}

const defaultProps = {
    value: undefined,
}

const LaneFeeSelector = ({
    orderNumber,
    onChange,
    value,
}: Props) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()

    const loadOptions = useDelayedLoadOptions({
        url: config.LaneFeeSelector.requestFn({
            orderNumber,
        }).url,
        convertion: optionsParser,
    })

    const {
        data,
    } = useLoadLabelLaneFee(value)

    return (
        <Typeahead
            name="laneFee"
            value={value}
            title={l('OutgoingInvoice.laneFee')}
            onChange={onChange}
            loadOptions={loadOptions}
            labelData={data}
            labelField="laneFeesNumber"
            classNames={{
                options: classes.options,
            }}
        />
    )
}

LaneFeeSelector.defaultProps = defaultProps

export default LaneFeeSelector
