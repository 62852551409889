import flatten from 'lodash/flatten'

import randomColor from 'app/utils/generateRandomColor'

const DEFAULT_AXIS_COLOR = '#333333'

const doorEventSeria = () => {
    return [
        {
            targetAxisIndex: 0,
            lineWidth: 2,
            color: randomColor(),
            pointSize: 0,
            visibleInLegend: true,
            tooltip: false,
            pointsVisible: false,
        },
        {
            targetAxisIndex: 0,
            lineWidth: 2,
            color: randomColor(),
            pointSize: 0,
            visibleInLegend: true,
            tooltip: false,
            pointsVisible: false,
        },
    ]
}

const getDoorEventSeriesOptions = (showDoorEvent, numberDoorEventLoggers) => {
    if (showDoorEvent) {
        let serias = []

        for (let i = 0; i < numberDoorEventLoggers; i++) {
            serias = [
                ...serias,
                doorEventSeria(),
            ]
        }

        const arr = flatten([...serias])

        return [
            ...arr,
            {
                targetAxisIndex: 0,
                lineWidth: 2,
                color: DEFAULT_AXIS_COLOR,
                pointSize: 1,
                visibleInLegend: false,
                pointsVisible: false,
                tooltip: false,
            },
        ]
    }
    return []
}

export default getDoorEventSeriesOptions
