import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        header: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }
})

export default useStyles
