import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'

import FxRatesRequests from '../FxRates.request'
import components from '../FxRate.fields'
import useStyles from '../FxRates.styles'

type Props = {
    name?: string,
}

const defaultProps: Partial<Props> = {
    name: '',
}

const Create = ({
    name,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const fields = useFieldsWithClassName(components(getAllowedValues), classes)

    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((newFxRate) => {
        addTableElement(newFxRate)
        selectDomainObject(newFxRate.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <FormWithControls
            name={name}
            fields={fields}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            onSuccess={onSuccess}
            requestParams={FxRatesRequests.create.request()}
        />
    )
}

Create.defaultProps = defaultProps

export default Create
