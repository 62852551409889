import React, {
    useCallback,
} from 'react'

import {
    LocationObjectSelector,
} from 'app/shared-components/LocationSelector'

import useStyles from './AirportsRoute.style'
import RouteViaAirport from './RouteViaAirport'
import {
    RouteSelectionType,
} from '../../simulation.types'

type Event = {
    target: {
        name: string,
        value: {id: number} | {id: number}[],
    }
}

type Props = {
    value: RouteSelectionType,
    onChange: (newVal: RouteSelectionType, event: {
        target: {
            name: string,
            value: RouteSelectionType,
        }
    }) => void,
    name: string,
}

const AirportsRoute = ({
    onChange,
    value,
    name,
}: Props) => {
    const {
        routeOriginAirport,
        routeViaAirports,
        routeDestinationAirport,
    } = value

    const {
        classes,
    } = useStyles()

    const onChangeSelection = useCallback((_, {
        target: {
            name: inputName,
            value: newValue,
        },
    }: Event): void => {
        onChange({
            ...value,
            [inputName]: newValue,
        }, {
            target: {
                value: {
                    ...value,
                    [inputName]: newValue,
                },
                name,
            },
        })
    }, [
        name,
        onChange,
        value,
    ])

    return (
        <div>
            <div className={classes.item}>
                <div className={classes.containerIcon}>
                    <div className={classes.circle} />
                    <div className={classes.verticalLine} />
                    <div className={classes.verticalLine} />
                    <div className={classes.verticalLine} />
                </div>
                <LocationObjectSelector
                    filterLocationType="AIRPORT"
                    name="routeOriginAirport"
                    value={routeOriginAirport}
                    onChange={onChangeSelection}
                    className={classes.locationInput}
                    classNames={{
                        label: classes.label,
                    }}
                    title="Origin Airport"
                    required
                />
            </div>
            <RouteViaAirport
                name="routeViaAirports"
                value={routeViaAirports}
                onChange={onChangeSelection}
            />
            <div className={classes.item}>
                <div className={classes.containerIcon}>
                    <img
                        className={classes.iconLocation}
                        alt="location"
                        src="/assets/images/icons/location.svg"
                    />
                </div>
                <LocationObjectSelector
                    name="routeDestinationAirport"
                    value={routeDestinationAirport}
                    onChange={onChangeSelection}
                    className={classes.locationInput}
                    classNames={{
                        label: classes.label,
                    }}
                    title="Destination Airport"
                    filterLocationType="AIRPORT"
                    required
                />
            </div>
        </div>
    )
}

export default AirportsRoute
