import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useCallback,
} from 'react'

import config from '../Damages.requests'
import {
    Damage,
} from '../Damages.types'

export default (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getDamage],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Damage>({
        key: RequestKeys.getDamage,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}
