import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    AdditionalFees,
    LaneFees,
    OutgoingInvoice,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import request from '../../customerTransport.request'

type Args = {
    orderNumber: string,
    selectedInvoice: OutgoingInvoice,
}

const useGetFeesInfo = ({
    orderNumber,
    selectedInvoice,
}: Args): {
    laneFees: LaneFees,
    additionalFees: AdditionalFees,
} => {
    const {
        data: laneFees,
    } = useRequest<LaneFees>({
        key: RequestKeys.getLaneFees,
        params: {
            orderNumber,
        },
        requestFunc: RequestFn.getEntity(request.LaneFees.requestFn),
        enabled: Boolean(selectedInvoice),
    })

    const {
        data: additionalFees,
    } = useRequest<AdditionalFees>({
        key: RequestKeys.getAdditionalFees,
        params: {
            orderNumber,
        },
        requestFunc: RequestFn.getEntity(request.AdditionalFees.requestFn),
        enabled: Boolean(selectedInvoice),
    })

    return {
        laneFees,
        additionalFees,
    }
}

export default useGetFeesInfo
