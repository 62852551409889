import React, {
    useEffect,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    CancelFeeConfirmationDialog,
} from 'app/shared-components/CancelFee'
import useCountOrdersAdditionalFee from '../hooks/useCountOrdersAdditionalFee'

type Props = {
    id: number,
    cancelFee: {
        askConfirmation: () => void,
        openConfirmDialog: boolean,
        doAction: (param: boolean) => void,
    }
    setReason: (param: string) => void,
    reason: string,
}

const AdditionalFeeCancellation = ({
    id,
    cancelFee,
    setReason,
    reason,
}: Props) => {
    const {
        openConfirmDialog,
        doAction,
    } = cancelFee

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useCountOrdersAdditionalFee(id, {
        openConfirmDialog,
    })

    useEffect(() => {
        if (isDataReady && openConfirmDialog && data?.orderCount === 0) {
            doAction(true)
        }
    }, [
        data?.orderCount,
        openConfirmDialog,
        doAction,
        isDataReady,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            { data?.orderCount > 0 ? (
                <CancelFeeConfirmationDialog
                    title="Additional Fee"
                    openConfirmDialog={openConfirmDialog}
                    handleClose={doAction}
                    reason={reason}
                    setReason={setReason}
                    orderCount={data?.orderCount}
                />
            ) : null }
        </StatusHandler>

    )
}

export default AdditionalFeeCancellation
