/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import StatusSelector from './StatusSelector'

const StatusSelectorContainer = (props) => {
    return (
        <StatusSelector
            {...props}
        />
    )
}

export default StatusSelectorContainer
