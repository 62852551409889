import PropTypes from 'prop-types'

const iconType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        render: PropTypes.func.isRequired,
    }),
])

export default iconType
