import {
    makeStyles,
} from 'app/tss'

const containerInformation = 'containerInformation'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${containerInformation} ${containerInformation} ${containerInformation} ${containerInformation}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        containerInformation: {
            gridArea: containerInformation,
        },
    }
})

export default useStyles
