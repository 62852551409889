const getFieldsWithClassName = (fields, classes) => {
    return fields.map(({
        className, ...field // eslint-disable-line no-shadow
    }) => {
        if (field.fields) {
            return {
                ...field,
                className: classes[className],
                fields: field.fields.map((item) => {
                    if (!classes[item.className]) {
                        return item
                    }

                    return {
                        ...item,
                        className: classes[item.className],
                    }
                }),
            }
        }

        if (!classes[className]) {
            return field
        }

        return {
            ...field,
            className: classes[className],
        }
    })
}

const injectClassName = (fields, classes) => {
    return fields.map(({
        className, ...field
    }) => {
        if (!field.fields) {
            if (!classes[className]) {
                return field
            }

            return {
                ...field,
                className: classes[className],
            }
        }

        if (!classes[className]) {
            return {
                ...field,
                fields: getFieldsWithClassName(field.fields, classes),
            }
        }

        return {
            ...field,
            className: classes[className],
            fields: getFieldsWithClassName(field.fields, classes),
        }
    })
}

const injectClass = (fields, classes) => {
    const fieldsWithClassName = injectClassName(fields, classes)

    return fieldsWithClassName.map(({
        classNames, ...field
    }) => {
        if (classNames) {
            return {
                ...field,
                classNames: classNames(classes),
            }
        }

        return field
    })
}

export default injectClass
