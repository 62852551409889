import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        newContent: {
            height: 240,
        },
        createText: {
            fontSize: theme.typography.h3.fontSize,
            color: theme.palette.secondary[600],
            marginTop: 100,
            textAlign: 'center',
            cursor: 'pointer',
        },
        radioContainer: {
            width: '80%',
            margin: '20px auto',
        },
        priceContainer: {
            borderBottom: `1px solid ${theme.palette.secondary[400]}`,
            textAlign: 'center',
            paddingTop: theme.spacing(1),
            position: 'relative',
            height: 40,
        },
        statusContainer: {
            height: 200,
            borderRadius: '0 0 4px 4px',
            paddingTop: theme.spacing(1),
            position: 'relative',
        },
        invoiceDate: {
            position: 'absolute',
            bottom: 5,
            right: 10,

        },
        radio: {
            flexDirection: 'column !important' as 'column',
        },
    }
})

export default useStyles
