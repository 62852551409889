import React from 'react'
import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'

import AlLeasingPricingUpdate from '../AlLeasingPricingUpdate'
import AlLeasePricingCopy from '../AlLeasePricingCopy'

type Props = {
    id: number,
}

const AlLeasePricingOverview = ({
    id,
}: Props) => {
    const {
        url,
    } = useRouteMatch()

    return (
        <Switch>
            <Route
                exact
                path={url}
            >
                <AlLeasingPricingUpdate id={id} />
            </Route>
            <Route
                path={`${url}/:copy`}
            >
                <AlLeasePricingCopy id={id} />
            </Route>
        </Switch>
    )
}

export default AlLeasePricingOverview
