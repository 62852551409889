import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import ChipSelect from 'app/shared-components/ChipSelect'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    SharedPositioningType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import config from './sharePositioningSelect.config'

type Props = {
    orderNumber: string,
    value?: SharedPositioningType[],
    disabled?: boolean,
    title: string,
    onChange: (val: any) => void,
}

const defaultProps: Partial<Props> = {
    value: undefined,
    disabled: false,
}

const SharePositioningSelect = ({
    orderNumber,
    disabled,
    onChange,
    title,
    value,
}: Props) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<SharedPositioningType[]>({
        key: RequestKeys.getSharePositioningList,
        params: {
            orderNumber,
        },
        requestFunc: RequestFn.getEntity(config.SharePositioningList.requestFn),
    })

    const options: {
        value: number,
        label: string,
    }[] = useMemo(() => {
        return data?.map((item) => {
            return {
                value: item.id,
                label: item.orderNumber,
            }
        })
    }, [data])

    const selectedItems = useMemo(() => {
        if (!value) {
            return []
        }

        return value.map((item) => {
            return {
                value: item.id,
                label: item.orderNumber,
            }
        })
    }, [value])

    const handleOnChange = (newValue: {
        value: number,
        label: string,
    }[]) => {
        onChange(newValue ? newValue.map((item) => {
            return {
                id: item.value,
                orderNumber: item.label,
            }
        }) : [])
    }

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ChipSelect
                disabled={disabled}
                options={options}
                title={title}
                value={selectedItems}
                onChange={handleOnChange}
                iconPath="/assets/images/order.svg"
            />
        </StatusHandler>
    )
}

SharePositioningSelect.defaultProps = defaultProps

export default SharePositioningSelect
