import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        icon: {
            paddingLeft: theme.spacing(1),
        },
    }
})

export default useStyles
