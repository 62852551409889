import flow from 'lodash/flow'
import Axios from 'axios'

import bearerToken from 'app/utils/api/bearerToken'
import addHostUrl from 'app/utils/api/addHostUrl'

import DOMAIN_SUBDIRECTORY from 'app/utils/api/constants'
import applySort from './requestEnhancers/applySort'
import applyStartRows from './requestEnhancers/applyStartRows'
import applyParseMatchedResults from './requestEnhancers/applyParseMatchedResult'
import applyFilter from './requestEnhancers/applyFilter'
import applySearch from './requestEnhancers/applySearch'
import applyArchived from './requestEnhancers/applyArchived'
import toLowerCaseEntityName from './requestEnhancers/toLowerCaseEntityName'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const skyMindDtoApi = (params) => {
    const modifiedQuery = flow([
        applyParseMatchedResults,
        applySort,
        applyStartRows,
        applyFilter,
        applySearch,
        applyArchived,
        toLowerCaseEntityName,
        bearerToken,
        addHostUrl(`${apiUrl}/${DOMAIN_SUBDIRECTORY}`),
    ])(Axios)

    return modifiedQuery(params)
}

export default skyMindDtoApi
