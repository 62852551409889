import React, {
    useEffect,
} from 'react'
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'
import {
    useJWTToken,
} from 'app/Auth'

import Apps from 'app/Apps'
import Layout from 'app/Layout'

const getEnviroment = () => {
    const url = document.location

    if (url.host.includes('pre')) {
        return ' (PRE)'
    }
    if (url.host.includes('test')) {
        return ' (TEST)'
    }
    if (url.host.includes('dev')) {
        return ' (DEV)'
    }
    return null
}

const Root = () => {
    const token = useJWTToken()

    useEffect(() => {
        const env = getEnviroment()

        if (env) {
            document.title = `SkyNet ${env}`
        }
    }, [])

    return (
        <Layout>
            <>
                {(process.env.NODE_ENV === 'development' || process.env.REACT_APP_TEST === 'test') && (
                    <div
                        style={{
                            display: 'none',
                        }}
                        data-testid="jwt-token"
                    >
                        {token}
                    </div>
                )}
            </>
            <Switch>
                <Route path="/apps">
                    <Apps />
                </Route>
                <Redirect
                    path="/"
                    to="/apps"
                />
            </Switch>
        </Layout>
    )
}

export default Root
