import React from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
} from '@mui/material'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './SmallContent.style'

const propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
}

const defaultProps = {
    title: '',
    text: '',
}

const SmallContent = ({
    title, text,
}) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <div
            className={classes.button}
        >
            <Typography
                variant="h2"
                className={classes.title}
            >
                {title}
            </Typography>
            <Typography
                variant="body1"
                className={classes.text}
            >
                {text}
            </Typography>

        </div>
    )
}

SmallContent.propTypes = propTypes
SmallContent.defaultProps = defaultProps

export default SmallContent
