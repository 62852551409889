import {
    BookedContainer,
    RejectedContainer,
} from '../bookedContainers.types'

const getSelectedContainersToReject = (data: BookedContainer[]): RejectedContainer[] => {
    return data.filter(({
        selected,
        isRejected,
    }: BookedContainer) => {
        return selected && !isRejected
    })
        .map(({
            id,
            rejectionComment,
            rejectionReason,
        }: BookedContainer) => {
            return {
                id,
                rejectionComment,
                rejectionReason,
            }
        })
}

export default getSelectedContainersToReject
