import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    useGetEngagementByContact,
} from 'app/Apps/RelationshipsManagement/Engagement'

import ContactForm from '../ContactForm/ContactForm'
import useGetContact from '../hooks/useGetContact'

const ContactOverview = ({
    id,
}: { id: number }) => {
    const {
        data: engagement,
        isDataReady: engagementIsDataReady,
    } = useGetEngagementByContact(id)
    const {
        data,
        isFetching,
        isDataReady,
        isError,
        error,
    } = useGetContact(id)

    return (
        <StatusHandler
            isSuccess={isDataReady && engagementIsDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContactForm
                data={{
                    ...data,
                    engagementId: engagement?.id,
                }}
                disabled
            />
        </StatusHandler>
    )
}

export default ContactOverview
