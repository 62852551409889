import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import clsx from 'clsx'
import Label from 'app/shared-components/Label'
import {
    strToDate,
} from 'app/utils/date'

import useStyles from './StatusAge.style'

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    title: PropTypes.string,
}

const defaultProps = {
    value: undefined,
    title: '',
}

const StatusAge = ({
    value,
    title,
}) => {
    const {
        classes,
    } = useStyles()

    const statusAgeTitle = useMemo(() => {
        if (value) {
            const momentDate = (typeof value === 'number') ? moment(value * 1000) : moment(strToDate(value))

            return `${momentDate.fromNow(true)}`
        }
        return ''
    }, [value])

    return (
        <div data-testid="status-age">
            {title && (
                <Label
                    title={title}
                />
            )}
            <div
                className={clsx(classes.upperText, {
                    [classes.text]: title,
                })}
                data-testid="status-age-text"
            >
                {statusAgeTitle}
            </div>
        </div>
    )
}

StatusAge.propTypes = propTypes
StatusAge.defaultProps = defaultProps

export default StatusAge
