import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    ModificationReason,
} from '../../orders.types'
import {
    ModificationFields,
} from './OrderChangeSummary.types'

const columns: RichTableColumns<ModificationFields>[] = [
    {
        id: 'fieldName',
        headerProps: {
            children: 'Change',
        },
        mapCellProps: 'fieldName',
        componentName: 'ModifiedField',
        labelPrefixKeys: [
            'OrderStep',
            'OrderBilling',
            'OrderByNumberView',
            'Order',
            'LaneGroup',
            'AdditionalFees',
        ],
    },
    {
        id: 'modificationReason',
        headerProps: {
            children: 'Reason',
        },
        mapCellProps: 'modificationReason',
        componentName: 'EnumValue',
        allowedValuesEnum: ModificationReason,
    },
    {
        id: 'modifiedBy',
        headerProps: {
            children: 'Modified By',
        },
        mapCellProps: 'modifiedBy',
    },
    {
        id: 'modifiedOn',
        headerProps: {
            children: 'Modified On',
        },
        mapCellProps: 'modifiedOn',
    },
    {
        id: 'previousValue',
        headerProps: {
            children: 'Previous Value',
        },
        mapCellProps: 'previousValue',
        componentName: 'ModifiedValue',
    },
    {
        id: 'updatedValue',
        headerProps: {
            children: 'Updated Value',
        },
        mapCellProps: 'updatedValue',
        componentName: 'ModifiedValue',
    },
]

export default columns
