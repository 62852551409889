import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useCallback,
} from 'react'
import config from '../ContainerType.request'
import {
    ContainerType,
} from '../../Containers/containers.types'

const useGetContainerTypes = () => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getContainerTypes],
        })
    }, [queryClient])

    const {
        data: containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<ContainerType[]>({
        key: RequestKeys.getContainerTypes,
        requestFunc: RequestFn.getEntity(config.fetchAll, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
        staleTime: Infinity,
        keepPreviousData: true,
    })

    return {
        containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetContainerTypes
