import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            overflow: 'auto',
        },
    }
})

export default useStyles
