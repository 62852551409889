import toDateWithHoursSafe from './toDateWithHoursSafe'

const toDateRangeLimitSafe = (date: Date | number | string, isMaxLimit = false): number => {
    return toDateWithHoursSafe(date, isMaxLimit ? [
        23,
        59,
        59,
    ] : [
        0,
        0,
        0,
    ])
}

export default toDateRangeLimitSafe
