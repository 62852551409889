const dtoSpec = {
    ContractBasis: {
        Create: {
            name: 'ContractBasisCreateDto',
            request: () => {
                return {
                    url: 'contractbasis',
                    method: 'POST',
                }
            },
            refresh: 'ContractBasis',
        },
        Update: {
            name: 'ContractBasisUpdateDto',
            request: ({
                id,
            }) => {
                return {
                    url: `contractbasis/${id}/data`,
                    method: 'PATCH',
                }
            },
            refresh: 'ContractBasis',
        },
        Archive: {
            request: ({
                id,
            }) => {
                return {
                    url: `contractbasis/${id}/archive`,
                    method: 'PATCH',
                }
            },
            url: '/apps/pricing/contract-basis/all',
        },
        Restore: {
            request: ({
                id,
            }) => {
                return {
                    url: `contractbasis/${id}/restore`,
                    method: 'PATCH',
                }
            },
        },
        BillingInformationUpdate: {
            name: 'ContractBasisBillingInformationUpdateDto',
            request: ({
                id,
            }) => {
                return {
                    url: `contractbasis/${id}/billing-information`,
                    method: 'PATCH',
                    domainName: 'ContractBasisBillingInformationUpdateDto',
                }
            },
        },
        ProductTypes: {
            request: {
                url: 'contractbasis/producttype/all',
                method: 'GET',
            },
        },
        ContractWithCurrency: {
            Create: {
                prapareRequest: {
                    domainName: 'ContractWithCurrency',
                    method: 'POST',
                    name: 'ContractWithCurrency',
                },
                makeRequest: ({
                    currency,
                    contractId,
                }) => {
                    return {
                        data: {
                            currency,
                        },
                        url: `contractbasis/${contractId}/currency`,
                    }
                },
            },
        },
        QuotePrint: {
            PreviewRequest: {
                url: 'quote/quotedocument/preview/json',
                method: 'POST',
            },
            GenerateRequest: {
                url: 'quote/quotedocument/preview/pdf',
                method: 'POST',
            },
        },
    },
    Quote: {
        QuotePrint: {
            GenerateRequest: (id) => {
                return {
                    url: `quote/${id}/quotedocument/generation`,
                    method: 'POST',
                }
            },
        },
    },
    FxRate: {
        Get: (id) => {
            return `fxrate/${id}`
        },
    },
}

export default dtoSpec
