import React, {
    useCallback,
    useMemo,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    OrderTemperatureRange,
} from 'app/types/enums'
import {
    ContainerType,
} from './ContainerTypes.types'

import AddForm from './AddForm'
import RequiredContainers from '../RequiredContainers'
import config from './RequiredContainersByContainerType.config'

type OrderTemperatureRangeWithoutBT = Omit<OrderTemperatureRange, 'BT'>
type Props = {
    value?: RequiredContainersInternal[],
    disabled?: boolean,
    required?: boolean,
    temperatureRange?: OrderTemperatureRangeWithoutBT,
    onChange: (changeValue: RequiredContainersInternal[]) => void,
}

const defaultProps = {
    value: [],
    disabled: false,
    required: true,
    temperatureRange: undefined,
}

const RequiredContainersByContainerType = (props: Props) => {
    const {
        value,
        onChange,
        disabled,
        required,
        temperatureRange,
    } = props

    const applicableTempRange: OrderTemperatureRangeWithoutBT[]
        | undefined = useMemo(() => {
            if (!temperatureRange || temperatureRange === OrderTemperatureRange.ANY_TEMP_RANGE) {
                return undefined
            }

            return [temperatureRange]
        }, [temperatureRange])

    const {
        data: containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<ContainerType[]>({
        key: RequestKeys.getContainerTypes,
        params: {
            includeFilters: {
                applicableTempRange,
            },
            sortField: 'containerModel',
            sortType: 'asc',
        },
        requestFunc: RequestFn.getEntity(config.ContainerTypes.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
    })

    const chipValues: {value: number, label: string}[] = useMemo(() => {
        return value?.map(({
            quantity,
            containerType: {
                containerModel,
            },
        }, i) => {
            return {
                value: i,
                label: `${quantity} x ${containerModel}`,
            }
        })
    }, [value])

    const addRequiredContainers = useCallback((requiredContainer): void => {
        const isExist = value.some(({
            containerType,
        }) => {
            return containerType.id === requiredContainer.containerType.id
        })
        const newValue = isExist ? value.map((item) => {
            if (item.containerType.id === requiredContainer.containerType.id) {
                return {
                    ...item,
                    quantity: item.quantity + requiredContainer.quantity,
                }
            }
            return item
        }) : [
            ...value,
            {
                quantity: requiredContainer.quantity,
                containerType: {
                    id: requiredContainer.containerType.id,
                    containerModel: requiredContainer.containerType.containerModel,
                },
            },
        ]

        onChange(newValue)
    }, [
        onChange,
        value,
    ])

    return (
        <RequiredContainers
            value={value}
            chipValues={chipValues}
            onChange={onChange}
            disabled={disabled}
        >
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <AddForm
                    data={containerTypes}
                    addRequiredContainers={addRequiredContainers}
                    temperatureRange={temperatureRange}
                    required={required}
                />
            </StatusHandler>
        </RequiredContainers>
    )
}

RequiredContainersByContainerType.defaultProps = defaultProps

export default RequiredContainersByContainerType
