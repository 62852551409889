import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
        },
        content: {
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.typography.body1.color,
            fontSize: theme.typography.body1.fontSize,
        },
        radioWrapper: {
            padding: '3px 5px 3px 9px !important',
        },
    }
})

export default useStyles
