import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import ActivityRecordForm from '../ActivityRecordForm'
import useGetActivityRecord from '../../hooks/useGetActivityRecord'

const ActivityRecordOverview = ({
    id,
}: { id: number }) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetActivityRecord(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ActivityRecordForm
                data={data}
                disabled
            />
        </StatusHandler>
    )
}

export default ActivityRecordOverview
