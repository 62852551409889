/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import InputSingleline from '../InputSingleline'

import useStyles from './StatusSelector.styles'

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.shape({
        statusLabelOld: PropTypes.string,
        statusLabelNew: PropTypes.string,
    }).isRequired,
}

const defaultProps = {
    name: 'statusSelector',
}

const StatusSelector = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        name,
        value: {
            statusLabelOld,
            statusLabelNew,
        },
    } = props

    return (
        <div
            className={classes.root}
            data-testid={`statusSelector-${name}`}
        >
            <InputSingleline
                {...props}
                title="Status Old"
                value={statusLabelOld}
            />
            <ArrowDownwardIcon
                className={clsx([
                    'text-4xl',
                    classes.arrow,
                ])}
            />
            <InputSingleline
                {...props}
                title="Status New"
                value={statusLabelNew}
            />
        </div>
    )
}

StatusSelector.propTypes = propTypes
StatusSelector.defaultProps = defaultProps

export default StatusSelector
