import React, {
    useMemo,
    useCallback,
} from 'react'

import {
    RequestKeys,
} from 'app/hooks/useRequest'

import Select from 'app/shared-components/Select'
import useRequestTable from 'app/hooks/useRequestTable'

import {
    Area,
} from './AreaSelector.types'

import request from './AreaSelector.request'

type Props = {
    locationId?: number,
    title: string,
    name: string,
    value?: {
        id: string,
    },
    required?: boolean,
    disabled?: boolean,
    onChange: (...args: any[]) => void,
}

const defaultProps: Partial<Props> = {
    locationId: undefined,
    value: undefined,
    required: false,
    disabled: false,
}

const AreaSelector = ({
    locationId,
    title,
    name,
    disabled,
    required,
    value,
    onChange,
}:Props) => {
    const {
        data,
        isDataReady,
    } = useRequestTable<{ data: Area[] }>({
        config: request.filterRequest,
        key: RequestKeys.getLocationArea,
        params: {
            id: locationId,
        },
        enabled: Boolean(locationId),
        keepPreviousData: true,
    })

    const allowedValues = useMemo(() => {
        if (!isDataReady) {
            return {}
        }
        return data.data.reduce((ac, area) => {
            return {
                ...ac,
                [area.id]: area.areaName,
            }
        }, {})
    }, [
        data,
        isDataReady,
    ])

    const handleChange = useCallback((val, target) => {
        const parsedValue = val ? {
            id: val,
        } : undefined

        onChange(parsedValue, {
            target: {
                name: target.name,
                value: parsedValue,
            },
        })
    }, [onChange])

    return (
        <Select
            title={title}
            allowedValues={allowedValues}
            value={value?.id}
            name={name}
            onChange={handleChange}
            disabled={disabled}
            required={required}
        />
    )
}

AreaSelector.defaultProps = defaultProps

export default AreaSelector
