import React, {
    useCallback, useState,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import {
    useFilterContext,
} from 'app/hooks/useFilter'
import UnsavedChangesPrompt from 'app/shared-components/UnsavedChangesPrompt'
import Filter from './FilterContainer'

const defaultProps = {
    loadFilters: undefined,
}

// TODO-DK: add other filterTypes for usage with FilterContext
// for now only FilterType.Array is supported
const FilterContextAdapter = ({
    name,
    loadFilters,
}: {
    name: string,
    loadFilters?: () => void
}) => {
    const filterContext = useFilterContext()
    const [
        filterAction,
        setFilterAction,
    ] = useState<() => void>(undefined)

    const setFilter = useCallback((value) => {
        if (!filterContext) return

        if (!value.length || isEmpty(value)) {
            filterContext.removeFilter({
                filterField: name,
            })
            return
        }

        filterContext.setFilter({
            filterField: name,
            value,
        })
    }, [
        filterContext,
        name,
    ])

    const set = useCallback((value) => {
        if (!filterContext) return

        if (filterContext?.edited) {
            setFilterAction(() => {
                return () => {
                    return setFilter(value)
                }
            })
            return
        }

        setFilter(value)
    }, [
        filterContext,
        setFilter,
    ])

    const close = useCallback(() => {
        setFilterAction(undefined)
    }, [])

    const action = useCallback(() => {
        if (filterContext?.beforeFilterCallback) {
            filterContext.beforeFilterCallback(filterAction)
            return
        }
        filterAction()
    }, [
        filterAction,
        filterContext,
    ])

    return (
        <>
            <Filter
                loadFilters={loadFilters}
                set={set}
                filter={filterContext.filter[name]}
            />
            <UnsavedChangesPrompt
                open={Boolean(filterAction)}
                close={close}
                action={action}
                discard={filterAction}
            />
        </>

    )
}

FilterContextAdapter.defaultProps = defaultProps

export default FilterContextAdapter

export const getFilterContextAdapter = ({
    name, loadFilters,
}) => {
    return () => {
        return (
            <FilterContextAdapter
                name={name}
                loadFilters={loadFilters}
            />
        )
    }
}
