import React from 'react'

import {
    MainMeansOfTransport,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const TransportMode = ({
    value,
}: {
    value: MainMeansOfTransport
}) => {
    if (!value) {
        return null
    }

    return (
        <img
            src={`assets/images/orderSteps/${value?.toLowerCase()}.svg`}
            alt={value}
        />
    )
}

export default TransportMode
