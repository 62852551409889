const injectOnLoad = (onLoad) => {
    return (cb) => {
        return (params) => {
            return cb(params)
                .then((res) => {
                    onLoad(res)

                    return res
                })
        }
    }
}

export default injectOnLoad
