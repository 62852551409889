const replaceTimezone = (timeZone) => {
    const chars = {
        PLUS_: '+',
        MINUS_: '-',
    }

    return timeZone.replace(/PLUS_|MINUS_/g, (x) => {
        return chars[x]
    })
}

export default replaceTimezone
