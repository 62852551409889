import {
    makeStyles,
} from 'app/tss'

export default makeStyles()(() => {
    return {
        table: {
            maxHeight: '50vh',
        },
        tableCellHead: {
            fontWeight: 600,
        },
        tableCell: {
            padding: '5px !important',
        },
    }
})
