/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Link from './Link'

const LinkContainer = (props) => {
    return (
        <Link
            {...props}
        />
    )
}

export default LinkContainer
