import React, {
    useCallback, useEffect,
    useState,
} from 'react'
import moment from 'moment'

import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation'
import useGetLane from 'app/Apps/Pricing/LaneGroups/Lanes/hooks/useGetLane'
import strToDate from 'app/utils/date/strToDate'

import PreBookingLaneDetails from './PreBookingLaneDetails'
import usePreBookingInfoContext from '../PreBookingInfoContext/PreBookingInfoContext'
import {
    PreBookingEntity,
} from '../../PreBooking.types'
import useGetPreBookingLaneDetailsFields from './hooks/useGetPreBookingLaneDetailsFields'

type Props = {
    disabled: boolean,
    isLoading: boolean,
    nextAction: (a: PreBookingEntity) => void | Promise<void>,
    nextLabel?: string,
    defaultValues?: Partial<PreBookingEntity>,
}

const defaultProps: Partial<Props> = {
    nextLabel: 'Create',
    defaultValues: {},
}

const PreBookingLaneDetailsContainer = ({
    disabled,
    nextAction,
    nextLabel,
    defaultValues,
    isLoading,
}: Props) => {
    const {
        activeStep,
        setActiveStep,
        value,
        setIsEdited,
    } = usePreBookingInfoContext()

    const {
        data: laneData,
        isDataReady,
    } = useGetLane(value.lane?.id)

    const [
        formValues,
        setFormValues,
    ] = useState<PreBookingEntity>({
        isForecasted: true,
        ...value,
        ...defaultValues,
    })
    const laneSelected = !!value.lane
    const [
        saving,
        setSaving,
    ] = useState<boolean>(false)

    useEffect(() => {
        if (isDataReady && laneData) {
            setFormValues((prevState) => {
                return {
                    ...prevState,
                    customerExecution: laneData.customerExecution,
                    pricingAccount: laneData.pricingAccount,
                    originAirport: laneData.originAirport,
                    destinationAirport: laneData.destinationAirport,
                    originServiceCenter: laneData.originSc,
                }
            })
        }
    }, [
        laneData,
        isDataReady,
    ])

    const {
        fields,
        isDataReady: fieldsReady,
    } = useGetPreBookingLaneDetailsFields({
        disabled,
        laneSelected,
        tempRange: laneData?.tempRange,
        requestedHandover: formValues?.requestedHandover,
    })

    const {
        isValid,
    } = useValidation({
        fields, values: formValues,
    })
    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
    }, [
        setActiveStep,
        activeStep,
    ])

    const handleNextAction = useCallback(async () => {
        setSaving(true)
        await nextAction(formValues)
        setSaving(false)
    }, [
        formValues,
        nextAction,
    ])

    const handleOnChange = useCallback((params: PreBookingEntity) => {
        setFormValues((prevState) => {
            setIsEdited(true)
            const newFormValues = {
                ...params,
            }

            if (params.requestedHandover) {
                const date = strToDate(params.requestedHandover)

                newFormValues.requestedHandoverYear = date.getFullYear()
                newFormValues.requestedHandoverWeek = moment(date).week()
            } else if (prevState.requestedHandover && params.requestedHandover === null) {
                newFormValues.requestedHandoverYear = undefined
                newFormValues.requestedHandoverWeek = undefined
            }
            return {
                ...prevState,
                ...newFormValues,
            }
        })
    }, [setIsEdited])

    return (
        <div>
            {fieldsReady && (
                <PreBookingLaneDetails
                    disabled={disabled}
                    onChange={handleOnChange}
                    formValues={formValues}
                    fields={fields}
                />
            )}
            <SkyNetStepperButtons
                isValid={isValid || saving}
                disabled={disabled}
                canGoBack
                backAction={backAction}
                nextLabel={nextLabel}
                nextAction={handleNextAction}
                isLoading={isLoading}
            />
        </div>
    )
}

PreBookingLaneDetailsContainer.defaultProps = defaultProps

export default PreBookingLaneDetailsContainer
