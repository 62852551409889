import {
    makeStyles,
} from 'app/tss'

const positioningSetup = 'positioningSetup'
const requiredContainers = 'requiredContainers'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateAreas: `
                "${positioningSetup} ${positioningSetup} ${positioningSetup} ${requiredContainers}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        requiredContainers: {
            gridArea: requiredContainers,
        },
        positioningSetup: {
            gridArea: positioningSetup,
        },
        numberOfTrucks: {
            '&&': {
                width: '50%',
                paddingRight: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        numberOfVans: {
            '&&': {
                width: '50%',
                paddingLeft: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
