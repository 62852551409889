const kbInBytes = 1024
const sizesUnits = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
]

const bytesToHumanReadable = (bytes, delimeters = 2) => {
    if (!bytes) {
        return null
    }
    if (bytes === 0) {
        return '0 Bytes'
    }
    const unitPrefix = Math.floor(Math.log(bytes) / Math.log(kbInBytes))

    return `(${parseFloat((bytes / (kbInBytes ** unitPrefix)).toFixed(delimeters))} ${sizesUnits[unitPrefix]})`
}

export default bytesToHumanReadable
