import React, {
    useMemo,
} from 'react'

import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'

import {
    LocationAreasContext,
    LocationAreasContextType,
} from '../hooks/useLocationAreasContext'

const LaneGroupContextWrapper = ({
    id, children,
}: { id: number, children: JSX.Element }) => {
    const {
        url,
    } = useRouteMatch()
    const locationAreasContext = useMemo<LocationAreasContextType>(() => {
        return {
            locationId: id,
        }
    }, [id])

    return (
        <LocationAreasContext.Provider value={locationAreasContext}>
            <Switch>
                <Route
                    path={`${url}/:id?/:action?`}
                >
                    {children}
                </Route>
            </Switch>
        </LocationAreasContext.Provider>
    )
}

export default LaneGroupContextWrapper
