/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

type SelectorProps = Record<string, any> & {
    onChange: (params: any, event?: Event) => void,
}

type Props<ComponentProps = Record<string, any>> = ComponentProps & {
    onChange: (id: string | number, ...args: any[])=>void,
    selector: (props: ComponentProps | SelectorProps)=> JSX.Element,
}

type Event = {
    target: {
        name: string,
        value: { id: string | number },
    }
}

const ObjectToIdSelectorConverter = <ComponentProps, >(props: Props<ComponentProps>) => {
    const {
        selector: Component,
        onChange,
        ...rest
    } = props

    const onIdChange = (val: { id: string | number }, event?: Event) => {
        return onChange(val.id, event && {
            target: {
                name: event.target?.name,
                value: event.target?.value?.id,
            },
        })
    }

    return (
        <Component
            onChange={onIdChange}
            {...rest}
        />
    )
}

export default ObjectToIdSelectorConverter
