import {
    useMemo,
    useState,
} from 'react'

import {
    NOT_ALLOWED_CANCEL_MODIFY_ORDER_STATUSES,
} from 'app/Apps/OrderManagement/Orders/utils/orderStatus'
import {
    OrderStatuses,
} from 'app/types/enums'

import {
    CancelOrderData,
} from '../orders.types'

const useCancelOrder = ({
    orderStatus,
}: {orderStatus: OrderStatuses}): CancelOrderData => {
    const [
        openCancellation,
        setCancellationOpen,
    ] = useState<boolean>(false)

    const isShowCancel: boolean = useMemo(() => {
        return !NOT_ALLOWED_CANCEL_MODIFY_ORDER_STATUSES.includes(orderStatus)
    }, [orderStatus])

    return {
        isShowCancel,
        openCancellation,
        setCancellationOpen,
    }
}

export default useCancelOrder
