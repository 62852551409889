import {
    useCallback,
} from 'react'
import {
    Id,
} from '@silevis/reactgrid'

// deletes selected row(s)
export default (setData: React.Dispatch<React.SetStateAction<Record<string, any>[]>>) => {
    return useCallback((
        selectedRowIds: Id[],
    ) => {
        setData((prev) => {
            const next = JSON.parse(JSON.stringify(prev))

            return next.filter((el, i) => {
                return !selectedRowIds.includes(i)
            })
        })
    }, [setData])
}
