import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        oldReading: {
            color: theme.palette.common.red,
        },
        recentReading: {
            color: theme.palette.common.yellow,
        },
        liveReading: {
            color: theme.palette.common.green,
        },
    }
})

export default useStyles
