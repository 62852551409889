import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderStatuses,
    ProductType,
} from 'app/types/enums'
import {
    InternalTransportTableType,
    OrderSubType,
    SeaFreightCarrier,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const internalTransportConfig: SkyNetTableConfig<keyof InternalTransportTableType> = {
    name: 'OrderManagement.IT_Orders',
    tabs: [{
        name: 'All Orders',
        url: 'all',
    }],
    fields: [
        {
            name: 'orderNumber',
            reduced: true,
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'destinationCountry',
            filterField: 'destinationCountry',
            filterType: FilterType.Array,
        },
        {
            name: 'destinationLocationLocationName',
            filterField: 'destinationLocationLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'originLocationLocationName',
            filterField: 'originLocationLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'plannedPickup',
            filterField: 'plannedPickup',
            filterType: FilterType.DateRange,
            labelKey: 'PostponementOrderDto.requestedPickup',
        },
        {
            name: 'requestedDelivery',
            labelKey: 'Order.requestedDelivery',
            filterField: 'requestedDelivery',
            filterType: FilterType.DateRange,
        },
        {
            name: 'forwarderExport',
            labelKey: 'Order.forwarderExport',
            filterField: 'forwarderExport',
            filterType: FilterType.Array,
        },
        {
            name: 'forwarderImport',
            labelKey: 'Order.forwarderImport',
            filterField: 'forwarderImport',
            filterType: FilterType.Array,
        },
        {
            name: 'orderStatus',
            filterField: 'orderStatus',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: OrderStatuses,
            labelKey: 'OrderView.orderStatus',
        },
        {
            name: 'orderSubType',
            componentName: 'EnumValue',
            allowedValuesEnum: OrderSubType,
            reduced: true,
            filterField: 'orderSubType',
            filterType: FilterType.Array,
        },
        {
            name: 'masterNumber',
            labelKey: 'Order.masterNumber',
            sorting: false,
        },
        {
            name: 'houseNumber',
            labelKey: 'Order.houseNumber',
            sorting: false,
        },
        {
            name: 'carrier',
            componentName: 'EnumValue',
            allowedValuesEnum: SeaFreightCarrier,
            labelKey: 'Order.carrier',
            sorting: false,
        },
        {
            name: 'mainMeansOfTransport',
            componentName: 'TransportMode',
            labelKey: 'OrderPoolingInternalTransportsView.mode',
            width: '150px',
            filterField: 'mainMeansOfTransport',
            filterType: FilterType.Array,
        },
        {
            name: 'requiredContainers',
            componentName: 'RequiredContainersList',
            allowedValuesEnum: ProductType,
            sorting: false,
            labelKey: 'Order.requiredContainers',
        },
    ],
}

export default internalTransportConfig
