import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        chip: {
            '&&': {
                margin: theme.spacing(0.5, 0.5, 0.5, 0),
                backgroundColor: theme.palette.secondary[200],
                color: theme.palette.common.black,
            },
        },
        text: {
            color: theme.palette.secondary[600],
            fontSize: theme.typography.body1.fontSize,
        },
        selectorContainer: {
            marginLeft: 5,
        },
        chipsWrapper: {
            minHeight: 32,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        requiredMark: {
            position: 'absolute',
            left: -8,
            backgroundColor: theme.palette.primary.main,
            width: 6,
            height: 'fill-available',
            borderRadius: 3,
        },
        isRequired: {
            backgroundColor: theme.palette.primary[30],
        },
    }
})

export default useStyles
