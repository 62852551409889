import React, {
    useEffect, useMemo,
} from 'react'

import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    CollServiceType, Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import validateForm from 'app/utils/validateForm'

import useStyles from './LaneImplementationStep.style'
import getFields from './laneImplementationStep.fields'
import useLaneUpdate from './hooks/useLaneUpdate'

const LaneImplementationStep = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
    laneData,
    onReset,
    onEdit,
}: {
    activeStep: number,
    setActiveStep: (val: number) => void,
    onChange: (val: Lane) => void,
    index: number,
    value: Lane,
    laneData: Lane,
    onReset: () => void,
    onEdit: () => void,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    useEffect(() => {
        onChange(laneData)
    }, []) // eslint-disable-line

    const {
        reqCustomsCoord,
        collectionServiceType,
        deliveryServiceType,
        id,
    } = value
    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        reqCustomsCoord,
        isDropOffSkyCell: collectionServiceType === CollServiceType.DROPOFF_SKYCELL,
        deliveryServiceType,
    }), classes)

    const onSuccessUpdateLane = () => {
        setActiveStep(index + 1)
    }

    const {
        isPending: isLoading,
        mutate,
    } = useLaneUpdate({
        onSuccess: onSuccessUpdateLane,
        id,
    })

    const onClickContinue = () => {
        mutate(value)
    }

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value,
        })
    }, [
        fields,
        value,
    ])

    const onClickBack = () => {
        onReset()
        setActiveStep(index - 1)
    }

    const onClickEdit = () => {
        setActiveStep(index)
        onEdit()
    }

    return (
        <div>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                className={classes.gridWrapper}
                name="OrderByNumberView"
                disabled={activeStep > index}
            />
            <div className={classes.actions}>
                {activeStep === index && (
                    <>
                        <Button
                            label="Continue"
                            onClick={onClickContinue}
                            className={classes.button}
                            disabled={!isFormValid}
                            saving={isLoading}
                            data-testid="btn-continue"
                        />
                        <Button
                            label="Back"
                            secondary
                            onClick={onClickBack}
                            className={classes.button}
                            data-testid="btn-back"
                        />
                    </>
                )}
                {activeStep > index && (
                    <Button
                        label="Edit"
                        onClick={onClickEdit}
                        className={classes.button}
                        data-testid="btn-edit"
                    />
                )}
            </div>
        </div>
    )
}

export default LaneImplementationStep
