import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import CountriesRequest from './Countries.request'

import CountryOverview from './CountryOverview'
import tableConfig from './Country.config'

const Countries = () => {
    const tabs: SidebarTab[] = [{
        url: 'overview',
        label: 'Overview',
        Component: CountryOverview,
    }]

    const tableControls: TableControlTypes[] = [TableControlTypes.ClearSorting]

    return (
        <SkyNetTable
            name="CountryView"
            moduleName={CountriesRequest.domainName}
            tableControls={tableControls}
            tabs={tabs}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Countries
