import React from 'react'
import clsx from 'clsx'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    ContainerStatus,
    ContainerProcess,
} from 'app/types/enums'
import useStyles from './ContainerStatusEnriched.style'

type Props = {
    rowProps: {
        containerStatus: string,
    }
    value: string,
}

const CombinedContainerStatuses = {
    ...ContainerStatus,
    ...ContainerProcess,
}

const ContainerStatusEnriched = (props: Props) => {
    const {
        rowProps,
        value,
    } = props

    const {
        containerStatus,
    } = rowProps

    const {
        classes,
    } = useStyles()
    const containerStatuses = useEnumValues()(CombinedContainerStatuses)

    return (
        <div className={classes.wrapper}>
            <div
                className={clsx(classes.containerStatusEnriched, classes.noWrap)}
                title={containerStatuses[value]}
            >
                {containerStatuses[value]}
            </div>
            { value !== containerStatus && (
                <div
                    className={clsx(classes.containerStatus, classes.noWrap)}
                    title={containerStatuses[containerStatus]}
                    data-testid="container-status"
                >
                    {containerStatuses[containerStatus]}
                </div>
            )}
            {value === containerStatus && value === ContainerStatus.QC_PASSED && (
                <img
                    className={classes.icon}
                    src="/assets/images/icons/checked.svg"
                    alt="None"
                />
            )}
        </div>
    )
}

export default ContainerStatusEnriched
