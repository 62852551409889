import {
    makeStyles,
} from 'app/tss'

export default makeStyles<{isAlert: boolean}>()((theme, {
    isAlert,
}) => {
    return {
        alertColor: {
            color: isAlert ? theme.palette.common.red : undefined,
        },
    }
})
