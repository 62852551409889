import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    DeliveryServiceType,
    CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    lanes,
} from '../Dashboard.types'

const lanesConfig: SkyNetTableConfig<keyof lanes> = {
    name: 'lanesToBePriced',
    tabs: [{
        name: 'To Be Priced',
        url: 'all',
    }],
    fields: [
        {
            name: 'laneNumber',
            labelKey: 'AccountLane.laneNumber',
        },
        {
            name: 'laneGroupNumber',
            labelKey: 'LaneGroup.laneGroupNumber',
        },
        {
            name: 'customerQuotation',
            labelKey: 'LaneGroupView.customerQuotationLocationName',
            filterField: 'customerQuotation',
            filterType: FilterType.Array,
        },
        {
            name: 'customerExecution',
            labelKey: 'LaneQuoteRequest.selectedCustomerExecution',
        },
        {
            name: 'pricingAccountCompanyName',
            labelKey: 'Lane.pricingAccount',
            filterField: 'pricingAccountCompanyName',
            filterType: FilterType.Array,
        },
        {
            name: 'consignee',
            labelKey: 'Order.consignee',
        },
        {
            name: 'deliveryServiceType',
            labelKey: 'Order.deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
            filterField: 'deliveryServiceType',
            filterType: FilterType.Array,
        },
        {
            name: 'collectionServiceType',
            labelKey: 'LaneGroup.collectionServiceType',
            filterType: FilterType.Array,
            filterField: 'collectionServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: CollServiceType,
        },
        {
            name: 'originAirport',
            labelKey: 'OrderView.from',
            filterField: 'originAirport',
            filterType: FilterType.Array,
        },
        {
            name: 'destinationAirport',
            labelKey: 'OrderView.to',
            filterField: 'destinationAirport',
            filterType: FilterType.Array,
        },
        {
            name: 'temperatureRange',
            labelKey: 'PriceDashboard.temp',
        },
        {
            name: 'changedOn',
            labelKey: 'KamDashboard.lastUpdated',
        },
    ],
}

export default lanesConfig
