import {
    useCallback,
    useEffect,
} from 'react'
import {
    KeyboardKeys,
} from '../../types/common.enums'

// key codes
const keys = [
    KeyboardKeys.ARROW_LEFT,
    KeyboardKeys.ARROW_UP,
    KeyboardKeys.ARROW_RIGHT,
    KeyboardKeys.ARROW_DOWN,
]

const wheelOpt = {
    passive: false,
} as EventListenerOptions

const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

export default function useScrollOptions(ref) {
    const preventDefault = useCallback((e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            e.preventDefault()
        }
    }, [ref])

    const preventDefaultForScrollKeys = useCallback((e) => {
        if (keys.includes(e.key) && ref.current && !ref.current.contains(e.target)) {
            preventDefault(e)
            return false
        }
        return true
    }, [
        ref,
        preventDefault,
    ])

    useEffect(() => {
        window.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
        window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
        window.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
        window.addEventListener('keydown', preventDefaultForScrollKeys, false)

        return () => {
            window.removeEventListener('DOMMouseScroll', preventDefault, false)
            window.removeEventListener(wheelEvent, preventDefault, wheelOpt)
            window.removeEventListener('touchmove', preventDefault, wheelOpt)
            window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
        }
    }, [
        preventDefault,
        preventDefaultForScrollKeys,
    ])
}
