import {
    makeStyles,
} from 'app/tss'

const originDestination = 'originDestination'
const consignee = 'consignee'
const general = 'general'
const customerExecution = 'customerExecution'
const billingInformation = 'billingInformation'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapper: {
            display: 'grid',
            padding: theme.spacing(1),
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
                "${originDestination} ${consignee} ${customerExecution}"
                "${billingInformation} ${general} ."
            `,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        originDestination: {
            gridArea: originDestination,
        },
        consignee: {
            gridArea: consignee,
        },
        customerExecution: {
            gridArea: customerExecution,
        },
        billingInformation: {
            gridArea: billingInformation,
        },
        general: {
            gridArea: general,
        },
    }
})

export default useStyles
