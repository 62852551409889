import React, {
    useState,
    useCallback, useMemo,
} from 'react'
import clsx from 'clsx'

import useHasPermission from 'app/hooks/useHasPermission'
import {
    Collapse,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Typography from '@mui/material/Typography'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'

import CreateContainerNote from './components/CreateContainerNote'
import ContainerNoteDetails from './components/ContainerNoteDetails'
import useStyles from './ContainerNotes.styles'
import columns from './ContainerNotes.columns'
import useContainerNotesFilterRequest from './hooks/useContainerNotesFilterRequest'

type Props = {
    id: number,
}

const FORM_OBJECT_NAME = 'Note'
const DOMAIN_NAME = 'ContainerNoteView'

const ContainerNotes = ({
    id,
}: Props) => {
    const noteRead = useHasPermission(['note_read'])
    const noteCreate = useHasPermission(['note_create'])
    const {
        load,
        refresh,
    } = useContainerNotesFilterRequest(id)

    const {
        classes,
    } = useStyles()

    const [
        newNote,
        setNewNote,
    ] = useState(false)

    const handleCancelNew = useCallback(() => {
        setNewNote(false)
    }, [])

    const handleAddNote = useCallback(() => {
        setNewNote(!newNote)
    }, [newNote])

    const defaultFilter = useMemo(() => {
        return {
            relatedObjectId: [id],
            objectName: [FORM_OBJECT_NAME],
        }
    }, [id])

    if (!noteRead) {
        return null
    }

    return (
        <div
            className={clsx(classes.container, classes.tableFont)}
            data-testid={`history-note-${FORM_OBJECT_NAME}`}
        >
            <Collapse in={newNote}>
                {newNote
                && (
                    <CreateContainerNote
                        name="Note"
                        onCancel={handleCancelNew}
                        id={id}
                        objectName={DOMAIN_NAME}
                        isNew
                        onSuccess={refresh}
                    />
                )}
            </Collapse>
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="history-card"
            >
                <div className={classes.header}>
                    <Typography variant="h3">
                        Change History
                    </Typography>
                    {noteCreate && (
                        <div className={classes.icon}>
                            {newNote
                                ? (
                                    <RemoveIcon
                                        className={classes.icon}
                                        onMouseDown={handleAddNote}
                                    />
                                )
                                : (
                                    <AddIcon
                                        data-testid="open-new-history-note"
                                        className={classes.icon}
                                        onMouseDown={handleAddNote}
                                    />
                                )}
                        </div>
                    )}
                </div>
                <div
                    className={classes.gridCardWrapper}
                >
                    <RichTable
                        configName="ContainerNotes"
                        name={FORM_OBJECT_NAME}
                        load={load}
                        filter={defaultFilter}
                        columns={columns}
                        className={classes.tableContent}
                        classNames={{
                            contentWrapper: classes.tableContentWrapper,
                        }}
                        detailPanel={ContainerNoteDetails}
                    />
                </div>
            </Card>
        </div>
    )
}

export default ContainerNotes
