import React from 'react'

import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
    useHistory,
} from 'react-router-dom'

import ShortTermForecasts from './ShortTermForecasts'
import ForecastSubmission from './ForecastSubmission'

const Forecast = () => {
    const {
        path,
    } = useRouteMatch()

    const history = useHistory()

    return (
        <Switch>
            <Route path={`${path}/shortterm-forecasts/bulk-edit`}>
                <ForecastSubmission onClose={() => {
                    history.push(`${path}/shortterm-forecasts`)
                }}
                />
            </Route>

            <Route path={`${path}/shortterm-forecasts`}>
                <ShortTermForecasts />
            </Route>

            <Redirect
                exact
                path={path}
                to={`${path}/shortterm-forecasts`}
            />

            <Route path="/">
                <div>
                    Not found
                </div>
            </Route>
        </Switch>
    )
}

export default Forecast
