import getUniqFieldId from 'app/Apps/DomainObject/utils/getUniqFieldId'
import getComponentByName from 'app/utils/components/getComponentByName'

const createColumnObj = (fieldIds) => {
    return fieldIds.map(({
        name: fieldName,
        componentName,
        ...column
    }) => {
        return {
            id: getUniqFieldId(fieldName),
            mapCellProps: fieldName,
            component: getComponentByName(componentName),
            ...column,
        }
    })
}

export default createColumnObj
