import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            marginTop: theme.spacing(1),
        },
        label: {
            textDecoration: 'underline',
            color: theme.palette.secondary[500],
        },
        text: {
            margin: 0,
            color: theme.palette.secondary[500],
            fontSize: theme.typography.body1.fontSize,
        },
        wrapper: {
            boxSizing: 'border-box',
        },
    }
})

export default useStyles
