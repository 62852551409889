import React from 'react'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import AccountOverview from './AccountOverview'
import AccountUpdate from './AccountUpdate'
import tableConfig from './Accounts.config'
import CreateAccount from './AccountCreate'
import RegionalGroups from './RegionalGroups'
import Contacts from './Contacts'

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: AccountOverview,
}

const accountUpdateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data-update',
    Component: AccountUpdate,
}

const regionalGroupsTab: SidebarTab = {
    label: 'Regional Groups',
    url: 'regional-groups',
    Component: RegionalGroups,
}

const contactsTab: SidebarTab = {
    label: 'Contacts',
    url: 'contacts',
    Component: Contacts,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Accounts = () => {
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: accountUpdateTab,
            permission: accountUpdate,
        },
        {
            tab: regionalGroupsTab,
            permission: accountUpdate,
        },
        {
            tab: contactsTab,
            permission: true,
        },
    ])

    return (
        <SkyNetTable
            createForm={accountCreate
                ? CreateAccount
                : undefined}
            name={tableConfig.name}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Accounts
