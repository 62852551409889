import {
    RequestConfig,
} from 'app/types/request.types'

type AlertNotificationsRequests = {
    hasUnreadAlerts: () => RequestConfig,
    getAll: (data: Record<string, any>) => RequestConfig,
    markAsRead: ({
        id,
    }: {
        id: number
    }) => RequestConfig,
    markAsUnread: ({
        id,
    }: {
        id: number
    }) => RequestConfig
}

const alertNotificationRequests: AlertNotificationsRequests = {
    getAll: (data) => {
        return {
            url: 'useralert/filter-request/all',
            method: 'POST',
            ...data,
        }
    },
    markAsRead: ({
        id,
    }) => {
        return {
            url: `useralert/${id}/read`,
            method: 'PATCH',
        }
    },
    markAsUnread: ({
        id,
    }) => {
        return {
            url: `useralert/${id}/unread`,
            method: 'PATCH',
        }
    },
    hasUnreadAlerts: () => {
        return {
            url: 'useralert/unread',
            method: 'GET',
        }
    },
}

export default alertNotificationRequests
