import React, {
    useMemo,
} from 'react'
import {
    Route, Switch, useRouteMatch,
} from 'react-router-dom'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import useLabel from 'app/hooks/useLabel'
import Card from 'app/shared-components/Card'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import AwardRejectLane from './AwardRejectLane'
import useGetLanesToAwardReject from './hooks/useGetLanesToAwardReject'
import requests from '../AccountQuotes.requests'
import useStyles from '../LanesToBeGrouped/LanesToBeGrouped.styles'
import getColumns from './LanesToAwardReject.columns'
import AwardRejectLaneFees from './AwardRejectLaneFees'

const LanesToAwardReject = ({
    id,
}: {id: number}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const {
        url,
    } = useRouteMatch()

    const requestsHandlers = useGetLanesToAwardReject({
        id,
    })

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const actionComponents = useMemo(() => {
        return {
            Update: AwardRejectLane,
        }
    }, [])

    return (
        <Switch>
            <Route path={`${url}/:id?/:action?`}>
                <div
                    data-testid="LanesToAwardRejectTable"
                    className={classes.root}
                >
                    <Card
                        fullHeight
                        contentClass={classes.cardContent}
                        data-testid="LanesTable-card"
                    >
                        <CollapsibleTable
                            title="Lanes"
                            domainName={requests.domainName}
                            configName="LanesToAwardReject"
                            columns={columns}
                            customUrl={requests.getLanesToAwardReject({
                                id,
                            }).url}
                            requestHandlers={requestsHandlers}
                            actionComponents={actionComponents}
                            createEnabled={false}
                            tableControls={[TableControlTypes.Search]}
                        />
                    </Card>
                </div>
                <AwardRejectLaneFees id={id} />
            </Route>
        </Switch>
    )
}

export default LanesToAwardReject
