import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        wrapper: {
            width: 1436,
        },
    }
})

export default useStyles
