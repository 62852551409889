import getUniqueId from 'app/utils/getUniqueId'
import {
    Column,
} from 'app/types/simpletable.types'
import {
    Company,
} from '../Companies.types'

export type CompanyDuplication = Pick<Company, 'companyName' | 'website' | 'awbPrefix'>

export default (l): Column<keyof CompanyDuplication>[] => {
    return [
        {
            fieldName: 'companyName',
            label: l('Company.companyName'),
            id: getUniqueId(),
        },
        {
            fieldName: 'website',
            label: l('Company.website'),
            id: getUniqueId(),
        },
        {
            fieldName: 'awbPrefix',
            label: l('Company.awbPrefix'),
            id: getUniqueId(),
        },
    ]
}
