import {
    RangeType,
    PaymentTerms,
    TemperatureRange,
} from 'app/types/enums'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    AdditionalFees,
    LaneFees,
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getSuffix = (days) => {
    if (!days) {
        return undefined
    }

    return days > 1 ? ' Days' : ' Day'
}

type Args = {
    additionalFeesCommentForBilling: string,
    baseLeaseDays: number,
    laneFeesCommentForBilling: string,
    extraDays: number,
    laneFees: LaneFees,
    additionalFees: AdditionalFees,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
}

const getOrderFields = ({
    additionalFeesCommentForBilling,
    baseLeaseDays,
    laneFeesCommentForBilling,
    extraDays,
    laneFees,
    additionalFees,
    getAllowedValues,
}: Args) => {
    return [
        {
            title: 'Summary',
            className: 'summary',
            fields: [{
                id: 'summary1',
                fields: [
                    {
                        name: 'orderNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingInformationView.orderNumber',
                    },
                    {
                        name: 'laneNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingInformationView.laneNumber',
                    },
                    {
                        name: 'billing.baseLeaseUntilTimestamp',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingOverviewView.baseLeaseUntilTimestamp',
                    },
                    {
                        name: 'customerReference',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingInformationView.customerReference',
                    },
                    {
                        name: 'personInCharge',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingInformationView.personInCharge',
                    },
                    {
                        name: 'temperatureRange',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(TemperatureRange),
                        labelKey: 'OrderBillingInformationView.temperatureRange',
                    },
                    {
                        name: 'orderReceived',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingInformationView.orderReceived',
                    },
                    {
                        name: 'requestedHandover',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingInformationView.requestedHandover',
                    },
                    {
                        name: 'actualHandover',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingInformationView.actualHandover',
                    },
                    {
                        name: 'transportModeRoad',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(TransportModeRoad),
                        labelKey: 'OrderBillingInformationView.transportModeRoad',
                    },
                ],
            }],
        },
        {
            title: 'Comments',
            className: 'comments',
            fields: [
                {
                    id: 'comments1',
                    fields: [
                        {
                            name: 'laneCommentBilling',
                            componentName: 'LongText',
                            labelKey: 'OrderBillingInformationView.laneCommentBilling',
                        },
                        {
                            name: 'laneFeesCommentForBilling',
                            componentName: 'LongText',
                            value: laneFeesCommentForBilling,
                            labelKey: 'OrderBillingInformationView.laneFeesCommentForBilling',
                        },
                        {
                            name: 'additionalFeesCommentForBilling',
                            componentName: 'LongText',
                            value: additionalFeesCommentForBilling,
                            labelKey: 'AdditionalFees.comment',
                        },
                    ],
                },
                {
                    id: 'comments2',
                    fields: [
                        {
                            name: 'billing.billingCompany.instructionsForBilling',
                            componentName: 'LongText',
                            labelKey: 'OrderBillingInformationView.instructionsForBilling',
                        },
                        {
                            name: 'commentForBillingOps',
                            componentName: 'LongText',
                            labelKey: 'OrderByNumberView.commentForBillingOps',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Other Fees Data',
            className: 'feeDays',
            fields: [
                {
                    id: 'feeDays1',
                    fields: [
                        {
                            name: 'additionalFees.additionalFeesNumber',
                            componentName: 'SkyNetLink',
                            text: additionalFees?.additionalFeesNumber,
                            link: additionalFees?.contractBasisId
                                && additionalFees?.additionalFeesNumber
                                && `/apps/pricing/contract-basis/all/edit/${additionalFees?.contractBasisId}/additional-fees/${additionalFees?.additionalFeesNumber}`,
                            labelKey: 'AdditionalFees.additionalFeesNumber',
                            type: TypeLink.INTERNAL_WITH_ICON,
                            size: SizeLink.S,
                        },
                        {
                            name: 'laneFees.expressOrderTimeline',
                            value: laneFees?.expressOrderTimeline,
                            componentName: 'IntegerInput',
                            labelKey: 'LaneFees.expressFeeTimeline',
                        },
                        {
                            name: 'laneFees.postponementFeeTimeline',
                            value: laneFees?.postponementFeeTimeline,
                            componentName: 'IntegerInput',
                            labelKey: 'LaneGroupUpdateDto.postponementFeeTimeline',
                        },
                        {
                            name: 'additionalFees.cancellationFeeTimeline',
                            value: additionalFees?.cancellationFeeTimeline,
                            componentName: 'IntegerInput',
                            labelKey: 'LaneGroupUpdateDto.cancellationFeeTimeline',
                        },
                    ],
                },
                {
                    id: 'feeDays2',
                    fields: [
                        {
                            name: 'laneFees.laneFeesNumber',
                            componentName: 'SkyNetLink',
                            text: laneFees?.laneFeesNumber,
                            link: laneFees?.laneGroupId
                                && laneFees?.id
                                && `/apps/pricing/lane-groups/all/edit/${laneFees?.laneGroupId}/lane-fees/${laneFees?.id}`,
                            labelKey: 'LaneFees.laneFeesNumber',
                            type: TypeLink.INTERNAL_WITH_ICON,
                            size: SizeLink.S,

                        },
                        {
                            name: 'laneFees.expressDaysType',
                            value: laneFees?.expressDaysType,
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RangeType),
                            labelKey: 'OrderBilling.expressDaysType',
                        },
                        {
                            name: 'laneFees.postponementDaysType',
                            value: laneFees?.postponementDaysType,
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RangeType),
                            labelKey: 'OrderBilling.postponementDaysType',
                        },
                        {
                            name: 'additionalFees.cancellationDaysType',
                            value: additionalFees?.cancellationDaysType,
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RangeType),
                            labelKey: 'OrderBilling.cancellationDaysType',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Billing Information',
            className: 'billingInformation',
            fields: [{
                id: 'comments1',
                fields: [
                    {
                        name: 'billing.billingCompany',
                        componentName: 'BillingCompanyObjectSelector',
                        labelKey: 'OrderBilling.billingCompany',
                        disabled: true,
                    },
                    {
                        name: 'billing.billingCompany.paymentTerms',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(PaymentTerms),
                        className: 'smallCell',
                        labelKey: 'BillingCompanyView.paymentTerms',
                    },
                    {
                        name: 'billing.billingCompany.markUp',
                        componentName: 'InputSingleline',
                        disabled: true,
                        className: 'smallCell',
                        labelKey: 'BillingCompanyDto.markUp',
                    },
                    {
                        name: 'billing.leaseStartDate',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBilling.leaseStartDate',
                    },
                    {
                        name: 'billing.leaseEndDate',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBilling.leaseEndDate',
                    },
                    {
                        name: 'billing.billingComment',
                        componentName: 'LongText',
                        labelKey: 'OrderBilling.billingComment',
                    },
                    {
                        name: 'laneFees.minOrder',
                        componentName: 'IntegerInput',
                        value: laneFees?.minOrder,
                        labelKey: 'LaneFees.minOrder',
                    },
                    {
                        name: 'billing.baseLeaseDays',
                        componentName: 'InputSingleline',
                        type: 'number',
                        suffix: getSuffix(baseLeaseDays),
                        labelKey: 'OrderBilling.baseLeaseDays',
                    },
                    {
                        name: 'billing.extraDays',
                        componentName: 'InputSingleline',
                        type: 'number',
                        suffix: getSuffix(extraDays),
                        labelKey: 'OrderBilling.extraDays',
                    },
                ],
            }],
        },
    ]
}

export default getOrderFields
