import React from 'react'

import Button from 'app/shared-components/Button'

type Props = {
    archived?: boolean,
    onClick: (arg: boolean) => void,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    archived: false,
    disabled: undefined,
}
const ArchiveButton = ({
    archived,
    onClick,
    disabled,
}: Props) => {
    return (
        <Button
            label={archived ? 'Restore' : 'Archive'}
            key={archived ? 'restore' : 'archive'}
            name={archived ? 'restore' : 'archive'}
            secondary
            onClick={onClick}
            disabled={disabled}
        />
    )
}

ArchiveButton.defaultProps = defaultProps

export default ArchiveButton
