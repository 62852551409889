import {
    makeStyles,
} from 'app/tss'

export default makeStyles()((theme) => {
    return {
        alertContainer: {
            width: 416,
            color: theme.palette.secondary[600],
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.h4.fontWeight,
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        },
        alertHeader: {
            minHeight: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.caption.fontWeight,
        },
        lightGrey: {
            color: theme.palette.secondary[300],
        },
        spaceAfter: {
            paddingRight: theme.spacing(0.5),
        },
        alertBody: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        alertMessage: {
            width: 'calc(100%-30px)',
            whiteSpace: 'pre',
            textWrap: 'wrap',
        },
        alertIcon: {
            width: 30,
            textAlign: 'right',
        },
    }
})
