import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {},
        checkboxRoot: {
            padding: '1px 3px 1px 9px !important',
            fill: 'white',
        },
        checkboxWrapper: {
            padding: theme.spacing(2, 0),
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: 7,
                width: 6,
                height: 27,
                backgroundColor: theme.palette.primary.main,
                display: 'none',
                borderRadius: 3,
            },
        },
    }
})

export default useStyles
