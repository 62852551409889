import React, {
    useState,
} from 'react'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'

import {
    StorageTableType,
} from '../../../serviceCenters.types'
import StorageForm from '../StorageForm'
import useStyles from './StorageUpdate.styles'

const StorageUpdate = ({
    value,
    closeOpenedRow,
    updateStorage,
    removeStorage,
}: {
    value: StorageTableType,
    updateStorage: (storage: StorageTableType) => void,
    removeStorage: (storageId: number) => void,
    closeOpenedRow: () => void,
}) => {
    const {
        classes,
    } = useStyles()

    const [
        data,
        setData,
    ] = useState<StorageTableType>(value)

    const onUpdate = () => {
        updateStorage(data)
        closeOpenedRow()
    }

    return (
        <div className={classes.formContainer}>
            <StorageForm
                value={data}
                onChange={setData}
            />
            <ButtonContainer>
                <Button
                    label="Update"
                    onClick={onUpdate}
                    data-testid="update-storage-button"
                />
                <Button
                    label="Delete"
                    onClick={() => { removeStorage(data?.id) }}
                    secondary
                    data-testid="delete-storage-button"
                />
                <Button
                    secondary
                    label="Cancel"
                    onClick={() => { closeOpenedRow() }}
                    data-testid="cancel-storage-button"
                />
            </ButtonContainer>
        </div>
    )
}

export default StorageUpdate
