import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        laneIndepPricingStatus: {
            borderRadius: 16,
            padding: `${theme.spacing(0.5)} 0`,
            lineHeight: 'normal',
            whiteSpace: 'nowrap',
        },
        icon: {
            width: 14,
            height: 14,
            marginLeft: 5,
        },
    }
})

export default useStyles
