import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            padding: theme.spacing(2),
            cursor: 'default',
            backgroundColor: theme.palette.secondary[100],
        },
    }
})

export default useStyles
