import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LocationType,
    ProductType,
} from 'app/types/enums'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import {
    PreBookingEntity,
    PreBookingStatus,
    PreBookingContainerStatus,
} from '../../PreBooking.types'

export type LaneInfo = {
    id: number,
    name: string,
    link?: string,
}

type PreBookingEntityFields = keyof (PreBookingEntity & {
    laneLink?: string,
})

const getFields = ({
    getAllowedValues,
    disabled,
    hasLane,
    allowedProductTypes,
    requestedHandoverSelected,
    lane,
    showGeneralInfo = false,
}: {
    getAllowedValues: (e:Record<string, string>) => Record<string, string>,
    disabled: boolean,
    hasLane: boolean,
    allowedProductTypes: ProductType[],
    lane?: LaneInfo
    requestedHandoverSelected: boolean,
    showGeneralInfo?: boolean,
}): DTOCardFormField<PreBookingEntityFields>[] => {
    const generalFields: DTOCardFormField<PreBookingEntityFields> = {
        id: 'general',
        className: 'general',
        title: 'General',
        fields: [
            {
                id: 'column-1',
                fields: [{
                    name: 'preBookingNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'Prebooking.number',
                    disabled,
                }],
            },
            {
                id: 'column0',
                fields: [{
                    name: 'laneLink',
                    componentName: 'SkyNetLink',
                    link: lane?.link,
                    type: TypeLink.INTERNAL_WITH_ICON,
                    size: SizeLink.S,
                    labelKey: 'Prebooking.lane',
                    disabled,
                    text: lane?.name,
                    hidden: !lane?.id,
                }],
            },
        ],
    }

    const laneFields: DTOCardFormField<PreBookingEntityFields> = {
        id: 'lane',
        className: 'lane',
        title: 'Lane Information',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'customerExecution',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'Prebooking.customerExecution',
                        disabled: disabled || hasLane,
                    },
                    {
                        name: 'pricingAccount',
                        labelKey: 'Prebooking.pricingAccount',
                        componentName: 'AccountSelector',
                        required: true,
                        disabled: disabled || hasLane,
                    },
                    {
                        name: 'originCountry',
                        labelKey: 'Prebooking.originCountry',
                        componentName: 'CountryDataSelector',
                        disabled: disabled || hasLane,
                        hidden: hasLane,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'originAirport',
                        componentName: 'LocationObjectSelector',
                        filterLocationType: LocationType.AIRPORT,
                        labelKey: 'Prebooking.originAirport',
                        required: true,
                        disabled: disabled || hasLane,
                    },
                    {
                        name: 'destinationAirport',
                        componentName: 'LocationObjectSelector',
                        filterLocationType: LocationType.AIRPORT,
                        labelKey: 'Prebooking.destinationAirport',
                        required: true,
                        disabled: disabled || hasLane,
                    },
                    {
                        name: 'destinationCountry',
                        componentName: 'CountryDataSelector',
                        labelKey: 'Prebooking.destinationCountry',
                        disabled: disabled || hasLane,
                        hidden: hasLane,
                    },
                ],
            },
        ],
    }
    const statusFields: DTOCardFormField<PreBookingEntityFields> = {
        id: 'status',
        className: 'status',
        title: 'Status',
        fields: [
            {
                id: 'column5',
                fields: [{
                    name: 'preBookingStatus',
                    componentName: 'EnumSelector',
                    labelKey: 'Prebooking.prebookingStatus',
                    allowedValues: getAllowedValues(PreBookingStatus),
                    useDropdown: true,
                    required: true,
                    disabled,
                }],
            },
            {
                id: 'column6',
                fields: [{
                    name: 'containersStatus',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(PreBookingContainerStatus),
                    labelKey: 'Prebooking.containerStatus',
                    useDropdown: true,
                    required: true,
                    disabled,
                }],
            },
        ],
    }
    const preBookingFields: DTOCardFormField<PreBookingEntityFields> = {
        id: 'prebooking',
        className: 'prebooking',
        title: 'Pre Booking Details',
        fields: [
            {
                id: 'column3',
                fields: [
                    {
                        name: 'requestedHandover',
                        componentName: 'DateTimeZonedSelect',
                        labelKey: 'Prebooking.requestedHandover',
                        disabled,
                    },
                    {
                        name: 'requestedHandoverYear',
                        labelKey: 'Prebooking.requestedHandoverYear',
                        componentName: 'IntegerInput',
                        required: !requestedHandoverSelected,
                        disabled: disabled || requestedHandoverSelected,
                    },
                    {
                        name: 'requestedHandoverWeek',
                        labelKey: 'Prebooking.requestedHandoverWeek',
                        componentName: 'IntegerInput',
                        required: !requestedHandoverSelected,
                        disabled: disabled || requestedHandoverSelected,
                    },
                    {
                        name: 'project',
                        labelKey: 'Prebooking.project',
                        componentName: 'InputSingleline',
                        disabled,
                    },
                    {
                        name: 'customerReference',
                        labelKey: 'Prebooking.customerReference',
                        componentName: 'InputSingleline',
                        disabled,
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'requestedPickup',
                        componentName: 'DateTimeZonedSelect',
                        labelKey: 'Prebooking.requestedPickup',
                        disabled,
                    },
                    {
                        name: 'mawbNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'Prebooking.mawbNumber',
                        disabled,
                    },
                ],
            },
        ],
    }

    const containerFields: DTOCardFormField<PreBookingEntityFields> = {
        id: 'container',
        className: 'container',
        title: 'Required Containers',
        fields: [{
            name: 'requiredContainers',
            componentName: 'RequiredContainerByProductTypeList',
            allowedProductTypes,
            labelKey: 'Prebooking.requiredContainers',
            required: true,
            disabled,
        }],
    }
    const opsFields: DTOCardFormField<PreBookingEntityFields> = {
        id: 'ops',
        className: 'ops',
        title: 'Ops Parameters',
        fields: [
            {
                id: 'column7',
                fields: [{
                    name: 'hasBooking',
                    componentName: 'Checkbox',
                    labelKey: 'Prebooking.hasBookingQ',
                    disabled,
                }],
            },
            {
                id: 'column8',
                fields: [{
                    name: 'originServiceCenter',
                    componentName: 'LocationObjectSelector',
                    filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                    labelKey: 'Prebooking.originServiceCenter',
                    disabled,
                }],
            },
        ],
    }
    const kamFields: DTOCardFormField<PreBookingEntityFields> = {
        id: 'kam',
        className: 'kam',
        title: 'KAM Parameters',
        fields: [{
            id: 'column7',
            fields: [{
                name: 'isForecasted',
                componentName: 'Checkbox',
                labelKey: 'Prebooking.isForecasted',
                disabled,
            }],
        }],
    }

    return showGeneralInfo ? [
        generalFields,
        laneFields,
        statusFields,
        preBookingFields,
        containerFields,
        opsFields,
        kamFields,
    ] : [
        laneFields,
        statusFields,
        preBookingFields,
        containerFields,
        opsFields,
        kamFields,
    ]
}

export default getFields
