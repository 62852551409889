import {
    OrderStatuses,
    OrderTemperatureRange,
} from 'app/types/enums'
import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    NestedKeyOf,
} from 'app/types/utils.types'

import {
    Order,
    PreviousOrder,
} from '../../orders.types'

export type InternalTransportOverviewFields = NestedKeyOf<Order>

type Args = {
    isGeneralFreight: boolean,
    previousOrders: PreviousOrder[],
    orderNumber: string
    orderStatus: OrderStatuses,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
}

const fields = ({
    isGeneralFreight,
    previousOrders,
    orderNumber,
    orderStatus,
    getAllowedValues,
}: Args): DTOCardFormField<InternalTransportOverviewFields>[] => {
    const previousOrdersField: DTOCardFormField<InternalTransportOverviewFields>[] = [{
        title: 'Previous Orders',
        className: 'previousOrders',
        id: 'previousOrders',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'previousOrders',
            componentName: 'PreviousOrders',
            orderNumber,
        }],
    }]

    const bookedFreightField: DTOCardFormField<InternalTransportOverviewFields>[] = [{
        id: 'bookedFreight',
        className: 'selectedAsset',
        title: 'Booked Freight',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'generalFreight',
            componentName: 'BookedFreight',
        }],
    }]

    const selectedAssetField: DTOCardFormField<InternalTransportOverviewFields>[] = [{
        id: 'selectedAsset',
        className: 'selectedAsset',
        title: 'Booked Containers',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'selectedContainers',
            componentName: 'SelectedAssets',
        }],
    }]

    return [
        {
            id: 'orderInfo',
            className: 'orderInfo',
            title: 'Order Info',
            fields: [
                {
                    name: 'orderNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderByNumberView.orderNumber',
                },
                {
                    name: 'temperatureRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(OrderTemperatureRange),
                    labelKey: 'OrderByNumberView.temperatureRange',
                },
                {
                    name: 'incoTerm',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(IncoTerm),
                    disabled: true,
                    labelKey: 'OrderByNumberView.incoTerm',
                },
                {
                    name: 'createdBy',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderByNumberView.createdBy',
                },
            ],
        },
        ...isGeneralFreight ? bookedFreightField : selectedAssetField,
        {
            id: 'orderStatusHistory',
            className: 'orderStatusHistory',
            title: 'Order Status History',
            fields: [{
                name: 'orderStatusTransitions',
                componentName: 'OrderStatusHistory',
                noLabel: true,
                orderStatus,
            }],
        },
        {
            id: 'orderStatus',
            className: 'orderStatus',
            title: 'Order Status',
            fields: [
                {
                    name: 'orderStatus',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(OrderStatuses),
                    labelKey: 'OrderByNumberView.orderStatus',
                },
                {
                    name: 'orderSteps',
                    componentName: 'OrderStatus',
                    labelKey: 'OrderByNumberView.orderSteps',
                },
            ],
        },
        {
            id: 'origin',
            title: 'Origin',
            className: 'origin',
            fields: [
                {
                    name: 'originLocation',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderByNumberView.originLocation',
                },
                {
                    name: 'originAirport',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderByNumberView.originAirport',
                },
                {
                    name: 'originLocationContacts',
                    labelKey: 'OrderByNumberView.originLocationContacts',
                    componentName: 'MultipleContactObjectSelector',
                },
            ],
        },
        {
            id: 'destination',
            title: 'Destination',
            className: 'destination',
            fields: [
                {
                    name: 'destinationLocation',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderByNumberView.destinationLocation',
                },
                {
                    name: 'destinationAirport',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderByNumberView.destinationAirport',
                },
                {
                    name: 'destinationLocationContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'OrderByNumberView.destinationLocationContacts',
                },
            ],
        },
        ...previousOrders?.length ? previousOrdersField : [],
    ]
}

export default fields
