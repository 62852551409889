import React, {
    useMemo,
} from 'react'
import {
    Country,
} from 'app/Apps/Masterdata/Countries/Countries.types'
import useValidateItem from 'app/hooks/useValidateItem'
import RandExp from 'randexp'
import validateZipCodeByCountry from './ZipCode.validation'
import useGetCountry from '../../Apps/Masterdata/Countries/hooks/useGetCountry'
import InputSingleline from '../InputSingleline'

const defaultProps = {
    country: undefined,
    setErrors: undefined,
    required: false,
    disabled: false,
    className: undefined,
    classNames: undefined,
    title: undefined,
}

const ZipCodeInput = ({
    name,
    value,
    onChange,
    required,
    disabled,
    country,
    setErrors,
    className,
    classNames,
    title,
}: {
    name: string,
    value: string,
    onChange: (...a: any[]) => void,
    required?: boolean,
    disabled?: boolean,
    setErrors?: (e: [string, any[]]) => void,
    country?: Country,
    className?: string,
    classNames?: {
        label?: string,
        text?: string | Record<string, any>
    },
    title?: string,
}) => {
    const {
        data: countryData,
    } = useGetCountry(country?.id)

    const zipCodeValidatonValues = useMemo(() => {
        if (countryData?.zipCodePattern) {
            const zipCodePattern = new RegExp(`^${countryData?.zipCodePattern}$`)
            const zipCodeExample = new RandExp(zipCodePattern).gen()

            return {
                zipCodePattern,
                zipCodeExample,
                zipCodePatternDescription: countryData?.zipCodePatternDescription,
            }
        }

        return {
            zipCodePattern: undefined,
            zipCodeExample: undefined,
            zipCodePatternDescription: undefined,
        }
    }, [countryData])

    const errors = useValidateItem({
        validate: validateZipCodeByCountry,
        value: {
            zipCode: value,
            ...zipCodeValidatonValues,
        },
        setFormError: setErrors,
        key: name,
    })

    return (
        <InputSingleline
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            errors={errors}
            validated={Boolean(countryData?.zipCodePattern && value)}
            className={className}
            classNames={classNames}
            title={title || name}
            placeholder={zipCodeValidatonValues.zipCodeExample
                ? `e.g. ${zipCodeValidatonValues.zipCodeExample}` : undefined}
        />
    )
}

ZipCodeInput.defaultProps = defaultProps

export default ZipCodeInput
