/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'

import {
    SensorCode,
} from 'app/types/enums'

import LoggerOptions from './LoggerOptions'

type Props = {
    value: {
        options: {
            value: string,
            label: string,
        }[],
        title: string,
        name: string,
    }[],
    onChange: (...args: any[]) => void,
    className?: string,
    title?: string,
    allowedSensorCodes: SensorCode[],
}

const defaultProps: Partial<Props> = {
    className: '',
    title: '',
}

const LoggerOptionsContainer = (props: Props) => {
    const {
        value,
        onChange,
        allowedSensorCodes,
    } = props

    const onChangeSelection = useCallback((newValue, {
        name,
    }) => {
        const newConfigLoggers = value.map((configLogger) => {
            if (configLogger.name === name) {
                return {
                    ...configLogger,
                    options: newValue,
                }
            }

            return configLogger
        })

        onChange(newConfigLoggers)
    }, [
        onChange,
        value,
    ])

    return (
        <LoggerOptions
            {...props}
            value={value}
            onChange={onChangeSelection}
            allowedSensorCodes={allowedSensorCodes}
        />
    )
}

LoggerOptionsContainer.defaultProps = defaultProps

export default LoggerOptionsContainer
