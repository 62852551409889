import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, _, classes) => {
    return {
        formControl: {
            width: '100%',
        },
        input: {
            fontSize: theme.typography.body1.fontSize,
        },
        select: {
            width: '100%',
            color: `${theme.palette.secondary[600]} !important`,
            paddingTop: '2px !important',
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: -8,
                width: 6,
                height: 26,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 3,
            },
        },
        isRequiredBackground: {
            backgroundColor: theme.palette.primary[30],
        },
        disabled: {
            [`& .${classes.requiredMark}`]: {
                backgroundColor: 'transparent !important',
            },
            [`& .${classes['MuiInputBase-input']}`]: {
                backgroundColor: 'transparent !important',
            },
            [`& .${classes['MuiInput-underline:before']}`]: {
                border: '0 !important',
            },
        },
        errorMessage: {
            height: 10,
        },
        selectContainer: {
            position: 'relative',
            width: '100%',
        },
        timeFormat: {
            width: '50%',
        },
        hideHelperText: {
            display: 'none',
        },
    }
})

export default useStyles
