import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        labelCheckbox: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        chip: {
            '&&': {
                backgroundColor: theme.palette.secondary[200],
                color: theme.palette.secondary[600],
            },
        },
    }
})

export default useStyles
