import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    TableResponse,
} from 'app/types/request.types'
import requests from '../../AccountQuotes.requests'
import {
    LaneToBeGrouped,
} from '../../AccountQuotes.types'

export default ({
    id,
}:{id: number}) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<TableResponse<LaneToBeGrouped>>({
        key: RequestKeys.getLanesToAwardRejectByAccountQuote,
        params: {
            id,
        },
        config: requests.getLanesToAwardReject,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}
