import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    ProductType: {
        requestFn: () => {
            return {
                url: 'contractbasis/producttype/all',
            }
        },
    },
}

export default config
