import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    AddressCategory,
} from 'app/types/enums'
import {
    Gateway, GatewayBoxConfiguration, GatewayDataPlan, GatewayStatus, GatewayType,
} from './Gateways.types'

const config: SkyNetTableConfig<keyof Gateway> = {
    name: 'GatewaysTable',
    tabs: [
        {
            name: 'Gateways',
            url: 'all',
        },
        {
            name: 'Excluded',
            url: 'excluded',
        },
    ],
    fields: [
        {
            name: 'gatewayType',
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayType,
            filterField: 'gatewayType',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'gatewayImeiMac',
            width: '280px',
        },
        {
            name: 'gatewayStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayStatus,
            filterField: 'gatewayStatus',
            filterType: FilterType.Array,
        },
        {
            name: 'loggerConnections',
        },
        {
            name: 'isConnectedLora',
            componentName: 'CloudIconBoolean',
            filterField: 'isConnectedLora',
            filterType: FilterType.Array,
        },
        {
            name: 'lastConnectedOn',
            componentName: 'TemperatureAge',
        },
        {
            name: 'locationLocationName',
            reduced: true,
            filterField: 'locationLocationName',
            width: '200px',
            filterType: FilterType.Array,
        },
        {
            name: 'locationCity',
            labelKey: 'Gateway.locationCity',
            filterField: 'locationCity',
            width: '200px',
            filterType: FilterType.Array,
        },
        {
            name: 'locationPortOfReferenceIataCode',
            labelKey: 'LocationView.portOfReference',
            filterField: 'locationPortOfReferenceIataCode',
            width: '200px',
            filterType: FilterType.Array,
        },
        {
            name: 'gatewayLocationCategories',
            componentName: 'EnumList',
            allowedValuesEnum: AddressCategory,
        },
        {
            name: 'area',
            filterField: 'area',
            filterType: FilterType.Array,
        },
        {
            name: 'project',
            filterField: 'project',
            filterType: FilterType.Array,
        },
        {
            name: 'gatewayBoxConfiguration',
            filterField: 'gatewayBoxConfiguration',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayBoxConfiguration,
        },
        {
            name: 'gatewayConnectionType',
            filterField: 'gatewayConnectionType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayDataPlan,
        },
        {
            name: 'remark',
        },
    ],
}

export default config
