import React, {
    useCallback,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import requests from '../Users.request'
import getUserCreateFields from './UserCreate.fields'
import useStyles from './UserCreate.styles'

const UserCreate = () => {
    const {
        classes,
    } = useStyles()
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((account) => {
        addTableElement(account)
        selectDomainObject(account?.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    const modifyDataBeforeSend = useCallback((update) => {
        return {
            ...update,
            hint: undefined,
        }
    }, [])

    return (
        <FormWithControls
            onSuccess={onSuccess}
            modifyDataBeforeSend={modifyDataBeforeSend}
            name="Users"
            fields={getUserCreateFields()}
            requestParams={requests.create()}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
        />
    )
}

export default UserCreate
