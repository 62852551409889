import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    Lane,
} from 'app/Apps/Pricing/Pricing.types'
import {
    LaneStatus,
} from 'app/types/enums'

const columns: RichTableColumns<keyof Lane>[] = [
    {
        id: 'laneNumber',
        headerProps: {
            children: 'Lane',
        },
        mapCellProps: 'laneNumber',
    },
    {
        id: 'originAirportIataCode',
        headerProps: {
            children: 'Origin',
        },
        mapCellProps: 'originAirportIataCode',
        filterField: 'originAirportIataCode',
        filterType: FilterType.Array,
    },
    {
        id: 'destAirportIataCode',
        headerProps: {
            children: 'Destination',
        },
        mapCellProps: 'destAirportIataCode',
        filterField: 'destAirportIataCode',
        filterType: FilterType.Array,
    },
    {
        id: 'consigneeLocationName',
        headerProps: {
            children: 'Consignee',
        },
        mapCellProps: 'consigneeLocationName',
        filterField: 'consigneeLocationName',
        filterType: FilterType.Array,
    },
    {
        id: 'laneStatus',
        headerProps: {
            children: 'Status',
        },
        mapCellProps: 'laneStatus',
        filterField: 'laneStatus',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        allowedValuesEnum: LaneStatus,
    },
    {
        id: 'customerExecutionLocationName',
        headerProps: {
            children: 'Customer (Execution)',
        },
        mapCellProps: 'customerExecutionLocationName',
    },
]

export default columns
