import React, {
    useCallback,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'

import {
    NotificationsAdvancedSettingType,
} from '../../lanes.types'
import getColumns from './settingsTable.columns'
import useStyles from './SettingsTable.style'

const SettingsTable = ({
    data,
    value,
    onChange,
}: {
    data: NotificationsAdvancedSettingType[],
    value: string[],
    onChange: (val: string[]) => void
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()

    const load = useCallback(() => {
        return Promise.resolve({
            data,
        })
    }, [data])

    const columns = getColumns(l)

    return (
        <Card
            title="Settings"
            className={classes.root}
            contentClass={classes.wrapper}
            titleClass={classes.title}
        >
            <RichTable
                configName="NotificationsAdvancedSettingTable"
                name="NotificationsAdvancedSetting"
                load={load}
                columns={columns}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
                onSelectRow={onChange}
                selectedRows={value}
                uniqField="triggerType"
            />
        </Card>
    )
}

export default SettingsTable
