import React, {
    useMemo,
} from 'react'
import useLabel from 'app/hooks/useLabel'
import Card from 'app/shared-components/Card'
import SimpleTable, {
    RowComponent,
} from 'app/shared-components/SimpleTable'
import {
    Column,
} from 'app/types/simpletable.types'
import useEnumValues from 'app/hooks/useEnumValues'
import useValidation from 'app/hooks/useValidation'
import getColumns, {
    QuotationOptionsTableType,
} from './QuotationOptionsTable.config'
import useStyles from './QuotationOptionsTable.styles'

const QuotationOptionsTable = ({
    data, onChange, renderButtonPanel,
}: {
    data: QuotationOptionsTableType[],
    onChange: (...args: any[]) => void,
    renderButtonPanel: ({
        isValid,
    }: {isValid: boolean}) => JSX.Element
}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const columns = useMemo((): Column<keyof QuotationOptionsTableType>[] => {
        return getColumns({
            classes,
            l,
            handleChange: onChange,
            getAllowedValues,
        })
    }, [
        getAllowedValues,
        onChange,
        classes,
        l,
    ])

    const {
        isValid,
    } = useValidation({
        fields: columns,
        values: data,
    })

    return (
        <>
            <Card title="Quotation Options">
                <SimpleTable
                    name="QuotationOptionsTable"
                    columns={columns}
                    data={data}
                    component={RowComponent}
                    onChange={onChange}
                    disabled
                    classNames={{
                        tableCellHead: classes.tableCellHead,
                        tableCell: classes.tableCellHead,
                    }}
                />
            </Card>
            {renderButtonPanel({
                isValid,
            })}
        </>

    )
}

export default QuotationOptionsTable
