const OPEN = 'OPEN'
const CLOSED = 'CLOSED'

const fillEventData = ({
    tempArray,
    eventName,
    temps,
    sensorCodes,
    doorEventLoggers,
    minValue,
    maxValue,
    shiftNumber,
}) => {
    const openIndex = temps.indexOf(eventName)
    const dooorSensorName = sensorCodes[openIndex]
    const doorSensorNumber = doorEventLoggers.indexOf(dooorSensorName)
    const indexOfTripetStarts = doorSensorNumber > 0
        ? (doorSensorNumber * 6) + shiftNumber : shiftNumber

    tempArray.splice(indexOfTripetStarts, 3, 0, minValue, maxValue)
    return tempArray
}

const prepareDoorEventCustomDataItem = (temps,
    maxValue, minValue, sensorCodes, doorEventLoggers) => {
    const sizeData = doorEventLoggers.length * 6

    const tempArray = [
        ...new Array(sizeData).fill(null),
        0,
    ]

    if (temps.includes(OPEN)) {
        fillEventData({
            tempArray,
            eventName: OPEN,
            temps,
            sensorCodes,
            doorEventLoggers,
            minValue,
            maxValue,
            shiftNumber: 0,
        })
    }

    if (temps.includes(CLOSED)) {
        fillEventData({
            tempArray,
            eventName: CLOSED,
            temps,
            sensorCodes,
            doorEventLoggers,
            minValue,
            maxValue,
            shiftNumber: 3,
        })
    }

    return tempArray
}

export default prepareDoorEventCustomDataItem
