import {
    FieldInCard,
} from 'app/types/form.types'
import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LaneGroupHandoverData,
} from '../../Pricing.types'

const getFields = ({
    getAllowedValues,
    showFTL,
    showLTL,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    showFTL,
    showLTL,
}): FieldInCard<keyof LaneGroupHandoverData>[] => {
    const transportModeRoad = {
        name: 'transportModeRoad',
        className: 'transportMode',
        labelKey: 'LaneGroupCreateOrDuplicateDto.transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        showInColumns: true,
        required: true,
    } as FieldInCard<keyof LaneGroupHandoverData>

    const isFtlPositioningTemperatureControlled = {
        name: 'isFtlPositioningTemperatureControlled',
        className: 'checkbox',
        labelKey: 'LaneGroupCreateOrDuplicateDto.isFtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<keyof LaneGroupHandoverData>

    const isLtlPositioningTemperatureControlled = {
        name: 'isLtlPositioningTemperatureControlled',
        className: 'checkbox',
        labelKey: 'LaneGroupCreateOrDuplicateDto.isLtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<keyof LaneGroupHandoverData>

    let fields = [transportModeRoad]

    if (showFTL) {
        fields = [
            ...fields,
            isFtlPositioningTemperatureControlled,
        ]
    }

    if (showLTL) {
        fields = [
            ...fields,
            isLtlPositioningTemperatureControlled,
        ]
    }

    return fields
}

export default getFields
