import queryString from 'query-string'

import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    AllowedProductTypes: {
        requestFn: ({
            selectedLaneId,
            leaseStartTimestamp,
            isPerformanceQualification = false,
        }) => {
            return {
                url: queryString.stringifyUrl({
                    url: `lane/${selectedLaneId}/allowedproducttypes`,
                    query: {
                        leaseStartTimestamp,
                        isPerformanceQualification,
                    },
                }),
            }
        },
    },
    AvailableLanes: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'lane/filter-request/customer-transport-creation',
                method: 'POST',
                ...params,
            }
        },
    },
}

export default config
