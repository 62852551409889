import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    ContainerType,
} from 'app/Apps/ContainerManagement/Containers/containers.types'
import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useCallback,
} from 'react'

import config from '../ContainerType.request'

const useGetContainer = (
    id,
) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getContainerType],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<ContainerType>({
        key: RequestKeys.getContainerType,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetContainer
