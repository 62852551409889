import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import config from 'app/Apps/OrderManagement/Orders/orders.request'
import {
    ModificationReason,
    ModificationRequiredContainersType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

type RequestParams = {
    modificationReason: ModificationReason,
    orderNumber: string,
    data: Partial<ModificationRequiredContainersType>,
    version: number,
}

const useModifyRequiredContainersCT = (onSuccess) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessPostpone,
        onError,
    } = useHandleStatusRequest({
        id: 'ModifyRequiredContainers',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            modificationReason,
            orderNumber,
            version,
            data,
        }: RequestParams) => {
            return config.ModifyRequiredContainersCT.mutationFn({
                modificationReason,
                orderNumber,
                version,
                data,
                token,
            })
        },
        onSuccess: onSuccessPostpone,
        onError,
    })
}

export default useModifyRequiredContainersCT
