import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        temp: {
            fontSize: theme.typography.title.fontSize,
            lineHeight: '0.9',
            whiteSpace: 'nowrap',
            padding: `${theme.spacing(1.25)} 0 ${theme.spacing(0.25)} 0`,
        },
        messageDate: {
            textAlign: 'center',
        },
    }
})

export default useStyles
