import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import AlLogisticPricingForm from '../AlLogisticPricingForm'
import AlLogisticPricingRequests from '../AlLogisticPricing.requests'

import {
    AlLogisticPricing,
} from '../AlLogisticPricing.types'

const AlLogisticPricingCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((newAlLogisticPricing: AlLogisticPricing) => {
        addTableElement(newAlLogisticPricing)
        selectDomainObject(newAlLogisticPricing?.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <AlLogisticPricingForm
            onSuccess={onSuccess}
            requestParam={AlLogisticPricingRequests.create()}
        />
    )
}

export default AlLogisticPricingCreate
