import isNil from 'lodash/isNil'

const getUniqFieldId = (name, index = undefined) => {
    if (isNil(index)) {
        return `{self}.${name}`
    }

    return `{self}.${name}.${index}`
}

export default getUniqFieldId
