import React from 'react'

import useScrollToForm from 'app/hooks/useScrollToForm'
import HeaderWarning from 'app/shared-components/HeaderWarning'

import {
    AdditionalFeesStatus,
    RangeType,
} from 'app/types/enums'
import AdditionalFeeDetailPanel from '../AdditionalFeeDetailPanel/AdditionalFeeDetailPanel'

type AdditionalFeeCopy = {
    additionalFeesStatus: AdditionalFeesStatus,
    cancellationDaysType: RangeType,
    cancellationFeeTimeline: number,
    contractId: number,
    currency: string,
    additionalFeeId: number,
    isMostRecentPricing: boolean,
    validFrom: string,
    validTo: string,
}

type Props = {
    value: AdditionalFeeCopy,
    closeCopyForm: () => void,
    onDetailPanelCreateSuccess: (
        additionalFeeNumber: {
            additionalFeeNumber: string,
        },
    ) => void,
    redirectToCopyForm: (id: number) => void,
}

const AdditionalFeeCopyPanel = ({
    value,
    closeCopyForm,
    onDetailPanelCreateSuccess,
    redirectToCopyForm,
}: Props) => {
    const scrollToForm = useScrollToForm(`scroll-to-${value.additionalFeeId}`)

    return (
        <div
            ref={scrollToForm}
        >
            <HeaderWarning>You are deep copying a additional fee.</HeaderWarning>
            <AdditionalFeeDetailPanel
                closeForm={closeCopyForm}
                onDetailPanelCreateSuccess={onDetailPanelCreateSuccess}
                rowProps={value}
                isCopy
                redirectToCopyForm={redirectToCopyForm}
            />
        </div>
    )
}

export default AdditionalFeeCopyPanel
