import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {},
        freightType: {
            marginBottom: theme.spacing(2),
        },
        gridWrapper: {
            display: 'grid',
            gridRowGap: theme.spacing(2),
        },

        previousOrders: {
            minHeight: 315,
        },
        wrapper: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
