import React, {
    useCallback, useMemo, useState,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    useHistory,
} from 'react-router-dom'

import {
    LaneFeesStatus,
} from 'app/types/enums'
import {
    useChangeValidity,
} from 'app/shared-components/ChangeValidity'
import useRequestWithConfirmation from 'app/hooks/useRequestWithConfirmation'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import Button from 'app/shared-components/Button'
import useHistoryNavigation from 'app/hooks/useHistoryNavigation'
import useGetLaneFee from 'app/Apps/Pricing/LaneGroups/LaneFees/hooks/useGetLaneFee'
import LaneFeesRequests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import useLaneGroupContext from 'app/Apps/Pricing/LaneGroups/hooks/useLaneGroupContext'
import LaneFeesForm from '../LaneFeesForm'
import LaneFeeChangeValidity from './LaneFeeChangeValidity/LaneFeeChangeValidity'
import LaneFeeCancellation from './LaneFeeCancellation'
import ReviewLaneFeesReturnReason from './ReviewLaneFeesReturnReason'

const defaultProps = {
    id: undefined,
    refetch: () => {},
}

const LaneFeesUpdate = ({
    id,
    refetch,
}: { id?: number, refetch?: (...args: any[]) => void }) => {
    const {
        laneGroupId,
    } = useLaneGroupContext()

    const {
        updatePathWithParams,
    } = useHistoryNavigation()

    const history = useHistory()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLaneFee(id)

    const [
        reason,
        setReason,
    ] = useState('')
    const [
        openReviewLaneFeesReturnConfirm,
        setOpenReviewLaneFeesReturnConfirm,
    ] = useState(false)

    const requestParam = useMemo(() => {
        if (data?.laneFeesStatus !== LaneFeesStatus.IN_PROGRESS) {
            return LaneFeesRequests.editableData.request({
                laneFeesId: data?.id,
            })
        }
        return LaneFeesRequests.update.request({
            laneFeesId: data?.id,
        })
    }, [data])

    const archive = useArchive({
        requestParamFn: data?.archived ? LaneFeesRequests.restore
            : LaneFeesRequests.archive,
        notificationId: 'LaneFeeUpdateDto',
        title: 'Lane Fee',
        data,
        redirectionAfterSuccess: `/apps/pricing/lane-groups/all/edit/${laneGroupId}/lane-fees/`,
    })

    const onCancelSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        invalidate,
        refetch,
    ])

    const cancelFee = useRequestWithConfirmation({
        requestParamFn: LaneFeesRequests.cancellation,
        notificationId: 'LaneFeesUpdateDto',
        title: 'Lane Fee',
        data: {
            ...data,
            commentForBilling: reason,
        },
        actionDone: 'Cancelled',
        onSuccess: onCancelSuccess,
    })

    const onSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        refetch,
        invalidate,
    ])

    const onChangeValiditySuccess = useCallback(() => {
        onSuccess()
        history.push(`/apps/pricing/lane-groups/all/edit/${laneGroupId}/lane-fees/${data?.id}`)
    }, [
        onSuccess,
        history,
        data?.id,
        laneGroupId,
    ])

    const changeValidity = useChangeValidity({
        requestParamFn: LaneFeesRequests.validity,
        notificationId: 'LaneFeesUpdateDto',
        title: 'Lane Fee',
        data: {
            id: data?.id,
            validFrom: data?.validFrom,
            validTo: data?.validTo,
        },
        onSuccess: onChangeValiditySuccess,
    })

    const completedLaneFees = useMemo(() => {
        return [
            LaneFeesStatus.AWARDED,
            LaneFeesStatus.SUBMITTED,
            LaneFeesStatus.CANCELLED,
        ].includes(data?.laneFeesStatus)
    }, [data?.laneFeesStatus])

    const customButtons = useMemo(() => {
        return (
            [
                <Button
                    label="Copy"
                    key="copy-button"
                    onClick={() => {
                        updatePathWithParams({
                            action: 'copy',
                            id: data?.id,
                        })
                    }}
                />,
                <Button
                    key="set-to-cancel-button"
                    label="Set to Canceled"
                    onClick={cancelFee.askConfirmation}
                    disabled={data?.laneFeesStatus === LaneFeesStatus.CANCELLED}
                />,
                <Button
                    key="change-validity-button"
                    label="Change Validity"
                    onClick={changeValidity.handleChangeValidity}
                    disabled={!completedLaneFees}
                />,
                <ArchiveButton
                    key="archive-button"
                    onClick={archive.handleArchiveRestore}
                    archived={data?.archived}
                />,
            ]
        )
    }, [
        updatePathWithParams,
        archive.handleArchiveRestore,
        cancelFee.askConfirmation,
        changeValidity.handleChangeValidity,
        data,
        completedLaneFees,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <LaneFeesForm
                    data={data}
                    onSuccess={onSuccess}
                    onCancel={invalidate}
                    requestParam={requestParam}
                    customButtons={customButtons}
                    setOpenReviewLaneFeesReturnConfirm={setOpenReviewLaneFeesReturnConfirm}
                />
                <LaneFeeChangeValidity
                    id={data?.id}
                    changeValidity={changeValidity}
                />
                <LaneFeeCancellation
                    id={data?.id}
                    cancelFee={cancelFee}
                    setReason={setReason}
                    reason={reason}
                />
                <ArchiveConfirmationDialog
                    title="Lane Fees"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
                <ReviewLaneFeesReturnReason
                    openConfirmDialog={openReviewLaneFeesReturnConfirm}
                    handleClose={() => { return setOpenReviewLaneFeesReturnConfirm(false) }}
                    data={{
                        returnCreatedBy: data?.returnCreatedBy,
                        returnCreatedOn: data?.returnCreatedOn,
                        returnReason: data?.returnReason,
                        returnReasonComment: data?.returnReasonComment,
                    }}
                />
            </>
        </StatusHandler>
    )
}

LaneFeesUpdate.defaultProps = defaultProps

export default LaneFeesUpdate
