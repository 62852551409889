import React, {
    useCallback, useMemo, useState,
} from 'react'
import useScrollToForm from 'app/hooks/useScrollToForm'
import FormWithControls from 'app/shared-components/FormWithControls'
import {
    Currency, LaneFeesStatus,
} from 'app/types/enums'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequestConfig,
} from 'app/types/request.types'
import useHistoryNavigation from 'app/hooks/useHistoryNavigation'
import {
    LaneFeesDetail,
} from 'app/Apps/Pricing/Pricing.types'
import {
    useGetFxRate,
} from 'app/shared-components/FxRate'
import LaneFeesRequests from '../../../Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import useStyles from './LaneFeesForm.styles'
import useLaneGroupContext from '../../../Apps/Pricing/LaneGroups/hooks/useLaneGroupContext'
import getRejectedReasonCommentHint from './utils/getRejectedReasonCommentHint'
import useLaneFeesFormContext from './LaneFeesFormContext'

const defaultProps = {
    data: {} as LaneFeesDetail,
    onSuccess: () => {},
    onCancel: () => {},
    customButtons: undefined,
    setOpenReviewLaneFeesReturnConfirm: undefined,
}

const LaneFeesForm = ({
    data, onSuccess, onCancel, requestParam, customButtons, setOpenReviewLaneFeesReturnConfirm,
}: {
    data?: LaneFeesDetail,
    onSuccess?: (...args: any[]) => void,
    onCancel?: (...args: any[]) => void,
    requestParam: RequestConfig,
    customButtons?: JSX.Element[],
    setOpenReviewLaneFeesReturnConfirm?: (state: boolean) => void,
}) => {
    const {
        action,
    } = useHistoryNavigation()

    const {
        getFields,
    } = useLaneFeesFormContext()

    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const scrollToForm = useScrollToForm(String(data?.id), action === 'copy')
    const {
        currency, tempRange,
    } = useLaneGroupContext()

    const [
        updatedValues,
        setUpdatedValues,
    ] = useState(data)

    const {
        data: fxRateData,
    } = useGetFxRate(updatedValues?.fxRate?.id || data?.fxRate?.id)

    const value = useMemo(() => {
        return {
            ...data,
            ...updatedValues,
            fxRate: fxRateData,
            rejectedReasonHint: getRejectedReasonCommentHint(updatedValues.rejectedReason,
                updatedValues.laneFeesStatus),
        }
    }, [
        data,
        updatedValues,
        fxRateData,
    ])

    const exists = Boolean(value?.id)
    const hidefxRate = currency === Currency.EUR

    const modifyDataBeforeSend = useCallback((update) => {
        if (!update.laneFeesStatus) {
            return {
                ...update,
                laneFeesStatus: LaneFeesStatus.IN_PROGRESS,
            }
        }
        return update
    }, [])

    const disabledEditingLaneFees = useMemo(() => {
        return [
            LaneFeesStatus.AWARDED,
            LaneFeesStatus.SUBMITTED,
            LaneFeesStatus.CANCELLED,
            LaneFeesStatus.REJECTED,
        ].includes(data?.laneFeesStatus)
    }, [data?.laneFeesStatus])

    const handleReviewLaneFeesReturnReason = useCallback((e) => {
        e.preventDefault()
        setOpenReviewLaneFeesReturnConfirm(true)
    }, [setOpenReviewLaneFeesReturnConfirm])

    const fields = useFieldsWithClassName(getFields({
        ccy: currency,
        fxRate: value?.fxRate,
        fees: value?.fees,
        status: value?.laneFeesStatus,
        exists,
        hidefxRate,
        tempRange,
        originalStatus: data?.laneFeesStatus,
        editingNotAllowed: disabledEditingLaneFees,
        getAllowedValues,
        onReviewLaneFeesReturnReasonClick: handleReviewLaneFeesReturnReason,
        hasReturnReeason: !!data?.returnReason,
    }), classes)

    return (
        <div
            className={classes.root}
            ref={scrollToForm}
        >
            <FormWithControls
                name={LaneFeesRequests.domainName}
                setExternalValue={setUpdatedValues}
                value={value}
                modifyDataBeforeSend={modifyDataBeforeSend}
                fields={fields}
                customButtons={customButtons}
                requestParams={requestParam}
                exists
                onSuccess={onSuccess}
                onCancel={onCancel}
                cancelEnabled
                classNames={{
                    gridWrapper: hidefxRate
                        ? classes.gridWrapper : classes.gridWrapperWithFxRate,
                }}
            />
        </div>
    )
}

LaneFeesForm.defaultProps = defaultProps

export default LaneFeesForm
