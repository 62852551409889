import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    DamageType, ReportingDepartment,
} from 'app/types/enums'
import {
    Damage,
} from '../Damages.types'

const getDamageCreateFields = (getAllowedValues): DTOCardFormField<keyof Damage>[] => {
    return [{
        title: 'Damage Details',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'container',
                        labelKey: 'Damages.serialNumber',
                        componentName: 'ContainerSelector',
                        required: true,
                    },
                    {
                        name: 'reportingServiceCenter',
                        labelKey: 'Damages.reportingServiceCenter',
                        componentName: 'LocationObjectSelector',
                        filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                    },
                    {
                        name: 'damageType',
                        labelKey: 'Damages.damageType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(DamageType),
                        required: true,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'reportedOn',
                        labelKey: 'Damages.reportedOn',
                        componentName: 'DateTimeSelect',
                        required: true,
                    },
                    {
                        name: 'damageComment',
                        labelKey: 'Damages.damageComment',
                        componentName: 'LongText',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'reportedBy',
                        labelKey: 'Damages.reportedBy',
                        componentName: 'ContactObjectSelector',
                        required: true,
                    },
                    {
                        name: 'reportingDepartment',
                        labelKey: 'Damages.reportingDepartment',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ReportingDepartment),
                        required: true,
                        useDropdown: true,
                    },
                ],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'damageAttachments',
                    labelKey: 'Damages.damageAttachment',
                    componentName: 'AttachmentsObjectSelector',
                }],
            },
        ],
    }]
}

export default getDamageCreateFields
