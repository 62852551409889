import {
    tss,
} from 'app/tss'

export default tss.withParams<{height: string}>().create(({
    height,
}) => {
    return {
        container: {
            height,
        },
    }
})
