import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import requests from 'app/Apps/Forecast/Forecast.requests'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    ForecastType,
} from '../../Forecast.types'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess,
        onError,
    } = useHandleStatusRequest({
        title: 'Forecasts were updated',
        id: 'ForecastsBulkUpdate',
    })

    const {
        mutateAsync: updateForecasts,
    } = useMutation({
        mutationFn: (forecasts: ForecastType[]) => {
            return simpleApi({
                ...requests.bulkUpdate({
                    data: {
                        items: forecasts,
                    },
                }),
                token,
            })
        },
        onError,
        onSuccess,
    })

    return useCallback(async (forecasts: ForecastType[], options: AsyncMutationOptions) => {
        if (!forecasts.length) {
            onError({
                response: {
                    data: ['Nothing was changed'],
                    statusCode: 500,
                },
            })
            return Promise.resolve()
        }

        return updateForecasts(forecasts, options)
    }, [
        updateForecasts,
        onError,
    ])
}
