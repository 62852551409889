import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import OpportunitiesForm from '../OpportunitiesForm'
import useGetOpportunityById from '../hooks/useGetOpportunityById'

const Overview = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetOpportunityById(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <OpportunitiesForm
                disabled
                data={data}
                exists
            />
        </StatusHandler>
    )
}

export default Overview
