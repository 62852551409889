import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        title: {
            textAlign: 'center',
        },
    }
})

export default useStyles
