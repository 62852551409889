import {
    useCallback,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

const useLabel = () => {
    const {
        t,
    } = useTranslation()

    return useCallback((
        ...args: [key: string]
    ) => {
        const [key] = args

        if (key.split('.').length > 2) {
            // eslint-disable-next-line no-console
            console.log(`uselabel not support key with more than one '.' , key = ${key}`)
            return null
        }
        return t(...args)
    }, [t])
}

export default useLabel
