const rangeToString = (range: {
    min: number,
    max: number,
}): string => {
    if (!range) {
        return '-'
    }

    const {
        min, max,
    } = range

    return `${min >= 0 ? '+' : ''}${Math.floor(min * 10) / 10}°C to ${max >= 0 ? '+' : ''}${Math.floor(max * 10) / 10}°C`
}

export default rangeToString
