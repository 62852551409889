import React, {
    useMemo,
} from 'react'

import useLabel from 'app/hooks/useLabel'
import DuplicationCheckDialog from 'app/shared-components/DuplicationCheckDialog'
import getColumns, {
    LocationDuplication,
} from './LocationDuplicationDialog.columns'

const TABLE_NOTIFICATION = 'Warning: There are similar locations already in the system.\n'
    + 'Please make sure you are not creating a duplicate'

type Props = {
    data?: LocationDuplication[]
    close: () => void,
    createLocation: (...args: any[]) => void,
    title: string,
    positiveLabel: string,
}

const defaultProps = {
    data: null,
}

const LocationDuplicationCheckDialog = ({
    data,
    createLocation,
    close,
    title,
    positiveLabel,
}: Props) => {
    const l = useLabel()

    const columns = useMemo(() => {
        return getColumns(l)
    }, [l])

    return (
        <DuplicationCheckDialog<LocationDuplication>
            columns={columns}
            close={close}
            create={createLocation}
            message={TABLE_NOTIFICATION}
            data={data}
            title={title}
            positiveLabel={positiveLabel}
        />
    )
}

LocationDuplicationCheckDialog.defaultProps = defaultProps

export default LocationDuplicationCheckDialog
