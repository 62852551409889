import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import useLabel from 'app/hooks/useLabel'

import SimpleTable from 'app/shared-components/SimpleTable'
import getUniqueId from 'app/utils/getUniqueId'

import Inputs from './Inputs'

const propTypes = {
    value: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func.isRequired,
    addEntry: PropTypes.func.isRequired,
    deleteEntry: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    tempRange: PropTypes.string,
}

const defaultProps = {
    value: undefined,
    disabled: false,
    tempRange: undefined,
}

const TruckingCapacity = (props) => {
    const {
        value,
        onChange,
        addEntry,
        deleteEntry,
        disabled,
        tempRange,
    } = props
    const l = useLabel()

    const columns = [
        {
            label: l('TruckingCapacity.productType'),
            id: getUniqueId(),
        },
        {
            label: l('TruckingCapacity.maxNumberOfContainersPerTruck'),
            id: getUniqueId(),
        },
        {
            label: l('TruckingCapacity.maxNumberOfContainersPerVan'),
            id: getUniqueId(),
        },
    ]

    const valueEnhanced = useMemo(() => {
        return value.map((trucking) => {
            if (!trucking.containerTypeId) {
                return {
                    ...trucking,
                    containerTypeId: trucking.containerType?.id,
                    tempRange,
                }
            }
            return {
                ...trucking,
                tempRange,
            }
        })
    }, [
        value,
        tempRange,
    ])

    return (
        <SimpleTable
            columns={columns}
            data={valueEnhanced}
            component={Inputs}
            addEntry={addEntry}
            onChange={onChange}
            onDelete={deleteEntry}
            disabled={disabled}
        />
    )
}

TruckingCapacity.propTypes = propTypes
TruckingCapacity.defaultProps = defaultProps

export default TruckingCapacity
