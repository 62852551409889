import React from 'react'

import Typography from '@mui/material/Typography'
import BarChart from 'app/shared-components/BarChart'
import Card from 'app/shared-components/Card'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import useStyles from './AnalyseCO2EmissionsChart.style'
import options from './options'

type Props = {
    value: any[][]
}

const AnalyseCO2EmissionsChart = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'BarChart',
    })

    return (
        <Card
            className={classes.container}
            title={`CO${String.fromCharCode(8322)} Emissions Data`}
        >
            <BarChart
                options={options}
                value={value}
                className={classes.chart}
                onError={onError}
            />
            <Typography
                variant="caption"
            >
                {`The results include cradle to grave emissions and are calculated by the Swiss
                climate protection organisation “myclimate”. The functional unit is 1 m${String.fromCharCode(179)} and
                the results include secondary climate effects with a factor of two.`}
            </Typography>
        </Card>
    )
}

export default AnalyseCO2EmissionsChart
