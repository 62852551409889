const hasPermission = (categoriesPermission) => {
    return categoriesPermission.map((category) => {
        return category?.length
    }).filter(Boolean)
}

const getPermission = (
    accessRights,
    permissionsList,
    categories,
    permissionType,
) => {
    if (permissionType === 'create' && accessRights) {
        const categoriesPermission = permissionsList.map((permission) => {
            return Object.prototype.hasOwnProperty.call(accessRights, permission)
                ? Object.entries(accessRights[permission]).map((category) => {
                    if (category[1][permissionType]) {
                        return category[0]
                    }
                    return null
                }).filter(Boolean)
                : null
        }).filter(Boolean)

        return !!hasPermission(categoriesPermission).length
    }
    if (categories && categories.length && accessRights) {
        const categoriesPermission = categories.map((category) => {
            return permissionsList.map((permission) => {
                return Object.prototype.hasOwnProperty.call(accessRights, permission)
                    ? accessRights[permission][category]?.[permissionType]
                    : null
            }).filter(Boolean)
        })

        return !!hasPermission(categoriesPermission).length
    }
    return false
}

export default getPermission
