import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'

import requests from '../../../Companies.request'

const useGetSubsidiaries = (id) => {
    const {
        isError,
        loadData: load,
        isDataReady,
        isFetching,
        error,
    } = useRequestTable({
        key: RequestKeys.getCompanyParentSubsidiaries,
        params: {
            id,
        },
        config: requests.requestParentsSubsidiaries,
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        isDataReady,
        load,
        isError,
        isFetching,
        error,
    }
}

export default useGetSubsidiaries
