import ModifiedField from './ModifiedField'

export const LabelsComponents = {
    ModifiedField,
}

export type LabelsComponentConfig = {
    componentName: keyof typeof LabelsComponents,
    labelPrefixKeys: string[],
}
