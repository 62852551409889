import React from 'react'
import InputSingleline from 'app/shared-components/InputSingleline'
import AlertDialog from 'app/shared-components/AlertDialog'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    ReturnedReasons,
} from '../../LaneFeesForm/reasons.types'

import useStyles from './ReviewLaneFeesReturnReason.style'

type Props = {
    openConfirmDialog?: boolean,
    handleClose: (confirm: boolean) => void
    data?: {
        returnCreatedBy?: string,
        returnCreatedOn?: string,
        returnReason?: string,
        returnReasonComment?: string,
    },
}
const defaultProps: Partial<Props> = {
    openConfirmDialog: true,
    data: {},
}
const ReviewLaneFeesReturnReason = ({
    openConfirmDialog,
    handleClose,
    data,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getEnumValues = useEnumValues()(ReturnedReasons)

    return (
        <AlertDialog
            open={openConfirmDialog}
            handleClose={handleClose}
            dialogTitle="Lanes Fee Return Reason"
        >
            <>
                <div className={classes.container}>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Return Reason"
                            value={getEnumValues[data?.returnReason]}
                            disabled
                        />
                    </div>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Date"
                            value={data.returnCreatedOn}
                            disabled
                        />
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Reason Comment"
                            value={data.returnReasonComment}
                            rows={2}
                            multiline
                            disabled
                        />
                    </div>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Created By"
                            value={data.returnCreatedBy}
                            disabled
                        />
                    </div>
                </div>
            </>
        </AlertDialog>
    )
}

ReviewLaneFeesReturnReason.defaultProps = defaultProps

export default ReviewLaneFeesReturnReason
