import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useHasPermission from 'app/hooks/useHasPermission'

import CompanyOverview from './CompanyOverview'
import CompanyCreate from './CompanyCreate'
import CompanyUpdate from './CompanyUpdate'
import tableConfig from './Companies.config'
import SkyMindSync from './SkyMindSync'
import useTabsByPermission from '../../../hooks/useTabsByPermission'

const overviewTab: SidebarTab = {
    url: 'overview',
    label: 'Overview',
    Component: CompanyOverview,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearSorting,
]

const Companies = () => {
    const isAllowedToCreateCompany = useHasPermission(['company_create'])
    const isAllowedToUpdateCompany = useHasPermission(['company_update'])
    const isAllowedToUpdateSkyMindCompany = useHasPermission(['company_skymind_update'])

    const tabs = useTabsByPermission([
        {
            permission: true,
            tab: overviewTab,
        },
        {
            permission: isAllowedToUpdateCompany,
            tab: {
                url: 'data-update',
                label: 'Data Update',
                Component: CompanyUpdate,
            },
        },
        {
            permission: isAllowedToUpdateSkyMindCompany,
            tab: {
                url: 'skymind',
                label: 'SkyMind',
                Component: SkyMindSync,
            },
        },
    ])

    return (
        <SkyNetTable
            createForm={isAllowedToCreateCompany ? CompanyCreate : undefined}
            name="Company"
            tabs={tabs}
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Companies
