/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import noop from 'lodash/noop'
import clsx from 'clsx'
import {
    DateTimePicker,
} from '@mui/x-date-pickers'
import {
    renderTimeViewClock,
} from '@mui/x-date-pickers/timeViewRenderers'

import {
    dateTimeMask,
    strToMoment,
} from 'app/utils/date'
import Label from 'app/shared-components/Label'
import TextSingleline from 'app/shared-components/TextSingleline'

import useStyles from './DateTimeSelect.styles'

type Props = {
    title?: string,
    name?: string,
    onChange?: (value: any) => void,
    disabled?: boolean,
    required?: boolean,
    value?: string,
    className?: string,
}

const defaultProps: Partial<Props> = {
    title: '',
    name: '',
    onChange: noop,
    disabled: false,
    required: false,
    value: '',
    className: '',
}

const DateTimeSelect = (props: Readonly<Props>) => {
    const {
        classes,
    } = useStyles()
    const {
        title,
        name,
        onChange,
        disabled,
        required,
        value,
        className,
    } = props

    if (disabled) {
        return (
            <div
                className={clsx(classes.calendar, className)}
                data-testid={`dateTime-${name}`}
            >
                <TextSingleline
                    {...props}
                    value={value}
                />
            </div>
        )
    }

    return (
        <div className={clsx(classes.calendar, className)}>
            <Label
                title={title}
            />
            <div
                className={clsx(
                    {
                        [classes.isRequired]: required,
                    },
                )}
            >
                <div className={classes.requiredMark} />
                <DateTimePicker
                    ampm={false}
                    value={strToMoment(value, dateTimeMask)}
                    onChange={onChange}
                    format={dateTimeMask}
                    className={classes.dateTimePicker}
                    viewRenderers={
                        {
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }
                    }
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            fullWidth: true,
                        },
                    }}
                />
            </div>
        </div>
    )
}

DateTimeSelect.defaultProps = defaultProps

export default DateTimeSelect
