import React, {
    useCallback,
} from 'react'

import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import Typeahead from 'app/shared-components/Typeahead'
import useGetLocation from 'app/Apps/ContactManagement/Locations/hooks/useGetLocation'

import requestConfig from './customerExecutionSelector.request'

type Props = {
    name?: string,
    value?: {
        id: number | string,
    },
    onChange: (value: {id: number}) => void,
    title?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps : Partial<Props> = {
    name: '',
    value: undefined,
    title: 'Customer Execution',
    disabled: false,
    required: false,
}

const CustomerExecutionSelector = ({
    name,
    value,
    onChange,
    title,
    disabled,
    required,
}: Props) => {
    const loadOptions = useDelayedLoadOptions(requestConfig.CustomerQuotation)

    const {
        data: location,
    } = useGetLocation(value?.id)

    const onChangeValue = useCallback((newValue) => {
        const convertedValue = newValue ? {
            id: newValue,
        } : undefined

        onChange(convertedValue)
    }, [onChange])

    return (
        <Typeahead
            name={name}
            title={title}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            labelData={location}
            labelField="locationName"
            value={value?.id}
            disabled={disabled}
            isRequired={required}
        />
    )
}

CustomerExecutionSelector.defaultProps = defaultProps

export default CustomerExecutionSelector
