import React, {
    useMemo,
} from 'react'

import {
    getDuration,
    getDurationHoursStr,
    getDurationMinutesStr,
} from './utils'
import {
    Duration,
} from './duration.types'

type Props = {
    value?: number,
    className?: string,
}

const defaultProps = {
    value: undefined,
    className: '',
}

const AmountOfTime = ({
    value,
    className,
}: Props) => {
    const duration = useMemo<Duration>(() => {
        return getDuration(value)
    }, [value])
    const {
        years,
        months,
        days,
        hrs,
        minutes,
    } = duration

    if (!value) {
        return null
    }

    return (
        <div className={className}>
            {
                years !== 0 && `${years}y `
            }
            {
                months !== 0 && `${years !== 0 ? Math.abs(months) : months}m `
            }
            {
                days !== 0 && `${months !== 0 ? Math.abs(days) : days} day${Math.abs(days) > 1 ? 's' : ''}`
            }
            {getDurationHoursStr(days, hrs)}
            {getDurationMinutesStr(days, hrs, minutes)}
        </div>
    )
}

AmountOfTime.defaultProps = defaultProps

export default AmountOfTime
