import {
    useCallback,
    useEffect,
} from 'react'

const useBeforeUnloadDialog = (hasChanges: boolean) => {
    const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
        if (hasChanges) {
            e.preventDefault()
            e.returnValue = ''

            return e.returnValue
        }

        return null
    }, [hasChanges])

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [handleBeforeUnload])
}

export default useBeforeUnloadDialog
