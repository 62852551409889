import React from 'react'

import Card from 'app/shared-components/Card'
import InputSingleline from 'app/shared-components/InputSingleline'
import {
    Event,
} from 'app/types/common.types'

type Props = {
    simulationName?: string,
    className: string,
    onChange: (newVal: string, event: Event<string>) => void,
}

const defaultProps = {
    simulationName: undefined,
}

const Options = ({
    className,
    simulationName,
    onChange,
}: Props) => {
    return (
        <Card
            title="Options"
            className={className}
        >
            <InputSingleline
                title="Simulation Name"
                value={simulationName}
                onChange={onChange}
                name="simulationName"
            />
        </Card>
    )
}

Options.defaultProps = defaultProps

export default Options
