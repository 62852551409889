import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        chart: {
            height: '80%',
        },
        rangeFilter: {
            height: '20%',
        },
    }
})

export default useStyles
