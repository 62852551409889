/* eslint-disable camelcase */
import React, {
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react'
import noop from 'lodash/noop'

import {
    useJsApiLoader, Libraries,
} from '@react-google-maps/api'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete'

import {
    Library,
} from 'app/types/googlemaps.types'
import {
    DataSuggestion,
} from './GoogleAddress.types'

import GoogleAddress from './GoogleAddress'

import useGetCountries from './hooks/useGetCountries'
import useGetState from './hooks/useGetState'
import useGetTimeZoneByLatLng from './hooks/useGetTimeZoneByLatLng'

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env

const libraries: Libraries = [Library.PLACES]

const defaultProps = {
    required: false,
    title: '',
    setAutocomplete: noop,
    formData: {},
    value: '',
    name: '',
    disabled: undefined,
}

const GoogleAddressContainer = ({
    required,
    title,
    setAutocomplete,
    formData,
    value: currentValue,
    name,
    disabled,
}: {
    required?: boolean,
    title?: string,
    setAutocomplete?: (...args: any[]) => void,
    formData?: {
        addressLine1: string,
        countryId: number,
        stateId: number,
        city: string,
        zip: string,
        latitude: number,
        longitude: number,
        timeZone: string,
        placeId: string,
    },
    value?: string,
    name?: string,
    disabled?: boolean,
}) => {
    const {
        placeId: currentPlaceId,
    } = formData

    const {
        isLoaded,
    } = useJsApiLoader({
        googleMapsApiKey: googleMapKey,
        libraries,
    })

    const getCountry = useGetCountries()
    const getState = useGetState()
    const getTimeZone = useGetTimeZoneByLatLng()

    const [
        placeId,
        setPlaceId,
    ] = useState(currentPlaceId)

    const [
        autocompleteGoogle,
        setAutocompleteGoogle,
    ] = useState('')

    const formValue = useMemo(() => {
        return {
            description: currentValue || '',
            id: null,
            place_id: '',
            structured_formatting: {},
        } as DataSuggestion
    }, [currentValue])

    const {
        ready,
        suggestions: {
            data,
        },
        setValue,
    } = usePlacesAutocomplete({
        debounce: 300,
    })

    const customData = useMemo(() => {
        if (data.length === 0 && !autocompleteGoogle && currentValue) {
            return [{
                description: currentValue,
                id: null,
                place_id: '',
                structured_formatting: {},
            }] as DataSuggestion[]
        }

        return data.concat([{
            description: autocompleteGoogle,
            id: null,
            place_id: '',
            structured_formatting: {},
        }] as DataSuggestion[])
    }, [
        autocompleteGoogle,
        data,
        currentValue,
    ])

    const getCity = useCallback((address_components) => {
        const localityComponent = address_components.find((item) => {
            return item.types.includes('locality')
        })

        return localityComponent?.long_name
    }, [])

    const getZipCode = useCallback((address_components) => {
        const zipCodeComponent = address_components.find((item) => {
            return item.types.includes('postal_code')
        })

        return zipCodeComponent?.long_name
    }, [])

    const getAutocompleteValues = useCallback(async () => {
        try {
            const response = await getGeocode({
                placeId,
            }) || []
            const [place = null] = response

            if (place) {
                const {
                    lat: latitude = null, lng: longitude = null,
                } = getLatLng(place) || {}
                const timeZone = await getTimeZone({
                    latitude, longitude,
                })
                const {
                    address_components = [],
                    formatted_address,
                } = place
                const country = getCountry(address_components)
                const stateId = await getState({
                    addressComponents: address_components,
                    country,
                })
                const city = getCity(address_components)
                const zip = getZipCode(address_components)
                const autofillValues = {
                    addressLine1: formatted_address,
                    timeZone,
                    country,
                    stateId,
                    city,
                    zip,
                    latitude,
                    longitude,
                    placeId,
                }

                setAutocomplete(autofillValues)
            }
        } catch (error) {
            global.console.log(error)
        }
    }, [
        placeId,
        setAutocomplete,
        getCity,
        getZipCode,
        getCountry,
        getState,
        getTimeZone,
    ])

    useEffect(() => {
        if (currentPlaceId === null) {
            setPlaceId(currentPlaceId)
        } else if (placeId && (currentPlaceId !== placeId)) {
            getAutocompleteValues()
        }
    }, [
        placeId,
        currentPlaceId,
        getAutocompleteValues,
    ])

    if (!isLoaded) {
        return null
    }
    return (
        <GoogleAddress
            formValue={formValue}
            value={currentValue}
            addressOptions={customData}
            isGoogleServiceReady={ready}
            setAutocompleteGoogle={setAutocompleteGoogle}
            setPlaceId={setPlaceId}
            setValue={setValue}
            required={required}
            title={title}
            placeId={placeId}
            setAutocomplete={setAutocomplete}
            fieldLabel={title}
            name={name}
            disabled={disabled}
        />
    )
}

GoogleAddressContainer.defaultProps = defaultProps

export default GoogleAddressContainer
