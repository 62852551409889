import {
    ProductType,
} from 'app/types/enums'

const primeMapContainerTypes: {
    productType: ProductType,
    imgSrc: string,
}[] = [
    {
        productType: ProductType.VAL_PRIME_VIAL,
        imgSrc: 'assets/images/containerTypes/tp_prime_vial.png',
    },
    {
        productType: ProductType.VAL_PRIME_BOTTLE,
        imgSrc: 'assets/images/containerTypes/tp_prime_bottle.png',
    },
]

export default primeMapContainerTypes
