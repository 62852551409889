import {
    useMemo,
} from 'react'

import getChartOptions from 'app/Apps/utils/getChartOptions'
import temperatureRangeColumns from 'app/Apps/utils/temperatureRangeColumns'
import filterTempChartSensorData from 'app/Apps/utils/filterTempChartSensorData'
import {
    DOOR_LOGGER_PREFIX,
    getDoorEventsColumns,
    getDoorEventRangeValues,
    prepareDoorEventCustomDataItem,
} from 'app/Apps/utils/doorEvent'

const useDoorEventForSensorData = ({
    sensorData,
    sensorLabels,
    sensorCodes,
    showTempRange,
    temperatureRange,
    showDoorEvent,
}) => {
    const doorEventLoggers = useMemo(() => {
        const numberOfDoorSensoreCode = sensorCodes?.filter((i) => {
            return i.includes(DOOR_LOGGER_PREFIX)
        })

        return showDoorEvent ? numberOfDoorSensoreCode : []
    }, [
        showDoorEvent,
        sensorCodes,
    ])

    const filteredSensorData = useMemo(() => {
        if (!sensorData) {
            return undefined
        }

        return filterTempChartSensorData(sensorData, showDoorEvent)
    }, [
        sensorData,
        showDoorEvent,
    ])

    const userOptions = useMemo(() => {
        return getChartOptions(sensorLabels, showTempRange, showDoorEvent, doorEventLoggers?.length)
    }, [
        sensorLabels,
        showTempRange,
        showDoorEvent,
        doorEventLoggers,
    ])

    const customColumns = useMemo(() => {
        let columns = showTempRange
            ? temperatureRangeColumns : []

        if (showDoorEvent) {
            columns = [
                ...columns,
                ...getDoorEventsColumns(doorEventLoggers),
            ]
        }
        return columns
    }, [
        showTempRange,
        showDoorEvent,
        doorEventLoggers,
    ])

    const customData = useMemo(() => {
        if (!sensorData) {
            return []
        }

        const {
            maxValue,
            minValue,
        } = getDoorEventRangeValues(sensorData, showDoorEvent, showTempRange, temperatureRange)

        return sensorData.map((item, i) => {
            let newRow = showTempRange ? [
                temperatureRange ? temperatureRange.low : null,
                Boolean(temperatureRange) && (sensorData.length - 1) === i ? 'Min' : null,
                temperatureRange ? temperatureRange.high : null,
                Boolean(temperatureRange) && (sensorData.length - 1) === i ? 'Max' : null,
            ] : []

            if (showDoorEvent) {
                newRow = [
                    ...newRow,
                    ...prepareDoorEventCustomDataItem(item.d,
                        maxValue,
                        minValue,
                        sensorCodes,
                        doorEventLoggers),
                ]
            }

            return newRow
        })
    }, [
        temperatureRange,
        sensorData,
        showTempRange,
        showDoorEvent,
        sensorCodes,
        doorEventLoggers,
    ])

    return {
        userOptions,
        filteredSensorData,
        customColumns,
        customData,
    }
}

export default useDoorEventForSensorData
