import ContainerStatusEnriched from 'app/Apps/DomainObject/ComponentMapper/ContainerStatusEnriched'
import InputSingleline from 'app/shared-components/InputSingleline'
import Checkbox from 'app/shared-components/Checkbox'
import Select from 'app/shared-components/Select'
import getUniqueId from 'app/utils/getUniqueId'
import {
    RejectionReason,
} from 'app/types/enums'

import {
    Column,
} from 'app/types/simpletable.types'
import {
    BookedContainer,
} from './bookedContainers.types'

type Args = {
    disabled: boolean,
    classes: Record<string, any>,
    l: (key:string)=> string,
    rejectionReasons: Record<string, string>,
}

const getColumns = ({
    classes,
    disabled,
    l,
    rejectionReasons,
}: Args): Column<keyof BookedContainer>[] => {
    return [
        {
            label: '',
            component: Checkbox,
            fieldName: 'selected',
            getProps: () => {
                return {
                    className: classes.checkbox,
                    disabled,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.serialNumber'),
            component: InputSingleline,
            fieldName: 'serialNumber',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.jypId'),
            component: InputSingleline,
            fieldName: 'jypId',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('ContainerView.containerStatusEnriched'),
            component: ContainerStatusEnriched,
            fieldName: 'containerStatusEnriched',
            getProps: (propsData) => {
                return {
                    disabled: true,
                    rowProps: {
                        containerStatus: propsData.containerStatusEnriched,
                    },
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('ContainerView.tempRange'),
            component: InputSingleline,
            fieldName: 'tempRange',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.containerTypeContainerModel'),
            component: InputSingleline,
            fieldName: 'containerTypeContainerModel',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.rejectionReason'),
            component: Select,
            fieldName: 'rejectionReason',
            getProps: () => {
                return {
                    allowedValues: rejectionReasons,
                    disabled,
                    required: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.rejectionComment'),
            component: InputSingleline,
            fieldName: 'rejectionComment',
            getProps: ({
                rejectionReason,
            }) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    className: classes.input,
                    disabled,
                    required: rejectionReason === RejectionReason.REJECTION_OTHER,
                }
            },
            id: getUniqueId(),
        },
    ]
}

export default getColumns
