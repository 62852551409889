import {
    FieldInCard,
} from 'app/types/form.types'
import {
    Currency,
    Frequency,
} from 'app/types/enums'

import {
    FxRateFields,
} from './FxRate.types'

const fields = (getAllowedValues): FieldInCard<FxRateFields>[] => {
    return [{
        id: 'fxRate',
        title: 'FX Rate',
        className: 'fxRate',
        fields: [
            {
                name: 'ccyFrom',
                useDropdown: true,
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(Currency),
                required: true,
            },
            {
                name: 'exchangeRate',
                className: 'exchangeRate',
                required: true,
                componentName: 'IntegerInput',
            },
            {
                name: 'fxRateType',
                useDropdown: true,
                className: 'fxRateType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(Frequency),
                required: true,
            },
            {
                name: 'validFrom',
                componentName: 'DateSelect',
                required: true,
            },
            {
                name: 'ccyTo',
                useDropdown: true,
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(Currency),
                required: true,
            },
            {
                name: 'validTo',
                componentName: 'DateSelect',
                required: true,
            },
        ],
    }]
}

export default fields
