import React from 'react'
import {
    HeaderCell, HeaderCellTemplate, Compatible,
} from '@silevis/reactgrid'
import {
    SkyNetSpreadSheetCellType,
} from '../../SkyNetSpreadSheet.types'
import HeaderFilterable from './HeaderFilterableCell'

export interface HeaderFilterableCell extends Omit<HeaderCell, 'type'> {
    text: string,
    name: string,
    type: SkyNetSpreadSheetCellType.HEADER_FILTERABLE,
}

export default class HeaderFilterableTemplate extends HeaderCellTemplate {
    render(
        cell: Compatible<HeaderCell>,
    ): React.ReactNode {
        const filterableCell: HeaderFilterableCell = cell as unknown as HeaderFilterableCell

        return <HeaderFilterable cell={filterableCell} />
    }
}
