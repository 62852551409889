/* eslint-disable class-methods-use-this */

import React from 'react'
import {
    Compatible,
    NumberCellTemplate, NumberCell,
} from '@silevis/reactgrid'

export default class PercentageCellTemplate extends NumberCellTemplate {
    getClassName(cell, isInEditMode): string {
        if (isInEditMode) {
            return 'rg-number-celleditor'
        }
        return 'rg-cell rg-number-cell'
    }

    render(
        cell: Compatible<NumberCell>,
        isInEditMode: boolean,
        onCellChanged: (
            cell: Compatible<NumberCell>,
            commit: boolean
        ) => void,
    ): React.ReactNode {
        if (isInEditMode) {
            return super.render(cell, isInEditMode, onCellChanged)
        }
        return <span>{`${cell.value} %`}</span>
    }
}
