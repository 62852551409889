import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import AwardedRejectLaneRequests from '../../AwardedRejectLane.requests'

type AwardedLanes = {
    hasAwardedImplementedLanes: boolean,
}

export default (laneGroupId) => {
    const {
        data,
        isDataReady,
    } = useRequest<AwardedLanes>({
        key: RequestKeys.checkAwardedLanes,
        params: {
            id: laneGroupId,
        },
        requestFunc: RequestFn.getEntity(AwardedRejectLaneRequests.checkAwardedLanes),
        enabled: Boolean(laneGroupId),
        keepPreviousData: true,
    })

    if (!isDataReady) {
        return true
    }

    return data?.hasAwardedImplementedLanes
}
