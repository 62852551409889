import React from 'react'

import useStyles from './ModificationWarningMsg.style'

type Props = {
    msg: string,
}

const ModificationWarningMsg = ({
    msg,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.warningMsg}>
            <img
                src="assets/images/warning.svg"
                alt="warning"
            />
            <div className={classes.warningText}>{msg}</div>
        </div>
    )
}

export default ModificationWarningMsg
