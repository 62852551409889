import React from 'react'

import ChangeData from '../ChangeData'

type Props = {
    id: string,
}

const Overview = ({
    id,
}: Props) => {
    return (
        <ChangeData
            id={id}
            disabled
        />
    )
}

export default Overview
