import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    LoggerType,
} from 'app/types/enums'

const config: SkyNetTableConfig = {
    name: 'Gateway.ConnectedLoggers',
    fields: [
        {
            name: 'loggerNumber',
        },
        {
            name: 'loggerType',
            componentName: 'EnumValue',
            allowedValuesEnum: LoggerType,
        },
        {
            name: 'lastMeasuredOnUtc',
            componentName: 'TemperatureAge',
        },
    ],
}

export default config
