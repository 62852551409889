import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../engagement.request'
import {
    EngagementType,
} from '../engagement.types'

const useGetEngagement = (id: number) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<EngagementType>({
        key: RequestKeys.getEngagement,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requestConfig.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetEngagement
