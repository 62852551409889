import React, {
    useCallback,
    useState,
    useMemo,
    useEffect,
} from 'react'
import Typography from '@mui/material/Typography'
import {
    useLocation,
} from 'react-router-dom'

import Card from 'app/shared-components/Card'
import {
    TypeWithId,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'
import AdditionalFeesTable from 'app/Apps/Pricing/ContractBasis/components/AdditionalFeesTable'
import LaneGroupsTable from 'app/Apps/Pricing/ContractBasis/components/LaneGroupsTable'
import idConvertions from 'app/Apps/Pricing/ContractBasis/utils/idConvertions'

import useStyles from './AddFeesLaneGroupsForm.style'

type SelectedIdsData = {
    id: number,
    uniqField: number,
}

type Props = {
    disabled: boolean,
    id: number,
    setAdditionalFees: (fee: TypeWithId)=>void,
    setLaneGroups: (laneGroups:TypeWithId[])=> void,
    quoteId?: number,
}

const defaultProps : Partial<Props> = {
    quoteId: undefined,
}

const AddFeesLaneGroupsForm = ({
    disabled,
    id,
    setAdditionalFees,
    setLaneGroups,
    quoteId,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        state,
    } = useLocation()
    const [
        selectedAdditionalFeesIds,
        setSelectedAdditionalFeesIds,
    ] = useState({
        ids: [],
        uniqFields: [],
    })
    const {
        laneGroupId, laneGroupNumber,
    } = state || {}
    const [
        selectedLaneGroupsIds,
        setSelectedLaneGroupsIds,
    ] = useState({
        ids: laneGroupId ? [laneGroupId] : [],
        uniqFields: laneGroupNumber ? [laneGroupNumber] : [],
    })

    const handleSetSelectedAdditionalFeesIds = useCallback((selectedIds: SelectedIdsData[]) => {
        setSelectedAdditionalFeesIds(
            {
                ids: selectedIds.map((additionalFeesId) => {
                    return additionalFeesId.id
                }),
                uniqFields: selectedIds.map((additionalFeesId) => {
                    return additionalFeesId.uniqField
                }),
            },
        )
    }, [])

    const handleSetSelectedLaneGroupsIds = useCallback((laneGroupsIds: SelectedIdsData[]): void => {
        setSelectedLaneGroupsIds(
            {
                ...selectedLaneGroupsIds,
                ids: laneGroupsIds.map((laneGroupsId) => {
                    return laneGroupsId.id
                }),
                uniqFields: laneGroupsIds.map((laneGroupsId) => {
                    return laneGroupsId.uniqField
                }),
            },
        )
    }, [selectedLaneGroupsIds])

    const additionalFees = useMemo(():TypeWithId => {
        return {
            id: selectedAdditionalFeesIds.ids[0],
        }
    }, [selectedAdditionalFeesIds])

    const laneGroups = useMemo(():TypeWithId[] => {
        return selectedLaneGroupsIds.ids.map(idConvertions)
    }, [selectedLaneGroupsIds])

    useEffect(() => {
        setAdditionalFees(additionalFees)
    }, [
        setAdditionalFees,
        additionalFees,
    ])

    useEffect(() => {
        setLaneGroups(laneGroups)
    }, [
        laneGroups,
        setLaneGroups,
    ])

    return (
        <div>
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="createquote-additionalFees-card"
            >
                <div className={classes.header}>
                    <Typography variant="h3">
                        Additional Fees
                    </Typography>
                </div>
                <AdditionalFeesTable
                    selectedRows={selectedAdditionalFeesIds.uniqFields}
                    onSelectedRow={handleSetSelectedAdditionalFeesIds}
                    contractId={id}
                    disabled={disabled}
                />
            </Card>
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="createquote-laneGroup-card"
            >
                <div className={classes.header}>
                    <Typography variant="h3">
                        Lane Groups
                    </Typography>
                </div>
                <LaneGroupsTable
                    contractId={id}
                    selectedRows={selectedLaneGroupsIds.uniqFields}
                    onSelectedRow={handleSetSelectedLaneGroupsIds}
                    disabled={disabled}
                    showSelectAll
                    quoteId={quoteId}
                />
            </Card>
        </div>
    )
}

AddFeesLaneGroupsForm.defaultProps = defaultProps

export default AddFeesLaneGroupsForm
