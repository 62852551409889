import {
    FilterType,
} from 'app/types/common.enums'
import {
    RichTableColumns,
} from 'app/types/richtable.types'
import TimeLessDateRangeSelectorFilter from 'app/shared-components/DateRangeSelectorFilter/TimeLessDateRangeSelectorFilter'

import {
    LaneGroup,
} from 'app/Apps/Pricing/Pricing.types'
import {
    CollectionServiceType,
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LeaseType,
} from 'app/types/enums'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof LaneGroup>[] => {
    return [
        {
            id: 'laneGroupNumber',
            mapCellProps: 'laneGroupNumber',
            headerProps: {
                children: 'Lane Group',
            },
        },
        {
            id: 'deliveryServiceType',
            filterField: 'deliveryServiceType',
            filterType: FilterType.Array,
            headerProps: {
                children: 'Delivery Service Type',
            },
            mapCellProps: 'deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
        },
        {
            id: 'handoverPointLocationName',
            filterField: 'handoverPointLocationName',
            filterType: FilterType.Array,
            headerProps: {
                children: 'Handover Point',
            },
            mapCellProps: 'handoverPointLocationName',
        },
        {
            id: 'collectionServiceType',
            filterField: 'collectionServiceType',
            filterType: FilterType.Array,
            headerProps: {
                children: 'Collection Service Type',
            },
            mapCellProps: 'collectionServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: CollectionServiceType,
        },
        {
            id: 'collectionDropoffPointLocationName',
            filterField: 'collectionDropoffPointLocationName',
            filterType: FilterType.Array,
            headerProps: {
                children: 'Collection Point',
            },
            mapCellProps: 'collectionDropoffPointLocationName',
        },
        {
            id: 'leaseType',
            filterField: 'leaseType',
            filterType: FilterType.Array,
            headerProps: {
                children: 'Lease Type',
            },
            mapCellProps: 'leaseType',
            width: '120px',
            componentName: 'EnumValue',
            allowedValuesEnum: LeaseType,
        },

        {
            id: 'originAirportsIataCode',
            filterField: 'originAirportsIataCode',
            filterType: FilterType.Array,
            headerProps: {
                children: 'From',
            },
            mapCellProps: 'originAirportsIataCode',
            width: '70px',
            componentName: 'List',
        },
        {
            id: 'destinationAirportsIataCode',
            filterField: 'destinationAirportsIataCode',
            filterType: FilterType.Array,
            headerProps: {
                children: 'To',
            },
            mapCellProps: 'destinationAirportsIataCode',
            width: '70px',
            componentName: 'List',
        },
        {
            id: 'tempRange',
            filterField: 'tempRange',
            filterType: FilterType.Array,
            headerProps: {
                children: 'Temp',
            },
            width: '70px',
            mapCellProps: 'tempRange',
        },
        {
            id: 'additionalFeesValidTo',
            headerProps: {
                children: l('LaneGroupView.additionalFeesValidTo'),
            },
            filterField: 'additionalFeesValidTo',
            mapCellProps: 'additionalFeesValidTo',
            FilterComponent: TimeLessDateRangeSelectorFilter,
            filterType: FilterType.DateRange,
            componentName: 'LaneDateEnriched',
        },
        {
            id: 'laneFeesValidTo',
            headerProps: {
                children: l('LaneGroupView.laneFeesValidTo'),
            },
            filterField: 'laneFeesValidTo',
            filterType: FilterType.DateRange,
            FilterComponent: TimeLessDateRangeSelectorFilter,
            mapCellProps: 'laneFeesValidTo',
            componentName: 'LaneDateEnriched',
        },
    ]
}

export default columns
