import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    NavLink,
    useLocation,
} from 'react-router-dom'
import {
    Typography,
} from '@mui/material'
import iconType from 'app/shared-components/images/icons/iconType'
import {
    useTheme,
} from '@mui/material/styles'

import getAppNameFromPath from 'app/utils/getAppNameFromPath'

import useStyles from './ActiveTab.style'

const propTypes = {
    icon: iconType.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
    className: PropTypes.string,
}

const defaultProps = {
    className: '',
}

const ActiveTab = (props) => {
    const {
        pathname,
    } = useLocation()

    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)
    const {
        icon: Icon,
        links,
        className,
    } = props

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                <Icon
                    className={clsx(
                        classes.icon,
                        classes[className],
                    )}
                />
            </div>
            <div
                className={classes.linkContainer}
            >
                {links.map(({
                    url,
                    title,
                    id,
                }) => {
                    return (
                        <NavLink
                            key={`active-tab-links-${title}`}
                            to={url}
                            data-testid={`active-tab-links-${id}`}
                            className={clsx(
                                {
                                    [classes.link]: true,
                                    [classes.activeLink]: getAppNameFromPath(pathname) === id,
                                },
                            )}
                        >
                            <Typography
                                variant="button"
                            >
                                {title}
                            </Typography>
                        </NavLink>
                    )
                })}
            </div>
        </div>
    )
}

ActiveTab.propTypes = propTypes
ActiveTab.defaultProps = defaultProps

export default ActiveTab
