import React from 'react'
import {
    Icon,
} from '@mui/material'
import PropTypes from 'prop-types'
import {
    Link as RouterLink,
} from 'react-router-dom'

import useStyles from './Link.styles'

const propTypes = {
    link: PropTypes.string.isRequired,
}

const Link = (props) => {
    const {
        link,
    } = props
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.root}>
            <div>
                <RouterLink to={link}>
                    <Icon
                        fontSize="large"
                        className={classes.icon}
                    >
                        navigate_next
                    </Icon>
                </RouterLink>
            </div>
        </div>
    )
}

Link.propTypes = propTypes

export default Link
