import React from 'react'
import {
    Chip,
} from '@mui/material'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './ChipSelect.style'

const propTypes = {
    children: PropTypes.string.isRequired,
    isFocused: PropTypes.bool.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    removeProps: PropTypes.objectOf(PropTypes.func).isRequired,
    selectProps: PropTypes.shape({
        getValueLabel: PropTypes.func,
        iconPath: PropTypes.string,
    }).isRequired,
}

const defaultProps = {}

function MultiValue(props) {
    const {
        children,
        isFocused,
        data,
        removeProps,
        selectProps,
    } = props

    const {
        getValueLabel,
        iconPath,
    } = selectProps

    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)
    const label = getValueLabel ? getValueLabel(data) : children

    return (
        <Chip
            tabIndex={-1}
            label={label}
            className={clsx(classes.chip, {
                [classes.chipFocused]: isFocused,
            }, data.class)}
            onDelete={() => {
                removeProps.onClick()
            }}
            onMouseDown={removeProps.onMouseDown}
            avatar={iconPath && (
                <img
                    alt={label}
                    src={iconPath}
                />
            )}
        />
    )
}

MultiValue.propTypes = propTypes
MultiValue.defaultProps = defaultProps

export default MultiValue
