import {
    FieldInCard,
} from 'app/types/form.types'

import {
    PreBookingCommentType,
} from '../preBookingComment.types'

const fields: FieldInCard<keyof PreBookingCommentType>[] = [{
    title: 'New Comment',
    fields: [{
        name: 'comment',
        componentName: 'LongText',
        required: true,
        labelKey: 'OrderComment.comment',
    }],
}]

export default fields
