import {
    SimulationStep,
} from '../../simulation.types'

const reorderElement = (
    arr: SimulationStep[],
    currentIndex: number,
    newIndex: number,
): SimulationStep[] => {
    const dragItem = arr[currentIndex]

    arr.splice(currentIndex, 1)
    arr.splice(newIndex, 0, dragItem)

    return [...arr]
}

export default reorderElement
