import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import InternalTransportsOverview from './InternalTransportsOverview'
import orderRequest from '../orders.request'
import request from './internalTransport.request'
import config from './internalTransport.config'
import ContainerSelection from '../Components/ContainerSelection/ContainerSelectionContainer'
import OrderSteps from '../Components/OrderSteps'
import OrderNotificationsTab from '../Components/OrderNotificationsTab'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const InternalTransport = () => {
    const tabs: SidebarTab[] = [
        {
            url: 'overview',
            label: 'Overview',
            Component: InternalTransportsOverview,
        },
        {
            url: 'notifications',
            label: 'Notifications',
            Component: OrderNotificationsTab,
        },
        {
            url: 'container-selection',
            label: 'Containers / Freight',
            Component: ContainerSelection,
        },
        {
            url: 'order-steps',
            label: 'Order Steps',
            Component: OrderSteps,
        },
    ]

    return (
        <SkyNetTable
            name={orderRequest.dataModelName}
            moduleName={orderRequest.moduleName}
            tabs={tabs}
            customUrl={request.all.url}
            tableControls={tableControls}
            uniqField="orderNumber"
            tableConfig={config}
            showSearch
        />
    )
}

export default InternalTransport
