import React from 'react'

import useGetRegionalGroupById from 'app/Apps/AccountManagement/RegionalGroups/hooks/useGetRegionalGroupById'
import {
    TypeaheadObjectContainer,
} from 'app/shared-components/Typeahead'

import {
    RegionalGroup,
} from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.types'

import useRegionalGroupsLoadOptions from './hooks/useRegionalGroupsLoadOptions'

const defaultProps = {
    name: '',
    value: undefined,
    title: 'Opportunity Number',
    disabled: false,
    className: '',
    required: false,
    filters: undefined,
}
const RegionalGroupSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
    filters,
}: {
    name?: string,
    value?: RegionalGroup,
    onChange: (value: any) => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
    filters?: Record<string, (string|number|boolean)[]>
}) => {
    const {
        data,
    } = useGetRegionalGroupById(value?.id)

    const loadOptions = useRegionalGroupsLoadOptions(filters)

    return (
        <TypeaheadObjectContainer
            name={name}
            title={title}
            className={className}
            onChange={onChange}
            loadOptions={loadOptions}
            labelData={data}
            labelField="regionalGroupNumber"
            value={value?.id}
            disabled={disabled}
            isRequired={required}
        />
    )
}

RegionalGroupSelector.defaultProps = defaultProps

export default RegionalGroupSelector
