import {
    FieldInCard,
} from 'app/types/form.types'

import {
    TemperatureRangePreconditioned,
} from 'app/types/enums'

import {
    Container,
} from '../../containers.types'

const getFields = (getAllowedValues): FieldInCard<keyof Container>[] => {
    return [
        {
            title: 'Summary',
            className: 'containerData',
            fields: [
                {
                    name: 'serialNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'Container.serialNumber',
                    disabled: true,
                },
                {
                    name: 'material',
                    componentName: 'InputSingleline',
                    labelKey: 'Container.material',
                },
                {
                    name: 'financingContract',
                    componentName: 'InputSingleline',
                    labelKey: 'Container.financingContract',
                    disabled: true,
                },
                {
                    name: 'manufacturingTimestamp',
                    componentName: 'DateTimeZonedSelect',
                    disabled: true,
                    labelKey: 'Container.manufacturingTimestamp',
                },
                {
                    name: 'tempRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TemperatureRangePreconditioned),
                    showInColumns: true,
                    required: true,
                    labelKey: 'Container.tempRange',
                },
            ],
        },
        {
            title: 'Description',
            className: 'containerDescription',
            fields: [{
                name: 'containerDescription',
                componentName: 'LongText',
                labelKey: 'Container.containerDescription',
            }],
        },
        {
            title: 'Location',
            className: 'location',
            fields: [{
                name: 'location',
                labelKey: 'Container.location',
                componentName: 'LocationObjectSelector',
                required: true,
            }],
        },
    ]
}

export default getFields
