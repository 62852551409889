import React, {
    useMemo,
} from 'react'

import OrderNotifications from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import Card from 'app/shared-components/Card'
import {
    OrderStatuses,
} from 'app/types/enums'
import {
    Order,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useGetOrderByNumber from '../../hooks/useGetOrderByNumber'
import useStyles from './OrderNotificationsTab.style'

const OrderNotificationsTab = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        classes,
    } = useStyles()

    const {
        data = {} as Order,
    } = useGetOrderByNumber(orderNumber)

    const {
        orderStatus,
        id,
    } = data

    const disabled: boolean = useMemo(() => {
        return orderStatus === OrderStatuses.CLOSED || orderStatus === OrderStatuses.CANCELLED
    }, [orderStatus])

    return (
        <Card
            className={classes.root}
            contentClass={classes.wrapper}
            title="Notifications"
            titleClass={classes.title}
        >
            <OrderNotifications
                includeFilters={{
                    orderId: [id],
                }}
                disabled={disabled}
                orderNumber={orderNumber}
                url={orderManagementRequest.Notifications.Overview.url(orderNumber)}
            />
        </Card>
    )
}

export default OrderNotificationsTab
