import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    OpportunitiesFields,
} from './Opportunities.types'

const columns: RichTableColumns<OpportunitiesFields>[] = [
    {
        id: 'opportunityNumber',
        headerProps: {
            children: 'Opportunity',
        },
        mapCellProps: 'opportunityNumber',
    },
    {
        id: 'reprOriginAirport',
        headerProps: {
            children: 'Origin',
        },
        mapCellProps: 'reprOriginAirport',
        filterField: 'reprOriginAirport',
        filterType: FilterType.Array,
    },
    {
        id: 'reprDestinationAirport',
        headerProps: {
            children: 'Destination',
        },
        mapCellProps: 'reprDestinationAirport',
        filterField: 'reprDestinationAirport',
        filterType: FilterType.Array,
    },
    {
        id: 'changedOn',
        headerProps: {
            children: 'Changed On',
        },
        mapCellProps: 'changedOn',
    },
    {
        id: 'changedBy',
        headerProps: {
            children: 'Changed By',
        },
        mapCellProps: 'changedBy',
    },
]

export default columns
