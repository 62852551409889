import {
    useMemo,
} from 'react'

import useGetLocation from 'app/Apps/ContactManagement/Locations/hooks/useGetLocation'

export default (id, getLabelConfig) => {
    const {
        data,
    } = useGetLocation(id)

    const labelData = useMemo(() => {
        if (data) {
            return getLabelConfig(data)
        }

        return null
    }, [
        data,
        getLabelConfig,
    ])

    return {
        location: data,
        labelData,
    }
}
