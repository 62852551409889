import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    }
})

export default useStyles
