import {
    useCallback, useMemo,
} from 'react'
import {
    CellChange, Row,
} from '@silevis/reactgrid'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import getRows from '../getRows'
import {
    Domain, ExtendedCellTypes, SkyNetSpreadSheetConfigType,
} from '../SkyNetSpreadSheet.types'
import {
    SpreadsheetMappingResponse,
} from '../SkyNetSpreadSheet.requests'
import useSkyNetSpreadSheetValidationContext from '../SkyNetSpreadsheetValidation'
import useFindUnresolvedValues from './useFindUnresolvedValues'

// called every time we made changes to one or several cells
export default ({
    data,
    getSpreadsheetMappings,
    applyResultsToValues,
    convertRowsToData,
    config,
    styles,
    disabled,
    locked,
    setData,
    validateRow,
}: {
    applyResultsToValues: (
        newValues: CellChange<ExtendedCellTypes>[],
        mappedResult: Map<Domain, SpreadsheetMappingResponse>,
        updatedData: Record<string, any>[]
    ) => Promise<Record<string, any>[]>,
    config: SkyNetSpreadSheetConfigType<any>,
    convertRowsToData: (r: Row[]) => Record<string, any>[],
    data: Record<string, any>[],
    disabled: boolean,
    getSpreadsheetMappings: (
        unresolvedValues: Map<Domain, Set<string>>
    ) => Promise<Map<Domain, SpreadsheetMappingResponse>>,
    locked: boolean,
    setData: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
    styles: Record<string, any>,
    validateRow: (i: Record<string, any>) => boolean,
}) => {
    const {
        onError,
    } = useHandleStatusRequest({
        id: `${config.id}-spreadsheet`,
    })

    const unresolvedValues = useMemo(() => {
        return new Map<Domain, Set<string>>()
    }, [])
    const findUnresolvedValues = useFindUnresolvedValues(unresolvedValues)

    const {
        showError,
        setValid,
        valid,
        setShowError,
    } = useSkyNetSpreadSheetValidationContext()

    return useCallback(async (newValues: CellChange[]) => {
        let newData = JSON.parse(JSON.stringify(data))

        try {
            findUnresolvedValues(newValues)
            let mappedResult

            if (unresolvedValues.size > 0) {
                mappedResult = await getSpreadsheetMappings(unresolvedValues)
            }

            newData = await applyResultsToValues(newValues, mappedResult, newData)
        } catch (err) {
            onError({
                response: {
                    data: ['Changes were not applied, please try again'],
                    statusCode: 500,
                },
            })
        } finally {
            setShowError(false)
            setValid(true)
            setData(
                convertRowsToData(
                    getRows({
                        data: newData,
                        config,
                        styles,
                        spreadSheetDisabled: disabled || locked,
                        showError,
                        setValid,
                        valid,
                        validateRow,
                    }),
                ),
            )
        }
    }, [
        data,
        findUnresolvedValues,
        unresolvedValues,
        getSpreadsheetMappings,
        applyResultsToValues,
        onError,
        setShowError,
        setValid,
        setData,
        convertRowsToData,
        config,
        styles,
        disabled,
        locked,
        showError,
        valid,
        validateRow,
    ])
}
