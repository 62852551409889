/* global google */

import loadScript from 'app/utils/loadScript'

const url = 'https://www.gstatic.com/charts/loader.js'

const createColumns = (dataTable, chartColumns) => {
    chartColumns.forEach((column) => {
        dataTable.addColumn(column)
    })
}

const loadChart = ({
    chartData,
    elm,
    options,
    onError,
    columns,
}: {
    chartData: (string | number | Date)[][],
    elm: Element,
    options: Record<string, any>,
    onError: (err: any) => void,
    columns: {type: string}[]
}) => {
    loadScript(url).then(() => {
        google.charts.load('current', {
            packages: ['timeline'],
        })

        const drawChart = () => {
            const chart = new google.visualization.Timeline(elm)
            const dataTable = new google.visualization.DataTable()

            createColumns(dataTable, columns)

            dataTable.addRows(chartData)
            chart.draw(dataTable, options)
        }

        return google.charts.setOnLoadCallback(drawChart)
    })
        .catch((error) => {
            onError([error])
        })
}

export default loadChart
