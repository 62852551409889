import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

import deliveryViaRoadFields from './deliveryViaRoad.fields'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const deliveryViaRoadConfirmConfig: SkyNetTableConfig<DailyOpsFields> = {
    name: 'DeliveryViaRoadConfirm',
    tabs: [{
        name: 'Delivery: Confirmation',
        url: 'all',
    }],
    fields: deliveryViaRoadFields,
}

export default deliveryViaRoadConfirmConfig
