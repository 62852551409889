/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import sortAscending from 'app/utils/sortAscending'
import ChipSelect from 'app/shared-components/ChipSelect'
import InputSingleline from 'app/shared-components/InputSingleline'

import MultipleSelectUnselectable from '../MultipleSelectUnselectable'

import useStyles from './MultiEnumSelector.styles'

const propTypes = {
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    allowedValues: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    allowOnly: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
}

const defaultProps = {
    title: '',
    value: [],
    className: null,
    allowOnly: [],
    disabled: false,
}

const filteredAllowedValues = (allowedValues, allowOnly, value) => {
    if (allowOnly.length) {
        return Object.entries(allowedValues).filter(([item]) => {
            return allowOnly.includes(item) || value.includes(item)
        })
    }
    return Object.entries(allowedValues)
}

const MultiEnumSelector = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        disabled,
        title,
        allowedValues,
        onChange,
        value,
        className,
        allowOnly,
    } = props

    const convertToChips = useCallback((item) => {
        return {
            label: allowedValues[item],
            value: item,
        }
    }, [allowedValues])

    if (disabled) {
        if (!value.length) {
            return (
                <InputSingleline
                    title={title}
                    value="-"
                    disabled
                />
            )
        }
        return (
            <ChipSelect
                disabled
                title={title}
                value={value.map(convertToChips)}
                options={Object.keys(allowedValues).map(convertToChips)}
            />
        )
    }

    const entries = sortAscending(filteredAllowedValues(allowedValues, allowOnly, value))

    return (
        <div className={clsx(classes.root, className)}>
            <MultipleSelectUnselectable
                {...props}
                options={entries}
                value={value}
                onChange={(options) => {
                    const val = options.map((option) => {
                        return option[0]
                    })

                    return onChange(val)
                }}
            />
        </div>
    )
}

MultiEnumSelector.propTypes = propTypes
MultiEnumSelector.defaultProps = defaultProps

export default MultiEnumSelector
