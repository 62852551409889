import createReducer from 'app/utils/createReducer'

import {
    SET_VALUE,
    SET_ERROR,
    SET_ASSIGNED_LANES,
    SET_OPEN,
    SET_DEFAULT_LANE,
} from './actions'

const reducer = createReducer({
    [SET_VALUE]: (state, {
        newValue,
    }) => {
        return {
            ...state,
            value: newValue,
        }
    },
    [SET_ERROR]: (state, {
        error,
    }) => {
        return {
            ...state,
            error,
        }
    },
    [SET_ASSIGNED_LANES]: (state, {
        assignedLanes,
    }) => {
        return {
            ...state,
            assignedLanes,
        }
    },
    [SET_OPEN]: (state, {
        open,
    }) => {
        return {
            ...state,
            open,
        }
    },
    [SET_DEFAULT_LANE]: (state, {
        defaultLane,
    }) => {
        return {
            ...state,
            defaultLane,
        }
    },
})

export default reducer
