import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            margin: `${theme.spacing(2)} 0`,
        },
        wrapper: {
            display: 'flex',
        },
        info: {
            marginRight: theme.spacing(1.5),
            width: 250,
        },
    }
})

export default useStyles
