import React, {
    useMemo,
} from 'react'
import moment from 'moment'

import {
    dateTimeMask,
} from 'app/utils/date'

import useStyles from './LaneDateEnriched.styles'

type Props = {
    value?: string,
}

const defaultProps: Partial<Props> = {
    value: undefined,
}

const LaneDateEnriched = ({
    value,
}:Props) => {
    const {
        classes,
    } = useStyles()
    const matchedClass = useMemo(() => {
        const now = moment()
        const laneDate = moment(value, dateTimeMask)
        const laneDateSubtracted = moment(value, dateTimeMask).subtract(15, 'd')

        if (now <= laneDateSubtracted) {
            return classes.green
        }
        if (laneDateSubtracted < now && now < laneDate) {
            return classes.yellow
        }
        if (now >= laneDate) {
            return classes.red
        }
        return classes.green
    }, [
        classes,
        value,
    ])

    return (
        <div className={matchedClass}>
            {value}
        </div>
    )
}

LaneDateEnriched.defaultProps = defaultProps

export default LaneDateEnriched
