import {
    FormFields,
} from 'app/types/form.types'

type CurrentMonthForecastUpdate = {
    currentMonth: string,
    submittedValue: number,
    units: number,
}

const fields: FormFields<keyof CurrentMonthForecastUpdate> = [{
    title: 'Update current month forecast',
    fields: [
        {
            id: 'column1',
            fields: [{
                name: 'currentMonth',
                labelKey: 'CurrentMonthForecastUpdate.currentMonth',
                componentName: 'InputSingleline',
                disabled: true,
            }],
        },
        {
            id: 'column2',
            fields: [{
                name: 'submittedValue',
                labelKey: 'CurrentMonthForecastUpdate.submittedValue',
                componentName: 'InputSingleline',
                disabled: true,
            }],
        },
        {
            id: 'column3',
            fields: [{
                name: 'units',
                labelKey: 'CurrentMonthForecastUpdate.updatedValue',
                componentName: 'IntegerInput',
                required: true,
            }],
        },
    ],
}]

export default fields
