import {
    LeaseType,
    Currency,
    TemperatureRange,
} from 'app/types/enums'
import {
    FormPart,
} from 'app/types/form.types'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

const laneGroupFields = (
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): FormPart<keyof Lane> => {
    return {
        title: 'Lane Group',
        className: 'laneGroup',
        fields: [
            {
                id: 'laneGroup1',
                fields: [
                    {
                        name: 'leaseType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LeaseType),
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.leaseType',
                    },
                    {
                        name: 'createdBy',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.createdBy',
                    },
                    {
                        name: 'businessDevelopers',
                        componentName: 'MultipleContactObjectSelector',
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.businessDevelopers',
                    },
                    {
                        name: 'keyAccountManager',
                        componentName: 'ContactObjectSelector',
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.keyAccountManager',
                    },
                ],
            },
            {
                id: 'laneGroup2',
                fields: [
                    {
                        name: 'expPalletsYear',
                        componentName: 'IntegerInput',
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.expPalletsYear',
                    },
                    {
                        name: 'tempRange',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(TemperatureRange),
                        labelKey: 'LaneOpsInformationDto.tempRange',
                    },
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Currency),
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.currency',
                    },
                    {
                        name: 'account',
                        componentName: 'AccountSelector',
                        disabled: true,
                        labelKey: 'Prebooking.pricingAccount',
                    },
                ],
            },
        ],
    }
}

export default laneGroupFields
