import React, {
    useMemo,
    useState,
    useCallback,
} from 'react'
import {
    Route,
    Switch,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import TabsContext from './TabsContext'
import useStyles from './TabNavigation.styles'
import Tabs from '../Tabs'
import TabNavigationContent from './TabNavigationContent'

const propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
    })).isRequired,
    tab: PropTypes.string,
    onTabChange: PropTypes.func,
}

const defaultProps = {
    tab: '',
    onTabChange: noop,
}

const TabNavigation = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        url, params: {
            id,
        } = {},
    } = useRouteMatch()

    const {
        tabs,
        tab,
        onTabChange,
    } = props

    const [
        defaultTab,
        setDefaultTab,
    ] = useState(tab)

    const context = useMemo(() => {
        return {
            baseUrl: url,
            defaultTab,
            setDefaultTab,
        }
    }, [
        url,
        defaultTab,
        setDefaultTab,
    ])

    const TabContentRenderer = useCallback((
        {
            history,
            match: {
                params: {
                    tab: selectedTab,
                },
            },
        },
    ) => {
        if (tabs.some((item) => { return item.url === selectedTab })) {
            onTabChange(selectedTab)

            return (
                <TabNavigationContent
                    tabs={tabs}
                    id={id}
                />
            )
        }

        history.push({
            pathname: `${url}/${tabs[0].url}`,
        })

        return null
    }, [
        onTabChange,
        tabs,
        url,
        id,
    ])

    return (
        <TabsContext.Provider
            value={context}
        >
            <div className={classes.tabsWrapper}>
                <div className={classes.tabs}>
                    <Tabs
                        items={tabs}
                    />
                </div>
            </div>
            <Switch>
                <Route
                    path={`${url}/:tab`}
                    render={TabContentRenderer}
                />
                <Redirect
                    from={url}
                    to={{
                        pathname: `${url}/${defaultTab || tabs[0].url}`,
                    }}
                />
                <Route
                    path={`${url}`}
                >
                    404
                </Route>
            </Switch>
        </TabsContext.Provider>
    )
}

TabNavigation.propTypes = propTypes
TabNavigation.defaultProps = defaultProps

export default TabNavigation
