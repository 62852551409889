import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    SimulationProfile,
} from 'app/types/enums'

const config: SkyNetTableConfig = {
    name: 'SavedSimulationsTable',
    tabs: [{
        name: 'TransportSimulation',
        url: 'all',
    }],
    fields: [
        {
            name: 'simulationNumber',
        },
        {
            name: 'routeOriginAirportIataCode',
            filterField: 'routeOriginAirportIataCode',
        },
        {
            name: 'routeDestinationAirportIataCode',
            filterField: 'routeDestinationAirportIataCode',
        },
        {
            name: 'createdBy',
            filterField: 'createdBy',
        },
        {
            name: 'createdOn',
            labelKey: 'Damages.createdOn',
        },
        {
            name: 'simulationProfile',
            componentName: 'EnumValue',
            allowedValuesEnum: SimulationProfile,
            filterField: 'simulationProfile',
        },
        {
            name: 'simulationFinalized',
            componentName: 'Boolean',
        },
    ],
}

export default config
