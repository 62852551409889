import {
    RequestConfig,
} from 'app/types/request.types'

type CountOrdersLaneFeeRequests = {
    count: ({
        id, data,
    }) => RequestConfig
}

const countOrderLaneFeeRequests: CountOrdersLaneFeeRequests = {
    count: ({
        id, data,
    }) => {
        return {
            url: `order/billing/count/lanefees/${id}`,
            method: 'POST',
            data,
        }
    },
}

export default countOrderLaneFeeRequests
