/* eslint-disable class-methods-use-this */
import React from 'react'
import {
    Cell,
    CellTemplate,
    Compatible,
    getCellProperty,
    keyCodes,
    Uncertain, UncertainCompatible,
} from '@silevis/reactgrid'
import isEmpty from 'lodash/isEmpty'

import {
    selectSpreadSheetConfigByDomain,
} from 'app/store/SpreadSheetConfigs'
import store from 'app/store'
import {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import {
    TooltipWrapper,
} from 'app/shared-components/Tooltip'
import MultipleTypeahead from './MultipleTypeaheadCell'
import {
    Domain, SkyNetSpreadSheetCellType, SpreadsheetCellOption,
} from '../../SkyNetSpreadSheet.types'
import SelectableElement from '../../SelectableElement'

type Value = {
    id: number | string,
    label: string,
    textLabel: string,
    value: number | string,
}

export interface MultipleTypeaheadCell extends Cell {
    type: SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD,
    loadOptions: (o: DelayedLoadOptions) => Promise<SpreadsheetCellOption[]>,
    filters?: Record<string, any>,
    domain: Domain,
    name: string,
    id?: number,
    text?: string,
    selectedValue: Value[],
}

export default class MultipleTypeaheadCellTemplate implements CellTemplate<MultipleTypeaheadCell> {
    private configId: string

    private delimiter = '; '

    constructor({
        configId,
    }: {
        configId: string
    }) {
        this.configId = configId
    }

    getCellOptionsFromStore(cell: UncertainCompatible<MultipleTypeaheadCell>) {
        const {
            options,
        } = selectSpreadSheetConfigByDomain(
            store.getState(), {
                field: cell.name,
                name: this.configId,
                domain: cell.domain,
            },
        )

        return options
    }

    findByText(cell: UncertainCompatible<MultipleTypeaheadCell>): Value[] {
        const options = this.getCellOptionsFromStore(cell) || []

        const textValues = cell.text.split(this.delimiter)

        return textValues.reduce((acc, text) => {
            const el = options.find(({
                label, textLabel,
            }) => {
                return textLabel === text || label === text
            })

            if (el) {
                return [
                    ...acc,
                    el,
                ]
            }
            return acc
        }, [])
    }

    getLabel(
        selectedValue: Value[],
    ): string {
        return selectedValue?.map((value) => {
            return value.textLabel || value.label
        })
            .join(this.delimiter)
    }

    getCompatibleCell(
        uncertainCell: Uncertain<MultipleTypeaheadCell>,
    ): Compatible<MultipleTypeaheadCell> {
        let selectedValue

        try {
            selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'object')
        } catch {
            selectedValue = null
        }

        return {
            ...uncertainCell,
            selectedValue,
            name: uncertainCell.name,
            domain: uncertainCell.domain,
            value: selectedValue?.value,
            loadOptions: uncertainCell.loadOptions,
            text: this.getLabel(selectedValue),
        }
    }

    update(
        cell: Compatible<MultipleTypeaheadCell>,
        cellToMerge: UncertainCompatible<MultipleTypeaheadCell>,
    ): Compatible<MultipleTypeaheadCell> {
        if (cellToMerge.type === SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD) {
            if (!isEmpty(cellToMerge.text) && !cellToMerge.selectedValue.length) {
                return this.getCompatibleCell({
                    ...cell,
                    ...cellToMerge,
                    selectedValue: this.findByText(cellToMerge),
                })
            }

            return this.getCompatibleCell({
                ...cell,
                ...cellToMerge,
            })
        }

        return undefined
    }

    handleKeyDown(
        cell: Compatible<MultipleTypeaheadCell>,
        keyCode: number,
    ): { cell: Compatible<MultipleTypeaheadCell>, enableEditMode: boolean } {
        if (keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
            || keyCode === keyCodes.SPACE) {
            return {
                cell,
                enableEditMode: true,
            }
        }
        return {
            cell, enableEditMode: false,
        }
    }

    getTooltipValue(cell: Compatible<MultipleTypeaheadCell>): JSX.Element {
        if (isEmpty(cell.text)) return null
        return (
            <div>
                { (cell.text.split(this.delimiter) || []).map((text, i) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <div key={`${i}-${text}`}>{text}</div>
                })}
            </div>
        )
    }

    render(
        cell: Compatible<MultipleTypeaheadCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<MultipleTypeaheadCell>, commit: boolean) => void,
    ): React.ReactNode {
        if (!isInEditMode) {
            return (
                <TooltipWrapper
                    value={this.getTooltipValue(cell)}
                    ownTooltipProps={{
                        placement: 'top',
                        followCursor: true,
                        arrow: false,
                    }}
                    styles={{
                        width: '100%',
                    }}
                >
                    <SelectableElement
                        text={cell.text}
                        id={cell.id}
                        name={cell.name}
                        editMode={false}
                    />
                </TooltipWrapper>
            )
        }
        return (
            <MultipleTypeahead
                cell={cell}
                onChange={(
                    selected,
                ) => {
                    return onCellChanged(this.getCompatibleCell({
                        ...cell,
                        selectedValue: selected,
                    }), true)
                }}
            />
        )
    }
}
