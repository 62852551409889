const statusMap = {
    CREATED: [
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Created',
        },
        {
            iconPath: 'pending_blue',
            title: 'Sent To Abacus',
        },
        {
            iconPath: 'pending_grey',
            title: 'Sent To Billing Company',
        },
        {
            iconPath: 'pending_grey',
            title: 'Paid',
        },
    ],
    SENTTOABACUS: [
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Created',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Sent To Abacus',
        },
        {
            iconPath: 'pending_blue',
            title: 'Sent To Billing Company',
        },
        {
            iconPath: 'pending_grey',
            title: 'Paid',
        },
    ],
    SENTTOBILLINGCOMPANY: [
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Created',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Sent To Abacus',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Sent To Billing Company',
        },
        {
            iconPath: 'pending_blue',
            title: 'Paid',
        },
    ],
    PAID: [
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Created',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Sent To Abacus',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Sent To Billing Company',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Paid',
        },
    ],
    OVERDUE: [
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Created',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Sent To Abacus',
        },
        {
            iconPath: 'circle_tick_mark_blue',
            title: 'Sent To Billing Company',
        },
        {
            iconPath: 'pending_red',
            title: 'Overdue',
        },
    ],
    CANCELLED: [{
        iconPath: 'circle_rose_cross_red',
        title: 'Invoice Cancelled',
    }],
}

export default statusMap
