import {
    OrderStepFields,
    TransportModeSea,
    SeaFreightCarrier,
    TransportModeRoad,
    DefaultTruckingComment,
    SeafreightContainerSize,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LocationType,
} from 'app/types/enums'
import {
    FieldInCard,
} from 'app/types/form.types'

type Args = {
    forwarderId: number,
    transportModeRoad: TransportModeRoad,
    pickupLocationId: number,
    pickupLocationContacts: {id: number}[],
    deliveryLocationId: number,
    filterLocationType: LocationType,
    transportModeSea: TransportModeSea,
    isFirstStep: boolean,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    freightRateServiceProviderCode: string,
}

const getInternalSeaFormFields = ({
    forwarderId,
    transportModeRoad,
    pickupLocationId,
    deliveryLocationId,
    filterLocationType,
    transportModeSea,
    getAllowedValues,
    freightRateServiceProviderCode,
}: Args): FieldInCard<OrderStepFields>[] => {
    const trucksAndVans: FieldInCard<OrderStepFields>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'OrderStep.numberOfTrucks',
            disabled: true,
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'OrderStep.numberOfVans',
            disabled: true,
        },
    ]

    const seafreightContainerSize = [{
        name: 'seafreightContainerSize',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(SeafreightContainerSize),
        labelKey: 'OrderStep.seafreightContainerSize',
    }]

    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'forwarder',
                    componentName: 'LocationObjectSelector',
                    freightRateServiceProviderCode,
                    required: true,
                    labelKey: 'OrderStep.forwarder',
                },
                {
                    name: 'forwarderContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: true,
                    selectedLocation: forwarderId,
                    labelKey: 'OrderStep.forwarderContacts',
                },
                {
                    name: 'transportModeSea',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeSea),
                    labelKey: 'OrderStep.transportModeSea',
                    disabled: true,
                },
                ...transportModeSea === TransportModeSea.FCL ? seafreightContainerSize as [] : [],
                {
                    name: 'transportModeRoad',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'OrderStep.transportModeRoad',
                    disabled: true,
                },
                ...transportModeRoad === TransportModeRoad.FTL ? trucksAndVans as [] : [],
                {
                    name: 'isTemperatureControlled',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.isTemperatureControlled',
                    disabled: true,
                },
                {
                    name: 'defaultTruckingComment',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DefaultTruckingComment),
                    useDropdown: true,
                    labelKey: 'OrderStep.defaultTruckingComment',
                    disabled: true,
                },
                {
                    name: 'truckingComment',
                    componentName: 'LongText',
                    className: 'truckingComment',
                    labelKey: 'OrderStep.truckingComment',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'pickupLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.pickupLocation',
                    disabled: true,
                },
                {
                    name: 'pickupLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: true,
                    selectedLocation: pickupLocationId,
                    labelKey: 'OrderStep.pickupLocationContacts',
                },
                {
                    name: 'requestedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.requestedPickup',
                },
                {
                    name: 'estimatedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.estimatedPickup',
                },
                {
                    name: 'pickupComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.pickupComment',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'deliveryLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.deliveryLocation',
                    disabled: true,
                },
                {
                    name: 'deliveryLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: true,
                    selectedLocation: deliveryLocationId,
                    labelKey: 'OrderStep.deliveryLocationContacts',
                },
                {
                    name: 'requestedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.requestedDelivery',
                },
                {
                    name: 'estimatedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.estimatedDelivery',
                },
                {
                    name: 'deliveryComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.deliveryComment',
                },
            ],
        },
        {
            id: 'column4',
            fields: [
                {
                    name: 'seaFreightCarrier',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(SeaFreightCarrier),
                    labelKey: 'OrderStep.seaFreightCarrier',
                    disabled: true,
                },
                {
                    name: 'originSeaport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType,
                    labelKey: 'OrderStep.originSeaport',
                    disabled: true,
                },
                {
                    name: 'destinationSeaport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType,
                    labelKey: 'OrderStep.destinationSeaport',
                    disabled: true,
                },
                {
                    name: 'seaFreightComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.seaFreightComment',
                },
            ],
        },
    ]
}

export default getInternalSeaFormFields
