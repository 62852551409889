import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom'
import React, {
    useCallback,
} from 'react'
import useHasPermission from 'app/hooks/useHasPermission'
import PreBookings from './PreBookings'
import PreBookingBulkCreate from './PreBookingBulkCreate'
import PreBookingBulkEdit from './PreBookingBulkEdit'

export default () => {
    const {
        path,
    } = useRouteMatch()

    const createPreBookingPermission = useHasPermission(['pre_booking_create'])
    const updatePreBookingPermission = useHasPermission(['pre_booking_update'])

    const renderPreBookingBulkCreate = useCallback(() => {
        if (createPreBookingPermission) {
            return <PreBookingBulkCreate backUrl={path} />
        }
        return <Redirect to={path} />
    }, [
        createPreBookingPermission,
        path,
    ])

    const renderPreBookingBulkEdit = useCallback(() => {
        if (updatePreBookingPermission) {
            return <PreBookingBulkEdit backUrl={path} />
        }
        return <Redirect to={path} />
    }, [
        updatePreBookingPermission,
        path,
    ])

    return (
        <Switch>
            <Route
                path={`${path}/bulk-create`}
                render={renderPreBookingBulkCreate}
                exact
            />
            <Route
                path={`${path}/bulk-edit`}
                exact
                render={renderPreBookingBulkEdit}
            />
            <Route path={path}>
                <PreBookings />
            </Route>
        </Switch>
    )
}
