import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    PreBookingReasonForChange,
} from '../../PreBooking.types'

export type ReasonForChangeValue = {
    reasonForChange: PreBookingReasonForChange,
}

const getFields = ({
    getAllowedValues,
}: {
    getAllowedValues: (e:Record<string, string>) => Record<string, string>,
}): DTOCardFormField<keyof ReasonForChangeValue>[] => {
    return [{
        id: 'reason',
        className: 'reason',
        title: '',
        fields: [{
            id: 'column1',
            fields: [{
                name: 'reasonForChange',
                componentName: 'EnumSelector',
                labelKey: 'OrderByNumberView.modificationReason',
                allowedValues: getAllowedValues(PreBookingReasonForChange),
                useDropdown: true,
                required: true,
            }],
        }],
    }]
}

export default getFields
