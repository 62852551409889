import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import locationRequests from 'app/Apps/ContactManagement/Locations/Locations.request'

const useGetAirportsById = (airportIds: number[]) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Location[]>({
        key: RequestKeys.getLocationFilterRequest,
        params: {
            includeFilters: {
                id: airportIds,
            },
        },
        enabled: Boolean(airportIds.length),
        requestFunc: RequestFn.getEntity(locationRequests.filterRequest.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
    })

    return {
        airports: data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetAirportsById
