import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    LeaseType,
} from 'app/types/enums'
import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    FinalizeBookingStepType,
    Selection,
} from '../../createCustomerTransportForm.types'

const getFields = ({
    originScSelection,
    isDeliveryRoad,
    laneData,
    getAllowedValues,
    originScBackup,
    fwdPosBackup,
    disabled,
}: {
    getAllowedValues: (enumType: any) => Record<string, string>,
    originScSelection: Selection,
    isDeliveryRoad: boolean,
    laneData: Lane,
    originScBackup: {id: number},
    fwdPosBackup: {id: number},
    disabled: boolean,
}): DTOCardFormField<keyof FinalizeBookingStepType>[] => {
    return [{
        title: 'Booking Details',
        className: 'bookingDetails',
        id: 'bookingDetails',
        fields: [
            {
                id: 'bookingDetails-column1',
                fields: [
                    ...isDeliveryRoad ? [{
                        name: 'transportModeRoad',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(TransportModeRoad),
                        disabled: disabled || (laneData.transportModeRoad === TransportModeRoad.LTL
                            || laneData.transportModeRoad === TransportModeRoad.FTL),
                        labelKey: 'Order.transportModeRoad',
                    }] as NestedField<keyof FinalizeBookingStepType>[] : [],
                    ...isDeliveryRoad ? [{
                        name: 'transportModeForwarder',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(TransportModeRoad),
                        required: true,
                        labelKey: 'Order.transportModeForwarder',
                    }] as NestedField<keyof FinalizeBookingStepType>[] : [],
                    {
                        name: 'numberOfTrucks',
                        componentName: 'IntegerInput',
                        className: 'numberOfTrucks',
                        labelKey: 'CustomerTransportCreateDto.numberOfTrucks',
                    },
                    {
                        name: 'numberOfVans',
                        componentName: 'IntegerInput',
                        className: 'numberOfVans',
                        labelKey: 'CustomerTransportCreateDto.numberOfVans',
                    },
                    {
                        name: 'orderEntered',
                        componentName: 'DateTimeZonedSelect',
                        disabled: true,
                        labelKey: 'Order.orderEntered',
                    },
                    {
                        name: 'mawbNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.mawbNumber',
                    },
                ],
            },
            {
                id: 'bookingDetails-column2',
                fields: [
                    ...originScBackup ? [{
                        name: 'originScSelection',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Selection),
                        noSort: true,
                        labelKey: 'CustomerTransportCreateDto.originScSelection',
                    }] as NestedField<keyof FinalizeBookingStepType>[] : [],
                    ...originScSelection === Selection.BACKUP
                        ? [{
                            name: 'originScBackup',
                            componentName: 'LocationObjectSelector',
                            disabled: true,
                            labelKey: 'Lane.originSc',
                        }] as NestedField<keyof FinalizeBookingStepType>[]
                        : [{
                            name: 'originSc',
                            componentName: 'LocationObjectSelector',
                            disabled: true,
                            labelKey: 'Lane.originSc',
                        }] as NestedField<keyof FinalizeBookingStepType>[],
                    {
                        name: 'hawbNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'CustomerTransportCreateDto.hawbNumber',
                    },
                    ...isDeliveryRoad ? [{
                        name: 'estimatedPickup',
                        componentName: 'DateTimeZonedSelect',
                        labelKey: 'CustomerTransportCreateDto.estimatedPickup',
                    }] as NestedField<keyof FinalizeBookingStepType>[] : [],
                ],
            },
            {
                id: 'bookingDetails-column3',
                fields: [
                    ...fwdPosBackup ? [{
                        name: 'fwdPosSelection',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Selection),
                        noSort: true,
                        labelKey: 'CustomerTransportCreateDto.fwdPosSelection',
                    }] as NestedField<keyof FinalizeBookingStepType>[] : [],
                    {
                        name: 'commentForBillingOps',
                        componentName: 'LongText',
                        labelKey: 'OrderByNumberView.commentForBillingOps',
                    },
                    {
                        name: 'personInCharge',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.personInCharge',
                    },
                    ...laneData?.leaseType === LeaseType.AIRLINE_LEASE ? [{
                        name: 'freightForwarder',
                        componentName: 'CompanyObjectSelector',
                        required: true,
                        labelKey: 'OrderByNumberView.freightForwarder',
                    }] as NestedField<keyof FinalizeBookingStepType>[] : [],
                ],
            },

            {
                id: 'bookingDetails-column4',
                fields: [
                    {
                        name: 'minOrder',
                        value: laneData?.minOrder,
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.minOrder',
                    },
                    {
                        name: 'requiredCargoComment',
                        componentName: 'LongText',
                        labelKey: 'Order.requiredCargoComment',
                    },
                ],
            },
        ],
    }]
}

export default getFields
