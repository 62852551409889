import {
    SimulationTempResult,
} from 'app/Apps/TransportPlanner/Simulation/simulation.types'

const getExcursionTime = ({
    isDurationByHours,
    simulationResults,
}: {
    isDurationByHours: boolean,
    simulationResults: SimulationTempResult[],
}): number => {
    const time = simulationResults?.find(({
        isExcursion,
    }) => { return isExcursion })?.time

    if (!time) {
        return undefined
    }

    return isDurationByHours ? time * 60 : time * 5
}

export default getExcursionTime
