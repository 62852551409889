import {
    Library,
} from 'app/types/googlemaps.types'
import {
    Libraries,
} from '@react-google-maps/api'

export const DEFAULT_ZOOM_100_M = 16
const {
    REACT_APP_GOOGLE_MAP_KEY_SKYGATE,
} = process.env

export const GOOGLE_MAP_KEY = REACT_APP_GOOGLE_MAP_KEY_SKYGATE
export const DEFAULT_HEIGHT = '60vh'
export const DEFAULT_MAP_OPTIONS = {
    disableDefaultUI: true,
    scaleControl: true,
    mapTypeId: 'roadmap',
    draggable: true,
}
export const ZRH_LATITIDUE = 47.376
export const ZRH_LONGITUDE = 8.541

export const libraries: Libraries = [
    Library.CORE,
    Library.PLACES,
    Library.MAPS,
    Library.GEOCODING,
    Library.PLACES,
]
