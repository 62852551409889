import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    }
})

export default useStyles
