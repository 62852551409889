import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Button from 'app/shared-components/Button'

import useStyles from './AlertDialog.style'

type Props = {
    open: boolean,
    handleClose: (confirm: boolean) => void,
    dialogTitle?: string,
    dialogContent?: string,
    closeLabel?: string,
    children?: JSX.Element,
    classNames?: {
        paper: string,
        content: string,
        title: string,
    },
}

const defaultProps = {
    dialogTitle: '',
    dialogContent: '',
    closeLabel: 'Cancel',
    children: null,
    classNames: {},
}

const AlertDialog = ({
    open,
    handleClose,
    dialogTitle,
    dialogContent,
    closeLabel,
    children,
    classNames,
}: Props) => {
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onClick={(event) => { event.stopPropagation() }}
            classes={{
                paper: classNames.paper || classes.paper,
            }}
        >
            <DialogTitle classes={{
                root: cx(classes.title, classNames.title),
            }}
            >
                {dialogTitle.toUpperCase()}
            </DialogTitle>
            <DialogContent className={cx(classes.dialogContent, classNames.content)}>
                {dialogContent && (
                    <DialogContentText>
                        {dialogContent}
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    label={closeLabel}
                    secondary
                    onClick={() => { handleClose(false) }}
                />
            </DialogActions>
        </Dialog>
    )
}

AlertDialog.defaultProps = defaultProps

export default AlertDialog
