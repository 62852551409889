import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        containerRow: {
            display: 'flex',
        },
        excursion: {
            paddingLeft: theme.spacing(6),
        },
        excursionStatus: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        icon: {
            width: 20,
            height: 20,
            paddingRight: theme.spacing(0.75),
        },
    }
})

export default useStyles
