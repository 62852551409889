import {
    useEffect,
} from 'react'

import {
    useAppSelector,
    useAppDispatch,
} from 'app/store/hooks'
import {
    clearState,
    selectShowArchived,
} from 'app/shared-components/TableControls/store'

const useArchivedTableControl = (name: string) => {
    const dispatch = useAppDispatch()

    const showArchived = useAppSelector((state) => {
        return selectShowArchived(state, name)
    })

    useEffect(() => {
        return () => {
            dispatch(clearState(name))
        }
    }, [
        dispatch,
        name,
    ])

    return showArchived
}

export default useArchivedTableControl
