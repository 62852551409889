import isNumber from 'lodash/isNumber'

const filterTempChartSensorData = (sensorData, showDoorEvent) => {
    if (!showDoorEvent) {
        return sensorData
    }
    return sensorData.map(({
        t,
        d: temperatures,
    }) => {
        return {
            t,
            d: temperatures.map((temperature) => {
                return isNumber(temperature) ? temperature : null
            }),
        }
    })
}

export default filterTempChartSensorData
