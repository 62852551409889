const getAnnotation = ({
    showStepType,
    showLocation,
    location,
    type,
}: {
    showStepType: boolean,
    showLocation: boolean,
    location: string,
    type: string,
}): string => {
    if (showLocation && showStepType) {
        return location || type
    }

    if (showLocation) {
        return location
    }

    if (showStepType) {
        return type
    }

    return null
}

export default getAnnotation
