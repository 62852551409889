import React from 'react'

import useAddedItem from 'app/shared-components/SkyNetTable/hooks/useAddedItem'

import ServiceCenterForm from '../ServiceCenterForm'

const ServiceCenterCreate = () => {
    const onSuccess = useAddedItem()

    return (
        <ServiceCenterForm
            onSuccess={onSuccess}
            exists={false}
        />
    )
}

export default ServiceCenterCreate
