import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import ActivityRecords from './ActivityRecords'
import Engagement from './Engagement'

const RelationshipsManagement = () => {
    const {
        path,
    } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/activity-records`}>
                <ActivityRecords />
            </Route>
            <Route path={`${path}/engagements`}>
                <Engagement />
            </Route>
            <Redirect
                exact
                path={path}
                to={`${path}/activity-records`}
            />
            <Route path="/">
                <div>
                    Not found
                </div>
            </Route>
        </Switch>
    )
}

export default RelationshipsManagement
