import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: 5,
            paddingRight: 5,
            minHeight: '100%',
            color: theme.palette.secondary[600],
            fontSize: theme.typography.body1.fontSize,
            width: 60,
            justifyContent: 'space-between',
        },
    }
})

export default useStyles
