import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    LaneFees: {
        requestFn: () => {
            return {
                url: 'lanefees/feename/feechargeability/allowed',
            }
        },
    },
    AdditionalFees: {
        requestFn: () => {
            return {
                url: 'additionalfees/feename/feechargeability/allowed',
            }
        },
    },
    ProductType: {
        requestFn: () => {
            return {
                url: 'contractbasis/producttype/all',
            }
        },
    },
}

export default config
