import {
    createContext,
} from 'react'
import noop from 'lodash/noop'

import {
    AuthContextValues,
} from './Auth.types'
import {
    INITIAL_USER_INFO,
} from './const'

const AuthContext = createContext<AuthContextValues>({
    userInfo: INITIAL_USER_INFO,
    token: undefined,
    logout: noop,
})

export default AuthContext
