import {
    RequestConfig, SelectorConfig, UseRequestConfig,
} from 'app/types/request.types'

import {
    Location,
} from './Locations.types'

type LocationRequests = {
    domainName: string,
    get: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    archive: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    restore: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    locationDuplicateCheck: ({
        data,
    }: { data: { longitude: number, latitude: number } }) => RequestConfig,
    create: ({
        data,
    }: { data: Location }) => RequestConfig,
    update: ({
        data,
    }: { data: Location }) => RequestConfig,
    all: () => RequestConfig,
    multiByIds: ({
        ids,
    }:{
        ids: number[],
    }) => RequestConfig,
    filter: {
        isProspect: boolean[]
    },
    filterRequest: UseRequestConfig,
    selector: SelectorConfig<undefined>
}

const locationRequests: LocationRequests = {
    domainName: 'Location',
    get: ({
        id,
    }) => {
        return {
            url: `location/${id}`,
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `location/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `location/${id}/restore`,
            method: 'PATCH',
        }
    },
    locationDuplicateCheck: ({
        data,
    }) => {
        return {
            url: 'location/duplicate-check',
            method: 'POST',
            data,
        }
    },
    create: ({
        data,
    }) => {
        return {
            url: 'location',
            method: 'POST',
            data,
        }
    },
    update: ({
        data,
    }) => {
        return {
            url: `location/${data?.id}`,
            method: 'PUT',
            data,
        }
    },
    all: () => {
        return {
            url: 'location/filter-request/all',
            method: 'POST',
        }
    },
    multiByIds: ({
        ids,
    }) => {
        return {
            url: 'location/filter-request/all',
            method: 'POST',
            data: {
                includeFilters: {
                    id: ids,
                },
            },
        }
    },
    filter: {
        isProspect: [
            true,
            false,
        ],
    },
    filterRequest: {
        requestFn: (params) => {
            return {
                url: 'location/filter-request/all',
                method: 'POST',
                data: {
                    ...params,
                    rows: 50,
                },
            }
        },
    },
    selector: {
        url: 'location/filter-request/all',
    },
}

export default locationRequests
