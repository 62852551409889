import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tools: {
            display: 'flex',
            flexDirection: 'column',
            height: 700,
            '@media print': {
                display: 'none',
            },
        },
        contentWrapper: {
            '@media print': {
                height: '100%',
            },
        },
        containerBtn: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            '@media print': {
                display: 'none',
            },
        },
        btnControl: {
            paddingTop: 20,
        },
        chartWrapper: {
            '@media print': {
                maxWidth: '100%',
                height: '100%',
                flexBasis: '100%',
            },
        },
        chartContainer: {
            position: 'relative',
            width: '100%',
            height: '100%',
            '@media print': {
                background: 'none',
                border: 'none',
            },
        },
        errorWrapper: {
            position: 'absolute',
            display: 'flex',
            zIndex: 100,
            width: '90%',
            height: '80%',
            backgroundColor: theme.palette.common.white,
            alignItems: 'center',
            justifyContent: 'center',
        },
        temperatureFailure: {
            textAlign: 'center',
        },
        middle: {
            margin: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },
    }
})

export default useStyles
