import React, {
    useCallback,
} from 'react'
import useFilter from 'app/hooks/useFilter'
import {
    useHistory,
} from 'react-router-dom'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import PreBookingSpreadsheet from '../PreBookingSpreadsheet'
import usePreBookingBulkCreate from './hooks/usePreBookingBulkCreate'
import {
    convertToPrebookingEntity,
} from '../PreBookingSpreadsheet/PrebookingSpreadsheet.utils'
import {
    PreBookingEntitySpreadsheetData,
} from '../PreBookingSpreadsheet/PreBookingSpreadsheet.config'

const PreBookingBulkCreate = ({
    backUrl,
}: Readonly<{backUrl: string}>) => {
    const history = useHistory()
    const filterOptions = useFilter()

    const onSuccess = useCallback(() => {
        history.push(backUrl)
    }, [
        history,
        backUrl,
    ])

    const bulkCreate = usePreBookingBulkCreate()

    const onSave = useCallback((
        preBookings: PreBookingEntitySpreadsheetData[], options?: AsyncMutationOptions,
    ) => {
        bulkCreate(convertToPrebookingEntity(preBookings), {
            ...options,
            onSuccess: options?.onSuccess || onSuccess,
        })
    }, [
        onSuccess,
        bulkCreate,
    ])

    return (
        <PreBookingSpreadsheet
            filterOptions={filterOptions}
            onSave={onSave}
            onCancel={onSuccess}
            enableAddNewRows
        />
    )
}

export default PreBookingBulkCreate
