import {
    createSlice,
    createSelector,
} from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import type {
    RootState,
} from 'app/store'
import {
    Domain,
} from 'app/shared-components/SkyNetSpreadSheet'

type SpreadSheetConfigStore = Record<string, any>

const initialState = {} as SpreadSheetConfigStore

const SpreadSheetConfigsSlice = createSlice({
    name: 'spreadSheetConfigs',
    initialState,
    reducers: {
        setSpreadSheetConfig(state, {
            payload: config,
        }) {
            return {
                ...state,
                ...config,
            }
        },
        addToDomainConfigs(state, {
            payload: {
                name, domain, field, params,
            },
        }: {
            payload: {
                name: string,
                field: string,
                domain: Domain,
                params: Record<string, any[]>
            }
        }) {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    domains: {
                        ...state[name].domains,
                        [domain]: {
                            ...state[name]?.domains[domain],
                            [field]: {
                                ...state[name]?.domains[domain]?.[field] || {},
                                ...Object.entries(params).reduce((acc, [
                                    key,
                                    value,
                                ]) => {
                                    return {
                                        ...acc,
                                        [key]: uniqBy(
                                            [
                                                ...state[name]?.domains[domain]?.
                                                    [field]?.[key] || [],
                                                ...value,
                                            ], 'id',
                                        ),
                                    }
                                }, {}),
                            },
                        },
                    },

                },
            }
        },
    },
})

export const {
    setSpreadSheetConfig,
    addToDomainConfigs,
} = SpreadSheetConfigsSlice.actions

const selectSpreadSheetConfig = (state: RootState): SpreadSheetConfigStore => {
    return state.spreadSheetConfigs
}

const getName = (state, {
    name,
}: { name: string }) => {
    return name
}

// TODO-DK refactor it to pass options params
export const isSpreadSheetConfigSet = createSelector(
    [
        selectSpreadSheetConfig,
        getName,
    ],
    (state: SpreadSheetConfigStore, name) => {
        return Boolean(state?.[name])
    },
)

const getField = (state, {
    field,
}: { field: string }) => {
    return field
}

// TODO-DK refactor it to pass options params
export const selectSpreadSheetConfigByFieldName = createSelector(
    [
        selectSpreadSheetConfig,
        getName,
        getField,
    ],
    (state: SpreadSheetConfigStore, name, field) => {
        return state?.[name]?.configs[field].config
    },
)

const getDomain = (state, {
    domain,
}: { domain: Domain }) => {
    return domain
}

export const selectSpreadSheetConfigByDomain = createSelector(
    [
        selectSpreadSheetConfig,
        getName,
        getField,
        getDomain,
    ],
    (state: SpreadSheetConfigStore, name, field, domain) => {
        return state?.[name]?.domains[domain]?.[field]
    },
)

export default SpreadSheetConfigsSlice.reducer
