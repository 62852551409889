import React, {
    useCallback, useMemo,
} from 'react'

import useGetContractBasis from 'app/Apps/Pricing/ContractBasis/hooks/useGetContractBasis'
import request from 'app/Apps/Pricing/ContractBasis/ContractBasis.requests'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import Typeahead from 'app/shared-components/Typeahead'

type Props = {
    name?: string,
    value?: {
        id: number | string,
    },
    onChange: (value: {id: number}) => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
    noneOption?: boolean,
}

const defaultProps : Partial<Props> = {
    name: '',
    value: undefined,
    title: 'Contract Basis',
    disabled: false,
    className: '',
    required: false,
    noneOption: true,
}

const ContractBasisSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
    noneOption,
}: Props) => {
    const loadOptions = useDelayedLoadOptions({
        ...request.filterRequest({
            params: {
                rows: 50,
                start: 0,
            },
            includeFilters: {
                hasNDA: [false],
            },
        }),
        convertion: (contractBasis) => {
            return contractBasis.map(({
                contractBasisNumber,
                customerQuotationLocationName,
                accountCompanyCompanyName,
                currency,
                id,
            }) => {
                return {
                    value: id,
                    label: `${contractBasisNumber} | ${accountCompanyCompanyName} | ${currency} | ${customerQuotationLocationName}`,
                    id,
                }
            })
        },
    })

    const {
        data,
    } = useGetContractBasis(value?.id)

    const onChangeValue = useCallback((newValue) => {
        const convertedValue = newValue ? {
            id: newValue,
        } : undefined

        onChange(convertedValue)
    }, [onChange])

    const labelData = useMemo(() => {
        if (data) {
            return {
                label: `${data?.contractBasisNumber} | ${data.account?.company?.companyName} | ${data.currency} | ${data.customerQuotation?.locationName}`,
            }
        }

        return null
    }, [data])

    return (
        <Typeahead
            name={name}
            title={title}
            className={className}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="label"
            value={value?.id}
            disabled={disabled}
            isRequired={required}
            noneOption={noneOption}
        />
    )
}

ContractBasisSelector.defaultProps = defaultProps

export default ContractBasisSelector
