/* eslint-disable complexity */
function formatCurrencyValue(value) {
    let number = value

    if (typeof number === 'string') {
        number = parseFloat(value)
    }

    const currencyValue = new Intl.NumberFormat('de-CH').format((number.toFixed(2)))

    if (currencyValue.includes('.')) {
        const array = currencyValue.split('.')

        if (array[1].length === 0) {
            return `${currencyValue}.00`
        }

        if (array[1].length === 1) {
            return `${currencyValue}0`
        }

        if (array[1].length > 1) {
            return currencyValue
        }
    }

    return `${currencyValue}.00`
}

export default formatCurrencyValue
