import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import config from 'app/Apps/OrderManagement/Orders/orders.request'

import {
    UpdateLocationRequestType,
} from '../../OrderSteps.types'

const useUpdateLocation = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessUpdateLocation,
        onError,
    } = useHandleStatusRequest({
        id: 'ModifyOrderStep',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            orderNumber,
            data,
        }: {
            data: UpdateLocationRequestType,
            orderNumber: string,
        }) => {
            return config.UpdateLocationOrderStep.mutationFn({
                orderNumber,
                data,
                token,
            })
        },
        onSuccess: onSuccessUpdateLocation,
        onError,
    })
}

export default useUpdateLocation
