import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    AdditionalLaneStepType,
    BookingDetailsStepType, FinalizeBookingStepType,
    SelectLaneStepType,
} from '../createCustomerTransportForm.types'

export const SET_FIRST_STEP = 'SET_FIRST_STEP'
export const SET_SECOND_STEP = 'SET_SECOND_STEP'
export const RESET_SECOND_STEP = 'RESET_SECOND_STEP'
export const SET_THIRD_STEP = 'SET_THIRD_STEP'
export const RESET_THIRD_STEP = 'RESET_THIRD_STEP'
export const SET_FOURTH_STEP = 'SET_FOURTH_STEP'
export const RESET_FOURTH_STEP = 'RESET_FOURTH_STEP'
export const SET_FIFTH_STEP = 'SET_FIFTH_STEP'
export const RESET_FIFTH_STEP = 'RESET_FIFTH_STEP'

export function setFirstStep(newValue: Partial<SelectLaneStepType>) {
    return {
        type: SET_FIRST_STEP,
        newValue,
    }
}

export function setSecondStep(newValue: Partial<AdditionalLaneStepType>) {
    return {
        type: SET_SECOND_STEP,
        newValue,
    }
}

export function resetSecondStep() {
    return {
        type: RESET_SECOND_STEP,
    }
}

export function setThirdStep(newValue: Partial<BookingDetailsStepType>) {
    return {
        type: SET_THIRD_STEP,
        newValue,
    }
}

export function resetThirdStep(selectedLaneId: number) {
    return {
        type: RESET_THIRD_STEP,
        selectedLaneId,
    }
}

export function setFourthStep(newValue: Partial<Lane>) {
    return {
        type: SET_FOURTH_STEP,
        newValue,
    }
}

export function resetFourthStep() {
    return {
        type: RESET_FOURTH_STEP,
    }
}

export function setFifthStep(newValue: Partial<FinalizeBookingStepType>) {
    return {
        type: SET_FIFTH_STEP,
        newValue,
    }
}

export function resetFifthStep() {
    return {
        type: RESET_FIFTH_STEP,
    }
}
