import min from 'lodash/min'
import max from 'lodash/max'
import flatten from 'lodash/flatten'
import isNumber from 'lodash/isNumber'

const getDoorEventRangeValues = (sensorData, showDoorEvent, showTempRange, temperatureRange) => {
    if (!showDoorEvent) {
        return {
            maxValue: 0,
            minValue: 0,
        }
    }
    const flatenedData = flatten(sensorData.map((e) => {
        return e.d.filter(isNumber)
    }))
    const maxValue = max([
        max(flatenedData),
        showTempRange ? temperatureRange.high : 0,
        0,
    ])
    const minValue = min([
        min(flatenedData),
        showTempRange ? temperatureRange.low : 0,
        0,
    ])

    return {
        maxValue,
        minValue,
    }
}

export default getDoorEventRangeValues
