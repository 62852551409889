type ConnectedLoggersRequests = {
    domainName: string,
    all: string,
}

const connectedLoggersRequests: ConnectedLoggersRequests = {
    domainName: 'Logger',
    all: 'logger/filter-request/all',
}

export default connectedLoggersRequests
