import flow from 'lodash/flow'
import Axios from 'axios'

import addHostUrl from 'app/utils/api/addHostUrl'
import bearerToken from 'app/utils/api/bearerToken'
import DOMAIN_SUBDIRECTORY from 'app/utils/api/constants'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const simpleApi = (params) => {
    const modifiedQuery = flow([
        bearerToken,
        addHostUrl(`${apiUrl}/${DOMAIN_SUBDIRECTORY}`),
    ])(Axios)

    return modifiedQuery(params)
        .then(({
            data,
        }) => { return data })
}

export default simpleApi
