import omit from 'lodash/omit'
import {
    SimulationForm, SimulationStep,
} from '../simulation.types'

const getDataRequest = ({
    requestData, simulationSteps,
}: {requestData: SimulationForm, simulationSteps: SimulationStep[]}) => {
    return {
        ...requestData.analyseTemperature?.useCustomTempRange
            ? requestData.analyseTemperature
            : omit(requestData.analyseTemperature, [
                'customLowerTempRange',
                'customUpperTempRange',
            ]),
        ...requestData.routeSelection,
        routeViaAirports: requestData.routeSelection
            .routeViaAirports?.filter((item) => { return Boolean(item?.id) }),
        selectedContainer: requestData.selectedContainer && {
            productType: requestData.selectedContainer?.productType,
            amountCoolingGel: requestData.selectedContainer?.amountCoolingGel,
        },
        id: requestData.id,
        simulationNumber: requestData.simulationNumber,
        simulationSteps,
        createdBy: requestData.createdBy,
        createdOn: requestData.createdOn,
    }
}

export default getDataRequest
