import {
    FieldInCard,
} from 'app/types/form.types'

import {
    GeneralStatusChangeNote,
} from '../../../ContainerNotes.types'
import {
    AuditTrailConfig,
    ChangeConfig, StatusChangeConfig,
} from './notesConfigs'

type Fields = keyof GeneralStatusChangeNote

const fields: FieldInCard<Fields>[] = [
    ChangeConfig,
    StatusChangeConfig,
    AuditTrailConfig,
]

export default fields
