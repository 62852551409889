import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    DamageStatus,
    DamageType,
} from 'app/types/enums'

import {
    DamageByContainerType,
} from '../../containers.types'

const columns: RichTableColumns<keyof DamageByContainerType>[] = [
    {
        id: 'damageNumber',
        headerProps: {
            children: 'Damage Number',
        },
        mapCellProps: 'damageNumber',
    },
    {
        id: 'damageType',
        headerProps: {
            children: 'Reason',
        },
        mapCellProps: 'damageType',
        componentName: 'EnumValue',
        allowedValuesEnum: DamageType,
        filterField: 'damageType',
        filterType: FilterType.Array,
    },
    {
        id: 'repairingServiceCenter',
        headerProps: {
            children: 'Repairing Service Center',
        },
        mapCellProps: 'repairingServiceCenter',
        filterField: 'repairingServiceCenter',
        filterType: FilterType.Array,
    },
    {
        id: 'reportedOn',
        headerProps: {
            children: 'Reported On (UTC)',
        },
        mapCellProps: 'reportedOn',
    },
    {
        id: 'repairedOn',
        headerProps: {
            children: 'Repaired On (UTC)',
        },
        mapCellProps: 'repairedOn',
    },
    {
        id: 'damageStatus',
        headerProps: {
            children: 'Status',
        },
        mapCellProps: 'damageStatus',
        filterField: 'damageStatus',
        filterType: FilterType.Array,
        allowedValuesEnum: DamageStatus,
        componentName: 'EnumValue',
    },
]

export default columns
