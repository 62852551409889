import React, {
    useState,
} from 'react'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import getUniqueId from 'app/utils/getUniqueId'

import {
    StorageTableType,
} from '../../../serviceCenters.types'
import StorageForm from '../StorageForm'

const StorageCreate = ({
    closeCreateForm,
    onAddStorage,
}: {
    closeCreateForm: () => void,
    onAddStorage: (newStorage: StorageTableType) => void,
}) => {
    const [
        data,
        setData,
    ] = useState<StorageTableType>({
        processingTime: 3,
        id: getUniqueId(),
    } as StorageTableType)

    const onAdd = () => {
        setData({
            id: getUniqueId(),
            processingTime: 3,
        } as StorageTableType)
        onAddStorage(data)
    }

    const onCancel = () => {
        setData({
            id: getUniqueId(),
            processingTime: 3,
        } as StorageTableType)
        closeCreateForm()
    }

    return (
        <div>
            <StorageForm
                value={data}
                onChange={setData}
            />
            <ButtonContainer>
                <Button
                    label="Add"
                    onClick={onAdd}
                    data-testid="add-storage-button"
                />
                <Button
                    secondary
                    label="Cancel"
                    onClick={onCancel}
                    data-testid="cancel-storage-button"
                />
            </ButtonContainer>
        </div>
    )
}

export default StorageCreate
