import {
    ContainerNoteType,
} from 'app/types/enums'

export default (noteType, classes) => {
    const styles = {
        [ContainerNoteType.NOTE]: {
            gridWrapper: classes.gridWrapper,
            gridAreaTemplate: classes.gridTemplateNote,
            gridCardWrapper: classes.gridCardWrapper,
            contentWrapper: classes.contentWrapper,
        },
        [ContainerNoteType.GENERAL_STATUS_CHANGE]: {
            gridWrapper: classes.gridWrapper,
            gridAreaTemplate: classes.gridTemplateGeneralStatus,
            gridCardWrapper: classes.gridCardWrapper,
            contentWrapper: classes.contentWrapper,
        },
        [ContainerNoteType.CONTAINER_STATUS_CHANGE]: {
            gridWrapper: classes.gridWrapper,
            gridAreaTemplate: classes.gridTemplateContainerStatus,
            gridCardWrapper: classes.gridCardWrapper,
            contentWrapper: classes.contentWrapper,
        },
        [ContainerNoteType.LOGGER_EXCHANGE]: {
            gridWrapper: classes.gridWrapper,
            gridAreaTemplate: classes.gridTemplateLoggerExchange,
            contentWrapper: classes.contentWrapper,
        },
        [ContainerNoteType.LOGGER_REMOVAL]: {
            gridWrapper: classes.gridWrapper,
            gridAreaTemplate: classes.gridTemplateLoggerRemoval,
            contentWrapper: classes.contentWrapper,
        },
        [ContainerNoteType.CONTAINER_LOCATION_CHANGE]: {
            gridWrapper: classes.gridWrapper,
            gridAreaTemplate: classes.gridTemplateLocationChange,
            gridCardWrapper: classes.gridCardWrapper,
            contentWrapper: classes.contentWrapper,
        },
    }

    return styles[noteType]
}
