import React, {
    useMemo,
} from 'react'
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom'

import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import useAddCopiedItemWorkaround from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'

import AlLeasePricingForm from '../AlLeasePricingForm'
import useGetAlLeasePricing from '../hooks/useGetAlLeasePricing'
import AlLeasePricingRequests from '../AlLeasePricing.requests'

const defaultProps = {
    id: undefined,
}

const AlLeasingPricingUpdate = ({
    id,
}: { id?: number }) => {
    const history = useHistory()
    const {
        url,
    } = useRouteMatch()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAlLeasePricing(id)

    useAddCopiedItemWorkaround({
        data,
    })

    const customButtons = useMemo(() => {
        return (
            [<Button
                label="Copy"
                key="copy-button"
                onClick={() => {
                    history.push(`${url}/copy`)
                }}
            />]
        )
    }, [
        history,
        url,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <AlLeasePricingForm
                data={data}
                onSuccess={invalidate}
                onCancel={invalidate}
                requestParam={AlLeasePricingRequests.update({
                    id,
                })}
                customButtons={customButtons}
            />

        </StatusHandler>
    )
}

AlLeasingPricingUpdate.defaultProps = defaultProps

export default AlLeasingPricingUpdate
