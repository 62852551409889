import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        fieldTitle: {
            marginTop: theme.spacing(1),
            textAlign: 'left',
            fontSize: 10,
            color: theme.palette.secondary[400],
            opacity: 1,
        },
        textFieldText: {
            color: theme.palette.secondary[600],
        },
        requiredMark: {
            position: 'absolute',
            left: 7,
            width: 6,
            height: 27,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
        },
        isRequired: {
            backgroundColor: theme.palette.primary[30],
        },
    }
})

export default useStyles
