import getEnumLabel from 'app/utils/getEnumLabel'

import {
    Domain,
    DomainUIOnly,
    FieldConfig,
    OnChangeCellFunction,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
    TypeaheadParams,
} from 'app/shared-components/SkyNetSpreadSheet'

import {
    ProductType, Timezone,
} from 'app/types/enums'
import {
    getTimezones,
} from 'app/utils/date'
import {
    PreBookingContainerStatus,
    PreBookingEntity,
    PreBookingReasonForChange,
    PreBookingStatus,
} from '../PreBooking.types'

export type PreBookingEntitySpreadsheetData = PreBookingEntity & Record<ProductType, number> & {
    handoverPointLocationName?: string,
    collectionDropoffPointLocationName?: string,
    handoverTimezone: Timezone,
    pickupTimezone: Timezone,
}

const preBookingSpreadsheetConfig = ({
    editMode,
    getLabel,
    getAllowedValues,
    locationAirportParams,
    serviceCenterParams,
    laneParams,
    noOriginDestinationSelected,
    noChangesToRow,
    applyLaneFilter,
    onLaneChange,
    onAirportChange,
    onPrebookingHandoverAutopopulateChange,
    onPrebookingHandoverOverrideChange,
    onPrebookingPickupAutopopulateChange,
    handoverYearWeekDisabled,
}: {
    editMode: boolean,
    getLabel: (s: string) => string,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
    locationAirportParams: TypeaheadParams,
    serviceCenterParams: TypeaheadParams,
    laneParams: TypeaheadParams,
    noOriginDestinationSelected: (v: PreBookingEntitySpreadsheetData) => boolean,
    noChangesToRow: (v: PreBookingEntitySpreadsheetData) => boolean,
    applyLaneFilter: (v: PreBookingEntity) => Record<string, any>,
    onLaneChange: OnChangeCellFunction,
    onAirportChange: OnChangeCellFunction,
    onPrebookingHandoverAutopopulateChange: OnChangeCellFunction,
    onPrebookingHandoverOverrideChange: OnChangeCellFunction,
    onPrebookingPickupAutopopulateChange: OnChangeCellFunction,
    handoverYearWeekDisabled: (v: PreBookingEntitySpreadsheetData) => boolean,
}): SkyNetSpreadSheetConfigType<keyof PreBookingEntitySpreadsheetData> => {
    let fields: FieldConfig<keyof PreBookingEntitySpreadsheetData>[] = [
        {
            name: 'originAirport',
            title: getLabel('Prebooking.from'),
            required: true,
            ...locationAirportParams,
            domain: Domain.AIRPORT,
            width: 110,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                onChangeOverride: onAirportChange,
            },
        },
        {
            name: 'destinationAirport',
            title: getLabel('Prebooking.to'),
            required: true,
            ...locationAirportParams,
            domain: Domain.AIRPORT,
            width: 110,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                onChangeOverride: onAirportChange,
            },
        },
        {
            name: 'lane',
            title: getLabel('Prebooking.lane'),
            required: true,
            ...laneParams,
            domain: Domain.AIRPORT,
            width: 300,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                conditionalType: [[
                    noOriginDestinationSelected,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
                onChangeOverride: onLaneChange,
            },
            applyFilters: applyLaneFilter,
        },
        {
            name: 'handoverPointLocationName',
            title: getLabel('Prebooking.handoverPoint'),
            disabled: true,
            width: 300,
        },
        {
            name: 'collectionDropoffPointLocationName',
            title: getLabel('Prebooking.collectionDropoffPoint'),
            disabled: true,
            width: 300,
        },
        {
            name: 'requestedHandover',
            title: getLabel('Prebooking.requestedHandover'),
            width: 150,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.DATETIME,
                onChangeAutopopulate: onPrebookingHandoverAutopopulateChange,
                onChangeOverride: onPrebookingHandoverOverrideChange,
            },
            format: 'DD.MM.YYYY HH:mm',
        },
        {
            name: 'handoverTimezone',
            title: getLabel('Prebooking.handoverTimezone'),
            width: 150,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: Object.fromEntries(getTimezones(getAllowedValues(Timezone))),
            noneOption: false,
        },
        {
            name: 'requestedHandoverYear',
            title: getLabel('Prebooking.requestedHandoverYear'),
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
            },
            width: 100,
            disabled: handoverYearWeekDisabled,
        },
        {
            name: 'requestedHandoverWeek',
            title: getLabel('Prebooking.requestedHandoverWeek'),
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
            },
            width: 100,
            disabled: handoverYearWeekDisabled,
        },
        {
            name: 'project',
            title: getLabel('Prebooking.project'),
            width: 150,
        },
        {
            name: 'customerReference',
            title: getLabel('Prebooking.customerReference'),
            width: 150,
        },
        {
            name: 'requestedPickup',
            title: getLabel('Prebooking.requestedPickup'),
            width: 150,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.DATETIME,
                onChangeAutopopulate: onPrebookingPickupAutopopulateChange,
            },
            format: 'DD.MM.YYYY HH:mm',
        },
        {
            name: 'pickupTimezone',
            title: getLabel('Prebooking.pickupTimezone'),
            width: 150,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: Object.fromEntries(getTimezones(getAllowedValues(Timezone))),
            noneOption: false,
        },
        {
            name: 'mawbNumber',
            title: getLabel('Prebooking.mawbNumber'),
            width: 150,
        },
        {
            name: ProductType.VAL_1500XCOL,
            title: getEnumLabel(ProductType.VAL_1500XCOL),
            width: 75,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
            },
        },
        {
            name: ProductType.VAL_1500C,
            title: getEnumLabel(ProductType.VAL_1500C),
            width: 75,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
            },
        },
        {
            name: ProductType.VAL_2500C,
            title: getEnumLabel(ProductType.VAL_2500C),
            width: 75,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
            },
        },
        {
            name: 'preBookingStatus',
            title: getLabel('Prebooking.prebookingStatus'),
            width: 150,
            required: true,
            allowedValues: getAllowedValues(PreBookingStatus),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
        },
        {
            name: 'containersStatus',
            title: getLabel('Prebooking.containerStatus'),
            width: 150,
            required: true,
            allowedValues: getAllowedValues(PreBookingContainerStatus),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
        },
        {
            name: 'hasBooking',
            title: getLabel('Prebooking.hasBooking'),
            width: 75,
            required: true,
            renderYesNoSelect: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
        },
        {
            name: 'originServiceCenter',
            title: getLabel('Prebooking.originServiceCenter'),
            width: 300,
            domain: DomainUIOnly.LOCATION,
            ...serviceCenterParams,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
            },
        },
        {
            name: 'isForecasted',
            title: getLabel('Prebooking.isForecasted'),
            width: 75,
            required: true,
            defaultValue: true,
            renderYesNoSelect: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
        },
    ]

    if (editMode) {
        fields = [
            ...fields,
            {
                name: 'modificationReason',
                title: getLabel('Prebooking.modificationReasons'),
                allowedValues: getAllowedValues(PreBookingReasonForChange),
                required: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.SELECT,
                    conditionalType: [[
                        noChangesToRow,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],
                },
                width: 120,
            },
            {
                name: 'id',
                width: 1,
                hidden: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.ID,
                },
            },
        ]
    }

    return {
        id: 'PreBookings',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption: !editMode,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: false,
        rowsLimit: 25,
        emptyRows: editMode ? 0 : 10,
        initialFocusLocation: {
            columnId: 'account',
            rowId: 0,
        },
        fields,
    }
}

export default preBookingSpreadsheetConfig
