import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: 6,
        },
        fxRate: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        fxRateType: {
            gridRowEnd: 'span 2',
        },
        exchangeRate: {
            gridRowEnd: 'span 2',
        },
    }
})

export default useStyles
