import React, {
    useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import Typography from '@mui/material/Typography'
import useLabel from 'app/hooks/useLabel'
import SharedContextProvider from 'app/shared-components/SharedContext'
import UploadFile from 'app/shared-components/UploadFile'
import StatusHandler from 'app/shared-components/StatusHandler'

import useStyles from './Documents.style'
import columns from './documents.columns'
import useGetLoggerDocuments from './hooks/useGetLoggerDocuments'
import useAddLoggerDocument from './hooks/useAddLoggerDocument'
import requestConfig from './documents.request'
import useGetLoggerByLoggerNumber from '../hooks/useGetLoggerByLoggerNumber'

const Documents = ({
    id: loggerNumber,
}: {id: string}) => {
    const {
        classes,
    } = useStyles()

    const canLoggerUpdate = useHasPermission(['logger_update'])
    const canLoggerDelete = useHasPermission(['logger_delete'])

    const l = useLabel()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLoggerByLoggerNumber(loggerNumber)

    const fields = useMemo(() => {
        return columns({
            l,
            canLoggerDelete,
        })
    }, [
        l,
        canLoggerDelete,
    ])

    const {
        onParamsChange,
        load,
        refresh,
    } = useGetLoggerDocuments(data?.id)

    const {
        mutate,
    } = useAddLoggerDocument(refresh)

    const onSuccessUpload = (idDocument: number) => {
        mutate({
            loggerId: data?.id,
            data: {
                id: idDocument,
            },
        })
    }

    return (
        <Card contentClass={classes.cardContent}>
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <>
                    <div className={classes.header}>
                        <Typography variant="h3">
                            Documents
                        </Typography>
                        <div className={classes.iconWrapper}>
                            {canLoggerUpdate && (<UploadFile onSuccessUpload={onSuccessUpload} />)}
                        </div>
                    </div>
                    <SharedContextProvider
                        value={{
                            loggerId: data?.id,
                            updateLoggerDocuments: refresh,
                        }}
                    >
                        <RichTable
                            configName={requestConfig.domainName}
                            onParamsChange={onParamsChange}
                            name={requestConfig.domainName}
                            load={load}
                            columns={fields}
                            uniqField="id"
                            className={classes.tableContent}
                            classNames={{
                                contentWrapper: classes.tableContentWrapper,
                            }}
                        />
                    </SharedContextProvider>
                </>
            </StatusHandler>
        </Card>
    )
}

export default Documents
