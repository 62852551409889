import {
    ExtendedProfile, UserInfo,
} from './Auth.types'

const getUserInfo = (extendedProfile: ExtendedProfile): UserInfo => {
    const {
        assignedRoles = [],
        attributes,
        email,
        firstName,
        lastName,
        username,
        roles,
    } = extendedProfile

    return {
        assignedRoles,
        email,
        firstName,
        attributes,
        roles,
        lastName,
        username,
    } as UserInfo
}

export default getUserInfo
