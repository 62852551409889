import useDelayedLoadOptions, {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import DTOSpec from 'app/services/dtoSpec'

export default (): {
    loadOptions: (options: DelayedLoadOptions) => Promise<any>
} => {
    const loadOptions = useDelayedLoadOptions({
        url: DTOSpec.Company.Selector,
    })

    return {
        loadOptions,
    }
}
