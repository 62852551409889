import {
    useMemo,
} from 'react'
import customTemplates from '../customTemplates'
import {
    SkyNetSpreadSheetCellType, SkyNetSpreadSheetConfigType,
} from '../SkyNetSpreadSheet.types'

// created separate templates for cells
// key of returned object need to be used as type of cell
export default (config: SkyNetSpreadSheetConfigType<any>) => {
    return useMemo(() => {
        const {
            PercentageCellTemplate,
            SelectCellTemplate,
            NoValueCellTemplate,
            IdCellTemplate,
            TypeaheadCellTemplate,
            DateTimeCellTemplate,
            CustomCheckboxCellTemplate,
            MultipleSelectCellTemplate,
            HeaderFilterableTemplate,
            MultipleTypeaheadCellTemplate,
        } = customTemplates

        return {
            [SkyNetSpreadSheetCellType.SELECT]: new SelectCellTemplate({
                configId: config.id,
            }),
            [SkyNetSpreadSheetCellType.NO_VALUE]: new NoValueCellTemplate(),
            [SkyNetSpreadSheetCellType.PERCENTAGE]: new PercentageCellTemplate(),
            [SkyNetSpreadSheetCellType.TYPEAHEAD]: new TypeaheadCellTemplate({
                configId: config.id,
            }),
            [SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD]: new MultipleTypeaheadCellTemplate({
                configId: config.id,
            }),
            [SkyNetSpreadSheetCellType.ID]: new IdCellTemplate(),
            [SkyNetSpreadSheetCellType.DATETIME]: new DateTimeCellTemplate({
                configId: config.id,
            }),
            [SkyNetSpreadSheetCellType.CHECKBOX]: new CustomCheckboxCellTemplate(),
            [SkyNetSpreadSheetCellType.MULTIPLE_SELECT]: new MultipleSelectCellTemplate({
                configId: config.id,
            }),
            [SkyNetSpreadSheetCellType.HEADER_FILTERABLE]: new HeaderFilterableTemplate(),
        }
    }, [config.id])
}
