import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'unset',
            whiteSpace: 'break-spaces',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        cardContent: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }
})

export default useStyles
