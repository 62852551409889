import React, {
    useMemo,
    useCallback,
} from 'react'
import {
    Link,
    useHistory,
} from 'react-router-dom'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'

import Card from 'app/shared-components/Card'

import useStyles from '../CompanyOverview.styles'
import config from './BillingAddresses.config'
import {
    Company,
} from '../../Companies.types'

const DOMAIN_NAME = 'BillingCompany'

const BillingAddresses = ({
    data,
}: { data: Company }) => {
    const history = useHistory()
    const {
        classes,
    } = useStyles()

    const filter = useMemo(() => {
        return {
            companyId: [data?.id],
        }
    }, [data?.id])

    const onRowClick = useCallback(({
        id: billingCompanyId,
    }) => {
        history.push(`/apps/contactmanagement/billing-companies/all/edit/${billingCompanyId}/data-update`)
    }, [history])

    const createLocationLink = `/apps/contactmanagement/billing-companies/all/create?companyId=${data?.id}`

    return (
        <div
            className={classes.tableContainer}
        >
            <Card
                fullHeight
                className={classes.cardRoot}
                contentClass={classes.cardContent}
            >
                <div className={classes.header}>
                    <Typography
                        variant="h3"
                    >
                        Billing Addresses
                    </Typography>
                    <div className={classes.icon}>
                        <Link
                            className={classes.link}
                            to={createLocationLink}
                        >
                            <AddIcon
                                data-testid="open-create-locationType-BILLING_ADDRESS"
                                className={classes.icon}
                            />
                        </Link>
                    </div>
                </div>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    simple
                    filter={filter}
                    tableConfig={config}
                    onRowClick={onRowClick}
                />
            </Card>
        </div>

    )
}

export default BillingAddresses
