import React, {
    useCallback,
} from 'react'
import Grid from '@mui/material/Unstable_Grid2'

import AdvancedList, {
    ListColumn,
} from 'app/shared-components/AdvancedList/AdvancedList'
import useEnumValues from 'app/hooks/useEnumValues'
import Select from 'app/shared-components/Select/SelectContainer'
import InputSingleline from 'app/shared-components/InputSingleline'

import {
    AllowedProductTypes,
    ProductMetricsType,
} from '../../serviceCenters.types'
import useStyles from './ProductMetrics.style'

const columns: ListColumn[] = [
    {
        colSpan: 6,
        label: 'Type',
    },
    {
        colSpan: true,
        label: 'Min Stored Containers',
        align: 'right',
    },
    {
        colSpan: true,
        label: 'Processing Ratio (%)',
        align: 'right',
    },
]

const defaultProps = {
    disabled: false,
    required: false,
    exists: false,
    value: undefined,
}

const ProductMetrics = ({
    value,
    onChange,
    disabled,
    required,
    exists,
}: {
    value?: ProductMetricsType[],
    onChange: (val: ProductMetricsType) => void,
    disabled?: boolean,
    required?: boolean,
    exists?: boolean,
}) => {
    const {
        classes,
    } = useStyles()
    const allowedValues = useEnumValues()(AllowedProductTypes)

    const onItemCellChange = useCallback(({
        item, newValue, index, handleChange,
    }) => {
        const newItem = {
            ...item,
            ...newValue,
        }

        handleChange(newItem, index)
    }, [])

    const validateItemForAdd = useCallback((item: ProductMetricsType): boolean => {
        return item?.minimumStoredContainers > 0 && item?.processingRatio > 0
            && Boolean(item?.productType)
    }, [])

    const renderItem = useCallback(({
        index,
        item,
        handleChange,
    }) => {
        return (
            <>
                <Grid
                    key={`row-container-${index}`}
                    xs={columns[0].colSpan}
                >
                    <Select
                        name="containerType"
                        allowedValues={allowedValues}
                        value={item?.productType}
                        onChange={(newValue) => {
                            return onItemCellChange({
                                newValue: {
                                    productType: newValue,
                                },
                                index,
                                item,
                                handleChange,
                            })
                        }}
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
                <Grid
                    key={`row-minStoredContainers-${index}`}
                    xs={columns[1].colSpan}
                >
                    <InputSingleline
                        name="minStoredContainers"
                        type="number"
                        min={1}
                        centerAligned
                        onChange={(newValue) => {
                            return onItemCellChange({
                                index,
                                item,
                                handleChange,
                                newValue: {
                                    minimumStoredContainers: Number(newValue),
                                },
                            })
                        }}
                        className={classes.input}
                        value={item?.minimumStoredContainers}
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
                <Grid
                    key={`row-processingRatio-${index}`}
                    xs={columns[2].colSpan}
                >
                    <InputSingleline
                        name="processingRatio"
                        type="number"
                        min={1}
                        centerAligned
                        onChange={(newValue) => {
                            return onItemCellChange({
                                index,
                                item,
                                handleChange,
                                newValue: {
                                    processingRatio: Number(newValue),
                                },
                            })
                        }}
                        className={classes.input}
                        value={item?.processingRatio}
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
            </>
        )
    }, [
        allowedValues,
        required,
        disabled,
        classes.input,
        onItemCellChange,
    ])

    if (exists) {
        return (
            <>
                { value && (
                    <AdvancedList<ProductMetricsType>
                        onChange={onChange}
                        disabled={disabled}
                        value={value}
                        renderItem={renderItem}
                        addButtonLabel="new container"
                        columns={columns}
                        validateForAdd={validateItemForAdd}
                    />
                )}
            </>
        )
    }

    return (
        <AdvancedList<ProductMetricsType>
            onChange={onChange}
            disabled={disabled}
            value={value}
            renderItem={renderItem}
            addButtonLabel="new container"
            columns={columns}
            validateForAdd={validateItemForAdd}
        />
    )
}

ProductMetrics.defaultProps = defaultProps

export default ProductMetrics
