import React, {
    useEffect, useState,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import DateTimeZonedSelect from 'app/shared-components/DateTimeZonedSelect'
import replaceTimezone from 'app/utils/date/replaceTimezone'

import request from '../OrderStepsForm.request'

type Props = {
    name: string,
    value?: string,
    title?: string,
    onChange: (newVal: string) => void,
    required?: boolean,
    disabled?: boolean,
    locationId?: number,
    isDisableWeekends?: boolean,
}

const defaultProps = {
    value: null,
    title: undefined,
    required: undefined,
    disabled: false,
    isDisableWeekends: false,
    locationId: undefined,
}

const DateTimeZonedFromLocation = ({
    name,
    value,
    title,
    required,
    onChange,
    disabled,
    locationId,
    isDisableWeekends,
}: Props) => {
    const [
        derivedLocationId,
        setLocationId,
    ] = useState<number>(locationId)

    useEffect(() => {
        if (derivedLocationId === locationId) {
            return
        }

        setLocationId(locationId)
        onChange(null)
    }, [
        derivedLocationId,
        locationId,
        onChange,
    ])

    const {
        data,
    } = useRequest<string>({
        key: RequestKeys.getLocationById,
        params: {
            id: locationId,
        },
        requestFunc: RequestFn.getEntity(request.GetLocationById.requestFn, (promise) => {
            return promise.then(({
                data: resp,
            }) => {
                return replaceTimezone(resp?.timeZone)
            })
        }),
        enabled: Boolean(!value && locationId),
    })

    return (
        <DateTimeZonedSelect
            name={name}
            value={value}
            onChange={onChange}
            title={title}
            required={required}
            disabled={disabled}
            defaultTimeZone={data}
            isDisableWeekends={isDisableWeekends}
        />
    )
}

DateTimeZonedFromLocation.defaultProps = defaultProps

export default DateTimeZonedFromLocation
