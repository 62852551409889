/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import get from 'lodash/get'

import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import useStyles from './LocationChangeSelector.styles'
import LocationSelector from '../LocationSelector'

type Props = {
    data: {
        old: Location,
        actual: Location,
    },
    inputs: ('old' | 'actual')[],
}

const LocationChangeSelector = (props: Props) => {
    const {
        data,
        inputs,
    } = props

    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.root}>
            <div>
                {inputs.includes('old') && (
                    <LocationSelector
                        {...props}
                        value={get(data, 'old.id')}
                        classNames={{
                            addresses: classes.addresses,
                        }}
                        onChange={() => { return false }}
                        disabled
                    />
                )}

                {inputs.includes('actual') && (
                    <LocationSelector
                        {...props}
                        value={get(data, 'actual.id')}
                        classNames={{
                            addresses: classes.addresses,
                        }}
                        onChange={() => { return false }}
                        disabled
                    />
                )}
            </div>
        </div>
    )
}

export default LocationChangeSelector
