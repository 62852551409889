import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    SharedPositioningType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

type SharePositioning = {
    sharedPositioning: SharedPositioningType,
}

export type SharePositioningFields = keyof SharePositioning

const getFields = (orderNumber: string): DTOCardFormField<SharePositioningFields>[] => {
    return [{
        title: 'Share Positioning',
        className: 'sharePositioning',
        fields: [{
            name: 'sharedPositioning',
            componentName: 'SharePositioningSelect',
            orderNumber,
            labelKey: 'Order.sharesPositioning',
        }],
    }]
}

export default getFields
