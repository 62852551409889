import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    OpportunityTableType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    OpportunityStatus,
} from 'app/types/enums'

const getColumns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof OpportunityTableType>[] => {
    return [
        {
            id: 'opportunityNumber',
            headerProps: {
                children: l('Opportunity.opportunityNumber'),
            },
            mapCellProps: 'opportunityNumber',
        },
        {
            id: 'reprOriginAirport',
            headerProps: {
                children: l('Opportunity.reprOriginAirport'),
            },
            mapCellProps: 'reprOriginAirport',
        },
        {
            id: 'reprDestinationAirport',
            headerProps: {
                children: l('Opportunity.reprDestinationAirport'),
            },
            mapCellProps: 'reprDestinationAirport',
        },
        {
            id: 'opportunityStatus',
            headerProps: {
                children: l('Opportunity.opportunityStatus'),
            },
            mapCellProps: 'opportunityStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: OpportunityStatus,
        },
        {
            id: 'changedOn',
            headerProps: {
                children: l('KamDashboard.lastUpdated'),
            },
            mapCellProps: 'changedOn',
        },
    ]
}

export default getColumns
