import get from 'lodash/get'

import isTypeTransport from 'app/Apps/OrderManagement/Orders/utils/isTypeTransport'
import {
    StepTypeDetailed,
} from 'app/types/enums'

import mapOrderSteps from './mapOrderSteps'
import {
    StepData,
} from '../../createManuallyForm.types'
import stepsRules from './stepsRules'

const DELIVERY_LOCATION = 'deliveryLocation'
const LOCATION = 'location'
const DELIVERY_LOCATION_EVENT = 'deliveryLocationEvent'

export const getListSteps = (orderSteps): StepData[] => {
    return mapOrderSteps.filter((step: StepData) => {
        const selectedSteps = orderSteps?.map(({
            stepTypeDetailed,
        }) => { return stepTypeDetailed })

        const path = [
            ...selectedSteps,
            step.stepTypeDetailed,
        ].join('.')

        return get(stepsRules, path)
    })
}

export const getLocation = ({
    isFirstStep,
    orderSteps,
    location,
}) => {
    if (isFirstStep) {
        return location
    }

    if (orderSteps?.[orderSteps.length - 1].stepTypeDetailed === StepTypeDetailed.ROAD_TO_EVENT) {
        return orderSteps?.[orderSteps.length - 1][DELIVERY_LOCATION_EVENT]
    }

    if (orderSteps?.[orderSteps.length - 1].stepTypeDetailed === StepTypeDetailed.EVENT_EVENT) {
        return orderSteps?.[orderSteps.length - 2][DELIVERY_LOCATION_EVENT]
    }

    const delivery = isTypeTransport(orderSteps?.[orderSteps.length - 1]?.stepType)
        ? DELIVERY_LOCATION
        : LOCATION

    return orderSteps?.[orderSteps.length - 1][delivery]
}
