import MultiEnumSelector from 'app/shared-components/MultiEnumSelector'
import EnumChipSelector from 'app/shared-components/EnumChipSelector'
import EnumSelector from 'app/shared-components/EnumSelector'
import Select from 'app/shared-components/Select'

export const EnumComponents = {
    EnumSelector,
    MultiEnumSelector,
    EnumChipSelector,
    Select,
}

export type EnumComponentConfig = {
    componentName: keyof typeof EnumComponents,
    allowedValues: Record<string, string>,
    useDropdown?: boolean,
    noSort?: boolean,
    showInColumns?: boolean,
    showOutOfRangeOption?: boolean,
}
