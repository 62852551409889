import React from 'react'
import SingleObjectSelectorConverter from 'app/shared-components/SingleObjectSelectorConverter'

import ContactSelector from './ContactSelector'
import {
    NotificationIconProps,
} from '../NotificationIcon/notificationIcon.types'

type Props = {
    name?: string,
    value?: {
        id: number | string,
    },
    onChange: ()=>void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
    includeFilters?: Record<string, string[] | number[]>
    filters?: Record<string, string[] | number[]>
    notificationIconProps?: NotificationIconProps,
    showOptionLabelWithEmail?: boolean,
}

const defaultProps : Partial<Props> = {
    name: '',
    value: undefined,
    title: 'Contacts',
    disabled: false,
    className: '',
    required: false,
    includeFilters: undefined,
    filters: undefined,
    notificationIconProps: undefined,
    showOptionLabelWithEmail: false,
}

const ContactObjectSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
    includeFilters,
    filters,
    notificationIconProps,
    showOptionLabelWithEmail,
}: Props) => {
    return (
        <SingleObjectSelectorConverter
            selector={ContactSelector}
            value={value}
            onChange={onChange}
            title={title}
            name={name}
            disabled={disabled}
            className={className}
            required={required}
            includeFilters={includeFilters}
            filters={filters}
            notificationIconProps={notificationIconProps}
            showOptionLabelWithEmail={showOptionLabelWithEmail}
        />
    )
}

ContactObjectSelector.defaultProps = defaultProps

export default ContactObjectSelector
