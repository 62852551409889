import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import useGetLane from 'app/Apps/Pricing/LaneGroups/Lanes/hooks/useGetLane'

import useGetPreBooking from '../components/hooks/useGetPreBooking'
import PreBookingLaneDetails
    from '../components/PreBookingLaneDetails/PreBookingLaneDetails'
import useGetPreBookingLaneDetailsFields
    from '../components/PreBookingLaneDetails/hooks/useGetPreBookingLaneDetailsFields'
import PreBookingComment from '../components/PreBookingComment'

const PreBookingOverview = ({
    id,
}: {
    id: number
}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetPreBooking(id)

    const {
        data: laneData,
    } = useGetLane(data?.lane?.id)

    const {
        fields,
    } = useGetPreBookingLaneDetailsFields({
        disabled: true,
        laneSelected: false,
        lane: {
            id: data?.lane?.id,
            name: String(laneData?.laneNumber),
        },
        requestedHandover: data?.requestedHandover,
        showGeneralInfo: true,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <PreBookingLaneDetails
                    disabled
                    formValues={data}
                    fields={fields}
                    showLaneInfo
                />
                <PreBookingComment id={id} />
            </>
        </StatusHandler>
    )
}

export default PreBookingOverview
