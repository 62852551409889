import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    AvailableLanes,
} from './AvailableLanes.types'

const columns: RichTableColumns<Partial<keyof AvailableLanes>>[] = [
    {
        id: 'laneNumber',
        headerProps: {
            children: 'Lane',
        },
        mapCellProps: 'laneNumber',
    },
    {
        id: 'customerExecutionLocationName',
        headerProps: {
            children: 'Customer (Execution)',
        },
        mapCellProps: 'customerExecutionLocationName',
    },
    {
        id: 'pricingAccountCompanyName',
        headerProps: {
            children: 'Pricing Account',
        },
        mapCellProps: 'pricingAccountCompanyName',
    },
    {
        id: 'consigneeLocationName',
        headerProps: {
            children: 'Consignee',
        },
        mapCellProps: 'consigneeLocationName',
    },
    {
        id: 'laneGroupLeaseType',
        headerProps: {
            children: 'Lease Type',
        },
        mapCellProps: 'laneGroupLeaseType',
        componentName: 'EnumValue',
        allowedValuesEnum: OrderSubType,
    },
    {
        id: 'laneGroupHandoverPointLocationName',
        headerProps: {
            children: 'Handover Point',
        },
        mapCellProps: 'laneGroupHandoverPointLocationName',
    },
    {
        id: 'laneGroupCollectionDropoffPointLocationName',
        headerProps: {
            children: 'Collection/Drop-off Point',
        },
        mapCellProps: 'laneGroupCollectionDropoffPointLocationName',
    },
    {
        id: 'originAirportIataCode',
        headerProps: {
            children: 'From',
        },
        mapCellProps: 'originAirportIataCode',
    },
    {
        id: 'destAirportIataCode',
        headerProps: {
            children: 'To',
        },
        mapCellProps: 'destAirportIataCode',
    },
    {
        id: 'laneGroupTempRange',
        headerProps: {
            children: 'Temperature Range',
        },
        mapCellProps: 'laneGroupTempRange',
    },
]

export default columns
