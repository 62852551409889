import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetEngagement from '../hooks/useGetEngagement'
import EngagementForm from '../EngagementForm'

const EngagementOverview = ({
    id,
}: { id: number }) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetEngagement(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <EngagementForm
                data={data}
                disabled
            />
        </StatusHandler>
    )
}

export default EngagementOverview
