import React from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    Postponement,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import useLoadDtoForSkyNetTable from 'app/shared-components/SkyNetTable/hooks/useLoadDtoForSkyNetTable'

import columns from './OrderPostponements.columns'
import useStyles from './OrderPostponements.style'

const OrderPostponements = ({
    orderNumber,
}: {
    orderNumber: string,
}) => {
    const {
        classes,
    } = useStyles()

    const load: (params: any) => Promise<{
        items: Postponement[],
        matches: number,
    }> = useLoadDtoForSkyNetTable(
        orderManagementRequest.OrderPostponements.request(orderNumber),
    )

    return (
        <RichTable
            configName="OrderPostponementsTable"
            name={orderManagementRequest.OrderPostponements.name}
            columns={columns}
            load={load}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            rowStyle={{
                crossLine: 'isRejected',
            }}
        />
    )
}

export default OrderPostponements
