import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    TemperatureRange,
    RegionFirstLevel,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    RegionalGroup,
} from '../../RegionalGroups/regionalGroups.types'

const columns: RichTableColumns<keyof RegionalGroup>[] = [
    {
        id: 'regionalGroupNumber',
        headerProps: {
            children: 'Regional Group',
        },
        mapCellProps: 'regionalGroupNumber',
    },
    {
        id: 'originRegion',
        headerProps: {
            children: 'Origin Region',
        },
        mapCellProps: 'originRegion',
        filterField: 'originRegion',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        allowedValuesEnum: RegionFirstLevel,
    },
    {
        id: 'destinationRegion',
        headerProps: {
            children: 'Destination Region',
        },
        mapCellProps: 'destinationRegion',
        filterField: 'destinationRegion',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        allowedValuesEnum: RegionFirstLevel,
    },
    {
        id: 'tempRange',
        headerProps: {
            children: 'Temperature Range',
        },
        mapCellProps: 'tempRange',
        filterField: 'tempRange',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        allowedValuesEnum: TemperatureRange,
    },
    {
        id: 'createdOn',
        headerProps: {
            children: 'Created On',
        },
        mapCellProps: 'createdOn',
        filterField: 'createdOn',
        filterType: FilterType.Array,
    },
]

export default columns
