import React, {
    useMemo,
} from 'react'
import moment from 'moment'

import useLabel from 'app/hooks/useLabel'
import InputSingleline from 'app/shared-components/InputSingleline'
import TemperatureInfo from 'app/shared-components/TemperatureInfo'

import useStyles from './TemperatureInfoLogger.style'

const bordersSensorAge = {
    new: 150,
    old: 180,
}

type Props = {
    logger?: {
        loggerNumber: string,
    },
    lastMeasured?: number,
    loggerExchanged?: number,
    temperature?: number,
}

const defaultProps: Partial<Props> = {
    logger: {
        loggerNumber: '',
    },
    lastMeasured: undefined,
    loggerExchanged: undefined,
    temperature: undefined,
}

const TemperatureInfoLogger = ({
    logger: {
        loggerNumber,
    },
    loggerExchanged,
    lastMeasured,
    temperature,
}: Props) => {
    const l = useLabel()

    const sensorAge = loggerExchanged && moment().diff(moment(loggerExchanged, 'X'), 'days')

    const loggerExchangedValue = useMemo(() => {
        return loggerExchanged ? (
            `Exchanged ${moment(loggerExchanged, 'X').fromNow()}`
        ) : (
            'No data'
        )
    }, [loggerExchanged])

    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.wrapper}>
            <TemperatureInfo
                lastMeasured={lastMeasured}
                temperature={temperature}
            />
            <div className={classes.sensorInfo}>
                <div className={classes.sensorInfoItem}>
                    <InputSingleline
                        title={l('Logger.loggerNumber')}
                        classNames={{
                            label: classes.label,
                        }}
                        value={loggerNumber}
                        name="loggerNumber"
                        disabled
                    />
                </div>
                <div className={classes.sensorInfoItem}>
                    <InputSingleline
                        title="Logger Age"
                        classNames={
                            {
                                text: {
                                    [classes.new]: sensorAge <= bordersSensorAge.new,
                                    [classes.medium]: sensorAge > bordersSensorAge.new
                                        && sensorAge < bordersSensorAge.old,
                                    [classes.old]: sensorAge >= bordersSensorAge.old,
                                },
                                label: classes.label,
                            }
                        }
                        value={loggerExchangedValue}
                        name="loggerNumber"
                        disabled
                    />
                </div>
            </div>
        </div>
    )
}

TemperatureInfoLogger.defaultProps = defaultProps

export default TemperatureInfoLogger
