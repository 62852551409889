import React from 'react'
import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'

import AlLogisticPricingUpdate from '../AlLogisticPricingUpdate'
import AlLogisticPricingCopy from '../AlLogisticPricingCopy'

type Props = {
    id: number,
}

const AlLogisticPricingOverview = ({
    id,
}: Props) => {
    const {
        url,
    } = useRouteMatch()

    return (
        <Switch>
            <Route
                exact
                path={url}
            >
                <AlLogisticPricingUpdate id={id} />
            </Route>
            <Route
                path={`${url}/:copy`}
            >
                <AlLogisticPricingCopy id={id} />
            </Route>
        </Switch>
    )
}

export default AlLogisticPricingOverview
