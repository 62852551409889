import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'

import Loading from 'app/shared-components/Loading'

import useStyles from './PendingMessage.styles'

const propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
}

const defaultProps = {
    title: '',
}

const PendingForm = ({
    title, id,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <Alert
            key={id}
            severity="info"
            variant="filled"
            icon={(
                <div className={classes.iconWrapper}>
                    <Loading
                        className={classes.loadingWrapper}
                        size={20}
                    />
                </div>
            )}
        >
            <div className={classes.text}>{title || 'We’re saving your updates. This might take a bit.'}</div>
        </Alert>
    )
}

PendingForm.propTypes = propTypes
PendingForm.defaultProps = defaultProps

export default PendingForm
