import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            marginTop: theme.spacing(1.5),
        },
        root: {
            padding: theme.spacing(1, 0),
        },
        'root &:nth-child(5)': {
            padding: theme.spacing(2, 0, 1, 0),
        },
        gridCardWrapper: {
            display: 'inline !important',
        },
        tempRange: {
            padding: theme.spacing(1, 0),
            width: '50%',
            display: 'inline-block',
            boxSizing: 'border-box',
            paddingRight: theme.spacing(1),
        },
    }
})

export default useStyles
