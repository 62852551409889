import {
    makeStyles,
} from 'app/tss'

export default makeStyles<{top: number}>()((theme, {
    top,
}) => {
    return {
        searchNotification: {
            top,
            position: 'absolute',
            border: `1px solid ${theme.palette.secondary[200]}`,
            background: theme.palette.common.white,
            left: 0,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.typography.body1.color,
            fontSize: theme.typography.body1.fontSize,
            padding: theme.spacing(1),
        },
    }
})
