/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import PrintIcon from '@mui/icons-material/Print'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './PrintLabel.style'

const propTypes = {
    onClick: PropTypes.func.isRequired,
}

const PrintLabel = (props) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    const {
        onClick,
    } = props

    return (
        <Link
            {...props}
            className={classes.btn}
            component="button"
            color="secondary"
            onClick={onClick}
        >
            <PrintIcon />
        </Link>
    )
}

PrintLabel.propTypes = propTypes

export default PrintLabel
