import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from '../Logger.request'
import {
    LoggerResponse,
} from '../Loggers.types'

const useGetLoggerByLoggerNumber = (
    loggerNumber: string,
) => {
    const queryClient = useQueryClient()

    const invalidate = () => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getLoggerByLoggerNumber],
        })
    }

    const {
        data = {},
        isDataReady,
        isError,
        isFetching,
        error,
    } = useRequest<LoggerResponse>({
        key: RequestKeys.getLoggerByLoggerNumber,
        params: {
            loggerNumber,
        },
        requestFunc: RequestFn.getEntity(config.getByLoggerNumber),
        enabled: Boolean(loggerNumber),
        keepPreviousData: false,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetLoggerByLoggerNumber
