import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Order,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import config from 'app/Apps/OrderManagement/Orders/orders.request'

const useGetOrderByNumber = (orderNumber: string) => {
    const queryClient = useQueryClient()

    const invalidate = () => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getOrderByNumber],
        })
    }

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Order>({
        key: RequestKeys.getOrderByNumber,
        params: {
            id: orderNumber,
        },
        requestFunc: RequestFn.getEntity(config.OrderByNumber.requestFn),
        enabled: Boolean(orderNumber),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetOrderByNumber
