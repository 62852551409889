import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import request from './Users.request'

import tableConfig from './Users.config'
import UserCreate from './UserCreate'
import UserUpdate from './UserUpdate'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]
const updateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data',
    Component: UserUpdate,
}

const Users = () => {
    const createPermission = useHasPermission(['user_create'])
    const readPermission = useHasPermission(['user_read'])

    const tabs: SidebarTab[] = useTabsByPermission([{
        tab: updateTab,
        permission: readPermission,
    }])

    return (
        <SkyNetTable
            name={tableConfig.name}
            moduleName={request.domainName}
            createForm={createPermission
                ? UserCreate
                : undefined}
            tabs={tabs}
            tableControls={tableControls}
            tableConfig={tableConfig}
            customUrl={request.all.url}
            showSearch
        />
    )
}

export default Users
