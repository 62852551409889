import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: UseRequestConfig = {
    requestFn: (params) => {
        return {
            url: 'fxrate/filter-request/selector',
            method: 'POST',
            data: {
                includeFilters: {
                    ...params,
                },
                rows: 50,
                start: 0,
            },
        }
    },
}

export default config
