import React from 'react'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import StatusHandler from 'app/shared-components/StatusHandler'

import useGetLaneById from '../../hooks/useGetLaneById'
import LaneImplementation from './LaneImplementation'

const LaneImplementationContainer = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetLaneById(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <LaneImplementation
                onSuccess={invalidate}
                name={orderManagementRequest.Lane.domainName}
                data={data}
            />
        </StatusHandler>
    )
}

export default LaneImplementationContainer
