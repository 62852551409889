import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import ContractBasisDetails from '../ContractBasisDetails'

import {
    ContractBasis,
} from '../../Pricing.types'

const ContractBasisCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((newContractBasis: ContractBasis) => {
        addTableElement(newContractBasis)
        selectDomainObject(newContractBasis?.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <ContractBasisDetails
            onSuccess={onSuccess}
        />
    )
}

export default ContractBasisCreate
