import React, {
    useMemo, useCallback,
} from 'react'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    TableResponse,
} from 'app/types/request.types'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Button from 'app/shared-components/Button'
import Card from 'app/shared-components/Card'
import useRequestTable from 'app/hooks/useRequestTable'
import Tooltip from 'app/shared-components/Tooltip'
import useLabel from 'app/hooks/useLabel'

import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    useSharedContext,
} from 'app/shared-components/SharedContext'

import NoOption from './NoOption'
import {
    SelectedMethod,
} from '../../../CreateInternalTransportForm'
import {
    AutomaticallyFormType,
    BestOptionType,
} from '../../createAutomaticallyForm.types'
import requestConfig from '../../createAutomaticallyForm.request'
import getColumns from './columns/bestOptions.columns'
import getAirColumns from './columns/bestOptionsAir.columns'
import getRoadColumns from './columns/bestOptionsRoad.columns'
import getSeaColumns from './columns/bestOptionsSea.columns'
import useStyles from './BestOptions.style'

const MAP_COLUMNS = {
    AIR: getAirColumns,
    ROAD: getRoadColumns,
    SEA: getSeaColumns,
}

const BestOptions = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
}: {
    value: AutomaticallyFormType,
    onChange: (newVal: AutomaticallyFormType) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const {
        setMethod,
    } = useSharedContext()

    const {
        isError,
        error,
        isFetching,
        isDataReady,
        loadData,
        data,
    } = useRequestTable<TableResponse<BestOptionType>>({
        config: requestConfig.GetOptions.requestFn,
        params: {},
        defaultValue: {
            data: {
                ...value,
            },
        },
        key: RequestKeys.getOpportunitiesAll,
    })

    const onSelectRow = (selectedVal: [string]) => {
        const [selectedOption] = selectedVal

        onChange({
            ...value,
            bestOption: (data?.data as BestOptionType[]).find((option) => {
                return option.rateIdCombined === selectedOption
            }),
        })
    }

    const columns = useMemo(() => {
        return value.transportModes?.length > 1
            ? getColumns(l)
            : MAP_COLUMNS[value.transportModes[0]](l)
    }, [
        l,
        value.transportModes,
    ])

    const onClickBack = () => {
        setActiveStep(index - 1)
        onChange({
            ...value,
            bestOption: null,
        })
    }

    const onClickEdit = () => {
        setActiveStep(index)
        onChange({
            ...value,
            orderSteps: null,
        })
    }

    const onNoOption = useCallback(() => {
        setMethod(SelectedMethod.MANUALLY)
    }, [setMethod])

    return (
        <StatusHandler
            isError={isError}
            error={error}
            isFetching={isFetching}
            isSuccess={Boolean(isDataReady && data)}
        >
            <DataContentWrapper
                isEmpty={!data?.meta?.matchedresults}
                emptyDataReplacer={<NoOption onClick={onNoOption} />}
            >
                <>
                    <Card
                        title="Best Options"
                        titleClass={classes.title}
                    >
                        <RichTable
                            configName="BestOptionsTable"
                            name="BestOptions"
                            load={loadData}
                            columns={columns}
                            uniqField="rateIdCombined"
                            className={classes.tableContent}
                            classNames={{
                                contentWrapper: classes.tableContentWrapper,
                            }}
                            onSelectRow={onSelectRow}
                            selectedRows={value.bestOption
                                ? [value.bestOption?.rateIdCombined] : undefined}
                            radioSelect
                            disabled={activeStep > index}
                        />
                    </Card>
                    <div className={classes.actions}>
                        {activeStep === index && (
                            <>
                                <Tooltip
                                    title="Please fill in all mandatory fields to continue"
                                    disabled={Boolean(value.bestOption)}
                                >
                                    <div>
                                        <Button
                                            label="Continue"
                                            onClick={() => { setActiveStep(index + 1) }}
                                            className={classes.button}
                                            disabled={!value.bestOption}
                                            data-testid="btn-continue"
                                        />
                                    </div>
                                </Tooltip>
                                <Button
                                    label="Back"
                                    secondary
                                    onClick={onClickBack}
                                    className={classes.button}
                                    data-testid="btn-back"
                                />
                            </>
                        )}
                        {activeStep > index && (
                            <Button
                                label="Edit"
                                onClick={onClickEdit}
                                className={classes.button}
                                data-testid="btn-edit"
                            />
                        )}
                    </div>
                </>
            </DataContentWrapper>
        </StatusHandler>
    )
}

export default BestOptions
