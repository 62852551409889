import React from 'react'
import {
    Icon,
} from '@mui/material'
import PropTypes from 'prop-types'

const propTypes = {
    locationName: PropTypes.string,
    path: PropTypes.string,
}

const defaultProps = {
    locationName: '',
    path: '',
}

const ChipLabel = ({
    locationName, path,
}) => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
        }}
        >
            {locationName}
            <Icon>
                <img
                    src={path}
                    alt="Chip Icon"
                />
            </Icon>
        </div>
    )
}

ChipLabel.propTypes = propTypes
ChipLabel.defaultProps = defaultProps

export default ChipLabel
