import React from 'react'
import PropTypes from 'prop-types'

import Range from 'app/shared-components/Range'
import FilterPopover from 'app/shared-components/FilterPopover'

import useStyles from './RangeFilter.styles'

const propTypes = {
    value: PropTypes.shape({
        range: PropTypes.arrayOf(PropTypes.number),
    }),
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    commitChanges: PropTypes.func.isRequired,
}

const defaultProps = {
    value: undefined,
}

const RangeFilter = (props) => {
    const {
        value,
        min,
        max,
        active,
        type,
        onChange,
        commitChanges,
    } = props

    const {
        classes,
    } = useStyles()

    return (
        <FilterPopover
            active={active}
            onClose={commitChanges}
        >
            <div
                className={classes.root}
            >
                <Range
                    value={value.range}
                    type={type}
                    min={min}
                    max={max}
                    onChange={onChange}
                />
            </div>
        </FilterPopover>
    )
}

RangeFilter.propTypes = propTypes
RangeFilter.defaultProps = defaultProps

export default RangeFilter
