import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'

import {
    valueConverterToArray,
    valueConverterToObject,
} from './utils/converters'
import getValueLabelByEmail from './utils/getValueLabelByEmail'
import useCommonMultiContactSelectorLogic, {
    Value,
} from './hooks/useCommonMultiContactSelectorLogic'
import useDoGetContactsByLocation from './hooks/useDoGetContactsByLocation'

type Props = {
    value?: Value[],
    onChange: (newValue: Value[]) => void,
    title?: string,
    className?: string,
    disabled?: boolean,
    required?: boolean,
    selectedLocation?: number | string,
    existedForm?: boolean,
}

const defaultProps: Partial<Props> = {
    value: [],
    title: 'Contacts',
    disabled: false,
    required: false,
    className: '',
    selectedLocation: undefined,
    existedForm: false,
}

const MultipleContactByLocationObjectSelector = ({
    value,
    onChange,
    title,
    disabled,
    required,
    className,
    selectedLocation,
    existedForm,
}: Props) => {
    const initialLocationId = useRef(selectedLocation)
    const {
        contacts,
        setContacts,
        loadOptions,
        contactsByIds,
    } = useCommonMultiContactSelectorLogic({
        valueConverter: valueConverterToArray,
        listIds: value,
    })

    const onContactsByLocationLoadedSuccess = useCallback(({
        items,
    }) => {
        setContacts(items)
        initialLocationId.current = selectedLocation
        onChange(items ? valueConverterToObject(items) : [])
    }, [
        setContacts,
        selectedLocation,
        onChange,
    ])

    const {
        mutate: getContactsByLocation,
    } = useDoGetContactsByLocation({
        onSuccess: onContactsByLocationLoadedSuccess,
    })

    useEffect(() => {
        if (contactsByIds && JSON.stringify(contactsByIds) !== JSON.stringify(contacts)) {
            setContacts(contactsByIds)
        }
    }, [
        contacts,
        contactsByIds,
        setContacts,
    ])

    useEffect(() => {
        if ((Boolean(selectedLocation) && !existedForm)
            || (selectedLocation !== initialLocationId.current)) {
            getContactsByLocation({
                selectedLocation,
            })
        }
    }, [
        selectedLocation,
        getContactsByLocation,
        existedForm,
    ])

    const onChangeValue = useCallback((newValue: Value[], actionParams): void => {
        let updatedValues

        if (actionParams?.action === 'remove-value') {
            updatedValues = contacts.filter(({
                id,
            }) => { return id !== actionParams?.removedValue?.id })
        } else {
            updatedValues = newValue
        }

        onChange(updatedValues ? valueConverterToObject(updatedValues) : [])
    }, [
        contacts,
        onChange,
    ])

    return (
        <div className={className}>
            <ChipSelect
                title={title}
                value={contacts}
                onChange={onChangeValue}
                loadOptions={loadOptions}
                getValueLabel={getValueLabelByEmail}
                showTitleOptions
                disabled={disabled}
                required={required}
            />
        </div>
    )
}

MultipleContactByLocationObjectSelector.defaultProps = defaultProps

export default MultipleContactByLocationObjectSelector
