import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1, 0, 2, 3),
            color: theme.palette.secondary[600],
        },
        title: {
            paddingRight: theme.spacing(1.5),
        },
        info: {
            paddingRight: theme.spacing(3),
            display: 'flex',
        },
        chip: {
            '&&': {
                margin: theme.spacing(0.5, 0.5, 0.5, 0),
                backgroundColor: theme.palette.secondary[200],
                color: theme.palette.common.black,
            },
        },
        icon: {
            paddingLeft: theme.spacing(1),
        },
    }
})

export default useStyles
