import React from 'react'
import get from 'lodash/get'

import {
    AdditionalFeesStatus,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import useStyles from './AdditionalFeesStatusEnriched.style'

type Props = {
    rowProps: {
        additionalFeesStatus: string,
        isMostRecentPricing: boolean,
    }
}

const AdditionalFeesStatusEnriched = (props: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        rowProps: {
            additionalFeesStatus,
            isMostRecentPricing,
        },
    } = props

    const additionalFeesStatuses = useEnumValues()(AdditionalFeesStatus)

    const iconPath = `/assets/images/icons/${additionalFeesStatus === AdditionalFeesStatus.VALID ? 'checked' : 'unchecked'}.svg`

    return (
        <div>
            <div className={classes.laneIndepPricingStatus}>
                {get(additionalFeesStatuses, additionalFeesStatus, additionalFeesStatus)}
                {isMostRecentPricing
                    && (
                        <img
                            className={classes.icon}
                            src={iconPath}
                            alt="None"
                        />
                    )}
            </div>
        </div>
    )
}

export default AdditionalFeesStatusEnriched
