import React from 'react'

import InputSingleline from 'app/shared-components/InputSingleline'
import useEnumValues from 'app/hooks/useEnumValues'

import useStyles from './AttitudeToSkyCell.style'
import {
    AttitudeToSkyCell as AttitudeToSkyCellEnum,
} from '../../engagement.types'
import MAP_COLOR from './attitudeToSkyCellColorMap.utils'

const AttitudeToSkyCell = ({
    value,
    title,
    disabled,
}: {
    value: AttitudeToSkyCellEnum,
    title: string,
    disabled: boolean
}) => {
    const {
        classes,
    } = useStyles()

    const allowedValues = useEnumValues()(AttitudeToSkyCellEnum)

    return (
        <InputSingleline
            value={allowedValues[value]}
            title={title}
            classNames={{
                text: classes[MAP_COLOR[value]],
            }}
            disabled={disabled}
        />
    )
}

export default AttitudeToSkyCell
