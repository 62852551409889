import React from 'react'
import noop from 'lodash/noop'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import StatusHandler from 'app/shared-components/StatusHandler'
import currency from 'app/utils/currency'
import {
    FxRateValues,
} from 'app/Apps/Finance/FxRates/FxRate.types'

import config from './FxRateSelect.request'
import FxRateSelect from './FxRateSelect'

type Props = {
    value?: number,
    ccy?: string,
    disabled?: boolean,
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}

const defaultProps: Partial<Props> = {
    value: null,
    ccy: null,
    disabled: false,
    onChange: noop,
}

const FxRateSelectContainer = ({
    ccy,
    disabled,
    value,
    onChange,
}: Props) => {
    const isDisabled = !ccy || ccy === currency.default || disabled

    const {
        data: fxRates,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<FxRateValues[]>({
        key: RequestKeys.getFxRates,
        params: {
            ccyFrom: [currency.default],
            ccyTo: [ccy],
        },
        requestFunc: RequestFn.getEntity(config.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FxRateSelect
                data={fxRates}
                onChange={onChange}
                value={value}
                disabled={isDisabled}
            />
        </StatusHandler>
    )
}

FxRateSelectContainer.defaultProps = defaultProps

export default FxRateSelectContainer
