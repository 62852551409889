const request = {
    domainName: 'RegionalGroup',
    RegionalGroups: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'regionalgroup/filter-request/all',
                method: 'POST',
                ...params,
            }
        },
    },
    GetRegionalGroupById: ({
        id,
    }) => {
        return {
            url: `regionalgroup/${id}`,
        }
    },
    UpdateRegionalGroup: ({
        regionalGroupId,
    }) => {
        return {
            url: `regionalgroup/${regionalGroupId}`,
            method: 'PUT',
        }
    },
    CreateRegionalGroup: () => {
        return {
            url: 'regionalgroup',
            method: 'POST',
        }
    },
    FilterCountRequest: () => {
        return {
            customUrl: 'regionalgroup/filter-request/all',
        }
    },
    getLinkedOpportunity: ({
        id,
    }) => {
        return {
            url: `regionalgroup/${id}/linked-opportunity`,
            method: 'GET',
        }
    },
}

export default request
