import React, {
    useMemo,
} from 'react'

import useStyles from './DownloadDocument.styles'

type Props = {
    value: string,
}

const DownloadDocument = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const filename = useMemo(() => {
        return value.split('/').pop()
    }, [value])

    return (
        <a
            href={value}
            rel="noopener noreferrer"
            download={filename}
            className={classes.link}
        >
            <img
                src="/assets/images/download_button.svg"
                alt="None"
            />
        </a>
    )
}

export default DownloadDocument
