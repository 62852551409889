import React, {
    useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import insertAfter from 'app/utils/insertAfter'
import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import DTOSpec from 'app/services/dtoSpec'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import Overview from './Overview'
import ChangeData from './ChangeData'
import Create from './Create'
import tableConfig from './FxRates.config'

const overviewTab: SidebarTab = {
    url: 'overview',
    label: 'Overview',
    Component: Overview,
}
const baseTabs: SidebarTab[] = [overviewTab]

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const FxRates = () => {
    const fxrateCreate = useHasPermission(['fxrate_create'])
    const fxrateUpdate = useHasPermission(['fxrate_update'])

    const tabs: SidebarTab[] = useMemo(() => {
        if (fxrateCreate || fxrateUpdate) {
            return insertAfter(
                baseTabs,
                overviewTab,
                {
                    url: 'data-update',
                    label: 'Data Update',
                    Component: ChangeData,
                },
            )
        }
        return baseTabs
    }, [
        fxrateCreate,
        fxrateUpdate,
    ])

    return (
        <SkyNetTable
            createForm={fxrateCreate ? Create : undefined}
            name={DTOSpec.FxRate.Update.refresh}
            tabs={tabs}
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default FxRates
