import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    ModificationFields,
} from './ContactChangeSummary.types'

const columns: RichTableColumns<ModificationFields>[] = [
    {
        id: 'fieldName',
        headerProps: {
            children: 'Change',
        },
        mapCellProps: 'fieldName',
        componentName: 'ModifiedField',
        labelPrefixKeys: ['Contact'],
    },
    {
        id: 'previousValue',
        headerProps: {
            children: 'Previous Value',
        },
        mapCellProps: 'previousValue',
        componentName: 'ModifiedValue',
    },
    {
        id: 'updatedValue',
        headerProps: {
            children: 'Updated Value',
        },
        mapCellProps: 'updatedValue',
        componentName: 'ModifiedValue',
    },
    {
        id: 'modifiedBy',
        headerProps: {
            children: 'Modified By',
        },
        mapCellProps: 'modifiedBy',
        componentName: 'ModifiedValue',
    },
    {
        id: 'modifiedOn',
        headerProps: {
            children: 'Modified On',
        },
        mapCellProps: 'modifiedOn',
        componentName: 'ModifiedValue',
    },
]

export default columns
