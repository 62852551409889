import {
    LaneFeesStatus,
} from 'app/types/enums'

const rejectedReasonValues = {
    PRICING_POSITIONING: 'SkyCell rate is too high. Please share price indication. How do we compare to our competition? If shared with you, who did the customer choose for this lane?',
    PRICING_COLLECTION: 'SkyCell rate is too high. Please share price indication. How do we compare to our competition? If shared with you, who did the customer chose for this lane?',
    PRICING_BASE_LEASE: 'Are the numbers of days in our base lease too high? Too low? What did the customer request? Why did SkyCell not accommodate this request? What was the reason(s)?',
    PRICING_PROCESS: 'Slow response times, quotation was sent too late. If shared with you, how long was the response time from the competition? Miscommunication between the parties: internal or external?',
    PRICING_OTHER: 'Please let us know the reason why we lost this business if not already covered by the other pricing reasons.',
    OPERATIONAL_CONTAINER_AVAILABILITY: 'What type of container was not available for this request? Did we offer alternatives? Why was it rejected?',
    OPERATIONAL_SERVICE_CENTER: 'Which service center? What was the reason we did not have this container availability in this location?  Is it pre-conditioning? Capacity? Choice of containers?',
    OPERATIONAL_OTHER: 'Please let us know the reason why we lost this business if not already covered by the other operational reasons.',
    EXTERNAL_COMPETITION: 'If shared with you, who is the competitor who won this lane? What was the offer? What are their values? Why did the customer choose them over SkyCell?',
    EXTERNAL_PARTNERS: 'Does the customer have a preferred partner that SkyCell could not collaborate with? Who is the partner? What was the reason?',
    EXTERNAL_OTHER: 'Please let us know the reason why we lost this business if not already covered by the other external reasons.',
    OTHER_REASON: 'If we lost this business opportunity due to any other factor internal or external, please let us know the reasons why, e.g. Covid19, decision maker, embargo countries, remote location, lack of partners...',
}

const getRejectedReasonCommentHint = (reason, status) => {
    const value = status === LaneFeesStatus.REJECTED ? rejectedReasonValues[reason] : ''

    return value
}

export default getRejectedReasonCommentHint
