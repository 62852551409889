import {
    useEffect,
    useState,
} from 'react'

import {
    useLocation,
} from 'react-router-dom'

const usePrefilledValues = () => {
    const {
        state,
    } = useLocation()
    const [
        filterState,
        setFilterState,
    ] = useState(state?.filter)

    useEffect(() => {
        return () => { return setFilterState(undefined) }
    }, [])
    return filterState
}

export default usePrefilledValues
