import {
    useEffect,
    useState,
    useMemo,
} from 'react'

const useWindowSize = () => {
    const [
        windowSize,
        setWindowSize,
        once,
    ] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        once: false,
    })

    const handleResize = useMemo(() => {
        if (once) {
            return
        }
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            once: true,
        })
    }, [
        setWindowSize,
        once,
    ])

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => { return window.removeEventListener('resize', handleResize) }
    }, [handleResize])

    return windowSize
}

export default useWindowSize
