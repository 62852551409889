import {
    TemperatureRange,
} from 'app/types/enums'

const radioOptions = [
    {
        label: TemperatureRange.DF,
        value: TemperatureRange.DF,
    },
    {
        label: TemperatureRange.F,
        value: TemperatureRange.F,
    },
    {
        label: TemperatureRange.C,
        value: TemperatureRange.C,
    },
    {
        label: TemperatureRange.CRT,
        value: TemperatureRange.CRT,
    },
]

export default radioOptions
