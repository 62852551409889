import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, _, classes) => {
    return {
        dateTimePicker: {
            '& .MuiInputBase-inputAdornedEnd': {
                textTransform: 'lowercase',
            },
        },
        calendar: {
            cursor: 'pointer',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            width: '100%',
            whiteSpace: 'nowrap',
            position: 'relative',
            minWidth: 120,
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: -8,
                width: 6,
                height: 26,
                backgroundColor: theme.palette.primary.main,
                display: 'none',
                borderRadius: 3,
            },
        },
        disabled: {
            paddingTop: 7,
        },
        isRequired: {
            [`& .${classes.requiredMark}`]: {
                display: 'block',
            },
            backgroundColor: theme.palette.primary[30],
        },
    }
})

export default useStyles
