import {
    makeStyles,
} from 'app/tss'

import {
    TypeIcon,
} from './notificationIcon.types'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
        },
        icon: {
            paddingTop: theme.spacing(1),
            height: `${theme.spacing(2)} !important`,
        },
        [`${TypeIcon.INFO}`]: {
            '&&': {
                color: theme.palette.primary.main,
            },
        },
        [`${TypeIcon.WARNING}`]: {
            '&&': {
                color: theme.palette.common.yellow,
            },
        },
    }
})

export default useStyles
