import {
    TemperatureRange,
    LaneStatus,
    LeaseType,
} from 'app/types/enums'
import {
    FieldInCard,
} from 'app/types/form.types'

import {
    CollServiceType,
    DeliveryServiceType,
    Lane,
} from '../lanes.types'

const getFields = (
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): FieldInCard<keyof Lane>[] => {
    return [
        {
            id: 'generalInformation',
            title: 'General Information',
            className: 'generalInformation',
            fields: [
                {
                    name: 'laneNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'LaneOpsInformationDto.laneNumber',
                },
                {
                    name: 'laneStatus',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneStatus),
                    labelKey: 'LaneOpsInformationDto.laneStatus',
                },
                {
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                    labelKey: 'LaneOpsInformationDto.leaseType',
                },
                {
                    name: 'tempRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TemperatureRange),
                    labelKey: 'LaneOpsInformationDto.tempRange',
                },
                {
                    name: 'createdBy',
                    componentName: 'InputSingleline',
                    labelKey: 'LaneOpsInformationDto.createdBy',
                },
                {
                    name: 'collectionServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(CollServiceType),
                    labelKey: 'LaneOpsInformationDto.collectionServiceType',
                },
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    labelKey: 'LaneOpsInformationDto.deliveryServiceType',
                },
                {
                    name: 'handoverPoint',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'LaneOpsInformationDto.handoverPoint',
                },
                {
                    name: 'keyAccountManager',
                    componentName: 'ContactObjectSelector',
                    labelKey: 'LaneOpsInformationDto.keyAccountManager',
                },
                {
                    name: 'businessDevelopers',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'LaneOpsInformationDto.businessDevelopers',
                },
            ],
        },
        {
            id: 'customer',
            title: 'Customer',
            className: 'customer',
            fields: [{
                name: 'customerQuotation',
                componentName: 'LocationObjectSelector',
                labelKey: 'LaneOpsInformationDto.customerQuotation',
            }],
        },
        {
            id: 'airports',
            title: 'Airports',
            className: 'airports',
            fields: [
                {
                    name: 'originAirport',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'LaneOpsInformationDto.originAirport',
                },
                {
                    name: 'destinationAirport',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'LaneOpsInformationDto.destAirport',
                },
            ],
        },

    ]
}

export default getFields
