import React, {
    useCallback, useMemo, useState,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import {
    LoggerType,
} from 'app/types/enums'
import loggerCreateFields from './LoggerCreate.fields'
import LoggerRequest from '../Logger.request'
import {
    LoggerResponse,
} from '../Loggers.types'

type LoggerCreateFormData = Pick<LoggerResponse, 'id' | 'loggerStatus' | 'loggerNumber' | 'loggerType'>

const defaultProps: Partial<LoggerCreateFormProps> = {
    data: {} as LoggerCreateFormData,
    exists: false,
}

type LoggerCreateFormProps = {
    data?: LoggerCreateFormData,
    exists?: boolean,
}

const LoggerCreate = ({
    data,
    exists,
}: LoggerCreateFormProps) => {
    const [
        value,
        setValue,
    ] = useState<LoggerCreateFormData>(data)
    const {
        selectDomainObject,
    } = useDomainObjectContext()

    const fields = useMemo(() => {
        return loggerCreateFields(value?.loggerStatus)
    }, [value?.loggerStatus])

    const onChangeValue = useCallback((val: LoggerCreateFormData) => {
        const newValue = {
            ...val,
        }

        if (JSON.stringify(newValue) !== JSON.stringify(value)) {
            setValue(newValue)
        }
    }, [value])

    const onSuccessLocal = useCallback(() => {
        let loggerNumber = value?.loggerNumber

        if ([
            LoggerType.CARTASENSE,
            LoggerType.CARTASENSE_M,
        ].includes(value?.loggerType)) {
            loggerNumber = value?.loggerNumber.padStart(10, '0')
        }

        selectDomainObject(loggerNumber)
    }, [
        selectDomainObject,
        value,
    ])

    return (
        <FormWithControls
            name={LoggerRequest.domainName}
            value={value}
            fields={fields}
            onSuccess={onSuccessLocal}
            exists={exists}
            requestParams={LoggerRequest.create()}
            setExternalValue={onChangeValue}
        />
    )
}

LoggerCreate.defaultProps = defaultProps

export default LoggerCreate
