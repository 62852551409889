/* eslint-disable class-methods-use-this */

import React from 'react'
import {
    Cell, CellTemplate, Compatible, getCellProperty, Uncertain,
} from '@silevis/reactgrid'
import {
    SkyNetSpreadSheetCellType,
} from '../../SkyNetSpreadSheet.types'

export interface NoValueCell extends Cell {
    type: SkyNetSpreadSheetCellType.NO_VALUE,
    text: string,
    nonEditable?: boolean
}

export default class NoValueCellTemplate implements CellTemplate<NoValueCell> {
    getCompatibleCell(uncertainCell: Uncertain<NoValueCell>): Compatible<NoValueCell> {
        const value = getCellProperty(uncertainCell, 'text', 'string')

        return {
            ...uncertainCell, text: value, value, nonEditable: true,
        }
    }

    isFocusable(): boolean {
        return false
    }

    render(
        cell: Compatible<NoValueCell>,
    ): React.ReactNode {
        return <span>{cell.text}</span>
    }
}
