import {
    makeStyles,
} from 'app/tss'

const generalInformation = 'generalInformation'
const deliveryCollectionFees = 'deliveryCollectionFees'
const otherFees = 'otherFees'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${generalInformation} ${generalInformation} ${generalInformation} ${generalInformation}"
            "${deliveryCollectionFees} ${deliveryCollectionFees} ${deliveryCollectionFees} ${deliveryCollectionFees}"
            "${otherFees} ${otherFees} ${otherFees} ${otherFees}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        generalInformation: {
            gridArea: generalInformation,
        },
        deliveryCollectionFees: {
            gridArea: deliveryCollectionFees,
        },
        otherFees: {
            gridArea: otherFees,
        },
    }
})

export default useStyles
