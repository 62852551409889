import {
    makeStyles,
} from 'app/tss'

const defaultAttachments = 'defaultAttachments'
const bookingConfirmation = 'bookingConfirmation'

const useStyles = makeStyles()((theme) => {
    return {
        rowFormContainer: {
            padding: theme.spacing(2),
            cursor: 'default',
            backgroundColor: theme.palette.secondary[100],
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `"${bookingConfirmation} ${defaultAttachments}"`,
            gridTemplateColumns: '3fr 1fr',
        },
    }
})

export default useStyles
