import React from 'react'
import noop from 'lodash/noop'

import FxRate from 'app/shared-components/FxRate/FxRate.types'
import CurrencyConverter from '../../CurrencyConverter'

type Props = {
    value?: {
        feeValue?: number,
        feeName?: string,
    },
    params?: {
        exchangeRate: FxRate,
    },
    onChange?: (...args: any[]) => void,
    disabled?: boolean,
    currency?: string,
}

const defaultProps: Partial<Props> = {
    value: {
        feeValue: undefined,
        feeName: undefined,
    },
    params: {
        exchangeRate: undefined,
    },
    onChange: noop,
    disabled: false,
    currency: undefined,
}

const Calculation = ({
    value,
    params,
    onChange,
    disabled,
    currency,
}: Props) => {
    return (
        <CurrencyConverter
            value={value}
            params={params}
            onChange={onChange}
            disabled={disabled}
            currency={currency}
        />
    )
}

Calculation.defaultProps = defaultProps

export default Calculation
