import React, {
    useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
    useDispatch,
} from 'react-redux'

import {
    hideMessage,
} from 'app/shared-components/FormStatuses/store/actions'

import SuccessMessage from './SuccessMessage'

const propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
}

const defaultProps = {
    title: undefined,
}

const SuccessMessageContainer = ({
    id, title,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            dispatch(hideMessage({
                id,
            }))
        }, 5000)
    }, [
        id,
        dispatch,
    ])

    return (
        <SuccessMessage
            id={id}
            title={title}
        />
    )
}

SuccessMessageContainer.propTypes = propTypes
SuccessMessageContainer.defaultProps = defaultProps

export default SuccessMessageContainer
