import React, {
    useCallback, useMemo,
} from 'react'
import {
    renderToString,
} from 'react-dom/server'
import ReactApexChart from 'react-apexcharts'

import Card from 'app/shared-components/Card'
import StatusHandler from 'app/shared-components/StatusHandler'

import useGetDryStorage from '../../hooks/useGetDryStorage'
import useStyles from './DryStorage.style'

import getOptions from './optionsChart'
import CustomTooltip from './CustomTooltip'

const DryStorage = ({
    id,
}: {
    id: number,
}) => {
    const {
        classes,
    } = useStyles()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetDryStorage({
        serviceCenter: {
            id,
        },
    })

    const chartData: {
        series: number[],
        categories: string[],
    } = useMemo(() => {
        return data?.totalContainerAmountDry?.reduce((acc, {
            containerAmount,
            date,
        }) => {
            return {
                series: [
                    ...acc.series,
                    containerAmount,
                ],
                categories: [
                    ...acc.categories,
                    date,
                ],
            }
        }, {
            series: [],
            categories: [],
        })
    }, [data])

    const customTooltip = useCallback(({
        series: selectedSeries,
        dataPointIndex,
        w,
    }: {
        series: number[],
        dataPointIndex: number,
        w: {
            globals :{
                labels: string[],
            }
        }
    }) => {
        return renderToString(
            <CustomTooltip
                date={w.globals.labels[dataPointIndex]}
                dryStorageCapacity={data?.dryStorageCapacity}
                totalContainerAmount={selectedSeries[0][dataPointIndex]}
            />,
        )
    }, [data?.dryStorageCapacity])

    const options = useMemo(() => {
        return getOptions({
            categories: chartData?.categories,
            customTooltip,
            dryStorageCapacity: data?.dryStorageCapacity,
        })
    }, [
        chartData?.categories,
        customTooltip,
        data?.dryStorageCapacity,
    ])

    return (
        <Card
            contentClass={classes.cardContent}
            data-testid="dry-storage-card"
            title="Dry Storage Fill Ratio"
        >
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                {data && (
                    <ReactApexChart
                        options={options}
                        height={700}
                        style={{
                            width: '100%',
                        }}
                        series={[{
                            name: 'Total Container Amount (Dry)',
                            data: chartData.series,
                        }]}
                        type="bar"
                    />
                )}
            </StatusHandler>
        </Card>
    )
}

export default DryStorage
