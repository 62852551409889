import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    AddressCategory,
    LocationType,
} from 'app/types/enums'

const config:SkyNetTableConfig = {
    name: 'LocationTable',
    tabs: [
        {
            name: 'All Locations',
            url: 'all',
        },
        {
            name: 'Addresses',
            url: 'address',
            includeFilters: {
                locationType: [LocationType.ADDRESS],
            },
        },
        {
            name: 'Airports',
            url: 'airport',
            includeFilters: {
                locationType: [LocationType.AIRPORT],
            },
        },
        {
            name: 'Seaports',
            url: 'seaport',
            includeFilters: {
                locationType: [LocationType.SEAPORT],
            },
        },
    ],
    fields: [
        {
            name: 'locationName',
            reduced: true,
        },
        {
            name: 'addressLine1',
            filterType: FilterType.Array,
        },
        {
            name: 'zip',
            filterType: FilterType.Array,
        },
        {
            name: 'city',
            reduced: true,
        },
        {
            name: 'countryName',
            filterField: 'countryName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'country',
        },
        {
            name: 'isMainAddress',
            componentName: 'ChipCheckMark',
        },
        {
            name: 'companyName',
            filterField: 'companyName',
            filterType: FilterType.Array,
            labelKey: 'company',
        },
        {
            name: 'addressCategory',
            componentName: 'EnumList',
            allowedValuesEnum: AddressCategory,
        },
    ],
}

export default config
