import skyTheme from '@skycell-ag/theme'

const theme = {
    ...skyTheme,
    components: {
        MuiTimeClock: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                },
            },
        },
    },
}

export default theme
