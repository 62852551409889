import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import loggerPairingRequests from '../LoggerExchange.requests'

export default (onSuccessAction) => {
    const token = useJWTToken()

    const {
        onError: onFailedRequest,
        onSuccess: onSuccessRequest,
    } = useHandleStatusRequest({
        id: 'LocationDuplicationCheck',
    })

    const onSuccess = useCallback((data) => {
        onSuccessRequest(data)
        onSuccessAction(data)
    }, [
        onSuccessRequest,
        onSuccessAction,
    ])

    const onError = useCallback((err) => {
        onFailedRequest(err)
    }, [onFailedRequest])

    return useMutation({
        mutationFn: ({
            id, serialNumber,
        }: {id: number, serialNumber: string}) => {
            return simpleApi({
                ...loggerPairingRequests.deleteLoggerPairing({
                    serialNumber, id,
                }),
                token,
            })
        },
        onSuccess,
        onError,
    })
}
