import React, {
    useCallback,
} from 'react'
import noop from 'lodash/noop'

import {
    Event,
} from 'app/types/common.types'
import {
    dayPassedToRange,
    toDateRangeLimitSafe,
    strOrDateToDateStr,
} from 'app/utils/date'

import DateRangeSelector from './DateRangeSelector'

export type Value = {
    from: number | string | Date,
    to: number | string | Date,
}

type Props = {
    value?: Value,
    onChange?: (newVal: Value, event: Event<Value>) => void,
    setDateRange: (value: boolean) => void,
    mini?: boolean,
    showTimeRange?: boolean,
    options?: {
        label: string,
        value: number,
    }[]
}

const defaultProps = {
    value: {},
    onChange: noop,
    mini: false,
    showTimeRange: false,
    options: [
        {
            label: '24h',
            value: 1,
        },
        {
            label: '7d',
            value: 7,
        },
        {
            label: '14d',
            value: 14,
        },
    ],
}

const DateRangeSelectorContainer = ({
    value,
    onChange,
    setDateRange,
    mini,
    showTimeRange,
    options,
}: Props) => {
    const {
        from = new Date(),
        to = new Date(),
    } = value

    const selectOption = useCallback((selected: number, event): void => {
        const newValue = dayPassedToRange(selected)

        onChange({
            from: toDateRangeLimitSafe(newValue.from, false),
            to: toDateRangeLimitSafe(newValue.to, true),
        }, event)
        setDateRange(false)
    }, [
        onChange,
        setDateRange,
    ])

    const onChangeRange = useCallback((_, {
        target: {
            name,
            value: newValue,
        },
    }: Event<Date>): void => {
        const updatedData = {
            from: toDateRangeLimitSafe(from),
            to: toDateRangeLimitSafe(to, true),
            [name]: toDateRangeLimitSafe(newValue, name === 'to'),
        }

        onChange(updatedData, {
            target: {
                name,
                value: updatedData,
            },
        })

        setDateRange(true)
    }, [
        from,
        onChange,
        setDateRange,
        to,
    ])

    return (
        <DateRangeSelector
            selectOption={selectOption}
            onChangeRange={onChangeRange}
            from={strOrDateToDateStr(from)}
            to={strOrDateToDateStr(to)}
            options={options}
            showTimeRange={showTimeRange}
            mini={mini}
        />
    )
}

DateRangeSelectorContainer.defaultProps = defaultProps

export default DateRangeSelectorContainer
