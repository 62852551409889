import {
    useDispatch,
} from 'react-redux'
import noop from 'lodash/noop'
import {
    useCallback,
    useMemo,
} from 'react'

import {
    failedToLoadSaveForm,
    successedToSaveForm,
    startToSaveForm, removePending,
} from 'app/shared-components/FormStatuses/store/actions'

const useHandleStatusRequest = ({
    onSuccess = noop,
    onError = noop,
    title,
    id,
}: {
    id: string,
    title?: string,
    onSuccess?: (data?: any) => void,
    onError?: (error?: any) => void,
}) => {
    const dispatch = useDispatch()
    const onErrorHandler = useCallback((error) => {
        const {
            response: {
                data: errors,
                status: statusCode,
            },
        } = error

        dispatch(
            failedToLoadSaveForm({
                id,
                errors,
                statusCode,
            }),
        )

        onError(error)
    }, [
        dispatch,
        id,
        onError,
    ])

    const onSuccessHandler = useCallback((data) => {
        dispatch(
            successedToSaveForm({
                id,
                title,
            }),
        )
        onSuccess(data)
    }, [
        dispatch,
        id,
        onSuccess,
        title,
    ])

    const onStartSavingHandler = useCallback((msg?: string) => {
        dispatch(
            startToSaveForm({
                id,
                title: msg,
            }),
        )
    }, [
        dispatch,
        id,
    ])

    const removePendingStatusHandler = useCallback(() => {
        dispatch(
            removePending({
                id,
            }),
        )
    }, [
        dispatch,
        id,
    ])

    return useMemo(() => {
        return {
            onSuccess: onSuccessHandler,
            onError: onErrorHandler,
            onStartSaving: onStartSavingHandler,
            removePendingStatus: removePendingStatusHandler,
        }
    }, [
        onErrorHandler,
        onStartSavingHandler,
        onSuccessHandler,
        removePendingStatusHandler,
    ])
}

export default useHandleStatusRequest
