import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        simulationWrapper: {
            width: '100%',
        },
        infoMsg: {
            width: '100%',
            marginBottom: theme.spacing(4),
        },
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            width: 26,
            height: 26,
            background: theme.palette.primary[200],
            color: theme.palette.common.white,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.spacing(2),
        },
        msg: {
            paddingTop: theme.spacing(0.4),
            fontSize: theme.typography.h2.fontSize,
            fontWeight: theme.typography.subtitle1.fontWeight,
            color: theme.palette.primary[200],
            marginRight: theme.spacing(4),
        },
    }
})

export default useStyles
