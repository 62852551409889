import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import useEnumValues from 'app/hooks/useEnumValues'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import ContainerTemperatureChart from './ContainerTemperatureChart'
import PrintLabelOverview from './PrintLabelOverview'
import ContainerNotes from './ContainerNotes'

import getFields from './Overview.fields'
import useStyles from './Overview.styles'
import {
    OverviewProps,
} from './Overview.types'
import useGetContainerBySerialNumber from '../../hooks/useGetContainerBySerialNumber'
import containerRequests from '../../Containers.request'

const Overview = ({
    id: serialNumber,
}: OverviewProps) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetContainerBySerialNumber(serialNumber)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                {
                    data?.id
                        ? (
                            <div className={classes.gridWrapper}>
                                <div className={classes.domainObject}>
                                    <DomainObjectForm
                                        name={containerRequests.name}
                                        value={data}
                                        fields={getFields(getAllowedValues)}
                                        exists
                                        disabled
                                    />
                                </div>
                                <div className={classes.temperatureChart}>
                                    <ContainerTemperatureChart
                                        serialNumber={serialNumber}
                                        defaultAmbientLoggerType={data.defaultAmbientLoggerType}
                                        defaultInternalLoggerType={data.defaultInternalLoggerType}
                                    />
                                </div>
                                <div className={classes.printLabel}>
                                    <PrintLabelOverview
                                        value={data}
                                    />
                                </div>
                                <div className={classes.containerNotes}>
                                    <ContainerNotes
                                        id={data?.id}
                                    />
                                </div>
                            </div>
                        )
                        : null
                }
            </>
        </StatusHandler>
    )
}

export default Overview
