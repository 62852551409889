import React from 'react'
import PropTypes from 'prop-types'
import {
    CircularProgress,
} from '@mui/material'
import noop from 'lodash/noop'

import useStyles from './Button.style'

const propTypes = {
    className: PropTypes.string,
    classNameLabel: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    secondary: PropTypes.bool,
    alert: PropTypes.bool,
    disabled: PropTypes.bool,
    saving: PropTypes.bool,
    icon: PropTypes.element,
    isUpperCase: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    name: PropTypes.string,
}

const defaultProps = {
    className: '',
    classNameLabel: undefined,
    secondary: false,
    disabled: false,
    alert: undefined,
    saving: false,
    icon: null,
    isUpperCase: true,
    name: undefined,
}

const Button = ({
    label,
    onClick,
    secondary,
    disabled,
    alert,
    saving,
    icon,
    className,
    classNameLabel,
    isUpperCase,
}) => {
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <div className={cx(classes.buttonContainer, className)}>
            <div
                data-testid={`button-${label}`}
                className={cx(classNameLabel, {
                    [classes.buttonPrimary]: !secondary,
                    [classes.buttonSecondary]: secondary,
                    [classes.button]: true,
                    [classes.disabled]: disabled || saving,
                    [classes.buttonAlert]: alert,
                })}
                onMouseDown={disabled || saving ? noop : onClick}
            >
                {saving && (
                    <>
                        <CircularProgress
                            size={16}
                            color="inherit"
                        />
                        &nbsp;
                    </>
                )}
                {icon}
                &nbsp;
                <div className={cx(
                    isUpperCase && classes.buttonTitleUppercase,
                    classes.buttonTitle,
                )}
                >
                    {label}
                </div>
            </div>
        </div>

    )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
