/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import LocationChangeSelector from './LocationChangeSelector'
import useGetLocations from './hook/useGetLocations'

type LocationType = {
    id: string | number,
}

type Props = {
    value: {
        locationOld: LocationType,
        locationNew: LocationType,
    },
    inputs: ('old' | 'actual')[],
}

const LocationChangeSelectorContainer = (props: Props) => {
    const {
        value: {
            locationOld,
            locationNew,
        },
    } = props

    const {
        data,
    } = useGetLocations({
        locationOldId: locationOld.id,
        locationNewId: locationNew.id,
    })

    return (
        <LocationChangeSelector
            {...props}
            data={data}
        />
    )
}

export default LocationChangeSelectorContainer
