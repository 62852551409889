import {
    UseRequestConfig,
} from 'app/types/request.types'
import simpleApi from 'app/services/simpleApi'

const config: Record<string, UseRequestConfig> = {
    OrderNotifications: {
        requestFn: ({
            url,
            params,
        }) => {
            return {
                url,
                method: 'POST',
                ...params,
            }
        },
    },
    TriggerNotifications: {
        mutationFn: ({
            orderNumber,
            notificationId,
            token,
            count,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/notification/${notificationId}/dispatch/${count}/sending`,
                method: 'POST',
                token,
            })
        },
    },
    NotificationEmail: {
        requestFn: ({
            orderNumber,
            id,
        }) => {
            return {
                url: `order/${orderNumber}/notification/${id}/dispatch/FIRST/content`,
            }
        },
    },
}

export default config
