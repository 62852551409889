import {
    RequestKeys,
} from 'app/hooks/useRequest'

import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'
import config from '../../../PreBookings.requests'
import {
    RequiredContainersChange,
} from '../../../PreBooking.types'

export default (id: number) => {
    const {
        onParamsChange,
        loadData,
    } = useRequestTable<TableResponse<RequiredContainersChange>>({
        config: config.getRequiredContainersChanges,
        params: {
            id,
        },
        key: RequestKeys.getPreBookingRequiredContainersChanges,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    return {
        onParamsChange,
        loadData,
    }
}
