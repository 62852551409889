export type BillingCompanySelectorProps = {
    title: string,
    name: string,
    value: string | number,
    className: string,
    classNames: {
        options?: string,
    }
    'data-testid': string,
    onChange: (value: any, ...params: any[]) => void,
    isRequired: boolean,
    disabled: boolean,
}

export const BillingCompanySelectorDefaultProps: Partial<BillingCompanySelectorProps> = {
    value: undefined,
    name: '',
    title: '',
    disabled: false,
    isRequired: false,
    className: '',
    classNames: {},
    'data-testid': 'BillingCompanySelector',
}
