import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    SelectedContainerFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    RejectionReason,
} from 'app/types/enums'

const columns: RichTableColumns<SelectedContainerFields>[] = [
    {
        id: 'serialNumber',
        headerProps: {
            children: 'Serial Number',
        },
        mapCellProps: 'serialNumber',
    },
    {
        id: 'containerTypeContainerModel',
        headerProps: {
            children: 'Type',
        },
        mapCellProps: 'containerTypeContainerModel',
    },
    {
        id: 'rejectionComment',
        headerProps: {
            children: 'Rejection Comment',
        },
        mapCellProps: 'rejectionComment',
    },
    {
        id: 'rejectionReason',
        headerProps: {
            children: 'Rejection Reason',
        },
        mapCellProps: 'rejectionReason',
        componentName: 'EnumValue',
        allowedValuesEnum: RejectionReason,
    },
]

export default columns
