import {
    LaneGroupTemperatureRange, LeaseType,
} from 'app/types/enums'
import {
    DTOCardFormField, FieldInCard, NestedField,
} from 'app/types/form.types'
import {
    CollServiceType, DeliveryServiceType, TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    AccountLaneForm,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    App,
} from 'app/types/common.enums'
import {
    LaneGroup,
} from 'app/Apps/Pricing/Pricing.types'
import {
    SizeLink,
    TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    OpportunityType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    RequestedLane, RequestedOpportunity,
} from 'app/Apps/AccountManagement/AccountQuotes/AccountQuotes.types'

type AccountQuoteLaneGroupForm = keyof
    (LaneGroup & RequestedOpportunity & OpportunityType & RequestedLane & {
        airlineName: string,
        isPerformanceQualification: boolean,
    })

const getFields = ({
    getAllowedValues,
    showFTL,
    showLTL,
    isDeliveryRoad,
    laneGroupId,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    showFTL: boolean,
    showLTL: boolean,
    isDeliveryRoad: boolean,
    laneGroupId: number,

}): DTOCardFormField <AccountQuoteLaneGroupForm>[] => {
    const generalFields: FieldInCard<AccountQuoteLaneGroupForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'tempRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'LaneGroup.tempRange',
                    disabled: true,
                },
                {
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                    labelKey: 'LaneGroup.leaseType',
                    showInColumns: true,
                },
                {
                    name: 'changedBy',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.changedBy',
                    disabled: true,
                },
                {
                    name: 'changedOn',
                    componentName: 'DateTimeSelect',
                    labelKey: 'AccountLane.changedOn',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'comment',
                    componentName: 'LongText',
                    labelKey: 'LaneGroup.comment',
                    disabled: true,
                },
                {
                    name: 'laneGroupNumber',
                    componentName: 'SkyNetLink',
                    link: `/apps/${App.PRICING}/lane-groups/all/edit/${laneGroupId}/overview`,
                    labelKey: 'LaneGroup.laneGroupNumber',
                    type: TypeLink.EXTERNAL,
                    size: SizeLink.S,
                },
                {
                    name: 'isPerformanceQualification',
                    componentName: 'Checkbox',
                    labelKey: 'AccountQuotes.isPerformanceQualification',
                    disabled: true,
                },
            ],
        },
    ]

    const transportModeRoad = {
        name: 'transportModeRoad',
        labelKey: 'LaneGroupCreateOrDuplicateDto.transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        showInColumns: true,
    } as FieldInCard<AccountLaneForm>

    const isFtlPositioningTemperatureControlled = {
        name: 'isFtlPositioningTemperatureControlled',
        labelKey: 'LaneGroupCreateOrDuplicateDto.isFtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<AccountLaneForm>

    const isLtlPositioningTemperatureControlled = {
        name: 'isLtlPositioningTemperatureControlled',
        labelKey: 'LaneGroupCreateOrDuplicateDto.isLtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<AccountLaneForm>

    let transportModeFields = [transportModeRoad]

    if (showLTL) {
        transportModeFields = [
            ...transportModeFields,
            isLtlPositioningTemperatureControlled,
        ]
    }

    if (showFTL) {
        transportModeFields = [
            ...transportModeFields,
            isFtlPositioningTemperatureControlled,
        ]
    }

    const handoverFields: FieldInCard<AccountQuoteLaneGroupForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    labelKey: 'LaneGroup.deliveryServiceType',
                    required: true,
                },
                ...(isDeliveryRoad
                    ? transportModeFields : []
                ) as NestedField<AccountQuoteLaneGroupForm>[],
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'handoverPointLocationName',
                    componentName: 'LongText',
                    labelKey: 'LaneGroup.handoverPoint',
                },
                {
                    name: 'handoverPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'LaneGroup.handoverPointContacts',
                    disabled: true,
                },
            ],
        },
    ]

    const collectionFields: FieldInCard<AccountQuoteLaneGroupForm>[] = [
        {
            id: 'column1',
            fields: [{
                name: 'collectionServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(CollServiceType),
                labelKey: 'LaneGroup.collectionServiceType',
            }],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'collectionDropoffPointLocationName',
                    componentName: 'LongText',
                    labelKey: 'LaneGroup.collectionDropoffPoint',
                },
                {
                    name: 'collectionDropoffPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'LaneGroup.collectionDropoffPointContacts',
                    disabled: true,
                },
            ],
        },
    ]

    return [
        {
            id: 'general',
            title: 'General Information',
            className: 'general',
            fields: generalFields,
        },
        {
            id: 'handover',
            title: 'Handover',
            className: 'handover',
            fields: handoverFields,
        },
        {
            id: 'collection',
            title: 'Collection',
            className: 'collection',
            fields: collectionFields,
        },
    ]
}

export default getFields
