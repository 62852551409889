import React, {
    useCallback,
    useState,
} from 'react'
import SendIcon from '@mui/icons-material/Send'

import Checkbox from 'app/shared-components/Checkbox'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'

import useStyles from './SendTime.style'
import useTriggerNotifications, {
    Count,
} from './hooks/useTriggerNotifications'
import useRefetch from './hooks/useRefetch'

type Props = {
    value: boolean,
    name: string,
    title: string,
    disabled: boolean,
    notificationId: number,
    orderNumber: string,
    onChange: (changeValue: boolean) => void,
}

const COUNT_MAP = {
    sendFirstTime: Count.FIRST,
    sendSecondTime: Count.SECOND,
    sendThirdTime: Count.THIRD,
}
const DIALOG_CONTENT = 'Are you sure you want to manually trigger this notification? It will be sent even if the data is incomplete.'

const SendTime = (props: Props) => {
    const {
        value,
        name,
        title,
        onChange,
        orderNumber,
        notificationId,
        disabled,
    } = props

    const {
        classes,
    } = useStyles()
    const refetch = useRefetch()

    const [
        open,
        setOpen,
    ] = useState(false)

    const onSuccess = useCallback(() => {
        refetch()
        setOpen(false)
    }, [refetch])

    const onError = useCallback(() => {
        setOpen(false)
    }, [])

    const {
        isPending: isLoading,
        mutate,
    } = useTriggerNotifications(onSuccess, onError)

    const triggerNotifications = useCallback(() => {
        mutate({
            orderNumber,
            notificationId,
            count: COUNT_MAP[name],
        })
    }, [
        mutate,
        orderNumber,
        notificationId,
        name,
    ])

    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }

    const onTriggerNotifications = useCallback((action) => {
        if (!action) {
            setOpen(false)

            return
        }

        triggerNotifications()
    }, [triggerNotifications])

    return (
        <div className={classes.wrapper}>
            <Checkbox
                name={name}
                title={title}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
            <SendIcon
                onClick={handleClickOpen}
                className={classes.icon}
            />
            <ConfirmationDialog
                open={open}
                handleClose={onTriggerNotifications}
                dialogTitle="NOTIFICATION CONFIRMATION"
                dialogContent={DIALOG_CONTENT}
                positiveLabel="send"
                negativeLabel="cancel"
                isLoading={isLoading}
            />
        </div>
    )
}

export default SendTime
