/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import InputSingleline from '../InputSingleline'

const propTypes = {
    rows: PropTypes.number,
    data: PropTypes.shape({
        value: PropTypes.string,
    }),
}

const defaultProps = {
    rows: 4,
    data: {},
}

const InputMultiline = (props) => {
    const {
        rows,
        data: {
            value,
        },
    } = props

    const countValueRowNumber = value?.split(/\r\n|\r|\n/).length

    return (
        <InputSingleline
            {...props}
            rows={countValueRowNumber || rows}
            multiline
        />
    )
}

InputMultiline.propTypes = propTypes
InputMultiline.defaultProps = defaultProps

export default InputMultiline
