import React, {
    useEffect,
    useMemo, useState,
} from 'react'
import moment from 'moment'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'
import Button from 'app/shared-components/Button'
import Tooltip from 'app/shared-components/Tooltip'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import validateForm from 'app/utils/validateForm'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    DeliveryServiceType, TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    ProductType,
} from 'app/types/enums'

import {
    BookingDetailsStep,
    Selection,
} from '../../createDirectLease.types'
import getFields from './bookingDetails.fields'
import useStyles from './BookingDetails.style'
import requestConfig from '../../createDirectLease.request'

const BookingDetails = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    onEdit,
    onReset,
    index,
    selectedLane,
}: {
    value: BookingDetailsStep,
    onChange: (newVal: BookingDetailsStep) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    onEdit: () => void,
    onReset: () => void,
    index: number,
    selectedLane: Lane,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const [
        dependentData,
        setDependentData,
    ] = useState<Partial<BookingDetailsStep>>({})

    const {
        leaseStartTimestamp,
        isPerformanceQualification,
        transportModeForwarder,
        numberOfTrucks,
        numberOfVans,
        fwdPosSelection,
        originScSelection,
        requestedDelivery,
        requestedPickup,
    } = value

    const {
        data: allowedProductTypes,
    } = useRequest<ProductType[]>({
        key: RequestKeys.getAllowedProductTypes,
        params: {
            selectedLaneId: selectedLane.id,
            leaseStartTimestamp,
            isPerformanceQualification,
        },
        requestFunc: RequestFn.getEntity(requestConfig.AllowedProductTypes.requestFn),
        enabled: Boolean(leaseStartTimestamp),
    })

    useEffect(() => {
        onChange({
            ...value,
            customerExecutionContactsOps: selectedLane.customerExecutionContactsOps,
            transportModeRoad: selectedLane.transportModeRoad === TransportModeRoad.LTL
                ? TransportModeRoad.LTL
                : TransportModeRoad.FTL,
            transportModeForwarder:
                    selectedLane.transportModeRoad === TransportModeRoad.LTL
                        ? TransportModeRoad.LTL
                        : TransportModeRoad.FTL,
            orderEntered: moment().format('DD.MM.YYYY HH:mm ZZ'),
            originScSelection: Selection.DEFAULT,
            fwdPosSelection: Selection.DEFAULT,
            originServiceCenter: selectedLane.originSc,
            forwarder: selectedLane.fwdPos,
            laneCommentOps: selectedLane.laneCommentOps,
        })

        setDependentData({
            originScSelection: Selection.DEFAULT,
            fwdPosSelection: Selection.DEFAULT,
        })
    }, []) // eslint-disable-line

    useEffect(() => {
        if (
            Boolean(isPerformanceQualification)
            !== Boolean(dependentData.isPerformanceQualification)
        ) {
            onChange({
                ...value,
                requiredContainers: [],
            })
            setDependentData({
                ...dependentData,
                isPerformanceQualification,
            })
        }
    }, [
        dependentData,
        isPerformanceQualification,
        onChange,
        value,
    ])

    useEffect(() => {
        if (originScSelection !== dependentData.originScSelection) {
            const newValueOriginServiceCenter = originScSelection === Selection.DEFAULT
                ? selectedLane.originSc
                : selectedLane.originScBackup

            onChange({
                ...value,
                originServiceCenter: newValueOriginServiceCenter,
            })
            setDependentData({
                ...dependentData,
                originScSelection,
            })
        }

        if (fwdPosSelection !== dependentData.fwdPosSelection) {
            const newValueForwarder = fwdPosSelection === Selection.DEFAULT
                ? selectedLane.fwdPos
                : selectedLane.fwdPosBackup

            onChange({
                ...value,
                forwarder: newValueForwarder,
            })
            setDependentData({
                ...dependentData,
                fwdPosSelection,
            })
        }

        if (transportModeForwarder === TransportModeRoad.LTL && (numberOfTrucks || numberOfVans)) {
            onChange({
                ...value,
                numberOfTrucks: undefined,
                numberOfVans: undefined,
            })
        }
    }, [
        dependentData,
        fwdPosSelection,
        numberOfTrucks,
        numberOfVans,
        onChange,
        originScSelection,
        selectedLane.fwdPos,
        selectedLane.fwdPosBackup,
        selectedLane.originSc,
        selectedLane.originScBackup,
        transportModeForwarder,
        value,
    ])

    useEffect(() => {
        if (leaseStartTimestamp) {
            return
        }
        if (requestedDelivery !== dependentData.requestedDelivery) {
            setDependentData({
                ...dependentData,
                requestedDelivery,
            })
            onChange({
                ...value,
                leaseStartTimestamp: requestedDelivery,
            })
        }
        if (requestedPickup !== dependentData.requestedPickup) {
            setDependentData({
                ...dependentData,
                requestedPickup,
            })
            onChange({
                ...value,
                leaseStartTimestamp: requestedPickup,
            })
        }
    }, [
        dependentData,
        leaseStartTimestamp,
        onChange,
        requestedDelivery,
        requestedPickup,
        value,
    ])

    const isDeliveryRoad: boolean = useMemo(() => {
        return selectedLane?.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    }, [selectedLane?.deliveryServiceType])

    const defaultTimeZone: string = useMemo(() => {
        const chars = {
            PLUS_: '+',
            MINUS_: '-',
        }

        const timeZone = selectedLane?.handoverPoint?.timeZone

        return timeZone?.replace(/PLUS_|MINUS_/g, (x) => {
            return chars[x]
        })
    }, [selectedLane])

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        selectedLane,
        isDeliveryRoad,
        transportModeForwarder,
        allowedProductTypes,
        defaultTimeZone,
        disabled: activeStep > index,
    }), classes)

    const onClickBack = () => {
        onReset()
        setActiveStep(index - 1)
    }

    const onClickEdit = () => {
        onEdit()
        setActiveStep(index)
    }

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value,
        })
    }, [
        fields,
        value,
    ])

    return (
        <>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                className={classes.gridWrapper}
                disabled={activeStep > index}
                name="OrderInformation"
            />
            <div className={classes.actions}>
                {activeStep === index && (
                    <>
                        <Tooltip
                            title="Please fill in all mandatory fields to continue"
                            disabled={isFormValid}
                        >
                            <div>
                                <Button
                                    label="Continue"
                                    onClick={() => {
                                        setActiveStep(index + 1)
                                    }}
                                    className={classes.button}
                                    disabled={!isFormValid}
                                    data-testid="btn-continue"
                                />
                            </div>
                        </Tooltip>
                        <Button
                            label="Back"
                            secondary
                            onClick={onClickBack}
                            className={classes.button}
                            data-testid="btn-back"
                        />
                    </>
                )}
                {activeStep > index && (
                    <Button
                        label="Edit"
                        onClick={onClickEdit}
                        className={classes.button}
                        data-testid="btn-edit"
                    />
                )}
            </div>
        </>
    )
}

export default BookingDetails
