import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import Overview from './Overview'
import LaneImplementation from './LaneImplementation'
import Notifications from './Notifications'
import tableConfig from './LaneOpsInformationDto.config'

const DOMAIN_NAME = 'LaneOpsInformationDto'

const tabs: SidebarTab[] = [
    {
        url: 'overview',
        label: 'Overview',
        Component: Overview,
    },
    {
        url: 'laneimplementation',
        label: 'Lane Implementation',
        Component: LaneImplementation,
    },
    {
        url: 'notifications',
        label: 'Notifications',
        Component: Notifications,
    },
]

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const LanesTable = () => {
    return (
        <SkyNetTable
            name={DOMAIN_NAME}
            tabs={tabs}
            tableControls={tableControls}
            tableConfig={tableConfig}
            customUrl="lane/filter-request/ops-overview"
            showSearch
        />
    )
}

export default LanesTable
