import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {

    FxRate: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `fxrate/${id}`,
            }
        },
    },
}

export default config
