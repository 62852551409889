import React, {
    useCallback, useRef,
    useState,
} from 'react'
import {
    FormControl,
} from '@mui/material'

import Card from 'app/shared-components/Card'
import Label from 'app/shared-components/Label'
import useLabel from 'app/hooks/useLabel'
import Checkbox from 'app/shared-components/Checkbox'
import EnumSelector from 'app/shared-components/EnumSelector'
import useEnumValues from 'app/hooks/useEnumValues'
import StatusHandler from 'app/shared-components/StatusHandler'

import useStyles from './ContainerAvailabilityForecast.styles'
import {
    AllowedProductTypes,
} from '../../serviceCenters.types'
import useGetAvailability from '../../hooks/useGetAvailability'
import ContainerAvailabilityForecastChart from './ContainerAvailabilityForecastChart'

const ContainerAvailabilityForecast = ({
    id,
    defaultProductType,
    productTypes,
}: {
    id: number,
    defaultProductType: AllowedProductTypes,
    productTypes: Record<string, string>,
}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const allowedValues = useEnumValues()({
        ALL: 'ALL',
        ...productTypes,
    })
    const [
        value,
        setValue,
    ] = useState<{
        containerStatus: Record<string, boolean>,
        productType: AllowedProductTypes,
    }>({
        containerStatus: {
            toBeProcessed: true,
            inProcess: true,
            preconditioned: true,
        },
        productType: defaultProductType,
    })

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetAvailability({
        serviceCenter: {
            id,
        },
        productType: value.productType,
    })

    const listStatuses = useRef(Object.keys(value.containerStatus))

    const onChangeContainerStatus = useCallback((newVal: boolean, {
        target: {
            name,
        },
    }) => {
        setValue({
            ...value,
            containerStatus: {
                ...value.containerStatus,
                [name]: newVal,
            },
        })
    }, [value])

    const onChangeProductType = useCallback((newVal: AllowedProductTypes) => {
        setValue({
            ...value,
            productType: newVal,
        })
    }, [value])

    return (
        <Card
            contentClass={classes.cardContent}
            data-testid="availability-forecast-card"
            title="Container Availability Forecast"
        >
            <div className={classes.panel}>
                <FormControl className={classes.containerStatus}>
                    <Label title={l('Container.containerStatus')} />
                    <div className={classes.wrapperCheckbox}>
                        {
                            listStatuses.current.map((item) => {
                                return (
                                    <Checkbox
                                        key={item}
                                        name={item}
                                        title={l(`Monitoring.${item}`)}
                                        value={value.containerStatus[item]}
                                        onChange={onChangeContainerStatus}
                                        className={classes.checkbox}
                                    />
                                )
                            })
                        }
                    </div>
                </FormControl>
                <EnumSelector
                    title={l('Forecasts.productType')}
                    className={classes.productType}
                    value={value.productType}
                    allowedValues={allowedValues}
                    onChange={onChangeProductType}
                    name="productType"
                    useDropdown
                    noSort
                />
            </div>
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <ContainerAvailabilityForecastChart
                    value={data?.containerAvailabilityForecastForDates}
                    listStatuses={listStatuses.current}
                    containerStatus={value.containerStatus}
                    minPreconditioned={data?.minPreconditioned}
                    l={l}
                />
            </StatusHandler>
        </Card>
    )
}

export default ContainerAvailabilityForecast
