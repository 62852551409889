import {
    LocationType,
} from 'app/types/enums'

const LOCATION_OPTIONS_MAP = {
    [LocationType.ADDRESS]: ({
        locationName, addressLine1, zip, city, countryName,
    }) => {
        return `${locationName}, ${addressLine1},${zip ? ` ${zip},` : ''} ${city}, ${countryName}`
    },
    [LocationType.SEAPORT]: ({
        unloCode, locationName,
    }) => { return `${unloCode} | ${locationName}` },
    [LocationType.AIRPORT]: ({
        iataCode, locationName,
    }) => { return `${iataCode} | ${locationName}` },
}

export default LOCATION_OPTIONS_MAP
