import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    UseRequestConfig,
} from 'app/types/request.types'

/**
* Use this to get mainCategories (addressCategory and contactCategory)
* @return   {Object}  with addressMainCategory and contactMainType
*/

type MainCategoryMap = {
    addressMainCategory: {
        addressCategory: string,
        value: string,
    }[],
    contactMainType: {
        contactType: string,
        value: string,
    }[],
}

const config: UseRequestConfig = {
    requestFn: () => {
        return {
            url: 'location/maincategorymap',
        }
    },
}

const useGetMainCategory = () => {
    const {
        data,
    } = useRequest<MainCategoryMap>({
        key: RequestKeys.getMainCategory,
        requestFunc: RequestFn.getEntity(config.requestFn),
    })

    return data
}

export default useGetMainCategory
