import React, {
    useCallback,
} from 'react'

import InputSingleline from 'app/shared-components/InputSingleline'

import useDownloadNotificationEmail from './hook/useDownloadNotificationEmail'
import useStyles from './SentFirstTimeOn.style'

type Props = {
    notificationName: string,
    orderNumber: string,
    disabled?: boolean,
    value: string,
    title?: string,
    id: number,
}

const defaultProps: Partial<Props> = {
    disabled: false,
    title: '',
}

const SentFirstTimeOn = ({
    notificationName,
    orderNumber,
    disabled,
    title,
    value,
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        mutate: downloadNotificationEmail,
    } = useDownloadNotificationEmail({
        orderNumber,
        id,
        notificationName,
        value,
    })

    const onLoad = useCallback((): void => {
        downloadNotificationEmail()
    }, [downloadNotificationEmail])

    return (
        <div className={classes.root}>
            <InputSingleline
                value={value}
                disabled={disabled}
                title={title}
            />
            {value && (
                <div
                    onMouseDown={onLoad}
                    className={classes.icon}
                    data-testid="download-icon"
                >
                    <img
                        src="/assets/images/download_button.svg"
                        alt="None"
                    />
                </div>
            )}
        </div>
    )
}

SentFirstTimeOn.defaultProps = defaultProps

export default SentFirstTimeOn
