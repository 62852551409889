import React, {
    useMemo,
} from 'react'
import maxBy from 'lodash/maxBy'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetServiceCenterById from '../../hooks/useGetServiceCenterById'
import ContainerAvailabilityForecast from './ ContainerAvailabilityForecast'

const ContainerAvailabilityForecastContainer = ({
    id,
}: {
    id: number
}) => {
    const {
        data: serviceCenter,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetServiceCenterById(id)

    const productTypes: Record<string, string> = useMemo(() => {
        return serviceCenter?.productMetrics?.reduce((acc, {
            productType,
        }) => {
            return {
                ...acc,
                [productType]: productType,
            }
        }, {})
    }, [serviceCenter?.productMetrics])

    const defaultProductType = useMemo(() => {
        if (serviceCenter) {
            return maxBy(serviceCenter.productMetrics, ({
                processingRatio,
            }) => {
                return processingRatio
            })?.productType
        }

        return undefined
    }, [serviceCenter])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContainerAvailabilityForecast
                defaultProductType={defaultProductType}
                productTypes={productTypes}
                id={id}
            />
        </StatusHandler>
    )
}

export default ContainerAvailabilityForecastContainer
