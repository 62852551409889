import React, {
    useCallback,
    useMemo,
} from 'react'

import AttachmentContainer from './AttachmentContainer'

type Props = {
    name?: string,
    title?: string,
    value?: {
        id: number
    }[],
    onChange: (...args: any) => void,
    className?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    title: 'Attachments',
    name: 'attachments',
    value: [],
    className: '',
    disabled: false,
    required: false,
}

const AttachmentsObjectSelector = ({
    title,
    name,
    onChange,
    value,
    className,
    disabled,
    required,
}: Props) => {
    const localOnChange = useCallback((newValue) => {
        const tNewValue = newValue ? newValue.map((id) => {
            return {
                id,
            }
        }) : []

        onChange(tNewValue)
    }, [onChange])

    const localValue = useMemo(() => {
        const coverterValue = value?.map(({
            id,
        }) => {
            return id
        })

        return coverterValue
    }, [value])

    return (
        <AttachmentContainer
            onChange={localOnChange}
            value={localValue}
            className={className}
            title={title}
            disabled={disabled}
            required={required}
            name={name}
        />
    )
}

AttachmentsObjectSelector.defaultProps = defaultProps

export default AttachmentsObjectSelector
