import React, {
    useMemo,
    useCallback,
} from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import pick from 'lodash/pick'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import InputBase from 'app/shared-components/InputBase'
import ProductTypeSelector from 'app/shared-components/ProductTypeSelector'

import useStyles from './Inputs.styles'

const propTypes = {
    value: PropTypes.shape({
        maxNumberOfContainersPerTruck: PropTypes.number,
        maxNumberOfContainersPerVan: PropTypes.number,
        productType: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        tempRange: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

const defaultProps = {
    value: undefined,
    disabled: false,
}

const Inputs = (props) => {
    const {
        value,
        onChange,
        onDelete,
        disabled,
    } = props

    const {
        classes,
    } = useStyles()

    const Input = useMemo(() => {
        if (!value) {
            return []
        }
        const valueWithDefaults = {
            maxNumberOfContainersPerTruck: undefined,
            maxNumberOfContainersPerVan: undefined,
            ...value,
        }

        return Object.entries(pick(valueWithDefaults, [
            'maxNumberOfContainersPerTruck',
            'maxNumberOfContainersPerVan',
        ])).map(([
            name,
            val,
        ]) => {
            return {
                name,
                val,
                type: 'number',
            }
        })
    }, [value])

    const handleChange = useCallback((newValue, {
        target: {
            name,
        },
    }) => {
        const update = {
            ...value,
            [name]: newValue,
        }

        onChange(update)
    }, [
        value,
        onChange,
    ])

    const handleOnDelete = useCallback(() => {
        onDelete(value.id)
    }, [
        value,
        onDelete,
    ])

    return (
        <TableRow>
            <TableCell
                classes={{
                    root: clsx(classes.tableCell, classes.productType),

                }}
            >
                <ProductTypeSelector
                    name="productType"
                    className={classes.input}
                    value={value?.productType}
                    onChange={handleChange}
                    useDropdown
                    tempRange={value?.tempRange}
                    disabled={disabled}
                />
            </TableCell>
            {Input.map((currentInput) => {
                return (
                    <TableCell
                        classes={{
                            root: classes.tableCell,
                        }}
                        key={`currentInput.name-${currentInput.name}`}
                    >
                        <InputBase
                            value={currentInput.val}
                            name={currentInput.name}
                            onChange={handleChange}
                            disabled={disabled}
                            type={currentInput.type}
                            autoComplete="off"
                            data-testid={`inputBase-${currentInput.name}`}
                            classes={{
                                root: classes.inputBase,
                            }}
                            inputProps={{
                                className: classes.input,
                            }}
                        />
                    </TableCell>
                )
            })}
            <TableCell
                classes={{
                    root: classes.tableCell,
                }}
            >
                {!disabled
                    && (
                        <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={handleOnDelete}
                        >
                            Delete
                        </DeleteIcon>
                    )}
            </TableCell>
        </TableRow>
    )
}

Inputs.propTypes = propTypes
Inputs.defaultProps = defaultProps

export default Inputs
