import React, {
    useState,
    useMemo,
    useEffect,
    useCallback,
} from 'react'

import containerTemperatureRanges from 'app/utils/containerTemperatureRanges'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    dayPassedToRange,
} from 'app/utils/date'
import {
    LoggerType,
} from 'app/types/enums'

import {
    Container,
    LoggerContainerInfo,
} from '../../containers.types'
import SensorData from './SensorData'
import defaultConfigLoggers from './utils'

type Props = {
    data: Container,
    loggerContainerInformation: LoggerContainerInfo[],
    serialNumber: string,
}
const ContainerSensorData = ({
    data,
    loggerContainerInformation,
    serialNumber,
}: Props) => {
    const loggerTypesEnums = useEnumValues()(LoggerType)
    const {
        id,
        tempRange,
        lastMeasuredTempInternal,
        lastMeasuredTempAmbient,
        lastMeasuredOnUtcInternal,
        lastMeasuredOnUtcAmbient,
        loggerExchangedOnUtcAmbient,
        loggerExchangedOnUtcInternal,
        productType,
    } = data

    const [
        configLoggers,
        setConfigLoggers,
    ] = useState(() => {
        const {
            loggerTypesOptions, loggerPositionsOptions,
        } = loggerContainerInformation.reduce((acc, currentLogger) => {
            acc.loggerTypesOptions.add(currentLogger.logger.loggerType)
            currentLogger.sensorPositions.forEach((p) => {
                return acc.loggerPositionsOptions.add(p)
            })

            return acc
        }, {
            loggerTypesOptions: new Set(),
            loggerPositionsOptions: new Set(),
        })

        return defaultConfigLoggers(
            Array.from(loggerTypesOptions).map((currentOption: LoggerType) => {
                return {
                    value: currentOption,
                    label: loggerTypesEnums[currentOption],
                }
            }), Array.from(loggerPositionsOptions),
        )
    })

    const [
        timeRange,
        setTimeRange,
    ] = useState(dayPassedToRange(7))

    const [
        showTempRange,
        onCheckShowTempRange,
    ] = useState(false)

    const [
        temperatureChartFullscreen,
        setTemperatureChartFullscreen,
    ] = useState(false)

    const [
        isChartPrinting,
        setChartPrinting,
    ] = useState(false)

    const [
        isDateRange,
        setDateRange,
    ] = useState(false)

    const beforePrintHandler = useCallback(() => {
        setChartPrinting(true)
    }, [])

    const afterPrintHandler = useCallback(() => {
        setChartPrinting(false)
    }, [])

    useEffect(() => {
        window.addEventListener('beforeprint', beforePrintHandler)
        window.addEventListener('afterprint', afterPrintHandler)

        return () => {
            window.removeEventListener('beforeprint', beforePrintHandler)
            window.removeEventListener('afterprint', afterPrintHandler)
        }
    }, [
        afterPrintHandler,
        beforePrintHandler,
    ])

    const printChart = useCallback(() => {
        window.print()
    }, [])

    const temperatureRange = useMemo(() => {
        return containerTemperatureRanges[tempRange]
    }, [tempRange])

    const [
        showDoorEvent,
        onCheckShowDoorEvent,
    ] = useState(false)

    const [
        showVirtualLogger,
        setShowVirtualLogger,
    ] = useState(false)

    return (
        <>
            {
                id && configLoggers ? (
                    <SensorData
                        productType={productType}
                        serialNumber={serialNumber}
                        lastMeasuredTempInternal={lastMeasuredTempInternal}
                        lastMeasuredTempAmbient={lastMeasuredTempAmbient}
                        lastMeasuredOnUtcInternal={lastMeasuredOnUtcInternal}
                        lastMeasuredOnUtcAmbient={lastMeasuredOnUtcAmbient}
                        loggerExchangedOnUtcAmbient={loggerExchangedOnUtcAmbient}
                        loggerExchangedOnUtcInternal={loggerExchangedOnUtcInternal}
                        isChartPrinting={isChartPrinting}
                        printChart={printChart}
                        isDateRange={isDateRange}
                        setDateRange={setDateRange}
                        showTempRange={showTempRange}
                        onCheckShowTempRange={onCheckShowTempRange}
                        timeRange={timeRange}
                        setTimeRange={setTimeRange}
                        temperatureChartFullscreen={temperatureChartFullscreen}
                        setTemperatureChartFullscreen={setTemperatureChartFullscreen}
                        configLoggers={configLoggers}
                        setConfigLoggers={setConfigLoggers}
                        temperatureRange={temperatureRange}
                        showDoorEvent={showDoorEvent}
                        onCheckShowDoorEvent={onCheckShowDoorEvent}
                        showVirtualLogger={showVirtualLogger}
                        setShowVirtualLogger={setShowVirtualLogger}
                        loggerContainerInformation={loggerContainerInformation}
                    />
                ) : null
            }
        </>
    )
}

export default ContainerSensorData
