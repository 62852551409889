import {
    RolesTypes,
} from 'app/utils/hasPermission'

const PROSPECT_READ_ONLY_LIST: RolesTypes[] = [
    'location_airlines',
    'location_bil',
    'location_ops',
    'location_ts',
    'location_prd',
    'location_rnd',
    'location_pmo',
    'location_cs_super',
    'location_bil_super',
    'location_ops_super',
    'location_ts_super',
    'location_pmo_super',
    'location_read',
]

export default PROSPECT_READ_ONLY_LIST
