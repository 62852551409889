/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import FxRateSelect from 'app/shared-components/FxRateSelect'
import FxRateContainer from 'app/shared-components/FxRate'

import useStyles from './FxRateWithSelect.styles'

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    ccy: PropTypes.string,
}

const defaultProps = {
    value: null,
    ccy: null,
}

const FxRateWithSelect = (props) => {
    const {
        value,
        ccy,
    } = props

    const {
        classes,
    } = useStyles()

    return (
        <>
            <div className={classes.select}>
                <FxRateSelect
                    {...props}
                />
            </div>
            {value && (
                <FxRateContainer
                    fxRateId={value}
                    keepInput
                    ccy={ccy}
                />
            )}
        </>
    )
}

FxRateWithSelect.propTypes = propTypes
FxRateWithSelect.defaultProps = defaultProps

export default FxRateWithSelect
