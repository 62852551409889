import React from 'react'
import noop from 'lodash/noop'

import CompanySelector from './CompanySelector'
import useGetCompanyRequests from './hooks/useGetCompanyRequests'

type Props = {
    title?: string,
    value?: string | number,
    disabled?: boolean,
    onChange?: (value: any, ...params: any[]) => void,
    isRequired?: boolean,
}

const defaultProps: Partial<Props> = {
    title: '',
    value: undefined,
    disabled: false,
    onChange: noop,
    isRequired: false,
}

const CompanySelectorContainer = ({
    title,
    value,
    disabled,
    onChange,
    isRequired,
}:Props) => {
    const {
        loadOptions,
    } = useGetCompanyRequests()

    return (
        <CompanySelector
            loadOptions={loadOptions}
            title={title}
            value={value}
            disabled={disabled}
            onChange={onChange}
            isRequired={isRequired}
        />
    )
}

CompanySelectorContainer.defaultProps = defaultProps

export default CompanySelectorContainer
