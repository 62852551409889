import {
    ContainerNoteType,
} from 'app/types/enums'

import noteFields from './utils/note.fields'
import generalStatusFields from './utils/generalStatus.fields'
import containerStatusFields from './utils/containerStatus.fields'
import loggerExchangeFields from './utils/loggerExchange.fields'
import locationFields from './utils/location.fields'

function getFields(noteType) {
    switch (noteType) {
    case ContainerNoteType.NOTE:
        return noteFields
    case ContainerNoteType.GENERAL_STATUS_CHANGE:
        return generalStatusFields
    case ContainerNoteType.CONTAINER_STATUS_CHANGE:
        return containerStatusFields
    case ContainerNoteType.LOGGER_EXCHANGE:
        return loggerExchangeFields
    case ContainerNoteType.CONTAINER_LOCATION_CHANGE:
        return locationFields
    default:
        return noteFields
    }
}

export default getFields
