import React, {
    useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'

import getFields from './StorageForm.fields'
import useStyles from './StorageForm.style'
import {
    StorageTableType,
} from '../../../serviceCenters.types'

type StorageFormProps = {
    value?: StorageTableType,
    onChange: (storage: StorageTableType) => void,
}
const defaultProps: Partial<StorageFormProps> = {
    value: {} as StorageTableType,
}

const StorageForm = ({
    value,
    onChange,
}: StorageFormProps) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
        })
    }, [getAllowedValues])

    return (
        <div
            className={classes.root}
        >
            <DomainObjectForm
                name="ServiceCenter"
                value={value}
                fields={fields}
                className={classes.gridWrapper}
                onChange={onChange}
            />
        </div>
    )
}

StorageForm.defaultProps = defaultProps

export default StorageForm
