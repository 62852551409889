import {
    makeStyles,
} from 'app/tss'

const transportMode = 'transportMode'
const checkbox = 'checkbox'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            rowGap: 0,
            columnGap: theme.spacing(1.5),
            gridTemplateAreas: `
            "${transportMode} ${checkbox}"
            "${transportMode} ${checkbox}"`,
            gridTemplateColumns: 'repeat(2, 2fr)',
        },
        transportMode: {
            gridArea: transportMode,
            gridTemplateRows: 'repeat(1, 2fr)',
        },
        checkbox: {
            paddingTop: theme.spacing(1),
            paddingBottom: 0,
        },
    }
})

export default useStyles
