import {
    makeStyles,
} from 'app/tss'

const internalTransport = 'internalTransport'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridTemplateAreas: `"${internalTransport}"`,
            gridGap: theme.spacing(2),
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        numberOfTrucks: {
            '&&': {
                width: '50%',
                paddingRight: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        numberOfVans: {
            '&&': {
                width: '50%',
                paddingLeft: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
    }
})

export default useStyles
