import {
    OrderStep,
    StepStatus,
    StepType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    TimeStampDataType,
} from '../../OrderSteps.types'

const getTimeStampData = ({
    pickupLocation,
    location,
    actualPickup,
    estimatedPickup,
    requestedPickup,
    actualDelivery,
    estimatedDelivery,
    requestedDelivery,
    actualExecution,
    estimatedExecution,
    requestedExecution,
    originAirport,
    actualFlightDeparture,
    estimatedFlightDeparture,
    actualFlightArrival,
    estimatedFlightArrival,
    originSeaport,
    actualShipDeparture,
    actualShipArrival,
    stepStatus,
    stepType,
}: OrderStep): TimeStampDataType[] => {
    const data = []
    const formatTimeStamp = ({
        actual,
        estimated,
        requested,
        isCompleted,
    }: {
        actual: string,
        estimated: string,
        requested?: string,
        isCompleted?: boolean,
    }): TimeStampDataType => {
        const isActual = Boolean(actual) || stepStatus === StepStatus.CLOSED || isCompleted

        return {
            date: actual || estimated || requested,
            iconPath: `assets/images/orderSteps/${isActual ? 'clock_blue' : 'clock'}.svg`,
        }
    }

    if (pickupLocation) {
        data.push(formatTimeStamp({
            actual: actualPickup,
            estimated: estimatedPickup,
            requested: requestedPickup,
            isCompleted: Boolean(actualFlightDeparture || actualShipDeparture),
        }))
    }

    if (location) {
        data.push(formatTimeStamp({
            actual: actualExecution,
            estimated: estimatedExecution,
            requested: requestedExecution,
        }))
    }

    if (stepType === StepType.AIR && originAirport) {
        data.push(formatTimeStamp({
            actual: actualFlightDeparture,
            estimated: estimatedFlightDeparture,
        }))
        data.push(formatTimeStamp({
            actual: actualFlightArrival,
            estimated: estimatedFlightArrival,
        }))
    }

    if (stepType === StepType.SEA && originSeaport) {
        data.push(formatTimeStamp({
            actual: actualShipDeparture,
            estimated: null,
        }))
        data.push(formatTimeStamp({
            actual: actualShipArrival,
            estimated: null,
        }))
    }

    if (pickupLocation) {
        data.push(formatTimeStamp({
            actual: actualDelivery,
            estimated: estimatedDelivery,
            requested: requestedDelivery,
            isCompleted: Boolean(actualFlightArrival || actualShipArrival),
        }))
    }

    return data
}

export default getTimeStampData
