import {
    createSlice,
    createSelector,
} from '@reduxjs/toolkit'
import type {
    RootState,
} from 'app/store/index'

type ConfigStore = Record<string, any>

const initialState = {} as ConfigStore

const ConfigsSlice = createSlice({
    name: 'configs',
    initialState,
    reducers: {
        setConfig(state, {
            payload: config,
        }) {
            return {
                ...state,
                ...config,
            }
        },
    },
})

export const {
    setConfig,
} = ConfigsSlice.actions

const selectConfig = (state: RootState): ConfigStore => {
    return state.configs
}

const getName = (state, name: string) => {
    return name
}

// TODO-DK refactor it to pass options params
export const isConfigSet = createSelector(
    [
        selectConfig,
        getName,
    ],
    (state: ConfigStore, name) => {
        return Boolean(state?.[name])
    },
)

const getField = (state, name, field: string) => {
    return field
}

// TODO-DK refactor it to pass options params
export const selectConfigByFieldName = createSelector(
    [
        selectConfig,
        getName,
        getField,
    ],
    (state: ConfigStore, name, field) => {
        return state?.[name]?.[field]
    },
)

export default ConfigsSlice.reducer
