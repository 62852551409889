import React, {
    useMemo,
    useState,
    useCallback,
} from 'react'
import {
    useJWTToken,
} from 'app/Auth'

import InputSingleline from 'app/shared-components/InputSingleline'
import Select from 'app/shared-components/Select'
import DTOSpec from 'app/services/dtoSpec'
import simpleApi from 'app/services/simpleApi'
import Typeahead from 'app/shared-components/Typeahead'
import {
    OrderTemperatureRange,
} from 'app/types/enums'
import useGetContainer from 'app/Apps/ContainerManagement/ContainerTypes/hooks/useGetContainerType'

import {
    ContainerType,
} from '../ContainerTypes.types'

import useStyles from './AddForm.styles'

type Props = {
    data?: ContainerType[],
    temperatureRange?: Omit<OrderTemperatureRange, 'BT'>,
    addRequiredContainers: ({
        quantity,
        containerType,
    }) => void,
    required?: boolean,
}
const defaultProps: Partial<Props> = {
    data: [],
    temperatureRange: undefined,
    required: false,
}

const AddForm = ({
    data,
    temperatureRange,
    addRequiredContainers,
    required,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const token = useJWTToken()

    const loadOptions = useCallback(({
        phrase,
    }) => {
        return simpleApi({
            url: DTOSpec.ContainerType.Selector,
            domainName: DTOSpec.ContainerType.domainName,
            method: 'POST',
            token,
            data: {
                searchAll: phrase,
                sortField: 'containerModel',
                sortType: 'asc',

            },
        }).then(({
            items: resp,
        }) => {
            return resp.map(({
                containerModel, id,
            }) => {
                return {
                    label: containerModel,
                    value: id,
                    id,
                }
            })
        })
    }, [token])

    const options = useMemo(() => {
        return data.reduce((acc, element) => {
            return {
                ...acc,
                [element.id]: element.containerModel,
            }
        }, {})
    }, [data])

    const [
        formValue,
        setFormValue,
    ] = useState({
        amount: 1,
        containerTypeId: data[0]?.id,
    })

    const {
        data: labelData,
    } = useGetContainer(formValue.containerTypeId)

    const onAmountChange = useCallback((value) => {
        setFormValue({
            ...formValue,
            amount: Number(value),
        })
    }, [formValue])

    const onContainerTypeChange = useCallback((containerTypeId) => {
        setFormValue({
            ...formValue,
            containerTypeId,
        })
    }, [formValue])

    const add = useCallback(() => {
        const {
            containerTypeId,
            amount,
        } = formValue

        const containerType = data.find(({
            id,
        }) => {
            return id === Number(containerTypeId)
        })

        addRequiredContainers({
            quantity: amount,
            containerType,
        })
    }, [
        addRequiredContainers,
        data,
        formValue,
    ])

    return (
        <div
            className={classes.root}
        >
            <div className={classes.amount}>
                <InputSingleline
                    name="amount"
                    classNames={{
                        label: classes.label,
                    }}
                    type="number"
                    centerAligned
                    onChange={onAmountChange}
                    value={formValue.amount}
                    required={required}
                />
            </div>
            <span className={classes.cross}>x</span>
            {
                temperatureRange === OrderTemperatureRange.ANY_TEMP_RANGE ? (
                    <Typeahead
                        name="ContainerType"
                        onChange={onContainerTypeChange}
                        loadOptions={loadOptions}
                        labelData={labelData}
                        labelField="containerModel"
                        classNames={{
                            label: classes.label,
                        }}
                        isRequired={required}
                        value={formValue.containerTypeId}
                    />
                ) : (
                    <Select
                        name="containerType"
                        allowedValues={options}
                        value={formValue.containerTypeId}
                        onChange={onContainerTypeChange}
                        required={required}
                        className={{
                            formControl: classes.select,
                        }}
                    />
                )
            }
            <div
                data-testid="add-btn"
                className={classes.control}
                onMouseDown={add}
            >
                +
            </div>
        </div>
    )
}

AddForm.defaultProps = defaultProps

export default AddForm
