import getUniqueId from 'app/utils/getUniqueId'

const config = (l) => {
    return {
        leaseFees: {
            columns: [
                {
                    label: l('OrderBilling.baseLeaseDays'),
                    id: getUniqueId(),
                },
                {
                    label: l('Fee.productType'),
                    id: getUniqueId(),
                },
                {
                    label: l('GeneralPricing.baseLease'),
                    id: getUniqueId(),
                },
            ],
        },
        deliveryCollectionFees: {
            columns: [
                {
                    label: l('LocationDto.locationName'),
                    id: getUniqueId(),
                    width: '20%',
                },
                {
                    label: l('ContainerView.tempRange'),
                    id: getUniqueId(),
                    width: '20%',
                },
                {
                    label: l('GeneralPricing.deliveryFeeLTL'),
                    id: getUniqueId(),
                    width: '10%',
                },
                {
                    label: l('GeneralPricing.deliveryFeeFTL'),
                    id: getUniqueId(),
                    width: '10%',
                },
                {
                    label: l('GeneralPricing.pickup'),
                    id: getUniqueId(),
                    width: '10%',
                },
                {
                    label: l('GeneralPricing.dropoffCollectionFee'),
                    id: getUniqueId(),
                    width: '10%',
                },
                {
                    label: l('OrderBilling.expressDays'),
                    id: getUniqueId(),
                    width: '10%',
                },
                {
                    label: l('OrderBilling.postponementDays'),
                    id: getUniqueId(),
                    width: '10%',
                },
            ],
        },
        otherFees: {
            columns: [
                {
                    label: l('FeeDto.feeName'),
                    id: getUniqueId(),
                },
                {
                    label: l('GeneralPricing.chargeability'),
                    id: getUniqueId(),
                },
                {
                    label: l('Fee.productType'),
                    id: getUniqueId(),
                },
                {
                    label: l('Position.positionDescription'),
                    id: getUniqueId(),
                },
                {
                    label: l('GeneralPricing.value'),
                    id: getUniqueId(),
                },
            ],
        },
    }
}

export default config
