/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import TableControls from 'app/shared-components/TableControls'
import AddEntry from 'app/shared-components/AddEntry'
import PrintLabel from 'app/shared-components/PrintLabel'

import useStyles from './ControlPanel.styles'

const propTypes = {
    hasCreate: PropTypes.bool,
    hasPrint: PropTypes.bool,
    url: PropTypes.string,
    tableControls: PropTypes.arrayOf(PropTypes.string),
    simple: PropTypes.bool,
}

const defaultProps = {
    hasCreate: false,
    hasPrint: false,
    url: '',
    tableControls: [],
    simple: false,
}

const ControlPanel = (props) => {
    const {
        url,
        tableControls,
        hasCreate,
        hasPrint,
        simple,
    } = props

    const {
        classes,
    } = useStyles()

    const addEntryElement = hasCreate ? (
        <AddEntry
            to={`${url}/create`}
        />
    ) : null

    const addPrintElement = hasPrint ? (
        <PrintLabel
            to={`${url}/print`}
        />
    ) : null

    const tableControlsTab = (
        <TableControls
            {...props}
            simple={simple}
            controls={tableControls}
        />
    )

    return (
        <div className={classes.root}>
            <div className={classes.tabsWrapper}>
                {addEntryElement}
                {addPrintElement}
            </div>
            {tableControlsTab}
        </div>
    )
}

ControlPanel.propTypes = propTypes
ControlPanel.defaultProps = defaultProps

export default ControlPanel
