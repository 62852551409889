import React from 'react'
import {
    Typography,
} from '@mui/material'

import formatCurrencyValue from 'app/utils/formatCurrencyValue'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Frequency,
} from 'app/types/enums'

import useStyles from './FxRate.styles'
import InputSingleline from '../InputSingleline/InputSingleline'
import FxRateType from './FxRate.types'

type Props = {
    exchangeValues: {
        from?: number,
        to?: number,
    },
    value?: FxRateType,
    onChange: (val: string, event: React.ChangeEvent<HTMLInputElement>) => void,
}

const defaultProps: Partial<Props> = {
    value: {
        exchangeRate: 1,
    },
}

const fxRateNotFoundText = "Ouups, we're sorry - it seems that we don't have the FX Rates we should have!"

const FxRate = ({
    value,
    exchangeValues,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const fxRateTypeAllowedValues = useEnumValues()(Frequency)

    return (
        <div className={classes.root}>
            {
                value?.id ? (
                    <>
                        <Typography
                            id="data-testid"
                            className={classes.text}
                        >
                            {`1 ${value?.ccyFrom} = ${formatCurrencyValue(value?.exchangeRate)} ${value?.ccyTo}.
                    ${fxRateTypeAllowedValues[value?.fxRateType]} average,
                    valid from ${value?.validFrom} to ${value?.validTo},
                    uploaded by ${value?.changedBy || value?.createdBy || 'Anonym'} on ${value?.changedOn || value?.createdOn || '-'}.`}
                        </Typography>
                        <div className={classes.exchangeExamples}>
                            <div>
                                <InputSingleline
                                    title={value?.ccyFrom}
                                    value={exchangeValues?.from}
                                    name="from"
                                    type="number"
                                    onChange={onChange}
                                />
                            </div>
                            <div>
                                <InputSingleline
                                    title={value?.ccyTo}
                                    value={exchangeValues?.to}
                                    name="to"
                                    type="number"
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classes.notFoundText}>
                            {fxRateNotFoundText}
                        </div>
                        <div className={classes.notFoundImage}>
                            <img
                                height={160}
                                alt="not found"
                                src="/assets/images/not_found_alternative.png"
                            />
                        </div>
                    </>
                )
            }
        </div>
    )
}

FxRate.defaultProps = defaultProps

export default FxRate
