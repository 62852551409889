import React, {
    useCallback, useEffect, useState,
} from 'react'
import isEqual from 'lodash/isEqual'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Button from 'app/shared-components/Button'

import QuoteInformationForm from '../../QuoteInformationForm'
import {
    AccountQuote,
} from '../../AccountQuotes.types'
import useStyles from '../AccountQuoteCreate.styles'
import useAccountQuoteInformationContext from '../AccountQuoteInformationContext'

const AccountQuoteInformationCreate = ({
    data,
    disabled,
}: {
    data: AccountQuote,
    disabled: boolean
}) => {
    const {
        classes,
    } = useStyles()
    const {
        activeStep,
        setActiveStep,
        setValue,
        initialValue,
        setIsEdited,
    } = useAccountQuoteInformationContext()

    const [
        formValues,
        setFormValues,
    ] = useState<AccountQuote>(data)

    const nextAction = useCallback(() => {
        setValue(formValues)
        setActiveStep(activeStep + 1)
    }, [
        formValues,
        setValue,
        setActiveStep,
        activeStep,
    ])

    useEffect(() => {
        if (!isEqual(data, formValues)) {
            setIsEdited(true)
        }
    }, [
        setIsEdited,
        formValues,
        data,
    ])

    const isCreate = !initialValue

    return (
        <QuoteInformationForm
            disabled={disabled}
            value={formValues}
            setValue={setFormValues}
            omitField={[
                'customerQuotation',
                'pricingResponsible',
                'plannedContractBasis',
            ]}
            render={({
                fields, formData, onChange, isValid,
            }) => {
                return (
                    <div>
                        <DomainObjectForm
                            name="AccountQuoteInformation"
                            value={formData}
                            fields={fields}
                            onChange={onChange}
                            disabled={disabled}
                        />
                        {isCreate && (
                            <div className={classes.buttonWrapper}>
                                <Button
                                    label="Next"
                                    onClick={nextAction}
                                    className={classes.button}
                                    disabled={disabled || !isValid}
                                    data-testid="btn-next"
                                />
                            </div>
                        )}
                    </div>
                )
            }}
        />
    )
}

export default AccountQuoteInformationCreate
