import React from 'react'
import moment from 'moment'

import {
    strToDate,
} from 'app/utils/date'

import useStyles from './PoolingEstimatedPickup.style'

const PoolingEstimatedPickup = ({
    value,
}: {
    value: string,
}) => {
    const {
        classes,
    } = useStyles()

    if (!value) {
        return null
    }

    const diff = moment().diff(moment(strToDate(value), 'X'), 'hours')

    const color = diff > 24 ? classes.red : classes.grey

    return (<div className={color}>{value}</div>)
}

export default PoolingEstimatedPickup
