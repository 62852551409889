import {
    useCallback, useMemo,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useDispatch,
} from 'react-redux'
import {
    useJWTToken,
} from 'app/Auth'
import {
    RequestKeys, RequestFn,
} from 'app/hooks/useRequest'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    OpportunityType,
} from '../opportunities.types'
import requestConfig from '../opportunities.request'

export default (): (i: number) => Promise<OpportunityType> => {
    const queryClient = useQueryClient()
    const token = useJWTToken()
    const dispatch = useDispatch()

    const {
        onError,
    } = useHandleStatusRequest({
        id: 'OpportunityByIdFetch',
    })

    const queryFn = useMemo(() => {
        return RequestFn.getEntity(requestConfig.GetOpportunityById)(dispatch)
    }, [dispatch])

    return useCallback(async (id) => {
        let data: OpportunityType

        const queryKey = [
            RequestKeys.getOpportunityById,
            {
                id,
                token,
            },
        ]

        try {
            data = await queryClient.fetchQuery({
                queryKey,
                queryFn,
                staleTime: 100000,
            })
        } catch (error) {
            onError(error)
        }

        return data
    }, [
        onError,
        queryClient,
        queryFn,
        token,
    ])
}
