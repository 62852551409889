import React from 'react'

import {
    OrderStep, StepConfiguration,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    SubstepName,
} from 'app/types/enums'

import useGetCurrentStepProgress from '../../hooks/useGetCurrentStepProgress'
import OrderStepsForm from './OrderStepsForm'
import config from './OrderStepsForm.request'

type Props = {
    value: Partial<OrderStep>,
    onChange: (changeValue: OrderStep) => void,
    location?: {id: number},
    pickupLocationContacts?: {id: number}[],
    baseLeaseUntilTimestamp?: string,
    disabled?: boolean,
    isModified?: boolean,
    exists?: boolean,
    isFirstStep?: boolean,
    setIsFormValid?: (isFormValid: boolean) => void,
}

const defaultProps: Partial<Props> = {
    location: undefined,
    pickupLocationContacts: undefined,
    baseLeaseUntilTimestamp: undefined,
    setIsFormValid: undefined,
    disabled: false,
    isModified: false,
    exists: false,
    isFirstStep: false,
}

const OrderStepsFormContainer = (props: Props) => {
    const {
        value,
        location,
        onChange,
        disabled,
        exists,
        isModified,
        baseLeaseUntilTimestamp,
        pickupLocationContacts,
        isFirstStep,
        setIsFormValid,
    } = props

    const {
        stepProgress,
    } = value

    const currentStepProgress: SubstepName = useGetCurrentStepProgress(stepProgress)

    const {
        data: stepConfiguration,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<StepConfiguration>({
        key: RequestKeys.getStepConfiguration,
        params: {
            stepTypeDetailed: value.stepTypeDetailed,
            stepProgress: currentStepProgress,
        },
        requestFunc: RequestFn.getEntity(config.StepConfiguration.requestFn),
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <OrderStepsForm
                value={value}
                location={location}
                onChange={onChange}
                stepConfiguration={stepConfiguration?.[0]}
                baseLeaseUntilTimestamp={baseLeaseUntilTimestamp}
                pickupLocationContacts={pickupLocationContacts}
                disabled={disabled}
                isModified={isModified}
                exists={exists}
                isFirstStep={isFirstStep}
                setIsFormValid={setIsFormValid}
            />
        </StatusHandler>
    )
}

OrderStepsFormContainer.defaultProps = defaultProps

export default OrderStepsFormContainer
