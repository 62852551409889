import {
    RequestConfig,
} from 'app/types/request.types'

type ContactsRequests = {
    get: ({
        id,
        params,
    }) => RequestConfig,
}

const contactsRequest: ContactsRequests = {
    get: ({
        id,
        params,
    }) => {
        return {
            method: 'POST',
            url: 'contact/filter-request/all',
            includeFilters: {
                locationId: [id],
            },
            ...params,
        }
    },
}

export default contactsRequest
