import React from 'react'

import {
    ModificationRequiredContainersType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import useLoadDtoForSkyNetTable from 'app/shared-components/SkyNetTable/hooks/useLoadDtoForSkyNetTable'

import columns from './requiredContainersChanges.columns'
import useStyles from './RequiredContainersChanges.style'

const RequiredContainersChanges = ({
    orderNumber,
}: {orderNumber: string}) => {
    const {
        classes,
    } = useStyles()

    const load: (params: any) => Promise<{
        items: ModificationRequiredContainersType[],
        matches: number,
    }> = useLoadDtoForSkyNetTable(
        orderManagementRequest.RequiredContainersChanges.request(orderNumber),
    )

    return (
        <RichTable
            configName="RequiredContainersChangesTable"
            name={orderManagementRequest.RequiredContainersChanges.name}
            load={load}
            columns={columns}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            uniqField="id"
        />
    )
}

export default RequiredContainersChanges
