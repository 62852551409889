import React, {
    useCallback,
} from 'react'
import Link from '@mui/material/Link'
import {
    useRouteMatch, useHistory,
} from 'react-router-dom'
import useStyles from './BulkEditIcon.style'

const defaultProps = {
    onClick: undefined,
}

const BulkEditIcon = () => {
    const {
        classes,
    } = useStyles()

    const {
        url,
    } = useRouteMatch()
    const history = useHistory()

    const redirectToBulkEdit = useCallback(() => {
        history.push(`${url}/bulk-edit`)
    }, [
        url,
        history,
    ])

    return (
        <Link
            className={classes.link}
            component="button"
            color="secondary"
            onClick={redirectToBulkEdit}
        >
            <img
                className={classes.icon}
                src="assets/images/icons/bulk_edit.svg"
                alt="Bulk Edit"
            />
        </Link>
    )
}

BulkEditIcon.defaultProps = defaultProps

export default BulkEditIcon
