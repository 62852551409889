import React from 'react'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useTableControlsByPermissions from 'app/hooks/useTableControlsByPermissions'
import request from './AccountLanes.requests'
import tableConfig from './AccountLanes.config'
import AccountLaneCreate from './AccountLaneCreate'
import AccountLaneOverview from './AccountLaneOverview'
import AccountLaneUpdate from './AccountLaneUpdate'

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: AccountLaneOverview,
    activeComparer: (pathname) => {
        return pathname.split('/').includes('overview')
    },
}

const dataUpdateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data-update',
    Component: AccountLaneUpdate,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.BulkCreate,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Accounts = () => {
    const createPermission = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const updatePermission = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: dataUpdateTab,
            permission: updatePermission,
        },
    ])

    const restrictedTableControls = useTableControlsByPermissions(tableControls, {
        [TableControlTypes.BulkCreate]: createPermission,
    })

    return (
        <SkyNetTable
            createForm={createPermission
                ? AccountLaneCreate
                : undefined}
            name={request.domainName}
            customUrl={request.getAll}
            tabs={tabs}
            defaultTab="overview"
            tableControls={restrictedTableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Accounts
