import {
    FunctionComponent, SVGProps,
    useMemo,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'
import {
    App,
} from 'app/types/common.enums'

import mainMenuConfig from 'app/mainMenuConfig'

type Link = {
    title: string,
    id: string,
    auth?: string[],
    url: string,
}

export type ActiveTab = {
    title: string,
    id: string,
    subTitleText: string,
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string; }>,
    className?: string,
    links: Link[],
}

const useSelectedTab = () => {
    const {
        pathname,
    } = useLocation()

    const pathSplitted = pathname.split('/')

    const activeTab: ActiveTab | undefined = useMemo(() => {
        if (Object.values(App).includes(pathSplitted[2])) {
            return mainMenuConfig.find(({
                id,
            }) => {
                return id === pathSplitted[2]
            })
        }

        return mainMenuConfig.find(({
            links,
        }) => {
            return links.find(({
                id,
            }) => { return id === pathSplitted[2] })
        })
    }, [pathSplitted])

    const selectedTab: ActiveTab | {
        icon: () => null,
        links: [],
    } = useMemo(() => {
        return activeTab || {
            icon: () => { return null },
            links: [],
        }
    }, [activeTab])

    return selectedTab
}

export default useSelectedTab
