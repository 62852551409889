import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'

type ContactTable = Contact & {
    countryCountryName: string,
}

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof ContactTable>[] => {
    return [
        {
            id: 'contactName',
            headerProps: {
                children: l('billingContacts.billingContactName'),
            },
            mapCellProps: 'contactName',
        },
        {
            id: 'email',
            headerProps: {
                children: l('BillingContactUpdateDto.email'),
            },
            mapCellProps: 'email',
        },
        {
            id: 'isMainContact',
            headerProps: {
                children: l('Contact.isMainContact'),
            },
            mapCellProps: 'isMainContact',
            filterField: 'isMainContact',
            filterType: FilterType.Array,
        },
        {
            id: 'countryCountryName',
            headerProps: {
                children: l('Country.basedInCountry'),
            },
            mapCellProps: ({
                isProspect,
                countryCountryName,
                locationCountryCountryName,
            }) => {
                return {
                    children: isProspect ? countryCountryName : locationCountryCountryName,
                }
            },
        },
        {
            id: 'locationLocationName',
            headerProps: {
                children: l('Contact.location'),
            },
            mapCellProps: 'locationLocationName',
            filterField: 'locationLocationName',
            filterType: FilterType.Array,
        },
        {
            id: 'position',
            headerProps: {
                children: l('Contact.position'),
            },
            mapCellProps: 'position',
        },
    ]
}

export default columns
