import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
        msg: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 10,
            padding: theme.spacing(1, 1.5),
            marginTop: theme.spacing(2),
        },
        icon: {
            paddingRight: theme.spacing(1),
        },
        errorMsg: {
            background: theme.palette.warning.main,
            color: theme.palette.common.red,
        },
        successMsg: {
            backgroundColor: theme.palette.primary[30],
            color: theme.palette.primary.dark,
        },
    }
})

export default useStyles
