import {
    UseRequestConfig,
} from 'app/types/request.types'
import DTOSpec from 'app/services/dtoSpec'

const config: UseRequestConfig = {
    requestFn: (params) => {
        return {
            url: DTOSpec.Contact.url,
            method: 'POST',
            data: {
                ...params,
            },
        }
    },
}

export default config
