const applyArchived = (cb) => {
    return (params) => {
        const {
            data: paramsData,
            archived,
        } = params

        let data = {
            ...paramsData,
        }

        if (archived) {
            data = {
                ...data,
                archived,
            }
        }

        return cb({
            ...params,
            data,
        })
    }
}

export default applyArchived
