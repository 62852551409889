/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    TextField,
    FormControl,
    FormHelperText,
} from '@mui/material'
import clsx from 'clsx'
import noop from 'lodash/noop'
import Label from 'app/shared-components/Label'
import filterFieldForInput from 'app/utils/filterFieldForInput'

import {
    TooltipWrapper,
} from 'app/shared-components/Tooltip'
import {
    ValidationResult,
} from 'app/types/form.types'
import {
    useTheme,
} from '@mui/material/styles'
import useStyles from './InputSingleline.styles'
import {
    NotificationIconProps,
} from '../NotificationIcon/notificationIcon.types'
import LabelPanel from '../LabelPanel'

type Props = {
    onChange?: (...args: any[]) => void,
    onKeyPress?: (...args: any[]) => void,
    onBlur?: (...args: any[]) => void,
    className?: string,
    classNames?: {
        label?: string,
        text?: string | Record<string, any>
    },
    title?: string,
    name?: string,
    value?: string | number,
    disabled?: boolean,
    required?: boolean,
    hasError?: string,
    type?: string,
    min?: number,
    max?: number,
    step?: number,
    fullWidth?: boolean,
    fullHeight?: boolean,
    rightAligned?: boolean,
    centerAligned?: boolean,
    suffix?: string,
    iconPath?: string,
    rows?: number,
    multiline?: boolean,
    iconTooltip?: string | JSX.Element,
    labelTooltip?: string | JSX.Element,
    placeholder?: string,
    validated?: boolean,
    errors?: ValidationResult,
    notificationIconProps?: NotificationIconProps,
}

const ownTooltipProps = {
    enterDelay: 500,
    placement: 'bottom-end',
}

const defaultProps: Partial<Props> = {
    onChange: noop,
    onKeyPress: noop,
    onBlur: noop,
    className: '',
    classNames: {},
    title: '',
    name: '',
    value: '',
    disabled: false,
    required: false,
    type: 'text',
    min: undefined,
    max: undefined,
    step: undefined,
    hasError: '',
    fullWidth: false,
    fullHeight: false,
    rightAligned: false,
    centerAligned: false,
    suffix: '',
    iconPath: undefined,
    rows: 1,
    multiline: false,
    iconTooltip: undefined,
    labelTooltip: undefined,
    placeholder: undefined,
    validated: undefined,
    notificationIconProps: undefined,
    errors: undefined,
}

const InputSingleline = (props: Props) => {
    const theme = useTheme()
    const {
        title,
        required,
        disabled,
        hasError,
        onChange,
        onBlur,
        onKeyPress,
        className,
        classNames,
        fullHeight,
        rightAligned,
        centerAligned,
        suffix,
        value,
        type,
        min,
        max,
        step,
        name,
        iconPath,
        rows,
        multiline,
        iconTooltip,
        labelTooltip,
        placeholder,
        errors,
        validated,
        notificationIconProps,
    } = props

    const {
        classes,
    } = useStyles({
        height: rows > 0
            ? `${rows * parseInt(String(theme.typography.body1.lineHeight), 10)}px !important`
            : undefined,
    })

    const defineValue = useCallback((newValue: string | number) => {
        if (type === 'number') {
            return newValue ? Number(newValue) : undefined
        }

        return newValue
    }, [type])

    const handleChange = useCallback(({
        target,
    }) => {
        const {
            value: newValue,
            name: inputName,
        } = target

        const inputValue = defineValue(newValue)

        onChange(inputValue, {
            target: {
                name: inputName,
                value: inputValue,
            },
        })
    }, [
        defineValue,
        onChange,
    ])

    const inputProps = useMemo(() => {
        return {
            classes: {
                input: clsx({
                    [classes.input]: true,
                    [classes.rightAligned]: rightAligned,
                    [classes.centerAligned]: centerAligned,
                    [classes.isRequired]: required && !disabled,
                    [classes.fullHeight]: fullHeight,
                    [classes.disabled]: disabled,
                }),
                root: clsx({
                    [classes.fullHeight]: fullHeight,
                    [classes.inputWithIcon]: iconPath,
                }),
                underline: clsx({
                    [classes.disabledUnderline]: disabled,
                }),
            },
            onBlur,
            inputProps: {
                min,
                max,
                step,
            },
        }
    }, [
        classes.disabled,
        classes.disabledUnderline,
        classes.fullHeight,
        classes.input,
        classes.inputWithIcon,
        classes.isRequired,
        classes.rightAligned,
        classes.centerAligned,
        disabled,
        fullHeight,
        iconPath,
        min,
        max,
        onBlur,
        required,
        rightAligned,
        centerAligned,
        step,
    ])

    const numberFocusHandler = useCallback((event) => {
        event.target.select()
    }, [])

    const optionalTextFieldProps = useMemo(() => {
        if (type === 'number') {
            return {
                onFocus: numberFocusHandler,
            }
        }
        return {}
    }, [
        type,
        numberFocusHandler,
    ])

    const iconClass = useMemo(() => {
        return clsx({
            [classes.icon]: !disabled,
            [classes.iconDisabled]: disabled,
        })
    }, [
        classes.iconDisabled,
        classes.icon,
        disabled,
    ])

    const renderIcon = (imgTitle: string): JSX.Element => {
        return (
            <img
                className={iconClass}
                src={iconPath}
                alt={imgTitle}
            />
        )
    }

    if (disabled) {
        return (
            <div
                className={className}
                data-testid={`inputSingleline${name}`}
            >
                <LabelPanel
                    validated={validated}
                    errors={errors}
                    notificationIconProps={notificationIconProps}
                >
                    <Label
                        title={title}
                        className={classNames.label}
                    />
                </LabelPanel>
                <div className={classes.wrapper}>
                    <TooltipWrapper
                        value={labelTooltip}
                        ownTooltipProps={ownTooltipProps}
                    >
                        <span
                            className={clsx(classes.text, classNames.text, className)}
                            data-testid={`inputSingleLine-${name}`}
                        >
                            {value || value === 0 ? value : '-'}
                        </span>
                    </TooltipWrapper>
                    {
                        iconPath && (
                            <TooltipWrapper
                                ownTooltipProps={ownTooltipProps}
                                value={iconTooltip}
                            >
                                {renderIcon(title)}
                            </TooltipWrapper>
                        )
                    }
                </div>
            </div>
        )
    }
    return (
        <FormControl
            error={Boolean(hasError)}
            className={clsx(classes.formControl, className)}
        >
            <LabelPanel
                validated={validated}
                errors={errors}
                notificationIconProps={notificationIconProps}
            >
                <Label
                    title={title}
                    className={classNames.label}
                />
            </LabelPanel>

            <div
                className={clsx(
                    {
                        [classes.textField]: true,
                        [classes.disabled]: disabled,
                        [classes.fullHeightTextField]: fullHeight,
                        [classes.isRequired]: required && !disabled,
                    },
                )}
            >
                {(required && !disabled) && (<div className={classes.requiredMark} />)}
                <TooltipWrapper
                    value={labelTooltip}
                    ownTooltipProps={ownTooltipProps}
                >
                    <TextField
                        {...filterFieldForInput(props, multiline)}
                        onChange={handleChange}
                        onKeyPress={onKeyPress}
                        InputProps={inputProps}
                        className={clsx({
                            [classes.inputControl]: true,
                            [classes.fullHeight]: fullHeight,
                            [classes.emptyValue]: !value && disabled,
                        })}
                        disabled={disabled}
                        fullWidth
                        data-testid={`inputSingleLine-${name}`}
                        variant="standard"
                        placeholder={placeholder}
                        {...optionalTextFieldProps}
                        onWheel={(event) => { event.currentTarget.querySelector('input')?.blur() }}
                    />
                </TooltipWrapper>
                {
                    suffix && (
                        <div className={classes.inputMeasure}>
                            <span className={classes.inputMeasureValue}>{value}</span>
                            <span>{suffix}</span>
                        </div>
                    )
                }
                {
                    iconPath && (
                        <TooltipWrapper
                            value={iconTooltip}
                            ownTooltipProps={ownTooltipProps}
                        >
                            {renderIcon(title)}
                        </TooltipWrapper>
                    )
                }
                {
                    hasError && (
                        <FormHelperText
                            error={Boolean(hasError)}
                            className={classes.errorMessage}
                        >
                            {hasError}
                        </FormHelperText>
                    )
                }
            </div>
        </FormControl>
    )
}

InputSingleline.defaultProps = defaultProps

export default InputSingleline
/* tslint:enable */
