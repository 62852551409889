import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            padding: theme.spacing(1),
            cursor: 'default',
        },
    }
})

export default useStyles
