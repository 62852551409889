import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        formControl: {
            width: '100%',
        },
        input: {
            fontSize: theme.typography.body1.fontSize,
        },
        select: {
            '&&': {
                width: '100%',
                color: `${theme.palette.secondary[600]} !important`,
                paddingTop: '3px !important',
                paddingLeft: theme.spacing(0.25),
            },
        },
        background: {
            backgroundColor: theme.palette.secondary[50],
        },
        isRequiredBackground: {
            backgroundColor: theme.palette.primary[30],
        },
        disabled: {
            '& .MuiInputBase-input': {
                backgroundColor: 'transparent !important',
            },
            '& .MuiInput-underline:before': {
                border: '0 !important',
            },
        },
        errorMessage: {
            height: 10,
        },
        selectContainer: {
            position: 'relative',
            width: '100%',
        },
        timeFormat: {
            width: '50%',
        },
        hideHelperText: {
            display: 'none',
        },
        isRequired: {
            backgroundColor: theme.palette.primary[30],
        },
    }
})

export default useStyles
