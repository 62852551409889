import React, {
    useMemo,
} from 'react'
import round from 'lodash/round'

import TableCell from '@mui/material/TableCell'

import currency from 'app/utils/currency'
import InputBase from 'app/shared-components/InputBase'
import InputIntegerNumbers from 'app/shared-components/InputIntegerNumbers'
import FxRate from 'app/shared-components/FxRate/FxRate.types'

import useStyles from './CurrencyConverter.styles'

type Props = {
    value?: {
        feeValue?: number,
    },
    onChange: () => void,
    disabled?: boolean,
    currency: string,
    params?: {
        exchangeRate?: FxRate,
    }
}

const defaultProps: Partial<Props> = {
    value: {
        feeValue: undefined,
    },
    disabled: false,
    params: {
        exchangeRate: undefined,
    },
}

const CurrencyConverter = ({
    value: {
        feeValue,
    },
    onChange,
    disabled,
    currency: selectedCurrency,
    params: {
        exchangeRate,
    },
}: Props) => {
    const {
        classes,
    } = useStyles()

    const amount = useMemo(() => {
        if (!exchangeRate?.exchangeRate) {
            return null
        }
        return round(Number(feeValue) / exchangeRate.exchangeRate)
    }, [
        feeValue,
        exchangeRate,
    ])

    return (
        <>
            {selectedCurrency
                && selectedCurrency !== currency.default
                && (
                    <TableCell
                        classes={{
                            root: classes.feeValue,
                        }}
                        data-testid="amount"
                    >
                        <div className={classes.fieldWrapper}>
                            <InputBase
                                value={amount}
                                inputProps={{
                                    className: classes.disabled,
                                }}
                                name="amount"
                                disabled
                            />
                        </div>
                    </TableCell>
                )}
            <TableCell
                classes={{
                    root: classes.amount,
                }}
                data-testid="feeValue"
            >
                <div className={classes.fieldWrapper}>
                    <InputIntegerNumbers
                        value={feeValue === null ? '' : feeValue}
                        inputProps={{
                            className: classes.input,
                        }}
                        name="feeValue"
                        disabled={disabled}
                        onChange={onChange}
                    />
                </div>
            </TableCell>
        </>
    )
}

CurrencyConverter.defaultProps = defaultProps

export default CurrencyConverter
