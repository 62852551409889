import {
    useCallback,
    useState,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import simpleApi from 'app/services/simpleApi'

type Params = {
    requestParamFn: (id)=> Record<string, any>,
    notificationId: string,
    title: string,
    data: {
        id: number,
        validFrom: string,
        validTo: string,
    },
    redirectionAfterSuccess?: string,
    onSuccess?: (props?: any) => void
}

const useChangeValidity = ({
    requestParamFn,
    notificationId,
    title,
    data,
    onSuccess,
}: Params) => {
    const token = useJWTToken()
    const [
        changeValidityState,
        setChangeValidityState,
    ] = useState({
        validFrom: data.validFrom,
        validTo: data.validTo,
        reason: '',
    })
    const [
        openConfirmDialog,
        setOpenConfirmDialog,
    ] = useState(false)

    const handleChangeValidity = useCallback(() => {
        setChangeValidityState({
            validFrom: data.validFrom,
            validTo: data.validTo,
            reason: '',
        })
        setOpenConfirmDialog(true)
    }, [data])

    const {
        onSuccess: onSuccessAction,
        onError,
    } = useHandleStatusRequest({
        id: notificationId,
        title: `${title} Changed`,
        onSuccess,
    })

    const mutation = useMutation({
        mutationFn: ({
            id,
        }: {id: number}) => {
            return simpleApi({
                ...requestParamFn({
                    id,
                }),
                data: {
                    validFrom: changeValidityState.validFrom,
                    validTo: changeValidityState.validTo,
                    commentForBilling: changeValidityState.reason,
                },
                token,
            })
        },
        onSuccess: onSuccessAction,
        onError,
    })

    const handleChangeValidityConfirm = useCallback((confirm) => {
        if (confirm) {
            mutation.mutate({
                id: data.id,
            })
        }
        setOpenConfirmDialog(false)
    }, [
        mutation,
        data.id,
    ])

    return {
        changeValidityState,
        setChangeValidityState,
        handleChangeValidity,
        openConfirmDialog,
        handleChangeValidityConfirm,
    }
}

export default useChangeValidity
