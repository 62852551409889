import React, {
    useCallback,
} from 'react'

import {
    v1 as uuidv1,
} from 'uuid'

import useLabel from 'app/hooks/useLabel'
import config from './TableFees.config'

import Table from './Table'

type Props = {
    name: string,
    onChange: (...args: any[]) => void,
    value?: Record<string, any>[],
    inputs: (...args: any) => JSX.Element,
    disabled?: boolean,
}
const defaultProps: Partial<Props> = {
    value: [],
    disabled: false,
}
const TableFees = ({
    name,
    onChange,
    value,
    inputs,
    disabled,
}: Props) => {
    const l = useLabel()

    const handleChange = useCallback((fee) => {
        const updatedFee = value.map((item) => {
            if (item.id === fee.id) {
                return fee
            }
            return item
        })

        onChange(updatedFee, {
            target: {
                name,
                value: updatedFee,
            },
        })
    }, [
        value,
        onChange,
        name,
    ])

    const handleAddEntry = useCallback(() => {
        const newEntry = [
            ...value,
            {
                id: `temp-${uuidv1()}`,
            },
        ]

        onChange(
            newEntry,
            {
                target: {
                    name,
                    value: newEntry,
                },
            },
        )
    }, [
        name,
        onChange,
        value,
    ])

    const handleDeleteEntry = useCallback((updatedId) => {
        onChange(value.filter(({
            id,
        }) => {
            return id !== updatedId
        }))
    }, [
        onChange,
        value,
    ])

    return (
        <Table
            value={value}
            columns={config(l)[name].columns}
            onChange={handleChange}
            addEntry={handleAddEntry}
            deleteEntry={handleDeleteEntry}
            inputs={inputs}
            disabled={disabled}
        />
    )
}

TableFees.defaultProps = defaultProps

export default TableFees
