import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import ContactForm from '../ContactForm/ContactForm'
import request from '../Contacts.request'
import {
    PROSPECT_CONTACTS_URL,
} from '../Contacts.config'

const ContactCreate = () => {
    const {
        selectDomainObject,
    } = useDomainObjectContext()

    const onSuccess = useCallback((contact) => {
        selectDomainObject(contact?.id, contact.isProspect && PROSPECT_CONTACTS_URL)
    }, [selectDomainObject])

    return (
        <ContactForm
            onSuccess={onSuccess}
            disabled={false}
            request={request.create()}
        />
    )
}

export default ContactCreate
