import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'

const contactParser = (contacts: Contact[]) => {
    return contacts.map(({
        contactName,
        email,
        id,
        locationLocationName,
        companyCompanyName,
        isProspect,
    }) => {
        return {
            label: `${email} (${contactName}, ${locationLocationName || companyCompanyName})`,
            email,
            value: id,
            id,
            contactName,
            isProspect,
        }
    })
}

export default contactParser
