/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import GatewayUpdate from '../GatewayUpdate'

const Overview = ({
    id,
}: { id: number }) => {
    return (
        <GatewayUpdate
            id={id}
            disabled
        />
    )
}

export default Overview
