import {
    useMemo,
} from 'react'
import injectClass from 'app/utils/injectClass'

const useFieldsWithClassNames = (fields, classes) => {
    return useMemo(() => {
        return injectClass(fields, classes)
    }, [
        fields,
        classes,
    ])
}

export default useFieldsWithClassNames
