import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import locationRequests from 'app/Apps/ContactManagement/Locations/Locations.request'

import {
    Location,
} from '../Locations.types'

const useGetLocationsByIds = (locationIds: number[]) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Location[]>({
        key: RequestKeys.getLocationsByIds,
        params: {
            includeFilters: {
                id: locationIds,
            },
        },
        requestFunc: RequestFn.getEntity(locationRequests.filterRequest.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
        keepPreviousData: true,
        enabled: Boolean(locationIds.length),
    })

    return {
        data: locationIds?.length ? data : [],
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetLocationsByIds
