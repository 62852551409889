import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        formOpened: {
            paddingBottom: theme.spacing(4),
        },
        section: {
            display: 'flex',
            flexDirection: 'column',
            flexBasis: '30%',
            '&:first-of-type': {
                flexBasis: '40%',
            },
            '&:last-child': {
                flexBasis: 230,
            },
        },
        sectionProgress: {
            justifyContent: 'space-between',
        },
        sectionLocation: {
            justifyContent: 'center',
        },
        sectionTop: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(4),
        },
        icon: {
            paddingRight: theme.spacing(1),
        },
        iconTitle: {
            padding: `0 ${theme.spacing(1)}`,
        },
        sectionBottom: {
            display: 'flex',
        },
        alignCenter: {
            display: 'flex',
            alignItems: 'center',
        },
    }
})

export default useStyles
