import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import RangeFilter from './RangeFilter'

const propTypes = {
    filter: PropTypes.arrayOf(PropTypes.number),
    rangeType: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
}

const defaultProps = {
    filter: null,
}

const typeMinMax = {
    temperatureRange: [
        -60,
        50,
    ],
    dayRange: [
        -200,
        0,
    ],
}

const RangeFilterContainer = (props) => {
    const {
        filter,
        rangeType,
        setFilter,
    } = props

    const initialValue = useMemo(() => {
        return filter || typeMinMax[rangeType]
    }, [
        filter,
        rangeType,
    ])

    const [
        value,
        setValue,
    ] = useState({
        range: initialValue,
        hasChanges: false,
    })

    const commitChanges = useCallback(() => {
        if (value.hasChanges) {
            setFilter(value.range)
        }
    }, [
        setFilter,
        value,
    ])

    const localOnChange = useCallback(({
        target: {
            value: newRange,
        },
    }) => {
        setValue({
            range: newRange,
            hasChanges: true,
        })
    }, [setValue])

    const active = useMemo(() => {
        return !isEmpty(filter)
    }, [filter])

    return (
        <RangeFilter
            type={rangeType}
            active={active}
            value={value}
            min={typeMinMax[rangeType][0]}
            max={typeMinMax[rangeType][1]}
            onChange={localOnChange}
            commitChanges={commitChanges}
        />
    )
}

RangeFilterContainer.propTypes = propTypes
RangeFilterContainer.defaultProps = defaultProps

export default RangeFilterContainer
