import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import RelationshipsManagementRequests from '../RelationshipsManagement.request'
import {
    ActivityRecord,
} from '../ActivityRecords/activityRecords.types'

const useGetActivityRecord = (id) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<ActivityRecord>({
        key: RequestKeys.getActivityRecord,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(RelationshipsManagementRequests.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetActivityRecord
