import React, {
    useMemo,
} from 'react'

import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import StatusHandler from 'app/shared-components/StatusHandler'
import CompanyForm from '../CompanyForm/CompanyForm'
import useGetCompany from '../hooks/useGetCompany'
import companyRequests from '../Companies.request'
import useUpdateCompany from '../hooks/useUpdateCompany'

const CompanyUpdate = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetCompany(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? companyRequests.restore
            : companyRequests.archive,
        notificationId: 'CompanyUpdateDto',
        title: 'Company',
        data,
        redirectionAfterSuccess: '/apps/contactmanagement/companies/all',
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        archive,
        data?.archived,
    ])

    const updateCompany = useUpdateCompany({
        onSuccess: invalidate,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <CompanyForm
                    data={data}
                    customButtons={customButtons}
                    onCancel={invalidate}
                    action={updateCompany}
                />
                <ArchiveConfirmationDialog
                    title="Company"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

export default CompanyUpdate
