import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            paddingRight: theme.spacing(2),
            display: 'flex',
            height: 20,
            alignItems: 'center',
        },
        icon: {
            width: 13,
            height: 13,
            marginRight: theme.spacing(0.5),
        },
    }
})

export default useStyles
