import React from 'react'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import useUploadFile from 'app/shared-components/DropZone/hook/useUploadFile'

import useStyles from './UploadFile.style'

const defaultProps = {
    acceptType: 'application/pdf',
}

const UploadFile = ({
    onSuccessUpload,
    acceptType,
}: {
    onSuccessUpload: (id: number) => void,
    acceptType?: string,
}) => {
    const {
        classes,
    } = useStyles()

    const uploadFile = useUploadFile(onSuccessUpload)

    const onChangeLoad = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const formData = new FormData()

        formData.append(
            'file',
            event.target.files[0],
            event.target.files[0].name,
        )

        uploadFile(formData)
    }

    return (
        <InputLabel className={classes.label}>
            <img
                src="assets/images/icons/upload_file.svg"
                alt="upload"
            />
            <TextField
                type="file"
                inputProps={{
                    accept: acceptType,
                }}
                className={classes.input}
                onChange={onChangeLoad}
            />
        </InputLabel>
    )
}

UploadFile.defaultProps = defaultProps

export default UploadFile
