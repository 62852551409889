import React from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    LaneStatus as LaneStatusEnum,
} from 'app/types/enums'
import useStyles from './LaneStatus.style'

type Props = {
    value?: string,
}

const defaultProps = {
    value: null,
}

const LaneStatus = ({
    value,
}: Props) => {
    const laneStatuses = useEnumValues()(LaneStatusEnum)
    const laneStatus = laneStatuses[value] || value
    const {
        classes,
    } = useStyles()

    if (value === LaneStatusEnum.AWARDED) {
        return (
            <div className={classes.wrapper}>
                <div>{laneStatus}</div>
                <img
                    className={classes.icon}
                    src="/assets/images/warning_blue.svg"
                    alt="AWARDED"
                />
            </div>
        )
    }

    return (
        <div>{laneStatus}</div>
    )
}

LaneStatus.defaultProps = defaultProps

export default LaneStatus
