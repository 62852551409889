import React, {
    useMemo,
} from 'react'

import TimelineChart from 'app/shared-components/TimelineChart'

import options from './optionsChart'

const columns = [
    {
        type: 'string',
        id: 'Gateway',
    },
    {
        type: 'date',
        id: 'Start',
    },
    {
        type: 'date',
        id: 'End',
    },
]

const GatewayTimelineChart = ({
    sensorData,
}: {
    sensorData: {
        t: string,
        d: (number|string)[],
    }[],
}) => {
    const chartData: (string | number | Date)[][] = useMemo(() => {
        return sensorData.map(({
            d, t,
        }, index) => {
            const itemChart = [
                d[0],
                new Date(t),
            ]

            if (index < sensorData.length - 1 && sensorData[index + 1].d[0] === d[0]) {
                return [
                    ...itemChart,
                    new Date(sensorData[index + 1].t),
                ]
            }

            return [
                ...itemChart,
                new Date(t),
            ]
        })
    }, [sensorData])

    return (
        <TimelineChart
            value={chartData}
            options={options}
            columns={columns}
        />
    )
}

export default GatewayTimelineChart
