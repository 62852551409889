import {
    FilterType,
} from 'app/types/common.enums'
import {
    convertFilterValueToObjectSelector, convertObjectValueToFilter,
    FilterConfig,
} from 'app/hooks/useFilter'
import {
    PreBookingEntity,
} from '../PreBooking.types'

const filterConfig: FilterConfig<keyof Pick<PreBookingEntity,
    'pricingAccount' | 'originAirport' | 'destinationAirport' | 'containersStatus'>> = {
        pricingAccount: {
            filterField: 'pricingAccountId',
            filterType: FilterType.Array,
            preselectOptions: {
                componentName: 'AccountSelector',
                filterToValueConverter: convertFilterValueToObjectSelector,
                onChangeConverter: convertObjectValueToFilter,
                placeholder: 'Select Pricing Account',
                noneOption: false,
            },
        },
        originAirport: {
            filterField: 'originAirport',
            filterType: FilterType.Array,
        },
        destinationAirport: {
            filterField: 'destinationAirport',
            filterType: FilterType.Array,
        },
        containersStatus: {
            filterField: 'containersStatus',
            filterType: FilterType.Array,
        },
    }

export default filterConfig
