import {
    removeSelf,
} from './utils/removeSelf'

const applySort = (cb) => {
    return (params) => {
        const {
            sort,
            data: paramsData,
        } = params

        let data = {
            ...paramsData,
        }

        if (sort) {
            data = {
                ...data,
                sortField: removeSelf(sort.field),
                sortType: sort.direction,
            }
        }

        return cb({
            ...params,
            data,
        })
    }
}

export default applySort
