import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import GatewayRequests from '../Gateways.request'
import GatewayForm from '../GatewayForm'

import useGetGateway from '../hooks/useGetGateway'

const defaultProps = {
    disabled: false,
}

const GatewayUpdate = ({
    id,
    disabled,
}: {
    id: number,
    disabled?: boolean,
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        invalidate,
        error,
    } = useGetGateway(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <GatewayForm
                data={data}
                onSuccess={invalidate}
                onCancel={invalidate}
                requestParam={GatewayRequests.update.request()}
                disabled={disabled}
            />
        </StatusHandler>

    )
}

GatewayUpdate.defaultProps = defaultProps

export default GatewayUpdate
