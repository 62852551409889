import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        noWrapText: {
            whiteSpace: 'nowrap',
            margin: 0,
            alignSelf: 'center',
            marginRight: theme.spacing(0.5),
        },
        buttons: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        selectContainer: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(0.5),
            flex: '1 1 10rem',
        },
        selectInput: {
            paddingBottom: theme.spacing(0.375),
            width: '100%',
            color: `${theme.palette.secondary[600]} !important`,
            paddingTop: theme.spacing(0.25),
        },
    }
})

export default useStyles
