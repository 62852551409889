import {
    useContext,
} from 'react'

import DomainObjectContext from 'app/Apps/DomainObject/DomainObjectContext'

const useDomainObjectContext = () => {
    return useContext(DomainObjectContext)
}

export default useDomainObjectContext
