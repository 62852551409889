import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        layout: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            padding: 0,
            overflow: 'hidden',
            position: 'relative',
        },
        defaultContent: {
            height: 'inherit',
        },
        content: {
            backgroundColor: theme.palette.secondary[200],
        },
    }
})

export default useStyles
