import {
    createContext, useContext,
} from 'react'
import {
    PreBookingEntity,
} from '../../PreBooking.types'

type StepperContextType<T> = {
    activeStep: number,
    setActiveStep: (n: number) => void,
    value: T,
    initialValue?: T,
    setValue: (a: T) => void,
    setIsEdited: (isEdited: boolean) => void,
}

export type PreBookingInfoContextType = StepperContextType<PreBookingEntity>

export const PreBookingInfoContext = createContext<PreBookingInfoContextType>(null)

const usePreBookingInfoContext = () => {
    return useContext<PreBookingInfoContextType>(PreBookingInfoContext)
}

export default usePreBookingInfoContext
