import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        optionsWrapper: {
            zIndex: 1000,
            position: 'absolute',
            display: 'none',
            '&.active': {
                display: 'block',
            },
        },
        options: {
            position: 'absolute',
            minWidth: 50,
            width: 'auto',
            border: `1px solid ${theme.palette.secondary[200]}`,
            background: theme.palette.common.white,
            minHeight: 50,
            maxHeight: 200,
            overflow: 'auto',
            display: 'block',
            left: 0,
        },
        optionNone: {
            fontStyle: 'italic',
        },
        loadingWrapper: {
            position: 'absolute',
            width: 50,
            height: 50,
            background: theme.palette.common.white,
            left: 0,
        },
    }
})

export default useStyles
