import {
    useCallback,
} from 'react'
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom'

type Args = {
    action?: string,
    id?: string | number
}

export default () => {
    const history = useHistory()
    const {
        params, path,
    } = useRouteMatch()

    const updatePathWithParams = useCallback((args?: Args) => {
        const newUrl = Object.entries({
            id: args?.id,
            action: args?.action,
        }).reduce((acc, [
            key,
            value,
        ]) => {
            return acc.replace(`:${key}?`, value || '')
        }, path)

        history.push(`/${newUrl.split('/').filter(Boolean).join('/')}`)
    }, [
        path,
        history,
    ])

    const updatePathWithId = useCallback(({
        id,
    }) => {
        if (String(params?.id) !== String(id)) {
            updatePathWithParams({
                id,
            })
            return
        }
        updatePathWithParams()
    }, [
        params?.id,
        updatePathWithParams,
    ])

    return {
        updatePathWithId,
        updatePathWithParams,
        id: params?.id,
        action: params?.action,
    }
}
