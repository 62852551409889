import React, {
    useCallback,
    useState,
} from 'react'

import useStyles from './Co2simulations.style'

import ContainerTypeSelection from './ContainerTypeSelection'
import RouteSelection from '../Simulation/RouteSelection'
import FormBlockItem from '../Simulation/FormBlockItem'
import AnalyseCO2Emissions from './AnalyseCO2Emissions'
import {
    Co2simulationType,
} from './co2simulations.types'

const Co2simulations = () => {
    const {
        classes,
    } = useStyles()
    const [
        state,
        setState,
    ] = useState<Co2simulationType>({
        selectedContainer: null,
        route: {
            routeOriginAirport: null,
            routeViaAirports: [null],
            routeDestinationAirport: null,
        },
    })

    const onChange = useCallback((newValue, {
        target: {
            name,
        },
    }) => {
        setState({
            ...state,
            [name]: newValue,
        })
    }, [state])

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <FormBlockItem
                    number={1}
                    title="Select Container Model"
                >
                    <ContainerTypeSelection
                        name="selectedContainer"
                        onChange={onChange}
                        value={state.selectedContainer}
                    />
                </FormBlockItem>
                <FormBlockItem
                    number={2}
                    title="Select Route"
                >
                    <RouteSelection
                        name="route"
                        onChange={onChange}
                        value={state.route}
                    />
                </FormBlockItem>
                <FormBlockItem
                    number={3}
                    title={`Analyse CO${String.fromCharCode(8322)} Emissions`}
                >
                    <AnalyseCO2Emissions
                        value={state}
                        onChange={onChange}
                    />
                </FormBlockItem>
            </div>
        </div>
    )
}

export default Co2simulations
