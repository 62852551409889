import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'
import {
    useRouteMatch, Route, Switch, Redirect,
} from 'react-router-dom'
import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import {
    LaneStatus,
} from 'app/types/enums'
import LaneFees from './LaneFees'
import useGetLaneGroupPricingAssignmentById from '../hooks/useGetLaneGroupPricingAssignmentById'
import useAccountQuoteContext from '../../AccountQuoteContext/useAccountQuoteContext'
import AccountQuoteLaneGroupOverview from './LaneGroupOverview'
import AssignPricingLanes from './AssignPricingLanes'
import useSetLaneStatus from '../hooks/useSetLaneStatus'
import useStyles from './AssignPricingLaneGroupUpdate.styles'

const AssignPricingLaneGroupUpdate = ({
    id,
    refetch,
}: {
    id: number,
    refetch: (...args: any[]) => void
}) => {
    const {
        classes,
    } = useStyles()

    const {
        url, isExact, params,
    } = useRouteMatch()

    const [
        selectedLanes,
        setSelectedLanes,
    ] = useState([])

    const {
        quoteId,
    } = useAccountQuoteContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLaneGroupPricingAssignmentById({
        id, quoteId,
    })

    const onSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        refetch,
        invalidate,
    ])

    const updateLaneStatus = useSetLaneStatus({
        onSuccess,
    })

    const onSelect = useCallback(({
        id: laneId, selected,
    }) => {
        if (selected) {
            setSelectedLanes([
                ...selectedLanes,
                {
                    id: laneId,
                },
            ])

            return
        }

        setSelectedLanes(selectedLanes.filter((lane) => { return lane.id !== laneId }))
    }, [
        setSelectedLanes,
        selectedLanes,
    ])

    const lanesData = useMemo(() => {
        return (data?.lanes || []).map((lane) => {
            return {
                ...lane,
                selected: Boolean(selectedLanes.find((el) => { return el.id === lane.id })),
            }
        })
    }, [
        data?.lanes,
        selectedLanes,
    ])

    return (
        <Switch>
            { isExact && !params.action && (
                <Switch>
                    <Redirect
                        to={`${url}/lane-fees`}
                    />
                </Switch>
            )}
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <div className={classes.formContainer}>
                    <AccountQuoteLaneGroupOverview data={data} />
                    <AssignPricingLanes
                        lanes={lanesData}
                        onSelect={onSelect}
                        onSuccess={onSuccess}
                    />
                    <ButtonContainer>
                        <Button
                            key="set-as-priced"
                            label="Set as Priced"
                            onClick={() => {
                                updateLaneStatus({
                                    laneStatus: LaneStatus.PRICED,
                                    lanes: selectedLanes,
                                })
                            }}
                        />
                        <Button
                            key="set-as-rejected"
                            label="Set as Rejected"
                            onClick={() => {
                                updateLaneStatus({
                                    laneStatus: LaneStatus.REJECTED_PRICING,
                                    lanes: selectedLanes,
                                })
                            }}
                        />
                        <Button
                            key="set-as-cancelled"
                            label="Set as Cancelled"
                            onClick={() => {
                                updateLaneStatus({
                                    laneStatus: LaneStatus.CANCELLED,
                                    lanes: selectedLanes,
                                })
                            }}
                        />
                    </ButtonContainer>
                    <Route path={url}>
                        <LaneFees id={id} />
                    </Route>
                </div>
            </StatusHandler>
        </Switch>
    )
}

export default AssignPricingLaneGroupUpdate
