import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    PreBookingEntity,
} from 'app/Apps/PreBooking/PreBooking.types'
import requests from 'app/Apps/PreBooking/PreBookings.requests'

export const parsePrebookingResponse = (resp) => {
    return resp.then(({
        data: respData = {},
    } = {}) => { return respData }).catch((e) => {
        if (e?.response?.status === 404) {
            return null
        }
        throw e
    })
}

const useGetPrebookingByParams = ({
    mawb,
}: {
    mawb?: string,
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<PreBookingEntity>({
        key: RequestKeys.getPreBookingByParams,
        params: {
            mawb,
        },
        requestFunc: RequestFn.getEntity(requests.getPrebookingByParams, parsePrebookingResponse),
        enabled: Boolean(mawb),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetPrebookingByParams
