import {
    LocationType, OrderTemperatureRange,
} from 'app/types/enums'
import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    OrderDetailsStep,
} from '../../createDirectLease.types'
import {
    AvailableLanesFilters,
} from './AvailableLanes'

const getFields = ({
    includeFilters,
    getAllowedValues,
}: {
    includeFilters: AvailableLanesFilters,
    getAllowedValues: (enumType: any) => Record<string, string>,
}): DTOCardFormField<keyof OrderDetailsStep>[] => {
    return [
        {
            title: 'Selection',
            className: 'selection',
            id: 'selection',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'customerExecution',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'Lane.customerExecution',
                        },
                        {
                            name: 'pricingAccount',
                            componentName: 'AccountSelector',
                            labelKey: 'Prebooking.pricingAccount',
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'originAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            labelKey: 'Order.originAirport',
                        },
                        {
                            name: 'destinationAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            labelKey: 'Order.destinationAirport',
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'handoverPoint',
                            componentName: 'LocationExpandedViewObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'Order.handoverPoint',
                        },
                        {
                            name: 'collectionDropoffPoint',
                            componentName: 'LocationExpandedViewObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'Order.collectionDropoffPoint',
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [
                        {
                            name: 'consignee',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'Order.consignee',
                        },
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OrderTemperatureRange),
                            useDropdown: true,
                            labelKey: 'Order.temperatureRange',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Available Lanes',
            className: 'availableLanes',
            id: 'availableLanes',
            classNames: (classes) => {
                return {
                    gridCardWrapper: classes.gridCardWrapperLanes,
                }
            },
            fields: [{
                name: 'selectedLaneId',
                componentName: 'AvailableLanes',
                includeFilters,
                labelKey: 'Order.leaseType',
            }],
        },
    ]
}

export default getFields
