const FeeStatus: Record<string, Record<string, string[]>> = {
    additionalFeesStatus: {
        VALID: ['VALID'],
        IN_PROGRESS: [
            'IN_PROGRESS',
            'VALID',
        ],
    },
    laneFeesStatus: {
        IN_PROGRESS: [
            'IN_PROGRESS',
            'AWARDED',
            'SUBMITTED',
        ],
        REJECTED: [
            'REJECTED',
            'AWARDED',
        ],
        SUBMITTED: [
            'SUBMITTED',
            'AWARDED',
            'REJECTED',
            'IN_PROGRESS',
        ],
        AWARDED: ['AWARDED'],
    },
    assignPricingLaneFeesStatus: {
        IN_PROGRESS: [
            'IN_PROGRESS',
            'SUBMITTED',
        ],
        REJECTED: ['REJECTED'],
        SUBMITTED: [
            'SUBMITTED',
            'IN_PROGRESS',
        ],
        AWARDED: ['AWARDED'],
    },
    laneImplementation: {
        IN_PROGRESS: [
            'IN_PROGRESS',
            'PRICED',
            'AWARDED',
            'CANCELLED',
        ],
        PRICED: [
            'PRICED',
            'AWARDED',
        ],
        AWARDED: [
            'AWARDED',
            'CANCELLED',
            'IMPLEMENTED',
        ],
        IMPLEMENTED: [
            'IMPLEMENTED',
            'INACTIVE',
        ],
        INACTIVE: [
            'INACTIVE',
            'IMPLEMENTED',
            'CANCELLED',
        ],
        CANCELLED: ['CANCELLED'],
    },
    laneStatus: {
        IN_PROGRESS: [
            'IN_PROGRESS',
            'PRICED',
            'AWARDED',
            'CANCELLED',
        ],
        PRICED: [
            'PRICED',
            'AWARDED',
            'CANCELLED',
            'REJECTED_CUSTOMER',
        ],
        AWARDED: [
            'AWARDED',
            'CANCELLED',
            'REJECTED_CUSTOMER',
        ],
        IMPLEMENTED: [
            'IMPLEMENTED',
            'CANCELLED',
        ],
        INACTIVE: [
            'INACTIVE',
            'IMPLEMENTED',
            'CANCELLED',
        ],
        CANCELLED: ['CANCELLED'],
        REJECTED_CUSTOMER: [
            'REJECTED_CUSTOMER',
            'AWARDED',
            'CANCELLED',
        ],
    },
    laneGroup: {
        IN_PROGRESS: [
            'IN_PROGRESS',
            'PRICED',
            'AWARDED',
            'CANCELLED',
        ],
        PRICED: [
            'PRICED',
            'AWARDED',
        ],
        AWARDED: [
            'AWARDED',
            'CANCELLED',
            'IMPLEMENTED',
        ],
        IMPLEMENTED: [
            'IMPLEMENTED',
            'CANCELLED',
        ],
        INACTIVE: [
            'INACTIVE',
            'CANCELLED',
        ],
        CANCELLED: ['CANCELLED'],
    },
    generalPricingStatus: {
        VALID: [
            'VALID',
            'CANCELLED',
        ],
        IN_PROGRESS: [
            'IN_PROGRESS',
            'VALID',
            'CANCELLED',
        ],
        CANCELLED: ['CANCELLED'],
    },
}

export default FeeStatus
