import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
        },
        text: {
            width: '100%',
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary[500],
        },
        withFilter: {
            width: 'calc(100% - 25px)',
        },
        filterIcon: {
            width: 25,
            display: 'inline-flex',
        },
    }
})

export default useStyles
