import {
    getLoggerPositionsConfig,
} from 'app/utils/optionsLoggers'

const defaultConfigLoggers = (loggerTypeOptions, loggerPositionsOptions) => {
    return [
        {
            title: 'Logger Types',
            name: 'loggerTypes',
            options: loggerTypeOptions,
        },
        getLoggerPositionsConfig({
            positions: loggerPositionsOptions,
        }),
    ]
}

export default defaultConfigLoggers
