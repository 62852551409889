import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            textTransform: 'capitalize',
        },
        iconWrapper: {
            height: theme.typography.h2.lineHeight,
            padding: theme.spacing(0, 0.5, 0, 0),
            display: 'flex',
            alignItems: 'center',
        },
        cloudIcon: {
            '&&': {
                color: theme.palette.primary[200],
                width: theme.spacing(2),
                height: theme.spacing(2),
            },
        },
        cloudOffIcon: {
            '&&': {
                color: theme.palette.common.black,
                width: theme.spacing(2),
                height: theme.spacing(2),
            },
        },
        oldReading: {
            color: theme.palette.common.red,
        },
        recentReading: {
            color: theme.palette.common.yellow,
        },
        liveReading: {
            color: theme.palette.common.green,
        },
        text: {
            color: theme.palette.secondary[600],
            fontSize: theme.typography.body1.fontSize,
        },
        iconWrapperWithLabel: {
            height: theme.typography.body1.lineHeight,
            padding: theme.spacing(0, 0.5, 0, 0),
            display: 'flex',
            alignItems: 'center',
        },
    }
})

export default useStyles
