import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
        },
        wrapper: {
            position: 'relative',
        },
        iconStatus: {
            width: 14,
            height: 14,
            position: 'absolute',
            right: -10,
            top: 8,
        },
        iconArrow: {
            color: theme.palette.secondary[600],
            fontSize: 'large',
            paddingLeft: theme.spacing(1.25),
        },
    }
})

export default useStyles
