import {
    makeStyles,
} from 'app/tss'

const containerData = 'containerData'
const location = 'location'
const containerDescription = 'containerDescription'

const useStyles = makeStyles()((theme) => {
    return {
        fullHeight: {
            minHeight: '100%',
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
            "${containerData} ${location}"
            " ${containerData} ${containerDescription}"
        `,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        containerData: {
            gridArea: containerData,
        },
        location: {
            gridArea: location,
        },
        containerDescription: {
            gridArea: containerDescription,
        },
    }
})

export default useStyles
