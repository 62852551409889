import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, _, classes) => {
    return {
        formControl: {
            width: '100%',
        },
        multipleSelect: {
            paddingTop: '0px !important',
            maxHeight: '300px !important',
            '& #multiple-checkbox': {
                color: theme.palette.secondary[600],
            },
        },
        menuItem: {
            maxHeight: 40,
        },
        select: {
            width: '100%',
            color: `${theme.palette.secondary[600]} !important`,
            paddingTop: '2px !important',
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: -8,
                width: 6,
                height: 26,
                backgroundColor: theme.palette.primary.main,
                display: 'none',
                borderRadius: 3,
            },
        },
        isRequired: {
            [`& .${classes.requiredMark}`]: {
                display: 'block',
            },
        },
        isRequiredBackground: {
            [`& .${classes.requiredMark}`]: {
                display: 'block',
            },
            backgroundColor: theme.palette.primary[30],
        },
    }
})

export default useStyles
