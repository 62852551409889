import formatCurrencyValue from 'app/utils/formatCurrencyValue'
import {
    Currency,
} from 'app/types/enums'
import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    PositionFields,
    PositionType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import AllPositionTypeCodes from 'app/types/positionTypeCode.enum'
import DeleteButton from './DeleteButton'

type Args = {
    currency?: Currency,
    invoiceId: number,
    disabled: boolean,
    canBeDeleted: boolean,
    onSuccess: () => void
}

const getColumns = ({
    currency,
    onSuccess,
    canBeDeleted,
    invoiceId,
    disabled,
}: Args): RichTableColumns<PositionFields | 'totalPrice' | 'delete'>[] => {
    return [
        {
            id: 'positionCode',
            headerProps: {
                children: 'Position',
            },
            mapCellProps: 'positionCode',
            componentName: 'EnumValue',
            allowedValuesEnum: AllPositionTypeCodes,
        },
        {
            id: 'positionType',
            headerProps: {
                children: 'Position Type',
            },
            mapCellProps: 'positionType',
            componentName: 'EnumValue',
            allowedValuesEnum: PositionType,
        },
        {
            id: 'positionDescription',
            headerProps: {
                children: 'Description',
            },
            mapCellProps: 'positionDescription',
        },
        {
            id: 'quantity',
            headerProps: {
                children: 'Quantity',
            },
            mapCellProps: 'quantity',
        },
        {
            id: 'unitPrice',
            headerProps: {
                children: 'Unit Price',
            },
            mapCellProps: ({
                unitPrice,
            }) => {
                return {
                    children: `${formatCurrencyValue(unitPrice)} ${currency || ''}`,
                }
            },
        },
        {
            id: 'totalPrice',
            headerProps: {
                children: 'Total Price',
            },
            mapCellProps: ({
                quantity,
                unitPrice,
            }) => {
                return {
                    children: `${formatCurrencyValue(unitPrice * quantity)} ${currency || ''}`,
                }
            },
        },
        {
            id: 'delete',
            Cell: DeleteButton,
            mapCellProps: () => {
                return {
                    canBeDeleted,
                    disabled,
                    invoiceId,
                    onSuccess,
                }
            },
        },
    ]
}

export default getColumns
