import countBy from 'lodash/countBy'

import {
    ProductType,
} from 'app/types/enums'
import {
    ProductTypeContainerTempRange,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    BookedContainer,
} from '../bookedContainers.types'

const getProductType = (
    filteredSelectedContainers: BookedContainer[],
    productTypes: ProductTypeContainerTempRange[],
): (BookedContainer & {productType: ProductType}
)[] => {
    return filteredSelectedContainers.map((item) => {
        const product = productTypes
            .filter(({
                containerCodes,
            }) => {
                return containerCodes.includes(item?.containerTypeContainerCode)
            })
            .find(({
                temperatureRange,
            }) => {
                return temperatureRange === item?.tempRange
            })

        return {
            ...item,
            productType: product?.productType,
        }
    })
}

const getSelectedAssetsMap = (
    {
        selectedContainers,
        productTypes = [],
        isInternalType,
    }: {
        selectedContainers: BookedContainer[],
        productTypes: ProductTypeContainerTempRange[] | [],
        isInternalType: boolean
},
): Record<string | ProductType, number> => {
    const filteredSelectedContainers = selectedContainers.filter(({
        isRejected,
    }) => {
        return !isRejected
    })

    return isInternalType
        ? countBy(filteredSelectedContainers, ({
            containerTypeContainerModel,
        }) => {
            return containerTypeContainerModel
        }) : countBy(getProductType(filteredSelectedContainers, productTypes), ({
            productType,
        }) => {
            return productType
        })
}

export default getSelectedAssetsMap
