import {
    createContext, useContext,
} from 'react'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    ComponentsFormCollectionProps,
} from 'app/utils/components/ComponentsFormCollection'
import {
    FilterOptions,
} from './useFilter'

export type PreselectOptions = ComponentsFormCollectionProps & {
    filterToValueConverter?: (f: (string|number|boolean)[]) => any,
    onChangeConverter?: (v: any) => (string|number|boolean)[],
    placeholder?: string,
}
export type FilterConfig<F extends string | number | symbol = any> = Record<F, {
    filterField: string,
    filterType: FilterType,
    preselectOptions?: PreselectOptions
}>

export type FilterContextType = FilterOptions & {
    config?: FilterConfig,
    request?: (...args: any[]) => RequestConfig,
    edited?: boolean,
    beforeFilterCallback?: (cb: (...a: any[]) => void) => any
}
export const FilterContext = createContext<FilterContextType>(null)

const useFilterContext = () => {
    return useContext<FilterContextType>(FilterContext)
}

export default useFilterContext
