import {
    useMemo,
} from 'react'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    FeesType,
} from './Fee.types'
import config from './Fee.request'

type Mapping = Record<string, string[]>

const useFeesNameToFeesChargeabilityMapping = (type: FeesType) : undefined | Mapping => {
    const {
        isDataReady: laneFeesIsDataReady,
        data: lanefees,
    } = useRequest<Record<string, string[]>>({
        key: RequestKeys.getAllowedLaneFeesFeeNameToChargeAbilityMapping,
        requestFunc: RequestFn.getEntity(config.LaneFees.requestFn),
        staleTime: Infinity,
        enabled: type === FeesType.Lane || type === FeesType.OtherFees,
    })

    const {
        isDataReady: additionalFeesIsDataReady,
        data: additionalFees,
    } = useRequest<Record<string, string[]>>({
        key: RequestKeys.getAllowedAdditionalFeesFeeNameToChargeAbilityMapping,
        requestFunc: RequestFn.getEntity(config.AdditionalFees.requestFn),
        staleTime: Infinity,
        enabled: type === FeesType.Additional,
    })

    return useMemo(() => {
        if ((type === FeesType.Lane || type === FeesType.OtherFees) && laneFeesIsDataReady) {
            return lanefees
        }
        if (type === FeesType.Additional && additionalFeesIsDataReady) {
            return additionalFees
        }
        return undefined
    }, [
        type,
        laneFeesIsDataReady,
        additionalFeesIsDataReady,
        lanefees,
        additionalFees,
    ])
}

export default useFeesNameToFeesChargeabilityMapping
