import {
    createSlice,
} from '@reduxjs/toolkit'
import type {
    RootState,
} from '../index'

const initialState = {
    isLocalTime: false,
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        toggleLocalTime(state) {
            return {
                ...state,
                isLocalTime: !state.isLocalTime,
            }
        },
    },
})

export const {
    toggleLocalTime,
} = settingsSlice.actions

export const selectIsLocalTime = (state: RootState): boolean => {
    return state.settings.isLocalTime
}

export default settingsSlice.reducer
