import {
    useCallback, useState, useEffect, useMemo,
} from 'react'

import {
    RequestConfig, TableResponse,
} from 'app/types/request.types'
import useRequest, {
    UseRequestParam,
    RequestFn,
} from 'app/hooks/useRequest'

type UsePaginatedRequest = Omit<UseRequestParam, 'requestFunc'> & {
    rows?: number,
    config: (args: Record<string, any>) => RequestConfig,
}

const usePaginatedRequest = <PaginatedData = any[]>({
    key,
    params,
    config,
    enabled = true,
    refetchInterval = false,
    refetchIntervalInBackground = false,
    keepPreviousData = false,
    staleTime = 1000,
    rows = 50,
}: UsePaginatedRequest): {
    data: PaginatedData[],
    dataByPage: [number, TableResponse<PaginatedData[]>][],
    isDataReady: boolean,
    isFetching: boolean,
    isError: boolean,
    error: string,
    invalidate: () => void,
    fetchNext: () => void,
    hasNext: boolean,
} => {
    const [
        page,
        setPage,
    ] = useState<number | undefined>(undefined)

    const [
        dataByPage,
        setDataByPage,
    ] = useState<[number, TableResponse<PaginatedData[]>][]>([])

    const requestParams = useMemo(() => {
        return {
            ...params,
            data: {
                ...params.data,
                ...{
                    start: page ? (page * rows) : 0,
                    rows,
                },
            },
        }
    }, [
        page,
        params,
        rows,
    ])

    const {
        data: response,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<TableResponse<PaginatedData[]>>({
        key,
        params: requestParams,
        requestFunc: RequestFn.table(config, {
            withPagination: true,
        }),
        enabled: enabled && page !== undefined,
        staleTime,
        refetchInterval,
        refetchIntervalInBackground,
        keepPreviousData,
    })

    const hasNext = useMemo(() => {
        return dataByPage?.at(-1)?.[1]?.hasNextPage
    }, [dataByPage])

    // initiates new request call for the next page
    const fetchNext: () => void | undefined = useCallback(() => {
        if (hasNext) {
            return setPage((prev) => {
                return prev + 1
            })
        }

        return undefined
    }, [hasNext])

    useEffect(() => {
        if (isDataReady) {
            setDataByPage((prev) => {
                return [
                    ...prev,
                    [
                        page,
                        response,
                    ],
                ]
            })
        }
    }, [
        response,
        page,
        isDataReady,
    ])

    const data: PaginatedData[] = useMemo(() => {
        return dataByPage.reduce((acc, [
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _pageNr,
            responseByPage,
        ]) => {
            return [
                ...acc,
                ...responseByPage.data,
            ]
        }, [] as [number, TableResponse<PaginatedData[]>][]) as PaginatedData[]
    }, [dataByPage])

    // disables excessive request call after unmounting
    useEffect(() => {
        setPage(enabled ? 0 : undefined)
        setDataByPage([])
    }, [enabled])

    return {
        data,
        dataByPage,
        isDataReady: Boolean(dataByPage?.at(0)?.[1]?.data),
        isFetching,
        isError,
        error,
        invalidate,
        fetchNext,
        hasNext,
    }
}

export default usePaginatedRequest
