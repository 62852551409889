/* eslint-disable react/require-default-props,react/jsx-props-no-spreading,no-nested-ternary */
import React from 'react'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import InsertInvitation from '@mui/icons-material/InsertInvitation'
import AccessTime from '@mui/icons-material/AccessTime'
import {
    DateTimePickerToolbar,
} from '@mui/x-date-pickers/DateTimePicker'
import {
    DatePickerToolbar,
    DatePickerToolbarProps,
} from '@mui/x-date-pickers/DatePicker'
import {
    DateOrTimeView,
} from '@mui/x-date-pickers/models/views'

/*  Custom Toolbar component to show a button to switch between
the date/time picker view and the keyboard view,
that was removed in 7v version of @mui/x-date-pickers
*/
function ToolbarWithKeyboardViewSwitch(
    props: DatePickerToolbarProps<any> & {
        showKeyboardViewSwitch?: boolean;
        showKeyboardView?: boolean;
        setShowKeyboardView?: React.Dispatch<React.SetStateAction<boolean>>;
    },
) {
    const {
        showKeyboardViewSwitch,
        showKeyboardView,
        setShowKeyboardView,
        ...other
    } = props

    if (showKeyboardViewSwitch) {
        const showTime = (other.views as unknown as DateOrTimeView).includes('hours')
            || (other.views as unknown as DateOrTimeView).includes('minutes')

        return (
            <Stack
                spacing={0}
                direction={other.isLandscape ? 'column' : 'row'}
                alignItems="stretch"
                sx={
                    other.isLandscape
                        ? {
                            gridColumn: 1,
                            gridRow: '1 / 3',
                        }
                        : {
                            gridColumn: '1 / 4',
                            gridRow: 1,
                            mr: 1,
                        }
                }
            >
                {(showTime) ? (
                    <DateTimePickerToolbar
                        {...other}
                        sx={{
                            flex: '1 1 100%',
                        }}
                    />
                ) : (
                    <DatePickerToolbar
                        {...other}
                        sx={{
                            flex: '1 1 100%',
                        }}
                    />
                )}
                <IconButton
                    color="inherit"
                    onClick={() => { return setShowKeyboardView!((prev) => { return !prev }) }}
                    sx={{
                        padding: '4px',
                        position: 'absolute',
                        top: '8px',
                        right: '10px',
                    }}
                >
                    {showKeyboardView ? (other.view === 'day' ? <InsertInvitation /> : <AccessTime />) : <ModeEditIcon />}
                </IconButton>
            </Stack>
        )
    }

    return <DatePickerToolbar {...other} />
}

export default ToolbarWithKeyboardViewSwitch
