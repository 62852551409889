import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    toBePriced,
} from '../Dashboard.types'

const beingPricedConfig: SkyNetTableConfig<keyof toBePriced> = {
    name: 'toBePriced',
    tabs: [{
        name: 'Being Priced',
        url: 'all',
    }],
    fields: [
        {
            name: 'quoteNumber',
            labelKey: 'AccountQuotes.quoteNumber',
        },
        {
            name: 'customerQuotationLocationName',
            labelKey: 'AccountQuotes.customerQuotation',
        },
        {
            name: 'accountName',
            labelKey: 'AccountLane.accountCompanyName',
            filterField: 'accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'pricingAccountCompanyName',
            labelKey: 'AccountLane.pricingAccount',
        },
        {
            name: 'leaseType',
            labelKey: 'AccountLane.leaseType',
            filterField: 'leaseType',
            componentName: 'EnumValue',
            filterType: FilterType.Array,
            allowedValuesEnum: LeaseType,
        },
        {
            name: 'pricingResponsible',
            labelKey: 'AccountQuotes.pricingResponsible',
            componentName: 'List',
            filterField: 'pricingResponsible',
            filterType: FilterType.Array,
        },
        {
            name: 'createdBy',
            labelKey: 'KamDashboard.requestor',
            filterField: 'createdBy',
            filterType: FilterType.Array,
        },
        {
            name: 'lanesToBePriced',
            labelKey: 'PriceDashboard.lanesToBePriced',
            sorting: false,
        },
        {
            name: 'totalLanes',
            labelKey: 'KamDashboard.totalLanes',
            sorting: false,
        },
        {
            name: 'lastUpdated',
            labelKey: 'KamDashboard.lastUpdated',
            sorting: false,
        },
    ],
}

export default beingPricedConfig
