import {
    FieldConfig,
    SkyNetSpreadSheetCellType, SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    LeaseType,
    ProductType,
} from 'app/types/enums'
import {
    ForecastDataSpreadsheetColumnsConfig,
    ForecastSpreadsheetDataType, ForecastSpreadsheetDataTypeEnum,
} from '../Forecast.types'
import {
    ACTUALS_MONTHS_QTY,
    FORECAST_MONTHS_QTY,
} from '../Forecast.constants'

const getForecastConfig = ({
    getLabel,
    getAllowedValues,
    tmpFields,
}: {
    getLabel: (...args: any[]) => string,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
    tmpFields: ForecastDataSpreadsheetColumnsConfig
}): SkyNetSpreadSheetConfigType<keyof ForecastSpreadsheetDataType> => {
    const fields = [
        {
            name: 'accountName',
            title: getLabel('Forecasts.accountName'),
            width: 150,
            disabled: true,
        },
        {
            name: 'opportunityNumber',
            title: getLabel('Forecasts.opportunityNumber'),
            width: 100,
            disabled: true,
        },
        {
            name: 'productType',
            title: getLabel('Forecasts.productType'),
            width: 100,
            disabled: true,
            allowedValues: getAllowedValues(ProductType),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
        },
        {
            name: 'leaseType',
            title: getLabel('Forecasts.leaseType'),
            width: 100,
            disabled: true,
            allowedValues: getAllowedValues(LeaseType),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
        },
        {
            name: 'originAirportIataCode',
            title: getLabel('Forecasts.from'),
            width: 70,
            disabled: true,
        },
        {
            name: 'destinationAirportIataCode',
            title: getLabel('Forecasts.to'),
            width: 70,
            disabled: true,
        },
        {
            name: 'opportunityCollaboratorsUserName',
            title: getLabel('Opportunity.opportunityCollaborators'),
            width: 150,
            disabled: true,
        },
        {
            name: 'changedOn',
            title: getLabel('Forecasts.changedOn'),
            width: 100,
            disabled: true,
        },
        {
            name: 'changedBy',
            title: getLabel('Forecasts.changedBy'),
            width: 100,
            disabled: true,
        },
    ]

    return {
        id: 'Forecasts',
        maxHeight: window.screen.height - 120,
        headerRow: true,
        headerHeight: 60,
        rowsCounter: true,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        filterable: true,
        stickyTopRows: 1,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'account',
            rowId: 0,
        },
        superHeader: [{
            height: 25,
            cells: [
                {
                    title: '',
                    colspan: fields.length,
                },
                {
                    title: ForecastSpreadsheetDataTypeEnum.FORECAST,
                    colspan: FORECAST_MONTHS_QTY,
                },
                {
                    title: ForecastSpreadsheetDataTypeEnum.ACTUALS,
                    colspan: ACTUALS_MONTHS_QTY,
                },
            ],
        }],
        fields: [
            ...fields,
            ...tmpFields.map((fieldProps) => {
                return {
                    ...fieldProps,
                    width: 75,
                    required: true,
                    defaultValue: 0,
                    cellConfig: {
                        type: SkyNetSpreadSheetCellType.NUMBER,
                    },
                }
            }),
            {
                name: 'id',
                width: 1,
                hidden: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.ID,
                },
            },
        ] as FieldConfig<keyof ForecastSpreadsheetDataType>[],
    }
}

export default getForecastConfig
