import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import omit from 'lodash/omit'
import StatusHandler from 'app/shared-components/StatusHandler'
import HeaderWarning from 'app/shared-components/HeaderWarning'
import {
    App,
} from 'app/types/common.enums'
import {
    REDIRECT_PARAM,
} from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'

import useGetLaneGroup from '../hooks/useGetLaneGroup'
import LaneGroupForm from '../LaneGroupForm'
import LaneGroupRequests from '../LaneGroup.requests'

type Props = {
    id: number
}

const LaneGroupCopy = ({
    id,
}: Props) => {
    const history = useHistory()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLaneGroup(id)

    const value = useMemo(() => {
        return {
            ...omit(data, [
                'id',
                'changedBy',
                'changedOn',
                'laneGroupNumber',
            ]),
            originId: data?.id,
        }
    }, [data])

    const onSuccess = useCallback(({
        id: copyId,
    }) => {
        invalidate()
        history.push(`/apps/${App.PRICING}/lane-groups/all/edit/${copyId}/overview`, REDIRECT_PARAM)
    }, [
        history,
        invalidate,
    ])

    const onCancel = useCallback(() => {
        history.back()
    }, [history])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <HeaderWarning>
                    You are deep copying a lane group.
                    All awarded/implemented lanes and most recent pricing will be copied as well.
                </HeaderWarning>
                <LaneGroupForm
                    data={value}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParam={LaneGroupRequests.duplicate({
                        id: value?.originId,
                    })}
                />
            </>
        </StatusHandler>
    )
}

export default LaneGroupCopy
