import React, {
    useMemo,
} from 'react'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import Card from 'app/shared-components/Card'
import useEnumValues from 'app/hooks/useEnumValues'

import displayComponentsFields from './internalTransportsOverview.fields'
import CancellationOrderDialog from '../../Components/CancellationOrderDialog'
import useStyles from './InternalTransportsOverview.styles'
import useCancelOrder from '../../hooks/useCancelOrder'
import {
    OrderSubType,
    CancelOrderData, Order,
} from '../../orders.types'
import OrderChangeSummary from '../../Components/OrderChangeSummary'
import useGetOrderByNumber from '../../hooks/useGetOrderByNumber'
import OrderComment from '../../Components/OrderComment'

const DOMAIN_NAME = 'OrderByNumberView'

const InternalTransportsOverview = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        data = {} as Order,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetOrderByNumber(orderNumber)

    const {
        previousOrders,
        orderSubType,
        orderStatus,
    } = data
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const isGeneralFreight: boolean = useMemo(() => {
        return orderSubType === OrderSubType.GENERAL_FREIGHT
    }, [orderSubType])

    const fields = useFieldsWithClassName(
        displayComponentsFields({
            orderStatus,
            orderNumber,
            previousOrders,
            isGeneralFreight,
            getAllowedValues,
        }),
        classes,
    )

    const {
        isShowCancel,
        openCancellation,
        setCancellationOpen,
    }: CancelOrderData = useCancelOrder({
        orderStatus,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <DomainObjectForm
                    value={data}
                    className={previousOrders?.length
                        ? classes.gridWrapperPreviousOrders
                        : classes.gridWrapper}
                    fields={fields}
                    disabled
                    name={DOMAIN_NAME}
                />
                <ButtonContainer>
                    {isShowCancel && (
                        <Button
                            secondary
                            label="cancel order"
                            onClick={() => { setCancellationOpen(true) }}
                        />
                    )}
                </ButtonContainer>
                <OrderComment orderNumber={orderNumber} />
                <Card
                    className={classes.root}
                    contentClass={classes.wrapper}
                    title="Order Change Summary"
                    titleClass={classes.title}
                >
                    <OrderChangeSummary orderNumber={orderNumber} />
                </Card>
                <CancellationOrderDialog
                    orderNumber={orderNumber}
                    onSuccess={invalidate}
                    open={openCancellation}
                    setOpen={setCancellationOpen}
                />
            </>
        </StatusHandler>
    )
}

export default InternalTransportsOverview
