import React, {
    useState,
    useCallback,
} from 'react'
import compact from 'lodash/compact'
import {
    useDropzone,
} from 'react-dropzone'

import useUploadFile from './hook/useUploadFile'
import DropZone from './DropZone'

const DEFAULT_FILE_CONTEXT_TYPE = 'application/octet-stream'
const fileToOctetStreamBlob = async (file: File) => {
    return new Blob([new Uint8Array(await file.arrayBuffer())], {
        type: DEFAULT_FILE_CONTEXT_TYPE,
    })
}

type Props = {
    onChange: (value: string[]) => void,
    required?: boolean,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    required: false,
    disabled: false,
}

function DropZoneContainer({
    onChange,
    required,
    disabled,
}:Props) {
    const [
        state,
        setState,
    ] = useState({
        uploaded: false,
        loaded: false,
    })

    const uploadFile = useUploadFile()

    const uploadFiles = useCallback((files: File[]) => {
        Promise.all(files.map(async (file) => {
            const formData = new FormData()

            formData.append('file', await fileToOctetStreamBlob(file), file.name)
            formData.append('name', file.name)
            return uploadFile(formData)
        })).then((attachmentsIds) => {
            onChange(compact(attachmentsIds))
        })
        setState({
            ...state,
            uploaded: true,
        })
    }, [
        state,
        uploadFile,
        onChange,
    ])

    const onDrop = useCallback((files) => {
        setState({
            ...state,
            loaded: true,
        })
        uploadFiles(files)
    }, [
        state,
        uploadFiles,
    ])

    const {
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        noClick: true,
        disabled,
    })

    return (
        <DropZone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            open={open}
            required={required}
            disabled={disabled}
        />
    )
}

DropZoneContainer.defaultProps = defaultProps

export default DropZoneContainer
