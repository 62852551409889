import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

const config: SkyNetTableConfig = {
    labelEntityName: 'BillingCompanyView',
    tabs: [{
        name: 'All Billing Companies',
        url: 'all',
    }],
    fields: [
        {
            name: 'billingLocationName',
            filterField: 'billingLocationName',
            reduced: true,
        },
        {
            name: 'street',
            filterField: 'street',
            filterType: FilterType.Array,
        },
        {
            name: 'zip',
            filterField: 'zip',
            filterType: FilterType.Array,
        },
        {
            name: 'city',
            filterField: 'city',
        },
        {
            name: 'countryCountryName',
            filterField: 'countryCountryName',
            filterType: FilterType.Array,
        },
        {
            name: 'companyCompanyName',
            filterField: 'companyCompanyName',
        },
        {
            name: 'isSyncedWithAbacus',
            componentName: 'ChipCheckMark',
        },
    ],
}

export default config
