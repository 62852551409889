import {
    RequestConfig, RequestForDomainForm, SelectorConfig,
} from 'app/types/request.types'

type SelectorParams = {
    params?: Record<string, any>,
    includeFilters?: Record<string, any>,
}

type Accounts = {
    domainName: string,
    filterRequest: ({
        params,
    }) => RequestConfig,
    get: ({
        id,
    }:{
        id: number,
    }) => RequestConfig,
    update: RequestForDomainForm<{id: number}>,
    create: RequestForDomainForm<undefined>,
    selector: ({
        params, includeFilters,
    }
    ?: SelectorParams) => SelectorConfig<{companyName: string, id: number}[]>,
    archive: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    restore: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    getContactsByAccount: ({
        accountId,
        params,
    }: {
        accountId: number,
        params: Record<string, any>,
    }) => RequestConfig,
    getRegionalGroupsByAccount: ({
        accountId: number,
    }) => RequestConfig,
    filterCountRequestContacts: ({
        accountId,
    }: {accountId: number}) => {
        customUrl: string
    },
    getAccounts: (params) => RequestConfig,
}

export const accountConversion = (accounts) => {
    return accounts.map(({
        companyName, id,
    }) => {
        return {
            label: companyName,
            value: id,
            id,
        }
    })
}

const request: Accounts = {
    domainName: 'Account',
    filterRequest: ({
        params,
    }) => {
        return {
            url: 'account/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `account/${id}`,
        }
    },
    update: {
        name: 'Account',
        request: ({
            id,
        }) => {
            return {
                url: `account/${id}`,
                method: 'PUT',
                domainName: 'Account',
            }
        },
    },
    create: {
        name: 'Account',
        request: () => {
            return {
                url: 'account',
                method: 'POST',
                domainName: 'Account',
            }
        },
    },
    selector: ({
        params, includeFilters,
    } = {}) => {
        return {
            url: 'account/filter-request/selector',
            convertion: accountConversion,
            params,
            includeFilters,
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `account/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `account/${id}/restore`,
            method: 'PATCH',
        }
    },
    getContactsByAccount: ({
        params,
        accountId,
    }) => {
        return {
            url: `account/${accountId}/contact/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    getRegionalGroupsByAccount: ({
        accountId,
    }) => {
        return {
            url: `account/${accountId}/regionalgroup/fields`,
            method: 'GET',
        }
    },
    filterCountRequestContacts: ({
        accountId,
    }) => {
        return {
            customUrl: `account/${accountId}/contact/filter-request/all`,
        }
    },
    getAccounts: (params) => {
        return {
            url: 'account/filter-request/selector',
            method: 'POST',
            data: {
                ...params,
            },
            convertion: accountConversion,
        }
    },
}

export default request
