import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            height: 25,
        },
        link: {
            color: theme.palette.primary.dark,
        },
        delete: {
            position: 'relative',
            top: -3,
            padding: `${theme.spacing(1.5)} ${theme.spacing(0.75)}`,
        },
        fileSize: {
            padding: `${theme.spacing(0.5)} 0 0 ${theme.spacing(0.5)}`,
            whiteSpace: 'nowrap',
        },
    }
})

export default useStyles
