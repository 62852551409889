import {
    Domain,
    DomainUIOnly,
    FieldConfig, OnChangeCellFunction,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
    TypeaheadParams,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    LaneGroupTemperatureRange, LeaseType,
} from 'app/types/enums'
import {
    CollServiceType, DeliveryServiceType, TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    AccountLane,
} from '../AccountLanes.types'

export type AccountLaneSpreadsheetType = AccountLane & {
    isTemperatureControlled: Record<string, any>
}

const AccountLaneSpreadsheetConfig = ({
    addRowMenuOption,
    getLabel,
    opportunityParams,
    getAllowedValues,
    locationAirportParams,
    customerExecutionParams,
    contactParams,
    countryParams,
    onOpportunityChange,
    isNotDeliveryByRoad,
    isNotCollection,
    isNoTransportModeRoad,
    getTemperatureControlledValues,
}: {
    addRowMenuOption: boolean,
    getLabel: (s: string) => string,
    opportunityParams: TypeaheadParams,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
    locationAirportParams: TypeaheadParams,
    customerExecutionParams: TypeaheadParams,
    contactParams: TypeaheadParams,
    countryParams: TypeaheadParams,
    onOpportunityChange: OnChangeCellFunction,
    isNotDeliveryByRoad: (l: AccountLane) => boolean,
    isNotCollection: (l: AccountLane) => boolean,
    isNoTransportModeRoad: (l: AccountLane) => boolean,
    getTemperatureControlledValues: (l: AccountLane) => Record<string, string>
}): SkyNetSpreadSheetConfigType<keyof AccountLaneSpreadsheetType> => {
    const fields:
        FieldConfig<keyof AccountLaneSpreadsheetType>[] = [
            {
                name: 'opportunity',
                title: getLabel('AccountLane.opportunity'),
                width: 120,
                required: true,
                domain: DomainUIOnly.OPPOPTUNITY,
                ...opportunityParams,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                    onChangeAutopopulate: onOpportunityChange,
                },
            },
            {
                name: 'temperatureRange',
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.SELECT,
                },
                allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                required: true,
                title: getLabel('AccountLane.temperatureRange'),
                width: 100,
                disabled: true,
            },
            {
                name: 'leaseType',
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.SELECT,
                },
                width: 120,
                allowedValues: getAllowedValues(LeaseType),
                title: getLabel('AccountLane.leaseType'),
                required: true,
            },
            {
                name: 'originAirport',
                title: getLabel('AccountLaneForm.originAirport'),
                required: true,
                ...locationAirportParams,
                domain: Domain.AIRPORT,
                width: 110,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                },
            },
            {
                name: 'destinationAirport',
                title: getLabel('AccountLaneForm.destinationAirport'),
                required: true,
                ...locationAirportParams,
                domain: Domain.AIRPORT,
                width: 110,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                },
            },
            {
                name: 'expPalletsYear',
                title: getLabel('LaneBasicView.expPalletsYear'),
                required: true,
                width: 75,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.NUMBER,
                },
            },
            {
                name: 'customerExecution',
                title: getLabel('AccountLane.customerExecution'),
                width: 140,
                domain: DomainUIOnly.LOCATION,
                ...customerExecutionParams,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                },
            },
            {
                name: 'customerExecutionProvisional',
                title: getLabel('AccountLane.customerExecutionProvisional'),
                width: 100,
            },
            {
                name: 'customerExecutionContacts',
                title: getLabel('AccountLane.customerExecutionContacts'),
                ...contactParams,
                width: 150,
                domain: DomainUIOnly.CONTACT,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD,
                },
            },
            {
                name: 'consigneeProvisional',
                title: getLabel('AccountLane.consigneeProvisional'),
                width: 100,
            },
            {
                name: 'consigneeContacts',
                title: getLabel('AccountLane.consigneeContacts'),
                ...contactParams,
                width: 150,
                domain: DomainUIOnly.CONTACT,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD,
                },
            },
            {
                name: 'laneComment',
                title: getLabel('AccountLane.laneComment'),
                width: 100,
            },
            {
                name: 'deliveryServiceType',
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.SELECT,
                },
                allowedValues: getAllowedValues(DeliveryServiceType),
                required: true,
                title: getLabel('AccountLane.deliveryServiceType'),
                width: 150,
            },
            {
                name: 'handoverPointContacts',
                title: getLabel('AccountLane.handoverPointContacts'),
                ...contactParams,
                width: 150,
                domain: DomainUIOnly.CONTACT,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD,
                },
            },
            {
                name: 'handoverPointZipCode',
                title: getLabel('AccountLane.handoverPointZipCode'),
                width: 70,
                required: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TEXT,
                    conditionalType: [[
                        isNotDeliveryByRoad,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],
                },
            },
            {
                name: 'handoverPointAddress',
                title: getLabel('AccountLane.handoverPointAddress'),
                width: 100,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TEXT,
                    conditionalType: [[
                        isNotDeliveryByRoad,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],
                },
            },
            {
                name: 'handoverPointCountry',
                title: getLabel('AccountLane.handoverPointCountry'),
                width: 100,
                required: true,
                ...countryParams,
                domain: DomainUIOnly.COUNTRY,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                    conditionalType: [[
                        isNotDeliveryByRoad,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],
                },
            },
            {
                name: 'transportModeRoad',
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.SELECT,
                    conditionalType: [[
                        isNotDeliveryByRoad,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],
                },
                allowedValues: getAllowedValues(TransportModeRoad),
                required: true,
                title: getLabel('AccountLane.transportModeRoad'),
                width: 100,
            },
            {
                name: 'isTemperatureControlled',
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.MULTIPLE_SELECT,
                    conditionalType: [
                        [
                            isNotDeliveryByRoad,
                            SkyNetSpreadSheetCellType.NO_VALUE,
                        ],
                        [
                            isNoTransportModeRoad,
                            SkyNetSpreadSheetCellType.NO_VALUE,
                        ],
                    ],
                },
                getAllowedValues: getTemperatureControlledValues,
                title: getLabel('OrderStep.isTemperatureControlled'),
                width: 150,
            },
            {
                name: 'collectionServiceType',
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.SELECT,
                },
                allowedValues: getAllowedValues(CollServiceType),
                title: getLabel('AccountLane.collectionServiceType'),
                required: true,
                width: 150,
            },
            {
                name: 'collectionDropoffPointContacts',
                title: getLabel('AccountLane.collectionDropoffPointContacts'),
                ...contactParams,
                width: 150,
                domain: DomainUIOnly.CONTACT,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD,
                },
            },
            {
                name: 'collectionDropoffPointZipCode',
                title: getLabel('AccountLane.collectionDropoffPointZipCode'),
                width: 100,
                required: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TEXT,
                    conditionalType: [[
                        isNotCollection,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],

                },
            },
            {
                name: 'collectionDropoffPointAddress',
                title: getLabel('AccountLane.collectionDropoffPointAddress'),
                width: 100,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TEXT,
                    conditionalType: [[
                        isNotCollection,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],

                },
            },
            {
                name: 'collectionDropoffPointCountry',
                title: getLabel('AccountLane.collectionDropoffPointCountry'),
                width: 100,
                ...countryParams,
                domain: DomainUIOnly.COUNTRY,
                required: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                    conditionalType: [[
                        isNotCollection,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ]],
                },
            },
        ]

    return {
        id: 'AccountLanes',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: false,
        rowsLimit: 25,
        emptyRows: addRowMenuOption ? 10 : 0,
        initialFocusLocation: {
            columnId: 'account',
            rowId: 0,
        },
        fields,
    }
}

export default AccountLaneSpreadsheetConfig
