import {
    useContext, createContext,
} from 'react'

export type LocationAreasContextType = {
    locationId: number,
}

export const LocationAreasContext = createContext<LocationAreasContextType>(null)

const useLocationAreasContext = () => {
    return useContext<LocationAreasContextType>(LocationAreasContext)
}

export default useLocationAreasContext
