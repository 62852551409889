import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AccountQuote,
} from '../../AccountQuotes.types'
import requests from '../../AccountQuotes.requests'

export default ({
    onSuccess,
    setIsEdited,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessCreateAccountQuote,
        onError,
    } = useHandleStatusRequest({
        title: 'New account quote was created',
        id: 'AccountQuoteCreated',
        onSuccess,
    })

    const {
        mutateAsync: createAccountQuote,
    } = useMutation({
        mutationFn: (accountQuote: AccountQuote) => {
            return simpleApi({
                ...requests.create({
                    data: accountQuote,
                }),
                token,
            })
        },
        onError,
    })

    return useCallback(async (accountQuote: AccountQuote) => {
        setIsEdited(false)
        return createAccountQuote(accountQuote)
            .then((data) => {
                onSuccessCreateAccountQuote(data)
            })
    }, [
        createAccountQuote,
        setIsEdited,
        onSuccessCreateAccountQuote,
    ])
}
