import React, {
    useCallback,
} from 'react'
import noop from 'lodash/noop'

import {
    SensorCode,
} from 'app/types/enums'
import downloadDocument from 'app/utils/downloadDocument'

import Button from '../Button'
import Card from '../Card'

import useStyles from './DownloadOptions.style'

type Props = {
    showPdfButton?: boolean,
    showCsvButton?: boolean,
    sensorData?: any[],
    sensorCodes?: SensorCode[],
    serialNumber?: string,
    printChart?: ()=>void,
    isContainer?: boolean,
    title?: string,
    children?: JSX.Element[],
}

const defaultProps: Partial<Props> = {
    showPdfButton: false,
    showCsvButton: false,
    sensorData: undefined,
    sensorCodes: undefined,
    serialNumber: '',
    printChart: noop,
    isContainer: false,
    children: undefined,
    title: 'Options',
}

const DownloadOptions = ({
    showPdfButton,
    showCsvButton,
    serialNumber,
    sensorData,
    sensorCodes,
    printChart,
    isContainer,
    title,
    children,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const exportCsv = useCallback(() => {
        const csvContent = `${sensorData.map((sensorDataElement) => {
            const csvArray = [
                isContainer ? serialNumber : undefined,
                ...sensorDataElement,
            ].filter((item) => {
                return item !== null && item !== undefined
            })

            return csvArray.join(',')
        }).join('\n')}`

        const baseColumns = [
            isContainer ? 'SERIAL_NUMBER' : undefined,
            'TIMESTAMP',
            ...sensorCodes,
        ].filter(Boolean).join(',')

        downloadDocument(
            {
                headers: {
                    'content-type': 'text/csv',
                },
                data: `${baseColumns}\n${csvContent}`,
            },
            `sensor_data_${serialNumber}`,
        )
    }, [
        sensorData,
        sensorCodes,
        serialNumber,
        isContainer,
    ])

    return (
        <Card title={title}>
            {children}
            <div
                className={classes.containerBtn}
            >
                {showPdfButton && (
                    <Button
                        className={classes.btnControl}
                        label="pdf"
                        isUpperCase
                        onClick={printChart}
                    />
                )}
                {showCsvButton && (
                    <Button
                        className={classes.btnControl}
                        label="csv"
                        isUpperCase
                        onClick={exportCsv}
                    />
                )}
            </div>
        </Card>
    )
}

DownloadOptions.defaultProps = defaultProps

export default DownloadOptions
