import React from 'react'
import {
    RequestConfig,
} from 'app/types/request.types'

import useRequestTable from 'app/hooks/useRequestTable'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import getColumns from './ChangeHistory.columns'
import useStyles from './ChangeHistory.styles'
import {
    Modification,
} from './ChangeHistory.types'

type Props = {
    id: number,
    config: (args: Record<string, any>) => RequestConfig,
    configName: string,
    name: string,
    key: RequestKeys,
    labelPrefixForChangedColumn: string[],
}

const ChangeHistory = ({
    id,
    key,
    config,
    configName,
    name,
    labelPrefixForChangedColumn,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        loadData: load,
    } = useRequestTable<Modification>({
        key,
        params: {
            id,
        },
        config,
        enabled: Boolean(id),
    })

    return (
        <Card
            title="Change History"
        >
            <RichTable
                configName={configName}
                name={name}
                load={load}
                columns={getColumns(labelPrefixForChangedColumn)}
                uniqField="id"
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}

export default ChangeHistory
