/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {
    useHistory,
} from 'react-router-dom'

import objectNames from 'app/utils/objectNames'

import DomainObjectCreate from './DomainObjectCreate'

const propTypes = {
    backUrl: PropTypes.string.isRequired,
    form: PropTypes.func.isRequired,
    name: PropTypes.oneOf(objectNames).isRequired,
}

const DomainObjectCreateContainer = (props) => {
    const {
        backUrl,
    } = props

    const history = useHistory()
    const onClose = useCallback(() => {
        history.push(backUrl)
    }, [
        backUrl,
        history,
    ])

    return (
        <DomainObjectCreate
            {...props}
            onClose={onClose}
        />
    )
}

DomainObjectCreateContainer.propTypes = propTypes

export default DomainObjectCreateContainer
