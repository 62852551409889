import {
    NestedField,
} from 'app/types/form.types'
import {
    Timezone,
} from 'app/types/enums'
import {
    LocationFormFields,
} from '../LocationForm.types'

const getAddressFieldDetails = ({
    country,
    categoriesValue,
    timeZones,
    allowedCategories,
    setAutocomplete,
    currentPlaceId,
    value,
    timeZone,
    isGoogleMapsServiceLoaded,
    isAddressType,
    getAllowedValues,
    isProspect,
    isShippingLocation,
}): NestedField<LocationFormFields>[] => {
    const addressFields: NestedField<LocationFormFields>[] = [
        isGoogleMapsServiceLoaded
            ? {
                name: 'addressLine1',
                componentName: 'GoogleAddress',
                required: !isProspect,
                setAutocomplete,
                currentPlaceId,
                formData: value,
                isGoogleMapsServiceLoaded,
                labelKey: 'Location.addressLine1',
            } : {
                name: 'addressLine1',
                required: true,
                componentName: 'InputSingleline',
                labelKey: 'Location.addressLine1',
            },
        {
            name: 'addressLine2',
            componentName: 'InputSingleline',
            labelKey: 'Location.addressLine2',
        },
        {
            name: 'addressLine3',
            componentName: 'InputSingleline',
            labelKey: 'Location.addressLine3',
        },
    ]
    const categoriesFields: NestedField<LocationFormFields> = {
        name: 'addressCategory',
        componentName: 'MultiEnumSelector',
        title: 'Categories',
        value: categoriesValue,
        allowedValues: allowedCategories,
        required: !!isAddressType,
        labelKey: 'Location.addressCategory',
    }
    const companyFields: NestedField<LocationFormFields> = {
        name: 'company',
        componentName: 'CompanyObjectSelector',
        labelKey: 'Location.company',
        required: !isProspect,
    }
    const isNotProspectfields: NestedField<LocationFormFields>[] = [
        {
            name: 'isMainAddress',
            componentName: 'Switch',
            labelKey: 'Location.isMainAddress',
        },
        {
            name: 'isApprovedSubContractor',
            componentName: 'Switch',
            labelKey: 'Location.isApprovedSubContractor',
        },
    ]
    const addressFields2: NestedField<LocationFormFields>[] = [
        {
            name: 'country',
            componentName: 'CountryDataSelector',
            labelKey: 'Location.country',
            required: true,
        },
        {
            name: 'zip',
            componentName: 'ZipCodeInput',
            labelKey: 'Location.zip',
            required: true,
            country,
        },
        {
            name: 'state',
            country: country?.id,
            labelKey: 'Location.state',
            componentName: 'StateSelector',
        },
        {
            componentName: 'CitySuggestions',
            required: !isProspect,
            name: 'city',
            timeZones,
            labelKey: 'Location.city',
        },
        {
            name: 'timeZone',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(Timezone),
            required: !isProspect && isShippingLocation,
            value: timeZone,
            labelKey: 'Location.timeZone',
        },
        {
            name: 'remarks',
            componentName: 'InputSingleline',
            labelKey: 'Location.remarks',
        },
    ]

    return isProspect
        ? [
            ...addressFields,
            companyFields,
            ...addressFields2,
        ]
        : [
            ...addressFields,
            categoriesFields,
            companyFields,
            ...isNotProspectfields,
            ...addressFields2,
        ]
}

export default getAddressFieldDetails
