import React, {
    useCallback,
} from 'react'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    App,
} from 'app/types/common.enums'

import useGetAccountQuote from '../hooks/useGetAccountQuote'
import AccountQuoteUpdate from './AccountQuoteUpdate'

const AccountQuoteUpdateContainer = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetAccountQuote(id)

    const history = useHistory()
    const {
        state,
    } = useLocation()

    const onSuccess = useCallback(() => {
        if (state?.path) {
            history.push(`/apps/${App.ACCOUNT_MANAGEMENT}/quotes/all/edit/${data?.id}/overview`)
        }

        invalidate()
    }, [
        data?.id,
        history,
        invalidate,
        state?.path,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <AccountQuoteUpdate
                data={data}
                onSuccess={onSuccess}
            />
        </StatusHandler>
    )
}

export default AccountQuoteUpdateContainer
