import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        containerImg: {
            marginTop: 25,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
        },
        img: {
            height: 50,
        },
        durationHours: {
            '&&': {
                width: '50%',
                display: 'inline-block',
                boxSizing: 'border-box',
                paddingRight: theme.spacing(1),
            },
        },
        durationMinutes: {
            '&&': {
                width: '50%',
                display: 'inline-block',
                boxSizing: 'border-box',
                paddingLeft: theme.spacing(1),
            },
        },
    }
})

export default useStyles
