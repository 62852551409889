import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import {
    LocationAreas,
} from '../LocationAreas.types'
import LocationAreasForm from '../LocationAreasForm'
import LocationAreasRequests from '../LocationAreas.requests'
import useLocationAreasContext from '../hooks/useLocationAreasContext'

import {
    useGetLocation,
} from '../../hooks'

type Props = {
    onSuccess: (...args: any[]) => void,
    onCancel: (...args: any[]) => void,
}

const LocationAreasCreate = ({
    onSuccess,
    onCancel,
}: Props) => {
    const {
        locationId,
    } = useLocationAreasContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLocation(locationId)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <LocationAreasForm
                onSuccess={onSuccess}
                onCancel={onCancel}
                requestParam={LocationAreasRequests.create({
                    locationId,
                })}
                data={{
                    latitude: data?.latitude,
                    longitude: data?.longitude,
                } as LocationAreas}
            />
        </StatusHandler>
    )
}

export default LocationAreasCreate
