import {
    LeaseType, RegionFirstLevel,
    ProductType, TemperatureRange,
} from 'app/types/enums'
import {
    CellConfig,
} from 'app/shared-components/SkyNetSpreadSheet/SkyNetSpreadSheet.types'

export enum ForecastSpreadsheetDataTypeEnum {
    FORECAST = 'Forecast',
    ACTUALS = 'Actuals',
}

export type ForecastData = {
    year: number,
    month: number,
    units: number,
}

export type ForecastDataSpreadsheetColumnsConfig = {
    name: string,
    title: string,
    disabled?: boolean,
    cellConfig?: CellConfig<any>
}[]

export type ForecastType = {
    id: number,
    isConfirmed: boolean,
    opportunityNumber: string,
    accountName: string,
    productType: ProductType,
    originAirportIataCode: string,
    destinationAirportIataCode: string,
    changedOn: string,
    changedBy: string,
    leaseType: LeaseType,
    forecasts: ForecastData[],
    actuallyShippedContainers: ForecastData[],
    responsibleUsers: string[],
    opportunityCollaborators?: { id: number, userName: string, email: string }[],
    opportunityCollaboratorsUserName?: string[],
    isOngoing?: boolean,
}

export type ForecastOngoingOpportunities = ForecastType & {
    temperatureRange: TemperatureRange,
    opportunityId: number,
    originAirportIataCode: string,
    destinationAirportIataCode: string,
    forecasts: ForecastData[],
    originAirportRegion: RegionFirstLevel,
    destinationAirportRegion: RegionFirstLevel,
}

export type ForecastSpreadsheetDataType = ForecastType & {
    dataType?: ForecastSpreadsheetDataTypeEnum
} & Record<string, number>

export type ForecastOngoingOpportunitiesTable =
    ForecastOngoingOpportunities & Record<string, number>
