import {
    AddressCategory,
    ContactType,
} from 'app/types/enums'

const getAllowedCategoriesTypes = (
    mainCategories,
    categories,
    isLocation = true,
    getAllowedValues,
) => {
    const allowedTypes = categories
        ? mainCategories?.map((type) => {
            if (categories.includes(type.value)) {
                if (isLocation) {
                    const addressCategoryValues = getAllowedValues(AddressCategory)

                    return {
                        [type.addressCategory]:
                            addressCategoryValues[type.addressCategory],
                    }
                }

                const contactTypeValues = getAllowedValues(ContactType)

                return {
                    [type.contactCategory]:
                        contactTypeValues[type.contactCategory],
                }
            }
            return null
        }).filter(Boolean)
        : []

    return allowedTypes?.length ? Object.assign(...allowedTypes) : allowedTypes
}

export default getAllowedCategoriesTypes
