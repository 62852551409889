import React from 'react'

import LocationAreasTable from './LocationAreasTable'
import LocationAreasContextWrapper from './LocationAreasContextWrapper'

const LaneFees = ({
    id,
}: { id: number }) => {
    return (
        <LocationAreasContextWrapper id={id}>
            <LocationAreasTable />
        </LocationAreasContextWrapper>
    )
}

export default LaneFees
