import React, {
    useCallback, useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import StatusHandler from 'app/shared-components/StatusHandler'

import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import {
    countFurtherMonths, countPreviousMonths,
    dataToSpreadsheetColumnsMap,
} from '../../Forecast.utils'
import useGetForecast from '../../hooks/useGetForecast'
import ShortTermForecastForm from '../ShortTermForecastForm'
import {
    ForecastDataSpreadsheetColumnsConfig, ForecastSpreadsheetDataTypeEnum,
} from '../../Forecast.types'
import requests from '../../Forecast.requests'

const ShortTermForecastUpdate = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetForecast(id)

    const fieldsMap = useMemo(() => {
        return {
            forecastFields: countFurtherMonths(),
            actualFields: countPreviousMonths(),
        }
    }, [])

    const getFieldsMapper = useCallback(dataToSpreadsheetColumnsMap, [])

    const tmpFields: ForecastDataSpreadsheetColumnsConfig = useMemo(() => {
        return [
            ...Array.from(fieldsMap.forecastFields).sort().reverse().map(getFieldsMapper({
                disabled: false,
            })),
            ...Array.from(fieldsMap.actualFields).sort().reverse().map(getFieldsMapper({
                disabled: true,
            })),
        ]
    }, [
        fieldsMap,
        getFieldsMapper,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <DataContentWrapper isEmpty={isEmpty(data)}>
                <ShortTermForecastForm
                    data={data}
                    tmpFields={tmpFields}
                    fieldsMap={fieldsMap}
                    requestParam={requests.forecastUpdate({
                        id,
                    })}
                    requiredRows={[
                        ForecastSpreadsheetDataTypeEnum.FORECAST,
                        ForecastSpreadsheetDataTypeEnum.ACTUALS,
                    ]}
                    onCancel={invalidate}
                />
            </DataContentWrapper>
        </StatusHandler>
    )
}

export default ShortTermForecastUpdate
