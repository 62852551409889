import React from 'react'
import Typography from '@mui/material/Typography'

import Button from 'app/shared-components/Button'
import Card from 'app/shared-components/Card'

import useStyles from './PrintLabelOverview.style'

type Props = {
    printLabel: () => void
}

const PrintLabelOverview = ({
    printLabel,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <Card>
            <Typography variant="h3">
                Label Printing
            </Typography>
            <div className={classes.containerBtn}>
                <Button
                    className={classes.btnControl}
                    label="Print Label"
                    isUpperCase={false}
                    onClick={printLabel}
                />
            </div>
        </Card>
    )
}

export default PrintLabelOverview
