import React, {
    useMemo,
} from 'react'

import {
    useArchivedTableControl,
} from 'app/shared-components/TableControls/ShowArchived'
import Card from 'app/shared-components/Card'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'

import useGetLaneFeesByLaneGroup from 'app/Apps/Pricing/LaneGroups/LaneFees/hooks/useGetLaneFeesByLaneGroup'
import requests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import useLaneGroupContext from 'app/Apps/Pricing/LaneGroups/hooks/useLaneGroupContext'

import columns from './LaneFees.columns'
import useStyles from './LaneFees.styles'
import generalInfoFields from './LaneFeesGeneralInfo.fields'

const defaultProps = {
    showGeneralInfoCard: false,
}

const LaneFees = ({
    showGeneralInfoCard,
    actionComponents,
}: {
    showGeneralInfoCard?: boolean,
    actionComponents: {
        Copy?: (props: Record<string, any>) => JSX.Element,
        Update: (props: Record<string, any>) => JSX.Element,
        Create?: (props: Record<string, any>) => JSX.Element
    }
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const laneGroupInfo = useLaneGroupContext()
    const {
        laneGroupId,
    } = laneGroupInfo

    const showArchived = useArchivedTableControl(requests.domainName)

    const requestsHandlers = useGetLaneFeesByLaneGroup(laneGroupId, showArchived)

    const customUrl = useMemo(() => {
        return requests.getLaneFeesByLaneGroup({
            laneGroupId,
        })?.url
    }, [laneGroupId])

    const generalFields = useFieldsWithClassName(generalInfoFields({
        getAllowedValues,
    }), classes)

    return (
        <div
            data-testid="LaneFeesTable"
            className={classes.root}
        >
            {showGeneralInfoCard ? (
                <DomainObjectForm
                    name="LaneGroupUpdateDto"
                    value={laneGroupInfo}
                    fields={generalFields}
                    classNames={{
                        gridWrapper: classes.gridWrapper,
                    }}
                    className={classes.generalCard}
                    wrapper="card"
                    disabled
                />
            ) : null}
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="LaneFeesTable-card"
            >
                <CollapsibleTable
                    title="Lane Fees"
                    domainName={requests.domainName}
                    columns={columns}
                    customUrl={customUrl}
                    requestHandlers={requestsHandlers}
                    actionComponents={actionComponents}
                />
            </Card>
        </div>
    )
}

LaneFees.defaultProps = defaultProps

export default LaneFees
