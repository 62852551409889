import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        text: {
            margin: 0,
            color: theme.palette.secondary[600],
            fontSize: theme.typography.body1.fontSize,
            whiteSpace: 'pre-wrap',
        },
    }
})

export default useStyles
