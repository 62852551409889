import {
    Column,
} from 'app/types/simpletable.types'
import getUniqueId from 'app/utils/getUniqueId'
import InputSingleline from 'app/shared-components/InputSingleline'
import EnumSelector from 'app/shared-components/EnumSelector'
import MultipleProductTypeSelector from 'app/shared-components/MultipleProductTypeSelector'

import {
    AccountLane,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    RequestedLane,
} from '../../../AccountQuotes.types'

export type QuotationOptionsTableType = RequestedLane & AccountLane & {
    baseLeaseDays: string,
    productTypes: any,
}

const getColumns = ({
    classes,
    handleChange,
    l,
    getAllowedValues,
}: {
    classes: Record<string, any>,
    l: (l:string) => string,
    handleChange: (...a: any[]) => void,
    getAllowedValues: (e: Record<string, string>) => Record<string, string>,
}): Column <keyof QuotationOptionsTableType>[] => {
    return [
        {
            label: l('AccountLane.laneNumber'),
            component: InputSingleline,
            fieldName: 'laneNumber',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneHandover'),
            component: InputSingleline,
            fieldName: 'handoverPoint',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneCollectionDropoff'),
            component: InputSingleline,
            fieldName: 'collectionDropoff',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneDeliveryType'),
            component: EnumSelector,
            fieldName: 'deliveryServiceType',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(DeliveryServiceType),
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneCollectionType'),
            component: EnumSelector,
            fieldName: 'collectionServiceType',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(CollServiceType),
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.productTypes'),
            component: MultipleProductTypeSelector,
            fieldName: 'productTypes',
            getProps: (
                {
                    temperatureRange,
                },
            ) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    temperatureRange,
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneBaseLeaseDays'),
            component: InputSingleline,
            fieldName: 'baseLeaseDays',
            required: true,
            getProps: ({
                id,
                existed,
            }) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: existed,
                    required: true,
                    placeholder: 'e.g. 1;2',
                    onChange: (value) => {
                        handleChange({
                            id,
                            baseLeaseDays: value,
                        })
                    },
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.quoteLaneComment'),
            component: InputSingleline,
            fieldName: 'otherComments',
            getProps: ({
                id,
                existed,
            }) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    onChange: (value) => {
                        handleChange({
                            id,
                            otherComments: value,
                        })
                    },
                    disabled: existed,
                }
            },
            id: getUniqueId(),
        },
    ]
}

export default getColumns
