import React from 'react'
import {
    Link,
} from 'react-router-dom'

import useStyles from './LinkToClick.styles'

const defaultProps = {
    title: '',
    classNames: undefined,
}

const LinkTo = (props: {
    title?: string,
    classNames?: Record<string, string>,
    onClick: () => void,
}) => {
    const {
        title,
        classNames,
        onClick,
    } = props
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <div className={classes.root}>
            <div className={cx(classNames?.text, classes.wrapper)}>
                <Link
                    to="/#"
                    onClick={onClick}
                    className={classes.text}
                >
                    {title}
                </Link>
            </div>
        </div>
    )
}

LinkTo.defaultProps = defaultProps

export default LinkTo
