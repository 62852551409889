import React from 'react'
import Label from 'app/shared-components/Label'
import clsx from 'clsx'
import useStyles from './BooleanReader.styles'

const defaultProps = {
    classNames: {
        label: '',
        text: '',
    },
    title: '',
}

const BooleanReader = ({
    className,
    name,
    title,
    value,
    classNames,
}: {
    className: string,
    name: string,
    title?: string,
    value: boolean,
    classNames?: {
        label?: string,
        text?: string | Record<string, any>
    },
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div
            className={className}
            data-testid={`booleanReader${name}`}
        >
            {title ? (
                <Label
                    title={title}
                    className={classNames.label}
                />
            ) : null }
            <div className={clsx(classes.text, classNames.text)}>
                {value ? 'Yes' : 'No'}
            </div>
        </div>
    )
}

BooleanReader.defaultProps = defaultProps

export default BooleanReader
