import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, _, classes: Record<string, any>) => {
    return {
        root: {},
        linksContainer: {
            position: 'relative',
            top: theme.spacing(0.15),
        },
        attachmentWrapper: {
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(0.125),
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: 7,
                width: 6,
                height: 120,
                backgroundColor: theme.palette.primary.main,
                display: 'none',
                borderRadius: 3,
            },
        },
        isRequired: {
            [`& ${classes.requiredMark}`]: {
                display: 'block',
            },
            backgroundColor: theme.palette.primary[30],
        },
    }
})

export default useStyles
