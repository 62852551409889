import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'

export default ({
    onSuccess,
}: {
    onSuccess: (...args: any[]) => void,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessLaneFeesStatusUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Lane Fees was awarded',
        id: 'AwardBulkLaneFees',
        onSuccess,
    })

    const {
        mutate: awardedBulk,
    } = useMutation({
        mutationFn: (laneFees: { id: number }[]) => {
            return simpleApi({
                ...requests.awardedBulk({
                    laneFees,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLaneFeesStatusUpdate,
    })

    return useCallback((laneFees) => {
        awardedBulk(laneFees)
    }, [awardedBulk])
}
