import React, {
    useMemo,
} from 'react'
import useLabel from 'app/hooks/useLabel'
import DuplicationCheckDialog from 'app/shared-components/DuplicationCheckDialog'
import getColumns, {
    CompanyDuplication,
} from './CompanyDuplicationCheckDialog.columns'

const TABLE_NOTIFICATION = 'Warning: There are similar companies already in the system.\n'
    + 'Please make sure you are not creating a duplicate'

type Props = {
    data?: CompanyDuplication[]
    close: () => void,
    createCompany: (...args: any[]) => void,
}

const defaultProps = {
    data: null,
}

const CompanyDuplicationCheckDialog = ({
    data,
    createCompany,
    close,
}: Props) => {
    const l = useLabel()

    const columns = useMemo(() => {
        return getColumns(l)
    }, [l])

    return (
        <DuplicationCheckDialog<CompanyDuplication>
            columns={columns}
            close={close}
            create={createCompany}
            message={TABLE_NOTIFICATION}
            data={data}
        />
    )
}

CompanyDuplicationCheckDialog.defaultProps = defaultProps

export default CompanyDuplicationCheckDialog
