import createReducer from 'app/utils/createReducer'

import {
    SET_FIRST_STEP,
    SET_SECOND_STEP,
    RESET_SECOND_STEP,
    SET_THIRD_STEP,
    RESET_THIRD_STEP,
    SET_FOURTH_STEP,
    RESET_FOURTH_STEP,
    SET_FIFTH_STEP,
    RESET_FIFTH_STEP,
} from './actions'

const reducer = createReducer({
    [SET_FIRST_STEP]: (state, {
        newValue,
    }) => {
        return {
            ...state,
            firstStep: {
                ...state.firstStep,
                ...newValue,
            },
        }
    },
    [SET_SECOND_STEP]: (state, {
        newValue,
    }) => {
        return {
            ...state,
            secondStep: {
                ...state.secondStep,
                ...newValue,
            },
        }
    },
    [RESET_SECOND_STEP]: (state) => {
        return {
            ...state,
            secondStep: {},
            thirdStep: {},
            fourthStep: {},
            fifthStep: {},
        }
    },
    [SET_THIRD_STEP]: (state, {
        newValue,
    }) => {
        return {
            ...state,
            thirdStep: {
                ...state.thirdStep,
                ...newValue,
            },
        }
    },
    [RESET_THIRD_STEP]: (state, {
        selectedLaneId,
    }) => {
        if (selectedLaneId) {
            return {
                ...state,
                secondStep: {},
                thirdStep: {},
                fourthStep: {},
                fifthStep: {},
            }
        }

        return {
            ...state,
            thirdStep: {},
            fourthStep: {},
            fifthStep: {},
        }
    },
    [SET_FOURTH_STEP]: (state, {
        newValue,
    }) => {
        return {
            ...state,
            fourthStep: {
                ...state.fourthStep,
                ...newValue,
            },
        }
    },
    [RESET_FOURTH_STEP]: (state) => {
        return {
            ...state,
            fourthStep: {},
            fifthStep: {},
        }
    },
    [SET_FIFTH_STEP]: (state, {
        newValue,
    }) => {
        return {
            ...state,
            fifthStep: {
                ...state.fifthStep,
                ...newValue,
            },
        }
    },
    [RESET_FIFTH_STEP]: (state) => {
        return {
            ...state,
            fifthStep: {},
        }
    },
})

export default reducer
