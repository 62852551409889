import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    InvoiceDesignation,
} from 'app/types/enums'
import {
    InvoiceNormalAddon,
    OutgoingInvoiceFields,
    InvoiceBulkOrIndividual,
    InvoiceStatus,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getInvoiceFields = (
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): DTOCardFormField<OutgoingInvoiceFields>[] => {
    return [{
        title: 'Invoice',
        className: 'invoice',
        id: 'invoice',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'invoiceDesignation',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(InvoiceDesignation),
                        disabled: true,
                        labelKey: 'OutgoingInvoiceView.invoiceDesignation',
                    },
                    {
                        name: 'invoiceDate',
                        componentName: 'DateSelect',
                        labelKey: 'OutgoingInvoiceView.invoiceDate',
                    },
                    {
                        name: 'invoiceNormalAddon',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(InvoiceNormalAddon),
                        useDropdown: true,
                        labelKey: 'OutgoingInvoiceView.invoiceNormalAddon',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'invoiceBulkOrIndividual',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(InvoiceBulkOrIndividual),
                        useDropdown: true,
                        labelKey: 'OutgoingInvoiceView.invoiceBulkOrIndividual',
                    },
                    {
                        name: 'invoiceTitle',
                        componentName: 'InputSingleline',
                        labelKey: 'OutgoingInvoiceView.invoiceTitle',
                    },
                    {
                        name: 'laneInfo',
                        componentName: 'InputSingleline',
                        labelKey: 'OutgoingInvoiceView.laneInfo',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [{
                    name: 'invoiceComment',
                    componentName: 'LongText',
                    labelKey: 'OutgoingInvoiceView.invoiceComment',
                }],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'invoiceSentToAbacusOn',
                        componentName: 'DateSelect',
                        disabled: true,
                        labelKey: 'OutgoingInvoiceView.invoiceSentToAbacusOn',
                    },
                    {
                        name: 'changedBy',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'OutgoingInvoiceView.changedBy',
                    },
                    {
                        name: 'invoiceStatus',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(InvoiceStatus),
                        disabled: true,
                        labelKey: 'OutgoingInvoiceView.invoiceStatus',
                    },
                ],
            },
        ],
    }]
}

export default getInvoiceFields
