import React, {
    useMemo,
} from 'react'

import {
    NotificationTypes,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import useStyles from './NotificationType.style'

type Props = {
    value?: NotificationTypes,
}

const defaultProps: Partial<Props> = {
    value: undefined,
}

const NotificationType = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValues = useEnumValues()(NotificationTypes)

    const iconName: string = useMemo(() => {
        return value === NotificationTypes.NOTIFICATION ? 'notification' : 'follow_up'
    }, [value])

    return (
        <>
            {!value && '-'}
            {value && (
                <>
                    <img
                        className={classes.icon}
                        src={`assets/images/notifications/${iconName}.svg`}
                        alt={iconName}
                    />
                    {allowedValues[value]}
                </>
            )}
        </>
    )
}

NotificationType.defaultProps = defaultProps

export default NotificationType
