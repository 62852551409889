import React, {
    useState,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'

import useStyles from './UpdateLocationDialog.style'
import fields from './updateLocationDialog.fields'
import {
    UpdateLocationType,
} from '../../OrderSteps.types'
import useUpdateLocation from '../hooks/useUpdateLocation'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void,
    onSuccess: () => void,
    orderNumber: string,
}

const DIALOG_TITLE = 'Update Collection/Dropoff Location'

const UpdateLocationDialog = ({
    open,
    setOpen,
    onSuccess,
    orderNumber,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState<UpdateLocationType>({} as UpdateLocationType)

    const onSuccessUpdateLocation = (): void => {
        setOpen(false)
        onSuccess()
    }

    const {
        isPending: isLoading,
        mutate,
    } = useUpdateLocation(onSuccessUpdateLocation)

    const updateLocation = () => {
        mutate({
            orderNumber,
            data: {
                ...value,
                containers: value.containers?.map((containerId) => {
                    return {
                        id: containerId,
                    }
                }),
            },
        })
    }

    const handleClose = (action: boolean): void => {
        if (!action) {
            setOpen(false)
            setValue(null)

            return
        }

        updateLocation()
    }

    const fieldsWithClasses = useFieldsWithClassName(fields, classes)

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="modify"
            negativeLabel="cancel"
            isLoading={isLoading}
            classNames={{
                paper: classes.paper,
            }}
        >
            <DomainObjectForm
                fields={fieldsWithClasses}
                name="ModifyRequiredContainers"
                value={value}
                onChange={setValue}
                className={classes.gridWrapper}
                wrapper="simple"
            />
        </ConfirmationDialog>
    )
}

export default UpdateLocationDialog
