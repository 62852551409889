import React from 'react'
import noop from 'lodash/noop'
import clsx from 'clsx'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import {
    Column,
} from 'app/types/simpletable.types'

import useStyles from './SimpleTable.styles'

type Props = {
    columns: Column<any>[],
    data?: Record<string, any>[],
    component?: (...args: any) => JSX.Element,
    onChange?: (...args: any) => void,
    addEntry?: (...args: any) => void,
    onDelete?: (...args: any) => void,
    disabled?: boolean,
    name?: string,
    renderComponent?: (...args: any) => JSX.Element,
    uniqField?: string,
    classNames?: Record<string, any>,
}

const defaultProps = {
    onChange: () => {},
    addEntry: noop,
    onDelete: noop,
    data: undefined,
    disabled: false,
    name: '',
    renderComponent: undefined,
    component: undefined,
    uniqField: null,
    classNames: {},
}
const SimpleTable = (props: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        uniqField,
        columns,
        data,
        component: Component,
        onChange,
        addEntry,
        onDelete,
        disabled,
        renderComponent,
        classNames,
    } = props

    return (
        <TableContainer>
            <Table
                className={classNames.table}
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        {columns && columns.map((column) => {
                            return (
                                <TableCell
                                    classes={{
                                        root: classes.tableCell,
                                        head: classNames.tableCellHead,
                                    }}
                                    align={column.align}
                                    key={column.id}
                                    width={column.width}
                                >
                                    {column.label}
                                </TableCell>
                            )
                        })}
                        {!disabled && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((currentData) => {
                        if (renderComponent) {
                            const rowComponent = renderComponent({
                                key: uniqField || currentData.id,
                                value: currentData,
                                onChange,
                                onDelete,
                                disabled,
                            })

                            return (
                                <React.Fragment key={currentData.id}>
                                    {rowComponent}
                                </React.Fragment>
                            )
                        }

                        if (Component) {
                            return (
                                <Component
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...props}
                                    key={currentData.id}
                                    value={currentData}
                                    onChange={onChange}
                                    onDelete={onDelete}
                                    disabled={disabled}
                                />
                            )
                        }

                        return (
                            <TableRow>
                                {
                                    (columns || []).map((column) => {
                                        return (
                                            <TableCell
                                                classes={{
                                                    root: classes.tableCell,
                                                }}
                                                align={column.align}
                                                key={`${column.id}-${currentData?.[uniqField]}`}
                                                width={column.width}
                                            >
                                                {currentData?.[column.fieldName] || ''}
                                            </TableCell>
                                        )
                                    })
                                }
                                <TableCell />
                            </TableRow>
                        )
                    })}
                    {!disabled
                        && (
                            <TableRow>
                                <TableCell />
                                <TableCell
                                    classes={{
                                        root: classes.tableCell,
                                    }}
                                    colSpan={columns.length}
                                    align="right"
                                >
                                    <Button
                                        className={classes.btn}
                                        component="button"
                                        color="secondary"
                                        onClick={addEntry}
                                        data-testid="button-add"
                                    >
                                        <AddIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}

                    {disabled && data?.length === 0
                        ? (
                            <TableRow>
                                <TableCell
                                    classes={{
                                        root: clsx(classes.tableCell, classes.noDataRow),
                                    }}
                                    colSpan={columns.length}
                                >
                                    {`${'There\'s no data in this table yet'}`}
                                </TableCell>
                            </TableRow>
                        ) : null}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

SimpleTable.defaultProps = defaultProps

export default SimpleTable
