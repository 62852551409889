import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        label: {
            color: `${theme.palette.secondary[400]} !important`,
            fontSize: `${theme.typography.caption.fontSize}px !important`,
            lineHeight: '1.15 !important',
            whiteSpace: 'nowrap',
            paddingTop: theme.spacing(1),
            display: 'block',
        },
    }
})

export default useStyles
