import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            background: theme.palette.primary[200],
            boxShadow: `0px 3px 6px ${theme.palette.secondary[400]}`,
        },
        controls: {
            display: 'flex',
            alignItems: 'center',
        },
        userName: {
            color: `${theme.palette.common.white} !important`,
        },
        userContainer: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.common.white,
        },
        control: {
            padding: theme.spacing(1),
        },
    }
})

export default useStyles
