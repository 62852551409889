import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    SelectedContainerFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const columns: RichTableColumns<SelectedContainerFields>[] = [
    {
        id: 'serialNumber',
        headerProps: {
            children: 'Serial Number',
        },
        mapCellProps: 'serialNumber',
    },
    {
        id: 'containerTypeContainerModel',
        headerProps: {
            children: 'Type',
        },
        mapCellProps: 'containerTypeContainerModel',
    },
]

export default columns
