import React, {
    useCallback,
} from 'react'
import {
    Link as LinkButton,
} from '@mui/material'

import BaseCell from 'app/shared-components/BaseCell'

import useStyles from './Link.styles'

const defaultProps = {
    website: '',
    className: '',
}

const Link = ({
    website, className,
}: {
    website?: string,
    className?: string
}) => {
    const {
        classes,
    } = useStyles()

    const LinkTo = useCallback((ev) => {
        ev.stopPropagation()
        window.open(`https://${website}`)
    }, [website])

    return (
        <BaseCell className={className}>
            <LinkButton
                component="button"
                variant="body1"
                color="inherit"
                className={classes.link}
                onClick={LinkTo}
            >
                {website}
            </LinkButton>
        </BaseCell>
    )
}

Link.defaultProps = defaultProps

export default Link
