import React, {
    useMemo, useState,
} from 'react'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'

import AddRemoveIcon from 'app/shared-components/CollapsableAddRemovePanel/AddRemoveIcon'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import SharedContext from 'app/shared-components/SharedContext'

import OrderCommentCreateForm from './OrderCommentCreateForm'
import useGetOrderComments from './hooks/useGetOrderComments'
import requestsConfig from './orderComment.request'
import getColumns from './orderComment.columns'
import useStyles from './OrderComment.style'

const OrderComment = ({
    orderNumber,
}: {
    orderNumber: string,
}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()
    const {
        onParamsChange,
        loadData,
        refetch,
    } = useGetOrderComments(orderNumber)

    const [
        createFormOpened,
        setCreateFormOpened,
    ] = useState(false)

    const closeCreateForm = (): void => {
        setCreateFormOpened(false)
    }

    const openCreateForm = (): void => {
        setCreateFormOpened(true)
    }

    const onCreate = () => {
        refetch()
        closeCreateForm()
    }

    const columns = useMemo(() => {
        return getColumns(l)
    }, [l])

    return (
        <Card
            className={classes.root}
            data-testid="OrderComments-card"
        >
            <div>
                <Typography variant="h3">
                    Comments
                </Typography>
                <AddRemoveIcon
                    formOpened={createFormOpened}
                    onOpenForm={openCreateForm}
                    onCloseForm={closeCreateForm}
                />
            </div>
            <Collapse in={createFormOpened}>
                <OrderCommentCreateForm
                    onSuccess={onCreate}
                    onCancel={closeCreateForm}
                    orderNumber={orderNumber}
                />
            </Collapse>
            <SharedContext value={refetch}>
                <RichTable
                    configName="OrderNotificationsTable"
                    name={requestsConfig.domainName}
                    load={loadData}
                    onParamsChange={onParamsChange}
                    columns={columns}
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                />
            </SharedContext>

        </Card>
    )
}

export default OrderComment
