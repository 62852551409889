import React from 'react'
import {
    Typography,
} from '@mui/material'

import InputSingleline from 'app/shared-components/InputSingleline'
import Label from 'app/shared-components/Label'

type Props = {
    name?: string,
    value?: string,
    text?: string,
    onChange: (value: {id: number}) => void,
    hasPermission?: boolean,
    title?: string,
    disabled?: boolean,
}

const defaultProps : Partial<Props> = {
    name: '',
    text: '',
    value: undefined,
    title: 'Accounts',
    hasPermission: false,
    disabled: false,
}

const LaneGroupTag = ({
    name,
    value,
    text,
    onChange,
    title,
    hasPermission,
    disabled,
}: Props) => {
    if (!hasPermission) {
        return (
            <div>
                <Label
                    title={title || name}
                />
                <Typography>
                    {text}
                </Typography>
            </div>
        )
    }

    return (
        <InputSingleline
            name={name}
            value={value}
            onChange={onChange}
            title={title}
            disabled={disabled}
        />
    )
}

LaneGroupTag.defaultProps = defaultProps

export default LaneGroupTag
