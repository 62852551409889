import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

const request = {
    get: () => {
        return {
            url: 'loggertypedetails/all',
        }
    },
}

const useGetLoggerTypeDetails = () => {
    const {
        data,
    } = useRequest<any[]>({
        key: RequestKeys.getLoggerTypeDetails,
        requestFunc: RequestFn.getEntity(request.get),
        enabled: true,
    })

    return data
}

export default useGetLoggerTypeDetails
