import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {
            display: 'flex',
            height: '100%',
            width: '100%',
        },
        containerBtn: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        },
        btnControl: {
            paddingTop: 10,
        },
    }
})

export default useStyles
