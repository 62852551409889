import {
    useMemo,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

/**
* Use this to get url query parameters
* @param    {Array}  options to match permission
* @return   {Object}  object where key is param and value is value of this query param
*/

const useQuery = (options) => {
    const {
        search,
    } = useLocation()

    const query = useMemo(() => { return new URLSearchParams(search) }, [search])

    const queryOptions = options.map((option) => {
        return {
            [option]: query.get(option),
        }
    })

    return queryOptions.reduce((result, item) => {
        return Object.assign(result, item)
    }, {})
}

export default useQuery
