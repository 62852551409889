import React, {
    useState, useEffect, useCallback, useRef,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import UnsavedChangesPrompt from '../UnsavedChangesPrompt'

const defaultProps = {
    promptText: 'Would you like to save your changes before you leave this page?',
    discard: undefined,
    positiveLabel: undefined,
    isPositiveAlert: false,
    action: () => {
        return Promise.resolve()
    },
}

const HistoryBlockPrompt = ({
    when, action, promptText, discard, positiveLabel, isPositiveAlert,
}: {
    when: boolean,
    discard?: () => void,
    action?: (...a: any[]) => Promise<any> | any,
    promptText?: string,
    positiveLabel?: string,
    isPositiveAlert?: boolean,
}) => {
    const history = useHistory()

    const unblockRef = useRef<Function>()

    const unblock = useCallback(() => {
        if (unblockRef && unblockRef.current) {
            unblockRef.current()
        }
    }, [])

    const [
        showPrompt,
        setShowPrompt,
    ] = useState(false)
    const [
        currentPath,
        setCurrentPath,
    ] = useState('')

    useEffect(() => {
        unblockRef.current = history.block(({
            pathname,
        }) => {
            if (when) {
                setCurrentPath(pathname)
                setShowPrompt(true)
                return false
            }
            return true
        })
        return unblock
    }, [
        history,
        unblock,
        when,
    ])

    const onSuccess = useCallback(() => {
        unblock()
        history.push(currentPath)
    }, [
        currentPath,
        history,
        unblock,
    ])

    const handleAction = useCallback(async () => {
        await action()
        onSuccess()
    }, [
        action,
        onSuccess,
    ])

    const handleDiscard = useCallback(() => {
        discard()
        onSuccess()
    }, [
        onSuccess,
        discard,
    ])

    const close = useCallback(() => {
        return setShowPrompt(false)
    }, [setShowPrompt])

    return (
        <UnsavedChangesPrompt
            action={handleAction}
            discard={discard ? handleDiscard : null}
            open={showPrompt}
            close={close}
            promptText={promptText}
            positiveLabel={positiveLabel}
            isPositiveAlert={isPositiveAlert}
        />
    )
}

HistoryBlockPrompt.defaultProps = defaultProps

export default HistoryBlockPrompt
