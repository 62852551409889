import simpleApi from 'app/services/simpleApi'

import {
    UseRequestConfig,
} from 'app/types/request.types'

const request: UseRequestConfig = {
    mutationFn: ({
        data, token,
    }) => {
        return simpleApi({
            url: 'attachment',
            method: 'POST',
            token,
            data,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`, // eslint-disable-line no-underscore-dangle
            },
        })
    },
}

export default request
