import React, {
    useCallback,
} from 'react'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetPreBooking from '../components/hooks/useGetPreBooking'
import PreBookingUpdate from './PreBookingUpdate'

const PreBookingUpdateContainer = ({
    id,
}: {id: number}) => {
    const {
        data: preBookingData,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetPreBooking(id)

    const history = useHistory()
    const {
        pathname,
    } = useLocation()

    const onSuccess = useCallback(() => {
        history.push(pathname.replace(/\/data-update$/, '/overview`'))
    }, [
        history,
        pathname,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <PreBookingUpdate
                data={preBookingData}
                onSuccess={onSuccess}
            />
        </StatusHandler>
    )
}

export default PreBookingUpdateContainer
