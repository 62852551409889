import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'

import FormWithControls from 'app/shared-components/FormWithControls'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import StatusHandler from 'app/shared-components/StatusHandler'
import SharedContextProvider from 'app/shared-components/SharedContext'
import TruckingCapacity from 'app/shared-components/TruckingCapacity'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Button from 'app/shared-components/Button'
import useHasPermission from 'app/hooks/useHasPermission'
import Fees, {
    FeesType,
} from 'app/shared-components/Fees'
import Card from 'app/shared-components/Card'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    Currency,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import useGetBillingInformation from 'app/Apps/OrderManagement/Orders/CustomerTransport/hooks/useGetBillingInformation'
import {
    useOrdersContext,
} from 'app/Apps/OrderManagement/Orders/CustomerTransport/OrdersContext'
import {
    OutgoingInvoice,
    InvoiceStatus,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useGetFeesInfo from './hooks/useGetFeesInfo'
import InvoiceSelector from './InvoiceSelector'
import getInvoiceFields from './invoices.fields'
import getOrderFields from './order.fields'
import useStyles from './Invoices.style'
import Positions from './Positions'

import RelinkLaneFeesDialog from './RelinkLaneFeesDialog'
import RelinkAdditionalFeesDialog from './RelinkAdditionalFeesDialog'
import useSendToAbacus from './hooks/useSendToAbacus'

const DOMAIN_NAME = orderManagementRequest.Invoices.name

const Invoices = ({
    id: orderNumber,
}: {id: string}) => {
    const queryClient = useQueryClient()
    const getAllowedValues = useEnumValues()

    const {
        billingInformation,
        isFetching,
        isError,
        error,
    } = useGetBillingInformation(orderNumber)

    const [
        openRelinkLaneFees,
        setRelinkLaneFeesOpen,
    ] = useState<boolean>(false)

    const [
        openRelinkAdditionalFees,
        setRelinkAdditionalFeesOpen,
    ] = useState<boolean>(false)

    const outgoingInvoiceUpdate = useHasPermission(['outgoing_invoice_update'])

    const onSuccess = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [
                RequestKeys.getBillingInformation,
                {
                    orderNumber,
                },
            ],
        })
    }, [
        orderNumber,
        queryClient,
    ])

    const {
        classes,
    } = useStyles()

    const outgoingInvoices: OutgoingInvoice[] = useMemo(() => {
        return billingInformation?.billing?.outgoingInvoices || []
    }, [billingInformation?.billing?.outgoingInvoices])

    const [
        defaultOutgoingInvoices,
        setDefaultOutgoingInvoices,
    ] = useState<OutgoingInvoice[]>()

    const currency: Currency = useMemo(() => {
        return billingInformation?.billing?.billingCurrency
    }, [billingInformation?.billing?.billingCurrency])

    const {
        selectedInvoiceId,
        setSelectedInvoiceId,
    } = useOrdersContext()

    const onSuccessCreate = useCallback((params: OutgoingInvoice): void => {
        setSelectedInvoiceId(params?.id)
        onSuccess()
    }, [
        onSuccess,
        setSelectedInvoiceId,
    ])

    const getSelectedInvoice = useCallback((): OutgoingInvoice => {
        const selectedInvoice = outgoingInvoices?.find(({
            id,
        }) => { return id === selectedInvoiceId })

        if (!selectedInvoice) {
            return outgoingInvoices[0]
        }

        return selectedInvoice
    }, [
        outgoingInvoices,
        selectedInvoiceId,
    ])

    const [
        selectedInvoice,
        setSelectedInvoice,
    ] = useState<OutgoingInvoice>()

    const {
        laneFees,
        additionalFees,
    } = useGetFeesInfo({
        orderNumber,
        selectedInvoice,
    })

    const onSuccessRelinkLaneFees = () => {
        queryClient.invalidateQueries({
            queryKey: [
                RequestKeys.getLaneFees,
                {
                    orderNumber,
                },
            ],
        })

        onSuccess()
    }

    const onSuccessRelinkAdditionalFees = () => {
        queryClient.invalidateQueries({
            queryKey: [
                RequestKeys.getAdditionalFees,
                {
                    orderNumber,
                },
            ],
        })

        onSuccess()
    }

    useEffect((): void => {
        if (defaultOutgoingInvoices === outgoingInvoices) {
            return
        }

        if (outgoingInvoices) {
            setDefaultOutgoingInvoices(outgoingInvoices)
            setSelectedInvoice(getSelectedInvoice())
        }
    }, [
        defaultOutgoingInvoices,
        getSelectedInvoice,
        outgoingInvoices,
    ])

    const onSelectInvoice = useCallback((invoice: OutgoingInvoice): void => {
        setSelectedInvoice(invoice)
        setSelectedInvoiceId(invoice?.id)
    }, [setSelectedInvoiceId])

    const disabledInvoice: boolean = selectedInvoice?.invoiceStatus !== InvoiceStatus.CREATED

    const orderFields = useFieldsWithClassName(getOrderFields({
        baseLeaseDays: billingInformation?.billing?.baseLeaseDays,
        extraDays: billingInformation?.billing?.extraDays,
        laneFeesCommentForBilling: billingInformation
            ?.billing.laneFeesCommentForBilling,
        additionalFeesCommentForBilling: billingInformation
            ?.billing.additionalFeesCommentForBilling,
        laneFees,
        additionalFees,
        getAllowedValues,
    }), classes)

    const modifyDataBeforeSend = useCallback(
        (requestData: OutgoingInvoice): Partial<OutgoingInvoice> => {
            return {
                invoiceDate: requestData.invoiceDate,
                invoiceNormalAddon: requestData.invoiceNormalAddon,
                invoiceBulkOrIndividual: requestData.invoiceBulkOrIndividual,
                invoiceTitle: requestData.invoiceTitle,
                invoiceComment: requestData.invoiceComment,
                laneInfo: requestData.laneInfo,
            }
        }, [],
    )

    const updateInvoiceParams = useMemo(() => {
        return orderManagementRequest.Invoices.Update.request(selectedInvoice?.id)
    }, [selectedInvoice?.id])

    const [
        sendingToAbacus,
        setSendingToAbacus,
    ] = useState(false)

    const onAbacusSendError = useCallback(() => {
        setSendingToAbacus(false)
    }, [])

    const onAbacusSendSuccess = useCallback(() => {
        setSendingToAbacus(false)
        onSuccess()
    }, [onSuccess])

    const {
        mutate: sendToAbacusRequest,
    } = useSendToAbacus({
        onSuccess: onAbacusSendSuccess,
        onError: onAbacusSendError,
    })

    const sendToAbacus = useCallback((): void => {
        setSendingToAbacus(true)
        sendToAbacusRequest({
            selectedInvoiceId: selectedInvoice?.id,
        })
    }, [
        selectedInvoice?.id,
        sendToAbacusRequest,
    ])

    const sendToAbacusButton = useMemo(() => {
        return (
            <Button
                label="Send to Abacus"
                onClick={sendToAbacus}
                disabled={sendingToAbacus}
            />
        )
    }, [
        sendToAbacus,
        sendingToAbacus,
    ])

    const customButtons: JSX.Element[] = useMemo(() => {
        if (selectedInvoice?.invoiceStatus === InvoiceStatus.CREATED) {
            return [sendToAbacusButton]
        }

        return []
    }, [
        selectedInvoice?.invoiceStatus,
        sendToAbacusButton,
    ])

    const disabledForm = !outgoingInvoiceUpdate || disabledInvoice
    const invoiceFields = getInvoiceFields(getAllowedValues)

    return (
        <StatusHandler
            isSuccess={!!billingInformation}
            isFetching={isFetching && !billingInformation}
            isError={isError}
            error={error}
        >
            <div>
                <InvoiceSelector
                    orderNumber={orderNumber}
                    invoices={outgoingInvoices}
                    currency={currency}
                    selectedInvoice={selectedInvoice}
                    setSelectedInvoice={onSelectInvoice}
                    onSuccess={onSuccessCreate}
                    disabled={!outgoingInvoiceUpdate}
                />
                <DomainObjectForm
                    name={DOMAIN_NAME}
                    value={billingInformation}
                    fields={orderFields}
                    className={classes.gridWrapperOrder}
                    disabled
                />
                {selectedInvoice && (
                    <>
                        <FormWithControls
                            name="OutgoingInvoiceView"
                            value={selectedInvoice}
                            fields={invoiceFields}
                            classNames={{
                                gridWrapper: disabledForm
                                    ? classes.gridWrapperInvoiceDisabled
                                    : classes.gridWrapperInvoice,
                            }}
                            onSuccess={onSuccess}
                            disabled={disabledForm}
                            modifyDataBeforeSend={modifyDataBeforeSend}
                            customButtons={customButtons}
                            requestParams={updateInvoiceParams}
                            exists
                        />
                        <Card
                            fullHeight
                            data-testid="truckingCapacity"
                            className={classes.truckingCapacity}
                            title="Max Containers Per Truck / Van"
                        >
                            <TruckingCapacity
                                value={laneFees?.truckingCapacity}
                                disabled
                            />
                        </Card>
                        <Positions
                            invoiceId={selectedInvoice.id}
                            currency={currency}
                            disabled={disabledForm}
                            canBeDeleted={
                                selectedInvoice.invoiceStatus !== InvoiceStatus.SENTTOBILLINGCOMPANY
                            }
                        />
                        <Card
                            fullHeight
                            data-testid="position-card"
                            className={classes.laneFees}
                            title="Lane Fees"
                        >
                            <Fees
                                value={laneFees?.fees}
                                fxRate={laneFees?.fxRate}
                                currency={laneFees?.contractBasisCurrency}
                                disabled
                            />
                        </Card>
                        <ButtonContainer>
                            <Button
                                label="relink lane fees"
                                onClick={() => { setRelinkLaneFeesOpen(true) }}
                                data-testid="relink-lane-fees"
                            />
                        </ButtonContainer>
                        <Card
                            fullHeight
                            data-testid="position-card"
                            className={classes.laneFees}
                            title="Additional Fees"
                        >
                            <SharedContextProvider
                                value={{
                                    feePricingName: FeesType.Additional,
                                }}
                            >
                                <Fees
                                    value={additionalFees?.fees}
                                    fxRate={additionalFees?.fxRate}
                                    currency={additionalFees?.contractBasisCurrency}
                                    disabled
                                />
                            </SharedContextProvider>
                        </Card>
                        <ButtonContainer>
                            <Button
                                label="relink additional fees"
                                onClick={() => { setRelinkAdditionalFeesOpen(true) }}
                                data-testid="relink-additional-fees"
                            />
                        </ButtonContainer>
                    </>
                )}
                <RelinkLaneFeesDialog
                    open={openRelinkLaneFees}
                    setOpen={setRelinkLaneFeesOpen}
                    onSuccess={onSuccessRelinkLaneFees}
                    orderNumber={orderNumber}
                />
                <RelinkAdditionalFeesDialog
                    open={openRelinkAdditionalFees}
                    setOpen={setRelinkAdditionalFeesOpen}
                    onSuccess={onSuccessRelinkAdditionalFees}
                    orderNumber={orderNumber}
                />
            </div>
        </StatusHandler>
    )
}

export default Invoices
