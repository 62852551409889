import {
    useParallelRequests,
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from '../Logger.request'
import {
    LoggerResponse,
} from '../Loggers.types'

const useGetLoggersByLoggerNumbers = (
    loggerNumbers: string[],
) => {
    const queries = loggerNumbers.map((loggerNumber) => {
        return {
            key: RequestKeys.getLoggerByLoggerNumber,
            params: {
                loggerNumber,
            },
        }
    })

    const enabled = Boolean(loggerNumbers.length)

    const response = useParallelRequests<LoggerResponse>({
        queries,
        enabled,
        requestFunc: RequestFn.getEntity(config.getByLoggerNumber),
    })

    return {
        response,
        isDataReady: response.every(({
            isDataReady,
        }) => {
            return isDataReady
        }),
    }
}

export default useGetLoggersByLoggerNumbers
