import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'

import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import ContactOverview from './ContactOverview'
import ContactUpdate from './ContactUpdate'
import ContactCreate from './ContactCreate'
import tableConfig from './Contacts.config'
import ContactChangeSummary from './ContactChangeSummary'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const overviewTab = {
    url: 'overview',
    label: 'Overview',
    Component: ContactOverview,
}

const Contacts = () => {
    const tabs = useMemo(() => {
        return [
            overviewTab,
            {
                url: 'dataUpdate',
                label: 'Data Update',
                Component: ContactUpdate,
            },
            {
                url: 'change-history',
                label: 'Change History',
                Component: ContactChangeSummary,
            },
        ]
    }, [])

    const hasCreatePermissions = useHasPermissionType([], 'ContactAccessRights', 'create')

    return (
        <SkyNetTable
            createForm={hasCreatePermissions ? ContactCreate : undefined}
            tabs={tabs}
            name="Contact"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Contacts
