const SELF = '{self}'

export const removeSelf = (str) => {
    if (!str) {
        return str
    }

    if (str.includes(SELF)) {
        return str.slice(SELF.length + 1)
    }

    return str
}

export const removeSelfFromKeys = (obj) => {
    if (!obj) {
        return obj
    }

    return Object.entries(obj).reduce((
        res,
        [
            key,
            value,
        ],
    ) => {
        return {
            ...res,
            [removeSelf(key)]: value,
        }
    }, {})
}
