import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        filterListContainer: {
            minWidth: 200,
            minHeight: 100,
            maxHeight: 400,
        },

        loader: {
            display: 'flex',
            justifyContent: 'center',
        },

        searchField: {
            '&&': {
                display: 'flex',
                maxWidth: '100%',
                margin: `${theme.spacing(2)} !important`,
            },
        },

        filtersList: {
            '& .MuiButtonBase-root': {
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 0,
                paddingBottom: 0,
                height: '40px !important',
            },
        },
    }
})

export default useStyles
