import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    Account,
    AccountStatus,
} from '../AccountManagement.types'

const config: SkyNetTableConfig<keyof Account> = {
    name: 'Account',
    tabs: [{
        name: 'All',
        url: 'all',
    }],
    fields: [
        {
            name: 'companyName',
            labelKey: 'Accounts.accountCompany',
            filterField: 'companyName',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'accountOwner',
            labelKey: 'Accounts.accountOwner',
            filterField: 'accountOwner',
            filterType: FilterType.Array,
        },
        {
            name: 'collaborators',
            labelKey: 'Accounts.collaborators',
            componentName: 'List',
            filterField: 'collaborators',
            filterType: FilterType.Array,
        },
        {
            name: 'accountStatus',
            labelKey: 'Accounts.status',
            componentName: 'EnumValue',
            allowedValuesEnum: AccountStatus,
            filterField: 'accountStatus',
            filterType: FilterType.Array,
        },
        {
            name: 'createdOn',
            labelKey: 'Accounts.createdOn',
        },
    ],
}

export default config
