import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import {
    Order,
    DeliveryServiceType,
    PostponeInfo,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useEnumValues from 'app/hooks/useEnumValues'

import useStyles from './PostponeDialog.style'
import getFields from './postponeDialog.fields'
import getDefaultData from './getDefaultData'
import usePostponeRequest from '../hooks/usePostponeRequest'

const DIALOG_TITLE = 'POSTPONEMENT CONFIRMATION'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    order: Order,
    onSuccess: () => void
}

const PostponeDialog = ({
    open,
    setOpen,
    order,
    onSuccess,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        deliveryServiceType,
        orderNumber,
        orderSteps,
        billing,
    } = order

    const [
        value,
        setValue,
    ] = useState<PostponeInfo>()

    useEffect(() => {
        if (open) {
            setValue(getDefaultData(orderSteps, billing?.leaseStartTimestamp))
        }
    }, [
        billing?.leaseStartTimestamp,
        open,
        orderSteps,
    ])

    const onSuccessPostpone = useCallback((): void => {
        setOpen(false)
        onSuccess()
    }, [
        onSuccess,
        setOpen,
    ])

    const {
        isPending: isLoading,
        mutate,
    } = usePostponeRequest(onSuccessPostpone)

    const postponeOrder = useCallback((): void => {
        mutate({
            orderNumber,
            data: value,
        })
    }, [
        mutate,
        orderNumber,
        value,
    ])

    const handleClose = useCallback((action: boolean): void => {
        if (!action) {
            setOpen(false)
            setValue(undefined)

            return
        }

        postponeOrder()
    }, [
        postponeOrder,
        setOpen,
    ])

    const isPickUp: boolean = useMemo(() => {
        return deliveryServiceType === DeliveryServiceType.PICKUP
    }, [deliveryServiceType])

    const fields = useMemo(() => {
        return getFields(isPickUp, getAllowedValues)
    }, [
        isPickUp,
        getAllowedValues,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="postpone"
            negativeLabel="do not postpone"
            isLoading={isLoading}
            classNames={{
                paper: classes.paper,
            }}
        >
            <DomainObjectForm
                fields={fields}
                name="PostponementOrderDto"
                value={value}
                onChange={setValue}
                className={classes.gridWrapper}
                wrapper="simple"
            />
        </ConfirmationDialog>
    )
}

export default PostponeDialog
