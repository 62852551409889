import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        blue: {
            color: `${theme.palette.secondary[700]} !important`,
        },
        red: {
            color: `${theme.palette.common.red} !important`,
        },
        grey: {
            color: `${theme.palette.secondary[600]} !important`,
        },
        green: {
            color: `${theme.palette.common.green} !important`,
        },
    }
})

export default useStyles
