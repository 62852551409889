import React from 'react'

import noop from 'lodash/noop'
import useGetCountry from 'app/Apps/Masterdata/Countries/hooks/useGetCountry'
import {
    TypeaheadObjectContainer,
} from '../Typeahead'
import useCountryRequests from './hooks/useCountryRequests'

type Props = {
    title?: string,
    value?: { id: number | string },
    onChange?: (...args: any[]) => void,
    className?: string,
    required?: boolean,
    disabled?: boolean,
}
const defaultProps: Partial<Props> = {
    title: '',
    value: null,
    onChange: noop,
    className: '',
    required: false,
    disabled: false,
}

// accepts country as object and returns an object onChange
// will be the only selector after dataModel elimination

const CountryDataSelector = ({
    title,
    value,
    onChange,
    className,
    required,
    disabled,
}: Props) => {
    const {
        loadOptions,
    } = useCountryRequests()

    const {
        data,
    } = useGetCountry(value?.id)

    return (
        <div className={className}>
            <TypeaheadObjectContainer
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={data}
                isRequired={required}
                disabled={disabled}
                labelField="countryName"
                value={value?.id}
                variant="standard"
            />
        </div>
    )
}

CountryDataSelector.defaultProps = defaultProps

export default CountryDataSelector
