import React from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import ChangeHistory from 'app/shared-components/ChangeHistory'

import config from '../Gateways.request'

const GatewayChangeHistory = ({
    id,
}: {id: number}) => {
    return (
        <ChangeHistory
            id={id}
            key={RequestKeys.getGatewayModifications}
            config={config.modifications}
            configName="GatewayChangeHistoryTable"
            name="GatewayChangeHistory"
            labelPrefixForChangedColumn={['Gateway']}
        />
    )
}

export default GatewayChangeHistory
