import React, {
    useCallback,
    useMemo,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    LoggerType,
} from 'app/types/enums'
import LogerSelectorColumns from './LogerSelector.columns'

type Props = {
    value?: {
        loggerPosition?: string,
        loggerType?: string,
        newLoggerNumber?: string,
        oldLoggerNumber?: string,
    },
    classNames?: Record<any, string>,
}

const defaultProps: Partial<Props> = {
    value: {
        loggerPosition: undefined,
        loggerType: undefined,
        newLoggerNumber: undefined,
        oldLoggerNumber: undefined,
    },
    classNames: {},
}

const LoggerSelector = ({
    value,
    classNames,
}: Props) => {
    const values = useMemo(() => {
        return Array.isArray(value) ? value : [value]
    }, [value])
    const logerTypes = useEnumValues()(LoggerType)

    const load = useCallback(() => {
        return Promise.resolve({
            data: values,
        })
    }, [values])

    return (
        <div>
            <RichTable
                configName="loggerSelector"
                name="logger"
                columns={LogerSelectorColumns(logerTypes)}
                classNames={{
                    contentWrapper: classNames.contentWrapper,
                }}
                load={load}
            />
        </div>
    )
}

LoggerSelector.defaultProps = defaultProps

export default LoggerSelector
