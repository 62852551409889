import React, {
    useCallback, useState,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import MultipleLocationSelector from 'app/shared-components/MultipleLocationSelector'
import Card from 'app/shared-components/Card'
import {
    useHistory,
} from 'react-router-dom'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useLabel from 'app/hooks/useLabel'
import ordersToBeConfirmedConfig from './ordersToBeConfirmed.config'
import bookingsWaitingForPricing from './bookingsWaitingForPricing.config'
import requests from './openBookings.requests'
import orderManagementRequest from '../services/orderManagement.request'

import {
    OrdersToBeConfirmedType,
} from './openBookings.types'
import useStyles from './OpenBookings.style'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]
const OpenBookings = () => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()
    const l = useLabel()

    const [
        filter,
        setFilter,
    ] = useState<{id: number}[]>()

    const onRowClickToBeConfirmed = useCallback(({
        orderNumber,
    }: OrdersToBeConfirmedType): void => {
        history.push(orderManagementRequest.OpenBookings.moveToUrl(orderNumber))
    }, [history])

    const onRowClickWaitingForPricing = useCallback(({
        id,
    }: OrdersToBeConfirmedType): void => {
        history.push(orderManagementRequest.PreBookings.moveToUrl(id))
    }, [history])

    const filterRequest = filter?.map(({
        id,
    }) => { return id })

    return (
        <div className={classes.root}>
            <Card
                title="Filter by"
                className={classes.filterWrapper}
            >
                <MultipleLocationSelector
                    title={l('Order.originLocation')}
                    value={filter}
                    onChange={setFilter}
                />
            </Card>
            <div className={classes.headerTitle}>
                Orders To Be Confirmed
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={ordersToBeConfirmedConfig.name}
                    onRowClick={onRowClickToBeConfirmed}
                    tableControls={tableControls}
                    tableConfig={ordersToBeConfirmedConfig}
                    customUrl={requests.OrdersToBeConfirmed().url}
                    filter={{
                        originLocationId: filterRequest,
                    }}
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>
                Bookings Waiting For Pricing
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={bookingsWaitingForPricing.name}
                    onRowClick={onRowClickWaitingForPricing}
                    tableControls={tableControls}
                    tableConfig={bookingsWaitingForPricing}
                    customUrl={requests.BookingsWaitingForPricing().url}
                    filter={{
                        originServiceCenterId: filterRequest,
                    }}
                    showSearch
                />
            </div>
        </div>
    )
}

export default OpenBookings
