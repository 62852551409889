import React, {
    useMemo,
} from 'react'

import {
    DISABLED_STEP_ORDER_STATUSES,
} from 'app/Apps/OrderManagement/Orders/utils/orderStatus'
import StatusHandler from 'app/shared-components/StatusHandler'
import SharedContextProvider from 'app/shared-components/SharedContext'

import SavedOrderStepsForm from './SavedOrderStepsForm'
import {
    StepStatus,
    OrderTypes,
    Order,
} from '../../orders.types'
import useGetOrderByNumber from '../../hooks/useGetOrderByNumber'

const OrderSteps = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        data = {} as Order,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetOrderByNumber(orderNumber)

    const {
        orderSteps,
        id: orderId,
        orderStatus,
        orderType,
        billing,
        version,
    } = data

    const isOrderTypeCustomer: boolean = useMemo(() => {
        return orderType === OrderTypes.CUSTOMER_TRANSPORT
    }, [orderType])

    const disabled: boolean = useMemo(() => {
        return isOrderTypeCustomer
            && DISABLED_STEP_ORDER_STATUSES.includes(orderStatus)
    }, [
        orderStatus,
        isOrderTypeCustomer,
    ])

    const openStepId: number = useMemo(() => {
        return orderSteps?.find(({
            stepStatus,
        }) => {
            return stepStatus !== StepStatus.CLOSED
        })?.id
    }, [orderSteps])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <SharedContextProvider
                value={{
                    order: data,
                }}
            >
                <>
                    {
                        orderSteps?.map((item) => {
                            return (
                                <SavedOrderStepsForm
                                    key={item.id}
                                    value={item}
                                    isOpen={!disabled && openStepId === item.id}
                                    orderId={orderId}
                                    orderNumber={orderNumber}
                                    onSuccess={invalidate}
                                    disabled={disabled}
                                    orderVersion={version}
                                    isOrderTypeCustomer={isOrderTypeCustomer}
                                    baseLeaseUntilTimestamp={billing?.baseLeaseUntilTimestamp}
                                    orderStatus={orderStatus}
                                />
                            )
                        })
                    }
                </>
            </SharedContextProvider>
        </StatusHandler>
    )
}

export default OrderSteps
