import React from 'react'
import PropTypes from 'prop-types'
import {
    Chip as MaterialChip,
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
}

const defaultProps = {
    value: false,
}

const ChipCheckMark = (props) => {
    const {
        value,
    } = props

    return (
        <>
            {value && (
                <MaterialChip
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                        borderRadius: 4,
                        paddingLeft: 8,
                    }}
                    icon={<DoneIcon />}
                />
            )}
        </>
    )
}

ChipCheckMark.propTypes = propTypes
ChipCheckMark.defaultProps = defaultProps

export default ChipCheckMark
