import {
    useState, useMemo,
} from 'react'

export default function useSkyNetSpreadSheetValidation() {
    const [
        showError,
        setShowError,
    ] = useState(false)

    const [
        valid,
        setValid,
    ] = useState(true)

    return useMemo(() => {
        return {
            showError,
            setShowError,
            valid,
            setValid,
        }
    }, [
        showError,
        valid,
    ])
}
