import React from 'react'
import AccountUpdate from '../AccountUpdate'

const AccountOverview = ({
    id,
}: { id: number }) => {
    return (
        <AccountUpdate
            id={id}
            disabled
        />
    )
}

export default AccountOverview
