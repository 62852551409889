import React, {
    useMemo,
} from 'react'

import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'

import StatusHandler from 'app/shared-components/StatusHandler'
import useGetLaneGroup from '../hooks/useGetLaneGroup'
import {
    LaneGroupContext,
    LaneGroupContextType,
} from '../hooks/useLaneGroupContext'
import useGetContractBasis from '../../ContractBasis/hooks/useGetContractBasis'

const LaneGroupContextWrapper = ({
    id, children,
}: { id: number, children: JSX.Element }) => {
    const {
        url,
    } = useRouteMatch()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLaneGroup(id)

    const {
        data: contractBasis,
        isDataReady: contractBasisDataReady,
    } = useGetContractBasis(data?.contractBasisId)

    const laneGroupContext = useMemo<LaneGroupContextType>(() => {
        return {
            laneGroupId: data?.id,
            currency: contractBasis?.currency,
            tempRange: data?.tempRange,
            leaseType: data?.leaseType,
            deliveryServiceType: data?.deliveryServiceType,
            collectionServiceType: data?.collectionServiceType,
        }
    }, [
        data,
        contractBasis,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady && contractBasisDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <LaneGroupContext.Provider value={laneGroupContext}>
                <Switch>
                    <Route
                        path={`${url}/:id?/:action?`}
                    >
                        {children}
                    </Route>
                </Switch>
            </LaneGroupContext.Provider>
        </StatusHandler>
    )
}

export default LaneGroupContextWrapper
