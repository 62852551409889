import reducer, {
    selectShowArchived,
    toggleShowArchived,
    setShowArchived,
    clearState,
} from './TablesControls.slice'

export {
    selectShowArchived,
    toggleShowArchived,
    setShowArchived,
    clearState,
}

export default reducer
