import {
    OrderTemperatureRange,
    LeaseType,
} from 'app/types/enums'
import {
    TransportModeRoad,
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'

export enum Selection {
    DEFAULT = 'DEFAULT',
    BACKUP = 'BACKUP'
}

export type OrderDetailsStep = {
    customerExecution: {id: number},
    pricingAccount: {id: number},
    originAirport: {id: number},
    destinationAirport: {id: number},
    handoverPoint: {id: number},
    collectionDropoffPoint: {id: number},
    consignee: {id: number},
    temperatureRange: OrderTemperatureRange,
    selectedLaneId: [number],
}

export type BookingDetailsStep = {
    transportModeRoad: TransportModeRoad,
    transportModeForwarder: TransportModeRoad,
    deliveryServiceType: DeliveryServiceType,
    leaseType: LeaseType,
    requiredContainers: RequiredContainersCustomer[],
    originScSelection: Selection,
    fwdPosSelection: Selection,
    originServiceCenter: {id: number},
    freightForwarder: {id: number},
    forwarder: {id: number},
    customerExecutionContactsOps: {id: number}[],
    numberOfTrucks: number,
    numberOfVans: number,
    laneCommentOps: string,
    mawbNumber: string,
    hawbNumber: string,
    orderReceived: string,
    orderEntered: string,
    requiredCargoComment: string,
    commentForBillingOps: string,
    personInCharge: string,
    minOrder: number,
    estimatedPickup: string,
    requestedPickup: string,
    requestedDelivery: string,
    leaseStartTimestamp: string,
    customerReference: string,
    isPerformanceQualification: boolean,
}

export type ClosePreBookingStep = BookingDetailsStep & {lane: {id: number}}

export type DirectLeaseFormType = {
    firstStep: OrderDetailsStep,
    secondStep: BookingDetailsStep,
    thirdStep: ClosePreBookingStep,
}
