import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LocationType, LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    AvailableLanesFilters,
    SelectLaneStepType,
} from '../../createCustomerTransportForm.types'
import {
    AirlineLeaseAvailableLane,
} from './AvailableLanes'

const getFields = ({
    getAllowedValues,
    includeFilters,
    availableLanes,
    setAvailableLanes,
}: {
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    includeFilters: AvailableLanesFilters,
    availableLanes: {data:AirlineLeaseAvailableLane[]},
    setAvailableLanes: (val: {data:AirlineLeaseAvailableLane[]}) => void,
    }): DTOCardFormField<keyof SelectLaneStepType>[] => {
    return [
        {
            title: 'Handover',
            className: 'handover',
            id: 'handover',
            fields: [
                {
                    id: 'column1',
                    fields: [{
                        name: 'deliveryServiceType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(DeliveryServiceType),
                        className: 'radio',
                        labelKey: 'Order.deliveryServiceType',
                    }],
                },
                {
                    id: 'column2',
                    fields: [{
                        name: 'handoverPoint',
                        componentName: 'LocationExpandedViewObjectSelector',
                        filterLocationType: LocationType.ADDRESS,
                        labelKey: 'Order.handoverPoint',
                    }],
                },
            ],
        },
        {
            title: 'Collection',
            className: 'collection',
            id: 'collection',
            fields: [
                {
                    id: 'column3',
                    fields: [{
                        name: 'collectionServiceType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(CollServiceType),
                        className: 'radio',
                        labelKey: 'LaneOpsInformationDto.collectionServiceType',
                    }],
                },
                {
                    id: 'column4',
                    fields: [{
                        name: 'collectionDropoffPoint',
                        componentName: 'LocationExpandedViewObjectSelector',
                        filterLocationType: LocationType.ADDRESS,
                        labelKey: 'Order.collectionDropoffPoint',
                    }],
                },
            ],
        },
        {
            title: 'General Information',
            className: 'generalInformation',
            id: 'generalInformation',
            fields: [
                {
                    id: 'column5',
                    fields: [
                        {
                            name: 'originAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            labelKey: 'Order.originAirport',
                        },
                        {
                            name: 'destinationAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            labelKey: 'Order.destinationAirport',
                        },
                        {
                            name: 'tempRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                            useDropdown: true,
                            labelKey: 'Order.temperatureRange',
                        },
                    ],
                },
                {
                    id: 'column6',
                    fields: [
                        {
                            name: 'customerExecution',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'Lane.customerExecution',
                        },
                        {
                            name: 'account',
                            componentName: 'AccountSelector',
                            labelKey: 'RegionalGroups.accountName',
                        },
                        {
                            name: 'consignee',
                            labelKey: 'AccountLane.consignee',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Available Lanes',
            className: 'availableLanes',
            id: 'availableLanes',
            classNames: (classes) => {
                return {
                    wrapper: classes.wrapper,
                    title: classes.title,
                }
            },
            fields: [{
                name: 'selectedLaneId',
                componentName: 'AirlineLeaseAvailableLane',
                includeFilters,
                availableLanes,
                setAvailableLanes,
                labelKey: 'Order.leaseType',
            }],
        },
    ]
}

export default getFields
