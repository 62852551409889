import useShareContext from 'app/shared-components/SharedContext/useSharedContext'

type WithPriningNameType = {
    feePricingName: string,
}

const useFeePricingName = () => {
    const context = useShareContext<WithPriningNameType>()
    const {
        feePricingName,
    } = context

    return feePricingName
}

export default useFeePricingName
