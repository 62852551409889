import React from 'react'
import Typography from '@mui/material/Typography'

import Card from 'app/shared-components/Card'
import {
    Event,
} from 'app/types/common.types'

import AirportsRoute from './AirportsRoute'
import useStyles from './RouteSelection.styles'
import RoutePath from './RoutePath'
import {
    RouteSelectionType,
} from '../simulation.types'

type Props = {
    value?: RouteSelectionType,
    onChange: (newVal: RouteSelectionType, e: Event<RouteSelectionType>) => void,
    name: string,
}

const defaultProps = {
    value: {},
}

const RouteSelection = ({
    value,
    onChange,
    name,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.container}>
            <Card className={classes.containerRoute}>
                <Typography variant="h3">
                    Route
                </Typography>
                <div className={classes.wrapper}>
                    <AirportsRoute
                        onChange={onChange}
                        value={value}
                        name={name}
                    />
                </div>
            </Card>
            <Card className={classes.containerMap}>
                <RoutePath value={value} />
            </Card>
        </div>
    )
}

RouteSelection.defaultProps = defaultProps

export default RouteSelection
