import {
    FilterOptions,
} from 'app/hooks/useFilter'
import {
    SkyNetPaginationType,
} from 'app/shared-components/SkyNetPagination'
import isEmpty from 'lodash/isEmpty'
import {
    useEffect, useMemo,
} from 'react'
import isEqual from 'lodash/isEqual'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import useFirstLoading from 'app/hooks/useFirstLoading'
import {
    RequestConfig,
    TableResponse,
} from 'app/types/request.types'

type Params<ResponseData, SpreadsheetData> = {
    filterOptions?: FilterOptions,
    dataAdapter?: (d: ResponseData[]) => SpreadsheetData[],
    paginationOptions: SkyNetPaginationType,
    requestKey: RequestKeys,
    config: (param: Record<string, any>) => RequestConfig,
    enabled?: boolean
}

// eslint-disable-next-line max-len
export default function useGetRequestSpreadSheetAdapter<ResponseData, SpreadsheetData = ResponseData>({
    filterOptions, dataAdapter, paginationOptions, requestKey, config, enabled,
}: Params<ResponseData, SpreadsheetData>) {
    const {
        isDataReady,
        refetch,
        data: response,
        isError,
        error,
        isFetching,
        invalidate,
    } = useRequestTable<TableResponse<ResponseData[]>>({
        key: requestKey,
        params: {
            includeFilters: isEmpty(filterOptions?.filter) ? undefined : filterOptions?.filter,
            data: {
                paginationModeMatches: true,
                rows: paginationOptions?.rows,
                start: paginationOptions?.start,
            },
        },
        config,
        keepPreviousData: true,
        enabled: !isEmpty(filterOptions?.filter) || enabled,
        withPagination: Boolean(paginationOptions),
    })

    const data = useMemo(() => {
        if (response?.data) {
            const responseData = response?.data as ResponseData[]

            return dataAdapter ? dataAdapter(responseData) : responseData
        }
        return []
    }, [
        dataAdapter,
        response?.data,
    ])

    useEffect(() => {
        if (isDataReady) {
            if (!isEqual(data, filterOptions.filteredData)) {
                if (response?.matches) {
                    paginationOptions.setMatches(response?.matches)
                }
                filterOptions?.setFilteredData(data)
            }
        }
    }, [
        data,
        filterOptions,
        isDataReady,
        paginationOptions,
        response?.matches,
    ])

    useEffect(() => {
        return invalidate
    }, [invalidate])

    const loaded = useFirstLoading(isFetching)

    return useMemo(() => {
        return {
            isError,
            loaded,
            error,
            isDataReady,
            refetch,
            data,
            invalidate,
        }
    }, [
        data,
        error,
        isDataReady,
        isError,
        loaded,
        refetch,
        invalidate,
    ])
}
