/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import DashboardWidget from 'app/Apps/Dashboard/components/DashboardWidget'

import useStyles from './Dashboard.style'

const propTypes = {
    widgets: PropTypes.arrayOf(PropTypes.any).isRequired,
}

const Dashboard = ({
    widgets,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <Container
            data-testid="dashboard"
            className={classes.containerWrapper}
            maxWidth="xl"
        >
            <Grid
                container
                spacing={6}
                className={classes.container}
            >
                {
                    widgets.map((widget) => {
                        return (
                            <DashboardWidget
                                data-testid={`dashboard-widget-${widget.id}`}
                                key={widget.id}
                                {...widget}
                            />
                        )
                    })
                }
            </Grid>
        </Container>
    )
}

Dashboard.propTypes = propTypes

export default Dashboard
