import React, {
    useMemo,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import StatusHandler from 'app/shared-components/StatusHandler'

import loggerUpdateFields from './LoggerUpdate.fields'
import LoggerRequest from '../Logger.request'
import useGetLoggerByLoggerNumber from '../hooks/useGetLoggerByLoggerNumber'

const LoggerUpdate = ({
    id: loggerNumber,
}: {id: string}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLoggerByLoggerNumber(loggerNumber)

    const fields = useMemo(() => {
        return loggerUpdateFields(data?.rentingCompany)
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                name={LoggerRequest.domainName}
                value={data}
                fields={fields}
                onSuccess={invalidate}
                requestParams={LoggerRequest.update({
                    serialNumber: data.id,
                })}
                exists
            />
        </StatusHandler>
    )
}

export default LoggerUpdate
