import {
    useTheme,
} from '@mui/material/styles'
import {
    createMakeAndWithStyles,
    createTss,
} from 'tss-react'
import createCache from '@emotion/cache'

export const tssCache = createCache({
    key: 'tss',
})

export const {
    makeStyles, useStyles,
} = createMakeAndWithStyles({
    useTheme,
    cache: tssCache,
})

export const {
    tss,
} = createTss({
    useContext: useTheme,
    cache: tssCache,
})
