/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    contentComponent: PropTypes.func.isRequired,
}

const Content = (props) => {
    const {
        contentComponent: Component,
    } = props

    return (
        <Component
            {...props}
        />
    )
}

Content.propTypes = propTypes

export default Content
