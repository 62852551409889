import React from 'react'

import {
    StepTypeDetailed,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useStyles from './OrderStatus.style'

type Props = {
    value?: OrderStep[]
}

const defaultProps: Partial<Props> = {
    value: [],
}

const OrderStatus = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValuesStepTypeDetailed = useEnumValues()(StepTypeDetailed)

    return (
        <div className={classes.root}>
            {value.map(({
                id,
                stepTypeDetailed,
                stepStatus,
                stepType,
            }) => {
                return (
                    <div
                        key={`order-status-step-${id}`}
                        className={classes.container}
                    >
                        <img
                            className={classes.iconStatus}
                            src={`assets/images/orderSteps/${stepStatus?.toLowerCase()}.svg`}
                            alt={stepStatus}
                        />
                        <img
                            className={classes.iconType}
                            src={`assets/images/orderSteps/${stepType?.toLowerCase()}.svg`}
                            alt={stepTypeDetailed}
                        />
                        <span className={classes.text}>
                            {allowedValuesStepTypeDetailed[stepTypeDetailed]}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

OrderStatus.defaultProps = defaultProps

export default OrderStatus
