import React, {
    useCallback,
} from 'react'
import RichTable from 'app/shared-components/RichTableReduxWrapper'

import {
    SelectedContainer,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    RichTableData,
} from 'app/types/richtable.types'

import columns from './containers.columns'
import useStyles from './Containers.style'

const defaultProps = {
    value: [],
}

const DOMAIN_NAME = 'OrderSelectedContainersView'

const Containers = ({
    value,
}: {value?: SelectedContainer[]}) => {
    const {
        classes,
    } = useStyles()

    const load = useCallback((): Promise<RichTableData<SelectedContainer[]>> => {
        return Promise.resolve({
            data: value,
        })
    }, [value])

    return (
        <RichTable
            configName="BillingInformation.Containers"
            name={DOMAIN_NAME}
            columns={columns}
            load={load}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            rowStyle={{
                crossLine: 'isRejected',
            }}
        />
    )
}

Containers.defaultProps = defaultProps

export default Containers
