/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'

import EnumSelector from 'app/shared-components/EnumSelector'
import {
    LaneStatus,
} from 'app/types/enums'

import transitionLogics from './transitionLogics'

type Props = {
    name: string,
    moduleName?: string,
    value?: LaneStatus,
    originalStatus?: LaneStatus,
    allowedValues?: Record<string, string>
    onChange: (changeValue: LaneStatus) => void,
    disabled?: boolean,
    transitionType?: keyof typeof transitionLogics,
}

const defaultProps: Partial<Props> = {
    value: undefined,
    moduleName: undefined,
    originalStatus: undefined,
    allowedValues: undefined,
    disabled: false,
    transitionType: undefined,
}

const StatusTransitionSelector = (props: Props) => {
    const {
        name,
        value,
        moduleName,
        originalStatus,
        allowedValues,
        onChange,
        disabled,
        transitionType,
    } = props

    const type: string = useMemo(() => {
        return transitionType || moduleName || name
    }, [
        transitionType,
        moduleName,
        name,
    ])

    const allowedValuesLogic = useMemo(() => {
        if (!transitionLogics) {
            return allowedValues
        }
        if (!transitionLogics[type][originalStatus]) {
            return allowedValues
        }
        return transitionLogics[type][originalStatus].reduce((acc, val) => {
            if (allowedValues[val]) {
                return {
                    ...acc,
                    [val]: allowedValues[val],
                }
            }
            return acc
        }, {})
    }, [
        type,
        originalStatus,
        allowedValues,
    ])

    return (
        <EnumSelector
            {...props}
            name={name}
            allowedValues={allowedValuesLogic}
            value={value}
            onChange={onChange}
            useDropdown
            disabled={disabled}
        />
    )
}

StatusTransitionSelector.defaultProps = defaultProps

export default StatusTransitionSelector
