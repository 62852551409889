/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import noop from 'lodash/noop'

import {
    dateToStr,
    dateTimeMask,
} from 'app/utils/date'

import DateTimeSelect from './DateTimeSelect'

type Props = {
    name?: string,
    onChange?: (value: any, event: any) => void,
}

const defaultProps: Partial<Props> = {
    name: '',
    onChange: noop,
}

const DateTimeSelectContainer = (props: Readonly<Props>) => {
    const {
        name,
        onChange,
    } = props

    const handleOnChange = useCallback((datetime) => {
        const newDate = dateToStr(datetime, dateTimeMask)

        onChange(newDate, {
            name,
            value: newDate,
        })
    }, [
        name,
        onChange,
    ])

    return (
        <DateTimeSelect
            {...props}
            onChange={handleOnChange}
        />
    )
}

DateTimeSelectContainer.defaultProps = defaultProps

export default DateTimeSelectContainer
