import React, {
    useMemo, useState,
} from 'react'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'

import AddRemoveIcon from 'app/shared-components/CollapsableAddRemovePanel/AddRemoveIcon'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import SharedContext from 'app/shared-components/SharedContext'

import PreBookingCommentCreateForm from './PreBookingCreateForm'
import useGetPreBookingComments from './hooks/useGetPreBookingComments'
import requestsConfig from './preBookingComment.request'
import getColumns from './preBookingComment.columns'
import useStyles from './preBookingComment.style'

const PreBookingComment = ({
    id,
}: {
    id: number,
}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()
    const {
        onParamsChange,
        loadData,
        refetch,
    } = useGetPreBookingComments(id)

    const [
        createFormOpened,
        setCreateFormOpened,
    ] = useState(false)

    const closeCreateForm = (): void => {
        setCreateFormOpened(false)
    }

    const openCreateForm = (): void => {
        setCreateFormOpened(true)
    }

    const onCreate = () => {
        refetch()
        closeCreateForm()
    }

    const columns = useMemo(() => {
        return getColumns(l)
    }, [l])

    return (
        <Card
            className={classes.root}
            data-testid="OrderComments-card"
        >
            <div>
                <Typography variant="h3">
                    Comments
                </Typography>
                <AddRemoveIcon
                    formOpened={createFormOpened}
                    onOpenForm={openCreateForm}
                    onCloseForm={closeCreateForm}
                />
            </div>
            <Collapse in={createFormOpened}>
                <PreBookingCommentCreateForm
                    onSuccess={onCreate}
                    onCancel={closeCreateForm}
                    id={id}
                />
            </Collapse>
            <SharedContext value={refetch}>
                <RichTable
                    configName="PreBookingCommentsTable"
                    name={requestsConfig.domainName}
                    load={loadData}
                    onParamsChange={onParamsChange}
                    columns={columns}
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                />
            </SharedContext>
        </Card>
    )
}

export default PreBookingComment
