import React, {
    forwardRef,
} from 'react'
import Loading from 'app/shared-components/Loading'
import {
    Cx,
} from 'tss-react'

const defaultProps = {
    classNames: undefined,
}

const RecalculationWrapper = forwardRef(({
    children, getClasses, classNames, recalculationIndex, maxHeight,
}: {
    children: JSX.Element,
    getClasses: () => {
        classes: Record<string, any>,
        cx: Cx,
    },
    recalculationIndex: number,
    maxHeight: number,
    classNames?: string,
}, containerRef: React.RefObject<HTMLDivElement>) => {
    const {
        classes, cx,
    } = getClasses()

    return (
        <div
            ref={containerRef}
            className={cx(classes.scrolledGridContainer, classNames, {
                [classes.recalculatedContainer]: recalculationIndex > 1,
            })}
            style={{
                maxHeight,
            }}
        >
            { containerRef?.current ? children : <Loading />}
        </div>
    )
})

RecalculationWrapper.defaultProps = defaultProps

export default RecalculationWrapper
