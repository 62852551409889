import {
    useCallback, useMemo,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useDispatch,
} from 'react-redux'
import {
    useJWTToken,
} from 'app/Auth'
import {
    RequestKeys, RequestFn,
} from 'app/hooks/useRequest'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    RequestConfig,
} from 'app/types/request.types'

export default <TData = any>({
    key,
    selectorConfig,
    convert,
}: {
    key: RequestKeys,
    selectorConfig: (...a: any[]) => RequestConfig,
    convert?: ({
        data,
    }: {
        data: TData
    }) => any
}): ({
    params, includeFilters,
}
?: {
    params?: Record<string, any>,
    includeFilters?: Record<string, any>,
}) => Promise<TData> => {
    const queryClient = useQueryClient()
    const token = useJWTToken()
    const dispatch = useDispatch()

    const {
        onError,
    } = useHandleStatusRequest({
        id: 'LanesSelector',
    })

    const queryFn = useMemo(() => {
        return RequestFn.table(selectorConfig)(dispatch)
    }, [
        dispatch,
        selectorConfig,
    ])

    return useCallback(async (options: {
        includeFilters?: Record<string, any>,
        filters?: Record<string, any>,
        phrase?: string,
    }) => {
        let data: {data: TData}

        const {
            includeFilters, filters, phrase, ...rest
        } = options

        const queryKey = [
            key,
            {
                token,
                ...rest,
                includeFilters: {
                    ...includeFilters,
                    ...filters,
                },
                searchAll: phrase,
            },
        ]

        try {
            data = await queryClient.fetchQuery({
                queryKey, queryFn, staleTime: 100000,
            })

            if (convert) {
                return convert(data)
            }
        } catch (error) {
            onError(error)
        }

        return data
    }, [
        convert,
        key,
        onError,
        queryClient,
        queryFn,
        token,
    ])
}
