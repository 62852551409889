import {
    LaneGroupTemperatureRange,
    LaneStatus,
    LeaseType,
    TemperatureRange,
} from 'app/types/enums'

import {
    DeliveryServiceType,
} from '../../OrderManagement/Lanes/lanes.types'
import {
    CollectionServiceType,
} from '../../OrderManagement/Orders/orders.types'

type base = {
    id: number,
    quoteNumber: string,
    accountName: string,
    leaseType: LeaseType,
    pricingResponsible: string,
    tempRange: TemperatureRange,
    createdBy: string,
    lastUpdated: string,
}

export type kamUpcoming = base & {
    quotedForCompanyLocationName: string,
    lanesToBePriced: number,
    lastUpdated: string,
}

export type kamToBeCompleted = base & {
    customerQuotationLocationName: string,
    upcomingLanes: number,
    pricedLanes: number,
    completedLanes: number,
}

export type kamCompleted = base & {
    customerQuotationLocationName: string,
    totalLanes: number,
    submittedOn: string,
}

export type UnassignedToOpportunity = {
    id: number,
    changedOn: string,
    laneNumber: string,
    laneGroupNumber: string,
    pricingAccountCompanyName: string,
    temperatureRange: LaneGroupTemperatureRange,
    customerQuotation: string,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollectionServiceType,
    originAirport: string,
    destinationAirport: string,
    laneStatus: LaneStatus,
    accountId: number,
}

export enum UnassignedToOpportunityError {
    MAX_LENGTH = 'maxLength',
    MATCH = 'match',
    MAX_LENGTH_AND_MATCH = 'maxLengthAndMatch'
}
