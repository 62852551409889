import {
    makeStyles,
} from 'app/tss'

const summary = 'summary'
const extDimensions = 'extDimensions'
const intDimensions = 'intDimensions'
const temperatureRange = 'temperatureRange'
const storageRange = 'storageRange'
const containerPicture = 'containerPicture'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
                "${summary} ${summary} ${summary} ${summary}"
                "${containerPicture} . . ."
                "${temperatureRange} ${storageRange} ${extDimensions} ${intDimensions}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        summary: {
            gridArea: summary,
        },
        extDimensions: {
            gridArea: extDimensions,
        },
        intDimensions: {
            gridArea: intDimensions,
        },
        temperatureRange: {
            gridArea: temperatureRange,
        },
        storageRange: {
            gridArea: storageRange,
        },
        containerPicture: {
            gridArea: containerPicture,
        },
    }
})

export default useStyles
