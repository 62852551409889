import React, {
    useLayoutEffect,
    useRef,
} from 'react'
import PropTypes from 'prop-types'

import loadChart from './loadChart'

const propTypes = {
    value: PropTypes.arrayOf(PropTypes.array),
    options: PropTypes.objectOf(PropTypes.any),
    onError: PropTypes.func.isRequired,
    className: PropTypes.string,
}

const defaultProps = {
    value: [],
    options: {},
    className: '',
}

const BarChart = (props) => {
    const {
        value,
        onError,
        options,
        className,
    } = props

    const elm = useRef()

    useLayoutEffect(() => {
        loadChart(value, elm.current, options, onError)
    }, [
        onError,
        options,
        value,
    ])

    return (
        <div
            className={className}
            ref={elm}
        />
    )
}

BarChart.propTypes = propTypes
BarChart.defaultProps = defaultProps

export default BarChart
