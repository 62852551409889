import React from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'
import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import requests from './Locations.request'
import {
    ROLES_PROSPECT_ALLOWED,
} from './Locations.types'

import LocationOverview from './LocationOverview'
import LocationUpdate from './LocationUpdate'
import LocationCreate from './LocationCreate'
import LocationAreas from './LocationAreas'
import LocationChangeSummary from './LocationChangeSummary'
import tableConfig from './Locations.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]
const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: LocationOverview,
}
const dataUpdateTab: SidebarTab = {
    url: 'data-update',
    label: 'Data Update',
    Component: LocationUpdate,
}
const areasTab: SidebarTab = {
    url: 'areas',
    label: 'Areas',
    Component: LocationAreas,
}

const historyTab: SidebarTab = {
    url: 'change-history',
    label: 'Change History',
    Component: LocationChangeSummary,
}

const Locations = () => {
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: dataUpdateTab,
            permission: true,
        },
        {
            tab: areasTab,
            permission: true,
        },
        {
            tab: historyTab,
            permission: true,
        },

    ])
    const hasCreatePermissionsByMainCategory = useHasPermissionType([], 'LocationAccessRights', 'create')
    const hasCreatePermissionForProspectLocation = useHasPermission(ROLES_PROSPECT_ALLOWED)
    const hasCreatePermissions = hasCreatePermissionsByMainCategory
        || hasCreatePermissionForProspectLocation

    return (
        <SkyNetTable
            createForm={hasCreatePermissions
                ? LocationCreate
                : undefined}
            name="Location"
            moduleName={requests.domainName}
            tabs={tabs}
            filter={requests.filter}
            tableControls={tableControls}
            tableConfig={tableConfig}
            customUrl={requests.all().url}
            showSearch
        />
    )
}

export default Locations
