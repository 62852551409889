import React, {
    useMemo, useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import omit from 'lodash/omit'
import {
    REDIRECT_PARAM,
} from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'
import {
    App,
} from 'app/types/common.enums'
import StatusHandler from 'app/shared-components/StatusHandler'
import HeaderWarning from 'app/shared-components/HeaderWarning'
import useGetAccountLane from '../hooks/useGetAccountLane'
import AccountLaneForm from '../AccountLaneForm'
import request from '../AccountLanes.requests'

const AccountLaneCopy = ({
    id,
}: {id: number}) => {
    const history = useHistory()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAccountLane(id)

    const value = useMemo(() => {
        return {
            ...omit(data, [
                'id',
                'changedBy',
                'changedOn',
            ]),
            originId: id,
        }
    }, [
        data,
        id,
    ])

    const onSuccess = useCallback(({
        id: copyId,
    }) => {
        invalidate()
        history.push(`/apps/${App.ACCOUNT_MANAGEMENT}/lanes/all/edit/${copyId}/overview`, REDIRECT_PARAM)
    }, [
        history,
        invalidate,
    ])

    const onCancel = useCallback(() => {
        history.back()
    }, [history])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <HeaderWarning>
                    You are copying a Lane.
                </HeaderWarning>
                <AccountLaneForm
                    data={value}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParam={request.create()}
                />
            </>
        </StatusHandler>
    )
}

export default AccountLaneCopy
