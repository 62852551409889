import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import DTOSpec from 'app/services/dtoSpec'
import request from 'app/Apps/ContactManagement/contactManagement.request'

type Params = {
    onSuccess: () => void,
}

const DOMAIN_NAME = DTOSpec.BillingCompany.Info.name

const useRestoreBillingCompany = ({
    onSuccess,
}: Params): UseMutationResult<unknown, unknown, {id: number}> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRestore,
        onError,
    } = useHandleStatusRequest({
        id: DOMAIN_NAME,
        title: 'Billing Company Restored',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            id,
        }: {id: number}) => {
            return request.Restore.mutationFn({
                id,
                token,
            })
        },
        onSuccess: onSuccessRestore,
        onError,
    })
}

export default useRestoreBillingCompany
