import {
    RequestConfig,
} from 'app/types/request.types'

type ContainerSelectorRequests = {
    get: ({
        id,
    }) => RequestConfig,
    Selector: string,
}

const config: ContainerSelectorRequests = {
    Selector: 'container/filter-request/all',
    get: ({
        id,
    }) => {
        return {
            url: `container/${id}`,
        }
    },
}

export default config
