/* eslint-disable import/prefer-default-export */

import {
    RequestConfig,
    TableResponse,
} from 'app/types/request.types'

import requestBusinessObjects from './requestBusinessObjects'
import requestTable from './requestTable'
import requestMultiplePages from './requestMultiplePages'
import requestFunctionBuilder from './requestFunctionBuilder'
import responseParser from './responseParser'

interface Output {
    (dispatch: any): (args: any) => Promise<TableResponse | Record<string, string> | void>
}

type RequestFuncTableOptions = {
    withPagination?: boolean
}

const requestFuncTable = (
    specRequestFn: (args: Record<string, any>) => RequestConfig,
    options?: RequestFuncTableOptions,
): Output => {
    return requestFunctionBuilder({
        paramFn: (rest) => {
            const tempTest: Record<string, any> = {
                ...specRequestFn({
                    ...rest,
                }),
            }

            return {
                ...tempTest,
                data: {
                    rows: 50,
                    start: 0,
                    ...tempTest.data,
                },
            }
        },
        requestFn: options?.withPagination ? requestMultiplePages : requestTable,
        parserFn: responseParser.table,
    })
}

const requestFuncGetEntity = (specRequestFn: (params: Record<string, any>) => RequestConfig,
    responseParserFn = responseParser.entity, requestFn = requestBusinessObjects) => {
    return requestFunctionBuilder({
        paramFn: (rest) => {
            return {
                method: 'GET',
                ...specRequestFn({
                    ...rest,
                }),
            }
        },
        requestFn,
        parserFn: responseParserFn,
    })
}

export const RequestFn = {
    table: requestFuncTable,
    getEntity: requestFuncGetEntity,
}
