// TODO: move to common utils and find everywhere in project that we do it
export const valueConverterToArray = (listId: {id: number}[]) : (number)[] => {
    return listId?.map(({
        id,
    }) => {
        return id
    })
}

export const valueConverterToObject = (listId: {id: number}[]):{id: number}[] => {
    return listId?.map(({
        id,
    }) => {
        return {
            id,
        }
    })
}
