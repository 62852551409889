import {
    useCallback,
    useMemo,
} from 'react'
import {
    useLocation,
    useRouteMatch,
    useHistory,
} from 'react-router-dom'

type OpenRowResult = {
    onOpenCloseDetail: (object: Record<string, any>) => void,
    paramValue: string,
    redirectToUrl: (paramUnique: string) => void,
    redirectToCopyForm: (id: number) => void,
    openCopyForm: () => string,
    closeCopyForm: () => void,
}

const useOpenRowWithPath = (
    paramToUse: string,
    paramPath: string,
    parentPath?: string,
    showArchived?: {
        show: boolean,
        prefix: string,
    },
    skipParamPath?: boolean,
): OpenRowResult => {
    const history = useHistory()
    const {
        url,
    } = useRouteMatch()
    const {
        pathname: location,
    } = useLocation()
    const locationArray = location.split('/')
    const paramValue = locationArray[locationArray.indexOf(paramPath) + 1]

    const rootUrl = useMemo((): string => {
        const urlOrigin = parentPath ? `${url}/${parentPath}` : url
        const indexOpen = urlOrigin.match(`/${paramPath}/`)

        if (!indexOpen) {
            return urlOrigin
        }
        return urlOrigin.slice(0, indexOpen)
    }, [
        url,
        paramPath,
        parentPath,
    ])

    const getParamPath = useCallback(() => {
        if (skipParamPath && locationArray.includes(paramPath)) {
            return ''
        }

        return `/${paramPath}`
    }, [
        paramPath,
        skipParamPath,
        locationArray,
    ])

    const onOpenCloseDetail = useCallback((object: Record<string, any>): void => {
        const objectId = object && object[paramToUse]

        if (objectId && location.indexOf(objectId) === -1) {
            if (showArchived?.show) {
                history.push(`${rootUrl}/${showArchived.prefix}${getParamPath()}${objectId ? '/'.concat(objectId) : ''}`)
            } else {
                history.push(`${rootUrl}${getParamPath()}${objectId ? '/'.concat(objectId) : ''}`)
            }
        } else {
            history.push(rootUrl)
        }
    }, [
        getParamPath,
        paramToUse,
        history,
        rootUrl,
        location,
        showArchived,
    ])

    const redirectToUrl = useCallback((paramUnique: string): void => {
        if (!paramUnique) {
            return
        }
        history.push(`${rootUrl}${getParamPath()}/${paramUnique}`)
    }, [
        history,
        getParamPath,
        rootUrl,
    ])

    const redirectToCopyForm = useCallback((id: number): void => {
        if (!id) {
            return
        }

        if (skipParamPath) {
            history.push(`${rootUrl}/copy/${id}`)
            return
        }

        history.push(`${rootUrl}/${paramPath}-copy/${id}`)
    }, [
        skipParamPath,
        paramPath,
        history,
        rootUrl,
    ])

    const openCopyForm = useCallback(() => {
        const copyFormIndex = skipParamPath
            ? locationArray.indexOf('copy')
            : locationArray.indexOf(`${paramPath}-copy`)

        if (copyFormIndex !== -1) {
            return locationArray[copyFormIndex + 1]
        }
        return undefined
    }, [
        skipParamPath,
        locationArray,
        paramPath,
    ])

    const closeCopyForm = useCallback(() => {
        history.push(`${rootUrl}`)
    }, [
        history,
        rootUrl,
    ])

    return {
        onOpenCloseDetail,
        paramValue,
        redirectToUrl,
        redirectToCopyForm,
        openCopyForm,
        closeCopyForm,
    }
}

export default useOpenRowWithPath
