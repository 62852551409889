import {
    useCallback,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

const useEnumValues = () => {
    const {
        t,
    } = useTranslation()

    return useCallback((enumType: Record<string, string> = {}) => {
        return Object.fromEntries(Object.entries(enumType).map(([key]) => {
            const label = t(key, {
                ns: 'enums',
            })

            return [
                key,
                label || key,
            ]
        }))
    }, [t])
}

export default useEnumValues
