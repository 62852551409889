import React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
    useTheme,
} from '@mui/material/styles'

import Tooltip from 'app/shared-components/Tooltip'

import useStyles from './ClearSorting.style'

const propTypes = {
    removeSort: PropTypes.func.isRequired,
}

const ClearSorting = ({
    removeSort,
}) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <Link
            className={classes.link}
            component="button"
            variant="body1"
            color="textSecondary"
            onClick={removeSort}
        >
            <Tooltip title="Clear Sorting">
                <div className={classes.iconWrapper}>
                    <ArrowUpwardIcon
                        className={classes.icon}
                    >
                        arrow_drop_up
                    </ArrowUpwardIcon>
                    <ArrowDownwardIcon
                        className={classes.icon}
                    >
                        arrow_drop_down
                    </ArrowDownwardIcon>
                </div>
            </Tooltip>
        </Link>
    )
}

ClearSorting.propTypes = propTypes

export default ClearSorting
