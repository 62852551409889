import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    PoolingFields,
} from '../pooling.types'

const internalTransportsConfig: SkyNetTableConfig<PoolingFields> = {
    name: 'Pooling.InternalTransports',
    tabs: [{
        name: 'Open Transports',
        url: 'all',
    }],
    fields: [
        {
            name: 'orderNumber',
            width: '100px',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            width: '80px',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            width: '80px',
        },
        {
            name: 'orderSubType',
            filterField: 'orderSubType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            width: '150px',
            allowedValuesEnum: OrderSubType,
        },
        {
            name: 'forwarderLocationName',
            filterField: 'forwarderLocationName',
            filterType: FilterType.Array,
            width: '200px',
        },
        {
            name: 'originLocationLocationName',
            filterField: 'originLocationLocationName',
            filterType: FilterType.Array,
            width: '360px',
        },
        {
            name: 'destinationLocationLocationName',
            width: '200px',
        },
        {
            name: 'estimatedDelivery',
            filterField: 'estimatedDelivery',
            filterType: FilterType.DateRange,
            width: '250px',
        },
        {
            name: 'stepProgress',
            componentName: 'StepProgress',
            headerProps: {
                children: 'Progress',
            },
            width: '150px',
            sorting: false,
        },
        {
            name: 'requiredContainers',
            componentName: 'List',
            width: '200px',
            sorting: false,
        },
    ],
}

export default internalTransportsConfig
