/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import ObjectToIdSelectorConverter from 'app/shared-components/ObjectToIdSelectorConverter'
import TypeaheadObjectContainer, {
    Props, defaultProps,
} from './TypeaheadObjectContainer'

const TypeaheadContainer = (props: Props) => {
    return (
        <ObjectToIdSelectorConverter
            {...props}
            selector={TypeaheadObjectContainer}
        />
    )
}

TypeaheadContainer.defaultProps = defaultProps

export default TypeaheadContainer
