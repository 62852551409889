import {
    useMemo,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    DTOCardFormField,
} from 'app/types/form.types'
import useValidation from 'app/hooks/useValidation'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'

import {
    useContractBasisHasValidAdditionalFees,
} from 'app/Apps/Pricing/ContractBasis'
import useHasPermission from 'app/hooks/useHasPermission'
import useGetContractBasis from 'app/Apps/Pricing/ContractBasis/hooks/useGetContractBasis'
import getFields from './QuoteInformationForm.fields'

import useStyles from './QuoteInformationForm.styles'
import {
    AccountQuote, QuoteStatus,
} from '../AccountQuotes.types'

const defaultProps = {
    customButtons: [],
    omitField: [],
    disabled: false,
    requestParam: undefined,
    companyName: undefined,
    showContractBasisLink: false,
    hasPlannedContractBasis: false,
}

const QuoteInformationForm = ({
    value,
    setValue,
    render,
    omitField,
    disabled,
    showContractBasisLink,
    hasPlannedContractBasis,
}: {
    value: AccountQuote,
    setValue: React.Dispatch<React.SetStateAction<AccountQuote>>,
    omitField?: string[],
    disabled?: boolean,
    showContractBasisLink?: boolean,
    hasPlannedContractBasis?: boolean,
    render?: ({
        fields,
        onChange,
        formData,
        isValid,
    }: {
        fields: DTOCardFormField<any>[],
        onChange: (...args: any[]) => void,
        formData: AccountQuote,
        isValid: boolean
    }) => JSX.Element
}) => {
    const getAllowedValues = useEnumValues()
    const {
        classes,
    } = useStyles()

    const contractBasisReadPermission = useHasPermission(['contract_basis_read'])

    const {
        data: contractBasisDetails,
        isDataReady: isContractBasisDataReady,
    } = useContractBasisHasValidAdditionalFees(
        value?.linkedContractBasis?.id, contractBasisReadPermission,
    )

    const {
        data: contractBasis,
    } = useGetContractBasis(value?.linkedContractBasis?.id, contractBasisReadPermission)

    const exists = Boolean(value.id)

    const result = useFieldsWithClassName(getFields({
        getAllowedValues,
        exists,
        disabled,
        enabledContractBasis: value.quoteStatus === QuoteStatus.CREATED
            || value.quoteStatus === QuoteStatus.IN_PROGRESS,
        quoteStatus: value.quoteStatus,
        showContractBasisLink: showContractBasisLink && [
            QuoteStatus.IN_PROGRESS,
            QuoteStatus.CLOSED,
            QuoteStatus.CANCELLED,
        ].includes(value.quoteStatus),
        linkedContractBasisId: value.linkedContractBasis?.id,
        hasPlannedContractBasis,
        contractBasisNumber: contractBasis?.contractBasisNumber,
        contractBasisHasValidAdditionalFees: (isContractBasisDataReady
            ? contractBasisDetails?.hasValidAdditionalFees : true),
        contractBasisReadPermission,
    }),
    classes)

    const fields = useMemo(() => {
        return omitField.length > 0 ? [{
            ...result[0],
            fields: result?.[0].fields.reduce((acc, config) => {
                return [
                    ...acc,
                    {
                        ...config,
                        fields: config?.fields.filter(({
                            name,
                        }) => { return !omitField.includes(name) }),
                    },
                ]
            }, []),
        }] : result
    }, [
        omitField,
        result,
    ])

    const {
        isValid,
    } = useValidation({
        fields, values: value,
    })

    return render({
        fields,
        onChange: setValue,
        formData: value,
        isValid,
    })
}

QuoteInformationForm.defaultProps = defaultProps

export default QuoteInformationForm
