import {
    makeStyles,
} from 'app/tss'

export default makeStyles<{height: number}>()((theme, {
    height,
}) => {
    return {
        container: {
            background: theme.palette.common.white,
            overflowY: 'auto',
            padding: `${theme.spacing(1)} 0`,
        },
        paper: {
            top: `${height + 3}px !important`,
            height: `calc(100% - ${height - 5}px)`,
        },
        loading: {
            height: theme.spacing(3),
            width: '100% !important',
            background: theme.palette.common.white,
            textAlign: 'center',
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.h4.fontWeight,
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        },
    }
})
