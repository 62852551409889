import {
    useCallback,
} from 'react'
import {
    Id, MenuOption, SelectionMode,
} from '@silevis/reactgrid'
import {
    SkyNetSpreadSheetConfigType,
} from '../SkyNetSpreadSheet.types'

// open context menu
export default ({
    config, addDataRows, deleteDataRow, disabled,
}: {
    config: SkyNetSpreadSheetConfigType<any>,
    addDataRows: (n: number) => void,
    deleteDataRow: (n: Id[]) => void,
    disabled: boolean
}) => {
    return useCallback((
        selectedRowIds: Id[],
        selectedColIds: Id[],
        selectionMode: SelectionMode,
        menuOptions: MenuOption[] = [],
    ): MenuOption[] => {
        if (disabled) {
            return menuOptions
        }

        let options = menuOptions

        if (config.addRowMenuOption) {
            const step: number[] = [
                1,
                10,
                50,
                100,
            ]

            options = [
                ...options,
                ...step.reduce((acc, nr) => {
                    if (config.rowsLimit && config.rowsLimit < nr) {
                        return acc
                    }
                    return [
                        ...acc,
                        {
                            id: `add${nr}Row${nr > 1 ? 's' : ''}`,
                            label: `Add ${nr} empty row${nr > 1 ? 's' : ''} to spreadsheet`,
                            handler: () => { return addDataRows(nr) },
                        },
                    ]
                }, []),
            ]
        }

        if (config.enableRowSelection) {
            return [
                ...options,
                {
                    id: 'deleteRow',
                    label: 'Delete Row(s)',
                    handler: deleteDataRow,
                },
            ]
        }

        return options
    }, [
        addDataRows,
        config.addRowMenuOption,
        config.enableRowSelection,
        config.rowsLimit,
        deleteDataRow,
        disabled,
    ])
}
