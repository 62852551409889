import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Label from 'app/shared-components/Label'
import formatCurrencyValue from 'app/utils/formatCurrencyValue'

import useStyles from './CurrencyHeadingSelector.styles'

const propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    className: PropTypes.string,
    currency: PropTypes.string,
}

const defaultProps = {
    title: '',
    value: 0,
    className: null,
    currency: '',
}

const CurrencyHeadingSelector = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        title,
        value,
        className,
        currency: currencyValue,
    } = props

    return (
        <div
            className={clsx(classes.root, className)}
            data-testid="currency-heading-selector"
        >
            <Label
                title={title}
            />
            <div className={classes.currencyValue}>
                {`${formatCurrencyValue(value)} ${currencyValue}`}
            </div>
        </div>
    )
}

CurrencyHeadingSelector.propTypes = propTypes
CurrencyHeadingSelector.defaultProps = defaultProps

export default CurrencyHeadingSelector
