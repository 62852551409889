import React from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import StatusHandler from 'app/shared-components/StatusHandler'
import ChangeHistory from 'app/shared-components/ChangeHistory'

import useGetLoggerByLoggerNumber from '../hooks/useGetLoggerByLoggerNumber'
import config from '../Logger.request'

const GatewayChangeHistory = ({
    id: loggerNumber,
}: {id: string}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLoggerByLoggerNumber(loggerNumber)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ChangeHistory
                id={data?.id}
                key={RequestKeys.getLoggerModifications}
                config={config.modifications}
                configName="LoggerChangeHistoryTable"
                name="LoggerChangeHistory"
                labelPrefixForChangedColumn={['Logger']}
            />
        </StatusHandler>
    )
}

export default GatewayChangeHistory
