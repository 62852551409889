import {
    configureStore,
} from '@reduxjs/toolkit'

import formStatusesReducer from 'app/shared-components/FormStatuses/store'
import tablesControlsReducer from 'app/shared-components/TableControls/store'
import configsReducer from 'app/store/Configs'
import settingsReducer from './Settings'
import SpreadSheetReducer from './SpreadSheetConfigs'

const store = configureStore({
    reducer: {
        settings: settingsReducer,
        formStatuses: formStatusesReducer,
        tablesControls: tablesControlsReducer,
        configs: configsReducer,
        spreadSheetConfigs: SpreadSheetReducer,
    },
})

export default store
