/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Typography,
} from '@mui/material'

import useStyles from './ChipSelect.style'

type Props = {
    children?: string,
    innerProps?: Record<string, any>,
    selectProps?: {
        inputValue: string,
    }
}

const defaultProps = {
    children: '',
    innerProps: {},
    selectProps: {},
}

function NoOptionsMessage(props: Props) {
    const {
        innerProps,
        children,
        selectProps,
    } = props

    const {
        classes,
    } = useStyles()

    return (
        <Typography
            color="textSecondary"
            className={classes.noOptionsMessage}
            {...innerProps}
        >
            {selectProps.inputValue ? children : 'Start typing'}
        </Typography>
    )
}

NoOptionsMessage.defaultProps = defaultProps

export default NoOptionsMessage
