import {
    RequestConfig,
} from 'app/types/request.types'

type AwardedRejectLaneRequests = {
    checkAwardedLanes: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
}

const accountQuoteRequests: AwardedRejectLaneRequests = {
    checkAwardedLanes: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}/awarded-implemented-lanes-data`,
            method: 'GET',
        }
    },
}

export default accountQuoteRequests
