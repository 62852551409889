import {
    OrderStepFields,
    TransportModeSea,
    SeaFreightCarrier,
    TransportModeRoad,
    DefaultTruckingComment,
    SeafreightContainerSize,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LocationType,
} from 'app/types/enums'
import {
    FieldInCard,
    NestedField,
} from 'app/types/form.types'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

type Args = {
    forwarderId: number,
    transportModeRoad: TransportModeRoad,
    pickupLocationId: number,
    pickupLocationContacts: {id: number}[],
    deliveryLocationId: number,
    filterLocationType: LocationType,
    transportModeSea: TransportModeSea,
    exists: boolean,
    isFirstStep: boolean,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
}

const getInternalSeaFormFields = ({
    forwarderId,
    transportModeRoad,
    pickupLocationId,
    deliveryLocationId,
    filterLocationType,
    transportModeSea,
    getAllowedValues,
    exists,
    isFirstStep,
}: Args): FieldInCard<OrderStepFields>[] => {
    const trucksAndVans: FieldInCard<OrderStepFields>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'OrderStep.numberOfTrucks',
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'OrderStep.numberOfVans',
        },
    ]

    const pickupLocationContactsByLocationField: NestedField<OrderStepFields>[] = [{
        name: 'pickupLocationContacts',
        componentName: 'MultipleContactByLocationObjectSelector',
        existedForm: exists,
        selectedLocation: pickupLocationId,
        labelKey: 'OrderStep.pickupLocationContacts',
    }]

    const pickupLocationContactsField: NestedField<OrderStepFields>[] = [{
        name: 'pickupLocationContacts',
        componentName: 'MultipleContactObjectSelector',
        labelKey: 'OrderStep.pickupLocationContacts',
    }]

    const seafreightContainerSize = [{
        name: 'seafreightContainerSize',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(SeafreightContainerSize),
        labelKey: 'OrderStep.seafreightContainerSize',
    }]

    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'forwarder',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.ADDRESS,
                    filterAddressMainCategory: AddressMainCategory.FORWARDER,
                    labelKey: 'OrderStep.forwarder',
                },
                {
                    name: 'forwarderContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: exists,
                    selectedLocation: forwarderId,
                    labelKey: 'OrderStep.forwarderContacts',
                },
                {
                    name: 'forwarderAdHoc',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.forwarderAdHoc',
                },
                {
                    name: 'transportModeSea',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeSea),
                    labelKey: 'OrderStep.transportModeSea',
                },
                ...transportModeSea === TransportModeSea.FCL ? seafreightContainerSize as [] : [],
                {
                    name: 'mbolReceived',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.mbolReceived',
                },
                {
                    name: 'mbolNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.mbolNumber',
                },
                {
                    name: 'mbolDocumentCopy',
                    componentName: 'AttachmentsObjectSelector',
                    labelKey: 'OrderStep.mbolDocumentCopy',
                },
                {
                    name: 'transportModeRoad',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'OrderStep.transportModeRoad',
                },
                ...transportModeRoad === TransportModeRoad.FTL ? trucksAndVans as [] : [],
                {
                    name: 'isTemperatureControlled',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.isTemperatureControlled',
                },
                {
                    name: 'defaultTruckingComment',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DefaultTruckingComment),
                    useDropdown: true,
                    labelKey: 'OrderStep.defaultTruckingComment',
                },
                {
                    name: 'truckingComment',
                    componentName: 'LongText',
                    className: 'truckingComment',
                    labelKey: 'OrderStep.truckingComment',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'pickupLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.pickupLocation',
                },
                ...isFirstStep
                    ? pickupLocationContactsByLocationField
                    : pickupLocationContactsField,
                {
                    name: 'requestedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.requestedPickup',
                },
                {
                    name: 'estimatedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.estimatedPickup',
                },
                {
                    name: 'actualPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.actualPickup',
                },
                {
                    name: 'pickupComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.pickupComment',
                },
                {
                    name: 'hbolReceived',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.hbolReceived',
                },
                {
                    name: 'hbolNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.hbolNumber',
                },
                {
                    name: 'hbolDocumentCopy',
                    componentName: 'AttachmentsObjectSelector',
                    labelKey: 'OrderStep.hbolDocumentCopy',
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'deliveryLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.deliveryLocation',
                },
                {
                    name: 'deliveryLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: exists,
                    selectedLocation: deliveryLocationId,
                    labelKey: 'OrderStep.deliveryLocationContacts',
                },
                {
                    name: 'requestedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.requestedDelivery',
                },
                {
                    name: 'estimatedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.estimatedDelivery',
                },
                {
                    name: 'actualDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.actualDelivery',
                },
                {
                    name: 'deliveryComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.deliveryComment',
                },
            ],
        },
        {
            id: 'column4',
            fields: [
                {
                    name: 'seaFreightCarrier',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(SeaFreightCarrier),
                    labelKey: 'OrderStep.seaFreightCarrier',
                },
                {
                    name: 'originSeaport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType,
                    labelKey: 'OrderStep.originSeaport',
                },
                {
                    name: 'destinationSeaport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType,
                    labelKey: 'OrderStep.destinationSeaport',
                },
                {
                    name: 'actualShipDeparture',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.actualShipDeparture',
                },
                {
                    name: 'actualShipArrival',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.actualShipArrival',
                },
                {
                    name: 'seaFreightComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.seaFreightComment',
                },
            ],
        },
    ]
}

export default getInternalSeaFormFields
