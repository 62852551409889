import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    LoggerResponse,
} from '../Loggers.types'
import {
    availableLoggerStatuses,
} from '../Logger/Logger.utils'

export type LoggerUpdate = {
    batteryVoltage: string | number,
    containerSerialNumber: string,
    hardwareVersion?: string,
    softwareVersion?: string,
}

type LoggerUpdateFields = LoggerUpdate & LoggerResponse & {
    'gateway.gatewayImeiMac': string,
}

const loggerUpdateFields = (rentingCompany): DTOCardFormField<keyof LoggerUpdateFields>[] => {
    return [{
        id: 'loggers',
        title: 'Logger',
        className: 'loggers',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'loggerNumber',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'Logger.loggerNumber',
                    },
                    {
                        name: 'batteryVoltage',
                        componentName: 'IntegerInput',
                        disabled: true,
                        labelKey: 'Logger.batteryVoltage',
                    },
                    {
                        name: 'loggerTransmissionRateSeconds',
                        componentName: 'IntegerInput',
                        labelKey: 'Logger.loggerTransmissionRateSeconds',
                    },
                    {
                        name: 'loggerQrCode',
                        componentName: 'InputSingleline',
                        required: true,
                        labelKey: 'Logger.loggerQrCode',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'containerSerialNumber',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'Logger.containerSerialNumber',
                    },
                    {
                        name: 'loggerStatus',
                        componentName: 'EnumSelector',
                        allowedValues: availableLoggerStatuses,
                        labelKey: 'Logger.loggerStatus',
                    },
                    {
                        name: 'loggerType',
                        componentName: 'LoggerTypeSelector',
                        disabled: true,
                        labelKey: 'Logger.loggerType',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'hardwareVersion',
                        componentName: 'InputSingleline',
                        labelKey: 'Logger.hardwareVersion',
                    },
                    {
                        name: 'softwareVersion',
                        componentName: 'InputSingleline',
                        labelKey: 'Logger.softwareVersion',
                    },
                    {
                        name: 'gateway.gatewayImeiMac',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'Logger.gateway',
                    },
                    {
                        name: 'location',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'Container.location',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'rentingCompany',
                        componentName: 'CompanyObjectSelector',
                        labelKey: 'Logger.rentingCompany',
                        disabled: !!rentingCompany,
                    },
                    {
                        name: 'calibrationDocuments',
                        labelKey: 'Logger.calibrationDocuments',
                        componentName: 'AttachmentsObjectSelector',
                    },
                ],
            },
        ],
    }]
}

export default loggerUpdateFields
