import React from 'react'
import upperCase from 'lodash/upperCase'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import InputSingleline from 'app/shared-components/InputSingleline'

import useStyles from './CancelFeeConfirmationDialog.style'

type Props = {
    title: string,
    openConfirmDialog?: boolean,
    handleClose: (confirm: boolean) => void,
    reason: string,
    setReason: (reason: string) => void,
    orderCount: number
}
const defaultProps: Partial<Props> = {
    openConfirmDialog: true,
}
const CancelFeeConfirmationDialog = ({
    title,
    openConfirmDialog,
    handleClose,
    reason,
    setReason,
    orderCount,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <ConfirmationDialog
            open={openConfirmDialog}
            handleClose={handleClose}
            dialogTitle={`${upperCase(title)} CANCEL CONFIRMATION`}
            positiveLabel="Cancel Fee"
            positiveLabelDisabled={reason.length === 0}
            negativeLabel="Do not Cancel Fee"
        >
            <div className={classes.container}>
                <div className={classes.child}>
                    <InputSingleline
                        title="Comment for Billing"
                        value={reason}
                        rows={4}
                        multiline
                        onChange={setReason}
                    />
                </div>
                <div className={classes.child}>
                    {`There ${orderCount > 1 ? 'are' : 'is'} ${orderCount} order${orderCount > 1 ? 's' : ''} linked to this fee.\n
                            Please clarify in the comment how potential affected orders should be priced.`}
                </div>
            </div>
        </ConfirmationDialog>
    )
}

CancelFeeConfirmationDialog.defaultProps = defaultProps

export default CancelFeeConfirmationDialog
