/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import {
    checkValidationError,
} from 'app/shared-components/utils/validation'

const propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any, // eslint-disable-line
    classNames: PropTypes.objectOf(PropTypes.string),
    errors: PropTypes.arrayOf(PropTypes.string),
    uploadFiles: PropTypes.func,
    exists: PropTypes.bool,
    isRequired: PropTypes.bool,
    fieldProps: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        customComponent: PropTypes.func,
    }).isRequired,
    componentValue: PropTypes.any, // eslint-disable-line
    name: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    setErrors: PropTypes.func,
}

const defaultProps = {
    value: {},
    exists: false,
    isRequired: false,
    classNames: {},
    uploadFiles: noop,
    errors: [],
    componentValue: undefined,
    setErrors: noop,
}

const WrapperComponent = (props) => {
    const {
        onChange,
        value,
        name,
        fieldProps,
        classNames,
        uploadFiles,
        componentValue,
        exists,
        errors,
        isRequired,
        component: Component,
        setErrors,
    } = props

    const onHandleChange = useCallback((
        fieldValue,
        event,
        aggregation,
    ) => {
        onChange(
            {
                ...value,
                [name]: fieldValue,
                ...aggregation,
            }, event,
        )
    }, [
        name,
        onChange,
        value,
    ])

    return (
        <>
            <Component
                {...fieldProps}
                classNames={classNames}
                onChange={onHandleChange}
                uploadFiles={uploadFiles}
                value={componentValue}
                exists={exists}
                required={isRequired}
                setErrors={setErrors}
                hasError={checkValidationError(errors, name)}
            />
        </>
    )
}

WrapperComponent.propTypes = propTypes
WrapperComponent.defaultProps = defaultProps

export default WrapperComponent
