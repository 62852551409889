/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import PropTypes from 'prop-types'

import Form from '../../Form'

const fieldTypes = PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    name: PropTypes.string,
}))

const propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        fields: fieldTypes,
    })).isRequired,
}

const GroupSimple = (props) => {
    const {
        fields,
    } = props

    return (
        <>
            {fields.map(({
                id,
                className,
                fields: groupFields,
            }) => {
                return (
                    <div
                        key={id}
                        className={className}
                    >
                        <Form
                            {...props}
                            fields={groupFields}
                        />
                    </div>
                )
            })}
        </>
    )
}

GroupSimple.propTypes = propTypes

export default GroupSimple
