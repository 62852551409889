/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Grid,
} from '@mui/material'
import {
    useTheme,
} from '@mui/material/styles'

import CardDashboard from 'app/shared-components/CardDashboard'
import breakpoints from 'app/Apps/Dashboard/config/breakpoints'
import Layer from 'app/Apps/Dashboard/components/Layer'
import Header from 'app/Apps/Dashboard/components/Header'
import SmallContent from 'app/Apps/Dashboard/components/SmallContent'

import useStyles from './DashboardWidget.style'

const propTypes = {
    'data-testid': PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitleText: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.node,
    ]),
    links: PropTypes.arrayOf(
        PropTypes.shape({}),
    ).isRequired,
}

const defaultProps = {
    'data-testid': '',
    className: '',
    icon: () => {
        return ''
    },
}

const DashboardWidget = ({
    className,
    title,
    subTitleText,
    links,
    icon: Icon,
    ...rest
}) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    if (links.length === 0) {
        return null
    }

    return (
        <Grid
            data-testid={rest['data-testid']}
            item
            {...breakpoints}
        >
            <CardDashboard
                className={clsx(className, classes.cardBody)}
                header={(
                    <Header>
                        <Icon className={classes.icon} />
                    </Header>
                )}
                smallContent={(
                    <SmallContent
                        title={title}
                        text={subTitleText}
                    />
                )}
                layer={(
                    <Layer
                        layerButtons={links}
                        layerClasses={clsx(links.length <= 3 && classes.column)}
                    />
                )}
            />
        </Grid>
    )
}

DashboardWidget.propTypes = propTypes
DashboardWidget.defaultProps = defaultProps

export default DashboardWidget
