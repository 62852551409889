import React, {
    useState,
    useMemo,
} from 'react'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import FormWithControls from 'app/shared-components/FormWithControls'
import StatusHandler from 'app/shared-components/StatusHandler'

import requestConfig from '../Companies.request'
import useStyles from './SkyMindSync.style'
import getSkyMindSyncFields from './SkyMindSync.fields'
import useGetCompany from '../hooks/useGetCompany'
import {
    Company,
} from '../Companies.types'

type SkyMindSyncData = Pick<Company, 'availableInSkyMind' | 'skyMindId'>

const SkyMindSync = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetCompany(id)

    const {
        classes,
    } = useStyles()
    const {
        gridWrapper,
    } = classes

    const [
        value,
        setValue,
    ] = useState<SkyMindSyncData>({
        availableInSkyMind: undefined,
        skyMindId: undefined,
    })

    const formData = useMemo(() => {
        if (data?.skyMindId) {
            return {
                availableInSkyMind: true,
                skyMindId: data?.skyMindId,
            }
        }
        return {
            availableInSkyMind: value?.availableInSkyMind,
            skyMindId: value?.availableInSkyMind ? value?.skyMindId : null,
        }
    }, [
        data,
        value,
    ])

    const skyMindSyncFields = useMemo(() => {
        return getSkyMindSyncFields(
            Boolean(data?.skyMindId),
            value.availableInSkyMind,
        )
    }, [
        data?.skyMindId,
        value.availableInSkyMind,
    ])

    const fields = useFieldsWithClassName(
        skyMindSyncFields, classes,
    )

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                name="CompanySkyMindSync"
                value={formData}
                fields={fields}
                setExternalValue={setValue}
                classNames={{
                    gridWrapper,
                }}
                requestParams={requestConfig.skyMindSyncUpdate({
                    id,
                })}
                onSuccess={invalidate}
                onCancel={invalidate}
                exists
            />
        </StatusHandler>
    )
}

export default SkyMindSync
