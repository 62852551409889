import reducer, {
    setSpreadSheetConfig,
    isSpreadSheetConfigSet,
    selectSpreadSheetConfigByFieldName,
    selectSpreadSheetConfigByDomain,
    addToDomainConfigs,
} from './SpreadSheetConfigs.slice'

import useStoreSpreadSheetConfig from './useStoreSpreadSheetConfig'

export {
    addToDomainConfigs,
    setSpreadSheetConfig,
    isSpreadSheetConfigSet,
    selectSpreadSheetConfigByFieldName,
    selectSpreadSheetConfigByDomain,
    useStoreSpreadSheetConfig,
}

export default reducer
