import {
    UseRequestConfig,
} from 'app/types/request.types'

const request: Record<string, UseRequestConfig> = {
    SharePositioning: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/shared-positioning-orders`,
            }
        },
    },
    Create: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/shared-positioning-orders`,
                method: 'POST',
            }
        },
    },
}

export default request
