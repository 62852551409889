import React, {
    useMemo,
} from 'react'
import clsx from 'clsx'
import {
    useFilterContext,
    useFilterRequest,
} from 'app/hooks/useFilter'
import {
    FilterContextAdapter,
} from 'app/shared-components/Filter'
import {
    HeaderFilterableCell as HeaderFilterableCellType,
} from './HeaderFilterableTemplate'
import useStyles from './HeaderFilterableCell.styles'

const HeaderFilterableCell = ({
    cell,
}: {
    cell: HeaderFilterableCellType
}) => {
    const {
        classes,
    } = useStyles()
    const filterContext = useFilterContext()

    const filterConfig = useMemo(() => {
        if (!filterContext) return null
        return filterContext?.config[cell.name]
    }, [
        filterContext,
        cell.name,
    ])
    const loadFilters = useFilterRequest({
        request: filterContext?.request,
        fieldName: filterConfig?.filterField,
        allFilters: filterContext?.filter,
    })

    return (
        <div className={classes.container}>
            <span className={clsx(classes.text, {
                [classes.withFilter]: !!filterConfig,
            })}
            >
                {cell.text}
            </span>
            { filterConfig ? (
                <span className={classes.filterIcon}>
                    <FilterContextAdapter
                        loadFilters={loadFilters}
                        name={filterConfig?.filterField}
                    />
                </span>
            ) : null }
        </div>
    )
}

export default HeaderFilterableCell
