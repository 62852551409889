/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import CompanyForm from '../CompanyForm/CompanyForm'
import useCreateCompany from '../hooks/useCreateCompany'

const CompanyCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((company) => {
        addTableElement(company)
        selectDomainObject(company.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    const createCompany = useCreateCompany({
        onSuccess,
    })

    return (
        <CompanyForm
            data-testid="create-company"
            action={createCompany}
        />
    )
}

export default CompanyCreate
