import React from 'react'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'

import {
    TooltipWrapper,
} from '../Tooltip'
import useStyles from './NotificationIcon.styles'
import {
    TypeIcon,
} from './notificationIcon.types'

const NotificationIcon = ({
    children,
    text,
    type,
}: {
    children: JSX.Element,
    text: string,
    type: TypeIcon,
}) => {
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <div className={classes.container}>
            { children }
            <TooltipWrapper
                value={text}
                ownTooltipProps={{
                    enterDelay: 100,
                    placement: 'bottom',
                }}
            >
                <ErrorOutlinedIcon
                    className={cx(classes[type], classes.icon)}
                />
            </TooltipWrapper>
        </div>
    )
}

export default NotificationIcon
