import React, {
    useMemo,
} from 'react'

import {
    StepProgress,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    SubstepName,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import useStyles from './StepProgressItem.style'

type Props = {
    progressStep: StepProgress,
    previousProgressStep?: StepProgress,
}

const defaultProps = {
    previousProgressStep: undefined,
}

const COMPLETED = 'completed'
const IN_PROGRESS = 'in_progress'
const NOT_STARTED = 'not_started'

const StepProgressItem = ({
    progressStep,
    previousProgressStep,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValuesStepProgress = useEnumValues()(SubstepName)

    const {
        substepCompleted,
        substepName,
    } = progressStep

    const iconStepProgress: string = useMemo(() => {
        if (substepCompleted) {
            return COMPLETED
        }

        if (!substepCompleted && previousProgressStep?.substepCompleted) {
            return IN_PROGRESS
        }

        return NOT_STARTED
    }, [
        previousProgressStep,
        substepCompleted,
    ])

    return (
        <div className={classes.container}>
            <img
                className={classes.icon}
                src={`assets/images/orderSteps/${iconStepProgress}.svg`}
                alt={substepName}
            />
            <span>{allowedValuesStepProgress[substepName]}</span>
        </div>
    )
}

StepProgressItem.defaultProps = defaultProps

export default StepProgressItem
