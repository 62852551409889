import {
    LaneFeesStatus,
} from 'app/types/enums'

export const QUOTATION = 'Quotation'
export const EXECUTION = 'Execution'

export type PrintFilterValues = {
    restrictAirports: boolean,
    selectedAirports: number[],
    customerType: typeof EXECUTION | typeof QUOTATION,
    selectedCustomerExecution: string,
    selectedLaneFeesStatuses: LaneFeesStatus[],
    selectedLaneStatus: string,
    exactDates: boolean,
    laneFeeValidFrom: string,
    laneFeeValidTo: string,
    isCustomerQuotation: boolean,
    allowDuplication: boolean,
    comment: string,
}

export type ContactsSelection = {
    keyAccountManager?: string,
    businessDeveloper?: string,
    customerQuotationContact?: string,
    customerExecutionContact?: string,
}
