import {
    makeStyles,
} from 'app/tss'

const generalInformation = 'generalInformation'
const origin = 'origin'
const containerInformation = 'containerInformation'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapperWithContainerInformation: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${generalInformation} ${generalInformation} ${origin} ${containerInformation}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${generalInformation} ${generalInformation} ${origin}"
                `,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        generalInformation: {
            gridArea: generalInformation,
        },
        origin: {
            gridArea: origin,
        },
        containerInformation: {
            gridArea: containerInformation,
        },
    }
})

export default useStyles
