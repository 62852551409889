import {
    App,
} from 'app/types/common.enums'

const getAppNameFromPath = (path: string) => {
    const pathSplitted = path.split('/')

    if (Object.values(App).includes(pathSplitted[2] as App)) {
        return pathSplitted[3]
    }
    return pathSplitted[2]
}

export default getAppNameFromPath
