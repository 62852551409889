import {
    makeStyles,
} from 'app/tss'

const generalInformation = 'generalInformation'
const serviceCenterMetrics = 'serviceCenterMetrics'
const productMetrics = 'productMetrics'
const containerProductionRate = 'containerProductionRate'
const storages = 'storages'

const useStyles = makeStyles<{isProductionSite: boolean}>()((theme, {
    isProductionSite,
}) => {
    const gridTemplateAreas = isProductionSite ? `
                "${generalInformation} ${serviceCenterMetrics}"
                "${productMetrics} ${containerProductionRate}"
                "${storages} ${storages}"
                ` : `
                "${generalInformation} ${serviceCenterMetrics}"
                "${productMetrics} ${productMetrics}"
                "${storages} ${storages}"
                `

    return {
        gridWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateAreas,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        generalInformation: {
            gridArea: generalInformation,
        },
        serviceCenterMetrics: {
            gridArea: serviceCenterMetrics,
        },
        productMetrics: {
            gridArea: productMetrics,
        },
        containerProductionRate: {
            gridArea: containerProductionRate,
        },
        storages: {
            gridArea: storages,
        },
    }
})

export default useStyles
