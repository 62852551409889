import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            top: 0,
        },
        tableContent: {
            boxShadow: 'none !important',
        },
    }
})

export default useStyles
