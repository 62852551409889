import {
    NestedField,
} from 'app/types/form.types'
import {
    Timezone,
} from 'app/types/enums'
import {
    LocationFormFields,
} from '../LocationForm.types'

export const getSeaportFieldDetails = ({
    country,
    getAllowedValues,
}): NestedField<LocationFormFields>[] => {
    return [
        {
            name: 'unloCode',
            required: true,
            componentName: 'InputSingleline',
            labelKey: 'Location.unloCode',
        },
        {
            name: 'country',
            componentName: 'CountryDataSelector',
            labelKey: 'Location.country',
            value: country,
        },
        {
            name: 'timeZone',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(Timezone),
            required: true,
            labelKey: 'Location.timeZone',
        },
    ]
}

export default getSeaportFieldDetails
