import {
    RequestConfig,
    RequestForDomainForm,
} from 'app/types/request.types'

type Accounts = {
    domainName: string,
    create: RequestForDomainForm<undefined>,
    get: ({
        id,
    }:{
        id: number,
    }) => RequestConfig,
}

const request: Accounts = {
    domainName: 'ActivityRecord',
    create: {
        name: 'Account',
        request: () => {
            return {
                url: 'activityrecord',
                method: 'POST',
                domainName: 'ActivityRecord',
            }
        },
    },
    get: ({
        id,
    }) => {
        return {
            url: `activityrecord/${id}`,
        }
    },
}

export default request
