import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    value: PropTypes.bool,
}

const defaultProps = {
    value: false,
}

const Boolean = (props) => {
    const {
        value,
    } = props

    return (
        <div>
            {value ? 'Yes' : 'No'}
        </div>
    )
}

Boolean.propTypes = propTypes
Boolean.defaultProps = defaultProps

export default Boolean
