import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import requests from '../alertNotifications.requests'

export default ({
    id,
    onSuccess,
    isRead,
}: { id: number, onSuccess: (...args: any[]) => void, isRead: boolean }) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdate,
        onError,
    } = useHandleStatusRequest({
        title: `Alert marked as ${isRead ? 'unread' : 'read'}`,
        id: 'AlertMarkedAsRead',
        onSuccess,
    })

    const {
        mutate: markAsRead,
    } = useMutation({
        mutationFn: () => {
            const requestConfig = isRead ? requests.markAsUnread : requests.markAsRead

            return simpleApi({
                ...requestConfig({
                    id,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessUpdate,
    })

    return useCallback(() => {
        markAsRead()
    }, [markAsRead])
}
