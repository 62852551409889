import {
    FieldInCard,
} from 'app/types/form.types'
import {
    LoggerResponse,
    LoggerStatus,
} from '../Loggers.types'
import {
    availableLoggerStatuses,
} from '../Logger/Logger.utils'

export type LoggerCreate = {
    loggerTransmissionRateSeconds: string | number,
    softwareVersion: string,
    hardwareVersion: string,
}

type LoggerFields = keyof LoggerCreate | keyof LoggerResponse

const loggerCreateFields = (
    loggerStatus: LoggerStatus,
): FieldInCard<LoggerFields>[] => {
    return [{
        id: 'loggers',
        title: 'Logger',
        className: 'loggers',
        fields: [
            {
                name: 'loggerNumber',
                componentName: 'InputSingleline',
                required: true,
            },
            {
                name: 'loggerType',
                componentName: 'LoggerTypeSelector',
                required: true,
            },
            {
                name: 'loggerTransmissionRateSeconds',
                componentName: 'IntegerInput',
            },
            {
                name: 'loggerStatus',
                componentName: 'EnumSelector',
                required: true,
                value: loggerStatus || LoggerStatus.ACTIVE,
                allowedValues: availableLoggerStatuses,
            },
            {
                name: 'loggerQrCode',
                componentName: 'InputSingleline',
                required: true,
            },
            {
                name: 'softwareVersion',
                componentName: 'InputSingleline',
            },
            {
                name: 'hardwareVersion',
                componentName: 'InputSingleline',
            },
        ],
    }]
}

export default loggerCreateFields
