import {
    makeStyles,
} from 'app/tss'

const general = 'general'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            height: '100%',
            position: 'relative',
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'unset',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        tableHeaderWrapper: {
            position: 'relative',
        },
        root: {
            position: 'relative',
            marginBottom: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(2),
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${general} ${general} ${general} ${general}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        general: {
            gridArea: general,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        generalCard: {
            paddingBottom: theme.spacing(1),
        },
    }
})

export default useStyles
