import {
    useMemo,
} from 'react'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

export default (
    tableControls: TableControlTypes[],
    permissions: {[key in TableControlTypes]?: boolean},
) => {
    return useMemo(() => {
        return tableControls.filter((control) => {
            if (permissions?.[control] === undefined) {
                return true
            }
            return permissions?.[control]
        }, [])
    }, [
        tableControls,
        permissions,
    ])
}
