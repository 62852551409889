import replaceTimezone from 'app/utils/date/replaceTimezone'

const getTimezones = (timeZones) => {
    return Object.entries(timeZones).map(([
        key,
        value,
    ]) => {
        return [
            replaceTimezone(key),
            value,
        ]
    })
}

export default getTimezones
