import {
    RequestConfig,
} from 'app/types/request.types'

type LaneSelectorRequests = {
    get: ({
        id,
    }) => RequestConfig,
    Selector: string,
}

const requestConfig: LaneSelectorRequests = {
    Selector: 'lane/filter-request/selector',
    get: ({
        id,
    }) => {
        return {
            url: `lane/${id}`,
        }
    },
}

export default requestConfig
