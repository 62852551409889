import {
    useState, useEffect, useCallback,
} from 'react'
import isEmpty from 'lodash/isEmpty'

export default function useInitValue(value: Record<string, any>[]) {
    const [
        initValue,
        setInitValue,
    ] = useState(null)

    useEffect(() => {
        if (!isEmpty(value) && isEmpty(initValue)) {
            setInitValue(value)
        }
    }, [
        initValue,
        value,
    ])

    const discardInitValue = useCallback(() => {
        setInitValue(null)
    }, [setInitValue])

    return [
        initValue,
        discardInitValue,
    ]
}
