import simpleApi from 'app/services/simpleApi'

const config = {
    domainName: 'calibrationDocument',
    removeLoggerDocument: {
        mutationFn: ({
            loggerId,
            documentId,
            token,
        }) => {
            return simpleApi({
                url: `logger/${loggerId}/calibrationdocument/${documentId}/removal`,
                method: 'POST',
                token,
            })
        },
    },
    getLoggerDocuments: ({
        loggerId,
    }: {
        loggerId: number,
    }) => {
        return {
            url: `logger/${loggerId}/calibrationdocument/filter-request/all`,
            method: 'POST',
        }
    },
    addDocument: {
        mutationFn: ({
            loggerId,
            token,
            data,
        }) => {
            return simpleApi({
                url: `logger/${loggerId}/calibrationdocument`,
                data,
                method: 'POST',
                token,
            })
        },
    },
}

export default config
