import moment from 'moment'

import dateTimeZonedMask from './dateTimeZonedMask'
import dateToStr from './dateToStr'
import getTimeZone from './getTimeZone'

const dateToStrTimezone = (date, zone, mask = dateTimeZonedMask) => {
    if (!date) {
        return undefined
    }

    const timeZone = zone || getTimeZone(date)

    return dateToStr(moment(date, mask).utc().utcOffset(timeZone))
}

export default dateToStrTimezone
