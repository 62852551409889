import React from 'react'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './ChipSelect.style'

const propTypes = {
    children: PropTypes.arrayOf(PropTypes.any).isRequired,
}

function ValueContainer(props) {
    const {
        children,
    } = props

    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <div className={classes.valueContainer}>
            {children}
        </div>
    )
}

ValueContainer.propTypes = propTypes

export default ValueContainer
