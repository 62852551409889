import {
    FieldInCard,
} from 'app/types/form.types'
import {
    ModificationType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    RelinkLaneType,
} from '../dataUpdate.types'

const getFields = (
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): FieldInCard<keyof RelinkLaneType>[] => {
    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'modificationType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(ModificationType),
                    useDropdown: true,
                    required: true,
                    labelKey: 'OrderByNumberView.modificationReason',
                },
                {
                    name: 'lane',
                    componentName: 'LaneSelector',
                    required: true,
                    labelKey: 'Order.lane',
                },
                {
                    name: 'modificationRequestedOn',
                    componentName: 'DateTimeZonedSelect',
                    required: true,
                    labelKey: 'OrderByNumberView.modificationRequestedOn',
                },
            ],
        },
        {
            id: 'column2',
            fields: [{
                name: 'commentForBilling',
                componentName: 'LongText',
                multiline: true,
                required: true,
                labelKey: 'LaneFees.commentForBilling',
            }],
        },
    ]
}

export default getFields
