import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        options: {
            width: 450,
        },
    }
})

export default useStyles
