import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    Company,
} from '../Companies.types'
import requests from '../Companies.request'

export default ({
    onSuccess,
}: { onSuccess: (...args: any[]) => void }) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdateCompany,
        onError,
    } = useHandleStatusRequest({
        title: 'Company was updated',
        id: 'CompanyUpdate',
        onSuccess,
    })

    const {
        mutate: updateCompany,
    } = useMutation({
        mutationFn: (company: Company) => {
            return simpleApi({
                ...requests.update({
                    data: company,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessUpdateCompany,
    })

    return useCallback((company: Company) => {
        updateCompany(company)
    }, [updateCompany])
}
