import React, {
    useCallback,
    useState,
} from 'react'

import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useShareContext from 'app/shared-components/SharedContext/useSharedContext'

import useStyles from './LoggerDocumentDelete.style'
import useDeleteLoggerDocument from './hooks/useDeleteLoggerDocument'

const LoggerDocumentDelete = ({
    value,
}: {
    value: number
}) => {
    const {
        classes,
    } = useStyles()
    const {
        loggerId,
        updateLoggerDocuments,
    } = useShareContext<{ loggerId: number, updateLoggerDocuments:() => void }>()

    const {
        mutate,
    } = useDeleteLoggerDocument(updateLoggerDocuments)

    const [
        confirmation,
        setConfirmation,
    ] = useState(false)

    const onConfirm = useCallback((positive) => {
        if (positive) {
            mutate({
                loggerId,
                documentId: value,
            })
        }
        setConfirmation(false)
    }, [
        mutate,
        value,
        loggerId,
    ])

    return (
        <>
            <IconButton
                onClick={() => {
                    return setConfirmation(true)
                }}
            >
                <DeleteIcon />
            </IconButton>
            <ConfirmationDialog
                dialogTitle="Delete Document Confirmation"
                open={confirmation}
                dialogContent="Are you sure you want to delete this document?"
                handleClose={onConfirm}
                classNames={{
                    content: classes.title,
                }}
            />
        </>
    )
}

export default LoggerDocumentDelete
