import React, {
    useCallback, useMemo,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useLabel from 'app/hooks/useLabel'
import useEnumValues from 'app/hooks/useEnumValues'
import Card from 'app/shared-components/Card'
import {
    OpportunityType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import useGetOpportunitiesByQuote from 'app/Apps/AccountManagement/AccountQuotes/hooks/useGetOpportunitiesByQuote'

import {
    AccountQuote, QuoteStatus, RequestedOpportunity,
} from '../../AccountQuotes.types'
import getColumns from './RequestedOpportunities.columns'
import useStyles from '../table.styles'
import RequestedLanes from '../RequestedLanes'

const RequestedOpportunities = ({
    data,
}: {
    data: AccountQuote
}) => {
    const l = useLabel()
    const getAllowedValues = useEnumValues()
    const {
        classes,
    } = useStyles()

    const {
        data: opportunities,
        isDataReady: opportunitiesIsReady,
    } = useGetOpportunitiesByQuote(data?.id)

    const columns = useMemo(() => {
        return getColumns({
            l,
            getAllowedValues,
            opportunityStyle: classes.opportunity,
        })
    }, [
        l,
        getAllowedValues,
        classes.opportunity,
    ])

    const oppTableData = useMemo(() => {
        if (opportunitiesIsReady) {
            return data?.requestedOpportunities.map((opp) => {
                return {
                    ...opportunities.find((el) => { return el.id === opp.opportunity.id }) || {},
                    ...opp,
                }
            }) as unknown as (RequestedOpportunity & OpportunityType)[]
        }
        return [] as unknown as (RequestedOpportunity & OpportunityType)[]
    }, [
        opportunitiesIsReady,
        data?.requestedOpportunities,
        opportunities,
    ])

    const load = useCallback(() => {
        return Promise.resolve({
            data: oppTableData,
        })
    }, [oppTableData])

    return (
        <>
            <Card
                title="Requested Opportunities"
                className={classes.cardWrapper}
            >
                <RichTable
                    configName="RequestedOpportunitiesTable"
                    name="Requested Opportunities"
                    load={load}
                    columns={columns}
                    uniqField="id"
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                />
            </Card>
            {
                data?.requestedLanes.length > 0 && oppTableData.length > 0 ? (
                    <RequestedLanes
                        requestedLanes={data.requestedLanes}
                        requestedOpportunities={oppTableData}
                        accountQuoteId={data.id}
                        disabled={data.quoteStatus === QuoteStatus.CANCELLED}
                    />
                ) : null
            }
        </>

    )
}

export default RequestedOpportunities
