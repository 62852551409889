import React, {
    useCallback,
} from 'react'

import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import Collapse from '@mui/material/Collapse'
import noop from 'lodash/noop'
import useLabel from 'app/hooks/useLabel'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useCollapsablePanel from 'app/shared-components/CollapsibleTable/hooks/useCollapsablePanel'

import useStyles from './StorageTable.style'
import getColumns from './Storage.columns'
import {
    StorageTableType,
} from '../../serviceCenters.types'
import StorageUpdate from './StorageUpdate'
import StorageCreate from './StorageCreate'

type Props = {
    value?: StorageTableType[],
    onChange?: (val: StorageTableType[]) => void,
    disabled?: boolean
}
const defaultProps: Partial<Props> = {
    value: [],
    onChange: noop,
    disabled: false,
}

const domainName = 'Storages'

const StorageTable = ({
    value,
    onChange,
    disabled,
}: Props) => {
    const l = useLabel()
    const {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    } = useCollapsablePanel('storages')

    const {
        classes,
    } = useStyles()

    const loadData = useCallback(() => {
        return Promise.resolve({
            data: value,
        })
    }, [value])

    const handleAddStorage = useCallback((newVal: StorageTableType) => {
        onChange([
            ...value,
            newVal,
        ])
        closeCreateForm()
    }, [
        closeCreateForm,
        onChange,
        value,
    ])

    const handleRemoveStorage = useCallback((storageId: number) => {
        onChange(value.filter((storages) => {
            return storages.id !== storageId
        }))
    }, [
        value,
        onChange,
    ])

    const updateStorage = useCallback((updatedStorage: StorageTableType) => {
        const newVal = value.map((storage) => {
            if (storage.id === updatedStorage.id) {
                return updatedStorage
            }

            return storage
        })

        onChange(newVal)
    }, [
        onChange,
        value,
    ])

    const getUpdateForm = useCallback(({
        rowProps,
        closeOpenedRow,
    }) => {
        return (
            <StorageUpdate
                value={rowProps}
                closeOpenedRow={closeOpenedRow}
                updateStorage={updateStorage}
                removeStorage={handleRemoveStorage}
            />
        )
    }, [
        handleRemoveStorage,
        updateStorage,
    ])

    const columns = getColumns(l)

    return (
        <div
            className={classes.root}
        >
            {!disabled && (
                <div className={classes.header}>
                    <div className={classes.icon}>
                        {createFormOpened
                            ? (
                                <RemoveIcon
                                    className={classes.icon}
                                    onMouseDown={closeCreateForm}
                                />
                            )
                            : (
                                <AddIcon
                                    className={classes.icon}
                                    onMouseDown={openCreateForm}
                                />
                            )}
                    </div>
                </div>
            )}
            <Collapse
                in={createFormOpened}
                className={classes.formContainer}
            >
                <StorageCreate
                    closeCreateForm={closeCreateForm}
                    onAddStorage={handleAddStorage}
                />
            </Collapse>
            <RichTable
                configName={domainName}
                name={domainName}
                columns={columns}
                load={loadData}
                uniqField="id"
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                    headerWrapper: classes.tableHeaderWrapper,
                }}
                detailPanel={getUpdateForm}
                disabled={disabled}
            />
        </div>
    )
}

StorageTable.defaultProps = defaultProps

export default StorageTable
