import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        actions: {
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
