import {
    SimulationStep,
} from '../simulation.types'

const getSummaryTimeSteps = (steps: SimulationStep[]): {
    h?: number,
    m?: number,
} => {
    if (!steps) {
        return {}
    }
    const summary = steps?.reduce((sum, {
        durationHours,
        durationMinutes,
    }) => {
        return {
            h: sum.h + (durationHours ? Number(durationHours) : 0),
            m: sum.m + (durationMinutes ? Number(durationMinutes) : 0),
        }
    }, {
        h: 0,
        m: 0,
    })

    return summary.m < 59 ? summary : {
        h: summary.h + Math.floor(summary.m / 60),
        m: summary.m % 60,
    }
}

export default getSummaryTimeSteps
