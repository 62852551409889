import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        label: {
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary[600],
            fontWeight: theme.typography.body1.fontWeight,
            width: 300,
        },
        slider: {
            width: 500,
            zIndex: 1000,
        },
    }
})

export default useStyles
