import React, {
    useCallback, useMemo, useState,
} from 'react'
import moment from 'moment'
import useEnumValues from 'app/hooks/useEnumValues'
import noop from 'lodash/noop'
import {
    RequestConfig,
} from 'app/types/request.types'
import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    ProductType,
} from 'app/types/enums'

import {
    monthYearShortMask,
} from 'app/utils/date'
import getFields from './ShortTermForecastForm.fields'
import {
    ForecastDataSpreadsheetColumnsConfig,
    ForecastOngoingOpportunities,
    ForecastSpreadsheetDataType,
    ForecastSpreadsheetDataTypeEnum,
} from '../../Forecast.types'
import useStyles from './ShortTermForecastForm.style'
import {
    createDefaultValues, parseForecastData,
} from '../../Forecast.utils'

const defaultProps = {
    onSuccess: noop,
    onCancel: noop,
    requestParam: undefined,
    requiredRows: [ForecastSpreadsheetDataTypeEnum.FORECAST],
    disabled: false,
}

const ShortTermForecastForm = ({
    requestParam,
    onSuccess,
    onCancel,
    data,
    disabled,
    tmpFields,
    fieldsMap,
    requiredRows,
}: {
    onSuccess?: (...a: any[]) => void,
    onCancel?: (...a: any[]) => void,
    data: ForecastOngoingOpportunities,
    requestParam?: RequestConfig,
    disabled?: boolean,
    tmpFields: ForecastDataSpreadsheetColumnsConfig,
    requiredRows?: ForecastSpreadsheetDataTypeEnum[],
    fieldsMap: {
        forecastFields: Map<string, string>,
        actualFields?: Map<string, string>
    }
}) => {
    const concatFieldsMap = useMemo(() => {
        return new Map([
            ...Array.from(fieldsMap.forecastFields),
            ...Array.from(fieldsMap.actualFields || []),
        ])
    }, [
        fieldsMap.actualFields,
        fieldsMap.forecastFields,
    ])

    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const dataMap = useMemo(() => {
        return {
            [ForecastSpreadsheetDataTypeEnum.FORECAST]: 'forecasts',
            [ForecastSpreadsheetDataTypeEnum.ACTUALS]: 'actuallyShippedContainers',
        }
    }, [])

    const [
        value,
        setValue,
    ] = useState({
        ...data,
        spreadsheetData: requiredRows.map((dataType) => {
            return {
                dataType,
                ...(data[dataMap[dataType]] || []).reduce(
                    parseForecastData(), createDefaultValues(concatFieldsMap),
                ),
            }
        }),
    })

    const onSpreadsheetChange = useCallback((val: ForecastSpreadsheetDataType[]) => {
        const next = {
            ...value,
            spreadsheetData: val,
        }

        setValue(next)
    }, [value])

    const modifyDataBeforeSend = useCallback(() => {
        return {
            // for now we're editing only 3 further month of forecast row on this form
            [dataMap[ForecastSpreadsheetDataTypeEnum.FORECAST]]: value.spreadsheetData.reduce(
                (acc, item) => {
                    if (item.dataType === ForecastSpreadsheetDataTypeEnum.FORECAST) {
                        Array.from(fieldsMap.forecastFields).forEach(([key]) => {
                            const momentDate = moment(key, monthYearShortMask)

                            acc.push({
                                month: momentDate.month() + 1,
                                year: momentDate.year(),
                                units: item[key],
                            })
                        })
                        return acc
                    }
                    return acc
                }, [],
            ),
        }
    }, [
        dataMap,
        fieldsMap,
        value.spreadsheetData,
    ])

    const fields = useFieldsWithClassName(useMemo(() => {
        return getFields({
            getAllowedValues,
            productType: data?.productType,
            tmpFields,
            disabled,
            onSpreadsheetChange,
            productTypeLabels: getAllowedValues(ProductType),
        })
    }, [
        tmpFields,
        getAllowedValues,
        data,
        disabled,
        onSpreadsheetChange,
    ]), classes)

    return (
        <FormWithControls
            onSuccess={onSuccess}
            value={value}
            name="ForecastOngoingOpportunities"
            fields={fields}
            requestParams={requestParam}
            exists={Boolean(data?.id)}
            onCancel={onCancel}
            disabled={disabled}
            modifyDataBeforeSend={modifyDataBeforeSend}
            customLabelSave="Update"
        />
    )
}

ShortTermForecastForm.defaultProps = defaultProps

export default ShortTermForecastForm
