import React, {
    useCallback,
} from 'react'
import noop from 'lodash/noop'
import {
    v1 as uuidv1,
} from 'uuid'

import {
    GeneralFreight as GeneralFreightType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import GeneralFreight from './GeneralFreight'

type Props = {
    name?: string,
    value?: GeneralFreightType[],
    onChange?: (newVal: GeneralFreightType[], e?: {
        target: {
            name: string,
            value: GeneralFreightType[],
        },
    }) => void,
    disabled?: boolean,
}

const defaultProps = {
    name: 'generalFreight',
    value: [],
    onChange: noop,
    disabled: false,
}

const GeneralFreightContainer = ({
    name,
    value,
    onChange,
    disabled,
}: Props) => {
    const handleChange = useCallback((generalFreight: GeneralFreightType): void => {
        const updatedGeneralFreights = value.map((item) => {
            if (item.id === generalFreight.id) {
                return generalFreight
            }

            return item
        })

        onChange(updatedGeneralFreights, {
            target: {
                name,
                value: updatedGeneralFreights,
            },
        })
    }, [
        name,
        value,
        onChange,
    ])

    const handleAddEntry = useCallback((): void => {
        const newEntry = [
            ...value,
            {
                id: `temp-${uuidv1()}`,
                material: undefined,
                description: undefined,
                quantity: undefined,
                unit: undefined,
                weightKg: undefined,
                dimensions: undefined,
            },
        ]

        onChange(
            newEntry,
            {
                target: {
                    name,
                    value: newEntry,
                },
            },
        )
    }, [
        name,
        onChange,
        value,
    ])

    const handleDeleteEntry = useCallback((generalFreightId: number | string): void => {
        onChange(value.filter(({
            id,
        }) => {
            return id !== generalFreightId
        }))
    }, [
        value,
        onChange,
    ])

    return (
        <GeneralFreight
            value={value}
            onChange={handleChange}
            addEntry={handleAddEntry}
            deleteEntry={handleDeleteEntry}
            disabled={disabled}
        />
    )
}

GeneralFreightContainer.defaultProps = defaultProps

export default GeneralFreightContainer
