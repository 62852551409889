import React from 'react'
import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'

import LaneGroupCopy from '../LaneGroupCopy/LaneGroupCopy'
import LaneGroupUpdate from '../LaneGroupUpdate'

type Props = {
    id: number,
}

const LaneGroupOverview = ({
    id,
}: Props) => {
    const {
        url,
    } = useRouteMatch()

    return (
        <Switch>
            <Route
                exact
                path={url}
            >
                <LaneGroupUpdate id={id} />
            </Route>
            <Route
                path={`${url}/:copy`}
            >
                <LaneGroupCopy id={id} />
            </Route>
        </Switch>
    )
}

export default LaneGroupOverview
