import React, {
    useLayoutEffect,
    useRef,
    useMemo,
} from 'react'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import useStyles from './GoogleMap.style'
import drawMap from './drawMap'
import {
    MapConfig, MarkerConfig, PolylineConfig,
} from './googleMap.types'

const MAP_CONFIG_DEFAULT = {
    zoom: 3,
    center: {
        lat: 47.22,
        lng: 8.33,
    },
    mapTypeId: 'terrain',
}

type Props = {
    mapConfig?: MapConfig,
    polylineConfig?: PolylineConfig,
    markerConfig?: MarkerConfig[],
}

const defaultProps = {
    mapConfig: null,
    polylineConfig: null,
    markerConfig: null,
}

const GoogleMap = (props: Props) => {
    const {
        classes,
    } = useStyles()
    const elm = useRef()
    const {
        mapConfig: mapConfigFromUser,
        polylineConfig,
        markerConfig,
    } = props

    const {
        onError,
    } = useHandleStatusRequest({
        id: 'googleMap',
    })

    const mapConfig: MapConfig = useMemo(() => {
        return {
            ...MAP_CONFIG_DEFAULT,
            ...mapConfigFromUser,
        }
    }, [mapConfigFromUser])

    useLayoutEffect(() => {
        drawMap(
            elm.current,
            mapConfig,
            polylineConfig,
            markerConfig,
            onError,
        )
    }, [
        mapConfig,
        markerConfig,
        onError,
        polylineConfig,
    ])

    return (
        <div
            ref={elm}
            className={classes.container}
        />
    )
}

GoogleMap.defaultProps = defaultProps

export default GoogleMap
