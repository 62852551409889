import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: 10,
            padding: '10px 0',
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        contactNameComment: {
            color: theme.palette.secondary[400],
        },
        contactCard: {
            display: 'block',
        },
        rowFormContainer: {},
        linkToEngagement: {
            paddingTop: theme.spacing(2),
        },
    }
})

export default useStyles
