import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
        },
        chipSelectInput: {
            color: `${theme.palette.secondary[700]} !important`,
            height: 'auto !important',
            display: 'flex !important',
            padding: '0 !important',
        },
        paper: {
            position: 'absolute',
            left: 0,
            right: 0,
        },
        chip: {
            '&&': {
                margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0 !important`,
                backgroundColor: `${theme.palette.secondary[200]} !important`,
                color: `${theme.palette.common.black} !important`,
            },
        },
        noOptionsMessage: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        },
        placeholder: {
            position: 'absolute',
            left: 0,
            fontSize: theme.typography.body1.fontSize,
            margin: 0,
        },
        singleValue: {
            fontSize: theme.typography.body1.fontSize,
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            paddingBottom: theme.spacing(0.5),
            minHeight: 40,
        },
        divider: {
            height: 16,
        },
        menu: {
            position: 'absolute',
            zIndex: 2,
            left: 0,
            right: 0,
        },
        outlined: {
            '&.placeholder': {
                left: 12,
            },
            '&.valueContainer': {
                paddingTop: theme.spacing(1.5),
                paddingLeft: theme.spacing(1.5),
            },
        },
        filled: {
            '&.placeholder': {
                left: theme.spacing(1.5),
            },
            '&.valueContainer': {
                paddingTop: theme.spacing(3),
                paddingLeft: theme.spacing(1.5),
            },
            '&.chip': {
                border: `1px solid ${theme.palette.secondary[300]}`,
            },
        },
        standard: {
            '&.valueContainer': {
                paddingTop: theme.spacing(0.5),
            },
        },
        chipFocused: {
            '&&': {
                backgroundColor: theme.palette.secondary[300],
            },
        },
        requiredMark: {
            position: 'absolute',
            left: -8,
            backgroundColor: theme.palette.primary.main,
            width: 6,
            height: 'fill-available',
            borderRadius: 3,
        },
        isRequired: {
            backgroundColor: theme.palette.primary[30],
        },
    }
})

export default useStyles
