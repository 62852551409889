import {
    App,
} from 'app/types/common.enums'

type ContainerSelectionRequests = {
    name: string,
    preconditioningFeasibility: string,
    customerTransports: string,
    internalTransports: string,
    moveToUrl: {
        preconditioningFeasibility: ({
            orderNumber: string,
        }) => string,
        customerTransports: ({
            orderNumber: string,
        }) => string,
        internalTransports: ({
            orderNumber: string,
        }) => string,
    }
}

const containerSelectionRequests: ContainerSelectionRequests = {
    name: 'ContainerSelectionView',
    preconditioningFeasibility: 'order/filter-request/preconditioning-feasibility',
    customerTransports: 'order/filter-request/container-selection-customer-transports',
    internalTransports: 'order/filter-request/container-selection-internal-transports',
    moveToUrl: {
        preconditioningFeasibility: (orderNumber) => {
            return `/apps/${App.ORDER_MANAGEMENT}/customer-orders/all/edit/${orderNumber}/container-selection`
        },
        customerTransports: (orderNumber) => {
            return `/apps/${App.ORDER_MANAGEMENT}/customer-orders/all/edit/${orderNumber}/container-selection`
        },
        internalTransports: (orderNumber) => {
            return `/apps/${App.ORDER_MANAGEMENT}/internal-orders/all/edit/${orderNumber}/container-selection`
        },
    },
}

export default containerSelectionRequests
