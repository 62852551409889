import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import idRemover from 'app/utils/idRemover'

import {
    PreBookingInfoContext,
} from '../components/PreBookingInfoContext/PreBookingInfoContext'
import {
    PreBookingEntity,
} from '../PreBooking.types'
import PreBookingAvailableLanes from '../components/PreBookingAvailableLanes'
import PreBookingLaneDetails from '../components/PreBookingLaneDetails'
import ReasonForChangeForm from '../components/ReasonForChangeForm'

const PreBookingUpdate = ({
    data,
    onSuccess,
}: {
    data: PreBookingEntity,
    onSuccess: () => void,
}) => {
    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()
    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)
    const [
        value,
        setValue,
    ] = useState<PreBookingEntity>(data as PreBookingEntity)

    const saveDetailValues = useCallback((values: PreBookingEntity) => {
        const filteredValues = {
            ...values,
            requiredContainers: values.requiredContainers && idRemover(values.requiredContainers),
        }

        setValue(filteredValues)
        setActiveStep(2)
    }, [])

    useBeforeUnloadDialog(isEdited)

    const stepperSteps = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Available Lanes',
                expanded: true,
                content: <PreBookingAvailableLanes
                    disabled={activeStep !== 0}
                />,
            },
            {
                key: '2',
                label: 'Lane Details',
                expanded: (step) => {
                    return step > 0
                },
                content: <PreBookingLaneDetails
                    disabled={activeStep !== 1}
                    nextLabel="Continue"
                    nextAction={saveDetailValues}
                />,
            },
            {
                key: '3',
                label: 'Select Reason For Change',
                expanded: (step) => {
                    return step > 1
                },
                content: <ReasonForChangeForm
                    disabled={activeStep !== 2}
                    nextAction={onSuccess}
                />,
            },
        ]
    }, [
        activeStep,
        saveDetailValues,
        onSuccess,
    ])

    const preBookingInformationContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            setValue,
            setIsEdited,
            initialValue: value,
        }
    }, [
        activeStep,
        setActiveStep,
        value,
        setValue,
    ])

    return (
        <PreBookingInfoContext.Provider value={preBookingInformationContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </PreBookingInfoContext.Provider>
    )
}

export default PreBookingUpdate
