import React, {
    useCallback,
    useEffect,
} from 'react'
import ChipSelect from 'app/shared-components/ChipSelect'

import {
    Validate,
} from 'app/types/form.types'
import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'
import {
    valueConverterToArray,
} from './utils/converters'
import getValueLabelByEmail from './utils/getValueLabelByEmail'
import useCommonMultiContactSelectorLogic from './hooks/useCommonMultiContactSelectorLogic'
import useValidateItem from '../../hooks/useValidateItem'

type Props = {
    value?: number[],
    name?: string,
    onChange: (newValue: number[]) => void,
    title?: string,
    disabled?: boolean,
    required?: boolean,
    validate?: Validate<Contact>
    setErrors?: (e: [string, any[]]) => void,
}

const defaultProps: Partial<Props> = {
    value: [],
    name: undefined,
    title: 'Contacts',
    disabled: false,
    required: false,
    validate: undefined,
    setErrors: undefined,
}

const MultipleContactSelector = ({
    name,
    value,
    onChange,
    title,
    disabled,
    required,
    validate,
    setErrors,
}: Props) => {
    const {
        contacts,
        setContacts,
        loadOptions,
        contactsByIds,
    } = useCommonMultiContactSelectorLogic({
        valueConverter: (listId: number[]) => {
            return listId
        },
        listIds: value,
    })

    useEffect(() => {
        if (contactsByIds && JSON.stringify(contactsByIds) !== JSON.stringify(contacts)) {
            setContacts(contactsByIds)
        }
    }, [
        contacts,
        contactsByIds,
        setContacts,
    ])

    const onChangeValue = useCallback((newValue: {id: number}[], actionParams): void => {
        let updatedValues

        if (actionParams?.action === 'remove-value') {
            updatedValues = contacts.filter(({
                id,
            }) => { return id !== actionParams?.removedValue?.id })
        } else {
            updatedValues = newValue
        }

        onChange(updatedValues ? valueConverterToArray(updatedValues) : [])
    }, [
        contacts,
        onChange,
    ])

    const errors = useValidateItem({
        validate, value, setFormError: setErrors, key: name,
    })

    return (
        <ChipSelect
            title={title}
            value={contacts}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            getValueLabel={getValueLabelByEmail}
            showTitleOptions
            disabled={disabled}
            required={required}
            errors={errors}
            validated={Boolean(validate)}
        />
    )
}

MultipleContactSelector.defaultProps = defaultProps

export default MultipleContactSelector
