import {
    makeStyles,
} from 'app/tss'

const summary = 'summary'

const useStyles = makeStyles()(() => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        gridWrapper: {
            width: '50%',
        },
        summary: {
            gridArea: summary,
        },
    }
})

export default useStyles
