/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import InputSingleline from 'app/shared-components/InputSingleline'

type Props = {
    title?: string,
    textType?: string,
    name?: string,
    onChange: (...args: any[]) => void,
    value?: string | number,
    className?: string,
    min?: number,
}

const defaultProps = {
    title: undefined,
    textType: undefined,
    name: undefined,
    value: undefined,
    className: undefined,
    min: undefined,
}

const IntegerInput = (props: Props) => {
    return (
        <InputSingleline
            {...props}
            type="number"
        />
    )
}

IntegerInput.defaultProps = defaultProps

export default IntegerInput
