import {
    makeStyles,
} from 'app/tss'

const createContainer = 'createContainer'
const additionalInformation = 'additionalInformation'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${createContainer} ${createContainer} ${createContainer} ${additionalInformation}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        createContainer: {
            gridArea: createContainer,
        },
        additionalInformation: {
            gridArea: additionalInformation,
        },
        infoMsg: {
            display: 'flex',
            background: theme.palette.primary[30],
            color: theme.palette.primary.main,
            alignItems: 'center',
            borderRadius: 10,
            padding: theme.spacing(0.5, 1),
            marginBottom: theme.spacing(1.5),
        },
        infoText: {
            paddingLeft: theme.spacing(0.5),
        },
    }
})

export default useStyles
