import React from 'react'
import {
    Card as MaterialCard, CardContent, Typography, Icon,
} from '@mui/material'
import PropTypes from 'prop-types'

import {
    PENDING,
} from 'app/utils/requestStatuses'
import Loading from 'app/shared-components/Loading'

import useStyles from './Card.style'

const propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    contentClass: PropTypes.string,
    titleClass: PropTypes.string,
    fullHeight: PropTypes.bool,
    status: PropTypes.string,
    'data-testid': PropTypes.string,
}

const defaultProps = {
    className: '',
    contentClass: '',
    titleClass: '',
    title: '',
    icon: '',
    fullHeight: false,
    status: '',
    'data-testid': 'card',
}

const Card = (props) => {
    const {
        classes, cx,
    } = useStyles()
    const {
        children,
        title,
        icon,
        className,
        contentClass,
        titleClass,
        fullHeight,
        status,
        'data-testid': dataTestid,
    } = props

    return (
        <MaterialCard
            className={cx(classes.card, {
                [classes.fullHeight]: fullHeight,
            }, className)}
            data-testid={dataTestid}
        >
            <CardContent className={contentClass}>
                {title
                    && (
                        <Typography className={cx(classes.title, titleClass)}>
                            {title}
                            {icon
                                && (
                                    <Icon className={classes.icon}>{icon}</Icon>
                                )}
                            {status === PENDING
                                && (
                                    <div className={classes.loading}>
                                        <Loading
                                            size={15}
                                            color="inherit"
                                        />
                                    </div>
                                )}
                        </Typography>
                    )}
                {children}
            </CardContent>
        </MaterialCard>
    )
}

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card
