import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import AlLeasePricingForm from '../AlLeasePricingForm'
import AlLeasePricingRequests from '../AlLeasePricing.requests'

import {
    AlLeasePricing,
} from '../AlLeasePricing.types'

const AlLeasePricingCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((newAlLeasePricing: AlLeasePricing) => {
        addTableElement(newAlLeasePricing)
        selectDomainObject(newAlLeasePricing?.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <AlLeasePricingForm
            onSuccess={onSuccess}
            requestParam={AlLeasePricingRequests.create()}
        />
    )
}

export default AlLeasePricingCreate
