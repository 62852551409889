export enum AttitudeToSkyCell {
    DISLIKES_US = 'DISLIKES_US',
    DOES_NOT_KNOW_US = 'DOES_NOT_KNOW_US',
    KNOWS_US = 'KNOWS_US',
    LIKES_US = 'LIKES_US',
    UNKNOWN = 'UNKNOWN'
}

export enum LeadSource {
    EVENTS = 'EVENTS',
    WEBINARS = 'WEBINARS',
    REFERRALS = 'REFERRALS',
    DIRECT_SALES_OUTREACH = 'DIRECT_SALES_OUTREACH',
    PARTNERSHIPS = 'PARTNERSHIPS',
    ORGANIC = 'ORGANIC',
    PAID = 'PAID',
    DIRECT = 'DIRECT',
    ZOHO = 'ZOHO',
}

export enum Department {
    ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
    ADMINISTRATIVE = 'ADMINISTRATIVE',
    BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT',
    CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
    FINANCE = 'FINANCE',
    LOGISTICS_AND_DISTRIBUTION = 'LOGISTICS_AND_DISTRIBUTION',
    MARKETING = 'MARKETING',
    OPERATIONS = 'OPERATIONS',
    QUALITY_ASSURANCE = 'QUALITY_ASSURANCE',
    REGULATORY_AFFAIRS = 'REGULATORY_AFFAIRS',
    RESEARCH_AND_DEVELOPMENT = 'RESEARCH_AND_DEVELOPMENT',
    SUPPLY = 'SUPPLY',
    SUSTAINABILITY = 'SUSTAINABILITY',
}

export enum DecisionMakingUnit {
    DECISION_MAKER = 'DECISION_MAKER',
    GATEKEEPER = 'GATEKEEPER',
    INFLUENCER = 'INFLUENCER',
    TEAM_MEMBER = 'TEAM_MEMBER',
    UNKNOWN = 'UNKNOWN',
}

export type EngagementTableType = {
    id: number,
    changedOn: string,
    contactName: string,
    contactCompanyName: string,
    contactCountryName: string,
    attitudeToSkyCell: AttitudeToSkyCell,
}

export type EngagementType = {
    id: number,
    leadSource: LeadSource,
    contact: {
        id: number,
    },
    department: Department,
    reportingTo: {
        id: number,
    },
    decisionMakingUnit: DecisionMakingUnit,
    attitudeToSkyCell: AttitudeToSkyCell,
    newsletterUnsubscribed: boolean,
    gdprConsent: boolean,
    interestInSkyMind: boolean,
    interestIn1500X: boolean,
    archived: boolean,
}
