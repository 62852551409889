import React, {
    useMemo,
} from 'react'
import Radio from 'app/shared-components/Radio'

import {
    InvoiceDesignation,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import InvoiceCardContainer from '../InvoiceCardContainer'
import useStyles from './NewInvoiceCard.styles'

type Props = {
    selected?: boolean,
    invoiceDesignation?: string,
    onInvoiceDesignationChange: (newValue: InvoiceDesignation) => void,
    handleClick: () => void,
}

const defaultProps = {
    selected: false,
    invoiceDesignation: null,
}

const NewInvoiceCard = ({
    handleClick,
    selected,
    invoiceDesignation,
    onInvoiceDesignationChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValues = useEnumValues()(InvoiceDesignation)
    const radioOptions: {
        label: string,
        value: string,
    }[] = useMemo(() => {
        return Object.entries(allowedValues)
            .map(([
                key,
                value,
            ]) => {
                return {
                    label: value, value: key,
                }
            }).sort((a, b) => {
                return ((a.label > b.label) ? 1 : -1)
            })
    }, [allowedValues])

    return (
        <InvoiceCardContainer
            selected={selected}
            handleClick={handleClick}
            title="New Invoice"
        >
            {!selected
                && (
                    <div className={classes.newContent}>
                        <div
                            className={classes.createText}
                            data-testid="finance-invoices-create-invoice"
                        >
                            + Click to Create
                        </div>
                    </div>
                )}
            {(selected && !invoiceDesignation)
                && (
                    <div className={classes.newContent}>
                        <div
                            className={classes.radioContainer}
                            data-testid="new-invoice-radio"
                        >
                            <Radio
                                value={invoiceDesignation}
                                options={radioOptions}
                                compact
                                onChange={onInvoiceDesignationChange}
                                className={classes.radio}
                            />
                        </div>
                    </div>
                )}
            {(selected && invoiceDesignation)
                && (
                    <div className={classes.newContent}>
                        <div
                            className={classes.createText}
                        >
                            Creating invoice...
                        </div>
                    </div>
                )}
        </InvoiceCardContainer>

    )
}

NewInvoiceCard.defaultProps = defaultProps

export default NewInvoiceCard
