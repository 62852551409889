export default (
    enumObj: Record<string, string>,
    valuesToReduce: string[] = [],
): Record<string, string> => {
    return Object.entries(enumObj).reduce((acc, [
        key,
        value,
    ]) => {
        return valuesToReduce.includes(key)
            ? acc
            : {
                ...acc,
                [key]: value,
            }
    }, {})
}
