import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    SimulationForm,
} from './simulation.types'

const fields: DTOCardFormField<keyof SimulationForm>[] = [
    {
        title: '1 | Select Container Model',
        className: 'blockItem',
        fields: [{
            name: 'selectedContainer',
            componentName: 'ContainerTypeSelection',
        }],
    },
    {
        title: '2 | Select Route',
        className: 'blockItem',
        fields: [{
            name: 'routeSelection',
            componentName: 'RouteSelection',
        }],
    },
    {
        title: '3 | Add Route Steps',
        className: 'blockItem',
        fields: [{
            name: 'simulationSteps',
            componentName: 'RouteSteps',
        }],
    },
    {
        title: '4 | Analyse Temperature',
        className: 'blockItem',
        fields: [{
            name: 'analyseTemperature',
            componentName: 'AnalyseTemperature',
        }],
    },
]

export default fields
