import {
    UnassignedToOpportunity,
    UnassignedToOpportunityError,
} from '../../KamDashboard.types'

export const SET_VALUE = 'SET_VALUE'
export const SET_ERROR = 'SET_ERROR'
export const SET_ASSIGNED_LANES = 'SET_ASSIGNED_LANES'
export const SET_OPEN = 'SET_OPEN'
export const SET_DEFAULT_LANE = 'SET_DEFAULT_LANE'

export function setValue(newValue: number[]) {
    return {
        type: SET_VALUE,
        newValue,
    }
}

export function setError(error: UnassignedToOpportunityError) {
    return {
        type: SET_ERROR,
        error,
    }
}

export function setAssignedLanes(assignedLanes: UnassignedToOpportunity[]) {
    return {
        type: SET_ASSIGNED_LANES,
        assignedLanes,
    }
}

export function setOpen(open: boolean) {
    return {
        type: SET_OPEN,
        open,
    }
}

export function setDefaultLane(defaultLane: UnassignedToOpportunity) {
    return {
        type: SET_DEFAULT_LANE,
        defaultLane,
    }
}
