import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {
            position: 'relative',
        },
        cardContent: {
            paddingRight: 0,
            paddingLeft: 0,
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'static',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        icon: {
            cursor: 'pointer',
        },
        iconContainer: {
            position: 'absolute',
            zIndex: 1,
            right: 20,
            top: 20,
        },
    }
})

export default useStyles
