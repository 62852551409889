import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import getReadingThresholdType, {
    READING_THRESHOLD_TYPE,
} from 'app/utils/getReadingThresholdType'
import useStyles from './Temperature.styles'

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    lastMeasuredOnUtc: PropTypes.number.isRequired,
}

const defaultProps = {
    value: null,
}

const Temperature = (props) => {
    const {
        value,
        lastMeasuredOnUtc,
    } = props

    const {
        classes,
    } = useStyles()

    const className = useMemo(() => {
        const reading = getReadingThresholdType(lastMeasuredOnUtc)

        return clsx({
            [classes.oldReading]: reading === READING_THRESHOLD_TYPE.OLD,
            [classes.recentReading]: reading === READING_THRESHOLD_TYPE.RECENT,
            [classes.liveReading]: reading === READING_THRESHOLD_TYPE.LIVE,
        })
    }, [
        lastMeasuredOnUtc,
        classes,
    ])

    return (
        <div className={className}>
            {value}
        </div>
    )
}

Temperature.propTypes = propTypes
Temperature.defaultProps = defaultProps

export default Temperature
