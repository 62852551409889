import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        containerWrapper: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
        },
        container: {
            height: 'inherit',
            alignContent: 'flex-start',
        },
    }
})

export default useStyles
