import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    AdditionalFeeSelectorType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import config from '../../invoce.request'

const useLoadLabelAdditionalFee = (id) => {
    const {
        data,
        isDataReady,
        isError,
        error,
    } = useRequest<AdditionalFeeSelectorType>({
        key: RequestKeys.getAdditionalFeeSelector,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.GetAdditionalFeeById.requestFn),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isError,
        error,
    }
}

export default useLoadLabelAdditionalFee
