/* eslint-disable camelcase */
import React from 'react'
import clsx from 'clsx'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {
    noop,
} from 'lodash'

import {
    DataSuggestion,
} from './GoogleAddress.types'

import useStyles from './GoogleAddress.style'

const defaultProps = {
    addressOptions: [],
    formValue: {},
    value: '',
    isGoogleServiceReady: false,
    setAutocompleteGoogle: noop,
    setPlaceId: noop,
    setValue: noop,
    setAutocomplete: noop,
    required: false,
    title: '',
    placeId: '',
    fieldLabel: 'Google Address Autocomplete',
    name: '',
    disabled: undefined,
}

const GoogleAddress = ({
    value,
    addressOptions = [],
    isGoogleServiceReady,
    setAutocompleteGoogle,
    setPlaceId,
    setValue,
    required,
    placeId,
    setAutocomplete,
    formValue,
    fieldLabel,
    name,
    disabled,
}: {
    addressOptions?: DataSuggestion[],
    formValue?: DataSuggestion,
    value?: string,
    isGoogleServiceReady?: boolean,
    setAutocompleteGoogle?: (...args: any[]) => void,
    setPlaceId?: (...args: any[]) => void,
    setValue?: (...args: any[]) => void,
    required?: boolean,
    title?: string,
    placeId?: string,
    setAutocomplete?: (...args: any[]) => void,
    fieldLabel?: string,
    name?: string,
    disabled?: boolean,
}) => {
    const {
        classes,
    } = useStyles()

    const handleAutocompleteGoogle = (event) => {
        setAutocompleteGoogle(event.target.value)
        if (isGoogleServiceReady) {
            setValue(event.target.value)
        }
    }

    const handlePlaceId = (event, selected) => {
        if (placeId !== selected?.place_id) {
            setPlaceId(selected?.place_id || '')
        }
    }

    const handleChange = (event, newValue) => {
        setAutocomplete(newValue, true)
    }

    const handleKeyDown = (event) => {
        setAutocomplete(event.target.value, true)

        if (event.key === 'Tab') {
            if (addressOptions.length > 0) {
                const [firstItem] = addressOptions

                const {
                    description = '',
                    id = null,
                    place_id = '',
                    structured_formatting = {},
                } = firstItem

                const option = {
                    description,
                    id,
                    place_id,
                    structured_formatting,
                } as DataSuggestion

                setPlaceId(option?.place_id || '')
                setAutocomplete(option?.structured_formatting?.main_text || option?.description || '', true)
            }
        }
    }

    return (
        <div>
            <div>
                <div className={classes.fieldTitle}>{fieldLabel}</div>
                {required && !disabled && <div className={classes.requiredMark} />}
                <Autocomplete
                    onChange={handlePlaceId}
                    onKeyDown={handleKeyDown}
                    onInputChange={handleChange}
                    classes={{
                        inputRoot: required && !disabled
                            ? clsx(classes.textFieldText, classes.isRequired)
                            : classes.textFieldText,
                    }}
                    freeSolo
                    size="small"
                    id="googleAddress"
                    data-testid="inputSingleLine-googleAddress"
                    options={addressOptions}
                    getOptionLabel={(option) => { return option.description }}
                    isOptionEqualToValue={(option, optionValue) => {
                        return option.description === optionValue.description
                    }}
                    filterSelectedOptions
                    value={formValue}
                    fullWidth
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params} //eslint-disable-line
                                onChange={handleAutocompleteGoogle}
                                variant="standard"
                                size="small"
                                value={value}
                                name={name}
                            />
                        )
                    }}
                    disabled={disabled}
                />

            </div>
        </div>
    )
}

GoogleAddress.defaultProps = defaultProps

export default React.memo(GoogleAddress)
