import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import request from 'app/Apps/AccountManagement/Opportunities/opportunities.request'
import {
    OpportunityTableType,
} from '../../Opportunities/opportunities.types'

const useGetOpportunitiesByQuote = (quoteId: number, update: boolean = false) => {
    const defaultValue = update ? {
        data: {
            filters: {
                quoteId,
                includeApplicableForQuote: true,
            },
        },
    } : {
        data: {
            filters: {
                quoteId,
            },
        },
        sort: {
            field: 'opportunityNumber',
            direction: 'asc',
        },
    }

    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<{data: OpportunityTableType[]}>({
        key: RequestKeys.getOpportunitiesByQuote,
        params: {},
        defaultValue,
        config: request.FilterRequest,
        keepPreviousData: true,
        enabled: Boolean(quoteId),
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data: data?.data,
        loadData,
    }
}

export default useGetOpportunitiesByQuote
