import {
    BookedContainer,
} from '../bookedContainers.types'

const getSelectedContainersToRebook = (data: BookedContainer[]): {id: number}[] => {
    return data.filter(({
        selected,
        isRejected,
    }) => {
        return selected && isRejected
    })
        .map(({
            id,
        }: BookedContainer) => {
            return {
                id,
            }
        })
}

export default getSelectedContainersToRebook
