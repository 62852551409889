import {
    NestedField,
} from 'app/types/form.types'
import {
    Timezone,
    RegionFirstLevel,
} from 'app/types/enums'

import {
    LocationFormFields,
} from '../LocationForm.types'

export const getAirportFieldDetails = ({
    timeZones, country, getAllowedValues,
}): NestedField<LocationFormFields>[] => {
    return [
        {
            name: 'iataCode',
            required: true,
            componentName: 'InputSingleline',
            labelKey: 'Location.iataCode',
        },
        {
            componentName: 'CitySuggestions',
            name: 'city',
            timeZones,
            required: true,
            labelKey: 'Location.city',
        },
        {
            name: 'country',
            componentName: 'CountryDataSelector',
            labelKey: 'Location.country',
            value: country,
        },
        {
            name: 'state',
            country: country?.id,
            labelKey: 'Location.state',
            componentName: 'StateSelector',
        },
        {
            name: 'timeZone',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(Timezone),
            required: true,
            labelKey: 'Location.timeZone',
        },
        {
            name: 'zip',
            componentName: 'InputSingleline',
            labelKey: 'BillingCompanyDto.zip',
        },
        {
            name: 'region',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(RegionFirstLevel),
            labelKey: 'Location.region',
            required: true,
        },
    ]
}
export default getAirportFieldDetails
