import {
    useMemo,
} from 'react'
import flow from 'lodash/flow'

import {
    useArchivedTableControl,
} from 'app/shared-components/TableControls/ShowArchived'
import injectOnLoad from 'app/Apps/DomainObject/utils/injectOnLoad'
import injectSearchQuery from 'app/Apps/DomainObject/utils/injectSearchQuery'
import injectSort from 'app/Apps/DomainObject/utils/injectSort'
import injectParseResponse from 'app/Apps/DomainObject/utils/injectParseResponse'
import useNextUrlParam from 'app/hooks/useNextUrlParam'
import {
    TableResponse,
} from 'app/types/request.types'
import injectDefaultFilters from 'app/Apps/DomainObject/utils/injectDefaultFilters'

import {
    SkyNetTableTabWithLabel,
} from '../types/skyNetTable.types'
import useLoadDtoForSkyNetTable from './useLoadDtoForSkyNetTable'
import injectUrlQuery from './utils/injectUrlQuery'
import injectArchived from './utils/injectArchived'

type Params = {
    customUrl?: string,
    filter?: Record<string, any>,
    method?: string,
    name: string,
    moduleName?: string,
    onLoadData: (data: TableResponse)=> void,
    rowsPerPageDefault?: number,
    tabs: SkyNetTableTabWithLabel[],
    searchValue: string,
    paginationModeMatches?: boolean,
    parseResponse: (data: TableResponse)=> TableResponse,
}

const useLoadWithParams = ({
    customUrl,
    filter,
    method,
    name,
    moduleName,
    onLoadData,
    rowsPerPageDefault,
    tabs,
    searchValue,
    paginationModeMatches,
    parseResponse,
}: Params) => {
    const showArchived = useArchivedTableControl(name)

    const [openedTabUrl] = useNextUrlParam()
    const {
        url: urlQuery,
        includeFilters,
        filters,
    } = useMemo((): SkyNetTableTabWithLabel => {
        const selected = tabs.find(({
            url: tabUrl,
        }) => {
            return tabUrl === openedTabUrl
        })

        if (selected?.requestUrl) {
            return {
                ...selected,
                url: selected.requestUrl,
            }
        }
        return selected || {
            url: undefined, name: undefined, label: undefined,
        }
    }, [
        openedTabUrl,
        tabs,
    ])

    const appliedFilter = useMemo((): Record<string, any> => {
        return {
            ...includeFilters,
            ...filter,
        }
    }, [
        includeFilters,
        filter,
    ])

    const load = useLoadDtoForSkyNetTable({
        name: moduleName || name,
        version: undefined,
        rowsPerPage: rowsPerPageDefault,
        customUrl,
        method,
        paginationModeMatches,
    })

    const loadWithParams = useMemo((): (params: any)=>any => {
        return flow([
            injectSort,
            injectDefaultFilters(filters),
            injectUrlQuery(urlQuery),
            injectSearchQuery(searchValue),
            injectArchived(showArchived),
            injectOnLoad(onLoadData),
            injectParseResponse(parseResponse),
        ])(load)
    }, [
        filters,
        urlQuery,
        searchValue,
        showArchived,
        onLoadData,
        parseResponse,
        load,
    ])

    return {
        loadWithParams,
        appliedFilter,
        selectedTabUrlQuery: urlQuery,
    }
}

export default useLoadWithParams
