import React, {
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useLabel from 'app/hooks/useLabel'
import InputSingleline from 'app/shared-components/InputSingleline'
import {
    defaultTaxCodes,
    defaultEmptyTaxCodes,
} from 'app/config'

import useStyles from './TaxCodesSelector.styles'

const propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.any).isRequired,
    disabled: PropTypes.bool,
    isNew: PropTypes.bool,
    className: PropTypes.string,
}

const defaultProps = {
    disabled: false,
    className: '',
    isNew: false,
}

const TaxCodesSelector = (props) => {
    const {
        onChange,
        value,
        className,
        disabled,
        isNew,
    } = props
    const l = useLabel()

    const {
        classes,
    } = useStyles()

    const codes = useMemo(() => {
        if (!value || value.length === 0) {
            return isNew ? defaultTaxCodes : defaultEmptyTaxCodes
        }
        return value
    }, [
        value,
        isNew,
    ])

    const handleTaxCodeChange = useCallback((name) => {
        return (taxCode) => {
            const taxCodes = codes.map((item) => {
                if (item.currency === name) {
                    return {
                        ...item,
                        taxCode,
                    }
                }

                return item
            })

            onChange(taxCodes)
        }
    }, [
        onChange,
        codes,
    ])

    return (
        <div className={clsx(classes.root, className)}>
            {codes.map((taxCode) => {
                return (
                    <InputSingleline
                        key={taxCode.currency}
                        disabled={disabled}
                        title={`${l('LocationDefaultTaxCode.taxCode')} ${taxCode.currency}`}
                        value={taxCode.taxCode}
                        onChange={handleTaxCodeChange(taxCode.currency)}
                        textType="editable"
                        required
                    />
                )
            })}
        </div>
    )
}

TaxCodesSelector.propTypes = propTypes
TaxCodesSelector.defaultProps = defaultProps

export default TaxCodesSelector
