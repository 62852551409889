import React from 'react'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import {
    IconButton,
    FormControl, Typography,
} from '@mui/material'

import Label from 'app/shared-components/Label'

import useStyles from './FileDownloadLink.styles'
import StatusHandler from '../StatusHandler'
import useGetAttachment from './hooks/useGetAttachment'

type Props = {
    title: string,
    value: {
        id: number
    }
}

const FileDownloadLink = ({
    title,
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        REACT_APP_ATTACHMENTS_STORAGE: blobStorageUrl,
    } = process.env

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetAttachment(value?.id)

    return (
        <FormControl className={classes.formControl}>
            <Label title={title} />
            { value?.id ? (
                <StatusHandler
                    isSuccess={isDataReady}
                    isFetching={isFetching}
                    isError={isError}
                    error={error}
                >
                    <a
                        className={classes.link}
                        href={isDataReady ? `${blobStorageUrl}/${data?.fileId}/${data?.filename}` : null}
                        rel="noopener noreferrer"
                        target="_blank"
                        download={data?.filename}
                        data-testid={`attachmentLink-${data?.filename}`}
                    >
                        <span className={classes.container}>
                            <Typography
                                variant="h5"
                            >
                                {data?.filename}
                            </Typography>

                            <IconButton
                                size="small"
                            >
                                <InsertDriveFileIcon />
                            </IconButton>
                        </span>
                    </a>
                </StatusHandler>
            ) : (<span className={classes.noFile}>No file attached</span>) }
        </FormControl>

    )
}

export default FileDownloadLink
