import {
    ProductType,
    OrderTemperatureRange,
} from 'app/types/enums'

export enum MilestoneSource {
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_SKYCELL = 'CUSTOMER_SKYCELL',
    SKYCELL = 'SKYCELL'
}

export enum SimulationStepType {
    AIRPORT = 'AIRPORT',
    CUSTOMS = 'CUSTOMS',
    FERRY = 'FERRY',
    FLIGHT = 'FLIGHT',
    LOAD_PALLET = 'LOAD_PALLET',
    RAMP = 'RAMP',
    RE_ICING = 'RE_ICING',
    TRUCK = 'TRUCK',
    WAREHOUSE = 'WAREHOUSE',
}

export enum SimulationProfile {
    SUMMER = 'SUMMER',
    WINTER = 'WINTER',
}

export type SelectedContainer = {
    amountCoolingGel?: number,
    productType: ProductType,
    tempRange: OrderTemperatureRange,
}

export type SimulationStepRequest = {
    ambientTemperature?: number,
    durationHours?: number,
    durationMinutes?: number,
    type?: SimulationStepType,
    amountCoolingGel?: number,
    palletTemperature?: number,
    palletWeight?: number,
    location?: string,
    palletLoaded?: boolean,
}

export type SimulationStep = SimulationStepRequest & {
    id: number,
}

export type RouteSelectionType = {
    routeOriginAirport: {id: number},
    routeViaAirports: {id: number}[],
    routeDestinationAirport: {id: number},
}

export type SimulationData = {
    id: number,
    createdBy: string,
    createdOn: string,
    simulationNumber: string,
    simulationName: string,
    routeComment: string,
    simulationProfile: string,
    showEnergyLevel: boolean,
    simulationFinalized: boolean,
    showTemperatureRange: boolean,
    showStepType: boolean,
    showLocation: boolean,
    useCustomTempRange: boolean,
    milestoneSource: MilestoneSource,
    customLowerTempRange: number,
    customUpperTempRange: number,
    preconditionTemperature: number,
    selectedContainer: SelectedContainer,
    simulationSteps: SimulationStep[],
    routeOriginAirport: {id: number},
    routeViaAirports: {id: number}[],
    routeDestinationAirport: {id: number},
}

export type AnalyseTemperatureType = {
    simulationName: string,
    milestoneSource: MilestoneSource,
    routeComment: string,
    showEnergyLevel: boolean,
    showStepType: boolean,
    showLocation: boolean,
    useCustomTempRange: boolean,
    customLowerTempRange: number,
    customUpperTempRange: number,
    showTemperatureRange: boolean,
    simulationProfile: string,
    simulationFinalized: boolean,
    preconditionTemperature: number,
}

export type SimulationForm = {
    selectedContainer: SelectedContainer,
    routeSelection: RouteSelectionType,
    simulationSteps: SimulationStep[],
    analyseTemperature: AnalyseTemperatureType,
    simulationNumber: string,
    createdBy: string,
    createdOn: string,
    id: number,
}

export type SimulationFields = keyof SimulationData

export type ProductTypeSimulation = {
    productType: ProductType,
    tempRange: OrderTemperatureRange,
    reicingEnabled: boolean,
}

export type ContainerTypeSelectionItemType = {
    productType: ProductType,
    title: string,
    imgSrc: string,
    tempRange: OrderTemperatureRange,
    reicingEnabled: boolean,
    amountCoolingGel: number,
}

export type SimulationTempResult = {
    isExcursion: boolean,
    maxTemperature: number,
    minTemperature: number,
    remainingEnergyLevel: number,
    simulatedTemperature: number,
    time: number,
    timestamp: string,
}

export type SimulationDataChart = {
    internalTemperature: number[],
    remainingEnergyLevel: number[],
    min: number[],
    max: number[],
    time: number[],
}

export type StepDataChart = {
    points: {
        x: number,
        y: number,
        location: string,
        type: string,
    }[],
    ambientTemp: number[],
    sumTime: number,
}
