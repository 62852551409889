/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'

type SelectorProps = Record<string, any> & {
    value: string | number,
    onChange: (value: any, ...params: any[]) => void,
}

type Props = Record<string, any> & {
    value?:{
        id: string | number,
    },
    onChange: (value: {id: any}, ...params: any[]) => void,
    selector: (props: SelectorProps)=> JSX.Element,
}

const defaultProps = {
    value: undefined,
}

const SingleObjectSelectorConverter = (props: Props) => {
    const {
        value,
        onChange,
        selector: Component,
    } = props
    const localOnChange = useCallback((newValue, event?: {
        target: {
            name: string,
            value: any,
        }
    }) => {
        const coverterValue = newValue ? {
            id: newValue,
        } : undefined

        const updatedEvent = event?.target && {
            target: {
                name: event.target.name,
                value: coverterValue,
            },
        }

        onChange(coverterValue, updatedEvent)
    }, [onChange])

    return (
        <Component
            {...props}
            value={value?.id}
            onChange={localOnChange}
        />
    )
}

SingleObjectSelectorConverter.defaultProps = defaultProps

export default SingleObjectSelectorConverter
