import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    ContainerTypes: {
        requestFn: (params) => {
            return {
                url: 'containertype/filter-request/all',
                method: 'POST',
                data: {
                    ...params,
                    rows: 50,
                },
            }
        },
    },
}

export default config
