import React, {
    useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import CreateContainer from './components/CreateContainer'
import PrintLabel from './components/PrintLabel'
import Overview from './components/Overview'
import ChangeData from './components/ChangeData'
import ChangeStatus from './components/ChangeStatus'
import LoggerExchange from './components/LoggerExchange'
import SensorData from './components/SensorData'
import Damages from './components/Damages'
import tableConfig from './Container.config'
import Geolocation from './components/Geolocation'

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: Overview,
}

const sensorDataTab: SidebarTab = {
    label: 'Sensor Data',
    url: 'sensordata',
    Component: SensorData,
}

const DamagesTab: SidebarTab = {
    label: 'Damages',
    url: 'damages',
    Component: Damages,
}

const GeolocationTab: SidebarTab = {
    label: 'Geolocation',
    url: 'geolocation',
    Component: Geolocation,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Containers = () => {
    const containerCreate = useHasPermission(['container_create'])
    const containerUpdate = useHasPermission(['container_update'])

    const tabs = useMemo((): SidebarTab[] => {
        if (containerUpdate) {
            return [
                overviewTab,
                {
                    label: 'Data Update',
                    url: 'changedata',
                    Component: ChangeData,
                },
                {
                    label: 'Status Update',
                    url: 'changestatus',
                    Component: ChangeStatus,
                },
                {
                    label: 'Loggers',
                    url: 'loggers',
                    Component: LoggerExchange,
                },
                sensorDataTab,
                DamagesTab,
                GeolocationTab,
            ]
        }
        return [
            overviewTab,
            sensorDataTab,
            DamagesTab,
            GeolocationTab,
        ]
    }, [containerUpdate])

    return (
        <SkyNetTable
            name="ContainerView"
            moduleName="Container"
            createForm={containerCreate ? CreateContainer : undefined}
            printForm={PrintLabel}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            uniqField="serialNumber"
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Containers
