import {
    FieldInCard,
} from 'app/types/form.types'

import {
    ContainerLocationChangeNote,
} from '../../../ContainerNotes.types'
import {
    AuditTrailConfig,
} from './notesConfigs'

type Fields = keyof ContainerLocationChangeNote

const fields: FieldInCard<Fields>[] = [
    {
        title: 'Change',
        className: 'change',
        fields: [
            {
                name: 'noteSubject',
                componentName: 'InputSingleline',
                labelKey: 'Note.noteSubject',
            },
            {
                name: 'noteText',
                componentName: 'LongText',
                labelKey: 'Note.noteText',
            },
        ],
    },
    {
        title: 'Location Old',
        className: 'locationOld',
        fields: [{
            name: 'locationChange',
            labelKey: 'Note.containerLocationChange',
            componentName: 'LocationChangeSelector',
            inputs: ['old'],
        }],
    },
    {
        title: 'Location New',
        className: 'locationNew',
        fields: [{
            name: 'locationChange',
            labelKey: 'Note.containerLocationChange',
            componentName: 'LocationChangeSelector',
            inputs: ['actual'],
        }],
    },
    AuditTrailConfig,
]

export default fields
