import simpleApi from 'app/services/simpleApi'
import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    LaneFeeSelector: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/billing/lanefees/filter-request/applicable`,
            }
        },
    },
    AdditionalFeeSelector: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/billing/additionalfees/filter-request/applicable`,
            }
        },
    },
    GetLaneFeeById: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `lanefees/${id}`,
            }
        },
    },
    GetAdditionalFeeById: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `additionalfees/${id}`,
            }
        },
    },
    RelinkLaneFee: {
        mutationFn: ({
            orderNumber,
            data,
            token,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/billing/lanefees`,
                data,
                method: 'PATCH',
                token,
            })
        },
    },
    RelinkAdditionalFee: {
        mutationFn: ({
            orderNumber,
            data,
            token,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/billing/additionalfees`,
                data,
                method: 'PATCH',
                token,
            })
        },
    },
    CreateCreditNote: {
        mutationFn: ({
            data,
            token,
        }) => {
            return simpleApi({
                url: 'outgoinginvoice/creditnoteinvoice',
                data,
                method: 'POST',
                token,
            })
        },
    },
    GetPositionsByInvoiceId: {
        requestFn: ({
            invoiceId,
        }) => {
            return {
                url: `outgoinginvoice/${invoiceId}/position/filter-request/all`,
                method: 'POST',
            }
        },
    },
    GetPositionTypeMapping: {
        requestFn: () => {
            return {
                url: 'outgoinginvoice/positiontype/mapping',
                method: 'GET',
            }
        },
    },
    RemoveInvoice: {
        mutationFn: ({
            orderNumber,
            invoice,
            token,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/billing/outgoinginvoice/${invoice.id}`,
                method: 'DELETE',
                token,
            })
        },
    },
}

export default config
