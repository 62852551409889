import React, {
    useCallback,
    useMemo,
} from 'react'
import Card from 'app/shared-components/Card'
import {
    AccountLane,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import SimpleTable, {
    RowComponent,
} from 'app/shared-components/SimpleTable'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Column,
} from 'app/types/simpletable.types'
import useLabel from 'app/hooks/useLabel'
import getColumns, {
    QuoteLane,
} from './AssignPricingLanes.columns'
import useStyles from './AssignPricingLanes.styles'
import useRemoveLaneFromLaneGroup from '../../hooks/useRemoveLaneFromLaneGroup'

const AssignPricingLanes = ({
    lanes,
    onSelect,
    onSuccess,
}: {
    lanes: AccountLane[],
    onSelect: (...args: any[]) => void,
    onSuccess: () => void,
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const getAllowedValues = useEnumValues()

    const {
        mutate,
    } = useRemoveLaneFromLaneGroup(onSuccess)

    const onRemoveFromLG = useCallback((params: {
        laneId: number,
    }): void => {
        mutate(params)
    }, [mutate])

    const columns = useMemo((): Column<keyof QuoteLane>[] => {
        return getColumns({
            classes,
            l,
            handleChange: onSelect,
            getAllowedValues,
            onRemoveFromLG,
        })
    }, [
        classes,
        l,
        onSelect,
        getAllowedValues,
        onRemoveFromLG,
    ])

    return (
        <Card
            title="Lanes"
            className={classes.container}
        >
            <SimpleTable
                name="QuoteOpportunitiesTable"
                columns={columns}
                data={lanes}
                component={RowComponent}
                disabled
                classNames={{
                    tableCellHead: classes.tableCellHead,
                    tableCell: classes.tableCell,
                }}
            />
        </Card>
    )
}

export default AssignPricingLanes
