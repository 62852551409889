import queryString from 'query-string'

import simpleApi from 'app/services/simpleApi'
import {
    RequestConfig,
} from 'app/types/request.types'

import {
    CancellationInfo,
    ModificationReason,
    ModificationRequiredContainersType,
    ModificationType,
    OrderStep,
} from './orders.types'

import {
    UpdateLocationRequestType,
} from './Components/OrderSteps/OrderSteps.types'

type OrderRequests = {
    dataModelName: string,
    moduleName: string,
    OrderByNumber: {
        requestFn: ({
            id,
        }: {id: string}) => RequestConfig,
    },
    CancelOrder: {
        mutationFn: ({
            orderNumber,
            data,
            token,
        }: {
            orderNumber: string,
            data: CancellationInfo,
            token: string,
        }) => Promise<any>,
    },
    ModifyRequiredContainersCT: {
        mutationFn: ({
            modificationReason,
            version,
            orderNumber,
            data,
            token,
        }: {
            modificationReason: ModificationReason,
            version: number,
            orderNumber: string,
            data: Partial<ModificationRequiredContainersType>,
            token: string,
        }) => Promise<any>,
    },
    UpdateOrderStep: {
        mutationFn: ({
            orderNumber,
            token,
            data,
            id,
        }: {
            orderNumber: string,
            token: string,
            data: OrderStep & {version: number},
            id: number,
        }) => Promise<any>,
    },
    ModifyOrderStep: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
            id,
        }: {
            modificationType: ModificationType,
            orderNumber: string,
            token: string,
            data: OrderStep & {version: number},
            id: number,
        }) => Promise<any>,
    },
    UpdateLocationOrderStep: {
        mutationFn: ({
            orderNumber,
            token,
            data,
        }: {
            orderNumber: string,
            token: string,
            data: UpdateLocationRequestType,
        }) => Promise<any>,
    },
}

const config: OrderRequests = {
    dataModelName: 'OrderView',
    moduleName: 'Order',
    OrderByNumber: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `order/${id}`,
                method: 'GET',
            }
        },
    },
    CancelOrder: {
        mutationFn: ({
            orderNumber,
            data = {},
            token,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/cancellation`,
                data,
                method: 'PATCH',
                token,
            })
        },
    },
    ModifyRequiredContainersCT: {
        mutationFn: ({
            modificationReason,
            orderNumber,
            version,
            data,
            token,
        }) => {
            return simpleApi({
                url: queryString.stringifyUrl({
                    url: `order/${orderNumber}/required-containers-customer`,
                    query: modificationReason && {
                        modificationType: modificationReason,
                    },
                }),
                data: {
                    ...data,
                    version,
                },
                method: 'PATCH',
                token,
            })
        },
    },
    UpdateOrderStep: {
        mutationFn: ({
            orderNumber,
            token,
            data,
            id,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/order-step/${id}`,
                data,
                method: 'PUT',
                token,
            })
        },
    },
    ModifyOrderStep: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
            id,
        }) => {
            return simpleApi({
                url: queryString.stringifyUrl({
                    url: `order/${orderNumber}/order-step/${id}`,
                    query: {
                        modificationType,
                    },
                }),
                data,
                method: 'PUT',
                token,
            })
        },
    },
    UpdateLocationOrderStep: {
        mutationFn: ({
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/collectiondropoffpoint`,
                data,
                method: 'PATCH',
                token,
            })
        },
    },
}

export default config
