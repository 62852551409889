import React from 'react'
import clsx from 'clsx'

import useStyles from './InputRule.styles'

type Props = {
    name?: string,
    className?: any,
    rule?: string,
    example?: string,
}

const defaultProps: Partial<Props> = {
    className: '',
    rule: '',
    example: '',
    name: 'inputRule',
}

const InputRule = ({
    rule,
    example,
    className,
    name,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.container}>
            <div
                className={className}
                data-testid={`inputRule${name}`}
            >
                <div className={classes.label}>
                    Rule
                </div>
                <div className={classes.wrapper}>
                    <span
                        className={clsx(classes.text, className.text)}
                        data-testid={`${name}-rule`}
                    >
                        {rule || '-'}
                    </span>
                </div>
            </div>
            <div
                className={className}
                data-testid={`inputRuleExample${name}`}
            >
                <div className={classes.label}>
                    Example
                </div>
                <div className={classes.wrapper}>
                    <span
                        className={clsx(classes.text, className.text)}
                        data-testid={`${name}-example`}
                    >
                        {example || '-'}
                    </span>
                </div>
            </div>
        </div>
    )
}

InputRule.defaultProps = defaultProps

export default InputRule
