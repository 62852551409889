const options: {
    width: string,
    chartArea: Record<string, string | number>,
    hAxis: {
        title: string,
        minValue: number,
    },
    legend: {
        position: string,
    },
} = {
    width: '100%',
    chartArea: {
        width: '80%',
        left: 100,
        top: 20,
        height: '75%',
    },
    hAxis: {
        title: `Emissions (tCO${String.fromCharCode(8322)})`,
        minValue: 1,
    },
    legend: {
        position: 'none',
    },
}

export default options
