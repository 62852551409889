import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    OrderFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    ProductType,
    OrderTemperatureRange,
} from 'app/types/enums'

const getContainerSelectionFields = ({
    isInternalType,
    temperatureRange,
    allowedProductTypes,
    getAllowedValues,
}: {
    isInternalType: boolean,
    temperatureRange: OrderTemperatureRange,
    allowedProductTypes: ProductType[],
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
}): DTOCardFormField<OrderFields>[] => {
    const notificationMsg: NestedField<OrderFields>[] = [{
        componentName: 'InputSingleline',
        value: 'Only QC Passed containers are shown below',
        disabled: true,
        labelKey: '',
    }]

    return [
        {
            title: 'Required Containers',
            className: 'requiredContainers',
            id: 'containers',
            fields: [
                {
                    id: 'column1',
                    fields: isInternalType ? [{
                        name: 'requiredContainersInternal',
                        componentName: 'RequiredContainersByContainerType',
                        temperatureRange,
                        labelKey: 'OrderByNumberView.requiredContainersInternal',
                    }] : [{
                        name: 'requiredContainers',
                        componentName: 'RequiredContainersByProductType',
                        allowedProductTypes,
                        disabled: true,
                        labelKey: 'OrderByNumberView.requiredContainers',
                    }],
                },
                {
                    id: 'column2',
                    fields: [{
                        name: 'requiredCargoComment',
                        componentName: 'LongText',
                        disabled: !isInternalType,
                        labelKey: 'OrderByNumberView.requiredCargoComment',
                    }],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OrderTemperatureRange),
                            disabled: true,
                            labelKey: 'OrderByNumberView.temperatureRange',
                        },
                        ...temperatureRange !== OrderTemperatureRange.ANY_TEMP_RANGE
                            ? notificationMsg
                            : [],
                    ],
                },
                {
                    id: 'column4',
                    fields: [{
                        name: 'tempDuringTransport',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'OrderByNumberView.tempDuringTransport',
                    }],
                },
            ],
        },
        {
            title: 'Origin',
            className: 'location',
            id: 'location',
            fields: [{
                name: 'originLocation',
                componentName: 'LocationObjectSelector',
                disabled: true,
                labelKey: 'OrderByNumberView.originLocation',
            }],
        },
    ]
}

export default getContainerSelectionFields
