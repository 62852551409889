/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import {
    JsonViewer,
} from '@textea/json-viewer'
import uniqueId from 'lodash/uniqueId'
import clsx from 'clsx'
import TextField from '@mui/material/TextField'

import createInputHandler from 'app/utils/createInputHandler'
import filterFieldForInput from 'app/utils/filterFieldForInput'

import useStyles from './Unknown.styles'

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.any, // eslint-disable-line
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    dataType: PropTypes.string,
}

const defaultProps = {
    disabled: false,
    className: '',
    value: undefined,
    title: '',
    dataType: '',
}

const Unknown = (props) => {
    const {
        onChange,
        value,
        className,
        disabled,
        title,
    } = props

    const {
        classes,
    } = useStyles()

    const inputId = useMemo(() => {
        return uniqueId('unknowen-input-')
    }, [])

    const inputOnChange = useMemo(() => {
        return createInputHandler(onChange, (str) => {
            return JSON.parse(str)
        })
    }, [onChange])

    if (typeof value === 'object') {
        return (
            <div className={className}>
                <label htmlFor={inputId}>
                    {title}
                </label>
                <div className={classes.json}>
                    <JsonViewer
                        value={value}
                    />
                </div>
            </div>
        )
    }

    return (
        <TextField
            variant="standard"
            {...filterFieldForInput(props)}
            multiline
            id={inputId}
            disabled={disabled}
            label={title}
            className={clsx(classes.root, className)}
            onChange={inputOnChange}
            value={value}
        />
    )
}

Unknown.propTypes = propTypes
Unknown.defaultProps = defaultProps

export default Unknown
