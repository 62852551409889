import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import AttachmentType from 'app/shared-components/Attachment/Attachment.types'
import requests from '../Attachment.request'

const useGetAttachment = (id) => {
    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useRequest<AttachmentType>({
        key: RequestKeys.getAttachment,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetAttachment
