import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import CircularProgress from '@mui/material/CircularProgress'
import List from '@mui/material/List'
import TextField from '@mui/material/TextField'

import FilterPopover from 'app/shared-components/FilterPopover'
import * as statuses from 'app/utils/requestStatuses'
import getDataFromInput from 'app/utils/getDataFromInput'

import FilterItem from './FilterItem'

import useStyles from './Filter.styles'

const propTypes = {
    className: PropTypes.string,
    button: PropTypes.element,
    id: PropTypes.string.isRequired,
    setSearchPhrase: PropTypes.func.isRequired,
    searchPhrase: PropTypes.string.isRequired,
    filter: PropTypes.arrayOf(PropTypes.string),
    set: PropTypes.func.isRequired,
    load: PropTypes.func.isRequired,
    predefinedFilter: PropTypes.object, // eslint-disable-line
    filters: PropTypes.array, // eslint-disable-line
    status: PropTypes.oneOf([
        statuses.SUCCESS,
        statuses.FAILURE,
        statuses.PENDING,
        statuses.PRISTIN,
    ]).isRequired,
    filterField: PropTypes.string,
}

const defaultProps = {
    predefinedFilter: {},
    filter: [],
    filters: [],
    className: '',
    button: null,
    filterField: '',
}

const rearrangeItems = (filterItems) => {
    const checkedItems = filterItems.filter(({
        checked,
    }) => {
        return checked
    }).sort((a, b) => {
        return a.label > b.label ? 1 : -1
    })

    const notCheckedItems = filterItems.filter(({
        checked,
    }) => {
        return !checked
    })

    return [
        ...checkedItems,
        ...notCheckedItems,
    ]
}

const Filter = (props) => {
    const {
        id,
        searchPhrase,
        predefinedFilter,
        setSearchPhrase,
        status,
        load,
        filters,
        set,
        filter,
        button,
        className,
        filterField,
    } = props

    const {
        classes,
    } = useStyles()

    const [
        selectedItems,
        setSelectedItems,
    ] = useState({})

    const filterItems = useMemo(() => {
        return rearrangeItems(
            filters
                .filter(({
                    label = '',
                    value = '',
                }) => {
                    return (
                        String(value).toLowerCase().includes(searchPhrase.toLowerCase())
                        || label.toLowerCase().includes(searchPhrase.toLowerCase())
                    )
                })
                .map((itemProps) => {
                    const itemValue = !isNil(itemProps.value) ? itemProps.value : 'null'

                    const selected = !!selectedItems[itemValue]
                    const predefinedSelected = get(predefinedFilter, `[${id}]`, []).includes(itemValue)

                    return {
                        ...itemProps,
                        checked: selected,
                        disabled: predefinedSelected,
                        value: itemValue,
                        onClick: (e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            if (predefinedSelected) {
                                return
                            }

                            setSelectedItems({
                                ...selectedItems,
                                [itemValue]: !selected,
                            })
                        },
                    }
                }),
        )
    }, [
        id,
        filters,
        searchPhrase,
        selectedItems,
        predefinedFilter,
    ])

    useEffect(() => {
        setSelectedItems(filter.reduce((acc, key) => {
            acc[key] = true

            return acc
        }, {}))
    }, [filter])

    const onClose = useCallback(() => {
        set(
            Object.entries(selectedItems).reduce((acc, [
                key,
                value,
            ]) => {
                if (value) {
                    acc.push(key === 'null' ? null : key)
                }
                return acc
            }, []),
        )
    }, [
        set,
        selectedItems,
    ])

    return (
        <FilterPopover
            data-testid={`filter-${filterField}`}
            className={className}
            onOpen={load}
            onClose={onClose}
            active={filter.length > 0}
            button={button}
        >
            <div
                className={classes.filterListContainer}
                data-testid={`filter-container-${filterField}`}
            >
                {status === statuses.FAILURE && (
                    <div>an error happened.</div>
                )}
                {(
                    status === statuses.PENDING || status === statuses.PRISTIN
                ) && (
                    <div className={classes.loader}>
                        <CircularProgress
                            size={40}
                        />
                    </div>
                )}
                {status === statuses.SUCCESS && (
                    <List
                        className={classes.filtersList}
                    >
                        <TextField
                            onChange={getDataFromInput(setSearchPhrase)}
                            className={classes.searchField}
                            label="Filter search"
                            value={searchPhrase}
                            autoFocus
                        />
                        {filterItems.map((itemProps) => {
                            return (
                                <FilterItem
                                    key={`filterItem-${itemProps.value}`}
                                    {...itemProps} // eslint-disable-line react/jsx-props-no-spreading, max-len
                                />
                            )
                        })}
                    </List>
                )}
            </div>
        </FilterPopover>
    )
}

Filter.propTypes = propTypes
Filter.defaultProps = defaultProps

export default Filter
