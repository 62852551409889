import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import get from 'lodash/get'
import noop from 'lodash/noop'

import DomainObjectContext from 'app/Apps/DomainObject/DomainObjectContext'
import DomainObject from 'app/Apps/DomainObject/DomainObject'
import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    TableResponse,
} from 'app/types/request.types'

import {
    useStoreTableConfig,
} from 'app/store/Configs'
import {
    SkyNetTableTab, SkyNetTableTabWithLabel, SkyNetTableConfig, SidebarTab,
} from './types/skyNetTable.types'
import usePrepareColumns from './hooks/usePrepareColumns'
import useLoadWithParams from './hooks/useLoadWithParams'
import useCreateDomainObjectContextValue from './hooks/useCreateDomainObjectContextValue'

type Props = {
    className?: string,
    createForm?: (props: any) => JSX.Element,
    customUrl?: string,
    defaultTab?: string,
    excludeFilters?: Record<string, any>,
    filter?: Record<string, any>,
    getDomainObject?: (id: string|number | { id: string | number }) => any,
    method?: string,
    moduleName?: string,
    name: string,
    noDetails?: boolean,
    onLoad?: (data: TableResponse)=> void,
    onRowClick?: (...args: any[])=>void,
    onSelectRow?: (rowIds: string|number[])=> void,
    printForm?: (props: Record<string, any>)=> JSX.Element,
    rowsPerPageDefault?: number,
    selectedRows?: number[],
    simple?: boolean,
    tableConfig: SkyNetTableConfig,
    tableControls?: TableControlTypes[],
    tabs?: SidebarTab[],
    uniqField?: string,
    isShowCounterTab?: boolean,
    showSearch?: boolean,
    domainRequestKey?: RequestKeys,
    paginationModeMatches?: boolean,
    showSelectAll?: boolean,
    disabled?: boolean,
    parseResponse?: (data: TableResponse) => TableResponse
}

const defaultProps: Partial<Props> = {
    defaultTab: '',
    className: null,
    customUrl: undefined,
    excludeFilters: {},
    filter: {},
    getDomainObject: undefined,
    isShowCounterTab: false,
    method: undefined,
    moduleName: undefined,
    noDetails: false,
    onLoad: noop,
    onRowClick: undefined,
    onSelectRow: undefined,
    printForm: undefined,
    rowsPerPageDefault: undefined,
    simple: false,
    selectedRows: undefined,
    showSearch: false,
    tableControls: [],
    tabs: undefined,
    uniqField: 'id',
    createForm: undefined,
    domainRequestKey: undefined,
    paginationModeMatches: undefined,
    showSelectAll: false,
    disabled: false,
    parseResponse: undefined,
}

const SkyNetTableContainer = ({
    className,
    createForm,
    customUrl,
    defaultTab,
    excludeFilters,
    filter,
    getDomainObject,
    method,
    moduleName,
    name,
    noDetails,
    onLoad,
    onRowClick,
    onSelectRow,
    printForm,
    rowsPerPageDefault,
    selectedRows,
    simple,
    tableConfig,
    tableControls,
    tabs: sidebarTabs,
    uniqField,
    isShowCounterTab,
    showSearch,
    domainRequestKey,
    paginationModeMatches,
    showSelectAll,
    disabled,
    parseResponse,
}: Props) => {
    const {
        contextValue,
        richtableRef,
    } = useCreateDomainObjectContextValue({
        defaultTab,
        name,
        uniqField,
        domainRequestKey,
    })

    useStoreTableConfig(tableConfig)

    const tableControlsWithDefault = useMemo((): TableControlTypes[] => {
        if (showSearch) {
            return [
                TableControlTypes.Search,
                ...tableControls,
            ]
        }

        return tableControls
    }, [
        tableControls,
        showSearch,
    ])

    const tableTabs = useMemo((): SkyNetTableTabWithLabel[] => {
        const tabs: SkyNetTableTab[] = get(tableConfig, 'tabs', [])

        return tabs.map((tab) => {
            return {
                label: tab.name,
                ...tab,
            }
        })
    }, [tableConfig])

    const [
        counter,
        setCounter,
    ] = useState<number>()

    const onLoadData = useCallback((data: TableResponse) => {
        if (isShowCounterTab) {
            setCounter(data?.meta?.matchedresults)
        }

        onLoad(data)
    }, [
        isShowCounterTab,
        onLoad,
    ])

    const [
        searchValue,
        setSearchValue,
    ] = useState<string>()

    const {
        loadWithParams,
        appliedFilter,
        selectedTabUrlQuery,
    } = useLoadWithParams({
        customUrl,
        filter,
        method,
        name,
        moduleName,
        onLoadData,
        rowsPerPageDefault,
        tabs: tableTabs,
        searchValue,
        paginationModeMatches,
        parseResponse,
    })

    const columns = usePrepareColumns({
        customUrl,
        name,
        moduleName,
        tableConfig,
        selectedTabUrlQuery,
    })

    const visibleByDefaultColumn = columns.reduce((acc, {
        isHidden, id,
    }) => {
        if (!isHidden) {
            return [
                ...acc,
                id,
            ]
        }
        return acc
    }, [])

    return (
        <DomainObjectContext.Provider
            value={contextValue}
        >
            <DomainObject
                className={className}
                columns={columns}
                createForm={createForm}
                excludeFilters={excludeFilters}
                filter={appliedFilter}
                getDomainObject={getDomainObject}
                load={loadWithParams}
                name={moduleName || name}
                onRowClick={onRowClick}
                onSelectRow={onSelectRow}
                printForm={printForm}
                richtableRef={richtableRef}
                selectedRows={selectedRows}
                sidebarTabs={sidebarTabs}
                simple={simple}
                tableControls={tableControlsWithDefault}
                tabs={tableTabs}
                uniqField={uniqField}
                noDetails={noDetails}
                counter={counter}
                setSearchValue={setSearchValue}
                configName={tableConfig.name}
                visibleByDefaultColumn={visibleByDefaultColumn}
                showSelectAll={showSelectAll}
                disabled={disabled}
            />
        </DomainObjectContext.Provider>
    )
}

SkyNetTableContainer.defaultProps = defaultProps

export default SkyNetTableContainer
