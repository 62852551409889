import React, {
    useEffect, useState, useCallback, useMemo,
} from 'react'
import clsx from 'clsx'
import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import useAccountQuoteInformationContext from '../AccountQuoteInformationContext'
import QuoteOpportunitiesTable from './QuoteOpportunititesTable'
import useStyles from '../AccountQuoteCreate.styles'
import useGetOpportunitiesQuoteStepper from '../../hooks/useGetOpportunitiesQuoteStepper'

const QuoteOpportunities = ({
    disabled,
}: {
    disabled: boolean
}) => {
    const {
        classes,
    } = useStyles()

    const [
        opportunities,
        setOpportunities,
    ] = useState([])
    const {
        value,
        setValue,
        activeStep,
        setActiveStep,
        initialValue,
        setIsEdited,
    } = useAccountQuoteInformationContext()

    const {
        data,
        isDataReady,
    } = useGetOpportunitiesQuoteStepper({
        accountId: value?.account?.id,
        quoteId: value?.id,
        filters: {
            accountLaneGroupLeaseType: value?.leaseType,
        },
    })

    const isCreate = !initialValue

    useEffect(() => {
        if (isDataReady) {
            if (isCreate) {
                setOpportunities(data)

                return
            }

            setOpportunities(data.map((opp) => {
                const requestedOpportunity = initialValue?.requestedOpportunities?.find(({
                    opportunity,
                }) => {
                    return opportunity.id === opp.id
                })

                if (requestedOpportunity) {
                    return {
                        ...opp,
                        selected: true,
                        existed: true,
                        productTypes: requestedOpportunity.productTypes,
                    }
                }

                return opp
            }))
        }
    }, [
        isDataReady,
        data,
        isCreate,
        initialValue?.requestedOpportunities,
    ])

    const selectedOpportunities = useMemo(() => {
        return opportunities.reduce((acc, {
            selected, id, palletType, productTypes, opportunityNumber,
        }) => {
            if (selected) {
                acc.push({
                    opportunity: {
                        id,
                        opportunityNumber,
                    },
                    palletType,
                    productTypes,
                })
            }
            return acc
        }, [])
    }, [opportunities])

    const handleChange = useCallback((updates) => {
        setIsEdited(true)
        setOpportunities((prev) => {
            return [...prev.map((opp) => {
                if (updates.id === opp.id) {
                    return {
                        ...opp,
                        ...updates,
                    }
                }
                return opp
            })]
        })
    }, [setIsEdited])

    const nextAction = useCallback(() => {
        setValue({
            ...value,
            requestedOpportunities: selectedOpportunities,
        })
        setActiveStep(activeStep + 1)
    }, [
        selectedOpportunities,
        value,
        setValue,
        setActiveStep,
        activeStep,
    ])

    const backAction = useCallback(() => {
        if (value.requestedOpportunities?.length) {
            setValue({
                ...value,
                requestedOpportunities: [],
            })
        }
        setActiveStep(activeStep - 1)
    }, [
        value,
        setValue,
        setActiveStep,
        activeStep,
    ])

    const renderButtonPanel = useCallback(({
        isValid,
    }) => {
        return (
            <div className={clsx(classes.buttonWrapper, classes.doubleButtons)}>
                <Button
                    label="Next"
                    onClick={nextAction}
                    className={classes.button}
                    disabled={disabled || !selectedOpportunities.length || !isValid}
                />
                {isCreate && (
                    <Button
                        label="Back"
                        secondary
                        onClick={backAction}
                        className={classes.button}
                        disabled={disabled}
                        data-testid="btn-back"
                    />
                )}
            </div>
        )
    }, [
        classes.buttonWrapper,
        classes.doubleButtons,
        classes.button,
        nextAction,
        disabled,
        selectedOpportunities.length,
        isCreate,
        backAction,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={!isDataReady}
        >
            <QuoteOpportunitiesTable
                data={opportunities}
                onChange={handleChange}
                disabled={disabled}
                renderButtonPanel={renderButtonPanel}
            />
        </StatusHandler>
    )
}

export default QuoteOpportunities
