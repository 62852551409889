import {
    makeStyles,
} from 'app/tss'

const customer = 'customer'
const generalInformation = 'generalInformation'
const airports = 'airports'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
            "${generalInformation} ${generalInformation} ${customer} ${airports}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        generalInformation: {
            gridArea: generalInformation,
        },
        customer: {
            gridArea: customer,
        },
        airports: {
            gridArea: airports,
        },
    }
})

export default useStyles
