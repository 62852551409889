import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    LocationType, LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CollServiceType,
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    AdditionalLaneStepType,
} from '../../createCustomerTransportForm.types'

const getFields = ({
    getAllowedValues,
    deliveryServiceType,
    collectionServiceType,
    transportModeRoad,
    selectedLaneId,
}: {
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    transportModeRoad: TransportModeRoad,
    selectedLaneId: number,
}): DTOCardFormField<keyof AdditionalLaneStepType>[] => {
    return [
        {
            title: 'Handover',
            className: 'handover',
            id: 'handover',
            fields: [
                {
                    id: 'column1',
                    fields: [{
                        name: 'deliveryServiceType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(DeliveryServiceType),
                        required: true,
                        className: 'radio',
                        labelKey: 'Order.deliveryServiceType',
                    }],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'handoverPoint',
                            componentName: 'LocationExpandedViewObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            required: true,
                            labelKey: 'Order.handoverPoint',
                        },
                        {
                            name: 'handoverPointContacts',
                            componentName: 'MultipleContactObjectSelector',
                            includeFilters: {
                                isProspect: [false],
                            },
                            required: deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD,
                            labelKey: 'LaneOpsInformationDto.handoverPointContacts',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Collection',
            className: 'collection',
            id: 'collection',
            fields: [
                {
                    id: 'column3',
                    fields: [{
                        name: 'collectionServiceType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(CollServiceType),
                        required: true,
                        className: 'radio',
                        labelKey: 'LaneOpsInformationDto.collectionServiceType',
                    }],
                },
                {
                    id: 'column4',
                    fields: [
                        {
                            name: 'collectionDropoffPoint',
                            componentName: 'LocationExpandedViewObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            required: true,
                            labelKey: 'Order.collectionDropoffPoint',
                        },
                        {
                            name: 'collectionDropoffPointContacts',
                            componentName: 'MultipleContactObjectSelector',
                            includeFilters: {
                                isProspect: [false],
                            },
                            required: collectionServiceType === CollServiceType.COLLECTION
                                || collectionServiceType === CollServiceType.DROPOFF_FORWARDER,
                            labelKey: 'LaneGroup.collectionDropoffPointContacts',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Additional Details',
            className: 'additionalDetails',
            id: 'additionalDetails',
            fields: [
                {
                    id: 'column5',
                    fields: [
                        {
                            name: 'originAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            required: true,
                            labelKey: 'Order.originAirport',
                        },
                        {
                            name: 'destinationAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            required: true,
                            labelKey: 'Order.destinationAirport',
                        },
                        {
                            name: 'tempRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                            useDropdown: true,
                            required: true,
                            labelKey: 'Order.temperatureRange',
                        },
                    ],
                },
                {
                    id: 'column6',
                    fields: [
                        {
                            name: 'customerExecution',
                            componentName: 'CustomerExecutionQuotationSelector',
                            required: true,
                            labelKey: 'Lane.customerExecution',
                        },
                        {
                            name: 'account',
                            componentName: 'AccountSelector',
                            required: true,
                            labelKey: 'Prebooking.pricingAccount',
                        },
                    ],
                },
                {
                    id: 'column7',
                    fields: [
                        {
                            name: 'consignee',
                            componentName: 'LocationExpandedViewObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            labelKey: 'Order.consignee',
                        },
                        ...!selectedLaneId ? [{
                            name: 'baseLeaseDays',
                            componentName: 'InputSingleline',
                            type: 'number',
                            required: true,
                            labelKey: 'OrderBillingView.baseLeaseDays',
                        }] as NestedField<keyof AdditionalLaneStepType>[] : [],
                    ],
                },
                {
                    id: 'column8',
                    fields: [
                        ...deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD ? [{
                            name: 'transportModeRoad',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(TransportModeRoad),
                            required: true,
                            className: 'radio',
                            labelKey: 'OrderStep.transportModeRoad',
                        }] as NestedField<keyof AdditionalLaneStepType>[] : [],
                        ...transportModeRoad === TransportModeRoad.LTL
                        || transportModeRoad === TransportModeRoad.FTL_OR_LTL ? [{
                            name: 'isLtlPositioningTemperatureControlled',
                            componentName: 'Checkbox',
                            labelKey: 'LaneOpsInformationDto.isLtlPositioningTemperatureControlled',
                        }] as NestedField<keyof AdditionalLaneStepType>[] : [],
                        ...transportModeRoad === TransportModeRoad.FTL
                        || transportModeRoad === TransportModeRoad.FTL_OR_LTL ? [{
                            name: 'isFtlPositioningTemperatureControlled',
                            componentName: 'Checkbox',
                            labelKey: 'LaneOpsInformationDto.isFtlPositioningTemperatureControlled',
                        }] as NestedField<keyof AdditionalLaneStepType>[] : [],
                    ],
                },
            ],
        },
    ]
}

export default getFields
