import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            padding: theme.spacing(2, 0),
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        button: {
            width: 100,
            paddingRight: 10,
        },
        doubleButtons: {
            display: 'inline-flex',
        },
        buttonWrapper: {
            width: 100,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    }
})

export default useStyles
