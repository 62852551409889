const applySearch = (cb) => {
    return (params) => {
        const {
            data: paramsData,
            searchAll,
        } = params

        const data = {
            ...paramsData,
            searchAll,
        }

        return cb({
            ...params,
            data,
        })
    }
}

export default applySearch
