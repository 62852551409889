import {
    useCallback,
} from 'react'
import {
    SkyNetSpreadSheetConfigType,
} from '../SkyNetSpreadSheet.types'

// adds empty row on first spreadsheet render
export default (config: SkyNetSpreadSheetConfigType<any>) => {
    return useCallback(() => {
        return config.fields.reduce((acc, {
            name, defaultValue,
        }) => {
            return {
                ...acc,
                [name as unknown as string]: defaultValue || null,
            }
        }, {})
    }, [config.fields])
}
