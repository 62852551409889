import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import {
    App,
} from 'app/types/common.enums'
import AccountQuotes from 'app/Apps/AccountManagement/AccountQuotes/AccountQuotes'
import ContractBasis from './ContractBasis'
import Lanes from './Lanes'
import LaneGroups from './LaneGroups'
import AlLogisticPricing from './AlLogisticPricing'
import AlLeasePricing from './AlLeasePricing'
import Dashboard from './Dashboard'

const Pricing = () => {
    const {
        path,
    } = useRouteMatch<{path: string}>()

    return (
        <Switch>
            <Route path={`/apps/${App.PRICING}/pricing-dashboard`}>
                <Dashboard />
            </Route>
            <Route path={`${path}/contract-basis`}>
                <ContractBasis />
            </Route>
            <Route path={`/apps/${App.PRICING}/lane-groups`}>
                <LaneGroups />
            </Route>
            <Route path={`/apps/${App.PRICING}/lanes`}>
                <Lanes />
            </Route>
            <Route path={`/apps/${App.PRICING}/al-logistic-pricing`}>
                <AlLogisticPricing />
            </Route>
            <Route path={`/apps/${App.PRICING}/al-lease-pricing`}>
                <AlLeasePricing />
            </Route>
            <Route path={`/apps/${App.PRICING}/quotes`}>
                <AccountQuotes isCreateFormEnabled={false} />
            </Route>
            <Redirect
                exact
                path={path}
                to={`${path}/contract-basis`}
            />
        </Switch>
    )
}

export default Pricing
