import React, {
    useCallback,
} from 'react'
import upperCase from 'lodash/upperCase'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import DateSelect from 'app/shared-components/DateSelect'
import InputSingleline from 'app/shared-components/InputSingleline'

import useStyles from './ChangeValidityConfirmationDialog.style'

type Props = {
    title: string,
    openConfirmDialog?: boolean,
    handleClose: (confirm: boolean) => void
    changeValidityState: {
        validFrom: string,
        validTo: string,
        reason: string,
    },
    setChangeValidityState: (change) => void,
    orderCount: number,
}
const defaultProps: Partial<Props> = {
    openConfirmDialog: true,
}
const ChangeValidityConfirmationDialog = ({
    title,
    openConfirmDialog,
    handleClose,
    changeValidityState,
    setChangeValidityState,
    orderCount,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const handleChange = useCallback((change, field) => {
        setChangeValidityState({
            ...changeValidityState,
            [field]: change,
        })
    }, [
        changeValidityState,
        setChangeValidityState,
    ])

    return (
        <ConfirmationDialog
            open={openConfirmDialog}
            handleClose={handleClose}
            dialogTitle={`${upperCase(title)} UPDATE`}
            positiveLabel="Update Fee"
            negativeLabel="Do not Update Fee"
        >
            <>
                <div className={classes.container}>
                    <div className={classes.child}>
                        <DateSelect
                            title="Valid From"
                            value={changeValidityState.validFrom}
                            name="validFrom"
                            onChange={(date) => { return handleChange(date, 'validFrom') }}
                        />
                    </div>
                    <div className={classes.child}>
                        <DateSelect
                            title="Valid To"
                            value={changeValidityState.validTo}
                            name="validTo"
                            onChange={(date) => { return handleChange(date, 'validTo') }}
                        />
                    </div>
                </div>
                { orderCount > 0 ? (
                    <div className={classes.container}>
                        <div className={classes.child}>
                            <InputSingleline
                                title="Comment for Billing"
                                value={changeValidityState.reason}
                                rows={4}
                                multiline
                                required
                                onChange={(reason) => { return handleChange(reason, 'reason') }}
                            />
                        </div>
                        <div className={classes.child}>
                            {`There ${orderCount > 1 ? 'are' : 'is'} ${orderCount} order${orderCount > 1 ? 's' : ''} linked to this fee.\n
                            Please clarify in the comment how potential affected orders should be priced.`}
                        </div>
                    </div>
                ) : (
                    <div className={classes.child}>
                        There are no orders linked to this fee.
                    </div>
                ) }

            </>
        </ConfirmationDialog>
    )
}

ChangeValidityConfirmationDialog.defaultProps = defaultProps

export default ChangeValidityConfirmationDialog
