import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import requestConfig from '../engagement.request'
import EngagementForm from '../EngagementForm'

import {
    PROSPECT_CONTACTS_URL,
} from '../engagement.config'

const EngagementCreateForm = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((record) => {
        addTableElement(record)
        selectDomainObject(record?.id, record.isProspect && PROSPECT_CONTACTS_URL)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <EngagementForm
            onSuccess={onSuccess}
            requestParam={requestConfig.create.request()}
        />
    )
}

export default EngagementCreateForm
