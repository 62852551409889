import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Select as MaterialSelect,
    Input,
    FormControl,
    MenuItem, Checkbox,
    ListItemText,
} from '@mui/material'
import {
    useTheme,
} from '@mui/material/styles'

import Label from 'app/shared-components/Label'

import useStyles from './MultipleSelect.styles'

const propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.array).isRequired,
    disabled: PropTypes.bool,
}

const defaultProps = {
    name: 'multiple-select',
    title: '',
    value: [],
    disabled: false,
    required: false,
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const MultipleSelect = (props) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)
    const {
        name,
        title,
        value,
        onChange,
        options,
        disabled,
        required,
    } = props

    const renderValue = useCallback((selected) => {
        return options.filter((option) => {
            return selected.includes(option[0])
        }).map((label) => {
            return label[1]
        }).join(', ')
    }, [options])

    return (
        <>
            <FormControl
                className={clsx(
                    classes.formControl,
                )}
                variant="standard"
            >
                {title && (
                    <Label
                        title={title}
                    />
                )}
                <div className={clsx(
                    {
                        [classes.isRequired]: required,
                    },
                )}
                >
                    <div className={classes.requiredMark} />
                    <MaterialSelect
                        labelId="mutiple-checkbox-label"
                        id="multiple-checkbox"
                        fullWidth
                        multiple
                        value={value}
                        className={
                            clsx(
                                {
                                    [classes.isRequiredBackground]: required,
                                },
                                classes.multipleSelect,
                            )
                        }
                        onChange={onChange}
                        disabled={disabled}
                        input={<Input />}
                        renderValue={renderValue}
                        MenuProps={MenuProps}
                        data-testid={`multiple-select-${name}`}
                        classes={{
                            root: classes.input,
                            select: classes.select,
                        }}
                    >
                        {options.map((option) => {
                            const isChecked = value.includes(option[0])

                            return (
                                <MenuItem
                                    key={`menu-item-${option[1]}`}
                                    value={option[0]}
                                    className={classes.menuItem}
                                >
                                    <Checkbox
                                        value={isChecked}
                                        checked={isChecked}
                                    />
                                    <ListItemText primary={option[1]} />
                                </MenuItem>
                            )
                        })}
                    </MaterialSelect>
                </div>
            </FormControl>
        </>
    )
}

MultipleSelect.propTypes = propTypes
MultipleSelect.defaultProps = defaultProps

export default MultipleSelect
