import React, {
    useMemo,
} from 'react'
import Card from 'app/shared-components/Card'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useLabel from 'app/hooks/useLabel'
import useGetRequiredContainersChanges from './hooks/useGetRequiredContainersChanges'
import getColumns from './RequiredContainersChanges.columns'
import useStyles from './RequiredContainersChanges.styles'

const RequiredContainersChanges = ({
    id,
}: {id: number}) => {
    const label = useLabel()
    const {
        classes,
    } = useStyles()
    const {
        loadData,
        onParamsChange,
    } = useGetRequiredContainersChanges(id)

    const columns = useMemo(() => {
        return getColumns({
            label,
        })
    }, [label])

    return (
        <Card
            className={classes.card}
            title={label('Prebooking.requiredContainersChanges')}
        >
            <RichTable
                configName="RequiredContainersChangesTable"
                name="PrebookingRequiredContainersChanges"
                load={loadData}
                onParamsChange={onParamsChange}
                columns={columns}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}

export default RequiredContainersChanges
