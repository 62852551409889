import React, {
    useMemo,
} from 'react'

import Card from 'app/shared-components/Card'
import InputSingleline from 'app/shared-components/InputSingleline'
import useGetAirportsById from 'app/Apps/TransportPlanner/Simulation/hooks/useGetAirportsById'

type Props = {
    route: number[],
    totalEmissions: string,
    totalCo2Saved: string,
    totalCo2Competition: string,
    className: string,
}

const Summary = ({
    route,
    totalEmissions,
    totalCo2Saved,
    totalCo2Competition,
    className,
}: Props) => {
    const {
        airports,
    } = useGetAirportsById(route)

    const label: string = useMemo(() => {
        if (airports?.length) {
            const start = airports.find((item) => { return item.id === route[0] })
            const end = route.length > 1
                ? airports.find((item) => { return item.id === route[route.length - 1] })
                : null

            return `${start?.iataCode || '___'}-${end?.iataCode || '___'}`
        }
        return null
    }, [
        airports,
        route,
    ])

    return (
        <Card
            title="Summary"
            className={className}
        >
            <InputSingleline
                title="Lane"
                value={label}
                disabled
            />
            <InputSingleline
                title={`CO${String.fromCharCode(8322)} emissions SkyCell [tCO${String.fromCharCode(8322)}e]`}
                value={totalEmissions}
                disabled
            />
            <InputSingleline
                title={`CO${String.fromCharCode(8322)} emissions best of competition [tCO${String.fromCharCode(8322)}e]`}
                value={totalCo2Competition}
                disabled
            />
            <InputSingleline
                title={`CO${String.fromCharCode(8322)} saved vs Best of competition [tCO${String.fromCharCode(8322)}e]`}
                value={totalCo2Saved}
                disabled
            />
        </Card>
    )
}

export default Summary
