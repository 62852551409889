import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            paddingTop: theme.spacing(1),
        },
        baseStyle: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            marginBottom: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: theme.palette.secondary[400],
            borderStyle: 'dashed',
            backgroundColor: theme.palette.secondary[100],
            color: theme.palette.secondary[400],
            outline: 'none',
            transition: 'all .24s ease-in-out',
        },
        required: {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary[30],
            outline: 'none',
        },
        disabled: {
            borderColor: theme.palette.secondary[300],
            backgroundColor: theme.palette.secondary[50],
            outline: 'none',
        },
        text: {
            fontSize: theme.typography.body1.fontSize,
            color: theme.typography.body1.color,
            marginBottom: theme.spacing(1),
            transition: 'all .24s ease-in-out',
        },
        textRequired: {
            color: theme.palette.primary.main,
        },
        textDisabled: {
            color: theme.palette.secondary[300],
        },
        activeStyleText: {
            fontWeight: 600,
        },
        activeStyle: {
            borderWidth: 3,
        },
        rejectStyle: {
            borderColor: theme.palette.common.red,
        },
        buttonStyle: {
            margin: '8px',
        },
        cloud: {
            marginRight: '3px',
        },
        progress: {
            marginRight: '10px',
        },
        link: {
            color: theme.palette.primary.dark,
        },
        fileSize: {
            color: theme.typography.caption.color,
            fontSize: theme.typography.caption.fontSize,
        },
    }
})

export default useStyles
