import queryString from 'query-string'

import simpleApi from 'app/services/simpleApi'
import {
    RequestConfig,
} from 'app/types/request.types'

import {
    ModificationType,
} from '../../orders.types'
import {
    PositioningSetupType,
} from './PositioningSetup.types'

type PositioningSetupRequest = {
    Get: {
        requestFn: ({
            orderNumber,
        }: {orderNumber: string}) => RequestConfig,
    },
    Update: {
        mutationFn: ({
            modificationType,
            orderNumber,
            data,
            token,
        }: {
            modificationType: ModificationType,
            orderNumber: string,
            data: PositioningSetupType,
            token: string,
        }) => Promise<any>,
    },
}

const request: PositioningSetupRequest = {
    Get: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/positioning-setup`,
                method: 'GET',
            }
        },
    },
    Update: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: queryString.stringifyUrl({
                    url: `order/${orderNumber}/positioning-setup`,
                    query: {
                        modificationType,
                    },
                }),
                method: 'PATCH',
                data,
                token,
            })
        },
    },
}

export default request
