import React, {
    useMemo,
} from 'react'
import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    TooltipWrapper,
} from 'app/shared-components/Tooltip'

import useStyles from './EnumListMultipleColored.styles'

type Props = {
    configName?: string,
    mapCellProps: string,
    value?: string[],
}

const defaultProps = {
    value: [],
    configName: null,
}

const ownTooltipProps = {
    enterDelay: 500,
    placement: 'top',
}

const EnumListMultipleColored = ({
    configName,
    mapCellProps,
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const fieldConfig = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })

    if (!fieldConfig?.enumsConfig) {
        // eslint-disable-next-line no-console
        console.error(`No allowed values config found for "${mapCellProps}" field in "${configName}"`)
    }

    const getAllowedValues = useEnumValues()

    const {
        tooltip,
        chip,
    } = useMemo(() => {
        return (fieldConfig?.enumsConfig || []).reduce((acc, el) => {
            const {
                renderAs, ...rest
            } = el

            return {
                ...acc,
                [renderAs]: {
                    ...rest,
                    allowedValues: getAllowedValues(el.allowedValuesEnum),
                },
            }
        }, {})
    }, [
        fieldConfig,
        getAllowedValues,
    ])

    return (
        <div className={classes.wrapper}>
            {value.map((item) => {
                return (
                    <TooltipWrapper
                        key={`tooltip-${tooltip?.name}`}
                        value={tooltip?.allowedValues?.[item[tooltip?.name]]}
                        ownTooltipProps={ownTooltipProps}
                    >
                        <div
                            className={classes.chip}
                            style={{
                                backgroundColor: tooltip?.colorConfig?.[item[tooltip?.name]],
                            }}
                        >
                            {chip?.allowedValues?.[item[chip?.name]]}
                        </div>
                    </TooltipWrapper>
                )
            })}
        </div>
    )
}

EnumListMultipleColored.defaultProps = defaultProps

export default EnumListMultipleColored
