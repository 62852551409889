import React, {
    useCallback,
} from 'react'
import Typography from '@mui/material/Typography'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import {
    PrintPreviewUniq,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'

import useStyles from './PrintPreviewDataForm.styles'
import {
    additionalFeesColumns,
    laneFeesColumns,
} from './PrintPreviewDataForm.columns'

type Props = {
    data: PrintPreviewUniq,
    laneFeesSelectedRows: string[],
    laneFeesOnSelectedRow: (selectedRows: string[]) => void,
    additionFeesSelectedRows: number[],
    additonalFeesOnSelectedRow: (selectedRows: number[]) => void,
}

const PrintPreviewDataForm = ({
    laneFeesSelectedRows,
    laneFeesOnSelectedRow,
    additionFeesSelectedRows,
    additonalFeesOnSelectedRow,
    data,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        additionalFeesTableRows,
        lanesTableRows,
    } = data

    const additionalFeesLoad = useCallback(() => {
        return Promise.resolve({
            data: additionalFeesTableRows,
        })
    }, [additionalFeesTableRows])

    const laneFeesLoad = useCallback(() => {
        return Promise.resolve({
            data: lanesTableRows,
        })
    }, [lanesTableRows])

    const handleAdditonalFeesOnSelectedRow = useCallback((selectedRowsIds) => {
        additonalFeesOnSelectedRow(selectedRowsIds)
    }, [additonalFeesOnSelectedRow])

    const handleLaneFeesOnSelectedRow = useCallback((selectedRowsIds) => {
        laneFeesOnSelectedRow(selectedRowsIds)
    }, [laneFeesOnSelectedRow])

    return (
        <div>
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="createquote-additionalFees-card"
            >
                <>
                    <div className={classes.header}>
                        <Typography variant="h3">
                            Lane / Lane Fees
                        </Typography>
                    </div>
                    <RichTable
                        configName="CreateQuote.LaneFees"
                        name="LaneFees"
                        load={laneFeesLoad}
                        columns={laneFeesColumns}
                        uniqField="tempId"
                        className={classes.tableContent}
                        classNames={{
                            contentWrapper: classes.tableContentWrapper,
                        }}
                        selectedRows={laneFeesSelectedRows}
                        onSelectRow={handleLaneFeesOnSelectedRow}
                        showSelectAll
                    />
                </>
            </Card>
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="createquote-additionalFees-card"
            >
                <>
                    <div className={classes.header}>
                        <Typography variant="h3">
                            Additional Fees
                        </Typography>
                    </div>
                    <RichTable
                        configName="CreateQuote.AdditionalFees"
                        name="AdditionalFees"
                        load={additionalFeesLoad}
                        columns={additionalFeesColumns}
                        uniqField="additionalFeesFeeId"
                        className={classes.tableContent}
                        classNames={{
                            contentWrapper: classes.tableContentWrapper,
                        }}
                        onSelectRow={handleAdditonalFeesOnSelectedRow}
                        selectedRows={additionFeesSelectedRows}
                        showSelectAll
                    />
                </>
            </Card>
        </div>
    )
}

export default PrintPreviewDataForm
