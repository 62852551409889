import simpleApi from 'app/services/simpleApi'

import {
    UseRequestConfig,
} from 'app/types/request.types'

const request: Record<string, UseRequestConfig> = {
    UpdateNotificationsSettings: {
        mutationFn: ({
            token,
            laneId,
            notificationsSettings,
        }) => {
            return simpleApi({
                url: `lane/${laneId}/notifications-settings`,
                method: 'PATCH',
                data: {
                    ...notificationsSettings,
                },
                token,
            })
        },
    },
    GetNotificationsSettings: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `lane/${id}/notifications-settings`,
            }
        },
    },
}

export default request
