import React, {
    useCallback, useMemo, useState,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import StatusHandler from 'app/shared-components/StatusHandler'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import Button from 'app/shared-components/Button'

import BillingCompanyForm from '../BillingCompanyForm'
import useGetBillingCompany from '../hooks/useGetBillingCompany'
import useArchiveBillingCompany from '../hooks/useArchiveBillingCompany'
import useRestoreBillingCompany from '../hooks/useRestoreBillingCompany'

import requests from '../BillingCompanies.request'

const ACTIONS = {
    ARCHIVE: 'ARCHIVE',
    RESTORE: 'RESTORE',
}

const DIALOG_TITLE_MAP = {
    ARCHIVE: 'ARCHIVING',
    RESTORE: 'RESTORING',
}

const DIALOG_CONTENT_MAP = {
    ARCHIVE: 'archive',
    RESTORE: 'restore',
}

const BillingCompanyUpdate = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetBillingCompany(id)

    const [
        actionDialog,
        setActionDialog,
    ] = useState({
        open: false,
        action: undefined,
    })

    const history = useHistory()

    const onSuccessArchiveRestoreLocal = useCallback(() => {
        history.push('/apps/contactmanagement/billing-companies/all')
    }, [history])

    const {
        mutate: mutateArchiveBillingCompany,
    } = useArchiveBillingCompany({
        onSuccess: onSuccessArchiveRestoreLocal,
    })

    const {
        mutate: mutateRestoreBillingCompany,
    } = useRestoreBillingCompany({
        onSuccess: onSuccessArchiveRestoreLocal,
    })

    const onArchive = useCallback(() => {
        setActionDialog({
            open: true,
            action: ACTIONS.ARCHIVE,
        })
    }, [])
    const onRestore = useCallback(() => {
        setActionDialog({
            open: true,
            action: ACTIONS.RESTORE,
        })
    }, [])

    const customButtons = useMemo(() => {
        return data?.archived ? [<Button
            label="Restore"
            secondary
            key="archive"
            onClick={onRestore}
        />] : [<Button
            label="Archive"
            secondary
            key="archive"
            onClick={onArchive}
        />]
    }, [
        data?.archived,
        onArchive,
        onRestore,
    ])

    const handleConfirmAction = useCallback((confirm) => {
        if (confirm) {
            if (actionDialog.action === ACTIONS.ARCHIVE) {
                mutateArchiveBillingCompany({
                    id: data?.id,
                })
            } else {
                mutateRestoreBillingCompany({
                    id: data?.id,
                })
            }
        }

        setActionDialog({
            open: false,
            action: undefined,
        })
    }, [
        actionDialog.action,
        data?.id,
        mutateArchiveBillingCompany,
        mutateRestoreBillingCompany,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <BillingCompanyForm
                    data={data}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    customButtons={customButtons}
                    request={requests.update({
                        id,
                    })}
                />
                <ConfirmationDialog
                    open={actionDialog.open}
                    handleClose={handleConfirmAction}
                    dialogTitle={`BILLING COMPANY ${DIALOG_TITLE_MAP[actionDialog.action]} CONFIRMATION`}
                    dialogContent={`Do you want to ${DIALOG_CONTENT_MAP[actionDialog.action]} this billing company?`}
                />
            </>

        </StatusHandler>
    )
}

export default BillingCompanyUpdate
