import React, {
    useState, useCallback,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import {
    OrderTemperatureRange,
} from 'app/types/enums'

import {
    useSharedContext,
} from 'app/shared-components/SharedContext'
import OrderInformation from './Steps/OrderInformation'
import {
    AutomaticallyFormType, TransportModes,
} from './createAutomaticallyForm.types'
import BestOptions from './Steps/BestOptions'
import EditAvailableOptions from './Steps/EditAvailableOptions'

const CreateAutomaticallyForm = () => {
    const {
        setFormData,
        collectionDropoffPointId,
        selectedPreviousOrderId,
    } = useSharedContext()
    const [
        value,
        setValue,
    ] = useState<AutomaticallyFormType>({
        originLocation: collectionDropoffPointId ? {
            id: collectionDropoffPointId,
        } : null,
        selectPreviousOrders: Boolean(collectionDropoffPointId),
        previousOrders: selectedPreviousOrderId && [selectedPreviousOrderId],
        transportModes: [
            TransportModes.AIR,
            TransportModes.ROAD,
            TransportModes.SEA,
        ],
        temperatureRange: OrderTemperatureRange.ANY_TEMP_RANGE,
    } as AutomaticallyFormType)

    const onChange = useCallback((val) => {
        setValue((prev) => {
            setFormData(val)
            return {
                ...prev,
                ...val,
            }
        })
    }, [setFormData])

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const steps = [
        {
            key: '0',
            label: 'Fill in Order Information',
            expanded: true,
            renderContent: ({
                index,
            }) => {
                return (
                    <OrderInformation
                        value={value}
                        onChange={onChange}
                        index={index}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                )
            },
        },
        {
            key: '1',
            label: 'Shipment Options',
            expanded: (currentStep) => {
                return currentStep > 1
            },
            renderContent: ({
                index,
            }) => {
                return (
                    <BestOptions
                        value={value}
                        onChange={setValue}
                        index={index}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                )
            },
        },
        {
            key: '2',
            label: 'Edit Available Options',
            expanded: (currentStep) => {
                return currentStep > 1
            },
            renderContent: ({
                index,
            }) => {
                return (
                    <EditAvailableOptions
                        value={value}
                        onChange={setValue}
                        index={index}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                )
            },
        },
    ]

    return (
        <SkyNetStepper
            activeStep={activeStep}
            steps={steps}
        />
    )
}

export default CreateAutomaticallyForm
