import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        blockItem: {
            height: 'auto',
            overflow: 'visible',
            position: 'relative',
        },
        root: {
            height: 'auto',
            padding: theme.spacing(2, 2, 3, 2),
        },
        content: {
            height: 'auto',
        },
        formItem: {
            width: '100%',
            maxWidth: 1620,
            display: 'flex',
            flexDirection: 'column',
        },
        headerTitle: {
            color: `${theme.palette.secondary[900]} !important`,
            fontWeight: `${theme.typography.h1.fontWeight} !important`,
            fontSize: `${theme.typography.h1.fontSize}px !important`,
            borderBottom: `1px solid ${theme.palette.primary[200]}`,
            paddingBottom: theme.spacing(1.5),
            marginBottom: `${theme.spacing(1)} !important`,
        },
    }
})

export default useStyles
