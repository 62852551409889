/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './ChipSelect.style'

const propTypes = {
    innerProps: PropTypes.objectOf(PropTypes.func).isRequired,
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
}

function Menu(props) {
    const {
        innerProps,
        children,
    } = props

    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <Typography
            className={classes.singleValue}
            {...innerProps}
        >
            {children}
        </Typography>
    )
}

Menu.propTypes = propTypes

export default Menu
