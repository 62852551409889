/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import AddIcon from '@mui/icons-material/Add'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './AddEntry.style'

const propTypes = {
    onClick: PropTypes.func.isRequired,
}

const AddEntry = (props) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    const {
        onClick,
    } = props

    return (
        <Link
            className={classes.btn}
            component="button"
            color="secondary"
            onClick={onClick}
            {...props}
        >
            <AddIcon />
        </Link>
    )
}

AddEntry.propTypes = propTypes

export default AddEntry
