import React from 'react'
import SingleObjectSelectorConverter from 'app/shared-components/SingleObjectSelectorConverter'

import CompanySelector from './CompanySelectorContainer'

type Props = {
    name?: string,
    value?: {
        id: number,
    },
    onChange: (value: any, ...params: any[]) => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
}

const defaultProps : Partial<Props> = {
    name: '',
    value: undefined,
    title: 'Companies',
    disabled: false,
    className: '',
    required: false,
}

const CompanyObjectSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
}: Props) => {
    return (
        <SingleObjectSelectorConverter
            selector={CompanySelector}
            value={value}
            onChange={onChange}
            title={title}
            name={name}
            disabled={disabled}
            className={className}
            isRequired={required}
        />
    )
}

CompanyObjectSelector.defaultProps = defaultProps

export default CompanyObjectSelector
