import isArray from 'lodash/isArray'
import {
    selectSpreadSheetConfigByFieldName,
} from 'app/store/SpreadSheetConfigs'
import store from 'app/store'
import {
    Uncertain,
} from '@silevis/reactgrid'
import {
    SpreadsheetCellOption,
} from './SkyNetSpreadSheet.types'
import {
    SelectCell, MultipleSelectCell,
} from './customTemplates'

function getCellOptions(
    cell: Uncertain<SelectCell> | Uncertain<MultipleSelectCell>,
    configId: string,
): SpreadsheetCellOption[] {
    let allowedValues: Record<string, string> | [string, string][]

    if ('getAllowedValues' in cell && cell.getAllowedValues) {
        allowedValues = cell.getAllowedValues() || []
    } else {
        allowedValues = selectSpreadSheetConfigByFieldName(
            store.getState(), {
                name: configId,
                field: cell.name,
            },
        )?.allowedValues || []
    }

    const result = (isArray(allowedValues)
        ? allowedValues : Object.entries(allowedValues)) as [string, string][]

    return result.map(([
        value,
        label,
    ]) => {
        return {
            value, label, id: value,
        }
    })
}

export default getCellOptions
