import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LaneGroupTemperatureRange,
    RegionFirstLevel,
} from 'app/types/enums'
import {
    RegionalGroup,
} from '../regionalGroups.types'

const getFields = (
    {
        getAllowedValues,
        hasLinkedOpportunities,
        disabled,
    }: {
        getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
        hasLinkedOpportunities: boolean,
        disabled: boolean,
    },
): DTOCardFormField<keyof RegionalGroup>[] => {
    return [{
        id: 'generalInformation',
        title: 'General Information',
        className: 'generalInformation',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'regionalGroupNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'RegionalGroups.regionalGroupNumber',
                        required: true,
                        disabled: true,
                    },
                    {
                        name: 'tempRange',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                        useDropdown: true,
                        labelKey: 'RegionalGroups.tempRange',
                        required: true,
                        disabled: hasLinkedOpportunities || disabled,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'account',
                        componentName: 'AccountSelector',
                        labelKey: 'RegionalGroups.accountName',
                        required: true,
                        disabled: hasLinkedOpportunities || disabled,
                    },
                    {
                        name: 'annualPotentialVolume',
                        componentName: 'IntegerInput',
                        labelKey: 'RegionalGroups.annualPotentialVolume',
                        required: true,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'originRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.originRegion',
                        required: true,
                        disabled: hasLinkedOpportunities || disabled,
                    },
                    {
                        name: 'opportunityIndex',
                        componentName: 'IntegerInput',
                        labelKey: 'RegionalGroups.opportunityIndex',
                    },
                    {
                        name: 'additionalInformation',
                        labelKey: 'RegionalGroups.additionalInformation',
                        componentName: 'LongText',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'destinationRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.destinationRegion',
                        required: true,
                        disabled: hasLinkedOpportunities || disabled,
                    },
                    {
                        name: 'supportingDocuments',
                        componentName: 'AttachmentsObjectSelector',
                        labelKey: 'RegionalGroups.supportingDocuments',
                    },
                ],
            },
        ],
    }]
}

export default getFields
