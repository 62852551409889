import {
    DTOCardFormField, FieldInCard,
} from 'app/types/form.types'

import {
    SimulationFields,
    SimulationProfile,
    MilestoneSource,
} from '../../simulation.types'

const customTempRange: FieldInCard<SimulationFields>[] = [
    {
        name: 'customLowerTempRange',
        componentName: 'InputSingleline',
        type: 'number',
        suffix: '°C',
        className: 'tempRange',
        labelKey: 'TransportSimulation.customLowerTempRange',
    },
    {
        name: 'customUpperTempRange',
        componentName: 'InputSingleline',
        type: 'number',
        suffix: '°C',
        className: 'tempRange',
        labelKey: 'TransportSimulation.customUpperTempRange',
    },
]

const getFields = (
    useCustomTempRange: boolean,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): DTOCardFormField<SimulationFields>[] => {
    return [{
        title: 'Options',
        className: 'options',
        fields: [
            {
                name: 'simulationName',
                componentName: 'InputSingleline',
                labelKey: 'TransportSimulation.simulationName',
            },
            {
                name: 'routeComment',
                componentName: 'LongText',
                multiline: true,
                labelKey: 'TransportSimulation.routeComment',
            },
            {
                name: 'simulationProfile',
                componentName: 'EnumSelector',
                useDropdown: true,
                allowedValues: getAllowedValues(SimulationProfile),
                labelKey: 'TransportSimulation.simulationProfile',
            },
            {
                name: 'milestoneSource',
                componentName: 'EnumSelector',
                useDropdown: true,
                allowedValues: getAllowedValues(MilestoneSource),
                labelKey: 'TransportSimulation.milestoneSource',
            },
            {
                name: 'showEnergyLevel',
                className: 'root',
                componentName: 'Checkbox',
                labelKey: 'TransportSimulation.showEnergyLevel',
            },
            {
                name: 'showStepType',
                componentName: 'Checkbox',
                className: 'root',
                labelKey: 'TransportSimulation.showStepType',
            },
            {
                name: 'showLocation',
                componentName: 'Checkbox',
                className: 'root',
                labelKey: 'TransportSimulation.showLocation',
            },
            {
                name: 'simulationFinalized',
                componentName: 'Checkbox',
                className: 'root',
                labelKey: 'TransportSimulation.simulationFinalized',
            },
            {
                name: 'showTemperatureRange',
                componentName: 'Checkbox',
                className: 'root',
                labelKey: 'TransportSimulation.showTemperatureRange',
            },
            {
                name: 'useCustomTempRange',
                componentName: 'Checkbox',
                className: 'root',
                labelKey: 'TransportSimulation.useCustomTempRange',
            },
            ...useCustomTempRange ? customTempRange : [],
        ],
    }]
}

export default getFields
