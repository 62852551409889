import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        controlsWrapper: {
            display: 'flex',
            justifyContent: 'right',
            maxHeight: theme.shape.tabHeight,
        },
        controls: {
            display: 'flex',
            zIndex: 1,
            height: theme.shape.tabHeight,
            paddingLeft: 10,
            whiteSpace: 'nowrap',
        },
        withBorder: {
            backgroundColor: theme.palette.secondary[50],
            borderRadius: '10px 10px 0px 0px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.secondary[300],
            borderBottom: `1px solid ${theme.palette.secondary[50]}`,
        },
    }
})

export default useStyles
