import {
    FilterConfig, convertObjectValueToFilter, convertFilterValueToObjectSelector,
} from 'app/hooks/useFilter'
import {
    FilterType,
} from 'app/types/common.enums'

const filterConfig: FilterConfig<'account'> = {
    account: {
        filterField: 'accountId',
        filterType: FilterType.Array,
        preselectOptions: {
            componentName: 'AccountSelector',
            filterToValueConverter: convertFilterValueToObjectSelector,
            onChangeConverter: convertObjectValueToFilter,
            placeholder: 'Select Account',
            noneOption: false,
        },
    },
}

export default filterConfig
