import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(2),
        },
        addBtn: {
            width: 36,
            height: 36,
            background: theme.palette.primary[400],
            borderRadius: '50%',
            color: theme.palette.common.white,
            fontSize: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        stepsContainer: {
            display: 'flex',
            width: '92%',
            overflowX: 'auto',
        },
    }
})

export default useStyles
