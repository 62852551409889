import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            width: '100%',
        },
        chip: {
            display: 'inline-block',
            background: theme.palette.secondary[200],
            fontSize: theme.typography.button.fontSize,
            verticalAlign: 'middle',
            borderRadius: 18,
            color: theme.palette.secondary[800],
            padding: theme.spacing(1),
            margin: theme.spacing(0.25),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: 'fill-available',
        },
    }
})

export default useStyles
