import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            paddingTop: theme.spacing(1.5),
        },
        label: {
            padding: '0 !important',
        },
        amount: {
            width: 40,
        },
        amountInput: {
            textAlign: 'right',
        },
        select: {
            width: 'auto',
        },
        cross: {
            padding: `0px ${theme.spacing(1.5)}`,
            marginRight: 5,
            alignSelf: 'center',
            color: theme.palette.secondary[600],
        },
        control: {
            width: 28,
            height: 28,
            borderRadius: '50%',
            background: theme.palette.primary[400],
            color: theme.palette.common.white,
            fontSize: theme.typography.h3.fontSize,
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginLeft: theme.spacing(1.5),
        },
    }
})

export default useStyles
