import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
} from 'react-router-dom'

import useGetLoggerByLoggerNumber from '../hooks/useGetLoggerByLoggerNumber'
import LoggerContainer from '../Logger'

export type LoggerContainerProps = {
    id: string,
    selectedRowId: string,
}
const NAME = 'Logger'

const LoggerOverviewContainer = ({
    id: loggerNumber,
}: LoggerContainerProps) => {
    const {
        data,
        isDataReady,
    } = useGetLoggerByLoggerNumber(loggerNumber)

    const {
        url,
    } = useRouteMatch()

    return (
        <Switch>
            <Route path={[
                `${url}/:loggerNumber`,
                `${url}`,
            ]}
            >
                {isDataReady && (
                    <LoggerContainer
                        data={data}
                        name={NAME}
                    />
                )}
            </Route>
        </Switch>
    )
}

export default LoggerOverviewContainer
