import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../regionalGroups.request'
import {
    RegionalGroup,
} from '../regionalGroups.types'

const useGetRegionalGroupById = (id: number) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<RegionalGroup>({
        key: RequestKeys.getRegionalGroupById,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetRegionalGroupById),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetRegionalGroupById
