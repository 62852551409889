import React, {
    useCallback, useMemo,
} from 'react'
import {
    renderToString,
} from 'react-dom/server'
import ReactApexChart from 'react-apexcharts'

import Card from 'app/shared-components/Card'
import StatusHandler from 'app/shared-components/StatusHandler'

import CustomTooltip from './CustomTooltip'
import useGetTruckCapacity from '../../hooks/useGetTruckCapacity'
import getOptions from './optionsChart'
import useStyles from './TruckCapacity.style'

const TruckCapacity = ({
    id,
}: {
    id: number,
}) => {
    const {
        classes,
    } = useStyles()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetTruckCapacity({
        serviceCenter: {
            id,
        },
    })

    const chartData: {
        series: number[],
        categories: string[],
    } = useMemo(() => {
        return data?.trucksAmount?.reduce((acc, {
            trucksAmount,
            date,
        }) => {
            return {
                series: [
                    ...acc.series,
                    trucksAmount,
                ],
                categories: [
                    ...acc.categories,
                    date,
                ],
            }
        }, {
            series: [],
            categories: [],
        })
    }, [data])

    const customTooltip = useCallback(({
        series: selectedSeries,
        dataPointIndex,
        w,
    }: {
        series: number[],
        dataPointIndex: number,
        w: {
            globals :{
                labels: string[],
            }
        }
    }) => {
        return renderToString(
            <CustomTooltip
                date={w.globals.labels[dataPointIndex]}
                maxTrucksPerDay={data?.maxTrucksPerDay}
                totalNumberOfTrucks={selectedSeries[0][dataPointIndex]}
            />,
        )
    }, [data?.maxTrucksPerDay])

    const options = useMemo(() => {
        return getOptions({
            categories: chartData?.categories,
            customTooltip,
            maxTrucksPerDay: data?.maxTrucksPerDay,
        })
    }, [
        chartData?.categories,
        customTooltip,
        data?.maxTrucksPerDay,
    ])

    return (
        <Card
            contentClass={classes.cardContent}
            data-testid="truck-capacity-card"
            title="Truck Capacity Usage Ratio"
        >
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                {data && (
                    <ReactApexChart
                        options={options}
                        height={700}
                        style={{
                            width: '100%',
                        }}
                        series={[{
                            name: 'Total Number of Trucks',
                            data: chartData.series,
                        }]}
                        type="bar"
                    />
                )}
            </StatusHandler>
        </Card>
    )
}

export default TruckCapacity
