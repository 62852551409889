import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        fullHeight: {
            minHeight: '100%',
        },
        gridCardWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateColumns: 'repeat(2, 1fr) 2fr',
        },
        gridWrapper: {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
        documentation: {
            gridRowEnd: 'span 3',
        },
        comment: {
            gridRowEnd: 'span 3',
        },
    }
})

export default useStyles
