import {
    LeaseType,
} from 'app/types/enums'

import {
    DeliveryServiceType, TransportModeRoad,
} from '../../orders.types'

export enum PositioningSelection {
    DEFAULT = 'DEFAULT',
    BACKUP = 'BACKUP',
    OTHER = 'OTHER'
}

export type PositioningSetupType = {
    version: number,
    mawbNumber: string,
    hawbNumber: string,
    transportModeForwarder: TransportModeRoad,
    transportModeRoad: TransportModeRoad,
    freightForwarder: {
        id: number,
    },
    numberOfTrucks: number,
    numberOfVans: number,
    leaseType: LeaseType,
    deliveryServiceType: DeliveryServiceType,
    orderReceived: string,
    originServiceCenter: {id: number},
    originServiceCenterContacts: {id: number}[],
    forwarderPositioning: {id: number},
    forwarderPositioningContacts: {id: number}[],
    customerReference: string,
    personInCharge: string,
    originServiceCenterSelection: PositioningSelection,
    forwarderPositioningSelection: PositioningSelection,
    estimatedPickup: string,
    laneId: number,
}
