import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
        },
        tableWrapper: {
            height: '33%',
            paddingBottom: theme.spacing(4),
        },
        headerTitle: {
            color: theme.palette.secondary[900],
            fontWeight: theme.typography.h1.fontWeight,
            fontSize: theme.typography.h1.fontSize,
            borderBottom: `1px solid ${theme.palette.primary[200]}`,
            paddingBottom: theme.spacing(1.5),
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        actionWrapper: {
            display: 'flex',
        },
    }
})

export default useStyles
