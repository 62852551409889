import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    Currency,
    AdditionalFeesStatus, PricingStatus,
} from 'app/types/enums'

import LeaseFeesInputs from '../../TableFees/Inputs/LeaseFeesInputs'
import {
    AlLeasePricingFields,
} from '../AlLeasePricing.types'

const alLeasePricingFields = ({
    getAllowedValues,
    originalStatus,
    exists,
    isGenericAccount,
}): DTOCardFormField<AlLeasePricingFields>[] => {
    const disabled = [
        PricingStatus.VALID,
        PricingStatus.CANCELLED,
    ].includes(originalStatus)

    const isGenericAccountField: NestedField<AlLeasePricingFields> = {
        name: 'isGenericAccount',
        componentName: 'Checkbox',
        labelKey: 'AlLeasePricing.isGenericAccount',
        className: 'isGenericAccount',
        disabled,
    }

    return [
        {
            title: 'General Information',
            className: 'generalInformation',
            fields: [
                {
                    id: 'column1',
                    fields: !isGenericAccount
                        ? [
                            isGenericAccountField,
                            {
                                name: 'account',
                                componentName: 'AccountSelector',
                                labelKey: 'AccountLane.accountCompanyName',
                                required: true,
                                disabled,
                            },
                        ] : [
                            isGenericAccountField,
                            {
                                name: 'excludedAccounts',
                                componentName: 'MultipleAccountSelector',
                                labelKey: 'AlLeasePricing.excludedAccounts',
                            },
                        ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'status',
                            labelKey: 'AdditionalFeesCreateDto.additionalFeesStatus',
                            originalStatus,
                            componentName: 'StatusTransitionSelector',
                            moduleName: 'generalPricingStatus',
                            useDropdown: true,
                            allowedValues: getAllowedValues(AdditionalFeesStatus),
                            required: true,
                            disabled: !exists,
                        },
                        {
                            name: 'currency',
                            componentName: 'EnumSelector',
                            labelKey: 'LocationDefaultTaxCode.currency',
                            allowedValues: getAllowedValues(Currency),
                            useDropdown: true,
                            required: true,
                            disabled,
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'validFrom',
                            componentName: 'DateSelect',
                            labelKey: 'AdditionalFeesCreateDto.validFrom',
                            required: true,
                            disabled,
                        },
                        {
                            name: 'comment',
                            componentName: 'LongText',
                            labelKey: 'AdditionalFeesDto.comment',
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [{
                        name: 'validTo',
                        componentName: 'DateSelect',
                        labelKey: 'AdditionalFeesCreateDto.validTo',
                        required: true,
                    }],
                },
            ],
        },
        {
            title: 'Lease Fees',
            className: 'leaseFees',
            fields: [{
                name: 'leaseFees',
                componentName: 'TableFees',
                inputs: LeaseFeesInputs,
                disabled,
            }],
        },
    ]
}

export default alLeasePricingFields
