import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import request from '../customerTransport.request'
import {
    BillingInformationType,
    BillingInformationResponse,
} from '../../orders.types'

const useGetBillingInformation = (
    orderNumber: string,
): BillingInformationResponse => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<BillingInformationType>({
        key: RequestKeys.getBillingInformation,
        params: {
            orderNumber,
        },
        requestFunc: RequestFn.getEntity(request.BillingInformation.requestFn),
        enabled: Boolean(orderNumber),
    })

    return {
        billingInformation: data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetBillingInformation
