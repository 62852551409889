import queryString from 'query-string'

import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    StepConfiguration: {
        requestFn: ({
            stepTypeDetailed,
            stepProgress,
        }) => {
            return {
                url: queryString.stringifyUrl({
                    url: 'order/step-configuration',
                    query: {
                        stepTypeDetailed,
                        stepProgress,
                    },
                }),
            }
        },
    },
    GetLocationById: {
        requestFn: ({
            id,
        }) => {
            return {
                url: `location/${id}`,
            }
        },
    },
}

export default config
