import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            height: '100%',
            position: 'relative',
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'unset',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        tableHeaderWrapper: {
            width: `calc(100% - ${theme.spacing(4)}) !important`,
            padding: theme.spacing(2),
            position: 'relative',
            '&&': {
                position: 'relative',
            },
        },
        backToTableText: {
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: theme.palette.primary.main,
            padding: theme.spacing(2),
        },
        icon: {
            height: '0.6em',
            width: '0.7em',
        },
    }
})

export default useStyles
