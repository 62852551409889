import {
    ReactGrid,
} from '@silevis/reactgrid'

export default class ReactGridExtended extends ReactGrid {
    componentDidMount() {
        super.componentDidMount()
        super.setState({
            contextMenuPosition: {
                top: -1, left: -1,
            },
        })
    }
}
