import {
    LeaseType,
    OrderTemperatureRange,
} from 'app/types/enums'

import {
    DTOCardFormField, FieldInCard,
} from 'app/types/form.types'
import {
    NestedKeyOf,
} from 'app/types/utils.types'
import {
    ModificationCustomerOrder,
} from './dataUpdate.types'

export type ModificationCustomerOrderFields = NestedKeyOf<ModificationCustomerOrder>

const getFields = ({
    getAllowedValues,
    leaseType,
}: {
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    leaseType: LeaseType,
}): DTOCardFormField<ModificationCustomerOrderFields>[] => {
    const freightForwarderField: FieldInCard<ModificationCustomerOrderFields>[] = [{
        name: 'freightForwarder',
        componentName: 'CompanyObjectSelector',
        required: true,
        labelKey: 'OrderByNumberView.freightForwarder',
    }]
    const orderInfoCard: DTOCardFormField<ModificationCustomerOrderFields> = {
        id: 'orderInfo',
        className: 'orderInfo',
        title: 'Order Info',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'orderNumber',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'OrderByNumberView.orderNumber',
                    },
                    {
                        name: 'temperatureRange',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(OrderTemperatureRange),
                        disabled: true,
                        labelKey: 'OrderByNumberView.temperatureRange',
                    },
                    {
                        name: 'createdBy',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'OrderByNumberView.createdBy',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'customerReference',
                        componentName: 'InputSingleline',
                        required: true,
                        labelKey: 'OrderByNumberView.customerReference',
                    },
                    {
                        name: 'orderReceived',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'OrderByNumberView.orderReceived',
                    },
                    {
                        name: 'personInCharge',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderByNumberView.personInCharge',
                        disabled: true,
                    },
                ],
            },
        ],
    }
    const billingInformationCard: DTOCardFormField<ModificationCustomerOrderFields> = {
        id: 'billingInformation',
        className: 'billingInformation',
        title: 'Billing Information',
        fields: [
            {
                id: 'column3',
                fields: [
                    {
                        name: 'commentForBillingOps',
                        componentName: 'LongText',
                        labelKey: 'OrderByNumberView.commentForBillingOps',
                    },
                    {
                        name: 'leaseStartTimestamp',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'requiresManualBillingReviewOps',
                        componentName: 'Checkbox',
                        labelKey: 'OrderByNumberView.requiresManualBillingReviewOps',
                    },
                    ...leaseType === LeaseType.AIRLINE_LEASE ? freightForwarderField as [] : [],
                ],
            },
        ],
    }

    return [
        orderInfoCard,
        billingInformationCard,
    ]
}

export default getFields
