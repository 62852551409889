import {
    useMemo, useCallback,
} from 'react'
import moment from 'moment'
import {
    dateMask,
} from 'app/utils/date'
import dateTimeMask from 'app/utils/date/dateTimeMask'
import {
    countFurtherMonths, countPreviousMonths, createDefaultValues, parseForecastData,
} from '../../Forecast.utils'
import {
    ForecastSpreadsheetDataType, ForecastType,
} from '../../Forecast.types'

export default function useConvertToSpreadsheetData() {
    const forecastFields = useMemo(() => {
        return countFurtherMonths()
    }, [])

    const actualDataFields = useMemo(() => {
        return countPreviousMonths()
    }, [])

    const convertToSpreadsheetData = useCallback(
        (data: ForecastType[]): ForecastSpreadsheetDataType[] => {
            return (data || []).reduce((acc, item) => {
                const changedOn = moment(item.changedOn, dateTimeMask)

                return [
                    ...acc,
                    {
                        ...item,
                        opportunityCollaboratorsUserName: (item.opportunityCollaboratorsUserName || []).join('; '),
                        changedOn: changedOn.isValid() ? changedOn.format(dateMask) : '',
                        ...(item.forecasts || []).reduce(
                            parseForecastData(), createDefaultValues(forecastFields),
                        ),
                        ...(item.actuallyShippedContainers || []).reduce(
                            parseForecastData(), createDefaultValues(actualDataFields),
                        ),
                    },
                ]
            }, []) as ForecastSpreadsheetDataType[]
        }, [
            actualDataFields,
            forecastFields,
        ],
    )

    return {
        convertToSpreadsheetData,
        forecastFields,
        actualDataFields,
    }
}
