import {
    useMemo,
} from 'react'
import {
    FilterNotifications,
} from '../../OrderSteps.types'

const useGetFilterNotifications = ({
    stepTypeDetailed,
    stepProgress,
    orderId,
}): FilterNotifications => {
    return useMemo(() => {
        const sentOnStepProgress = stepProgress.find(({
            substepCompleted,
        }) => {
            return !substepCompleted
        })?.substepName

        return {
            orderId: [orderId],
            isSentOnStepTypeDetailed: [stepTypeDetailed],
            isSentOnStepProgress: sentOnStepProgress && [sentOnStepProgress],
        }
    }, [
        orderId,
        stepProgress,
        stepTypeDetailed,
    ])
}

export default useGetFilterNotifications
