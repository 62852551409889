/* global google */
import loadScript from 'app/utils/loadScript'
import {
    MapConfig, MarkerConfig, PolylineConfig,
} from './googleMap.types'

const {
    REACT_APP_GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
} = process.env

const URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}`
const CACHE_MAP = new Map()

const getInstanceMap = (elm, mapConfig) => {
    if (CACHE_MAP.has(elm)) {
        return CACHE_MAP.get(elm)
    }

    CACHE_MAP.set(elm, new google.maps.Map(elm, mapConfig))

    return CACHE_MAP.get(elm)
}

const drawMap = (
    elm: JSX.Element,
    mapConfig: MapConfig,
    polylineConfig: PolylineConfig,
    markerConfig: MarkerConfig[],
    onError: (err: any) => void,
): void => {
    loadScript(URL).then(() => {
        const map = getInstanceMap(elm, mapConfig)

        const bounds = new google.maps.LatLngBounds()

        if (polylineConfig?.path.length) {
            const flightPath = new google.maps.Polyline(polylineConfig)

            flightPath.setMap(map)

            if (polylineConfig.path.length > 1) {
                polylineConfig.path.forEach((latLng) => {
                    bounds.extend(latLng)
                })

                map.fitBounds(bounds)
            }
        }

        if (markerConfig) {
            markerConfig.forEach((item) => {
                const marker = new google.maps.Marker(item)

                marker.setMap(map)
            })
        }
    })
        .catch(onError)
}

export default drawMap
