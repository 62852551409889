import React, {
    useMemo, useState,
} from 'react'
import FormWithControls from 'app/shared-components/FormWithControls'
import {
    RequestConfig,
} from 'app/types/request.types'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useScrollToForm from 'app/hooks/useScrollToForm'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    LaneDetails,
} from 'app/Apps/Pricing/Pricing.types'
import useHistoryNavigation from 'app/hooks/useHistoryNavigation'

import laneRequests from '../Lane.requests'
import useStyles from './LaneForm.styles'
import laneFormFields, {
    generalColumn,
} from './LaneForm.fields'
import {
    LaneStatus,
} from '../../../../../types/enums'

const defaultProps = {
    data: {} as LaneDetails,
    onSuccess: () => {},
    onCancel: () => {},
    customButtons: undefined,
}

const LaneForm = ({
    data,
    onCancel,
    onSuccess,
    requestParam,
    customButtons,
}: {
    data?: LaneDetails,
    onSuccess?: (...args: any[]) => void,
    onCancel?: (...args: any[]) => void,
    requestParam: RequestConfig,
    customButtons?: JSX.Element[]
}) => {
    const {
        action,
    } = useHistoryNavigation()
    const getAllowedValues = useEnumValues()
    const {
        classes,
    } = useStyles()
    const [
        updatedValues,
        setUpdatedValues,
    ] = useState<LaneDetails>(null)
    const scrollToForm = useScrollToForm(String(data?.id), action === 'copy')

    const exists = Boolean(data?.id)

    const formValue = useMemo(() => {
        return {
            ...data,
            ...updatedValues,
        }
    }, [
        data,
        updatedValues,
    ])

    const isImplementedStatus = useMemo(() => {
        return exists && data?.laneStatus === LaneStatus.IMPLEMENTED
    }, [
        data?.laneStatus,
        exists,
    ])

    const displayedFields = useMemo(() => {
        if (exists) {
            return [
                ...laneFormFields(isImplementedStatus),
                generalColumn(data?.laneStatus, getAllowedValues, isImplementedStatus),
            ]
        }
        return laneFormFields(isImplementedStatus)
    }, [
        exists,
        isImplementedStatus,
        data?.laneStatus,
        getAllowedValues,
    ])

    const fields = useFieldsWithClassName(displayedFields, classes)

    return (
        <div
            className={classes.root}
            ref={scrollToForm}
        >
            <FormWithControls
                name={laneRequests.domainName}
                fields={fields}
                value={formValue}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                setExternalValue={setUpdatedValues}
                onCancel={onCancel}
                onSuccess={onSuccess}
                exists={exists}
                requestParams={requestParam}
                customButtons={customButtons}
                cancelEnabled
            />
        </div>
    )
}

LaneForm.defaultProps = defaultProps

export default LaneForm
