import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        link: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.secondary[600],
            cursor: 'pointer',
        },
        iconWrapper: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.secondary[600],
            padding: '3px 10px 3px 2px',
            fontSize: theme.typography.body1.fontSize,
        },
        'iconWrapper &:hover': {
            textDecoration: 'underline',
        },
        icon: {
            color: theme.palette.secondary[600],
            marginRight: '5px',
        },
    }
})

export default useStyles
