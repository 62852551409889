import React from 'react'

import {
    LocationSelectorDefaultProps,
    LocationObjectSelectorProps,
} from './types/LocationSelector.types'
import BaseLocationSelector from './BaseLocationSelector'
import {
    TypeaheadObjectContainer,
} from '../Typeahead'

const LocationObjectDataSelector = ({
    value,
    onChange,
    name,
    title,
    disabled,
    isRequired,
    className,
    classNames,
    filterLocationType,
    filterByCompanyId,
    isApprovedSubContractor,
    'data-testid': dataTestId,
    validate,
    setErrors,
    allowedLocationTypes,
    isShippingLocation,
}: LocationObjectSelectorProps) => {
    return (
        <BaseLocationSelector
            searchComponent={TypeaheadObjectContainer}
            value={value?.id}
            onChange={onChange}
            name={name}
            title={title}
            disabled={disabled}
            isRequired={isRequired}
            className={className}
            classNames={classNames}
            filterByCompanyId={filterByCompanyId}
            filterLocationType={filterLocationType}
            isApprovedSubContractor={isApprovedSubContractor}
            data-testid={dataTestId}
            validate={validate}
            setErrors={setErrors}
            allowedLocationTypes={allowedLocationTypes}
            isShippingLocation={isShippingLocation}
        />
    )
}

LocationObjectDataSelector.defaultProps = LocationSelectorDefaultProps

export default LocationObjectDataSelector
