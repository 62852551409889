import {
    ComponentTableCollection,
} from 'app/Apps/DomainObject/ComponentMapper/ComponentTableCollection.types'

import {
    ComponentsFormCollection,
} from './ComponentsFormCollection'

/*  This object should contain all component that we will use as componentName in:
 SkyNetTable, Richtable or as Form fields  */
const AllCellOrFieldComponents = {
    ...ComponentTableCollection,
    ...ComponentsFormCollection,
}

type ComponentsMap = typeof AllCellOrFieldComponents
type ComponentsNames = keyof ComponentsMap

const getComponentByName = <K extends ComponentsNames>(key: K): ComponentsMap[K] => {
    return AllCellOrFieldComponents[key]
}

export default getComponentByName
