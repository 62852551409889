import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        buttonHeader: {
            backgroundColor: theme.palette.primary.light,
            height: 161,
        },
    }
})

export default useStyles
