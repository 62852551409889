import React, {
    useCallback,
    useMemo,
} from 'react'
import get from 'lodash/get'

import useCheckTransportPlanerRoles from 'app/Apps/TransportPlanner/hooks/useCheckTransportPlanerRoles'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    ProductType,
} from 'app/types/enums'

import availableUpdateMapContainerTypes from './config/availableUpdateMapContainerTypes'
import ContainerTypeSelectionItem from './ContainerTypeSelectionItem'
import primeMapContainerTypes from './config/primeMapContainerTypes'
import plannedUpdateMapContainerTypes from './config/plannedUpdateMapContainerTypes'
import amountCoolingGelOptionsMap from './ContainerTypeSelectionItem/amountCoolingGelOptions'
import useStyles from './ContainerTypeSelection.style'
import useGetProductTypes from '../../hooks/useGetProductTypes'
import {
    SelectedContainer,
} from '../simulation.types'

type Props = {
    value?: SelectedContainer,
    onChange: (newVal: SelectedContainer) => void
}

const defaultProps = {
    value: null,
}

const ContainerTypeSelection = ({
    value,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        isRolePlannedUpdate,
        isRoleAvailableUpdate,
        isRolePrimeUpdate,
    } = useCheckTransportPlanerRoles()

    const productTypes = useGetProductTypes()
    const allowedValuesProductType = getAllowedValues(ProductType)

    const listContainerTypes: {
        productType: ProductType,
        imgSrc: string,
    }[] = useMemo(() => {
        let listContainers = []

        if (isRolePlannedUpdate) {
            listContainers = [...plannedUpdateMapContainerTypes]
        }

        if (!isRolePlannedUpdate && isRoleAvailableUpdate) {
            listContainers = [...availableUpdateMapContainerTypes]
        }

        if (isRolePrimeUpdate) {
            listContainers = [
                ...listContainers,
                ...primeMapContainerTypes,
            ]
        }

        return listContainers
    }, [
        isRoleAvailableUpdate,
        isRolePlannedUpdate,
        isRolePrimeUpdate,
    ])

    const handleChange = useCallback((item: SelectedContainer, isFormChange = false): void => {
        if (item.productType === value?.productType && !isFormChange) {
            onChange(null)
            return
        }

        onChange({
            productType: item.productType,
            amountCoolingGel: item.amountCoolingGel,
            tempRange: item.tempRange,
        })
    }, [
        value,
        onChange,
    ])

    const getAmountCoolingGel = useCallback((
        reicingEnabled: boolean,
        productType: ProductType,
    ): number => {
        return reicingEnabled
            ? get(value, 'amountCoolingGel', amountCoolingGelOptionsMap[productType].defaultAmountCoolingGelValue)
            : undefined
    }, [value])

    return (
        <>
            <ul
                className={classes.container}
            >
                {
                    listContainerTypes.map((listContainerType) => {
                        const container = productTypes?.find(({
                            productType,
                        }) => {
                            return productType === listContainerType.productType
                        })

                        if (!container) return null

                        return (
                            <ContainerTypeSelectionItem
                                key={`Container-selection-${listContainerType.productType}`}
                                value={{
                                    ...listContainerType,
                                    ...container,
                                    title: `SkyCell ${allowedValuesProductType[container.productType]}`,
                                    amountCoolingGel: getAmountCoolingGel(
                                        container.reicingEnabled,
                                        container.productType,
                                    ),
                                }}
                                selectedProductType={value?.productType}
                                onChange={handleChange}
                            />
                        )
                    })
                }
            </ul>
        </>
    )
}

ContainerTypeSelection.defaultProps = defaultProps

export default ContainerTypeSelection
