import React from 'react'
import noop from 'lodash/noop'

import Typeahead from 'app/shared-components/Typeahead'
import userRequests from 'app/Apps/ContactManagement/Users/Users.request'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import useGetUser from 'app/Apps/ContactManagement/Users/hooks/useGetUser'

type Props = {
    title?: string,
    name?: string,
    onChange?: (value: any, ...params: any[]) => void,
    value?: string | number,
    disabled?: boolean,
    className?: string,
    required?: boolean,
}

const defaultProps = {
    title: 'Users',
    name: '',
    onChange: noop,
    value: undefined,
    disabled: false,
    className: '',
    required: false,
}

export const userParser = (users) => {
    return users.map(({
        id,
        userName,
    }) => {
        return {
            label: userName,
            value: id,
            id,
        }
    })
}

const UserSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
}: Props) => {
    const loadOptions = useDelayedLoadOptions({
        url: userRequests.all.url, convertion: userParser,
    })

    const {
        data: labelData,
    } = useGetUser(value)

    return (
        <Typeahead
            name={name}
            title={title}
            className={className}
            onChange={onChange}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="userName"
            value={value}
            disabled={disabled}
            isRequired={required}
        />
    )
}

UserSelector.defaultProps = defaultProps

export default UserSelector
