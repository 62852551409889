import React from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import ChangeHistory from 'app/shared-components/ChangeHistory'

import config from '../opportunities.request'

const OpportunitiesChangeHistory = ({
    id,
}: {id: number}) => {
    return (
        <ChangeHistory
            id={id}
            key={RequestKeys.getOpportunityModifications}
            config={config.modifications}
            configName="OpportunityHistoryTable"
            name="OpportunityChangeHistory"
            labelPrefixForChangedColumn={['Opportunity']}
        />
    )
}

export default OpportunitiesChangeHistory
