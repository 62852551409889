import {
    useCallback,
} from 'react'
import {
    CellChange,
} from '@silevis/reactgrid'
import {
    Domain, ExtendedCellTypes,
} from '../SkyNetSpreadSheet.types'
import useSetMappedResults from './useSetMappedResults'
import useOnChange from './useOnChange'
import {
    getCellValue,
} from '../getRows'
import {
    SpreadsheetMappingResponse,
} from '../SkyNetSpreadSheet.requests'

export default (configId: string) => {
    const addMappedDataToDomainConfig = useSetMappedResults({
        configId,
    })

    const onCellChange = useOnChange()

    return useCallback(async (
        newValues: CellChange<ExtendedCellTypes>[],
        mappedResult: Map<Domain, SpreadsheetMappingResponse> | undefined,
        updatedData: Record<string, any>[],
    ) => {
        let resultData = [...updatedData]

        await Promise.all(newValues.map(async ({
            columnId,
            newCell,
            rowId,
        }) => {
            let cellResult = newCell

            if ('undeterminedValue' in newCell && 'domain' in newCell && newCell.undeterminedValue) {
                if (mappedResult?.size > 0) {
                    addMappedDataToDomainConfig(mappedResult, newCell.name)
                }

                const result = (mappedResult.get(newCell?.domain) || []).find(({
                    label,
                }) => {
                    return label === newCell.undeterminedValue
                })

                cellResult = {
                    ...newCell,
                    undeterminedValue: null,
                    text: result?.label,
                    id: result?.id,
                    selectedValue: result,
                    value: result?.id,
                }
            }

            const updatedValue = getCellValue(cellResult)

            resultData[rowId][columnId] = updatedValue
            resultData = await onCellChange({
                newData: resultData, cell: newCell, newValue: updatedValue, currentRowId: rowId,
            })

            return Promise.resolve(resultData)
        }))

        return resultData
    }, [
        addMappedDataToDomainConfig,
        onCellChange,
    ])
}
