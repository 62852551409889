import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
        },
        icon: {
            paddingLeft: theme.spacing(1),
            cursor: 'pointer',
            alignSelf: 'end',
        },
    }
})

export default useStyles
