import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'

import MultipleSelect from './MultipleSelect'

const propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.array),
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
}

const defaultProps = {
    options: [],
    title: '',
    name: '',
    value: [],
    disabled: false,
    required: false,
}

const MultipleSelectContainer = (props) => {
    const {
        onChange,
        options,
        title,
        name,
        value,
        disabled,
        required,
    } = props

    const handleChange = useCallback(({
        target,
    }) => {
        const filtered = options.filter((option) => {
            return target.value.includes(option[0])
        })

        onChange(filtered, {
            target: {
                name,
                value: filtered,
            },
        })
    }, [
        name,
        onChange,
        options,
    ])

    return (
        <MultipleSelect
            options={options}
            title={title}
            value={value}
            name={name}
            disabled={disabled}
            required={required}
            onChange={handleChange}
        />
    )
}

MultipleSelectContainer.propTypes = propTypes
MultipleSelectContainer.defaultProps = defaultProps

export default MultipleSelectContainer
