import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    ForecastData,
} from '../../../Forecast.types'

import requests from '../../../Forecast.requests'

const useGetAccount = (id) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<ForecastData>({
        key: RequestKeys.getCurrentMonthForecast,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.getCurrentMonth),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetAccount
