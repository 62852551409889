import {
    SimulationStep, SimulationStepRequest,
    SimulationStepType,
} from '../simulation.types'

const getFilteredSteps = (steps: SimulationStep[]): SimulationStepRequest[] => {
    if (!steps) {
        return []
    }

    return steps.filter(({
        type,
        ambientTemperature,
        amountCoolingGel,
        palletTemperature,
        durationMinutes,
        durationHours,
        palletWeight,
    }) => {
        if (type === SimulationStepType.LOAD_PALLET) {
            return palletTemperature && palletWeight
        }
        if (type === SimulationStepType.RE_ICING) {
            return amountCoolingGel
        }
        return (ambientTemperature || ambientTemperature === 0)
            && (durationHours || durationMinutes)
    })
        .map(({
            ambientTemperature,
            amountCoolingGel,
            palletTemperature,
            durationMinutes,
            durationHours,
            palletWeight,
            location,
            type,
        }) => {
            if (type === SimulationStepType.LOAD_PALLET) {
                return {
                    palletTemperature,
                    palletWeight,
                    location,
                    palletLoaded: true,
                    type,
                }
            }
            if (type === SimulationStepType.RE_ICING) {
                return {
                    amountCoolingGel,
                    location,
                    type,
                }
            }
            return {
                ambientTemperature,
                durationMinutes,
                durationHours,
                location,
                type,
            }
        })
}

export default getFilteredSteps
