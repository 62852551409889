import {
    useCallback,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'
import {
    useDispatch,
} from 'react-redux'
import {
    omit,
} from 'lodash'

import barcodeServiceApi from 'app/services/barcodeServiceApi'
import downloadDocument from 'app/utils/downloadDocument'
import {
    failedToLoadSaveForm,
    startToSaveForm,
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'

type Variables = Record<string, any> & {
    label: string,
}

const usePrintContainerLabels = ({
    loadMessage,
}: {
        loadMessage: string,
}) => {
    const token = useJWTToken()
    const dispatch = useDispatch()
    const onError = useCallback((error) => {
        const enc = new TextDecoder('utf-8')

        dispatch(
            failedToLoadSaveForm({
                id: 'Container',
                errors: error.response ? JSON.parse(enc.decode(error.response.data)) : error,
                statusCode: error.response?.status,
            }),
        )
    }, [dispatch])
    const onSuccess = useCallback((
        response,
        variables: Variables,
    ) => {
        downloadDocument(
            response,
            variables.label,
        )
        dispatch(
            successedToSaveForm({
                id: 'Container',
                title: 'The label(s) have been generated and downloaded successfully',
            }),
        )
    }, [dispatch])

    return useMutation<unknown, unknown, Variables, unknown>({
        mutationFn: (params) => {
            dispatch(
                startToSaveForm({
                    id: 'Container',
                    title: loadMessage,
                }),
            )
            return barcodeServiceApi({
                method: 'GET',
                token,
                responseType: 'arraybuffer',
                params: omit(params, 'label'),
            })
        },
        onSuccess,
        onError,
    })
}

export default usePrintContainerLabels
