import {
    LocationType,
} from 'app/types/enums'
import {
    FieldInCard,
    FormPart,
} from 'app/types/form.types'

import {
    DeliveryServiceType,
    Lane,
    TransportModeRoad,
} from '../../lanes.types'

const originFields = ({
    isDeliveryRoad,
    getAllowedValues,
}: {
    isDeliveryRoad: boolean,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
}): FormPart<keyof Lane> => {
    const transportModeRoadField: FieldInCard<keyof Lane>[] = [{
        name: 'transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        disabled: true,
        labelKey: 'LaneOpsInformationDto.transportModeRoad',
    }]

    return {
        title: 'Origin',
        className: 'origin',
        fields: [
            {
                name: 'originAirport',
                componentName: 'LocationObjectSelector',
                filterLocationType: LocationType.AIRPORT,
                disabled: true,
                labelKey: 'LaneOpsInformationDto.originAirport',
            },
            {
                name: 'handoverPoint',
                componentName: 'LocationExpandedViewObjectSelector',
                disabled: true,
                labelKey: 'LaneOpsInformationDto.handoverPoint',
            },
            {
                name: 'handoverPointContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'LaneOpsInformationDto.handoverPointContacts',
                required: true,
            },
            {
                name: 'deliveryServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(DeliveryServiceType),
                disabled: true,
                labelKey: 'LaneOpsInformationDto.deliveryServiceType',
            },
            ...isDeliveryRoad ? transportModeRoadField : [],
        ],
    }
}

export default originFields
