import React from 'react'

import LaneRequests from '../Lane.requests'
import useLaneGroupContext from '../../hooks/useLaneGroupContext'
import LaneForm from '../LaneForm'

const defaultProps = {
    onCancel: () => {},
    onSuccess: () => {},
}

const LaneCreate = ({
    onSuccess, onCancel,
}: { onSuccess?: (...args: any[]) => void, onCancel?: (...args: any[]) => void }) => {
    const {
        laneGroupId,
    } = useLaneGroupContext()

    return (
        <LaneForm
            onSuccess={onSuccess}
            onCancel={onCancel}
            requestParam={LaneRequests.create.request({
                laneGroupId,
            })}
        />
    )
}

LaneCreate.defaultProps = defaultProps

export default LaneCreate
