import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../serviceCenters.request'
import {
    AllowedProductTypes,
    ForecastAvailability,
} from '../serviceCenters.types'

const useGetAvailability = ({
    serviceCenter,
    productType,
}: {
    serviceCenter: {id: number},
    productType: AllowedProductTypes,
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<ForecastAvailability>({
        key: RequestKeys.getAvailability,
        params: {
            params: {
                serviceCenter,
                productType,
            },
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetAvailability.requestFn),
        enabled: Boolean(serviceCenter?.id && productType),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetAvailability
