/* eslint-disable no-void */
import React, {
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react'
import Keycloak from 'keycloak-js'

import AuthContext from './AuthContext'
import {
    INITIAL_USER_INFO,
    TIME_IN_MS,
    INITIAL_EXTENDED_PROFILE,
} from './const'
import getUserInfo from './getUserInfo'
import useGetUserRoles from '../hooks/useGetUserRoles'
import useGetMicrosoftToken from '../hooks/useGetMicrosoftToken'
import {
    ExtendedProfile,
    MicrosoftData,
    KeycloakProfile,
    TKeycloak,
    UserInfo,
} from './Auth.types'

const {
    REACT_APP_REALM: realm,
    REACT_APP_RESOURCE: clientId,
    REACT_APP_AUTH_SERVER_URL: authUrl,
} = process.env

type Props = {
    children: ReactElement
}

function AuthProvider({
    children,
}: Props) {
    // TODO: refactor to useReducer when moving to common package
    const [
        token,
        setToken,
    ] = useState<string>(undefined)
    const [
        timeOutId,
        setTimeOutId,
    ] = useState<NodeJS.Timeout | null>(null)
    const [
        keycloak,
        setKeycloak,
    ] = useState<TKeycloak | null>(null)
    const [
        status,
        setStatus,
    ] = useState<string>('INIT')
    const [
        extendedProfile,
        setExtendedProfile,
    ] = useState<ExtendedProfile>(INITIAL_EXTENDED_PROFILE)
    const [
        microsoftData,
        setMicrosoftData,
    ] = useState<MicrosoftData>(null)

    const getUserRoles = useGetUserRoles()
    const getMicrosoftToken = useGetMicrosoftToken()

    useEffect(() => {
        const initKeycloak = async () => {
            try {
                setStatus('PENDING')

                const lKeycloack: TKeycloak = new Keycloak({
                    url: authUrl ?? '',
                    realm: realm ?? '',
                    clientId: clientId ?? '',
                }) as unknown as TKeycloak

                if (lKeycloack) {
                    await lKeycloack.init({
                        onLoad: 'login-required',
                    })

                    setKeycloak(lKeycloack)
                    const {
                        token: lToken,
                    } = lKeycloack

                    setToken(lToken)
                    setStatus('SUCCESS')
                } else {
                    setStatus('FAILURE')
                }
            } catch (error) {
                global.console.log('error on create Keycloak', error)
                setStatus('FAILURE')
            }
        }

        void initKeycloak()
    }, [])

    useEffect(() => {
        if (keycloak) {
            const {
                tokenParsed,
            } = keycloak
            const {
                exp,
            } = tokenParsed
            const timeout = exp * TIME_IN_MS.second - Date.now()

            if (timeOutId) {
                clearTimeout(timeOutId)
            }

            const newTimeOutId = setTimeout(() => {
                keycloak
                    .updateToken(TIME_IN_MS.minute * 5)
                    .then((refreshed) => {
                        if (refreshed) {
                            const {
                                token: lToken,
                            } = keycloak

                            setToken(lToken)
                        }
                    })
                    .catch((err) => {
                        global.console.log('error on update token', err)
                        keycloak.logout()
                    })
            }, timeout)

            setTimeOutId(newTimeOutId)
        }
        // Warning!!! timeOutId - don't put into deps - infinite refresh
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        keycloak,
        token,
    ])

    useEffect(() => {
        if (keycloak && !extendedProfile.email) {
            const loadProfile = async () => {
                try {
                    const profile: KeycloakProfile = await keycloak.loadUserProfile()
                    const {
                        tokenParsed,
                    } = keycloak
                    const {
                        groups: assignedRoles = [],
                    } = tokenParsed

                    const skyCoreRoles = await getUserRoles(token)
                    const microsoftToken = await getMicrosoftToken({
                        token,
                        authServerUrl: authUrl,
                        realm,
                    })

                    setMicrosoftData(microsoftToken)
                    setExtendedProfile({
                        ...profile,
                        assignedRoles,
                        roles: skyCoreRoles as string[],
                    })
                } catch (error) {
                    global.console.log('error loading user profile', error)
                    setExtendedProfile(INITIAL_EXTENDED_PROFILE)
                }
            }

            void loadProfile()
        }
    }, [
        extendedProfile.email,
        getMicrosoftToken,
        getUserRoles,
        keycloak,
        token,
    ])

    const userInfo: UserInfo = useMemo(() => {
        if (!extendedProfile.email) {
            return INITIAL_USER_INFO
        }

        return getUserInfo(extendedProfile)
    }, [extendedProfile])

    // eslint-disable-next-line no-nested-ternary
    return status === 'SUCCESS' ? (
        <AuthContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                userInfo,
                token,
                logout: keycloak
                    ? keycloak.logout
                    : () => {
                        return null
                    },
                microsoftData,
            }}
        >
            {children}
        </AuthContext.Provider>
    ) : status === 'FAILURE' ? (
        <span>NO ACCESS</span>
    ) : null
}

export default AuthProvider
