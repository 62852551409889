import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        paper: {
            '&&': {
                width: theme.spacing(86),
                maxWidth: theme.spacing(86),
                position: 'static',
            },
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    }
})

export default useStyles
