import moment from 'moment'
import {
    monthYearMask, monthYearShortMask,
} from 'app/utils/date'
import {
    ACTUALS_MONTHS_QTY, FORECAST_MONTHS_QTY,
} from './Forecast.constants'

export const getKeyByMonthYear = ({
    month, year,
}: {month: number, year: number}, format = monthYearMask) => {
    const monthYearDate = moment({
        month, year,
    })

    const key = monthYearDate.format(monthYearShortMask)

    return [
        key,
        monthYearDate.format(format),
    ]
}

export const dataToSpreadsheetColumnsMap = (params: Record<string, any>) => {
    return ([
        key,
        value,
    ]) => {
        return {
            name: key,
            title: value,
            ...params,
        }
    }
}

export const countFurtherMonths = (q: number = FORECAST_MONTHS_QTY) => {
    return new Map<string, string>(
        Array.from(Array(q), (_, i) => {
            const date = moment().add(i + 1, 'M')
            const [
                key,
                monthYear,
            ] = getKeyByMonthYear({
                month: date.month(), year: date.year(),
            })

            return [
                key,
                monthYear,
            ]
        }),
    )
}

export const countPreviousMonths = (q: number = ACTUALS_MONTHS_QTY) => {
    return new Map<string, string>(
        Array.from(Array(q), (_, i) => {
            const date = moment().subtract(i, 'M')
            const [
                key,
                monthYear,
            ] = getKeyByMonthYear({
                month: date.month(), year: date.year(),
            })

            return [
                key,
                monthYear,
            ]
        }),
    )
}

export const parseForecastData = () => {
    return (acc, {
        month, year, units,
    }) => {
        const [key] = getKeyByMonthYear({
            month: month - 1, year,
        })

        return {
            ...acc,
            [key]: units,
        }
    }
}

export const createDefaultValues = (fields: Map<string, string>) => {
    return Array.from(fields).reduce((acc, [key]) => {
        return {
            ...acc,
            [key]: 0,
        }
    }, {})
}
