import simpleApi from 'app/services/simpleApi'

const request = {
    AssignmentToOpportunity: {
        mutationFn: ({
            data,
            token,
        }) => {
            return simpleApi({
                url: 'lane/assignment-to-opportunity',
                data,
                method: 'PATCH',
                token,
            })
        },
    },
}

export default request
