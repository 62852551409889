import {
    createSlice,
    createSelector,
} from '@reduxjs/toolkit'
import type {
    RootState,
} from 'app/store'

type TableControlsState = {
    showArchived?: boolean,
}

type TablesControlsState = {
    items: Record<string, TableControlsState>
}

const initialState: TablesControlsState = {
    items: {},
}

const TablesControlsSlice = createSlice({
    name: 'tablesControls',
    initialState,
    reducers: {
        toggleShowArchived(state, {
            payload: name,
        }) {
            const newValue = state.items[`${name}`]?.showArchived || false

            return {
                ...state,
                items: {
                    ...state.items,
                    ...{
                        [name]: {
                            showArchived: !newValue,
                        },
                    },
                },
            }
        },
        setShowArchived(state, {
            payload,
        }) {
            return {
                ...state,
                items: {
                    ...state.items,
                    ...{
                        [payload.name]: {
                            showArchived: !payload.value,
                        },
                    },
                },
            }
        },
        clearState(state, {
            payload: name,
        }: {payload: string}) {
            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                [name]: removed,
                ...rest
            } = state.items

            return {
                ...state,
                items: {
                    ...rest,
                },
            }
        },
    },
})

export const {
    toggleShowArchived,
    setShowArchived,
    clearState,
} = TablesControlsSlice.actions

const selectTablesControlsState = (state: RootState): TablesControlsState => {
    return state.tablesControls
}

const selectTableControlState = createSelector(
    [
        selectTablesControlsState,
        (state, name: string) => {
            return name
        },
    ],
    (state: TablesControlsState, name: string) => {
        return state.items[name]
    },
)

export const selectShowArchived = createSelector(
    [
        selectTableControlState,
        (state, name: string) => {
            return name
        },
    ],
    (state) => {
        return state?.showArchived
    },
)

export default TablesControlsSlice.reducer
