import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    LocationAreasFields,
} from '../LocationAreas.types'

const additionalFeesColumns: RichTableColumns<LocationAreasFields>[] = [
    {
        id: 'areaName',
        mapCellProps: 'areaName',
        headerProps: {
            children: 'Area Name',
        },
    },
    {
        id: 'latitude',
        mapCellProps: 'latitude',
        headerProps: {
            children: 'Latitude',
        },
    },
    {
        id: 'longitude',
        mapCellProps: 'longitude',
        headerProps: {
            children: 'Longitude',
        },
    },
    {
        headerProps: {
            children: 'Changed On',
        },
        id: 'changedOn',
        mapCellProps: 'changedOn',
    },
    {
        headerProps: {
            children: 'Changed By',
        },
        id: 'changedBy',
        mapCellProps: 'changedBy',
    },
]

export default additionalFeesColumns
