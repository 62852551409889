import React from 'react'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    ContainerNoteType,
    ContainerStatus,
} from 'app/types/enums'
import StatusHandler from 'app/shared-components/StatusHandler'

import ContainerNoteDetails from './ContainerNoteDetails'
import config from '../../ContainerNotes.request'

type ContainerNoteDetail = {
    attachments: any[]
    containerId: number,
    createdBy: string,
    createdOn: string,
    id: number,
    location: Location,
    locationChange: {
        newLocation: Location,
        oldLocation: Location,
    },
    loggerChange: Record<string, any>
    noteSubject: string,
    noteText: string,
    noteType: ContainerNoteType,
    statusChange: {
        newStatus: ContainerStatus,
        oldStatus: ContainerStatus,
    },
    systemId: string,
    validDateTimestamp: string
}

type Props = {
    name: string,
    rowProps: {
        id: number
    }
}

const ContainerNoteDetailsContainer = ({
    name,
    rowProps: {
        id,
    },
}: Props) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<ContainerNoteDetail>({
        key: RequestKeys.getContainerNoteDetails,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(id),
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContainerNoteDetails
                data={data}
                name={name}
            />
        </StatusHandler>
    )
}

export default ContainerNoteDetailsContainer
