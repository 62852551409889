import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    DryStorage,
} from '../serviceCenters.types'
import requestConfig from '../serviceCenters.request'

const useGetDryStorage = ({
    serviceCenter,
}: {
    serviceCenter: {id: number}
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<DryStorage>({
        key: RequestKeys.getDryStorage,
        params: {
            params: {
                serviceCenter,
            },
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetDryStorage.requestFn),
        enabled: Boolean(serviceCenter?.id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetDryStorage
