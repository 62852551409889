import React, {
    useState,
    useCallback,
    useEffect,
} from 'react'

import useGetFiles from './hook/useGetFiles'

import AttachmentType from './Attachment.types'
import Attachment from './Attachment'

type Props = {
    title?: string,
    name?: string,
    value?: AttachmentType[] | number[],
    onChange: (...args: any) => void,
    className?: string,
    upload?: boolean,
    disabled?: boolean,
    required?: boolean,
}

// DON'T USE THIS COMPONENT as componentName: 'Attachment',
// USE componentName: 'AttachmentsObjectSelector' instead

const defaultProps: Partial<Props> = {
    title: 'Attachments',
    name: '',
    value: [],
    className: '',
    upload: true,
    disabled: false,
    required: false,
}

const AttachmentContainer = (props: Props) => {
    const {
        title,
        name,
        value,
        onChange,
        className,
        upload,
        disabled,
        required,
    } = props

    const [
        state,
        setState,
    ] = useState({
        files: [],
        editable: true,
    })

    const {
        data,
        isSuccess,
    } = useGetFiles(value)

    const handleOnChange = useCallback((newDocumentId) => {
        const fileIds = [
            ...value,
            ...newDocumentId,
        ]

        onChange(fileIds, {
            target: {
                name,
                value: fileIds,
            },
        })
    }, [
        name,
        onChange,
        value,
    ])

    const onDelete = useCallback((fileToDeleteId) => {
        const files = state.files.filter(({
            id,
        }) => {
            return id !== fileToDeleteId
        })
        const ids = files.map(({
            id,
        }) => {
            return id
        })

        onChange(ids, {
            target: name,
            value: ids,
        }, {})
    }, [
        name,
        onChange,
        state.files,
    ])

    useEffect(() => {
        if (isSuccess) {
            setState({
                files: data?.items,
                editable: true,
            })
        }
    }, [
        data?.items,
        isSuccess,
        value,
    ])
    return (
        <Attachment
            title={title}
            onChange={handleOnChange}
            value={state.files}
            editable={state.editable && !disabled}
            onDelete={onDelete}
            name={name}
            className={className}
            upload={upload}
            disabled={disabled}
            required={required}
        />
    )
}

AttachmentContainer.defaultProps = defaultProps

export default AttachmentContainer
