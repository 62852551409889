import React, {
    useCallback,
} from 'react'
import clsx from 'clsx'

import {
    LocationObjectSelector,
} from 'app/shared-components/LocationSelector'
import useStyles from './RouteViaAirport.style'

type Event = {
    target: {
        name: string,
        value: {id: number},
    },
}

type Props = {
    onChange: (newVal: {id: number}[], event: {
        target: {
            name: string,
            value: {id: number}[],
        },
    }) => void,
    value?: {id: number}[],
    name: string,
}

const defaultProps = {
    value: [null],
}

const RouteViaAirport = ({
    onChange,
    value: routeViaAirports,
    name,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const addRouteViaAirport = useCallback((): void => {
        const newRouteViaAirports = [
            ...routeViaAirports,
            null,
        ]

        onChange(
            newRouteViaAirports,
            {
                target: {
                    name,
                    value: newRouteViaAirports,
                },
            },
        )
    }, [
        name,
        onChange,
        routeViaAirports,
    ])

    const deleteRouteViaAirport = useCallback((index: number): void => {
        const newRouteViaAirports = routeViaAirports.filter(
            (item, i) => {
                return i !== index
            },
        )

        onChange(
            newRouteViaAirports,
            {
                target: {
                    name,
                    value: newRouteViaAirports,
                },
            },
        )
    }, [
        name,
        onChange,
        routeViaAirports,
    ])

    const onChangeSelection = useCallback((_, {
        target: {
            name: itemIndex,
            value: itemValue,
        },
    }: Event): void => {
        const newRouteViaAirports = routeViaAirports
            .map((item, index) => {
                if (Number(itemIndex) === index) {
                    return itemValue
                }

                return item
            })

        onChange(
            newRouteViaAirports,
            {
                target: {
                    name,
                    value: newRouteViaAirports,
                },
            },
        )
    }, [
        onChange,
        name,
        routeViaAirports,
    ])

    return (
        <>
            {
                routeViaAirports.map((item, index) => {
                    return (
                        <div
                            className={classes.item}
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={`airportSelector-${index}`}
                        >
                            <div className={classes.containerIcon}>
                                {index === 0
                                    ? (
                                        <div
                                            className={clsx(classes.control, classes.circle)}
                                            onMouseDown={addRouteViaAirport}
                                            data-testid="add-btn"
                                        >
                                            +
                                        </div>
                                    )

                                    : (
                                        <div
                                            className={clsx(classes.control, classes.circle)}
                                            onMouseDown={() => { deleteRouteViaAirport(index) }}
                                            data-testid="btn-delete"
                                        >
                                            -
                                        </div>
                                    )}
                                <div className={classes.verticalLine} />
                                <div className={classes.verticalLine} />
                                <div className={classes.verticalLine} />
                            </div>
                            <LocationObjectSelector
                                name={String(index)}
                                value={item}
                                onChange={onChangeSelection}
                                title="Via"
                                className={classes.locationInput}
                                classNames={{
                                    label: classes.label,
                                }}
                                filterLocationType="AIRPORT"
                            />
                        </div>
                    )
                })
            }
        </>
    )
}

RouteViaAirport.defaultProps = defaultProps

export default RouteViaAirport
