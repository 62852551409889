import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import internalTransportsConfig from './internalTransports.config'
import customerTransportsConfig from './customerTransports.config'
import preConditioningFeasibilityConfig from './preConditioningFeasibility.config'
import requests from './ContainerSelection.request'

import useStyles from './ContainerSelection.style'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const DOMAIN_NAME = requests.name

const ContainerSelection = () => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()

    const onRowClick = useCallback((name, {
        orderNumber,
    }) => {
        history.push(requests.moveToUrl[name](orderNumber))
    }, [history])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>Pre-Conditioning Feasibility</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('preconditioningFeasibility', args) }}
                    customUrl={requests.preconditioningFeasibility}
                    tableConfig={preConditioningFeasibilityConfig}
                    uniqField="orderNumber"
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>Customer Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('customerTransports', args) }}
                    customUrl={requests.customerTransports}
                    tableConfig={customerTransportsConfig}
                    uniqField="orderNumber"
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>
                Internal Transports
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    customUrl={requests.internalTransports}
                    tableConfig={internalTransportsConfig}
                    uniqField="orderNumber"
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
        </div>
    )
}

export default ContainerSelection
