import React, {
    useMemo,
} from 'react'
import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'
import Card from 'app/shared-components/Card'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import useLabel from 'app/hooks/useLabel'
import requests from '../AccountQuotes.requests'
import useGetLanesToBeGrouped from './hooks/useGetLanesToBeGrouped'
import useStyles from './LanesToBeGrouped.styles'
import getColumns from './LanesToBeGrouped.columns'
import LaneToBeGroupedUpdate from './LaneToBeGroupedUpdate'

const LanesToBeGrouped = ({
    id,
}: {
    id: number
}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const {
        url,
    } = useRouteMatch()

    const requestsHandlers = useGetLanesToBeGrouped(id)

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const actionComponents = useMemo(() => {
        return {
            Update: LaneToBeGroupedUpdate,
        }
    }, [])

    return (
        <Switch>
            <Route path={`${url}/:id?/:action?`}>
                <div
                    data-testid="LanesTable"
                    className={classes.root}
                >
                    <Card
                        fullHeight
                        contentClass={classes.cardContent}
                        data-testid="LanesTable-card"
                    >
                        <CollapsibleTable
                            title="Lanes"
                            domainName={requests.domainName}
                            configName="LanesToBeGrouped"
                            columns={columns}
                            customUrl={null}
                            requestHandlers={requestsHandlers}
                            actionComponents={actionComponents}
                            createEnabled={false}
                            tableControls={[]}
                        />
                    </Card>
                </div>
            </Route>
        </Switch>
    )
}

export default LanesToBeGrouped
