import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        address: {
            display: 'flex',
            alignItems: 'center',
        },
        addressDelivery: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        iconLocation: {
            height: 24,
            width: 24,
        },
        addressName: {
            paddingLeft: theme.spacing(1),
        },
        containerIcon: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        verticalLine: {
            height: 5,
            width: 2,
            margin: '2px 0',
            background: theme.palette.secondary[600],
        },
        blue: {
            background: theme.palette.primary[200],
        },
    }
})

export default useStyles
