import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        archiveButton: {
            margin: `${theme.spacing(2)} auto`,
            width: 120,
            textAlign: 'center',
        },
    }
})

export default useStyles
