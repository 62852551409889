import React from 'react'

import {
    ChangeValidityConfirmationDialog,
} from 'app/shared-components/ChangeValidity'
import StatusHandler from 'app/shared-components/StatusHandler'
import useCountOrdersLaneFee from '../hooks/useCountOrdersLaneFee'

type Props = {
    id: number,
    changeValidity: {
        openConfirmDialog: boolean,
        handleChangeValidityConfirm: (...args: any[]) => void,
        changeValidityState: {
            reason: string,
            validFrom: string,
            validTo: string,
        },
        setChangeValidityState: (...args: any[]) => void
    }
}

const LaneFeeChangeValidity = ({
    id,
    changeValidity,
}: Props) => {
    const {
        data,
        isError,
        error,
    } = useCountOrdersLaneFee(id, {
        openConfirmDialog: changeValidity.openConfirmDialog,
        validFrom: changeValidity.changeValidityState.validFrom,
        validTo: changeValidity.changeValidityState.validTo,
    })

    return (
        <StatusHandler
            isSuccess
            isFetching={false}
            isError={isError}
            error={error}
        >
            <ChangeValidityConfirmationDialog
                title="Lane Fee"
                openConfirmDialog={changeValidity.openConfirmDialog}
                handleClose={changeValidity.handleChangeValidityConfirm}
                changeValidityState={changeValidity.changeValidityState}
                setChangeValidityState={changeValidity.setChangeValidityState}
                orderCount={data?.orderCount}
            />
        </StatusHandler>

    )
}

export default LaneFeeChangeValidity
