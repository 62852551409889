export const CARTASENSE_NAME = 'cartaSense'
export const SKY_CELL_SAVY_NAME = 'skyCellSavy'

// TODO-JM: need to refactor this - why we need this anyway?
const loggerTypes = {
    cartaSense: {
        title: 'CartaSense U-Logger',
        name: CARTASENSE_NAME,
    },
    skyCellSavy: {
        title: 'SkyCell Savy',
        name: SKY_CELL_SAVY_NAME,
    },
    clButterfly: {
        title: 'Cl Butterfly',
        name: SKY_CELL_SAVY_NAME,
    },
    clTT7100: {
        title: 'Cl Tt 7100',
        name: SKY_CELL_SAVY_NAME,
    },
    mr810t: {
        title: 'Mr 810T',
        name: SKY_CELL_SAVY_NAME,
    },
    mr813: {
        title: 'MR 813',
        name: 'MR_813',
    },
}

export default loggerTypes
