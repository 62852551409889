enum AllPositionTypeCodes {
    _1500C_CANCELLATION = '_1500C_CANCELLATION',
    _1500CRT_CANCELLATION = '_1500CRT_CANCELLATION',
    _2500C_CANCELLATION = '_2500C_CANCELLATION',
    _2500CRT_CANCELLATION = '_2500CRT_CANCELLATION',
    _770C_CANCELLATION = '_770C_CANCELLATION',
    _770CRT_CANCELLATION = '_770CRT_CANCELLATION',
    _1500DF_CANCELLATION = '_1500DF_CANCELLATION',
    _1500F_CANCELLATION = '_1500F_CANCELLATION',
    _1500XCOL_CANCELLATION = '_1500XCOL_CANCELLATION',
    _1500XCOL_EXPRESS_ORDER = '_1500XCOL_EXPRESS_ORDER',
    _1500XCRT_CANCELLATION = '_1500XCRT_CANCELLATION',
    _1500DF_EURO_CANCELLATION = '_1500DF_EURO_CANCELLATION',
    _1500DF_EUROMAX_CANCELLATION = '_1500DF_EUROMAX_CANCELLATION',
    _1500XCOL_MONARCH_CANCELLATION = '_1500XCOL_MONARCH_CANCELLATION',
    _1500XCRT_MONARCH_CANCELLATION = '_1500XCRT_MONARCH_CANCELLATION',
    _1500DF_EUROMAX_MONARCH_CANCELLATION = '_1500DF_EUROMAX_MONARCH_CANCELLATION',
    _1500C_LOSS = '_1500C_LOSS',
    _1500CRT_LOSS = '_1500CRT_LOSS',
    _2500C_LOSS = '_2500C_LOSS',
    _2500CRT_LOSS = '_2500CRT_LOSS',
    _770C_LOSS = '_770C_LOSS',
    _770CRT_LOSS = '_770CRT_LOSS',
    _1500DF_LOSS = '_1500DF_LOSS',
    _1500F_LOSS = '_1500F_LOSS',
    LOSS = 'LOSS',
    _1500_FEET_RPLCMNT = '_1500_FEET_RPLCMNT',
    _1500_FULL_DAMAGE = '_1500_FULL_DAMAGE',
    _1500_FULL_DOOR_RPLCMNT = '_1500_FULL_DOOR_RPLCMNT',
    _1500_INNER_DOOR_RPLCMNT = '_1500_INNER_DOOR_RPLCMNT',
    _1500_INNER_SHELL = '_1500_INNER_SHELL',
    _1500_INSULATION_SIDE = '_1500_INSULATION_SIDE',
    _1500_LOGGER_RPLCMNT = '_1500_LOGGER_RPLCMNT',
    _1500_ADD_MARGIN = '_1500_ADD_MARGIN',
    _1500_AVAILABILITY = '_1500_AVAILABILITY',
    _1500_CARGO_STRAPS = '_1500_CARGO_STRAPS',
    _1500_CLOSING_MECHANISM = '_1500_CLOSING_MECHANISM',
    _1500_DELIVERY_LTL = '_1500_DELIVERY_LTL',
    _1500_FASTENING = '_1500_FASTENING',
    _1500_MANUAL_CONTAINER_FEE_A = '_1500_MANUAL_CONTAINER_FEE_A',
    _1500_MANUAL_CONTAINER_FEE_B = '_1500_MANUAL_CONTAINER_FEE_B',
    _1500_COLLECTION = '_1500_COLLECTION',
    _1500_RETURN_DROPOFF = '_1500_RETURN_DROPOFF',
    _2500_ADD_MARGIN = '_2500_ADD_MARGIN',
    _2500_AVAILABILITY = '_2500_AVAILABILITY',
    _2500_CARGO_STRAPS = '_2500_CARGO_STRAPS',
    _2500_DELIVERY_LTL = '_2500_DELIVERY_LTL',
    _2500_MANUAL_CONTAINER_FEE_A = '_2500_MANUAL_CONTAINER_FEE_A',
    _2500_MANUAL_CONTAINER_FEE_B = '_2500_MANUAL_CONTAINER_FEE_B',
    _2500_COLLECTION = '_2500_COLLECTION',
    _2500_RETURN_DROPOFF = '_2500_RETURN_DROPOFF',
    _770_ADD_MARGIN = '_770_ADD_MARGIN',
    _770_AVAILABILITY = '_770_AVAILABILITY',
    _770_CARGO_STRAPS = '_770_CARGO_STRAPS',
    _770_DELIVERY_LTL = '_770_DELIVERY_LTL',
    _770_MANUAL_CONTAINER_FEE_A = '_770_MANUAL_CONTAINER_FEE_A',
    _770_MANUAL_CONTAINER_FEE_B = '_770_MANUAL_CONTAINER_FEE_B',
    _770_COLLECTION = '_770_COLLECTION',
    _770_RETURN_DROPOFF = '_770_RETURN_DROPOFF',
    BASIC_READOUT = 'BASIC_READOUT',
    CHANGE_ORDER = 'CHANGE_ORDER',
    CONTAINER_POSITIONING = 'CONTAINER_POSITIONING',
    DAMAGE_WAIVER = 'DAMAGE_WAIVER',
    DAMAGE_DEDUCTIBLE = 'DAMAGE_DEDUCTIBLE',
    DELIVERY_FTL = 'DELIVERY_FTL',
    DELIVERY_FTL_TRUCK = 'DELIVERY_FTL_TRUCK',
    DELIVERY_FTL_VAN = 'DELIVERY_FTL_VAN',
    DRY_ICE = 'DRY_ICE',
    EXPRESS_ORDER = 'EXPRESS_ORDER',
    FEE = 'FEE',
    FUEL_SURCHARGE = 'FUEL_SURCHARGE',
    INSURANCE = 'INSURANCE',
    LEASE = 'LEASE',
    MANUAL_SHIPMENT_FEE_A = 'MANUAL_SHIPMENT_FEE_A',
    MANUAL_SHIPMENT_FEE_B = 'MANUAL_SHIPMENT_FEE_B',
    MILESTONE_READOUT = 'MILESTONE_READOUT',
    POSTPONEMENT = 'POSTPONEMENT',
    REVERSE_LOGISTICS = 'REVERSE_LOGISTICS',
    WEEKEND_DELIVERY = 'WEEKEND_DELIVERY',
    ACTIVE_MONITORING = 'ACTIVE_MONITORING',
    RECONDITIONING = 'RECONDITIONING',
    SWAPPING = 'SWAPPING',
    TRUCKING_FEE = 'TRUCKING_FEE',
    _1500C_DELIVERY_LTL = '_1500C_DELIVERY_LTL',
    _1500CRT_DELIVERY_LTL = '_1500CRT_DELIVERY_LTL',
    _1500F_DELIVERY_LTL = '_1500F_DELIVERY_LTL',
    _1500DF_DELIVERY_LTL = '_1500DF_DELIVERY_LTL',
    _1500DF_EURO_DELIVERY_LTL = '_1500DF_EURO_DELIVERY_LTL',
    _1500DF_EUROMAX_DELIVERY_LTL = '_1500DF_EUROMAX_DELIVERY_LTL',
    _1500XCOL_DELIVERY_LTL = '_1500XCOL_DELIVERY_LTL',
    _1500XCRT_DELIVERY_LTL = '_1500XCRT_DELIVERY_LTL',
    _1500XSCOL_DELIVERY_LTL = '_1500XSCOL_DELIVERY_LTL',
    _1500XSCRT_DELIVERY_LTL = '_1500XSCRT_DELIVERY_LTL',
    _2500C_DELIVERY_LTL = '_2500C_DELIVERY_LTL',
    _2500CRT_DELIVERY_LTL = '_2500CRT_DELIVERY_LTL',
    _770C_DELIVERY_LTL = '_770C_DELIVERY_LTL',
    _770CRT_DELIVERY_LTL = '_770CRT_DELIVERY_LTL',
    _1500C_COLLECTION = '_1500C_COLLECTION',
    _1500CRT_COLLECTION = '_1500CRT_COLLECTION',
    _1500F_COLLECTION = '_1500F_COLLECTION',
    _1500DF_COLLECTION = '_1500DF_COLLECTION',
    _1500DF_EURO_COLLECTION = '_1500DF_EURO_COLLECTION',
    _1500DF_EUROMAX_COLLECTION = '_1500DF_EUROMAX_COLLECTION',
    _1500XCOL_COLLECTION = '_1500XCOL_COLLECTION',
    _1500XCRT_COLLECTION = '_1500XCRT_COLLECTION',
    _1500XSCOL_COLLECTION = '_1500XSCOL_COLLECTION',
    _1500XSCRT_COLLECTION = '_1500XSCRT_COLLECTION',
    _2500C_COLLECTION = '_2500C_COLLECTION',
    _2500CRT_COLLECTION = '_2500CRT_COLLECTION',
    _770C_COLLECTION = '_770C_COLLECTION',
    _770CRT_COLLECTION = '_770CRT_COLLECTION',
    _1500C_CARGO_STRAPS = '_1500C_CARGO_STRAPS',
    _1500CRT_CARGO_STRAPS = '_1500CRT_CARGO_STRAPS',
    _1500F_CARGO_STRAPS = '_1500F_CARGO_STRAPS',
    _1500DF_CARGO_STRAPS = '_1500DF_CARGO_STRAPS',
    _1500DF_EURO_CARGO_STRAPS = '_1500DF_EURO_CARGO_STRAPS',
    _1500DF_EUROMAX_CARGO_STRAPS = '_1500DF_EUROMAX_CARGO_STRAPS',
    _1500XCOL_CARGO_STRAPS = '_1500XCOL_CARGO_STRAPS',
    _1500XCRT_CARGO_STRAPS = '_1500XCRT_CARGO_STRAPS',
    _1500XSCOL_CARGO_STRAPS = '_1500XSCOL_CARGO_STRAPS',
    _1500XSCRT_CARGO_STRAPS = '_1500XSCRT_CARGO_STRAPS',
    _2500C_CARGO_STRAPS = '_2500C_CARGO_STRAPS',
    _2500CRT_CARGO_STRAPS = '_2500CRT_CARGO_STRAPS',
    _770C_CARGO_STRAPS = '_770C_CARGO_STRAPS',
    _770CRT_CARGO_STRAPS = '_770CRT_CARGO_STRAPS',
    _1500C_RETURN_DROPOFF = '_1500C_RETURN_DROPOFF',
    _1500CRT_RETURN_DROPOFF = '_1500CRT_RETURN_DROPOFF',
    _1500F_RETURN_DROPOFF = '_1500F_RETURN_DROPOFF',
    _1500DF_RETURN_DROPOFF = '_1500DF_RETURN_DROPOFF',
    _1500DF_EURO_RETURN_DROPOFF = '_1500DF_EURO_RETURN_DROPOFF',
    _1500DF_EUROMAX_RETURN_DROPOFF = '_1500DF_EUROMAX_RETURN_DROPOFF',
    _1500XCOL_RETURN_DROPOFF = '_1500XCOL_RETURN_DROPOFF',
    _1500XCRT_RETURN_DROPOFF = '_1500XCRT_RETURN_DROPOFF',
    _1500XSCOL_RETURN_DROPOFF = '_1500XSCOL_RETURN_DROPOFF',
    _1500XSCRT_RETURN_DROPOFF = '_1500XSCRT_RETURN_DROPOFF',
    _2500C_RETURN_DROPOFF = '_2500C_RETURN_DROPOFF',
    _2500CRT_RETURN_DROPOFF = '_2500CRT_RETURN_DROPOFF',
    _770C_RETURN_DROPOFF = '_770C_RETURN_DROPOFF',
    _770CRT_RETURN_DROPOFF = '_770CRT_RETURN_DROPOFF',
    _1500C_AVAILABILITY = '_1500C_AVAILABILITY',
    _1500CRT_AVAILABILITY = '_1500CRT_AVAILABILITY',
    _1500F_AVAILABILITY = '_1500F_AVAILABILITY',
    _1500DF_AVAILABILITY = '_1500DF_AVAILABILITY',
    _1500DF_EURO_AVAILABILITY = '_1500DF_EURO_AVAILABILITY',
    _1500DF_EUROMAX_AVAILABILITY = '_1500DF_EUROMAX_AVAILABILITY',
    _1500XCOL_AVAILABILITY = '_1500XCOL_AVAILABILITY',
    _1500XCRT_AVAILABILITY = '_1500XCRT_AVAILABILITY',
    _1500XSCOL_AVAILABILITY = '_1500XSCOL_AVAILABILITY',
    _1500XSCRT_AVAILABILITY = '_1500XSCRT_AVAILABILITY',
    _2500C_AVAILABILITY = '_2500C_AVAILABILITY',
    _2500CRT_AVAILABILITY = '_2500CRT_AVAILABILITY',
    _770C_AVAILABILITY = '_770C_AVAILABILITY',
    _770CRT_AVAILABILITY = '_770CRT_AVAILABILITY',
    _1500XCOL_MONARCH_CARGO_STRAPS = '_1500XCOL_MONARCH_CARGO_STRAPS',
    _1500XCOL_MONARCH_RETURN_DROPOFF = '_1500XCOL_MONARCH_RETURN_DROPOFF',
    _1500XCOL_MONARCH_COLLECTION = '_1500XCOL_MONARCH_COLLECTION',
    _1500XCOL_MONARCH_AVAILABILITY = '_1500XCOL_MONARCH_AVAILABILITY',
    _1500XCOL_MONARCH_DELIVERY_LTL = '_1500XCOL_MONARCH_DELIVERY_LTL',
    _1500XCRT_MONARCH_CARGO_STRAPS = '_1500XCRT_MONARCH_CARGO_STRAPS',
    _1500XCRT_MONARCH_RETURN_DROPOFF = '_1500XCRT_MONARCH_RETURN_DROPOFF',
    _1500XCRT_MONARCH_COLLECTION = '_1500XCRT_MONARCH_COLLECTION',
    _1500XCRT_MONARCH_AVAILABILITY = '_1500XCRT_MONARCH_AVAILABILITY',
    _1500XCRT_MONARCH_DELIVERY_LTL = '_1500XCRT_MONARCH_DELIVERY_LTL',
    _1500DF_EUROMAX_MONARCH_CARGO_STRAPS = '_1500DF_EUROMAX_MONARCH_CARGO_STRAPS',
    _1500DF_EUROMAX_MONARCH_RETURN_DROPOFF = '_1500DF_EUROMAX_MONARCH_RETURN_DROPOFF',
    _1500DF_EUROMAX_MONARCH_COLLECTION = '_1500DF_EUROMAX_MONARCH_COLLECTION',
    _1500DF_EUROMAX_MONARCH_AVAILABILITY = '_1500DF_EUROMAX_MONARCH_AVAILABILITY',
    _1500DF_EUROMAX_MONARCH_DELIVERY_LTL = '_1500DF_EUROMAX_MONARCH_DELIVERY_LTL',
    _1500C = '_1500C',
    _1500C_ED = '_1500C_ED',
    _1500CRT = '_1500CRT',
    _1500CRT_ED = '_1500CRT_ED',
    _2500C = '_2500C',
    _2500C_ED = '_2500C_ED',
    _2500CRT = '_2500CRT',
    _2500CRT_ED = '_2500CRT_ED',
    _770C = '_770C',
    _770C_ED = '_770C_ED',
    _770CRT = '_770CRT',
    _770CRT_ED = '_770CRT_ED',
    _1500DF = '_1500DF',
    _1500DF_ED = '_1500DF_ED',
    _1500F = '_1500F',
    _1500F_ED = '_1500F_ED',
    _1500C_LT = '_1500C_LT',
    _1500CRT_LT = '_1500CRT_LT',
    _1500F_LT = '_1500F_LT',
    _1500DF_LT = '_1500DF_LT',
    _2500C_LT = '_2500C_LT',
    _2500CRT_LT = '_2500CRT_LT',
    _1500XCOL = '_1500XCOL',
    _1500XCRT = '_1500XCRT',
    _1500XCOL_ED = '_1500XCOL_ED',
    _1500XCRT_ED = '_1500XCRT_ED',
    _1500DF_EURO_ED = '_1500DF_EURO_ED',
    _1500DF_EURO_LT = '_1500DF_EURO_LT',
    _1500DF_EUROMAX_ED = '_1500DF_EUROMAX_ED',
    _1500DF_EUROMAX_LT = '_1500DF_EUROMAX_LT',
    _1500DF_EURO = '_1500DF_EURO',
    _1500DF_EURO_MAX = '_1500DF_EURO_MAX',
    _1500XCOL_MONARCH_ED = '_1500XCOL_MONARCH_ED',
    _1500XCOL_MONARCH_LT = '_1500XCOL_MONARCH_LT',
    _1500XCOL_MONARCH = '_1500XCOL_MONARCH',
    _1500XCRT_MONARCH_ED = '_1500XCRT_MONARCH_ED',
    _1500XCRT_MONARCH_LT = '_1500XCRT_MONARCH_LT',
    _1500XCRT_MONARCH = '_1500XCRT_MONARCH',
    _1500DF_EUROMAX_MONARCH_ED = '_1500DF_EUROMAX_MONARCH_ED',
    _1500DF_EUROMAX_MONARCH_LT = '_1500DF_EUROMAX_MONARCH_LT',
    _1500DF_EUROMAX_MONARCH = '_1500DF_EUROMAX_MONARCH',
    _1500C_EXPRESS_ORDER = '_1500C_EXPRESS_ORDER',
    _1500CRT_EXPRESS_ORDER = '_1500CRT_EXPRESS_ORDER',
    _1500F_EXPRESS_ORDER = '_1500F_EXPRESS_ORDER',
    _1500DF_EXPRESS_ORDER = '_1500DF_EXPRESS_ORDER',
    _1500DF_EURO_EXPRESS_ORDER = '_1500DF_EURO_EXPRESS_ORDER',
    _1500DF_EUROMAX_EXPRESS_ORDER = '_1500DF_EUROMAX_EXPRESS_ORDER',
    _1500DF_EUROMAX_MONARCH_EXPRESS_ORDER = '_1500DF_EUROMAX_MONARCH_EXPRESS_ORDER',
    _2500C_EXPRESS_ORDER = '_2500C_EXPRESS_ORDER',
    _2500CRT_EXPRESS_ORDER = '_2500CRT_EXPRESS_ORDER',
    _770C_EXPRESS_ORDER = '_770C_EXPRESS_ORDER',
    _770CRT_EXPRESS_ORDER = '_770CRT_EXPRESS_ORDER',
    _770_FULL_DOOR_RPLCMNT = '_770_FULL_DOOR_RPLCMNT',
    _770_FULL_DAMAGE = '_770_FULL_DAMAGE',
    _2500_TOP_BOTTOM_RPLCMNT = '_2500_TOP_BOTTOM_RPLCMNT',
    _2500_FULL_DOOR_RPLCMNT = '_2500_FULL_DOOR_RPLCMNT',
    _2500_FULL_DAMAGE = '_2500_FULL_DAMAGE',
    _2500_FEET_RPLCMNT = '_2500_FEET_RPLCMNT',
    DAMAGE = 'DAMAGE',
    _770_LOGGER_RPLCMNT = '_770_LOGGER_RPLCMNT',
    _770_INNER_SHELL = '_770_INNER_SHELL',
    _2500_WALL_PANEL_EXCHNG = '_2500_WALL_PANEL_EXCHNG',
    _2500_OUTER_DOOR_RPLCMNT = '_2500_OUTER_DOOR_RPLCMNT',
    _2500_LOGGER_RPLCMNT = '_2500_LOGGER_RPLCMNT',
    _2500_INSULATION_SIDE = '_2500_INSULATION_SIDE',
    _2500_INNER_SHELL = '_2500_INNER_SHELL',
    _2500_INNER_DOOR_RPLCMNT = '_2500_INNER_DOOR_RPLCMNT',
    _1500_WALL_PANEL_EXCHNG = '_1500_WALL_PANEL_EXCHNG',
    _1500_TOP_BOTTOM_RPLCMNT = '_1500_TOP_BOTTOM_RPLCMNT',
    _1500_OUTER_DOOR_RPLCMNT = '_1500_OUTER_DOOR_RPLCMNT',
}

export default AllPositionTypeCodes
