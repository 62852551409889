import {
    RequestConfig,
} from 'app/types/request.types'

type UserRequests = {
    domainName: string,
    all: RequestConfig,
    get: ({
        id,
    }) => RequestConfig,
    create: () => RequestConfig,
    update: ({
        id,
    }: { id: string }) => RequestConfig,
    getUsers: (params) => RequestConfig,
}

const userRequests: UserRequests = {
    domainName: 'User',
    all: {
        url: 'user/filter-request/all',
    },
    get: ({
        id,
    }) => {
        return {
            url: `user/${id}`,
        }
    },
    create: () => {
        return {
            url: 'user',
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `user/${id}`,
            method: 'PUT',
        }
    },
    getUsers: (params) => {
        return {
            url: 'user/filter-request/all',
            method: 'POST',
            data: {
                ...params,
            },
        }
    },
}

export default userRequests
