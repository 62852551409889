import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'
import {
    Country,
    State,
} from 'app/Apps/Masterdata/Countries/Countries.types'
import {
    Currency,
    LocationType,
    AddressCategory,
    RegionZeroLevel,
    RegionFirstLevel,
    RegionSecondLevel,
} from 'app/types/enums'
import {
    RolesTypes,
} from 'app/utils/hasPermission'

export enum StorageType {
    C = 'C',
    CRT = 'CRT',
    DRY = 'DRY',
}

export enum StorageSpaceRatio {
    _1_1 = '_1_1',
    _1_2 = '_1_2',
    _2_3 = '_2_3',
    _3_4 = '_3_4',
    _4_5 = '_4_5',
    _5_6 = '_5_6',
    _6_7 = '_6_7',
    _9_10 = '_9_10',
}

export type Storage = {
    id?: number,
    storageType?: StorageType,
    preconTimeInHours?: number,
    immediateUseOnly?: boolean,
    preconEndOnWorkdayOnly?: boolean,
    storageSpaceRatio?: StorageSpaceRatio,
    preconCapacity?: number,
    inStoragePrecon?: boolean,
    changedOn?: string,
    changedBy?: string,
}

export enum AddressMainCategory {
    AIRLINE = 'AIRLINE',
    CONSIGNEE = 'CONSIGNEE',
    FORWARDER = 'FORWARDER',
    GENERAL = 'GENERAL',
    INSTITUTE = 'INSTITUTE',
    PHARMA_WAREHOUSE = 'PHARMA_WAREHOUSE',
    SERVICECENTER = 'SERVICECENTER',
    SUPPLIER = 'SUPPLIER',
    ASSET_TRACKING = 'ASSET_TRACKING',
}

export enum ServiceCenterStatus {
    PLANNED = 'PLANNED',
    CURRENT = 'CURRENT',
    INACTIVE = 'INACTIVE',
}

export type ServiceCenterMetrics = {
    currency?: Currency,
    dryIceCapacity?: number,
    fixedCosts?: number,
    id?: number,
    operationalDays?: string,
    paymentSchedule?: string,
    pcmCapacity?: number,
    storages?: Storage[],
    variableCosts?: number,
    workforce?: number,
}

export type Location = {
    locationType?: LocationType,
    latitude?: number,
    longitude?: number,
    city?: string,
    addressMainCategory?: AddressMainCategory[],
    enhancedSkyCenterScanning?: boolean,
    addressCategory?: AddressCategory[],
    addressLine1?: string,
    addressLine2?: string,
    addressLine3?: string,
    area?: string,
    attachments?: any[],
    attachmentsIds?: any[],
    changedBy?: string,
    changedOn?: string,
    company?: Company,
    companyId?: number,
    country?: Country,
    countryName?: string,
    countryId?: number,
    createdBy?: string,
    createdOn?: string,
    currentPlaceId?: string,
    deleted?: boolean,
    iataCode?: string,
    id?: number,
    isApprovedSubContractor?: boolean,
    isMainAddress?: boolean,
    isProspect?: boolean,
    isShippingLocation?: boolean,
    locationName?: string,
    locationNumber?: string,
    requiresTruckDetails?: boolean,
    serviceCenterMetrics?: ServiceCenterMetrics,
    serviceCenterPlannedStartDate?: string,
    serviceCenterStatus?: ServiceCenterStatus,
    state?: State,
    stateId?: number,
    timeZone?: string,
    unloCode?: string,
    zip?: string
    archived?: boolean,
    region?: RegionFirstLevel,
    aebServiceProviderId?: string,
    portOfReference?: Location,
    regionZeroLevel?: RegionZeroLevel,
    regionFirstLevel?: RegionFirstLevel,
    regionSecondLevel?: RegionSecondLevel,
    freightRateServiceProviderCode?: string,
}

export const ROLES_PROSPECT_ALLOWED: RolesTypes[] = [
    'location_sales',
    'location_cs',
    'location_cs_super',
]

export const ROLES_NOPROSPECT_AND_PROSPECT_ALLOWED: RolesTypes[] = [
    'location_cs',
    'location_cs_super',
]
