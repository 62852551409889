import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles<{height: string}>()((
    theme, inputHeight,
    classes: Record<string, any>,
): Record<string, any> => {
    return {
        input: {
            '&&': {
                fontWeight: theme.typography.body1.fontWeight,
                lineHeight: theme.typography.body1.lineHeight,
                color: theme.typography.body1.color,
                fontSize: theme.typography.body1.fontSize,
                width: '100%',
                padding: '2px 0 5px !important',
                ...inputHeight,
                overflowY: 'scroll !important',
            },
        },
        textField: {
            width: '100%',
            position: 'relative',
        },
        text: {
            '&&': {
                margin: 0,
                color: theme.palette.secondary[600],
                fontSize: theme.typography.body1.fontSize,
                whiteSpace: 'pre-wrap',
            },
        },
        fullHeightTextField: {
            height: '100%',
            marginBottom: 13,
        },
        fullHeight: {
            height: '100%',
        },
        inputWithIcon: {
            paddingRight: theme.spacing(3.5),
        },
        rightAligned: {
            textAlign: 'right',
        },
        centerAligned: {
            textAlign: 'center',
        },
        formControl: {
            width: '100%',
        },
        isRequired: {
            backgroundColor: theme.palette.primary[30],
        },
        disabled: {
            [`& .${classes.requiredMark}`]: {
                backgroundColor: 'transparent !important',
            },
            padding: `${theme.spacing(0.25)} 0px ${theme.spacing(0.5)} 0px`,
        },
        emptyValue: {
            height: '0px !important',
        },
        disabledUnderline: {
            '&::before': {
                border: '0 !important',
            },
        },
        requiredMark: {
            '&&': {
                position: 'absolute',
                left: -8,
                backgroundColor: theme.palette.primary.main,
                width: 6,
                height: '100%',
                borderRadius: 3,
            },
        },
        errorMessage: {
            height: 10,
        },
        inputControl: {
            width: '100%',
        },
        inputMeasure: {
            position: 'absolute',
            top: 4,
            height: 0,
            color: theme.palette.secondary[600],
            fontSize: theme.typography.body1.fontSize,
        },
        inputMeasureValue: {
            visibility: 'hidden',
        },
        icon: {
            position: 'absolute',
            bottom: 7,
            right: 10,
        },
        iconArrowTop: {
            position: 'absolute',
            right: 5,
            cursor: 'pointer',
        },
        iconDisabled: {
            paddingLeft: theme.spacing(1),
            verticalAlign: 'top',
        },
        wrapper: {
            boxSizing: 'border-box',
            position: 'relative',
        },
    } as Record<string, any>
})

export default useStyles
