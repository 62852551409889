import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        emptyMsg: {
            paddingTop: theme.spacing(1),
            fontSize: theme.typography.body1.fontSize,
        },
    }
})

export default useStyles
