import React, {
    useCallback, useMemo, useState,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'

import {
    AccountQuote, QuoteStatus,
} from '../AccountQuotes.types'
import {
    AccountQuoteInformationContext,
} from '../AccountQuoteCreate/AccountQuoteInformationContext'
import AccountQuoteInformationCreate from '../AccountQuoteCreate/AccountQuoteInformationCreate'
import QuoteOpportunities from '../AccountQuoteCreate/QuoteOpportunities'
import QuoteLanes from '../AccountQuoteCreate/QuoteLanes'
import QuotationOptions from '../AccountQuoteCreate/QuotationOptions'
import useAccountQuoteUpdate from './hooks/useAccountQuoteUpdate'
import getRequestData from './getRequestData'

const AccountQuoteUpdate = ({
    data,
    onSuccess,
}: {data: AccountQuote, onSuccess: () => void}) => {
    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(1)

    const [
        value,
        setValue,
    ] = useState<AccountQuote>(data)

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    useBeforeUnloadDialog(isEdited)

    const accountQuoteInformationContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            initialValue: data,
            setValue,
            setIsEdited,
        }
    }, [
        activeStep,
        value,
        data,
    ])

    const {
        mutate,
    } = useAccountQuoteUpdate(onSuccess)

    const updateQuote = useCallback((accountQuote: AccountQuote) => {
        setIsEdited(false)
        mutate({
            id: value?.id,
            data: getRequestData(accountQuote, data),
        })
    }, [
        mutate,
        value?.id,
        data,
    ])

    const stepperSteps = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Fill Quote Information',
                expanded: true,
                content: <AccountQuoteInformationCreate
                    data={value}
                    disabled
                />,
            },
            {
                key: '2',
                label: 'Select Opportunities & Required Products',
                expanded: (step) => {
                    return step > 0
                },
                content: <QuoteOpportunities
                    disabled={activeStep !== 1 || data?.quoteStatus === QuoteStatus.CANCELLED}
                />,
            },
            {
                key: '3',
                label: 'Select Lanes',
                expanded: (step) => {
                    return step > 1
                },
                content: <QuoteLanes disabled={activeStep !== 2} />,
            },
            {
                key: '4',
                label: 'Specify Quotation Options',
                expanded: (step) => {
                    return step > 2
                },
                content: <QuotationOptions
                    data={value}
                    actionQuote={updateQuote}
                />,
            },
        ]
    }, [
        activeStep,
        data?.quoteStatus,
        updateQuote,
        value,
    ])

    return (
        <AccountQuoteInformationContext.Provider value={accountQuoteInformationContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                isPositiveAlert
                positiveLabel="Discard"
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </AccountQuoteInformationContext.Provider>
    )
}

export default AccountQuoteUpdate
