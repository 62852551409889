import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import simpleApi from 'app/services/simpleApi'

import requestConfig from '../../lane.request'

const useSetAsImplemented = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessImplemented,
        onError,
    } = useHandleStatusRequest({
        id: 'SetAsImplemented',
        title: 'Set As Implemented',
        onSuccess,
    })

    return useMutation({
        mutationFn: (laneId: number) => {
            return simpleApi({
                ...requestConfig.SetAsImplemented.requestFn({
                    id: laneId,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessImplemented,
    })
}

export default useSetAsImplemented
