import React, {
    useCallback,
    useState,
} from 'react'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'

import useStyles from './RelinkLaneFeesDialog.style'
import LaneFeeSelector from './LaneFeeSelector'
import useRelinkLaneFee from './hooks/useRelinkLaneFee'

const DIALOG_TITLE = 'RELINK LANE FEES'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    orderNumber: string,
    onSuccess: () => void
}

const RelinkLaneFeesDialog = ({
    open,
    setOpen,
    onSuccess,
    orderNumber,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const [
        laneFee,
        setLaneFee,
    ] = useState<number>()

    const onSuccessRelink = (): void => {
        setOpen(false)
        onSuccess()
    }

    const {
        isPending: isLoading,
        mutate,
    } = useRelinkLaneFee(onSuccessRelink)

    const relinkLaneFees = useCallback((): void => {
        mutate({
            orderNumber,
            data: {
                id: laneFee,
            },
        })
    }, [
        laneFee,
        mutate,
        orderNumber,
    ])

    const handleClose = useCallback((action: boolean): void => {
        if (!action) {
            setOpen(false)
            setLaneFee(undefined)

            return
        }

        relinkLaneFees()
    }, [
        relinkLaneFees,
        setOpen,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="relink"
            negativeLabel="cancel"
            isLoading={isLoading}
            classNames={{
                paper: classes.paper,
            }}
        >
            <div className={classes.gridWrapper}>
                <LaneFeeSelector
                    value={laneFee}
                    onChange={setLaneFee}
                    orderNumber={orderNumber}
                />
            </div>
        </ConfirmationDialog>
    )
}

export default RelinkLaneFeesDialog
