import {
    RequestConfig,
} from 'app/types/request.types'

type LoggerExchangeRequests = {
    filterRequest: ({
        serialNumber,
        params,
    }) => RequestConfig,
    deleteLoggerPairing: ({
        id, serialNumber,
    }) => RequestConfig,
    deletedLoggerHistory: ({
        serialNumber, params,
    }) => RequestConfig,
    loggerPairingUpdate: ({
        serialNumber,
    }) => RequestConfig,
}

const requests: LoggerExchangeRequests = {
    filterRequest: ({
        serialNumber,
        params,
    }) => {
        const newParams = {
            ...params,
            sort: params.sort || {
                direction: 'desc',
                field: 'exchangeTimestamp',
            },
            rows: params.rows || 200,
        }

        return {
            url: `container/${serialNumber}/logger-history/filter-request/all`,
            method: 'POST',
            ...newParams,
        }
    },
    deleteLoggerPairing: ({
        id, serialNumber,
    }) => {
        return {
            url: `container/${serialNumber}/logger-pairing/${id}/delete`,
            method: 'PATCH',
        }
    },
    deletedLoggerHistory: ({
        serialNumber,
        params,
    }) => {
        const newParams = {
            ...params,
            sort: params.sort || {
                direction: 'desc',
                field: 'deletedOn',
            },
            rows: params.rows || 200,
        }

        return {
            url: `container/${serialNumber}/logger-pairing/filter-request/deleted`,
            method: 'POST',
            ...newParams,
        }
    },
    loggerPairingUpdate: ({
        serialNumber,
    }) => {
        return {
            url: `container/${serialNumber}/logger-pairing/logger-removal`,
            method: 'PATCH',
        }
    },
}

export default requests
