import React, {
    useState, useEffect, useRef, useCallback,
} from 'react'
import {
    Compatible,
} from '@silevis/reactgrid'

import {
    useAppSelector, useAppDispatch,
} from 'app/store/hooks'
import useOutsideClick from 'app/hooks/useOutsideClick'
import useClickHandler from 'app/hooks/useClickHandler'
import {
    addToDomainConfigs,
    selectSpreadSheetConfigByDomain,
} from 'app/store/SpreadSheetConfigs'
import {
    KeyboardKeys,
} from 'app/types/common.enums'

import {
    MultipleTypeaheadCell as MultipleTypeaheadCellType,
} from './MultipleTypeaheadCellTemplate'
import useSkyNetSpreadSheetContext from '../../SkyNetSpreadSheetContext'
import {
    SpreadsheetCellOption,
} from '../../SkyNetSpreadSheet.types'
import MultipleObjectSelectDropdown from '../../MultipleObjectSelectDropdown'

const MultipleTypeaheadCell = ({
    cell,
    onChange,
}: {
    cell: Compatible<MultipleTypeaheadCellType>
    onChange: (...args: any[]) => void
}) => {
    const dispatch = useAppDispatch()
    const wrapperRef = useRef(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const {
        forceEditQuit,
        setFocusChanging,
        rowHeight,
        configId,
    } = useSkyNetSpreadSheetContext()

    const domainConfig = useAppSelector((state) => {
        return selectSpreadSheetConfigByDomain(state, {
            name: configId, field: cell.name, domain: cell.domain,
        })
    })

    const [
        pending,
        setPending,
    ] = useState<boolean>(!!cell.filters)

    const [
        options,
        setOptions,
    ] = useState<SpreadsheetCellOption[]>(domainConfig.options)

    const [
        selected,
        setSelected,
    ] = useState<Record<string, any>[]>(cell.selectedValue)

    const [
        search,
        setSearch,
    ] = useState(null)

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus()
        setFocusChanging(false)

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (inputRef.current) inputRef.current.blur()
            setFocusChanging(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function loadOptions() {
            const result = await cell.loadOptions({
                phrase: search,
                filters: cell.filters,
            })

            setOptions(result)
            setPending(false)
        }

        if (search || cell.filters) loadOptions()
    }, [
        search,
        cell,
    ])

    const handleChange = useCallback((values) => {
        onChange(values)
        if (cell.domain && values.length) {
            dispatch(addToDomainConfigs({
                name: configId,
                domain: cell.domain,
                field: cell.name,
                params: {
                    options: values,
                },
            }))
        }
    }, [
        cell,
        configId,
        dispatch,
        onChange,
    ])

    const saveChanges = useCallback(() => {
        handleChange(selected)
    }, [
        handleChange,
        selected,
    ])

    const saveAndQuit = useCallback(() => {
        setSelected((prev) => {
            handleChange(prev)
            return [...prev]
        })
        forceEditQuit()
    }, [
        forceEditQuit,
        handleChange,
    ])

    useOutsideClick(wrapperRef, saveAndQuit)
    useClickHandler([KeyboardKeys.ENTER], saveChanges)
    useClickHandler([KeyboardKeys.ESCAPE], forceEditQuit)

    return (
        <div ref={wrapperRef}>
            <input
                ref={inputRef}
                data-testid="typeaheadInput"
                defaultValue={search}
                onClick={forceEditQuit}
                onChange={(e) => {
                    setSearch(e?.target?.value)
                }}
                onKeyDown={(e) => {
                    e.stopPropagation()
                    if (e.key === KeyboardKeys.BACKSPACE) {
                        const val = inputRef.current?.value

                        if (val) setSearch(val.slice(0, val.length - 1))
                    }
                }}
            />
            <MultipleObjectSelectDropdown
                rowHeight={rowHeight}
                options={options}
                selected={selected}
                setSelected={setSelected}
                pending={pending}
            />
        </div>
    )
}

export default MultipleTypeaheadCell
