import {
    useEffect,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

const COPY = 'copy'

export const REDIRECT_PARAM = {
    source: 'copy',
}
/// This hook is used as workaround to add the new elements to SkyNetTable reduced mode
/// need to be removed when SkyNet table can use react-query and refetch data
/// For example used for Copy feature when Main SkyNetTable need to be updated with new element

const useAddCopiedItemWorkaround = ({
    data,
}:{
    data:{id?:string | number},
}) => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const {
        state,
    } = useLocation()

    // TODO workaround to add the new elements to SkyNetTable reduced mode
    // need to be removed when SkyNet table can use react-query and refetch data
    useEffect(() => {
        if (data?.id && state?.source === COPY) {
            selectDomainObject(data?.id)
            addTableElement(data)
        }
    }, [
        data,
        addTableElement,
        selectDomainObject,
        state,
    ])
}

export default useAddCopiedItemWorkaround
