import {
    makeStyles,
} from 'app/tss'

const contactInformation = 'contactInformation'
const billingInformation = 'billingInformation'
const orderSummary = 'orderSummary'
const cancellation = 'cancellation'
const containers = 'containers'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${contactInformation} ${billingInformation} ${billingInformation} ${billingInformation}"
                "${orderSummary} ${containers} ${containers} ${containers}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapperCancelled: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${contactInformation} ${billingInformation} ${billingInformation} ${billingInformation}"
                "${orderSummary} ${containers} ${containers} ${containers}"
                "${cancellation} ${containers} ${containers} ${containers}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        contactInformation: {
            gridArea: contactInformation,
        },
        billingInformation: {
            gridArea: billingInformation,
        },
        orderSummary: {
            gridArea: orderSummary,
        },
        cancellation: {
            gridArea: cancellation,
        },
        containers: {
            gridArea: containers,
            minHeight: 300,
        },
        wrapper: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
    }
})

export default useStyles
