import React from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'

import useGetAccountQuote from '../hooks/useGetAccountQuote'
import AssignQuote from './AssignQuote'

const AssignQuoteContainer = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetAccountQuote(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <AssignQuote
                invalidate={invalidate}
                data={data}
            />
        </StatusHandler>
    )
}

export default AssignQuoteContainer
