import React, {
    useCallback,
} from 'react'
import {
    useDispatch,
} from 'react-redux'
import moment from 'moment'
import kebabCase from 'lodash/kebabCase'

import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import downloadDocument from 'app/utils/downloadDocument'
import {
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'
import DTOSpec from 'app/Apps/Pricing/ContractBasis/dtoSpec'

import useQuoteGenerate from '../hooks/useQuoteGenerate'
import useGetContractBasis from '../hooks/useGetContractBasis'
import ContractBasisCreateQuote from './ContractBasisCreateQuote'

type Props = {
    id: string,
}
const ContractBasisCreateQuoteContainer = ({
    id,
}: Props) => {
    const dispatch = useDispatch()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContractBasis(id)

    const onGenerateSuccess = useCallback((responseData, locationName, companyName) => {
        const fileName = `${moment().format('YYYYMMDD')}${locationName ? `_${kebabCase(locationName)}` : ''}${companyName ? `_${kebabCase(companyName)}` : ''}`

        downloadDocument(responseData, fileName)
        dispatch(
            successedToSaveForm({
                id: 'Notification',
                title: 'The Lane Quote have been successfully downloaded.',
            }),
        )
    }, [dispatch])

    const {
        mutate: generate,
    } = useQuoteGenerate({
        locationName: data?.customerQuotation?.locationName,
        companyName: data?.account?.company?.companyName,
        request: DTOSpec.ContractBasis.QuotePrint.GenerateRequest,
        onSuccess: onGenerateSuccess,
    })
    const generatePDFCall = useCallback((params) => {
        generate({
            ...params,
        })
    }, [generate])

    const renderButtons = useCallback((params) => {
        return (
            <Button
                label="Submit"
                onClick={() => { return generatePDFCall(params) }}
            />
        )
    }, [generatePDFCall])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContractBasisCreateQuote
                id={Number(id)}
                renderActions={renderButtons}
            />
        </StatusHandler>
    )
}

export default ContractBasisCreateQuoteContainer
