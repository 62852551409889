import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'
import config from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateDirectLease/createDirectLease.request'

import {
    AvailableLanes,
} from '../AvailableLanes.types'

const useGetAvailableLanesForCTCreation = () => {
    const {
        onParamsChange,
        loadData,
    } = useRequestTable<TableResponse<AvailableLanes>>({
        config: config.AvailableLanes.requestFn,
        params: {},
        defaultValue: {
            sort: {
                field: 'changedOn',
                direction: 'desc',
            },
        },
        key: RequestKeys.getAvailableLanesForCTCreation,
    })

    return {
        onParamsChange,
        loadData,
    }
}

export default useGetAvailableLanesForCTCreation
