import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    DamageStatus,
    DamageType, ReportingDepartment,
} from 'app/types/enums'
import {
    Damage,
} from '../Damages.types'

const getDamageRepairFields = (editable): DTOCardFormField<keyof Damage> => {
    return {
        title: 'Damage Repair',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'repairingServiceCenter',
                    labelKey: 'Damages.repairingServiceCenter',
                    componentName: 'LocationObjectSelector',
                    filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                    disabled: !editable,
                    required: true,
                }],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'repairedOn',
                        labelKey: 'Damages.repairedOn',
                        componentName: 'DateTimeSelect',
                        required: true,
                    },
                    {
                        name: 'repairComment',
                        labelKey: 'Damages.repairComment',
                        componentName: 'LongText',
                        disabled: !editable,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'repairedBy',
                        labelKey: 'Damages.repairedBy',
                        componentName: 'ContactObjectSelector',
                        required: true,
                    },
                    {
                        name: 'repairProcessAttachment',
                        labelKey: 'Damages.repairProcess',
                        componentName: 'FileDownloadLink',
                        disabled: true,
                    },
                ],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'repairAttachments',
                    labelKey: 'Damages.repairAttachment',
                    componentName: 'AttachmentsObjectSelector',
                    disabled: !editable,
                }],
            },
        ],
    }
}

const getDamageUpdateFields = ({
    getAllowedValues, editable, damageStatus,
}): DTOCardFormField<keyof Damage>[] => {
    const mainFields = [
        {
            title: 'Summary',
            fields: [
                {
                    id: 'column1',
                    fields: [{
                        name: 'damageNumber',
                        labelKey: 'Damages.damageNumber',
                        componentName: 'InputSingleline',
                        disabled: true,
                    }],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'container',
                            labelKey: 'Damages.serialNumber',
                            componentName: 'ContainerSelector',
                            disabled: true,
                        },
                        {
                            name: 'containerModel',
                            labelKey: 'Damages.containerModel',
                            componentName: 'InputSingleline',
                            disabled: true,
                        },
                        {
                            name: 'createdOn',
                            labelKey: 'Damages.createdOn',
                            componentName: 'DateTimeSelect',
                            disabled: true,
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'reportedOn',
                            labelKey: 'Damages.reportedOn',
                            componentName: 'DateTimeSelect',
                            disabled: true,
                        },
                        {
                            name: 'reportedBy',
                            labelKey: 'Damages.reportedBy',
                            componentName: 'ContactObjectSelector',
                            disabled: true,
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [
                        {
                            name: 'repairedOn',
                            labelKey: 'Damages.repairedOn',
                            componentName: 'DateTimeSelect',
                            disabled: true,
                        },
                        {
                            name: 'repairedBy',
                            labelKey: 'Damages.repairedBy',
                            componentName: 'ContactObjectSelector',
                            disabled: true,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Damage Details',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'reportingServiceCenter',
                            labelKey: 'Damages.reportingServiceCenter',
                            componentName: 'LocationObjectSelector',
                            filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                            disabled: !editable,
                        },
                        {
                            name: 'damageType',
                            labelKey: 'Damages.damageType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(DamageType),
                            required: true,
                            disabled: !editable,
                        },
                        {
                            name: 'damageStatus',
                            labelKey: 'Damages.damageStatus',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(DamageStatus),
                            required: true,
                            useDropdown: true,
                            disabled: !editable,
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [{
                        name: 'damageComment',
                        labelKey: 'Damages.damageComment',
                        componentName: 'LongText',
                        disabled: !editable,
                    }],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'reportingDepartment',
                            labelKey: 'Damages.reportingDepartment',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(ReportingDepartment),
                            required: true,
                            useDropdown: true,
                            disabled: !editable,
                        },
                        {
                            name: 'inspectionProcessAttachment',
                            labelKey: 'Damages.inspectionProcess',
                            componentName: 'FileDownloadLink',
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [{
                        name: 'damageAttachments',
                        labelKey: 'Damages.damageAttachment',
                        componentName: 'AttachmentsObjectSelector',
                        disabled: !editable,
                    }],
                },
            ],
        },
    ]

    if (damageStatus === DamageStatus.FIXED) {
        return [
            ...mainFields,
            getDamageRepairFields(editable),
        ] as DTOCardFormField<keyof Damage>[]
    }

    return mainFields as DTOCardFormField<keyof Damage>[]
}

export default getDamageUpdateFields
