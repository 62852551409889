import {
    UseRequestConfig,
} from 'app/types/request.types'

type SimulationRequests = {
    Co2Calculation: UseRequestConfig,
}

const config: SimulationRequests = {
    Co2Calculation: {
        requestFn: (params) => {
            return {
                url: 'co2-calculator/shipmentcalculation',
                method: 'POST',
                data: params,
            }
        },
    },
}

export default config
