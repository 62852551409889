import React, {
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'

import useStyles from './ContainerForPooling.style'

const propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({
        containerLocationLocationName: PropTypes.string,
    })),
    rowProps: PropTypes.shape({
        collectionPointLocationName: PropTypes.string,
        leaseEndTimestamp: PropTypes.number,
        lastMeasuredOnUtcInternal: PropTypes.number,
    }),
}

const defaultProps = {
    value: [],
    rowProps: {},
}

const ContainerForPooling = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        value,
        rowProps: {
            collectionPointLocationName,
            leaseEndTimestamp,
        },
    } = props

    const groupByModel = useMemo(() => {
        return Object.values(
            groupBy(value, (item) => { return item.containerTypeContainerModel }),
        )
    }, [value])

    const getClassName = useCallback((container) => {
        const {
            containerLocationLocationName,
            lastMeasuredOnUtcInternal,
            isPooled,
        } = container

        if (containerLocationLocationName !== collectionPointLocationName || !leaseEndTimestamp) {
            return 'grey'
        }

        if (isPooled) {
            return 'blue'
        }

        if (!lastMeasuredOnUtcInternal || lastMeasuredOnUtcInternal < leaseEndTimestamp) {
            return 'yellow'
        }

        return 'green'
    }, [
        collectionPointLocationName,
        leaseEndTimestamp,
    ])

    return (
        <>
            {
                groupByModel.map((group) => {
                    return (
                        <div
                            key={group[0].containerTypeContainerModel}
                            className={classes.root}
                        >
                            {
                                group.map((item) => {
                                    const className = getClassName(item)

                                    return (
                                        <div
                                            className={clsx(classes[className], classes.square)}
                                            key={item.serialNumber}
                                            data-testid={`square-${item.serialNumber}`}
                                        />
                                    )
                                })
                            }
                            <div className={classes.summary}>
                                {`( ${group.length} x ${group[0].containerTypeContainerModel})`}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

ContainerForPooling.propTypes = propTypes
ContainerForPooling.defaultProps = defaultProps

export default ContainerForPooling
