import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import AccountManagementRequests from '../../AccountManagement.request'
import AccountForm from '../AccountForm'

const CreateAccount = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((account) => {
        addTableElement(account)
        selectDomainObject(account?.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <AccountForm
            onSuccess={onSuccess}
            requestParam={AccountManagementRequests.create.request()}
        />
    )
}

export default CreateAccount
