import {
    StepType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const transportTypes = [
    'ROAD',
    'AIR',
    'SEA',
]

const isTypeTransport = (stepType: StepType): boolean => {
    return transportTypes.includes(stepType)
}

export default isTypeTransport
