import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    NotificationRecipientType,
} from 'app/types/enums'

import {
    NotificationsAdvancedSettingType,
} from '../../lanes.types'

const getColumns = (
    l: (l:string) => string,
): RichTableColumns<keyof NotificationsAdvancedSettingType>[] => {
    return [
        {
            id: 'notificationName',
            headerProps: {
                children: l('Notification.notificationFollowUp'),
            },
            mapCellProps: 'notificationName',
        },
        {
            id: 'notificationType',
            headerProps: {
                children: l('Notification.type'),
            },
            mapCellProps: 'notificationType',
            componentName: 'NotificationType',
        },
        {
            id: 'recipientTypes',
            headerProps: {
                children: l('NotificationTableView.recipientTypes'),
            },
            mapCellProps: 'recipientTypes',
            componentName: 'EnumList',
            allowedValuesEnum: NotificationRecipientType,
        },
    ]
}

export default getColumns
