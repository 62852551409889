import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        tableHeaderWrapper: {
            '&&': {
                position: 'relative',
            },
        },
    }
})

export default useStyles
