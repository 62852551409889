/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import {
    InputBase,
} from '@mui/material'

import filterFieldForInput from 'app/utils/filterFieldForInput'

type Props = {
    name: string,
    value: number | string,
    disabled: boolean,
    onChange: (...args: any) => void,
    inputProps: Record<string, any>
}

const matchAllowedSymbols = (value) => { return String(value).match(/[0-9]{0,}/g) }

const InputIntegerNumbers = (props: Props) => {
    const {
        name,
        value,
        disabled,
        onChange,
        inputProps,
    } = props

    const onKeyPress = useCallback((e) => {
        const [allowedKey] = matchAllowedSymbols(e?.key)

        if (!allowedKey) {
            e.preventDefault()
        }
    }, [])

    const handleChange = useCallback(({
        target,
    }) => {
        const [newNumericValue] = matchAllowedSymbols(target?.value)

        const newValue = Number(newNumericValue)

        onChange(
            newValue,
            {
                target: {
                    name,
                    newValue,
                },
            },
        )
    }, [
        name,
        onChange,
    ])

    return (
        <InputBase
            {...filterFieldForInput(props)}
            type="number"
            name={name}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            inputProps={inputProps}
            onKeyPress={onKeyPress}
            onWheel={(event) => { event.currentTarget.querySelector('input')?.blur() }}
        />
    )
}

export default InputIntegerNumbers
