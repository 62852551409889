import {
    makeStyles,
} from 'app/tss'

const handover = 'handover'
const collection = 'collection'
const generalInformation = 'generalInformation'
const availableLanes = 'availableLanes'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${handover} ${collection} ${generalInformation}"
                "${availableLanes} ${availableLanes} ${availableLanes}"
                `,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        handover: {
            gridArea: handover,
        },
        generalInformation: {
            gridArea: generalInformation,
        },
        collection: {
            gridArea: collection,
        },
        availableLanes: {
            gridArea: availableLanes,
            minHeight: 300,
        },
        wrapper: {
            paddingLeft: 0,
            paddingRight: 0,
            maxHeight: 500,
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
        radio: {
            flexDirection: 'column',
        },
    }
})

export default useStyles
