import {
    useContext,
} from 'react'

import TabsContext from '../TabsContext'

const useTabsContext = () => {
    const contextValue = useContext(TabsContext)

    return contextValue
}

export default useTabsContext
