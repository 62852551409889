import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        cardContent: {
            height: 755,
        },
        panel: {
            display: 'flex',
        },
        containerStatus: {
            flexBasis: 400,
        },
        productType: {
            flexBasis: 200,
        },
        wrapperCheckbox: {
            display: 'flex',
        },
        checkbox: {
            padding: '0 !important',
        },
    }
})

export default useStyles
