import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {},
        arrow: {
            width: 20,
            height: 20,
            lineHeight: 0.3,
            color: theme.palette.secondary[600],
            marginLeft: theme.spacing(3.2),
            marginBottom: theme.spacing(1.6),
        },
    }
})

export default useStyles
