/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import InputSingleline from 'app/shared-components/InputSingleline'

import TextSingleline from './TextSingleline'

type Props = {
    textType?: string,
    title: string,
    value: string | Record<string, any>,
    className?: string,
    name?: string,
}

const defaultProps: Partial<Props> = {
    textType: 'read',
    name: '',
    className: '',
}

const map = {
    read: TextSingleline,
    editable: InputSingleline,
}

const TextSinglelineContainer = (props: Props) => {
    const {
        textType,
    } = props

    const Component = map[textType]

    return (
        <Component {...props} />
    )
}

TextSinglelineContainer.defaultProps = defaultProps

export default TextSinglelineContainer
