import React from 'react'

import SingleObjectSelectorConverter from 'app/shared-components/SingleObjectSelectorConverter'

import BillingCompanySelector from './BillingCompanySelector'
import {
    BillingCompanySelectorProps, BillingCompanySelectorDefaultProps,
} from './types/BillingCompanySelector.types'

type BillingCompanyObjectSelectorProps = Omit<BillingCompanySelectorProps, 'value'> & {
    value: {
        id: string | number
    }
}

const BillingCompanyObjectSelector = ({
    value,
    onChange,
    name,
    title,
    disabled,
    isRequired,
    className,
    classNames,
    'data-testid': dataTestId,
}: BillingCompanyObjectSelectorProps) => {
    return (
        <SingleObjectSelectorConverter
            selector={BillingCompanySelector}
            value={value}
            onChange={onChange}
            name={name}
            title={title}
            disabled={disabled}
            isRequired={isRequired}
            className={className}
            classNames={classNames}
            data-testid={dataTestId}
        />
    )
}

BillingCompanyObjectSelector.defaultProps = BillingCompanySelectorDefaultProps

export default BillingCompanyObjectSelector
