/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'

import MultipleSelectUnselectable from './MultipleSelectUnselectable'

const propTypes = {
    unselectableOptions: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.array),
    value: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
    unselectableOptions: [],
    value: [],
    options: [],
}

const MultipleSelectUnselectableContainer = (props) => {
    const {
        onChange,
        unselectableOptions,
        value: valueBefore,
        options,
    } = props

    const optionsMap = useMemo(() => {
        return new Map(options)
    }, [options])

    const handleChange = useCallback((value) => {
        const valueMap = new Map(value)

        const valueToAdd = unselectableOptions.reduce((acc, option) => {
            if (valueBefore.includes(option) && !valueMap.has(option)) {
                return [
                    ...acc,
                    [
                        option,
                        optionsMap.get(option),
                    ],
                ]
            }

            return acc
        }, [])

        onChange([
            ...value,
            ...valueToAdd,
        ])
    }, [
        onChange,
        optionsMap,
        unselectableOptions,
        valueBefore,
    ])

    return (
        <MultipleSelectUnselectable
            {...props}
            onChange={handleChange}
        />
    )
}

MultipleSelectUnselectableContainer.propTypes = propTypes
MultipleSelectUnselectableContainer.defaultProps = defaultProps

export default MultipleSelectUnselectableContainer
