import React, {
    useCallback,
} from 'react'

import Typeahead from 'app/shared-components/Typeahead'

import useContainerRequest from './hooks/useContainerRequest'
import useStyles from './ContainerSelector.styles'
import useGetContainer from './hooks/useGetContainer'

type Props = {
    value?: {
        id: number
    },
    title?: string,
    disabled?: boolean,
    required?: boolean,
    onChange: (value: any) => void,
}

const defaultProps: Partial<Props> = {
    value: undefined,
    disabled: false,
    required: false,
    title: '',
}

const ContainerSelector = ({
    value,
    onChange,
    title,
    required,
    disabled,
} : Props) => {
    const {
        classes,
    } = useStyles()
    const {
        loadOptions,
    } = useContainerRequest()

    const {
        data: labelData,
    } = useGetContainer(value?.id)

    const localOnChange = useCallback((newValue) => {
        const coverterValue = newValue ? {
            id: newValue,
        } : undefined

        onChange(coverterValue)
    }, [onChange])

    return (
        <Typeahead
            value={value?.id}
            onChange={localOnChange}
            title={title}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="serialNumber"
            disabled={disabled}
            isRequired={required}
            variant="standard"
            classNames={{
                options: classes.options,
            }}
        />
    )
}

ContainerSelector.defaultProps = defaultProps

export default ContainerSelector
