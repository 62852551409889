import {
    makeStyles,
} from 'app/tss'

const generalInformation = 'generalInformation'
const leaseFees = 'leaseFees'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${generalInformation} ${generalInformation} ${generalInformation} ${generalInformation}"
            "${leaseFees} ${leaseFees} ${leaseFees} ."`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        generalInformation: {
            gridArea: generalInformation,
        },
        leaseFees: {
            gridArea: leaseFees,
        },
        isGenericAccount: {
            paddingBottom: theme.spacing(0.5),
        },
    }
})

export default useStyles
