import React, {
    useMemo,
    useState,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    SidebarTab, SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    DeliveryServiceType,
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import OrdersContext from 'app/Apps/OrderManagement/Orders/CustomerTransport/OrdersContext'
import ContainerSelection from 'app/Apps/OrderManagement/Orders/Components/ContainerSelection'
import OrderNotificationsTab from 'app/Apps/OrderManagement/Orders/Components/OrderNotificationsTab'
import OrderSteps from 'app/Apps/OrderManagement/Orders/Components/OrderSteps'
import {
    OrderStatuses,
} from 'app/types/enums'

import OrderChanges from './OrderChanges'
import CustomerTransportsOverview from './CustomerTransportsOverview'
import DataUpdate from './DataUpdate'
import BillingInformation from './BillingInformation'
import Invoices from './Invoices'
import SharedPositioning from './SharedPositioning'
import PositioningSetup from './PositioningSetup'

import customerOrderConfig from './customerTransport.config'
import orderRequest from '../orders.request'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

type Props = {
    name?: string,
    defaultTab?: string,
    config?: SkyNetTableConfig,
}

const defaultProps = {
    name: orderRequest.dataModelName,
    config: customerOrderConfig,
    defaultTab: 'overview',
}

const CustomerTransport = ({
    name,
    config,
    defaultTab,
}: Props) => {
    const [
        selectedInvoiceId,
        setSelectedInvoiceId,
    ] = useState<number>()

    const outgoingInvoiceRead: boolean = useHasPermission(['outgoing_invoice_read'])
    const orderUpdateTransport: boolean = useHasPermission(['order_update_transport'])
    const positioningSetupCondition = ({
        orderStatus,
    }: {
        orderStatus: OrderStatuses,
    }): boolean => {
        return orderStatus === OrderStatuses.NOT_STARTED
    }
    const sharedPositioningCondition = ({
        transportModeRoad,
        deliveryServiceType,
    }: {
        transportModeRoad: TransportModeRoad,
        deliveryServiceType: DeliveryServiceType,
    }): boolean => {
        return transportModeRoad === TransportModeRoad.FTL
                && deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    }
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: 'overview',
                label: 'Overview',
                Component: CustomerTransportsOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: 'data-update',
                label: 'Data Update',
                Component: DataUpdate,
            },
            permission: true,
        },
        {
            tab: {
                url: 'notifications',
                label: 'Notifications',
                Component: OrderNotificationsTab,
            },
            permission: true,
        },
        {
            tab: {
                url: 'order-changes',
                label: 'Order Changes',
                Component: OrderChanges,
            },
            permission: true,
        },
        {
            tab: {
                url: 'container-selection',
                label: 'Containers / Freight',
                Component: ContainerSelection,
            },
            permission: true,
        },
        {
            tab: {
                url: 'order-steps',
                label: 'Order Steps',
                Component: OrderSteps,
            },
            permission: true,
        },
        {
            tab: {
                url: 'billing-information',
                label: 'Billing Information',
                Component: BillingInformation,
            },
            permission: outgoingInvoiceRead,
        },
        {
            tab: {
                url: 'invoices',
                label: 'Invoices',
                Component: Invoices,
            },
            permission: outgoingInvoiceRead,
        },
        {
            tab: {
                url: 'shared-positioning',
                label: 'Shared Positioning',
                Component: SharedPositioning,
                condition: sharedPositioningCondition,
            },
            permission: orderUpdateTransport,
        },
        {
            tab: {
                url: 'positioning-setup',
                label: 'Positioning Setup',
                Component: PositioningSetup,
                condition: positioningSetupCondition,
            },
            permission: true,
        },
    ])

    const contextValue = useMemo(() => {
        return {
            selectedInvoiceId,
            setSelectedInvoiceId,
        }
    }, [selectedInvoiceId])

    return (
        <OrdersContext.Provider value={contextValue}>
            <SkyNetTable
                name={name}
                moduleName={orderRequest.moduleName}
                tabs={tabs}
                getDomainObject={orderRequest.OrderByNumber.requestFn}
                domainRequestKey={RequestKeys.getOrderByNumber}
                tableControls={tableControls}
                uniqField="orderNumber"
                tableConfig={config}
                defaultTab={defaultTab}
                showSearch
            />
        </OrdersContext.Provider>
    )
}

CustomerTransport.defaultProps = defaultProps

export default CustomerTransport
