import React, {
    useMemo,
} from 'react'
import SimpleTable, {
    RowComponent,
} from 'app/shared-components/SimpleTable'
import useLabel from 'app/hooks/useLabel'
import Card from 'app/shared-components/Card'
import {
    Column,
} from 'app/types/simpletable.types'
import useEnumValues from 'app/hooks/useEnumValues'
import getColumns, {
    QuoteLanesTable as QuoteLanesTableType,
} from './QuoteLanesTable.config'
import useStyles from '../../QuoteOpportunities/QuoteOpportunititesTable/QuoteOpportunitiesTable.styles'

const QuoteLanesTable = ({
    data, onChange, disabled,
}: {
    data: QuoteLanesTableType[],
    onChange: (...args: any[]) => void,
    disabled: boolean
}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const columns = useMemo((): Column<keyof QuoteLanesTableType>[] => {
        return getColumns({
            classes,
            disabled,
            l,
            handleChange: onChange,
            getAllowedValues,
        })
    }, [
        disabled,
        onChange,
        classes,
        l,
        getAllowedValues,
    ])

    return (
        <Card title="Lanes">
            <SimpleTable
                name="QuoteLanesTable"
                columns={columns}
                data={data}
                component={RowComponent}
                onChange={onChange}
                disabled
                classNames={{
                    tableCellHead: classes.tableCellHead,
                    tableCell: classes.tableCellHead,
                }}
            />
        </Card>
    )
}

export default QuoteLanesTable
