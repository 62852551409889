import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import locationRequests from '../Locations.request'

type LocationParams = {
    latitude: number,
    longitude: number,
    editedLocation: {
        id: number,
    }
}

export default (onSuccessAction) => {
    const token = useJWTToken()

    const {
        onStartSaving,
        onError: onFailedRequest,
        removePendingStatus,
    } = useHandleStatusRequest({
        id: 'LocationDuplicationCheck',
    })

    const onSuccess = useCallback((data) => {
        removePendingStatus()
        onSuccessAction(data)
    }, [
        removePendingStatus,
        onSuccessAction,
    ])

    const onError = useCallback((err) => {
        onFailedRequest(err)
    }, [onFailedRequest])

    const {
        mutate: checkDuplication,
    } = useMutation({
        mutationFn: (data: LocationParams) => {
            return simpleApi({
                ...locationRequests.locationDuplicateCheck({
                    data,
                }),
                token,
            })
        },
        onSuccess,
        onError,
    })

    return useCallback((locationParams: LocationParams) => {
        onStartSaving('Checking Database for similar locations')
        checkDuplication(locationParams)
    }, [
        checkDuplication,
        onStartSaving,
    ])
}
