import React from 'react'

import useLabel from 'app/hooks/useLabel'

import useStyles from './CustomTooltip.styles'

const CustomTooltip = ({
    date,
    dryStorageCapacity,
    totalContainerAmount,
}: {
    date: string,
    dryStorageCapacity: number,
    totalContainerAmount: number,
}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()

    return (
        <div
            className={classes.tooltipWrapper}
            data-testid="tooltip"
        >
            <div className={classes.wrapper}>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.date')}</div>
                    <div>{date}</div>
                </div>
            </div>
            <div className={classes.wrapper}>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.totalContainerAmount')}</div>
                    <div>{totalContainerAmount}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.dryStorageCapacity')}</div>
                    <div>{dryStorageCapacity}</div>
                </div>
            </div>
        </div>
    )
}

export default CustomTooltip
