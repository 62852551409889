import React from 'react'

import useStyles from './LastSentOn.style'
import {
    NotificationTableView,
} from '../../../OrderManagement/Orders/Components/OrderNotifications/utils/OrderNotifications.types'

type Props = {
    value?: string,
    rowProps: NotificationTableView,
}

const defaultProps: Partial<Props> = {
    value: undefined,
}

const LastSentOn = ({
    value,
    rowProps,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        optional,
        triggerDescription,
    } = rowProps

    if (value) {
        return value
    }

    return (
        <>
            <span>
                {
                    optional ? 'Optional' : 'Not yet sent'
                }
            </span>
            <img
                src="assets/images/info.svg"
                alt="info"
                title={triggerDescription}
                className={classes.icon}
            />
        </>
    )
}

LastSentOn.defaultProps = defaultProps

export default LastSentOn
