import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        btnContainer: {
            width: 'fit-content',
            display: 'inline-flex',
        },
        classNameLabel: {
            flexBasis: 'content',
        },
    }
})

export default useStyles
