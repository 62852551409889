/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Content from './Content'

const ContentContainer = (props) => {
    return (
        <Content
            {...props}
        />
    )
}

export default ContentContainer
