import React, {
    useState,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RegionalGroup,
} from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.types'
import request from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.request'

import getFields from './regionalGroupCreateForm.fields'
import useStyles from './RegionalGroupCreateForm.style'

type Props = {
    onSuccess: (value: unknown) => void,
    accountId?: number,
}

const defaultProps: Partial<Props> = {
    accountId: undefined,
}

const RegionalGroupCreateForm = ({
    onSuccess,
    accountId,
}: Readonly<Props>) => {
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState<Partial<RegionalGroup>>({
        account: {
            id: accountId,
        },
    })

    const getAllowedValues = useEnumValues()

    const fields = getFields({
        getAllowedValues,
        needSelectAccount: !accountId,
    })

    return (
        <FormWithControls
            onSuccess={onSuccess}
            value={value}
            setExternalValue={setValue}
            name={request.domainName}
            fields={fields}
            requestParams={request.CreateRegionalGroup()}
            className={classes.root}
        />
    )
}

RegionalGroupCreateForm.defaultProps = defaultProps

export default RegionalGroupCreateForm
