import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        selectionItemContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 6,
            marginRight: 6,
            '&:first-of-type': {
                marginLeft: 0,
            },
            '&:last-of-type': {
                marginRight: 0,
            },
        },
        selectionItemBtn: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'inherit',
            height: 180,
            padding: 0,
            cursor: 'pointer',
        },
        selectionItem: {
            display: 'flex',
            boxSizing: 'border-box',
            border: `1px solid ${theme.palette.secondary[400]}`,
            flexDirection: 'column',
            width: 'inherit',
            height: 'inherit',
            boxShadow: `0px 1px 3px ${theme.palette.secondary[400]}`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper,
            transform: 'scale(1)',
            transition: 'all 0.3s',
        },
        unselectedViewItem: {
            width: '14rem',
            height: '12rem',
            transform: 'scale(0.65)',
            transition: 'all 0.3s',
        },
        selectionItemContent: {
            flex: '1 0 auto',
        },
        itemTitle: {
            fontSize: theme.typography.h3.fontSize,
            width: '13rem',
            color: theme.palette.secondary[800],
            fontFamily: theme.typography.h5.fontFamily,
            padding: theme.spacing(1),
            textAlign: 'center',
        },
        unselectedItemTitle: {
            color: theme.palette.secondary[200],
            fontSize: theme.typography.h2.fontSize,
        },
        selectionItemImageContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        selectionItemImage: {
            width: 90,
            height: 90,
        },
        containerAmount: {
            display: 'flex',
            alignItems: 'end',
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary[500],
            fontFamily: theme.typography.h6.fontFamily,
            padding: theme.spacing(0, 1),
        },
        footer: {
            height: 15,
            flexShrink: 0,
            borderRadius: '0 0 4px 4px',
            backgroundColor: theme.palette.secondary[100],
        },
        unselectedViewItemFooter: {
            height: 20,
        },
        noWrapText: {
            whiteSpace: 'nowrap',
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(0.65),
        },
        inputAmount: {
            width: 30,
        },
    }
})

export default useStyles
