import {
    useCallback,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

// good to use when we have the array of enum keys
const useEnumLabels = () => {
    const {
        t,
    } = useTranslation()

    return useCallback((enums: string[]): Record<string, string> => {
        return Object.fromEntries(enums.map((key) => {
            const label = t(key, {
                ns: 'enums',
            })

            return [
                key,
                label || key,
            ]
        }))
    }, [t])
}

export default useEnumLabels
