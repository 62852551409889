import {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import request from 'app/Apps/AccountManagement/Opportunities/opportunities.request'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'

export default (filters?: Record<string, (string|number|boolean)[]>) => {
    const params = useMemo(() => {
        return {
            url: request.FilterRequest({}).url,
            convertion: (opps) => {
                return opps.map(({
                    opportunityNumber,
                    id, reprOriginAirport,
                    reprDestinationAirport,
                    temperatureRange, companyName,
                }) => {
                    return {
                        textLabel: opportunityNumber,
                        label: `${opportunityNumber} | ${reprOriginAirport} | ${reprDestinationAirport} | ${temperatureRange} | ${companyName}`,
                        value: id,
                        id,
                        reprOriginAirport,
                        reprDestinationAirport,
                        temperatureRange,
                        companyName,
                    }
                })
            },
            includeFilters: isEmpty(filters) ? undefined : filters,
        }
    }, [filters])

    return useDelayedLoadOptions(params)
}
