import {
    useCallback,
} from 'react'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

export function isRowNotEmpty(item: Record<string, any>) {
    return !Object.values(item).every(
        (el: any) => {
            if (isObject(el)) {
                return !(isNumber(el?.id) || isString(el?.id))
            }
            return isEmpty(el)
        },
    )
}

export default (invalidate = isRowNotEmpty) => {
    return useCallback((data: Record<string, any>[]) => {
        return data.filter(invalidate)
    }, [invalidate])
}
