import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            height: `calc(100% - ${theme.spacing(4)}) !important`,
            padding: theme.spacing(2),
        },
        table: {
            background: theme.palette.common.white,
            borderRadius: 10,
            height: '100%',
            boxShadow: `0px 1px 3px ${theme.palette.secondary[200]}`,
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: theme.spacing(2),
        },
        tableHeaderWrapper: {
            padding: theme.spacing(2),
        },
        tableContentWrapper: {
            border: 'none !important',
            width: '100%',
            height: `calc(100% - ${theme.spacing(4)}) !important`,
            padding: '0 !important',
            position: 'relative',
            '&>div': {
                margin: 0,
                height: '100%',
            },
        },
    }
})

export default useStyles
