import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    LaneFee,
} from 'app/Apps/Pricing/Pricing.types'
import requests from '../LaneFees.requests'

export default (laneGroupId, showArchived) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<LaneFee>({
        key: RequestKeys.getLaneFeesByLaneGroup,
        params: {
            laneGroupId,
        },
        config: requests.getLaneFeesByLaneGroup,
        showArchived,
        keepPreviousData: true,
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}
