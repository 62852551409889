import React, {
    useCallback,
    useState,
} from 'react'

import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import useShareContext from 'app/shared-components/SharedContext/useSharedContext'

import useRemoveQuotePdf from './hooks/useRemoveQuotePdf'
import useStyles from './QuotePdfDelete.styles'

type Props = {
    value: string,
}
type WithPriningNameType = {
    quoteId: number,
}

const QuotePdfDelete = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        quoteId,
    } = useShareContext<WithPriningNameType>()

    const {
        selectDomainObject,
    } = useDomainObjectContext()

    const onSuccess = useCallback(() => {
        selectDomainObject(quoteId)
    }, [
        quoteId,
        selectDomainObject,
    ])
    const {
        mutate: removeQuotePdf,
    } = useRemoveQuotePdf(onSuccess)

    const onConfirm = useCallback((positive) => {
        if (positive) {
            removeQuotePdf({
                quoteId,
                fileId: value,
            })
        }
        setConfirmation(false)
    }, [
        removeQuotePdf,
        value,
        quoteId,
    ])

    const [
        confirmation,
        setConfirmation,
    ] = useState(false)

    return (
        <>
            <IconButton
                onClick={() => {
                    return setConfirmation(true)
                }}
            >
                <DeleteIcon />
            </IconButton>
            <ConfirmationDialog
                dialogTitle="Delete QuotePdf Confirmation"
                open={confirmation}
                dialogContent="Are you sure you want to delete this document?"
                handleClose={onConfirm}
                classNames={{
                    content: classes.title,
                }}
            />
        </>
    )
}

export default QuotePdfDelete
