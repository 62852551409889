import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        iconWrapper: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingRight: theme.spacing(1),
        },
        iconWrapperFirst: {
            paddingTop: theme.spacing(2),
        },
        iconWrapperLast: {
            paddingBottom: theme.spacing(2),
        },
        line: {
            width: 2,
            height: 10,
            background: theme.palette.secondary[600],
        },
        firstLine: {
            marginBottom: theme.spacing(0.25),
        },
        secondLine: {
            marginTop: theme.spacing(0.25),
        },
        title: {
            color: theme.palette.secondary[600],
            fontSize: theme.typography.h5.fontSize,
        },
        info: {
            color: theme.palette.secondary[400],
            fontSize: theme.typography.subtitle2.fontSize,
        },
    }
})

export default useStyles
