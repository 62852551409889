import React from 'react'
import PropTypes from 'prop-types'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'

import useStyles from './Range.styles'

const propTypes = {
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    type: PropTypes.string.isRequired,
}

const defaultProps = {
    step: 1,
}

const typeString = {
    temperatureRange: 'ºC',
    dayRange: 'days',
}

const Range = ({
    value,
    onChange,
    min,
    max,
    step,
    type,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <>
            <Stack
                spacing={2}
                direction="row"
                sx={{
                    mb: 1,
                }}
                alignItems="center"
            >
                <span
                    className={classes.label}
                    data-testid="label-left"
                >
                    {`${type === 'dayRange' ? Math.abs(value[0]) : value[0]} ${typeString[type]}`}
                </span>
                <Slider
                    size="small"
                    className={classes.slider}
                    aria-labelledby="range-slider"
                    min={min}
                    max={max}
                    value={value}
                    step={step}
                    onChange={onChange}
                />
                <span
                    className={classes.label}
                    data-testid="label-right"
                >
                    {`${type === 'dayRange' ? Math.abs(value[1]) : value[1]} ${typeString[type]}`}
                </span>
            </Stack>
        </>
    )
}

Range.propTypes = propTypes
Range.defaultProps = defaultProps

export default Range
