import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requestConfig from '../loggerDocumentDelete.request'

type RequestParams = {
    loggerId: number,
    documentId: number,
}

const useDeleteLoggerDocument = (
    onSuccess: () => void,
): UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessDelete,
        onError,
    } = useHandleStatusRequest({
        id: 'LoggerDocument',
        title: 'Document has been removed',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            loggerId,
            documentId,
        }: RequestParams) => {
            return requestConfig.RemoveLoggerDocument.mutationFn({
                loggerId,
                documentId,
                token,
            })
        },
        onSuccess: onSuccessDelete,
        onError,
    })
}

export default useDeleteLoggerDocument
