import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Button from 'app/shared-components/Button'

import useStyles from './ConfirmationDialog.style'

const propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.string,
    positiveLabel: PropTypes.string,
    isPositiveAlert: PropTypes.bool,
    positiveLabelDisabled: PropTypes.bool,
    negativeLabel: PropTypes.string,
    children: PropTypes.element,
    classNames: PropTypes.shape({
        paper: PropTypes.string,
        content: PropTypes.string,
        title: PropTypes.string,
    }),
    customButtons: PropTypes.arrayOf(PropTypes.any),
}

const defaultProps = {
    dialogTitle: '',
    dialogContent: '',
    positiveLabel: 'yes',
    isPositiveAlert: false,
    positiveLabelDisabled: undefined,
    negativeLabel: 'no',
    isLoading: false,
    children: null,
    classNames: {},
    customButtons: undefined,
}

const ConfirmationDialog = (props) => {
    const {
        open,
        handleClose,
        dialogTitle,
        dialogContent,
        positiveLabel,
        isPositiveAlert,
        positiveLabelDisabled,
        negativeLabel,
        isLoading,
        children,
        classNames,
        customButtons,
    } = props

    const {
        classes,
        cx,
    } = useStyles()

    return (
        <Dialog
            open={open}
            onClose={() => { return handleClose(false) }}
            disableEscapeKeyDown
            onClick={(event) => { event.stopPropagation() }}
            classes={{
                paper: classNames.paper || classes.paper,
            }}
        >
            <DialogTitle classes={{
                root: cx(classes.title, classNames.title),
            }}
            >
                {dialogTitle.toUpperCase()}
            </DialogTitle>
            <DialogContent className={cx(classes.dialogContent, classNames.content)}>
                {dialogContent && (
                    <DialogContentText>
                        {dialogContent}
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    label={positiveLabel}
                    onClick={() => { handleClose(true) }}
                    saving={isLoading}
                    disabled={positiveLabelDisabled}
                    alert={isPositiveAlert}
                />
                {(customButtons || []).map((customButton) => {
                    return (
                        <div
                            key={`custom-button-${customButton.props.name}`}
                        >
                            {customButton}
                        </div>
                    )
                })}
                <Button
                    label={negativeLabel}
                    secondary
                    onClick={() => { handleClose(false) }}
                    disabled={isLoading}
                />
            </DialogActions>
        </Dialog>
    )
}

ConfirmationDialog.propTypes = propTypes
ConfirmationDialog.defaultProps = defaultProps

export default ConfirmationDialog
