import {
    makeStyles,
} from 'app/tss'

const general = 'general'
const fxRate = 'fxRate'
const fees = 'fees'
const truckingCapacity = 'truckingCapacity'
const feeDays = 'feeDays'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapperWithFxRate: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${general} ${general} ${general} ${fxRate}"
            "${truckingCapacity} ${truckingCapacity} ${feeDays} ${feeDays}"
            "${fees} ${fees} ${fees} ${fees}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${general} ${general} ${general}"
            "${truckingCapacity} ${truckingCapacity} ${feeDays}"
            "${fees} ${fees} ${fees}"`,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        general: {
            gridArea: general,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        fxRate: {
            gridArea: fxRate,
        },
        truckingCapacity: {
            gridArea: truckingCapacity,
        },
        fees: {
            gridArea: fees,
            gridTemplateColumns: '1fr !important',
        },
        minOrder: {
            marginBottom: theme.spacing(1),
        },
        rejectedReasonHint: {
            '& span': {
                fontSize: theme.typography.subtitle2.fontSize,
                color: '#B7B7B7',
            },
        },
        feeDays: {
            gridArea: feeDays,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    }
})

export default useStyles
