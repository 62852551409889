import flatten from 'lodash/flatten'

export const DOOR_LOGGER_PREFIX = 'DOOR_DOOR_'

const styledRow = [
    'number',
    'style row',
]

const intervalColumnDef = {
    role: 'interval',
    type: 'number',
}
const getDoorEventColumnsForLogger = (loggerNumber) => {
    const loggerName = loggerNumber.replace(DOOR_LOGGER_PREFIX, '')

    return [
        [
            'number',
            `Door opened ${loggerName}`,
        ],
        [intervalColumnDef],
        [intervalColumnDef],
        [
            'number',
            `Door closed ${loggerName}`,
        ],
        [intervalColumnDef],
        [intervalColumnDef],
    ]
}

const getDoorEventColumnsForLoggers = (loggers) => {
    const columns = loggers?.map((logger) => {
        return getDoorEventColumnsForLogger(logger)
    })

    return flatten(columns)
}

const getDoorEventsColumns = (doorEventLoggers) => {
    return [
        ...getDoorEventColumnsForLoggers(doorEventLoggers),
        styledRow,
    ]
}

export default getDoorEventsColumns
