/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'
import RichTable from '@skycell-ag/richtable'
import {
    useStoreTableConfig,
} from 'app/store/Configs'
import getComponentByName from 'app/utils/components/getComponentByName'

import useStyles from './RichTableReduxWrapper.styles'

type RichTableWrapperProps = {
    configName: string,
    uniqField?: string,
    visible?: string[],
    selectedRows?: string[] | number[],
    onSelectRow?: (...args: any) => void,
    load: (...args: any) => Promise<any>,
    onRowClick?: (...args: any) => void,
    name: string,
    className?: string,
    classNames?: Record<string, any>,
    onParamsChange?: (...args: any) => void,
    selectedFilter?: Record<string, any>,
    filter?: Record<string, any>,
    excludeFilters?: Record<string, any>
    sort?: Record<string, any>
    columns: Record<string, any>[],
    radioSelect?: boolean,
    disabled?: boolean,
    detailPanel?: (...args: any[]) => JSX.Element,
    rowStyle?: Record<string, any>,
    renderControlPanel?: (...args: any[]) => JSX.Element,
    openRowId?: string | number,
    showSelectAll?: boolean,
    noBorders?: boolean,
    fullWidth?: boolean,
    relative?: boolean,
}

const defaultProps = {
    sort: {},
    visible: [],
    filter: {},
    classNames: {},
    selectedFilter: {},
    className: '',
    excludeFilters: {},
    uniqField: 'id',
    onSelectRow: undefined,
    onParamsChange: undefined,
    selectedRows: undefined,
    radioSelect: false,
    disabled: false,
    onRowClick: undefined,
    rowStyle: undefined,
    detailPanel: undefined,
    renderControlPanel: undefined,
    openRowId: undefined,
    showSelectAll: undefined,
    noBorders: true,
    fullWidth: true,
    relative: false,
}

const RichTableReduxWrapper = (props: RichTableWrapperProps) => {
    const {
        classes,
    } = useStyles()
    const {
        configName,
        columns,
        classNames,
    } = props

    useStoreTableConfig({
        name: configName,
        fields: columns,
    })

    const richTableColumns = useMemo(() => {
        return columns.map((item) => {
            const {
                componentName, ...rest
            } = item

            if (componentName) {
                return {
                    ...rest,
                    component: getComponentByName(componentName),
                }
            }

            return item
        })
    }, [columns])

    return (
        <RichTable
            {...props}
            classNames={{
                headerWrapper: classes.tableHeaderWrapper,
                ...classNames,
            }}
            columns={richTableColumns}
        />
    )
}

RichTableReduxWrapper.defaultProps = defaultProps

export default RichTableReduxWrapper
