import React from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'

import useGetPreviousOrdersByOrder from './hooks/useGetPreviousOrdersByOrder'
import columns from './previousOrders.columns'
import useStyles from './PreviousOrders.style'

const DOMAIN_NAME = orderManagementRequest.PreviousOrdersByOrder.name
const PreviousOrders = ({
    orderNumber,
}: {orderNumber: string}) => {
    const {
        classes,
    } = useStyles()

    const {
        onParamsChange,
        loadData,
    } = useGetPreviousOrdersByOrder(orderNumber)

    return (
        <RichTable
            configName="InternalTransportsOverview.PreviousOrders"
            name={DOMAIN_NAME}
            load={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
        />
    )
}

export default PreviousOrders
