import {
    UnassignedToOpportunity,
} from '../KamDashboard.types'

const checkIsMatch = (value: number[], assignedLanes: UnassignedToOpportunity[]): boolean => {
    const selectedLanes = assignedLanes.filter((lane) => {
        return value.some((id) => {
            return id === lane.id
        })
    })
    const [defaultLane] = selectedLanes

    return selectedLanes.every(({
        temperatureRange,
        pricingAccountCompanyName,
    }) => {
        return temperatureRange === defaultLane.temperatureRange
            && pricingAccountCompanyName === defaultLane.pricingAccountCompanyName
    })
}

export default checkIsMatch
