import React from 'react'
import noop from 'lodash/noop'
import Typography from '@mui/material/Typography'

import DateRangeSelector from 'app/shared-components/DateRangeSelector'
import TemperatureChart from 'app/shared-components/TemperatureChart'

import Card from 'app/shared-components/Card'
import getChartOptions from 'app/Apps/utils/getChartOptions'

import useStyles from './ContainerTemperatureChart.style'

type Props = {
    timeRange: {
        from: Date,
        to: Date,
    },
    setRange?: (value:any) => void,
    data?: {
        sensorData?: { t: string, d: any[] }[],
        sensorLabels?: any[],
    },
    isDateRange: boolean,
    setDateRange: (value: any) => void,
    onFullScreen: (e: any) => void,
}

const defaultProps: Partial<Props> = {
    setRange: noop,
    data: {},
}

const ContainerTemperatureChart = ({
    timeRange,
    setRange,
    data,
    isDateRange,
    setDateRange,
    onFullScreen,
}: Props) => {
    const {
        sensorData,
        sensorLabels,
    } = data

    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.wrapper}>
            <div className={classes.chartContainer}>
                <Card className={classes.chartContainer}>
                    <Typography variant="h3">
                        Temperature in °C / UTC Time
                    </Typography>
                    <DateRangeSelector
                        value={timeRange}
                        onChange={setRange}
                        setDateRange={setDateRange}
                        showTimeRange
                        mini
                    />
                    <TemperatureChart
                        onFullScreen={onFullScreen}
                        sensorData={sensorData}
                        sensorLabels={sensorLabels}
                        isDateRange={isDateRange}
                        userOptions={getChartOptions(sensorLabels)}
                    />
                </Card>
            </div>
        </div>
    )
}

ContainerTemperatureChart.defaultProps = defaultProps

export default ContainerTemperatureChart
