import React from 'react'

import LaneFeesRequests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import useLaneGroupContext from 'app/Apps/Pricing/LaneGroups/hooks/useLaneGroupContext'
import {
    LaneFeesDetail,
} from 'app/Apps/Pricing/Pricing.types'
import LaneFeesForm from '../LaneFeesForm'

const defaultProps = {
    onCancel: () => {},
    onSuccess: () => {},
}

const LaneFeesCreate = ({
    onSuccess, onCancel,
}: { onSuccess?: (...args: any[]) => void, onCancel?: (...args: any[]) => void }) => {
    const {
        laneGroupId,
    } = useLaneGroupContext()

    return (
        <LaneFeesForm
            data={{
                minOrder: 1,
            } as LaneFeesDetail}
            onSuccess={onSuccess}
            onCancel={onCancel}
            requestParam={LaneFeesRequests.create.request({
                laneGroupId,
            })}
        />
    )
}

LaneFeesCreate.defaultProps = defaultProps

export default LaneFeesCreate
