import Checkbox from 'app/shared-components/Checkbox'

import {
    Column,
} from 'app/types/simpletable.types'

import getUniqueId from 'app/utils/getUniqueId'

import InputSingleline from 'app/shared-components/InputSingleline'

import {
    AccountLanesTable,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import EnumSelector from 'app/shared-components/EnumSelector'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

export type QuoteLanesTable = AccountLanesTable & {
    selected: boolean
}

const getColumns = ({
    classes,
    handleChange,
    l,
    disabled,
    getAllowedValues,
}: {
    classes: Record<string, any>,
    disabled: boolean,
    l: (l:string) => string,
    handleChange: (...a: any[]) => void,
    getAllowedValues: (e: Record<string, string>) => Record<string, string>
}): Column <keyof QuoteLanesTable>[] => {
    return [
        {
            label: '',
            component: Checkbox,
            fieldName: 'selected',
            getProps: ({
                id,
                existed,
            }) => {
                return {
                    className: classes.checkbox,
                    disabled: disabled || existed,
                    onChange: (selected) => {
                        handleChange({
                            id,
                            selected,
                        })
                    },
                }
            },
            width: 50,
            id: getUniqueId(),
        },
        {
            label: l('AccountLane.laneNumber'),
            component: InputSingleline,
            fieldName: 'laneNumber',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.opportunityFrom'),
            component: InputSingleline,
            fieldName: 'originAirport',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.opportunityTo'),
            component: InputSingleline,
            fieldName: 'destinationAirport',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('LaneQuoteRequest.selectedCustomerExecution'),
            component: InputSingleline,
            fieldName: 'customerExecution',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountLane.customerExecutionProvisional'),
            component: InputSingleline,
            fieldName: 'customerExecutionProvisional',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneHandover'),
            component: InputSingleline,
            fieldName: 'handoverPoint',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            width: 100,
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneCollectionDropoff'),
            component: InputSingleline,
            fieldName: 'collectionDropoff',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            width: 100,
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneDeliveryType'),
            component: EnumSelector,
            fieldName: 'deliveryServiceType',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(DeliveryServiceType),
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneCollectionType'),
            component: EnumSelector,
            fieldName: 'collectionServiceType',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(CollServiceType),
                }
            },
            id: getUniqueId(),
        },
    ]
}

export default getColumns
