import React, {
    useEffect,
    useMemo, useRef,
} from 'react'
import {
    v1 as uuidv1,
} from 'uuid'

import {
    FreightTypes, OrderTemperatureRange,
} from 'app/types/enums'
import validateForm from 'app/utils/validateForm'
import Radio from 'app/shared-components/Radio'
import useEnumValues from 'app/hooks/useEnumValues'
import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import Tooltip from 'app/shared-components/Tooltip'

import {
    InternalOrderForm,
} from '../../createManuallyForm.types'
import freightTypeContainer from './fields/freightTypeContainer.fields'
import freightTypeGeneral from './fields/freightTypeGeneral.fields'
import useStyles from './OrderInformation.style'
import getFilteredGeneralFreight from './getFilteredGeneralFreight'

const OrderInformation = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
}: {
    value: InternalOrderForm,
    onChange: (val: InternalOrderForm) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
}) => {
    const {
        classes,
    } = useStyles()

    const {
        temperatureRange,
        requiredContainersInternal,
    } = value

    const getAllowedValues = useEnumValues()

    const selectedTemperatureRange = useRef(temperatureRange)

    useEffect(() => {
        if (selectedTemperatureRange.current !== temperatureRange && requiredContainersInternal) {
            selectedTemperatureRange.current = temperatureRange
            onChange({
                ...value,
                requiredContainersInternal: null,
            })
        }
    }, [
        onChange,
        requiredContainersInternal,
        temperatureRange,
        value,
    ])

    const freightTypeContainerFields = useFieldsWithClassName(freightTypeContainer({
        location: value.location,
        temperatureRange: value.temperatureRange,
        selectPreviousOrders: value.selectPreviousOrders,
        getAllowedValues,
        disabled: activeStep > index,
    }), classes)

    const allowedValues = getAllowedValues(FreightTypes)

    const onChangeFreightType = (freightType) => {
        onChange({
            freightType,
            orderSteps: [],
            temperatureRange: freightType === FreightTypes.CONTAINERS
                ? OrderTemperatureRange.ANY_TEMP_RANGE
                : null,
            generalFreight: freightType === FreightTypes.GENERAL_FREIGHT ? [{
                id: `temp-${uuidv1()}`,
                material: undefined,
                description: undefined,
                quantity: undefined,
                unit: undefined,
                weightKg: undefined,
                dimensions: undefined,
            }] : null,
        })
    }

    const radioOptionsFreightType: {
        label: string,
        value: string,
    }[] = useMemo(() => {
        return Object.entries(allowedValues)
            .map(([
                key,
                allowedValue,
            ]) => {
                return {
                    label: allowedValue, value: key,
                }
            })
    }, [allowedValues])

    const fields = useMemo(() => {
        return value.freightType === FreightTypes.CONTAINERS
            ? freightTypeContainerFields
            : freightTypeGeneral(
                value.location,
                getAllowedValues,
            )
    }, [
        freightTypeContainerFields,
        getAllowedValues,
        value.freightType,
        value.location,
    ])

    const onclickEdit = () => {
        onChange({
            ...value,
            orderSteps: [],
        })
        setActiveStep(index)
    }

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value: {
                ...value,
                generalFreight: getFilteredGeneralFreight(value.generalFreight),
            },
        })
    }, [
        fields,
        value,
    ])

    return (
        <div className={classes.container}>
            <Radio
                title="Freight Type"
                value={value.freightType}
                options={radioOptionsFreightType}
                onChange={onChangeFreightType}
                name="freightType"
                className={classes.freightType}
                disabled={activeStep > index}
                compact
            />
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                className={classes.gridWrapper}
                disabled={activeStep > index}
                name="OrderByNumberView"
            />
            <div className={classes.actions}>
                {activeStep === index && (
                    <Tooltip
                        title="Please fill in all mandatory fields to continue"
                        disabled={isFormValid}
                    >
                        <div>
                            <Button
                                label="Continue"
                                onClick={() => { setActiveStep(index + 1) }}
                                disabled={!isFormValid}
                                data-testid="btn-continue"
                            />
                        </div>
                    </Tooltip>
                )}
                {activeStep > index && (
                    <Button
                        label="Edit"
                        onClick={onclickEdit}
                        className={classes.button}
                        data-testid="btn-edit"
                    />
                )}
            </div>
        </div>
    )
}

export default OrderInformation
