import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import FxRates from './FxRates'
import Invoices from './Invoices'

const Masterdata = () => {
    const {
        path,
    } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/invoices`}>
                <Invoices />
            </Route>
            <Route path={`${path}/fx-rates`}>
                <FxRates />
            </Route>
            <Redirect
                exact
                path={path}
                to={`${path}/invoices/all`}
            />
            <Route path="/">
                <div>
                    Not found
                </div>
            </Route>
        </Switch>
    )
}

export default Masterdata
