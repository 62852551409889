import {
    makeStyles,
} from 'app/tss'

const primaryColor = '#009CCF'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
            backgroundColor: theme.palette.common.white,
            width: 'fit-content',
            borderRadius: `${theme.spacing(0.75)} !important`,
        },
        label: {
            paddingRight: theme.spacing(2),
            fontSize: theme.typography.body1.fontSize,
        },
        pgUl: {
            'li:has( > button)': {
                paddingLeft: theme.spacing(0.5),
            },
        },
        pgItem: {
            paddingLeft: theme.spacing(0.5),
            borderRadius: '0 !important',
            '&.MuiPaginationItem-root': {
                border: `1px solid ${primaryColor}`,
                color: primaryColor,
            },
            '&.MuiPaginationItem-root.Mui-selected': {
                border: `1px solid ${primaryColor}`,
                backgroundColor: primaryColor,
                color: theme.palette.common.white,
            },
            '&.MuiPaginationItem-ellipsis': {
                backgroundColor: 'transparent',
                color: primaryColor,
                border: 0,
            },
            '&.Mui-disabled': {
                border: `1px solid ${theme.palette.secondary[400]}`,
                color: theme.palette.secondary[400],
            },
        },
    }
})

export default useStyles
