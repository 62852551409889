import React from 'react'
import noop from 'lodash/noop'

import Typeahead from 'app/shared-components/Typeahead'
import useGetCompany from 'app/Apps/ContactManagement/Companies/hooks/useGetCompany'
import {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'

type Props = {
    value?: string | number,
    loadOptions?: (options: DelayedLoadOptions) => Promise<any>,
    onChange?: (value: any, ...params: any[]) => void,
    disabled?: boolean,
    title?: string,
    isRequired?: boolean,
}

const defaultProps: Partial<Props> = {
    value: undefined,
    title: '',
    onChange: noop,
    loadOptions: noop,
    disabled: false,
    isRequired: false,
}

const CompanySelector = (props: Props) => {
    const {
        title,
        value,
        onChange,
        loadOptions,
        disabled,
        isRequired,
    } = props

    const {
        data: labelData,
    } = useGetCompany(value as number)

    return (
        <Typeahead
            onChange={onChange}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="companyName"
            value={value}
            variant="standard"
            disabled={disabled}
            title={title}
            isRequired={isRequired}
        />
    )
}

CompanySelector.defaultProps = defaultProps

export default CompanySelector
