import React, {
    useCallback,
    useState,
} from 'react'
import {
    Icon, IconButton, InputBase, InputAdornment,
} from '@mui/material'
import clsx from 'clsx'
import {
    KeyboardKeys,
} from 'app/types/common.enums'

import getDataFromInput from 'app/utils/getDataFromInput'

import useStyles from './SearchControl.style'

type Props = {
    applySearch: (text: string) => void
}

const SearchControl = ({
    applySearch,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const [
        searchText,
        setSearchText,
    ] = useState('')

    const closeSearch = useCallback(() => {
        setSearchText('')
        applySearch('')
    }, [applySearch])

    const handleAction = useCallback((event) => {
        if (event.key === KeyboardKeys.ENTER) {
            applySearch(searchText)
        } else if (event.key === KeyboardKeys.ESCAPE) {
            setSearchText('')
            applySearch('')
        }
    }, [
        applySearch,
        searchText,
    ])

    return (
        <div className={clsx(classes.root, 'flex items-center relative w-full')}>
            <InputBase
                className={classes.searchFont}
                onChange={getDataFromInput(setSearchText)}
                onKeyDown={handleAction}
                value={searchText || ''}
                data-testid="table-search-input"
                startAdornment={searchText === '' && (
                    <InputAdornment
                        position="start"
                        className={classes.adorment}
                    >
                        <Icon className={classes.searchIcon}>search</Icon>
                    </InputAdornment>
                )}
            />
            <div className={classes.clear}>
                {searchText !== '' && (
                    <IconButton
                        className={classes.close}
                        onClick={closeSearch}
                        size="large"
                    >
                        <Icon>close</Icon>
                    </IconButton>
                )}
            </div>
        </div>
    )
}

export default SearchControl
