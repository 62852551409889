import React from 'react'
import {
    Link,
} from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import Label from 'app/shared-components/Label'
import LabelPanel from 'app/shared-components/LabelPanel'
import {
    NotificationIconProps,
} from '../NotificationIcon/notificationIcon.types'

import {
    SizeLink, TypeLink,
} from './skyNetLink.types'
import useStyles from './SkyNetLink.style'

const defaultProps = {
    size: SizeLink.M,
    type: TypeLink.INTERNAL,
    title: undefined,
    state: undefined,
    text: undefined,
    link: undefined,
    notificationIconProps: undefined,
    className: undefined,
    value: undefined,
}

const SkyNetLink = ({
    size,
    type,
    link,
    state,
    text,
    title,
    notificationIconProps,
    className,
    value,
}: {
    text?: string,
    link?: string,
    size?: SizeLink,
    type?: TypeLink,
    title?: string,
    state?: Record<string, any>,
    notificationIconProps?: NotificationIconProps,
    className?: string,
    value?: string,
}) => {
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <div className={className}>
            {title
                && (
                    <LabelPanel notificationIconProps={notificationIconProps}>
                        <Label title={title} />
                    </LabelPanel>
                )}
            {link
                ? (
                    <div className={classes.wrapper}>
                        <Link
                            to={{
                                pathname: link,
                                state,
                            }}
                            target={type === TypeLink.EXTERNAL ? '_blank' : undefined}
                            rel={type === TypeLink.EXTERNAL ? 'noopener noreferrer' : undefined}
                            className={cx(classes.link, classes[size])}
                        >
                            <div className={classes.text}>{text || value}</div>
                            { type === TypeLink.INTERNAL_WITH_ICON && (
                                <ArrowForwardIcon className={classes[`${size}Icon`]} />
                            )}
                            { type === TypeLink.EXTERNAL && (
                                <OpenInNewIcon className={classes[`${size}Icon`]} />
                            )}
                        </Link>
                    </div>
                ) : '-'}
        </div>
    )
}

SkyNetLink.defaultProps = defaultProps

export default SkyNetLink
