import React from 'react'
import groupBy from 'lodash/groupBy'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import contractBasisRequests from './ContractBasis.requests'

import ContractBasisCreate from './ContractBasisCreate'
import ContractBasisCreateQuote from './ContractBasisCreateQuote'
import tableConfig from './ContractBasis.config'
import ContractBasisDetails from './ContractBasisDetails'
import AdditionalFees from './AdditionalFees'
import LaneGroups from './LaneGroups'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ContractBasisTable = () => {
    const tabs: SidebarTab[] = [
        {
            url: 'contract-basis',
            label: 'Contract Basis',
            Component: ContractBasisDetails,
            activeComparer: (pathname, tabUrl) => {
                const pathnamesOccurrences = groupBy(pathname.split('/'))

                if (pathnamesOccurrences[tabUrl]?.length >= 2) {
                    return true
                }
                return false
            },
        },
        {
            url: 'additional-fees',
            label: 'Additional Fees',
            Component: AdditionalFees,
            activeComparer: (pathname) => {
                return pathname.split('/').includes('additional-fees')
            },
        },
        {
            url: 'lane-groups',
            label: 'Lane Groups',
            Component: LaneGroups,
            activeComparer: (pathname) => {
                return pathname.split('/').includes('lane-groups')
            },
        },
        {
            url: 'create-quote',
            label: 'Create Quote',
            Component: ContractBasisCreateQuote,
        },
    ]

    return (
        <SkyNetTable
            name={contractBasisRequests.domainName}
            createForm={ContractBasisCreate}
            tableControls={tableControls}
            tabs={tabs}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default ContractBasisTable
