import reducer, {
    toggleLocalTime,
    selectIsLocalTime,
} from 'app/store/Settings/settings.slice'

export {
    toggleLocalTime,
    selectIsLocalTime,
}

export default reducer
