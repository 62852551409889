import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetServiceCenterById from '../hooks/useGetServiceCenterById'
import ServiceCenterForm from '../ServiceCenterForm'

const ServiceCenterOverview = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetServiceCenterById(id)

    return (
        <StatusHandler
            isSuccess={isDataReady && Boolean(data)}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ServiceCenterForm
                data={data}
                exists
                disabled
            />
        </StatusHandler>
    )
}

export default ServiceCenterOverview
