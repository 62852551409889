import React, {
    useCallback,
    useMemo,
} from 'react'
import noop from 'lodash/noop'

import Radio from 'app/shared-components/Radio'
import Checkbox from 'app/shared-components/Checkbox'

import formatSensorData from 'app/Apps/utils/formatSensorData'
import DownloadOptions from 'app/shared-components/DownloadOptions'

import useStyles from './TemperatureChartOptions.style'

type Props = {
    showTemperatureRangeOption?: boolean,
    showPdfButton?: boolean,
    showCsvButton?: boolean,
    showTemperatureRangeAllOptions?: boolean,
    sensorData?: any[],
    sensorCodes?: any[],
    showTempRange: boolean,
    onCheckShowTempRange: (value: boolean)=> void,
    serialNumber?: string,
    printChart?: ()=>void,
    selectedTemperatureRange?: string,
    radioOptions?: {
        label: string,
        value: string,
    }[],
    onChangeSelectedTemperatureRange?: (value: string)=> void,
    isContainer?: boolean,
    showDoorEventOption?: boolean,
    showDoorEvent?: boolean,
    onCheckShowDoorEvent?: (value:boolean)=>void,
    showVirtualLogger?: boolean,
    setShowVirtualLogger?: (value:boolean)=>void,
}

const defaultProps: Partial<Props> = {
    showTemperatureRangeOption: false,
    showPdfButton: false,
    showCsvButton: false,
    showTemperatureRangeAllOptions: false,
    sensorData: undefined,
    sensorCodes: undefined,
    serialNumber: '',
    selectedTemperatureRange: '',
    radioOptions: [],
    printChart: noop,
    onChangeSelectedTemperatureRange: noop,
    isContainer: false,
    showDoorEventOption: false,
    showDoorEvent: false,
    onCheckShowDoorEvent: noop,
    showVirtualLogger: false,
    setShowVirtualLogger: undefined,
}

const TemperatureChartOptions = ({
    showTemperatureRangeOption,
    showPdfButton,
    showCsvButton,
    showTemperatureRangeAllOptions,
    serialNumber,
    showTempRange,
    onCheckShowTempRange,
    sensorData,
    sensorCodes,
    printChart,
    selectedTemperatureRange,
    radioOptions,
    onChangeSelectedTemperatureRange,
    isContainer,
    showDoorEventOption,
    showDoorEvent,
    onCheckShowDoorEvent,
    showVirtualLogger,
    setShowVirtualLogger,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const sensorDataUpdated = useMemo(() => {
        if (!sensorData) {
            return undefined
        }

        return formatSensorData(sensorData)
    }, [sensorData])

    const virtualLoggerOnChange = useCallback((value: boolean) => {
        if (value) {
            onCheckShowDoorEvent(false)
        }

        setShowVirtualLogger(value)
    }, [
        setShowVirtualLogger,
        onCheckShowDoorEvent,
    ])

    return (
        <DownloadOptions
            sensorData={sensorDataUpdated}
            sensorCodes={sensorCodes}
            serialNumber={serialNumber}
            printChart={printChart}
            isContainer={isContainer}
            showPdfButton={showPdfButton}
            showCsvButton={showCsvButton}
        >
            {showDoorEventOption && (
                <Checkbox
                    data-testid="door-event"
                    value={showDoorEvent}
                    onChange={onCheckShowDoorEvent}
                    title="Door Events"
                    name="DoorEvents"
                    disabled={showVirtualLogger}
                />
            )}
            {showTemperatureRangeOption && (
                <Checkbox
                    data-testid="temp-range"
                    value={showTempRange}
                    onChange={onCheckShowTempRange}
                    title="Temperature Range"
                    name="temperatureRange"
                />
            )}
            {showTemperatureRangeAllOptions && (
                <Radio
                    className={classes.selectedTemperatureRange}
                    name="selectedTemperatureRange"
                    compact
                    value={selectedTemperatureRange}
                    options={radioOptions}
                    onChange={onChangeSelectedTemperatureRange}
                />
            )}
            {setShowVirtualLogger && (
                <Checkbox
                    name="virtualLogger"
                    title="Virtual Logger"
                    value={showVirtualLogger}
                    onChange={virtualLoggerOnChange}
                />
            )}
        </DownloadOptions>
    )
}

TemperatureChartOptions.defaultProps = defaultProps

export default TemperatureChartOptions
