import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'

const useGetUserRoles = () => {
    const {
        mutateAsync,
    } = useMutation({
        mutationFn: (token: string) => {
            return simpleApi({
                url: 'user/roles',
                method: 'GET',
                token,
            }).then((res) => {
                return res.map(({
                    name,
                }) => {
                    return name
                })
            })
        },
    })

    return mutateAsync
}

export default useGetUserRoles
