import {
    useMemo,
} from 'react'
import {
    useQuery,
} from '@tanstack/react-query'
import {
    useAuth,
} from 'app/Auth'

import getAvatar from './getAvatar'

const useUserAvatar = () => {
    const {
        microsoftData,
    } = useAuth()

    const microsoftToken = useMemo(() => {
        return microsoftData?.access_token
    }, [microsoftData])

    return useQuery({
        queryKey: [
            'getAvatar',
            {
                microsoftToken,
            },
        ],
        queryFn: () => {
            return getAvatar(microsoftToken)
        },
        enabled: Boolean(microsoftToken),
        retry: false,
    })
}

export default useUserAvatar
