import React, {
    useMemo,
} from 'react'
import SimpleTable, {
    RowComponent,
} from 'app/shared-components/SimpleTable'
import Card from 'app/shared-components/Card'
import useValidation from 'app/hooks/useValidation'

import {
    Column,
} from 'app/types/simpletable.types'
import useLabel from 'app/hooks/useLabel'
import getColumns, {
    QuoteOpportunityTable as QuoteOpportunityTableType,
} from './QuoteOpportunitiesTable.config'
import useStyles from './QuoteOpportunitiesTable.styles'

const QuoteOpportunitiesTable = ({
    data, onChange, disabled, renderButtonPanel,
}: {
    data: QuoteOpportunityTableType[],
    onChange: (...args: any[]) => void,
    disabled: boolean,
    renderButtonPanel: ({
        isValid: boolean,
    }) => JSX.Element
}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()

    const columns = useMemo((): Column<keyof QuoteOpportunityTableType>[] => {
        return getColumns({
            classes,
            disabled,
            l,
            handleChange: onChange,
        })
    }, [
        disabled,
        onChange,
        classes,
        l,
    ])

    const {
        isValid,
    } = useValidation({
        fields: columns,
        values: data.filter(({
            selected,
        }) => {
            return selected
        }),
    })

    return (
        <>
            <Card title="Opportunities">
                <SimpleTable
                    name="QuoteOpportunitiesTable"
                    columns={columns}
                    data={data}
                    component={RowComponent}
                    onChange={onChange}
                    disabled
                    classNames={{
                        tableCellHead: classes.tableCellHead,
                        tableCell: classes.tableCellHead,
                    }}
                />
            </Card>
            { renderButtonPanel({
                isValid,
            }) }
        </>

    )
}

export default QuoteOpportunitiesTable
