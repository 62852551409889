import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        fullHeight: {
            minHeight: '100%',
        },
        container: {
            display: 'flex',
            width: '100%',
        },
        location: {
            flex: 2,
            marginRight: theme.spacing(2),
        },
        information: {
            width: '100%',
            flex: 1,
        },
        informationCard: {
            display: 'flex',
        },
        timestamp: {
            color: `${theme.palette.success.main} !important`,
        },
    }
})

export default useStyles
