import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import {
    useArchivedTableControl,
} from 'app/shared-components/TableControls/ShowArchived'
import LaneGroupsTable from 'app/Apps/Pricing/ContractBasis/components/LaneGroupsTable'
import StatusHandler from 'app/shared-components/StatusHandler'
import Card from 'app/shared-components/Card'
import LaneGroupRequests from 'app/Apps/Pricing/LaneGroups/LaneGroup.requests'
import useRenderTableControlPanel from 'app/shared-components/CollapsibleTable/useRenderTableControlPanel'
import {
    App,
} from 'app/types/common.enums'

import Collapse from '@mui/material/Collapse'
import LaneGroupForm from 'app/Apps/Pricing/LaneGroups/LaneGroupForm'
import useCollapsablePanel from 'app/shared-components/CollapsibleTable/hooks/useCollapsablePanel'
import useStyles from './LaneGroups.styles'
import useGetContractBasis from '../hooks/useGetContractBasis'

const LaneGroups = ({
    id: contractId,
}: { id: number }) => {
    const history = useHistory()
    const {
        classes,
    } = useStyles()

    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useGetContractBasis(contractId)

    const showArchived = useArchivedTableControl(LaneGroupRequests.domainName)

    const {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    } = useCollapsablePanel(data?.currency)

    const renderTableControlPanel = useRenderTableControlPanel({
        title: 'Lane Groups',
        stateName: LaneGroupRequests.domainName,
        createFormOpened,
        openCreateForm,
        closeCreateForm,
    })

    const onRowClick = useCallback(({
        id,
    }) => {
        history.push(`/apps/${App.PRICING}/lane-groups/all/edit/${id}/overview`, {
            filter: {
                customerQuotationLocationName: [data?.customerQuotation?.locationName],
                pricingAccountCompanyName: [data?.account?.company?.companyName],
                contractBasisCurrency: [data?.currency],
            },
        })
    }, [
        history,
        data,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <div
                data-testid="LaneGroupsTable"
                className={classes.root}
            >
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="LaneGroupsTable-card"
                >
                    <div className={classes.container}>
                        <Collapse in={createFormOpened}>
                            <LaneGroupForm
                                onSuccess={onRowClick}
                                onCancel={closeCreateForm}
                                requestParam={LaneGroupRequests.create({
                                    contractId,
                                })}
                            />
                        </Collapse>
                        <LaneGroupsTable
                            contractId={contractId}
                            onRowClick={onRowClick}
                            renderControlPanel={renderTableControlPanel}
                            showArchived={showArchived}
                        />
                    </div>
                </Card>
            </div>
        </StatusHandler>
    )
}

export default LaneGroups
