export const convertFilterValueToObjectSelector = (value: (string|number)[]) => {
    return {
        id: value?.[0],
    }
}

export const convertObjectValueToFilter = (value: {id: string|number}) => {
    if (value?.id) {
        return [value.id]
    }
    return undefined
}
