import React, {
    useCallback,
    useMemo,
} from 'react'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import FormWithControls from 'app/shared-components/FormWithControls'
import StatusHandler from 'app/shared-components/StatusHandler'
import useEnumValues from 'app/hooks/useEnumValues'

import getFields from './ChangeData.fields'
import useStyles from './ChangeData.styles'
import useGetContainerType from '../hooks/useGetContainerType'
import requests from '../ContainerType.request'

type Props = {
    id: string,
    disabled?: boolean
}

const defaultProps: Partial<Props> = {
    disabled: false,
}

const ChangeData = ({
    id,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetContainerType(id)

    const fields = useFieldsWithClassName(
        getFields(getAllowedValues), classes,
    )

    // need to be fixed: it accepts only one object
    // however Attachment component accepts array of the ids
    const modifyDataBeforeSend = useCallback((value) => {
        if (!value?.containerPicture?.length) {
            return {
                ...value, containerPicture: undefined,
            }
        }
        return {
            ...value,
            containerPicture: (value?.containerPicture || [])
                .at(value?.containerPicture?.length - 1),
        }
    }, [])

    const formData = useMemo(() => {
        return {
            ...data,
            containerPicture: data?.containerPicture && [data?.containerPicture],
        }
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                onSuccess={invalidate}
                name={requests.name}
                value={formData}
                modifyDataBeforeSend={modifyDataBeforeSend}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                fields={fields}
                exists={Boolean(data)}
                requestParams={requests.update()}
                disabled={disabled}
            />
        </StatusHandler>
    )
}

ChangeData.defaultProps = defaultProps

export default ChangeData
