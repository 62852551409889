import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        gridWrapper: {
            width: '50%',
        },
        skyMindId: {
            minWidth: 100,
            width: '60%',
        },
    }
})

export default useStyles
