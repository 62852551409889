import {
    makeStyles,
} from 'app/tss'

const loggerInformation = 'loggerInformation'
const pairingInformation = 'pairingInformation'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridTemplateAreas: `"${loggerInformation} ${loggerInformation} ${loggerInformation} ${pairingInformation}"`,
        },
        loggerInformation: {
            gridArea: loggerInformation,
        },
        pairingInformation: {
            gridArea: pairingInformation,
        },
        title: {
            textAlign: 'center',
        },
    }
})

export default useStyles
