import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    Company,
} from '../../Companies.types'

const columns = ({
    l,
}: {
    l: (l:string) => string,
}): RichTableColumns<keyof Company>[] => {
    return [
        {
            id: 'companyName',
            headerProps: {
                children: l('Company.companyName'),
            },
            mapCellProps: 'companyName',
        },
        {
            id: 'website',
            headerProps: {
                children: l('Company.website'),
            },
            mapCellProps: 'website',
        },
    ]
}

export default columns
