import strToDate from './strToDate'

const toDateWithHoursSafe = (
    d: Date | number | string, hours: [number, number, number],
): number | undefined => {
    if (!d) {
        return undefined
    }
    const date = (typeof d === 'string') ? strToDate(d) : new Date(d)

    return date.setHours(...hours)
}

export default toDateWithHoursSafe
