import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    Chip,
} from '@mui/material'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import {
    ProductTypeContainerTempRange,
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    ProductType,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import useStyles from './ValidationBookedContainers.style'
import getSelectedAssetsMap from './getSelectedAssetsMap'
import config from './ProductType.config'
import {
    BookedContainer,
} from '../bookedContainers.types'

type Props = {
    requiredContainers: RequiredContainersCustomer[] | RequiredContainersInternal[],
    selectedContainers: BookedContainer[],
    isInternalType: boolean,
    disabled: boolean,
}

const isInternalTypeContainer = (container : RequiredContainersCustomer
    | RequiredContainersInternal): container is RequiredContainersInternal => {
    return (container as RequiredContainersInternal).containerType !== undefined
}

const ValidationBookedContainers = ({
    selectedContainers,
    isInternalType,
    requiredContainers,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValues = useEnumValues()(ProductType)

    const {
        data: productTypes,
    } = useRequest<ProductTypeContainerTempRange[]>({
        key: RequestKeys.getProductTypesContractBasis,
        requestFunc: RequestFn.getEntity(config.ProductType.requestFn),
        enabled: Boolean(!isInternalType),
    })

    const containerQuantities: Record<string | ProductType, number> = useMemo(() => {
        return getSelectedAssetsMap({
            selectedContainers,
            productTypes,
            isInternalType,
        })
    }, [
        productTypes,
        isInternalType,
        selectedContainers,
    ])

    const getIconData = useCallback((containerModel: string, quantity: number): {
        iconName: string,
        title: string,
    } => {
        if (!containerQuantities[containerModel]
            || containerQuantities[containerModel] < quantity) {
            return {
                iconName: 'pending_grey',
                title: 'Not enough containers selected',
            }
        }

        if (containerQuantities[containerModel] > quantity) {
            return {
                iconName: 'circle_cross_red',
                title: 'Too many containers selected',
            }
        }

        return {
            iconName: 'circle_tick_mark_blue',
            title: 'All containers selected',
        }
    }, [containerQuantities])

    return (
        <div className={classes.root}>
            {!disabled && <span className={classes.title}>You need to select:</span>}
            {
                requiredContainers.map((item: RequiredContainersCustomer
                    | RequiredContainersInternal) => {
                    const {
                        quantity,
                    } = item
                    const container = isInternalType && isInternalTypeContainer(item)
                        ? item.containerType?.containerModel
                        : (item as RequiredContainersCustomer).productType
                    const {
                        iconName,
                        title,
                    } = getIconData(container, quantity)

                    return (
                        <div
                            className={classes.info}
                            key={`key-${container}`}
                        >
                            <Chip
                                className={classes.chip}
                                label={`${quantity} x ${isInternalType ? container : allowedValues[container]}`}
                            />
                            <img
                                className={classes.icon}
                                src={`assets/images/${iconName}.svg`}
                                title={title}
                                alt={title}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ValidationBookedContainers
