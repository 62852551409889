import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        link: {
            paddingTop: theme.spacing(0.5),
            color: theme.palette.secondary.main,
        },
    }
})

export default useStyles
