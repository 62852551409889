import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            width: '100%',
        },
        header: {
            display: 'flex',
            justifyContent: 'right',
            maxHeight: 40,
        },
        preselect: {
            justifyContent: 'space-between',
        },
        controls: {
            position: 'relative',
            zIndex: 2,
            height: theme.shape.tabHeight,
            padding: '3px 10px 0 10px',
            backgroundColor: theme.palette.secondary[50],
            borderRadius: '10px 10px 0px 0px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.secondary[300],
            borderBottom: `1px solid ${theme.palette.secondary[50]}`,
        },
        selectors: {
            minWidth: 400,
        },
        content: {
            position: 'relative',
            backgroundColor: theme.palette.secondary[50],
            boxShadow: 'none',
            border: `1px solid ${theme.palette.secondary[300]}`,
            borderRadius: '4px 0 0 0',
            padding: `${theme.spacing(0.5)} !important`,
            paddingRight: `${theme.spacing(0.75)} !important`,
        },
        icon: {
            color: theme.palette.secondary[600],
            padding: '3px 5px 3px 2px',
        },
        tabsWrapper: {
            marginLeft: theme.spacing(2),
            display: 'inline-block',
            maxWidth: 800,
        },
        pagination: {
            width: '100%',
            display: 'flex',
            justifyContent: 'right',
            paddingTop: theme.spacing(1),
        },
    }
})

export default useStyles
