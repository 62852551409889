import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            paddingRight: theme.spacing(0.5),
        },
    }
})

export default useStyles
