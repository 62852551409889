import {
    RequestConfig,
} from 'app/types/request.types'
import {
    AllowedProductTypes,
} from './serviceCenters.types'

type Request = {
    domainName: string,
    GetServiceCenterById: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    UpdateServiceCenter: ({
        serviceCenterId,
    }) => RequestConfig,
    CreateServiceCenter: () => RequestConfig,
    GetAvailability: {
        requestFn: ({
            params,
        }: {
            params: {
                serviceCenter: {id: number},
                productType: AllowedProductTypes,
            }}) => RequestConfig,
    },
    GetDryStorage: {
        requestFn: ({
            params,
        }: {
            params: {
                serviceCenter: {id: number},
            }}) => RequestConfig,
    },
    GetTruckCapacity: {
        requestFn: ({
            params,
        }: {
            params: {
                serviceCenter: {id: number},
            }}) => RequestConfig,
    },
}

const request: Request = {
    domainName: 'ServiceCenter',
    GetServiceCenterById: ({
        id,
    }) => {
        return {
            url: `servicecenter/${id}`,
        }
    },
    UpdateServiceCenter: ({
        serviceCenterId,
    }) => {
        return {
            url: `servicecenter/${serviceCenterId}`,
            method: 'PUT',
        }
    },
    CreateServiceCenter: () => {
        return {
            url: 'servicecenter',
            method: 'POST',
        }
    },
    GetAvailability: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'servicecenter/monitoring/container-availability',
                method: 'POST',
                data: params,
            }
        },
    },
    GetDryStorage: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'servicecenter/monitoring/dry-storage',
                method: 'POST',
                data: params,
            }
        },
    },
    GetTruckCapacity: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'servicecenter/monitoring/truck-capacity',
                method: 'POST',
                data: params,
            }
        },
    },
}

export default request
