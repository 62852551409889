import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetLoggerContainerInformationBySerialNumber from '../../hooks/useGetLoggerContainerInformationBySerialNumber'
import {
    Container,
} from '../../containers.types'
import useGetContainerBySerialNumber from '../../hooks/useGetContainerBySerialNumber'
import ContainerSensorData from './ContainerSensorData'

type Props = {
    id: string,
}
const SensorDataContainer = ({
    id: serialNumber,
}: Props) => {
    const {
        data = {} as Container,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContainerBySerialNumber(serialNumber)

    const {
        data: loggerContainerInformation,
        isDataReady: loggerInformationIsDataReady,
        isFetching: loggerInformationIsFetching,
    } = useGetLoggerContainerInformationBySerialNumber(serialNumber)

    return (
        <StatusHandler
            isSuccess={isDataReady && loggerInformationIsDataReady}
            isFetching={isFetching && loggerInformationIsFetching}
            isError={isError}
            error={error}
        >
            <ContainerSensorData
                data={data}
                loggerContainerInformation={loggerContainerInformation}
                serialNumber={serialNumber}
            />
        </StatusHandler>
    )
}

export default SensorDataContainer
