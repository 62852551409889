/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import get from 'lodash/get'
import noop from 'lodash/noop'

import Form from './Form'
import Group from './Group'

import useStyles from './DomainObjectForm.styles'

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.objectOf(PropTypes.any),
    fields: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                title: PropTypes.string,
            }),
            PropTypes.any,
        ]),
    ).isRequired,
    className: PropTypes.string,
    classNames: PropTypes.shape({
        gridWrapper: PropTypes.string,
        gridCardWrapper: PropTypes.string,
    }),
    wrapper: PropTypes.oneOf([
        'noCard',
        'simple',
        'card',
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    noCard: PropTypes.bool,
    exists: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    setErrors: PropTypes.func,
}

const defaultProps = {
    name: '',
    className: '',
    value: undefined,
    classNames: {},
    wrapper: undefined,
    onChange: noop,
    disabled: false,
    noCard: false,
    exists: false,
    errors: null,
    setErrors: undefined,
}

function isGroup(fields) {
    return get(fields, '[0].fields')
}

const DomainObjectForm = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        name,
        fields,
        className,
    } = props

    const Comp = isGroup(fields) ? Group : Form

    return (
        <div
            className={clsx(classes.root, className)}
            data-testid={`form-${name}`}
        >
            <Comp
                {...props}
            />
        </div>
    )
}

DomainObjectForm.propTypes = propTypes
DomainObjectForm.defaultProps = defaultProps

export default DomainObjectForm
