import React, {
    useCallback,
    useEffect,
    useState,
    useMemo,
} from 'react'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    OrderStep,
    StepConfiguration,
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LocationType, StepTypeDetailed,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import validateForm from 'app/utils/validateForm'

import useGetFieldsWithConfiguration from '../../hooks/useGetFieldsWithConfiguration'
import isTypeTransport from '../../utils/isTypeTransport'
import getStepRoadFormFields from './fields/stepRoadForm.fields'
import getStepAirFormFields from './fields/stepAirForm.fields'
import getStepEventFormFields from './fields/stepEventForm.fields'
import getStepSeaFormFields from './fields/stepSeaForm.fields'
import getStepCustomsFormFields from './fields/stepCustomsForm.fields'
import getStepConfirmationFormFields from './fields/stepConfirmationForm.fields'
import useStyles from './OrderStepsForm.style'

type OnChangeEvent = {
    target: {
        name: number,
        value: Partial<OrderStep>,
    },
}

type Props = {
    value: Partial<OrderStep>,
    onChange: (changeValue: Partial<OrderStep>, event: OnChangeEvent) => void,
    stepConfiguration: StepConfiguration,
    location?: {id: number},
    pickupLocationContacts?: {id: number}[],
    baseLeaseUntilTimestamp?: string,
    disabled: boolean,
    isModified: boolean,
    exists: boolean,
    isFirstStep: boolean,
    setIsFormValid?: (isFormValid: boolean) => void,
}

const defaultProps: Partial<Props> = {
    location: undefined,
    baseLeaseUntilTimestamp: undefined,
    pickupLocationContacts: undefined,
    setIsFormValid: undefined,
}

const PICKUP_LOCATION = 'pickupLocation'
const LOCATION = 'location'
const DOMAIN_NAME = 'OrderStep'
const TRANSPORT_MODE_LCL = 'LCL'
const FIELDS = {
    ROAD: getStepRoadFormFields,
    SEA: getStepSeaFormFields,
    AIR: getStepAirFormFields,
    CUSTOMS: getStepCustomsFormFields,
    EVENT: getStepEventFormFields,
    CONFIRMATION: getStepConfirmationFormFields,
}
const FILTER_LOCATION_TYPE = {
    SEA: LocationType.SEAPORT,
    AIR: LocationType.AIRPORT,
}

const OrderStepsForm = (props: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        stepConfiguration,
        value,
        location: defaultLocation,
        baseLeaseUntilTimestamp,
        pickupLocationContacts,
        isModified,
        onChange,
        disabled,
        exists,
        isFirstStep,
        setIsFormValid,
    } = props

    const {
        stepType,
        transportModeRoad,
        transportModeForwarder,
        forwarder,
        pickupLocation,
        deliveryLocation,
        informationEntered = moment().format('DD.MM.YYYY HH:mm ZZ'),
        location,
        transportModeSea,
        stepTypeDetailed,
    } = value

    const [
        defaultPickupLocationContacts,
        setDefaultPickupLocationContacts,
    ] = useState<{id: number}[]>()

    const onChangeValue = useCallback((newValue: Partial<OrderStep>): void => {
        onChange(newValue, {
            target: {
                name: value.id,
                value: newValue,
            },
        })
    }, [
        onChange,
        value.id,
    ])

    const [
        defaultTransportModeRoad,
        setTransportModeRoad,
    ] = useState<TransportModeRoad>(transportModeRoad)

    const [
        defaultTransportModeForwarder,
        setTransportModeForwarder,
    ] = useState<TransportModeRoad>(transportModeForwarder)

    const isRoadDelivery = value.stepTypeDetailed === StepTypeDetailed.ROAD_DELIVERY

    const isTransportStep: boolean = useMemo(() => {
        return isTypeTransport(stepType)
    }, [stepType])

    const locationProperty: string = useMemo(() => {
        return isTransportStep ? PICKUP_LOCATION : LOCATION
    }, [isTransportStep])

    const fieldsWithClassName = useFieldsWithClassName(FIELDS[stepType]({
        locationId: location?.id,
        forwarderId: forwarder?.id,
        pickupLocationId: pickupLocation?.id,
        deliveryLocationId: deliveryLocation?.id,
        informationEntered,
        baseLeaseUntilTimestamp,
        filterLocationType: FILTER_LOCATION_TYPE[stepType],
        exists,
        isFirstStep,
        transportModeSea,
        transportModeForwarder,
        transportModeRoad,
        getAllowedValues,
        isRoadDelivery,
        stepTypeDetailed,
    }), classes)

    const fieldsWithConfiguration = useGetFieldsWithConfiguration(
        stepConfiguration,
        fieldsWithClassName,
        disabled,
    )

    const modifiedFields = useMemo(() => {
        return fieldsWithConfiguration.map((field) => {
            return {
                ...field,
                fields: field.fields.map((item) => {
                    if (item.name === 'baseLeaseUntilTimestamp') {
                        return item
                    }
                    return {
                        ...item,
                        disabled: false,
                    }
                }),
            }
        })
    }, [fieldsWithConfiguration])

    const fields = useMemo(() => {
        return isModified ? modifiedFields : fieldsWithConfiguration
    }, [
        fieldsWithConfiguration,
        isModified,
        modifiedFields,
    ])

    useEffect(() => {
        if (setIsFormValid) {
            setIsFormValid(
                validateForm({
                    fields,
                    value,
                }),
            )
        }
    }, [
        setIsFormValid,
        fields,
        value,
    ])

    useEffect(() => {
        let updatedValue = {}

        if (!isRoadDelivery && (defaultTransportModeRoad !== transportModeRoad)) {
            setTransportModeRoad(transportModeRoad)
            updatedValue = {
                ...updatedValue,
                numberOfTrucks: undefined,
                numberOfVans: undefined,
            }
        }

        if (isRoadDelivery && (defaultTransportModeForwarder !== transportModeForwarder)) {
            setTransportModeForwarder(transportModeForwarder)
            updatedValue = {
                ...updatedValue,
                numberOfTrucks: undefined,
                numberOfVans: undefined,
            }
        }

        if (transportModeSea === TRANSPORT_MODE_LCL && value.seafreightContainerSize) {
            updatedValue = {
                ...updatedValue,
                seafreightContainerSize: undefined,
            }
        }

        if (!exists && defaultLocation?.id && defaultLocation?.id !== value[locationProperty]?.id) {
            updatedValue = {
                ...updatedValue,
                [locationProperty]: defaultLocation,
            }
        }

        if (!exists && stepTypeDetailed === StepTypeDetailed.EVENT_EVENT) {
            updatedValue = {
                ...updatedValue,
                eventLocation: defaultLocation,
            }
        }

        if (!exists && stepTypeDetailed === StepTypeDetailed.ROAD_FROM_EVENT) {
            updatedValue = {
                ...updatedValue,
                pickupLocationEvent: defaultLocation,
            }
        }

        if (!isFirstStep && defaultPickupLocationContacts !== pickupLocationContacts) {
            setDefaultPickupLocationContacts(pickupLocationContacts)
            updatedValue = {
                ...updatedValue,
                pickupLocationContacts,
            }
        }

        if (isEmpty(updatedValue)) {
            return
        }

        onChangeValue({
            ...value,
            ...updatedValue,
        })
    }, [
        isRoadDelivery,
        defaultLocation,
        defaultPickupLocationContacts,
        defaultTransportModeForwarder,
        defaultTransportModeRoad,
        exists,
        isFirstStep,
        locationProperty,
        onChangeValue,
        pickupLocationContacts,
        transportModeForwarder,
        transportModeRoad,
        transportModeSea,
        value,
        stepTypeDetailed,
    ])

    return (
        <DomainObjectForm
            name={DOMAIN_NAME}
            className={classes.gridWrapper}
            value={value}
            fields={fields}
            onChange={onChangeValue}
            wrapper="simple"
            disabled={disabled}
        />
    )
}

OrderStepsForm.defaultProps = defaultProps

export default OrderStepsForm
