import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import Label from 'app/shared-components/Label'

import getReadingThresholdType, {
    READING_THRESHOLD_TYPE,
} from 'app/utils/getReadingThresholdType'
import Age from './Age'

import useStyles from './TemperatureAge.style'

const propTypes = {
    value: PropTypes.number,
    title: PropTypes.string,
}

const defaultProps = {
    value: undefined,
    title: '',
}

const TemperatureAge = (props) => {
    const {
        value,
        title,
    } = props

    const {
        classes,
    } = useStyles()

    const readingType = useMemo(() => {
        return getReadingThresholdType(value)
    }, [value])

    const className = useMemo(() => {
        return clsx({
            [classes.oldReading]: readingType === READING_THRESHOLD_TYPE.OLD,
            [classes.recentReading]: readingType === READING_THRESHOLD_TYPE.RECENT,
            [classes.liveReading]: readingType === READING_THRESHOLD_TYPE.LIVE,
        })
    }, [
        classes,
        readingType,
    ])

    const icon = useMemo(() => {
        if (value) {
            return (readingType === READING_THRESHOLD_TYPE.LIVE)
                ? (<CloudDoneIcon className={classes.cloudIcon} />)
                : (<CloudOffIcon className={classes.cloudOffIcon} />)
        }

        return null
    }, [
        readingType,
        classes,
        value,
    ])

    return (
        <div>
            {title && (
                <Label
                    title={title}
                />
            )}
            <div className={classes.wrapper}>
                <div
                    className={title ? classes.iconWrapperWithLabel : classes.iconWrapper}
                >
                    {icon}
                </div>
                <div
                    data-testid="temperature-age-div"
                    className={title ? clsx(className, classes.text) : className}
                >
                    <Age
                        value={value}
                    />
                </div>
            </div>
        </div>
    )
}

TemperatureAge.propTypes = propTypes
TemperatureAge.defaultProps = defaultProps

export default TemperatureAge
