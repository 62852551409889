import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        square: {
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.5),
        },
        grey: {
            background: theme.palette.secondary[600],
        },
        yellow: {
            background: theme.palette.common.yellow,
        },
        blue: {
            background: theme.palette.primary.main,
        },
        green: {
            background: theme.palette.primary.lightGreen,
        },
        summary: {
            paddingLeft: theme.spacing(0.5),
        },
    }
})

export default useStyles
