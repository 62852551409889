import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from '../regionalGroups.request'

type LinkedOpportunities = {
    hasLinkedOpportunities: boolean,
}

export default (id, disabled) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<LinkedOpportunities>({
        key: RequestKeys.getLinkedOpportunity,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.getLinkedOpportunity),
        enabled: !disabled,
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}
