import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requests from '../AccountLanes.requests'
import {
    AccountLaneProductTypes,
} from '../AccountLanes.types'

const useGetAccountLane = (id) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<AccountLaneProductTypes>({
        key: RequestKeys.getLaneRequestedAwardedProductTypes,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.getRequestAwardedLaneProductTypes),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetAccountLane
