import React, {
    useMemo,
} from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'

import {
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    StepTypeDetailed,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import getTimeStampData from './utils/getTimeStampData'
import getLocationData from './utils/getLocationData'
import StepDate from '../StepDate'
import StepLocation from '../StepLocation'
import StepProgressItem from '../StepProgressItem'
import useStyles from './OrderStepCard.style'
import {
    LocationDataType,
    TimeStampDataType,
} from '../OrderSteps.types'

type Props = {
    value: OrderStep,
    className?: string,
    openForm: () => void,
    isFormOpen?: boolean,
}

const defaultProps = {
    className: '',
    isFormOpen: false,
}

const OrderStepCard = ({
    value,
    className,
    openForm,
    isFormOpen,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValuesStepTypeDetailed = useEnumValues()(StepTypeDetailed)

    const {
        stepTypeDetailed,
        stepProgress,
        stepStatus,
        stepType,
    } = value

    const locationData: LocationDataType[] = useMemo(() => {
        return getLocationData(value)
    }, [value])

    const timeStampData: TimeStampDataType[] = useMemo(() => {
        return getTimeStampData(value)
    }, [value])

    return (
        <div
            className={clsx(
                classes.root,
                className,
                isFormOpen && classes.formOpened,
            )}
            data-testid="card-step-order"
            onMouseDown={openForm}
        >
            <div className={classes.section}>
                <div className={classes.sectionTop}>
                    <img
                        className={classes.icon}
                        src={`assets/images/orderSteps/${stepStatus?.toLowerCase()}.svg`}
                        alt="in progress"
                    />
                    <div className={classes.alignCenter}>
                        <img
                            className={classes.iconTitle}
                            src={`assets/images/orderSteps/${stepType?.toLowerCase()}.svg`}
                            alt={stepTypeDetailed}
                        />
                        <Typography variant="h3">
                            {allowedValuesStepTypeDetailed[stepTypeDetailed]}
                        </Typography>
                    </div>
                </div>
                <div className={classes.sectionBottom}>
                    {stepProgress.map((progress, i) => {
                        return (
                            <StepProgressItem
                                key={`step-progress-item-${progress.id}`}
                                progressStep={progress}
                                previousProgressStep={stepProgress[i - 1]}
                            />
                        )
                    })}
                </div>
            </div>
            <div className={clsx(classes.section, classes.sectionLocation)}>
                {locationData.length > 0 && (
                    <StepLocation
                        value={locationData}
                    />
                )}
            </div>
            <div className={clsx(
                classes.section,
                {
                    [classes.sectionProgress]: timeStampData.length > 1,
                    [classes.sectionLocation]: timeStampData.length === 1,
                },
            )}
            >
                <StepDate value={timeStampData} />
            </div>
        </div>
    )
}

OrderStepCard.defaultProps = defaultProps

export default OrderStepCard
