/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import SideBar from 'app/shared-components/SideBar'
import objectNames from 'app/utils/objectNames'

import useStyles from './DomainObjectCreate.styles'

const propTypes = {
    name: PropTypes.oneOf(objectNames).isRequired,
    onClose: PropTypes.func.isRequired,
    form: PropTypes.func.isRequired,
}

const DomainObjectCreate = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        onClose,
        form: CreateForm,
    } = props

    return (
        <SideBar
            onClose={onClose}
        >
            <div className={classes.contentWrapper}>
                <div className={classes.content}>
                    <CreateForm
                        {...props}
                    />
                </div>
            </div>
        </SideBar>
    )
}

DomainObjectCreate.propTypes = propTypes

export default DomainObjectCreate
