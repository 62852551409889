import {
    useContext,
} from 'react'
import SharedContext from './SharedContext'

function useShareContext<T>():T {
    const context = useContext<T>((SharedContext as unknown) as React.Context<T>)

    return context
}

export default useShareContext
