/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import noop from 'lodash/noop'

import FormWithControls from 'app/shared-components/FormWithControls'
import useStyles from './CreateContainerNote.styles'
import fields from './CreateContainerNote.fields'

import config from '../../ContainerNotes.request'

type Props = {
    name: string,
    id: number,
    objectName?: string,
    isNew?: boolean,
    onCancel?: () => void,
    onSuccess?: () => void,
}

const defaultProps: Partial<Props> = {
    isNew: false,
    onCancel: noop,
    objectName: 'Container',
    onSuccess: noop,
}

const CreateContainerNote = (props: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        name,
        id,
        objectName,
        isNew,
        onCancel,
        onSuccess,
    } = props

    const onSuccessAdded = useCallback(() => {
        onSuccess()
        onCancel()
    }, [
        onCancel,
        onSuccess,
    ])

    return (
        <FormWithControls
            {...props}
            name={name}
            value={{
                containerId: id,
                objectName,
                noteType: 'NOTE',
                systemId: 'SKYNET',
            }}
            className={classes.rowFormContainer}
            classNames={{
                gridWrapper: classes.gridWrapper,
                gridCardWrapper: classes.gridCardWrapper,
            }}
            requestParams={config.create.request()}
            fields={fields}
            exists={!isNew}
            cancelEnabled={isNew}
            onSuccess={onSuccessAdded}
            onCancel={onCancel}
            canDelete
        />
    )
}

CreateContainerNote.defaultProps = defaultProps

export default CreateContainerNote
