/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Paper,
} from '@mui/material'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './ChipSelect.style'

const propTypes = {
    innerProps: PropTypes.objectOf(PropTypes.any).isRequired,
    children: PropTypes.objectOf(PropTypes.any).isRequired,
}

const defaultProps = {}

function Menu(props) {
    const {
        innerProps,
        children,
    } = props

    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <Paper
            square
            className={classes.menu}
            {...innerProps}
        >
            {children}
        </Paper>
    )
}

Menu.propTypes = propTypes
Menu.defaultProps = defaultProps

export default Menu
