/* global google */

import loadScript from 'app/utils/loadScript'

const url = 'https://www.gstatic.com/charts/loader.js'

const loadChart = (chartData, elm, options, onError) => {
    loadScript(url).then(() => {
        google.charts.load('current', {
            packages: [
                'corechart',
                'bar',
            ],
        })

        const drawChart = () => {
            const data = google.visualization.arrayToDataTable(chartData)
            const chart = new google.visualization.BarChart(elm)

            chart.draw(data, options)
        }

        return google.charts.setOnLoadCallback(drawChart)
    })
        .catch((error) => {
            onError([error])
        })
}

export default loadChart
