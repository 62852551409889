import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    Frequency,
} from 'app/types/enums'
import {
    FxRateFields,
} from './FxRate.types'

const config: SkyNetTableConfig<FxRateFields> = {
    name: 'FxRatesTable',
    tabs: [{
        name: 'FxRates',
        url: 'all',
    }],
    fields: [
        {
            name: 'ccyFrom',
            reduced: true,
            filterType: FilterType.Array,
            filterField: 'ccyFrom',
        },
        {
            name: 'ccyTo',
            reduced: true,
            filterType: FilterType.Array,
            filterField: 'ccyTo',
        },
        {
            name: 'exchangeRate',
        },
        {
            name: 'fxRateType',
            filterField: 'fxRateType',
            fxRateType: 'array',
            componentName: 'EnumValue',
            allowedValuesEnum: Frequency,
        },
        {
            name: 'validFrom',
            reduced: true,
        },
        {
            name: 'validTo',
            reduced: true,
        },
    ],
}

export default config
