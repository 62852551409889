import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    AdditionalFeesStatus,
} from 'app/types/enums'
import {
    AlLogisticPricing,
} from './AlLogisticPricing.types'

const config: SkyNetTableConfig<keyof AlLogisticPricing> = {
    name: 'AlLeasingPricing',
    tabs: [{
        name: 'All',
        url: 'all',
    }],
    fields: [
        {
            name: 'airlineName',
            filterField: 'airlineName',
            labelKey: 'Opportunity.airline',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'currency',
            labelKey: 'LaneOpsInformationDto.currency',
            filterField: 'currency',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'status',
            labelKey: 'LaneBaseDto.laneStatus',
            filterField: 'status',
            componentName: 'EnumValue',
            allowedValuesEnum: AdditionalFeesStatus,
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'validFrom',
            labelKey: 'LaneFeesUpdateDto.validFrom',
            filterField: 'validPricingFrom',
            filterType: FilterType.DateRange,
        },
        {
            name: 'validTo',
            filterField: 'validTo',
            labelKey: 'LaneFeesUpdateDto.validTo',
            filterType: FilterType.DateRange,
            componentName: 'LaneDateEnriched',
        },
    ],
}

export default config
