import {
    makeStyles,
} from 'app/tss'

const orderInfo = 'orderInfo'
const billingInformation = 'billingInformation'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${orderInfo} ${orderInfo} ${billingInformation} ${billingInformation}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        orderInfo: {
            gridArea: orderInfo,
        },
        billingInformation: {
            gridArea: billingInformation,
        },
    }
})

export default useStyles
