import addNotationValues from './addNotationValues'

const formatSensorData = (sensorData) => {
    return sensorData.map(({
        t: time,
        d: temperatures,
    }) => {
        return [
            time,
            ...addNotationValues(temperatures),
        ]
    })
}

export default formatSensorData
