import React from 'react'

import SingleObjectSelectorConverter from 'app/shared-components/SingleObjectSelectorConverter'

import {
    LocationSelectorDefaultProps,
    LocationObjectSelectorProps,
} from '../types/LocationSelector.types'
import LocationExpandedViewSelector from '../LocationExpandedViewSelector'

const LocationExpandedViewObjectSelector = ({
    value,
    onChange,
    name,
    title,
    disabled,
    isRequired,
    className,
    classNames,
    filterByCompanyId,
    filterLocationType,
    isApprovedSubContractor,
    'data-testid': dataTestId,
    allowedLocationTypes,
    includeFilters,
    validate,
    setErrors,
}: LocationObjectSelectorProps) => {
    return (
        <SingleObjectSelectorConverter
            selector={LocationExpandedViewSelector}
            value={value}
            onChange={onChange}
            name={name}
            title={title}
            disabled={disabled}
            isRequired={isRequired}
            className={className}
            classNames={classNames}
            filterByCompanyId={filterByCompanyId}
            filterLocationType={filterLocationType}
            isApprovedSubContractor={isApprovedSubContractor}
            data-testid={dataTestId}
            allowedLocationTypes={allowedLocationTypes}
            includeFilters={includeFilters}
            validate={validate}
            setErrors={setErrors}
        />
    )
}

LocationExpandedViewObjectSelector.defaultProps = LocationSelectorDefaultProps

export default LocationExpandedViewObjectSelector
