import React from 'react'
import clsx from 'clsx'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import noop from 'lodash/noop'
import {
    Column,
} from 'app/types/simpletable.types'
import useStyles from '../SimpleTable.styles'

const RowComponent = ({
    value,
    onChange: handleChange,
    columns: tableColumns,
    classNames,
}: {
    value: Record<string, any>[],
    columns: Column<any>[],
    onChange: (...a: any[]) => void,
    classNames: Record<string, any>
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <TableRow classes={{
            root: clsx(classes.root, classNames.root),
        }}
        >
            {value && tableColumns.map(({
                fieldName,
                component: Component,
                getProps = noop,
            }) => {
                return (
                    <TableCell
                        classes={{
                            root: clsx(classes.tableCell, classNames.tableCell),
                        }}
                        key={`${fieldName}`}
                    >
                        <Component
                            value={value?.[fieldName]}
                            name={fieldName}
                            data-testid={`input-${fieldName}`}
                            onChange={handleChange}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getProps(value)}
                        />
                    </TableCell>
                )
            })}
        </TableRow>
    )
}

export default RowComponent
