import {
    useUserRoles,
} from 'app/Auth'

import hasPermission, {
    RolesTypes,
} from 'app/utils/hasPermission'

/**
* Use this to check if the user has any of the passed permissions
* @param    {Array}  permissions array to check
* @return   {Boolean}
*/

const usePermission = (perm: RolesTypes[]) => {
    const roles = useUserRoles()

    return hasPermission(perm, roles)
}

export default usePermission
