import React, {
    useCallback,
    useEffect,
    useMemo, useState,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import useGetLocationsByIds from 'app/Apps/ContactManagement/Locations/hooks/useGetLocationsByIds'
import {
    AddressCategory,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import DTOSpec from '../../services/dtoSpec'
import {
    getLabel,
} from '../LocationSelector/utils'
import useStyles from './MultipleLocationSelector.style'

type Value = {
    id: number,
}

type Props = {
    value?: Value[],
    onChange: (newValue: Value[]) => void,
    title?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    value: [],
    title: 'Locations',
    disabled: false,
    required: false,
}

const MultipleLocationSelector = ({
    value,
    title,
    disabled,
    required,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const [
        locations,
        setLocations,
    ] = useState<Location[]>()

    const addressCategories = useEnumValues()(AddressCategory)

    const loadOptions = useDelayedLoadOptions({
        url: DTOSpec.Location.Request,
        convertion: (data) => {
            return data.map((item) => {
                return {
                    label: getLabel(item, classes, addressCategories),
                    value: item?.id,
                    id: item?.id,
                }
            })
        },
    })

    const locationIds = useMemo(() => {
        return value.map(({
            id,
        }) => {
            return id
        })
    }, [value])

    const {
        data: locationsByIds,
    } = useGetLocationsByIds(locationIds)

    useEffect(() => {
        if (JSON.stringify(locationsByIds) !== JSON.stringify(locations)) {
            setLocations(locationsByIds)
        }
    }, [
        locations,
        locationsByIds,
    ])

    const onChangeValue = useCallback((newValue: Value[], actionParams): void => {
        let updatedValues

        if (actionParams?.action === 'remove-value') {
            updatedValues = locations?.filter(({
                id,
            }) => { return id !== actionParams?.removedValue?.id })
        } else {
            updatedValues = newValue
        }

        onChange(updatedValues?.map(({
            id,
        }: {
            id: number
        }) => {
            return {
                id,
            }
        }))
    }, [
        locations,
        onChange,
    ])

    return (
        <ChipSelect
            title={title}
            value={locations}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            getValueLabel={({
                locationName,
            }) => { return locationName }}
            showTitleOptions
            disabled={disabled}
            required={required}
        />
    )
}

MultipleLocationSelector.defaultProps = defaultProps

export default MultipleLocationSelector
