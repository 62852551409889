/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.elementType,
        }),
    ]),
}

const defaultProps = {
    inputRef: {},
}

function inputComponent({
    inputRef, ...props
}) {
    return (
        <div
            ref={inputRef}
            {...props}
        />
    )
}

inputComponent.propTypes = propTypes
inputComponent.defaultProps = defaultProps

export default inputComponent
