import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            height: '100%',
            position: 'relative',
        },
        tableControlRoot: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        tableControls: {
            display: 'flex',
            alignItems: 'center',
        },
        root: {
            position: 'relative',
            marginBottom: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(2),
        },
    }
})

export default useStyles
