import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetRegionalGroupById from '../hooks/useGetRegionalGroupById'
import RegionalGroupForm from '../RegionalGroupForm'

const UpdateData = ({
    id,
}: {
    id: number,
}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetRegionalGroupById(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <RegionalGroupForm
                onSuccess={invalidate}
                data={data}
            />
        </StatusHandler>
    )
}

export default UpdateData
