import {
    RequestConfig,
} from 'app/types/request.types'

type OpenBookingsRequests = {
    OrdersToBeConfirmed: () => RequestConfig,
    BookingsWaitingForPricing: () => RequestConfig,
}

const openBookingRequests: OpenBookingsRequests = {
    OrdersToBeConfirmed: () => {
        return {
            url: 'order/filter-request/open-bookings',
        }
    },
    BookingsWaitingForPricing: () => {
        return {
            url: 'prebooking/filter-request/open-with-booking',
        }
    },
}

export default openBookingRequests
