import React from 'react'
import noop from 'lodash/noop'

import MultiLocationSelector from 'app/shared-components/MultiLocationSelector'
import {
    TypeWithId,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'

import useGetAirportsFromLaneGroupIds from './useGetAirportsFromLaneGroupIds'

type Props = {
    name?: string,
    title?: string,
    value?: number[],
    laneGroups?: TypeWithId[],
    onChange?: () => void,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    name: '',
    title: '',
    value: undefined,
    laneGroups: undefined,
    onChange: noop,
    disabled: false,
}

const AirportLaneGroupSelector = ({
    name,
    title,
    value,
    laneGroups,
    onChange,
    disabled,
}: Props) => {
    const locations = useGetAirportsFromLaneGroupIds(laneGroups)

    return (
        <MultiLocationSelector
            disabled={disabled}
            name={name}
            locations={locations?.data}
            title={title}
            value={value}
            onChange={onChange}
        />
    )
}

AirportLaneGroupSelector.defaultProps = defaultProps

export default AirportLaneGroupSelector
