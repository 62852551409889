import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    AirlineLeaseAvailableLane,
} from './availableLanes.types'

const columns: RichTableColumns<keyof AirlineLeaseAvailableLane>[] = [
    {
        id: 'laneNumber',
        headerProps: {
            children: 'Lane',
        },
        mapCellProps: 'laneNumber',
    },
    {
        id: 'customerExecutionLocationName',
        headerProps: {
            children: 'Customer',
        },
        mapCellProps: 'customerExecutionLocationName',
    },
    {
        id: 'pricingAccountCompanyName',
        headerProps: {
            children: 'Pricing Account',
        },
        mapCellProps: 'pricingAccountCompanyName',
    },
    {
        id: 'deliveryServiceType',
        headerProps: {
            children: 'Delivery Type',
        },
        mapCellProps: 'deliveryServiceType',
        componentName: 'EnumValue',
        allowedValuesEnum: DeliveryServiceType,
    },
    {
        id: 'collectionServiceType',
        headerProps: {
            children: 'Collection Type',
        },
        mapCellProps: 'collectionServiceType',
        componentName: 'EnumValue',
        allowedValuesEnum: CollServiceType,
    },
    {
        id: 'handoverPointLocationName',
        headerProps: {
            children: 'Handover Point',
        },
        mapCellProps: 'handoverPointLocationName',
    },
    {
        id: 'collectionDropoffPointLocationName',
        headerProps: {
            children: 'Collection/Drop-off Point',
        },
        mapCellProps: 'collectionDropoffPointLocationName',
    },
    {
        id: 'consigneeLocationName',
        headerProps: {
            children: 'Consignee',
        },
        mapCellProps: 'consigneeLocationName',
    },
    {
        id: 'originAirportIataCode',
        headerProps: {
            children: 'From',
        },
        mapCellProps: 'originAirportIataCode',
    },
    {
        id: 'destAirportIataCode',
        headerProps: {
            children: 'To',
        },
        mapCellProps: 'destAirportIataCode',
    },
    {
        id: 'tempRange',
        headerProps: {
            children: 'Temperature Range',
        },
        mapCellProps: 'tempRange',
    },
]

export default columns
