import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    Typography,
    Icon,
    IconButton,
} from '@mui/material'

import bytesToHumanReadable from 'app/utils/bytesToHumanReadable'

import useStyles from './AttachmentLink.styles'
import AttachmentType from './Attachment.types'

type Props = {
    file: AttachmentType,
    editable?: boolean,
    onDelete: (id: number) => void,
}

const defaultProps: Partial<Props> = {
    editable: false,
}

const AttachmentLink = ({
    file: {
        filename,
        id,
        fileId,
        fileSize,
    },
    editable,
    onDelete,
}: Props) => {
    const fileUrl = useMemo(() => {
        const {
            REACT_APP_ATTACHMENTS_STORAGE: blobStorageUrl,
        } = process.env

        return `${blobStorageUrl}/${fileId}/${filename}`
    }, [
        fileId,
        filename,
    ])

    const {
        classes,
    } = useStyles()

    const handleDelete = useCallback(() => {
        onDelete(id)
    }, [
        id,
        onDelete,
    ])

    return (
        <div className={classes.root}>
            <a
                className={classes.link}
                href={fileUrl}
                rel="noopener noreferrer"
                target="_blank"
                download={filename}
                data-testid={`attachmentLink-${filename}`}
            >
                <Typography
                    variant="h5"
                >
                    {filename}
                </Typography>
            </a>
            <Typography
                variant="caption"
                classes={{
                    caption: classes.fileSize,
                }}
            >
                {bytesToHumanReadable(fileSize, 0)}
            </Typography>
            {editable
                && (
                    <IconButton
                        onClick={handleDelete}
                        className={classes.delete}
                        size="large"
                    >
                        <Icon
                            fontSize="small"
                        >
                            delete_forever
                        </Icon>
                    </IconButton>
                )}
        </div>
    )
}

AttachmentLink.defaultProps = defaultProps

export default AttachmentLink
