import {
    Validate,
} from 'app/types/form.types'

const validateZipCodeByCountry: Validate<{
    zipCode: string,
    zipCodePattern: RegExp,
    zipCodeExample: string,
    zipCodePatternDescription: string,
}> = ({
    zipCode, zipCodePattern, zipCodeExample, zipCodePatternDescription,
}, errorMsg?: string) => {
    if (!zipCode || !zipCodePattern) {
        return undefined
    }

    if (!zipCode.match(zipCodePattern)) {
        return [errorMsg
        || `${zipCodePatternDescription || 'Zip doesn\'t match country zip pattern. '} e.g.: ${zipCodeExample}`]
    }

    return undefined
}

export default validateZipCodeByCountry
