import flow from 'lodash/flow'
import Axios from 'axios'

import bearerToken from 'app/utils/api/bearerToken'
import addHostUrl from 'app/utils/api/addHostUrl'
import applySort from 'app/shared-components/SkyNetTable/services/requestEnhancers/applySort'
import applyFilter from 'app/shared-components/SkyNetTable/services/requestEnhancers/applyFilter'
import applySearch from 'app/shared-components/SkyNetTable/services/requestEnhancers/applySearch'
import applyArchived from 'app/shared-components/SkyNetTable/services/requestEnhancers//applyArchived'
import DOMAIN_SUBDIRECTORY from 'app/utils/api/constants'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const requestMultiplePages = (params) => {
    const modifiedQuery = flow([
        applySort,
        applyFilter,
        applySearch,
        applyArchived,
        bearerToken,
        addHostUrl(`${apiUrl}/${DOMAIN_SUBDIRECTORY}`),
    ])(Axios)

    return modifiedQuery(params)
}

export default requestMultiplePages
