import React, {
    useMemo, useState, useCallback,
} from 'react'

import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import useLabel from 'app/hooks/useLabel'

import RichTable from 'app/shared-components/RichTableReduxWrapper/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Button from 'app/shared-components/Button'
import StatusHandler from 'app/shared-components/StatusHandler'

import useGetAwardRejectLaneFees from './hooks/useGetAwardRejectLaneFees'
import useBulkAwardLaneFees from './hooks/useBulkAwardLaneFees'
import useBulkRejectLaneFees from './hooks/useBulkRejectLaneFees'

import RejectLaneFeesConfirmationDialog from './RejectLaneFeesConfirmationDialog'
import requests from '../../AccountQuotes.requests'
import getColumns from './AwardRejectLaneFees.columns'
import useStyles from './AwardRejectLaneFees.styles'

const AwardRejectLaneFees = ({
    id,
}: {
    id: number
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const [
        confirmation,
        setConfirmation,
    ] = useState(false)
    const [
        selected,
        setSelected,
    ] = useState([])
    const {
        onParamsChange,
        refetch,
        loadData,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetAwardRejectLaneFees(id)

    const injectLoadFilters = useLoadFilters(
        {
            customUrl: requests.getLaneFeesToAwardReject({
                id,
            }).url,
        },
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(getColumns({
            l,
        }))
    }, [
        injectLoadFilters,
        l,
    ])

    const onSuccess = useCallback(() => {
        refetch()
        setSelected([])
    }, [refetch])

    const bulkAward = useBulkAwardLaneFees({
        onSuccess,
    })

    const bulkReject = useBulkRejectLaneFees({
        onSuccess,
    })

    const selectedLaneFees: {id: number}[] = useMemo(() => {
        return selected.map((laneFeesId) => {
            return {
                id: laneFeesId,
            }
        })
    }, [selected])

    return (
        <Card title="Lane Fees">
            <StatusHandler
                isFetching={isFetching}
                isError={isError}
                isSuccess={isDataReady}
                error={error}
            >
                <>
                    <RichTable
                        configName="AwardRejectLaneFees"
                        name="AwardRejectLaneFees"
                        load={loadData}
                        showSelectAll
                        selectedRows={selected}
                        onSelectRow={setSelected}
                        onParamsChange={onParamsChange}
                        columns={columnsWithFilters}
                        className={classes.tableContent}
                        classNames={{
                            contentWrapper: classes.tableContentWrapper,
                        }}
                    />
                    <ButtonContainer>
                        <Button
                            key="set-as-awarded"
                            label="Set as awarded"
                            onClick={() => {
                                bulkAward(selectedLaneFees)
                            }}
                            disabled={!selected.length}
                        />
                        <Button
                            key="set-as-rejected"
                            label="Set as rejected"
                            onClick={() => {
                                setConfirmation(true)
                            }}
                            disabled={!selected.length}
                        />
                    </ButtonContainer>
                    <RejectLaneFeesConfirmationDialog
                        laneFees={selectedLaneFees}
                        open={confirmation}
                        action={bulkReject}
                        close={() => {
                            setConfirmation(false)
                        }}
                    />
                </>
            </StatusHandler>

        </Card>

    )
}

export default AwardRejectLaneFees
