import {
    RequestKeys,
} from 'app/hooks/useRequest'
import usePaginatedRequest from 'app/hooks/usePaginatedRequest'

import requests from '../alertNotifications.requests'
import {
    AlertNotificationListItem,
} from '../alertNotifications.types'

const useGetAlertNotifications = ({
    enabled,
}: {enabled: boolean}) => {
    const {
        data,
        dataByPage,
        fetchNext,
        isDataReady,
        isFetching,
        isError,
        hasNext,
        error,
        invalidate,
    } = usePaginatedRequest<AlertNotificationListItem>({
        key: RequestKeys.getAllAlertsNotifications,
        params: {},
        config: requests.getAll,
        enabled,
    })

    return {
        data,
        dataByPage,
        fetchNext,
        hasNext,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetAlertNotifications
