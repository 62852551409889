import simpleApi from 'app/services/simpleApi'
import {
    UseRequestConfig,
} from 'app/types/request.types'

const config: Record<string, UseRequestConfig> = {
    RemoveLoggerDocument: {
        mutationFn: ({
            loggerId,
            documentId,
            token,
        }) => {
            return simpleApi({
                url: `logger/${loggerId}/calibrationdocument/${documentId}/removal`,
                method: 'POST',
                token,
            })
        },
    },
}

export default config
