import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

const config: SkyNetTableConfig = {
    tabs: [{
        name: 'Countries',
        url: 'all',
    }],
    fields: [
        {
            name: 'countryName',
            reduced: true,
        },
        {
            name: 'countryIsoAlpha2Code',
            reduced: true,
        },
    ],
}

export default config
