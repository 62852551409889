import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    ActivityRecordTable, ActivityRecordType,
} from './activityRecords.types'

const config: SkyNetTableConfig<keyof ActivityRecordTable> = {
    name: 'ActivityRecords',
    tabs: [{
        name: 'All',
        url: 'all',
    }],
    fields: [
        {
            name: 'companyName',
            filterField: 'companyName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'ActivityRecords.companyName',
        },
        {
            name: 'participants',
            componentName: 'List',
            reduced: true,
            labelKey: 'ActivityRecords.participants',
        },
        {
            name: 'subject',
            reduced: true,
            labelKey: 'ActivityRecords.subject',
        },
        {
            name: 'type',
            componentName: 'EnumValue',
            allowedValuesEnum: ActivityRecordType,
            labelKey: 'ActivityRecords.type',
        },
        {
            name: 'organizer',
            filterField: 'organizer',
            filterType: FilterType.Array,
            labelKey: 'ActivityRecords.organizer',
        },
        {
            name: 'isFollowUpNeeded',
            filterField: 'isFollowUpNeeded',
            filterType: FilterType.Array,
            componentName: 'ChipCheckMark',
            labelKey: 'ActivityRecord.followUpNeeded',
        },
        {
            name: 'happenedOn',
            labelKey: 'ActivityRecords.happenedOn',
        },
        {
            name: 'durationInMinutes',
            componentName: 'AmountOfTime',
            labelKey: 'ActivityRecords.duration',
        },
        {
            name: 'changedOn',
            labelKey: 'ActivityRecords.changedOn',
        },
    ],
}

export default config
