/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import {
    requestType,
    SUCCESS,
    FAILURE,
} from 'app/utils/requestStatuses'

import FailureMessage from './FailureMessage'
import SuccessMessage from './SuccessMessage'

const propTypes = {
    status: requestType.isRequired,
}

const FinishedMessage = ({
    status,
    ...rest
}) => {
    if (status === SUCCESS) {
        return (
            <SuccessMessage
                {...rest}
            />
        )
    }

    if (status === FAILURE) {
        return (
            <FailureMessage
                {...rest}
            />
        )
    }

    return (
        <div>
            Unknown type
        </div>
    )
}

FinishedMessage.propTypes = propTypes

export default FinishedMessage
