import createReducer from 'app/utils/createReducer'

import {
    SET_VALUE,
    SET_TIME_ZONES,
    SET_TIME_ZONE_STATE,
    SET_TIME_ZONE,
    SET_TIME_ZONE_CITY_STATE,
} from './actions'

const reducer = createReducer({
    [SET_VALUE]: (state, {
        value,
    }) => {
        return {
            ...state,
            value,
            edited: true,
        }
    },
    [SET_TIME_ZONES]: (state, {
        timeZones,
    }) => {
        return {
            ...state,
            timeZones,
        }
    },
    [SET_TIME_ZONE]: (state, {
        timeZone,
    }) => {
        return {
            ...state,
            value: {
                ...state.value,
                timeZone,
            },
        }
    },
    [SET_TIME_ZONE_STATE]: (state, {
        timeZone,
        stateId,
    }) => {
        if (!stateId) {
            return {
                ...state,
                value: {
                    ...state.value,
                    timeZone,
                },
            }
        }

        return {
            ...state,
            value: {
                ...state.value,
                timeZone,
                stateId,
            },
        }
    },
    [SET_TIME_ZONE_CITY_STATE]: (state, {
        timeZone,
        stateId,
        city,
    }) => {
        return {
            ...state,
            timeZones: [],
            value: {
                ...state.value,
                timeZone,
                stateId: stateId || state.value.stateId,
                city,
            },
        }
    },
})

export default reducer
