import React from 'react'

import List from 'app/Apps/DomainObject/ComponentMapper/List'
import useEnumValues from 'app/hooks/useEnumValues'

type Props = {
    value?: string | string[],
}

const defaultProps = {
    value: null,
}

const ModifiedValue = ({
    value,
}: Props) => {
    const getAllowedValues = useEnumValues()

    if (!value) {
        return null
    }

    if (Array.isArray(value)) {
        const labels = value.map((item) => {
            return getAllowedValues({
                [item]: `${item}`,
            })[item]
        })

        return (<List value={labels} />)
    }

    const lable = getAllowedValues({
        [`${value}`]: `${value}`,
    })

    return (<div>{lable?.[value] || value}</div>)
}

ModifiedValue.defaultProps = defaultProps

export default ModifiedValue
