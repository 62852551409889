import React from 'react'
import PropTypes from 'prop-types'
import isString from 'lodash/isString'
import moment from 'moment'

import {
    strToDate,
} from 'app/utils/date'
import AmountOfTime from 'app/shared-components/AmountOfTime'

import useStyles from './ContainerSelectionUntil.style'

const getDiffFromNow = (value) => {
    const time = isString(value) ? strToDate(value) : moment(value, 'X')

    return moment(time).diff(moment(), 'minutes')
}

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

const defaultProps = {
    value: undefined,
}

const ContainerSelectionUntil = (props) => {
    const {
        value,
    } = props

    const {
        classes,
    } = useStyles()

    const diffFromNow = value && getDiffFromNow(value)

    return (
        <AmountOfTime
            value={diffFromNow}
            className={diffFromNow < 0 ? classes.red : classes.grey}
        />
    )
}

ContainerSelectionUntil.propTypes = propTypes
ContainerSelectionUntil.defaultProps = defaultProps

export default ContainerSelectionUntil
