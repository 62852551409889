import {
    DTOCardFormField,
} from 'app/types/form.types'
import removeUnauthorizedField from 'app/utils/removeUnauthorizedField'
import {
    Currency, LeaseType,
} from 'app/types/enums'
import {
    Contact,
} from 'app/types/common.types'
import {
    App,
} from 'app/types/common.enums'
import {
    TypeIcon,
} from 'app/shared-components/NotificationIcon/notificationIcon.types'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

import {
    QuoteStatus,
    AccountQuote,
} from '../AccountQuotes.types'

type QuoteInformationFields = AccountQuote & {
    accountContacts: Contact[],
    businessDevelopers: Contact[],
    keyAccountManager: Contact,
    contractBasisNumber?: string,
}

const getFields = ({
    getAllowedValues,
    exists,
    disabled,
    enabledContractBasis,
    quoteStatus,
    showContractBasisLink,
    contractBasisNumber,
    linkedContractBasisId,
    hasPlannedContractBasis,
    contractBasisHasValidAdditionalFees,
    contractBasisReadPermission,
}: {
    getAllowedValues: (e:Record<string, string>) => Record<string, string>,
    exists: boolean,
    disabled: boolean,
    enabledContractBasis: boolean,
    quoteStatus: QuoteStatus,
    showContractBasisLink: boolean,
    contractBasisNumber: string,
    linkedContractBasisId: number,
    hasPlannedContractBasis: boolean,
    contractBasisHasValidAdditionalFees: boolean,
    contractBasisReadPermission: boolean,
}): DTOCardFormField<keyof QuoteInformationFields>[] => {
    return [{
        id: 'quoteInformation',
        title: 'Quote Information',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'account',
                        componentName: 'AccountSelector',
                        labelKey: 'AccountQuotes.accountName',
                        required: true,
                        disabled: disabled || exists,
                    },
                    {
                        name: 'quoteStatus',
                        labelKey: 'AccountQuotes.quoteStatus',
                        allowedValues: getAllowedValues(QuoteStatus),
                        componentName: 'EnumSelector',
                        disabled: true,
                    },
                    ...removeUnauthorizedField([{
                        name: 'plannedContractBasis',
                        className: 'contractBasis',
                        componentName: 'ContractBasisSelector',
                        disabled: !enabledContractBasis || disabled,
                        required: true,
                        labelKey: 'AccountQuotes.plannedContractBasis',
                    }], ['plannedContractBasis'], contractBasisReadPermission),
                    ...removeUnauthorizedField([{
                        name: 'contractBasisNumber',
                        className: 'contractBasisNumber',
                        labelKey: 'ContractBasis.assignedContractBasisId',
                        notificationIconProps: contractBasisHasValidAdditionalFees ? undefined : {
                            text: 'Please add valid Additional Fees to this Contracts Basis \n'
                                + 'before generating a quote document.',
                            type: TypeIcon.WARNING,
                        },
                        type: TypeLink.EXTERNAL,
                        size: SizeLink.S,
                        componentName: 'SkyNetLink',
                        text: contractBasisNumber,
                        link: `/apps/${App.PRICING}/contract-basis/all/edit/${linkedContractBasisId}/overview`,
                    }], ['contractBasisNumber'], (showContractBasisLink && contractBasisReadPermission && linkedContractBasisId)),
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Currency),
                        labelKey: 'AccountQuotes.currency',
                        required: true,
                        useDropdown: true,
                        disabled: disabled || exists,
                    },
                    {
                        name: 'leaseType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LeaseType),
                        labelKey: 'AccountQuotes.leaseType',
                        required: true,
                        useDropdown: true,
                        disabled: disabled || exists,
                    },
                    {
                        name: 'quotedForCompany',
                        componentName: 'LocationObjectSelector',
                        includeFilters: {
                            isProspect: [
                                true,
                                false,
                            ],
                        },
                        labelKey: 'AccountQuotes.quotedForCompany',
                        required: true,
                        disabled: disabled || exists,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'isPerformanceQualification',
                        componentName: 'Checkbox',
                        labelKey: 'AccountQuotes.isPerformanceQualification',
                        disabled: disabled || exists,
                    },
                    {
                        name: 'comment',
                        componentName: 'LongText',
                        labelKey: 'AccountQuotes.comment',
                        disabled: disabled || exists,
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'quotedFor',
                        componentName: 'ContactObjectSelector',
                        notificationIconProps: {
                            text: 'This contact will be shown in the quote document as a Customer Contact.',
                            type: TypeIcon.INFO,
                        },
                        labelKey: 'AccountQuotes.quotedFor',
                        required: true,
                        disabled: (!enabledContractBasis || disabled) && (disabled || exists),
                        showOptionLabelWithEmail: true,
                    },
                    {
                        name: 'pricingResponsible',
                        componentName: 'MultipleUserSelector',
                        labelKey: 'AccountQuotes.pricingResponsible',
                        required: QuoteStatus.IN_PROGRESS === quoteStatus
                        || (QuoteStatus.CREATED === quoteStatus && hasPlannedContractBasis),
                        disabled: [
                            QuoteStatus.CANCELLED,
                            QuoteStatus.CLOSED,
                        ].includes(quoteStatus) || disabled,
                    },
                    {
                        name: 'createdBy',
                        componentName: 'InputSingleline',
                        labelKey: 'KamDashboard.requestor',
                        disabled: true,
                    },
                ],
            },
        ],
    }]
}

export default getFields
