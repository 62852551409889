import React from 'react'

import moment from 'moment'

type Props = {
    value?: number,
}

const defaultProps: Partial<Props> = {
    value: undefined,
}

const Age = ({
    value,
}: Props) => {
    return (
        <div
            data-testid="age-div"
        >
            {value && `${moment(value, 'X').fromNow(true)}`}
        </div>
    )
}

Age.defaultProps = defaultProps

export default Age
