import React from 'react'

import Button from 'app/shared-components/Button'
import {
    OrderStatuses,
} from 'app/types/enums'

import useStyles from './ReverseLogisticBtn.style'

interface OrderData {
    collectionDropoffPointId: number,
    selectedPreviousOrderId: number,
}

type Props = {
    openCreateForm: (arg: OrderData) => void,
    collectionDropoffPointId: number,
    orderStatus: OrderStatuses,
    id: number,
}

const ReverseLogisticBtn = ({
    collectionDropoffPointId,
    openCreateForm,
    orderStatus,
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()

    if (orderStatus !== OrderStatuses.CLOSED) {
        return null
    }

    return (
        <div className={classes.wrapper}>
            <Button
                label="RL"
                onClick={() => {
                    openCreateForm({
                        collectionDropoffPointId,
                        selectedPreviousOrderId: id,
                    })
                }}
                className={classes.btn}
            />
        </div>
    )
}

export default ReverseLogisticBtn
