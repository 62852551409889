import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        link: {
            cursor: 'pointer',
            textDecoration: 'underline',
            textAlign: 'left',
            color: theme.palette.secondary[600],
        },
    }
})

export default useStyles
