import React, {
    useCallback,
} from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import clsx from 'clsx'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import InputBase from 'app/shared-components/InputBase'
import ProductTypeSelector from 'app/shared-components/ProductTypeSelector'

import useStyles from '../Inputs.styles'

type Props = {
    value?: {
        id: number,
        baseLeaseDays: number,
        productType: string,
        baseLeaseFee: number,
    },
        onChange: (...args: any[]) => void,
        onDelete: (...args: any[]) => void,
        disabled?: boolean,
}
const defaultProps: Partial<Props> = {
    value: undefined,
    disabled: false,
}

const LeaseFeesInputs = ({
    value,
    onChange,
    onDelete,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const handleChange = useCallback((newValue, {
        target: {
            name,
        },
    }) => {
        const update = {
            ...value,
            [name]: newValue,
        }

        onChange(update)
    }, [
        value,
        onChange,
    ])

    const handleOnDelete = useCallback(() => {
        onDelete(value.id)
    }, [
        value,
        onDelete,
    ])

    return (
        <TableRow>
            <TableCell
                classes={{
                    root: classes.tableCell,
                }}
            >
                <InputBase
                    value={value?.baseLeaseDays}
                    name="baseLeaseDays"
                    onChange={handleChange}
                    disabled={disabled}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: classes.required,
                    }}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.tableCell, classes.productType),

                }}
            >
                <ProductTypeSelector
                    className={classes.required}
                    value={value?.productType}
                    onChange={handleChange}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: classes.tableCell,
                }}
            >
                <InputBase
                    value={value?.baseLeaseFee}
                    name="baseLeaseFee"
                    onChange={handleChange}
                    disabled={disabled}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: classes.required,
                    }}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: classes.tableCell,
                }}
            >
                {!disabled
                    && (
                        <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={handleOnDelete}
                        >
                            Delete
                        </DeleteIcon>
                    )}
            </TableCell>
        </TableRow>
    )
}

LeaseFeesInputs.defaultProps = defaultProps

export default LeaseFeesInputs
