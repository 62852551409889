import React from 'react'
import moment from 'moment'

import {
    MainMeansOfTransport,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    strToDate,
} from 'app/utils/date'

import useStyles from './PoolingRequestedDelivery.style'

const executionTime = {
    SEA: 72,
    AIR: 48,
    ROAD: 24,
}

type Props = {
    value: string,
    rowProps: {
        mainMeansOfTransport: MainMeansOfTransport,
        bookedOrderStatusCompletedOn: string,
    }
}

const PoolingRequestedDelivery = ({
    value,
    rowProps,
}: Props) => {
    const {
        classes,
    } = useStyles()

    if (!rowProps?.bookedOrderStatusCompletedOn) {
        return value ? (<div className={classes.grey}>{value}</div>) : null
    }

    const diff = moment().diff(moment(strToDate(rowProps?.bookedOrderStatusCompletedOn), 'X'), 'hours')

    const color = diff > executionTime[rowProps?.mainMeansOfTransport]
        ? classes.red
        : classes.grey

    return (
        <div className={color}>{value}</div>
    )
}

export default PoolingRequestedDelivery
