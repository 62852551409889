import React from 'react'
import clsx from 'clsx'

import {
    strToDate,
} from 'app/utils/date'

import useStyles from './DateRangeSelector.style'
import rangeToDayPassed from './rangeToDayPassed'
import DateSelect from './DateSelect'

type Props = {
    mini: boolean,
    options: {
        label: string,
        value: number,
    }[]
    selectOption: (val, e) => void,
    showTimeRange: boolean,
    onChangeRange,
    from?: string,
    to?: string,
}

const defaultProps = {
    from: null,
    to: null,
}

const DateRangeSelector = ({
    mini,
    options,
    selectOption,
    showTimeRange,
    onChangeRange,
    from,
    to,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const dayPassed = from && to ? rangeToDayPassed({
        from: strToDate(from),
        to: strToDate(to),
    }) : undefined

    return (
        <div className={classes.wrapper}>
            {!mini && (
                <div className={classes.optionsWrapper}>
                    {options.map(({
                        label,
                        value,
                    }) => {
                        return (
                            <div
                                data-testid={`date-range-button-${value}`}
                                key={value}
                                className={clsx(classes.option, {
                                    [classes.selectedOption]: dayPassed === value,
                                })}
                                onMouseDown={(e) => {
                                    selectOption(value, e)
                                }}
                            >
                                {label}
                            </div>
                        )
                    })}
                </div>
            )}
            {showTimeRange && (
                <div className={classes.inputsWrapper}>
                    <div className={classes.input}>
                        <DateSelect
                            title="From"
                            className={classes.datePicker}
                            onChange={onChangeRange}
                            name="from"
                            value={from}

                        />
                    </div>
                    <div className={classes.input}>
                        <DateSelect
                            title="To"
                            className={classes.datePicker}
                            onChange={onChangeRange}
                            name="to"
                            value={to}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

DateRangeSelector.defaultProps = defaultProps

export default DateRangeSelector
