import React, {
    useCallback, useMemo, useEffect, useState, KeyboardEventHandler, MouseEventHandler,
} from 'react'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Collapse from '@mui/material/Collapse'
import useHistoryNavigation from 'app/hooks/useHistoryNavigation'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import {
    RichTableColumns,
} from 'app/types/richtable.types'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import useRenderTableControlPanel from './useRenderTableControlPanel'
import useCollapsablePanel from './hooks/useCollapsablePanel'
import useStyles from './CollapsibleTable.styles'
import {
    TableControlTypes,
} from '../TableControls'

type Props = {
    title: string,
    domainName: string,
    configName?: string,
    customUrl: string,
    requestHandlers: {
        loadData: (...args: any[]) => Promise<any>,
        refetch: (...args: any[]) => void,
        onParamsChange: (...args: any[]) => void,
    },
    actionComponents: {
        Copy?: (props: Record<string, any>) => JSX.Element,
        Update: (props: Record<string, any>) => JSX.Element,
        Create?: (props: Record<string, any>) => JSX.Element
    },
    columns: RichTableColumns[],
    createEnabled?: boolean,
    tableControls?: TableControlTypes[],
    switchableFormTable?: boolean,
    backToTableText?: string,
}

const defaultProps = {
    configName: undefined,
    createEnabled: true,
    tableControls: undefined,
    switchableFormTable: false,
    backToTableText: undefined,
}

const CollapsibleTable = ({
    title,
    domainName,
    configName,
    columns,
    customUrl,
    requestHandlers,
    actionComponents: ActionComponents,
    createEnabled,
    tableControls,
    switchableFormTable,
    backToTableText,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const [
        search,
        setSearch,
    ] = useState<string>()

    const {
        id,
        action,
        updatePathWithId,
        updatePathWithParams,
    } = useHistoryNavigation()

    const {
        loadData, refetch, onParamsChange,
    } = requestHandlers

    useEffect(() => {
        onParamsChange({
            searchAll: search,
            filter: {},
        })
    }, [
        search,
        onParamsChange,
    ])

    const {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    } = useCollapsablePanel(id)

    const renderTableControlPanel = useRenderTableControlPanel({
        title,
        stateName: domainName,
        createFormOpened,
        openCreateForm,
        closeCreateForm,
        createEnabled,
        tableControls,
        applySearch: setSearch,
    })

    const injectLoadFilters = useLoadFilters({
        customUrl,
    })

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [
        injectLoadFilters,
        columns,
    ])

    const onCreate = useCallback(({
        id: newId,
    }) => {
        updatePathWithParams({
            id: newId,
        })
        refetch()
        closeCreateForm()
    }, [
        closeCreateForm,
        updatePathWithParams,
        refetch,
    ])

    const getUpdateForm = useCallback(() => {
        return (
            <ActionComponents.Update
                id={id}
                refetch={refetch}
            />
        )
    }, [
        ActionComponents,
        id,
        refetch,
    ])

    const renderCopyComponent = useCallback(() => {
        return ActionComponents.Copy ? (
            <ActionComponents.Copy
                id={id}
                refetch={refetch}
            />
        ) : null
    }, [
        id,
        refetch,
        ActionComponents,
    ])

    const renderCreateComponent = useCallback(() => {
        return ActionComponents.Create ? (
            <Collapse in={createFormOpened}>
                <ActionComponents.Create
                    onSuccess={onCreate}
                    onCancel={closeCreateForm}
                />
            </Collapse>
        ) : null
    }, [
        onCreate,
        closeCreateForm,
        ActionComponents,
        createFormOpened,
    ])

    return (
        <div className={classes.container}>
            {(switchableFormTable && id)
                ? (
                    <>
                        <div className={classes.backToTableText}>
                            <ArrowBackIosIcon className={classes.icon} />
                            <a
                                onKeyDown={updatePathWithId as unknown as KeyboardEventHandler<HTMLAnchorElement>} // eslint-disable-line max-len
                                onClick={updatePathWithId as unknown as MouseEventHandler<HTMLAnchorElement>} // eslint-disable-line max-len
                            >
                                {backToTableText}
                            </a>
                        </div>

                        { getUpdateForm() }
                    </>
                ) : (
                    <>
                        {
                            action === 'copy'
                                ? renderCopyComponent()
                                : renderCreateComponent()
                        }
                        <RichTable
                            configName={configName || domainName}
                            name={domainName}
                            columns={customUrl ? columnsWithFilters : columns}
                            load={loadData}
                            onParamsChange={onParamsChange}
                            uniqField="id"
                            className={classes.tableContent}
                            classNames={{
                                contentWrapper: classes.tableContentWrapper,
                                headerWrapper: classes.tableHeaderWrapper,
                            }}
                            detailPanel={switchableFormTable ? undefined : getUpdateForm}
                            onRowClick={updatePathWithId}
                            openRowId={(action || createFormOpened) ? undefined : Number(id)}
                            renderControlPanel={renderTableControlPanel}
                        />
                    </>
                )}
        </div>
    )
}

CollapsibleTable.defaultProps = defaultProps

export default CollapsibleTable
