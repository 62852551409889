import {
    RequestConfig, SelectorConfig,
} from 'app/types/request.types'

type CountriesRequestConfig = {
    domainName: string,
    get: ({
        id: number,
    }) => RequestConfig,
    selector: SelectorConfig<{ countryName: string, id: number }[]>
}

const CountriesRequest: CountriesRequestConfig = {
    domainName: 'Country',
    get: ({
        id,
    }) => {
        return {
            url: `country/${id}`,
        }
    },
    selector: {
        url: 'country/filter-request/selector',
        convertion: (countries) => {
            return countries.map(({
                countryName, id,
            }) => {
                return {
                    id,
                    label: countryName,
                    value: id,
                    countryName,
                }
            })
        },
    },
}

export default CountriesRequest
