import {
    SettingField,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getFieldByName = (fields: SettingField[], name: string): SettingField | undefined => {
    return fields.find(({
        fieldName,
    }) => { return fieldName === name })
}

const getFieldsWithConfiguration = (
    fields: Record<string, any>[],
    fieldsWithSettings: SettingField[],
    disabled: boolean,
): {
    disabled: boolean,
    required: boolean,
}[] => {
    const filteredFields = fields.filter((field) => {
        if (field.name === 'baseLeaseUntilTimestamp') {
            return true
        }

        return getFieldByName(fieldsWithSettings, field.name)
    })

    return filteredFields.map((field) => {
        const fieldWithSettings = field.name === 'baseLeaseUntilTimestamp'
            ? field
            : getFieldByName(fieldsWithSettings, field.name)

        return {
            ...field,
            disabled: disabled || fieldWithSettings?.disabled,
            required: fieldWithSettings?.required,
        }
    })
}

export default getFieldsWithConfiguration
