import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        text: {
            paddingTop: theme.spacing(2),
        },
        exchangeExamples: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        notFoundImage: {
            paddingLeft: '80px',
        },
        notFoundText: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: theme.palette.secondary[400],
            fontSize: theme.typography.body1.fontSize,
        },
    }
})

export default useStyles
