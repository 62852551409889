import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        feeValue: {
            padding: `0 ${theme.spacing(0.25)} 0 0 !important`,
            width: '5%',
        },
        amount: {
            padding: `0 ${theme.spacing(0.25)} 0 0 !important`,
            width: '10%',
        },
        fieldWrapper: {
            width: '90%',
            margin: '0 auto',
        },
        disabled: {
            textAlign: 'right',
        },
        input: {
            '&&': {
                backgroundColor: theme.palette.secondary[50],
                textAlign: 'right',
            },
        },
    }
})

export default useStyles
