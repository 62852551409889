import {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import {
    App,
} from 'app/types/common.enums'

import {
    OrderTypes,
} from '../orders.types'

enum Path {
    CUSTOMER_TRANSPORT = 'customer',
    INTERNAL_TRANSPORT = 'internal',
}

const useOnSuccessCreateOrder = (orderType: OrderTypes) => {
    const history = useHistory()

    return useCallback(({
        orderNumber,
    }) => {
        history.push(`/apps/${App.ORDER_MANAGEMENT}/${Path[orderType]}-orders/all/edit/${orderNumber}/overview`)
    }, [
        history,
        orderType,
    ])
}

export default useOnSuccessCreateOrder
