import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        optionWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        option: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            textAlign: 'left',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.typography.body1.color,
            fontSize: theme.typography.body1.fontSize,
            paddingRight: theme.spacing(2),
        },
        'option &:hover': {
            background: theme.palette.primary[30],
        },
        checkbox: {
            padding: theme.spacing(0.6),
        },
    }
})

export default useStyles
