const applyParseMatchedResult = (cb) => {
    return (params) => {
        return cb(params)
            .then((resp) => {
                return {
                    meta: {
                        matchedresults: resp.data.matches,
                    },
                    data: resp.data.items,
                    hasNextPage: resp.data.hasNextPage,
                }
            })
    }
}

export default applyParseMatchedResult
