import React from 'react'
import PropTypes from 'prop-types'
import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from '@mui/material/styles'

import skyNetTheme from './defaultTheme'

const propTypes = {
    children: PropTypes.element.isRequired,
}

const theme = createTheme(skyNetTheme)

const ThemeProvider = ({
    children,
}) => {
    return (
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    )
}

ThemeProvider.propTypes = propTypes

export default ThemeProvider
