import React from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import StatusHandler from 'app/shared-components/StatusHandler'

import getContainerInformationFields from './OpportunitiesContainerInformation.fields'
import useStyles from './OpportunitiesContainerInformation.style'
import useGetOpportunityById from '../hooks/useGetOpportunityById'

import request from '../opportunities.request'

const defaultProps = {
    id: undefined,
}

const OpportunitiesForm = ({
    id,
}: {
    id?: number,
}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetOpportunityById(id)

    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const fields = getContainerInformationFields({
        getAllowedValues,
    })
    const fieldsWithClasses = useFieldsWithClassName([fields], classes)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                onSuccess={invalidate}
                value={data}
                name={request.domainName}
                fields={fieldsWithClasses}
                onCancel={invalidate}
                requestParams={request.UpdateContainerInformation({
                    opportunityId: data?.id,
                })}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                exists
            />
        </StatusHandler>
    )
}

OpportunitiesForm.defaultProps = defaultProps

export default OpportunitiesForm
