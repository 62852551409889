import React, {
    useMemo,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useLabel from 'app/hooks/useLabel'
import useEnumValues from 'app/hooks/useEnumValues'

import getColumns from './CountryOverview.columns'
import countryOverviewComponents from './CountryOverview.fields'
import useStyles from './CountryOverview.style'

import {
    State,
} from '../Countries.types'

type CountryOverviewProps = {
    value?: {
        states: State[]
    }
    load: (args: any) => Promise<any>,
    editable: boolean,
    errors?: string[],
}

const defaultProps: Partial<CountryOverviewProps> = {
    value: null,
    errors: [],
}

const dataModelObjectName = 'State'
const CountryOverview = ({
    load,
    value,
    editable,
    errors,
}: CountryOverviewProps) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const getAllowedValues = useEnumValues()

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    return (
        <div className={classes.root}>
            <DomainObjectForm
                name="Country"
                value={value}
                errors={errors}
                disabled={!editable}
                fields={countryOverviewComponents(getAllowedValues)}
            />
            <RichTable
                configName="CountryOverview"
                classNames={{
                    root: classes.table,
                    contentWrapper: classes.tableContentWrapper,
                    headerWrapper: classes.tableHeaderWrapper,
                }}
                name={dataModelObjectName}
                uniqField="stateIsoAlpha2Code"
                load={load}
                columns={columns}
            />
        </div>
    )
}

CountryOverview.defaultProps = defaultProps

export default CountryOverview
