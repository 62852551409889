import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'

import useStyles from './CloseButton.styles'

const propTypes = {
    onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

const CloseButton = (props) => {
    const {
        onClose,
    } = props

    const {
        classes,
    } = useStyles()

    return (
        <CloseIcon
            data-testid="close-icon-button"
            role="button"
            className={classes.root}
            onClick={onClose}
        />
    )
}

CloseButton.propTypes = propTypes
CloseButton.defaultProps = defaultProps

export default CloseButton
