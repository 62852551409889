import React, {
    useCallback,
} from 'react'
import SimpleTable from 'app/shared-components/SimpleTable'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'

import ModificationWarningMsg from 'app/shared-components/ModificationWarningMsg'
import {
    Column,
} from 'app/types/simpletable.types'
import useStyles from './DuplicationCheckDialog.styles'

const defaultProps = {
    title: 'Creation Confirmation',
    positiveLabel: 'Create anyway',
    data: null,
}

type DuplicationCheckDialogProps<D> = {
    data?: D[],
    columns: Column<keyof D>[],
    close: () => void,
    create: (...args: any[]) => void,
    message: string,
    title?: string,
    positiveLabel?: string,
}

function DuplicationCheckDialog<D>({
    columns,
    data,
    create,
    close,
    message,
    title,
    positiveLabel,
}: DuplicationCheckDialogProps<D>) {
    const {
        classes,
    } = useStyles()
    const open = Boolean(data?.length)

    const handleClose = useCallback((positive) => {
        if (positive) {
            create()
            close()
            return
        }

        close()
    }, [
        create,
        close,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={title}
            positiveLabel={positiveLabel}
            negativeLabel="Cancel"
        >
            {open ? (
                <>
                    <ModificationWarningMsg msg={message} />
                    <SimpleTable
                        columns={columns}
                        data={data}
                        uniqField="locationName"
                        classNames={classes}
                        disabled
                    />
                </>

            ) : null}
        </ConfirmationDialog>
    )
}

DuplicationCheckDialog.defaultProps = defaultProps

export default DuplicationCheckDialog
