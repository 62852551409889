import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        blue: {
            color: theme.palette.secondary[700],
        },
        red: {
            color: theme.palette.common.red,
        },
        grey: {
            color: theme.palette.secondary[600],
        },
        green: {
            color: theme.palette.common.green,
        },
    }
})

export default useStyles
