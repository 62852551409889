import {
    makeStyles,
} from 'app/tss'

const billingInformation = 'billingInformation'
const summary = 'summary'
const comments = 'comments'
const feeDays = 'feeDays'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapperOrder: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${summary} ${comments} ${comments} ${billingInformation}"
                "${summary} ${feeDays} ${feeDays} ${billingInformation}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
            paddingTop: theme.spacing(1),
        },

        gridWrapperInvoice: {
            paddingTop: theme.spacing(2),
        },
        gridWrapperInvoiceDisabled: {
            padding: theme.spacing(2, 0),
        },
        billingInformation: {
            gridArea: billingInformation,
        },
        summary: {
            gridArea: summary,
        },
        comments: {
            gridArea: comments,
        },
        feeDays: {
            gridArea: feeDays,
        },
        wrapper: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
        laneFees: {
            marginTop: theme.spacing(2),
        },
        truckingCapacity: {
            marginBottom: theme.spacing(2),
        },
        smallCell: {
            width: '50%',
            display: 'inline-block',
            boxSizing: 'border-box',
        },
    }
})

export default useStyles
