import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requests from '../Forecast.requests'
import {
    ForecastOngoingOpportunities,
} from '../Forecast.types'

const useGetForecast = (id: number) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<ForecastOngoingOpportunities>({
        key: RequestKeys.getForecastById,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetForecast
