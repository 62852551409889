import React, {
    useMemo,
} from 'react'
import {
    PreBookingEntity,
} from 'app/Apps/PreBooking/PreBooking.types'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import {
    ProductType,
} from 'app/types/enums'
import getFields from './ClosePrebookingForm.fields'

const ClosePrebookingForm = ({
    data,
}: Readonly<{ data: PreBookingEntity }>) => {
    const fields = useMemo(() => {
        return getFields({
            allowedProductTypes: Object.keys(ProductType) as ProductType[],
        })
    }, [])

    return (
        <DomainObjectForm
            fields={fields}
            value={data}
            disabled
            name="ClosePrebookingForm"
        />
    )
}

export default ClosePrebookingForm
