import pageRowToStartRows from 'app/utils/pageRowToStartRows'

const applyStartRows = (cb) => {
    return (params) => {
        const {
            data: paramsData,
        } = params

        const data = {
            ...paramsData,
            ...pageRowToStartRows(params),
        }

        return cb({
            ...params,
            data,
        })
    }
}

export default applyStartRows
