import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            height: '100%',
            position: 'relative',
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'unset',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        tableHeaderWrapper: {
            position: 'relative',
        },
        root: {
            position: 'relative',
        },
        cardContent: {
            padding: theme.spacing(2),
        },
        header: {
            display: 'flex',
            justifyContent: 'end',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        icon: {
            cursor: 'pointer',
        },
        formContainer: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
            padding: theme.spacing(1),
        },
    }
})

export default useStyles
