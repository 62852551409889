import {
    useCallback,
} from 'react'
import axios from 'axios'
import {
    useQuery,
} from '@tanstack/react-query'

import {
    RequestKeys,
} from 'app/hooks/useRequest'

/**
* Use this to fetch a config from public folder
* @param    {String}  name of the file
* @return   {object} data object
*/

const useGetRightsByName = (name) => {
    const load = useCallback(() => {
        return axios.get(`/${name}.json`).then(({
            data,
        }) => {
            return data
        })
    }, [name])

    const {
        data,
    } = useQuery({
        queryKey: [
            RequestKeys.getRightsByName,
            name,
        ],
        queryFn: load,
        refetchOnWindowFocus: false,
        retry: false,
        refetchInterval: false,
        staleTime: Infinity,
        placeholderData: (prev) => { return prev },
    })

    return data
}

export default useGetRightsByName
