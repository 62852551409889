import React, {
    useMemo,
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import ChipSelect from 'app/shared-components/ChipSelect'
import ChipLabel from './ChipLabel'
import LOCATION_OPTIONS_MAP from './locationOptionsMap'

const propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    ),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    locations: PropTypes.PropTypes.arrayOf(
        PropTypes.shape({
            addressCategory: PropTypes.arrayOf(PropTypes.string),
            addressMainCategory: PropTypes.arrayOf(PropTypes.string),
            city: PropTypes.string,
            countryName: PropTypes.string,
            iataCode: PropTypes.string,
            id: PropTypes.number,
            isMainAddress: PropTypes.boolean,
            isShippingLocation: PropTypes.boolean,
            label: PropTypes.string,
            latitude: PropTypes.string,
            locationName: PropTypes.string,
            locationType: PropTypes.string,
            longitude: PropTypes.number,
            value: PropTypes.number,
        }),
    ),
}

const defaultProps = {
    name: '',
    title: '',
    value: undefined,
    onChange: noop,
    disabled: false,
    locations: undefined,
}

const MultiLocationSelector = ({
    name,
    title,
    value,
    onChange,
    disabled,
    locations,
}) => {
    const handleLocationChange = useCallback((locationValue) => {
        onChange(locationValue, {
            target: {
                name,
                value: locationValue,
            },
        })
    }, [
        name,
        onChange,
    ])

    const handleOnChange = useCallback((selectedLocations) => {
        handleLocationChange(selectedLocations?.map((chips) => {
            return chips.value
        }))
    }, [handleLocationChange])

    const options = useMemo(() => {
        if (!locations) {
            return null
        }
        return locations.map((location) => {
            const {
                id,
                locationType,
            } = location

            return {
                value: id,
                label: LOCATION_OPTIONS_MAP[locationType](location),
            }
        })
    }, [locations])

    const selectedLocations = useMemo(() => {
        if (!value || !locations?.length) {
            return []
        }

        return value
            .filter((locationId) => {
                return locations.find(({
                    id,
                }) => {
                    return id === locationId
                })
            })
            .map((locationId) => {
                const currentLocation = locations
                    .find((location) => {
                        return location.id === locationId
                    })

                const {
                    locationType,
                } = currentLocation

                return {
                    value: locationId,
                    label: <ChipLabel
                        locationName={LOCATION_OPTIONS_MAP[locationType](currentLocation)}
                        path={`assets/images/locationSelector/${locationType.toLowerCase()}.svg`}
                    />,
                }
            })
    }, [
        value,
        locations,
    ])

    return (
        <div>
            <ChipSelect
                disabled={disabled}
                name={name}
                options={options}
                title={title}
                value={selectedLocations}
                onChange={handleOnChange}
            />
        </div>
    )
}

MultiLocationSelector.propTypes = propTypes
MultiLocationSelector.defaultProps = defaultProps

export default MultiLocationSelector
