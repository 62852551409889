import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            margin: `${theme.spacing(2)} 0`,
        },
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            width: '20%',
        },
    }
})

export default useStyles
