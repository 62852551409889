import useGetAccount from './useGetAccount'

export default function useAccountCollaborators(accountId: number): number[] {
    const collaborators = new Set<number>()
    const {
        data: account,
    } = useGetAccount(accountId)

    const accountCollaborators = [
        ...(account?.collaborators || []),
        account?.accountOwner,
    ]

    accountCollaborators.forEach((user) => {
        if (user?.id) {
            collaborators.add(user?.id)
        }
    })

    return Array.from(collaborators)
}
