import {
    useCallback,
    useState,
    useEffect,
} from 'react'

const useCollapsablePanel = (identifier: string) => {
    const [
        createFormOpened,
        setCreateFormOpened,
    ] = useState(false)

    const closeCreateForm = useCallback((): void => {
        setCreateFormOpened(false)
    }, [])

    const openCreateForm = useCallback((): void => {
        setCreateFormOpened(true)
    }, [])

    useEffect(() => {
        if (identifier) {
            closeCreateForm()
        }
    }, [
        identifier,
        closeCreateForm,
    ])
    return {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    }
}

export default useCollapsablePanel
