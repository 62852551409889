import React from 'react'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import useStyles from './CloudIconBoolean.style'

const CloudIconBoolean = ({
    value,
}: {
    value: boolean
}) => {
    const {
        classes,
    } = useStyles()

    return value
        ? <CloudDoneIcon className={classes.cloudDoneIcon} />
        : <CloudOffIcon className={classes.cloudOffIcon} />
}

export default CloudIconBoolean
