import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    TableResponse,
} from 'app/types/request.types'
import requests from 'app/Apps/AccountManagement/AccountQuotes/AccountQuotes.requests'
import {
    AwardRejectLaneFeesTable,
} from 'app/Apps/AccountManagement/AccountQuotes/AccountQuotes.types'

export default (id: number) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
        isFetching,
        isError,
        error,
    } = useRequestTable <TableResponse<AwardRejectLaneFeesTable[]>>({
        key: RequestKeys.getLaneFeesToAwardRejectByAccountQuote,
        params: {
            id,
        },
        config: requests.getLaneFeesToAwardReject,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    return {
        onParamsChange,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        data,
        loadData,
    }
}
