import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import {
    RequestConfig,
} from 'app/types/request.types'
import useRequest, {
    RequestFn,
} from 'app/hooks/useRequest'
import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'

const useGetDomainObjectById = ({
    id,
    requestFn,
    requestKey,
}: {
    requestFn: (params: Record<string, any>) => RequestConfig,
    requestKey: RequestKeys,
    id: number | string,
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<{
        id: number,
    }>({
        key: requestKey,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requestFn),
        enabled: !isEmpty(requestKey) && isFunction(requestFn) && Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetDomainObjectById
