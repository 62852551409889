import {
    useCallback,
} from 'react'
import {
    useDispatch,
} from 'react-redux'
import noop from 'lodash/noop'
import {
    useJWTToken,
} from 'app/Auth'

import {
    failedToLoadSaveForm,
    startToSaveForm,
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'

const useMakeRequestWithStatusHandlers = ({
    name,
    api,
    method,
    domainName,
    onSuccess = noop,
    onError = noop,
    version = 'v1',
}) => {
    const dispatch = useDispatch()
    const token = useJWTToken()

    return useCallback(({
        data,
        url,
    }) => {
        dispatch(
            startToSaveForm({
                id: name,
            }),
        )

        return api({
            method,
            url,
            token,
            data,
            domainName,
            version,
        })
            .then((resp) => {
                dispatch(
                    successedToSaveForm({
                        id: name,
                    }),
                )

                onSuccess(resp)
            }).catch((error) => {
                const {
                    response: {
                        data: errors,
                        status: statusCode,
                    },
                } = error

                dispatch(
                    failedToLoadSaveForm({
                        id: name,
                        errors,
                        statusCode,
                    }),
                )
                onError(errors)
            })
    }, [
        dispatch,
        name,
        api,
        method,
        token,
        domainName,
        version,
        onSuccess,
        onError,
    ])
}

export default useMakeRequestWithStatusHandlers
