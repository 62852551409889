import {
    SimulationData,
    SimulationForm,
} from '../simulation.types'

const getDataState = (savedSimulation: SimulationData): SimulationForm => {
    return {
        selectedContainer: savedSimulation.selectedContainer,
        routeSelection: {
            routeOriginAirport: savedSimulation.routeOriginAirport,
            routeViaAirports: savedSimulation.routeViaAirports?.length
                ? savedSimulation.routeViaAirports
                : [null],
            routeDestinationAirport: savedSimulation.routeDestinationAirport,
        },
        simulationSteps: savedSimulation.simulationSteps,
        analyseTemperature: {
            simulationName: savedSimulation.simulationName,
            milestoneSource: savedSimulation.milestoneSource,
            routeComment: savedSimulation.routeComment,
            showEnergyLevel: savedSimulation.showEnergyLevel,
            showStepType: savedSimulation.showStepType,
            showLocation: savedSimulation.showLocation,
            useCustomTempRange: savedSimulation.useCustomTempRange,
            customLowerTempRange: savedSimulation.customLowerTempRange,
            customUpperTempRange: savedSimulation.customUpperTempRange,
            showTemperatureRange: savedSimulation.showTemperatureRange,
            simulationProfile: savedSimulation.simulationProfile,
            simulationFinalized: savedSimulation.simulationFinalized,
            preconditionTemperature: savedSimulation.preconditionTemperature,
        },
        id: savedSimulation.id,
        simulationNumber: savedSimulation.simulationNumber,
        createdBy: savedSimulation.createdBy,
        createdOn: savedSimulation.createdOn,
    }
}

export default getDataState
