import {
    makeStyles,
} from 'app/tss'

const domainObject = 'domainObject'
const temperatureChart = 'temperatureChart'
const printLabel = 'printLabel'
const historyNotes = 'historyNotes'
const containerNotes = 'containerNotes'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2.5),
            gridTemplateAreas: `
                "${domainObject} ${temperatureChart} ${temperatureChart} ${temperatureChart}"
                "${printLabel} ${temperatureChart} ${temperatureChart} ${temperatureChart}"
                ". ${temperatureChart} ${temperatureChart} ${temperatureChart}"
                "${containerNotes} ${containerNotes} ${containerNotes} ${containerNotes}"
                "${historyNotes} ${historyNotes} ${historyNotes} ${historyNotes}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        domainObject: {
            gridArea: domainObject,
        },
        temperatureChart: {
            gridArea: temperatureChart,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        printLabel: {
            gridArea: printLabel,
        },
        containerNotes: {
            gridArea: containerNotes,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        historyNotes: {
            gridArea: historyNotes,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    }
})

export default useStyles
