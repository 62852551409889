import {
    makeStyles,
} from 'app/tss'

const general = 'general'
const fxRate = 'fxRate'
const fees = 'fees'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${general} ${general} ${general}"
            "${fees} ${fees} ${fees}"`,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        gridWrapperWithFxRate: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${general} ${general} ${general} ${fxRate}"
            "${fees} ${fees} ${fees} ${fees}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        general: {
            gridArea: general,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        fxRate: {
            gridArea: fxRate,
        },
        fees: {
            gridArea: fees,
            gridTemplateColumns: '1fr !important',
        },
        commentColumn: {
            gridColumnEnd: 'span 3',
        },
    }
})

export default useStyles
