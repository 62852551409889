/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
    useMemo,
    useRef,
} from 'react'
import {
    Popover,
    PopoverOrigin,
} from '@mui/material'

import InputSingleline from 'app/shared-components/InputSingleline'
import {
    Timezone,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import useStyles from './CitySuggestions.style'

export type TimeZoneType = {
    zoneStart?: string;
    cityName: string,
    regionName: string,
    timezone: Timezone,
}

type Props = {
    timeZones?: TimeZoneType[],
    setTimeZoneCityState: (...args: any[]) => void
}

const defaultProps: Partial<Props> = {
    timeZones: [],
}

const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
}

const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
}

const CitySuggestions = (props: Props) => {
    const {
        classes,
    } = useStyles()

    const anchorEl = useRef(null)

    const timeZoneValues = useEnumValues()(Timezone)

    const {
        timeZones,
        setTimeZoneCityState,
    } = props

    const handleCitySelect = useCallback((zone) => {
        setTimeZoneCityState(zone)
    }, [setTimeZoneCityState])

    const handleCityKeyPress = useCallback((zone) => {
        return (event) => {
            if (event.key === 'Enter') {
                handleCitySelect(zone)
            }
        }
    }, [handleCitySelect])

    const popoverOpen = Boolean(anchorEl && timeZones.length > 1)
    const popoverId = useMemo(() => {
        return popoverOpen ? 'city-suggestions' : undefined
    }, [popoverOpen])

    return (
        <div ref={anchorEl}>
            <InputSingleline
                {...props}
            />
            {anchorEl.current && (
                <Popover
                    id={popoverId}
                    open={popoverOpen}
                    anchorEl={anchorEl.current}
                    anchorOrigin={anchorOrigin}
                    className={classes.popover}
                    transformOrigin={transformOrigin}
                >
                    <div className={classes.citySuggestions}>
                        <div>
                            <strong>
                                Please select a city:
                            </strong>
                        </div>
                        {timeZones.map((zone) => {
                            const time = (timeZoneValues[zone?.timezone] || '')
                                .split(' ').slice(0, 2).join(' ')
                            const item = `${zone.cityName}, ${zone.regionName},
                    ${time}`

                            return (
                                <div
                                    data-testid={`zone-${zone.zoneStart}`}
                                    className={classes.city}
                                    onClick={handleCitySelect}
                                    onKeyPress={handleCityKeyPress(zone)}
                                    key={zone.zoneStart}
                                >
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </Popover>
            )}
        </div>
    )
}

CitySuggestions.defaultProps = defaultProps

export default CitySuggestions
