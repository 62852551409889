import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        button: {
            width: 100,
            paddingRight: 10,
        },
        doubleButtons: {
            display: 'inline-flex',
        },
        buttonWrapper: {
            width: 100,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    }
})

export default useStyles
