import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        root: {
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        tabsWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
    }
})

export default useStyles
