import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tableTitle: {
            whiteSpace: 'nowrap',
            minWidth: 120,
        },
        title: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        content: {
            padding: '0 !important',
        },
        titleText: {
            width: '50%',
        },
        tableCell: {
            '&&': {
                padding: '2px !important',
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.secondary[600],
            },
        },
        noDataRow: {
            '&&': {
                textAlign: 'center',
                padding: '16px !important',
            },
        },
        btn: {
            height: 24,
            width: 24,
            minWidth: '24px !important',
            alignSelf: 'flex-start',
            backgroundColor: `${theme.palette.secondary[100]} !important`,
            color: `${theme.palette.secondary[600]} !important`,
            borderRadius: '4px !important',
            boxShadow: `0px 1px 3px ${theme.palette.secondary[400]}`,
            margin: '5px !important',
        },
        root: {
            position: 'relative',
            minHeight: 100,
        },
    }
})

export default useStyles
