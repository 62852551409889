import React, {
    useMemo,
} from 'react'
import omit from 'lodash/omit'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import AdditionalFeesRequests from 'app/Apps/Pricing/ContractBasis/AdditionalFees/AdditionalFees.requests'

import {
    AdditionalFeeValue,
} from '../AdditionalFeeDetailPanel/AdditionalFee.types'

import AdditionalFeeCopyPanel from './AdditionalFeeCopyPanel'
import {
    AdditionalFeesStatus,
} from '../../../../../types/enums'

type Props = {
    value: {
        additionalFeeId: string,
        contractId: number,
        currency: string,
    }
    closeCopyForm: () => void,
    onDetailPanelCreateSuccess: (
        additionalFeeNumber: {
            additionalFeeNumber: string,
        },
    ) => void,
    redirectToCopyForm: (id: number) => void,
}

const AdditionalFeeCopyPanelContainer = ({
    value: {
        additionalFeeId,
        contractId,
        currency,
    },
    closeCopyForm,
    onDetailPanelCreateSuccess,
    redirectToCopyForm,
}: Props) => {
    const {
        data: additionalFeeData,
        isDataReady,
    } = useRequest<AdditionalFeeValue>({
        key: RequestKeys.getContractBasisAdditionalFeeDetails,
        params: {
            id: additionalFeeId,
        },
        requestFunc: RequestFn.getEntity(AdditionalFeesRequests.get),
        enabled: Boolean(additionalFeeId),
    })

    const valueCleaned = useMemo(() => {
        return {
            ...omit(additionalFeeData, [
                'id',
                'createdOn',
                'createdBy',
                'changedBy',
                'changedOn',
                'additionalFeesNumber',
                'contractWithCurrencyId',
                'supportingDocuments',
                'fees',
            ]),
            contractId,
            currency,
            additionalFeesStatus: AdditionalFeesStatus.IN_PROGRESS,
            additionalFeeId: additionalFeeData?.id,
        }
    }, [
        additionalFeeData,
        contractId,
        currency,
    ])

    if (!isDataReady) {
        return null
    }

    return (
        <AdditionalFeeCopyPanel
            value={valueCleaned}
            closeCopyForm={closeCopyForm}
            onDetailPanelCreateSuccess={onDetailPanelCreateSuccess}
            redirectToCopyForm={redirectToCopyForm}
        />
    )
}

export default AdditionalFeeCopyPanelContainer
