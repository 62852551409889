import useDelayedLoadOptions, {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'

import config from './containerSelector.config'

const useContainerRequest = (): {
    loadOptions: (options: DelayedLoadOptions) => Promise<any>
} => {
    const loadOptions = useDelayedLoadOptions({
        url: config.Selector,
        params: {
            sortField: 'changedOn',
            sortType: 'desc',
            rows: 25,
            start: 0,
        },
    })

    return {
        loadOptions,
    }
}

export default useContainerRequest
