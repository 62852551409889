import {
    LocationType,
} from 'app/types/enums'
import {
    FormPart,
    NestedField,
} from 'app/types/form.types'

import {
    Lane,
} from '../../lanes.types'

const reverseLogisticsFields = ({
    reqCustomsCoord,
    isDropOffSkyCell,
}: {
    reqCustomsCoord: boolean,
    isDropOffSkyCell: boolean,
}): FormPart<keyof Lane> => {
    const isNotDropOffSkyCellFields: NestedField<keyof Lane>[] = [
        {
            name: 'fwdExport',
            componentName: 'LocationObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExport',
        },
        {
            name: 'fwdExportContacts',
            componentName: 'MultipleContactObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExportContacts',
        },
        {
            name: 'fwdExportBackup',
            componentName: 'LocationObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExportBackup',
        },
        {
            name: 'fwdExportBackupContacts',
            componentName: 'MultipleContactObjectSelector',
            labelKey: 'LaneOpsInformationDto.fwdExportBackupContacts',
        },
    ]

    return {
        title: 'Reverse Logistics',
        className: 'reverseLogistics',
        fields: [
            {
                id: 'reverseLogistics1',
                fields: [
                    {
                        name: 'reqCustomsCoord',
                        componentName: 'Checkbox',
                        labelKey: 'LaneOpsInformationDto.reqCustomsCoord',
                    },
                    {
                        name: 'fwdCustoms',
                        componentName: 'LocationObjectSelector',
                        filterLocationType: LocationType.ADDRESS,
                        required: reqCustomsCoord,
                        labelKey: 'LaneOpsInformationDto.fwdCustoms',
                    },
                    {
                        name: 'fwdCustomsContacts',
                        componentName: 'MultipleContactObjectSelector',
                        required: reqCustomsCoord,
                        labelKey: 'LaneOpsInformationDto.fwdCustomsContacts',
                    },
                ],
            },
            {
                id: 'reverseLogistics2',
                fields: isDropOffSkyCell ? [] : isNotDropOffSkyCellFields,
            },
        ],
    }
}

export default reverseLogisticsFields
