import {
    useMemo,
} from 'react'
import {
    useQueries,
    QueryFunction,
} from '@tanstack/react-query'
import {
    useDispatch,
} from 'react-redux'
import {
    useJWTToken,
} from 'app/Auth'

import {
    TableResponse,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'

import {
    UseRequestResponse,
} from './useRequest'

type UseParallelRequestsParams = {
    queries: {
        key: RequestKeys,
        params?: Record<string, any>,
        requestFunc?: (dispatch: (any) => void) => QueryFunction,
        refetchInterval?: number | false | ((any) => number | false),
        enabled?: boolean,
        keepPreviousData?: boolean,
        staleTime?: number,
    }[],
    requestFunc?: (dispatch: (any) => void) => QueryFunction,
    refetchInterval?: number | false | ((any) => number | false),
    enabled?: boolean,
    keepPreviousData?: boolean,
    staleTime?: number,
}

const useParallelRequests = <Type = TableResponse>({
    queries,
    requestFunc,
    enabled = true,
    refetchInterval = false,
    keepPreviousData = false,
    staleTime = 1000,
}: UseParallelRequestsParams): UseRequestResponse<Type>[] => {
    const token = useJWTToken()
    const dispatch = useDispatch()
    const preparedQueries = queries.reduce((acc, {
        key,
        params,
        requestFunc: paramRequestFunc,
        ...args
    }) => {
        const queryFunction = paramRequestFunc || requestFunc

        if (queryFunction) {
            return [
                ...acc,
                {
                    queryKey: [
                        key,
                        {
                            ...params, token,
                        },
                    ],
                    queryFn: queryFunction(dispatch),
                    enabled,
                    refetchInterval,
                    keepPreviousData,
                    staleTime,
                    ...args,
                },
            ]
        }

        return acc
    }, [])

    const response = useQueries({
        queries: preparedQueries,
    })

    return useMemo(() => {
        return response.reduce((acc, {
            data,
            error,
            isFetching,
            isSuccess,
            isError,
            refetch,
        }) => {
            return [
                ...acc,
                {
                    data,
                    error,
                    isDataReady: !isFetching && isSuccess,
                    isFetching,
                    isError,
                    refetch,
                },
            ]
        }, [])
    }, [response])
}

export default useParallelRequests
