import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        inRange: {
            borderRadius: 16,
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
            background: '#C3E9F7',
            lineHeight: 'normal',
        },
    }
})

export default useStyles
