// validate only required fields

import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import {
    DTOCardFormField,
    FieldInCard,
} from 'app/types/form.types'

const getRequiredFields = <T>(fields: FieldInCard<T>[] | DTOCardFormField<T>[]): string[] => {
    const allFields = fields.map((field) => {
        if (field.name) {
            return field.required && field.name
        }

        return field.fields.map((fieldData) => {
            if (fieldData.name) {
                return fieldData.required && fieldData.name
            }

            return fieldData.fields?.map((subFieldsData) => {
                return subFieldsData.required && subFieldsData.name
            })
        })
    }).flat()

    return allFields.map((field) => {
        return Array.isArray(field) ? field.flat() : field
    })
        .flat()
        .filter((nameField) => { return nameField })
}

const validateForm = <T>({
    fields,
    value,
}: {
    fields: FieldInCard<T>[] | DTOCardFormField<T>[],
    value: Record<string, any>
}): boolean => {
    const requiredFields = getRequiredFields<T>(fields)

    return requiredFields.every((fieldName) => {
        return typeof value[fieldName] === 'object' ? !isEmpty(value[fieldName]) : !isNil(value[fieldName]) && value[fieldName] !== ''
    })
}

export default validateForm
