import React, {
    useMemo,
} from 'react'

import {
    useRouteMatch, Route, Switch,
} from 'react-router-dom'

import Card from 'app/shared-components/Card'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import useLabel from 'app/hooks/useLabel'

import useGetLaneGroupsPricingAssignment from './hooks/useGetLaneGroupsPricingAssignment'
import requests from '../AccountQuotes.requests'
import useStyles from '../LanesToBeGrouped/LanesToBeGrouped.styles'
import getColumns from './AssignPricing.columns'
import {
    AccountQuoteContext, AccountQuoteContextType,
} from '../AccountQuoteContext/useAccountQuoteContext'

import AssignPricingLaneGroupUpdate from './AssignPricingLaneGroupUpdate'
import useGetAccountQuote from '../hooks/useGetAccountQuote'

const AssignPricing = ({
    id,
}: {
    id: number
}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const {
        url,
    } = useRouteMatch()
    const {
        data: quote,
    } = useGetAccountQuote(id)

    const requestHandlers = useGetLaneGroupsPricingAssignment(id)

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const actionComponents = useMemo(() => {
        return {
            Update: AssignPricingLaneGroupUpdate,
        }
    }, [])

    const accountQuoteContext = useMemo(() => {
        return {
            quoteId: id,
            ...(quote || {}),
        } as AccountQuoteContextType
    }, [
        id,
        quote,
    ])

    return (
        <AccountQuoteContext.Provider value={accountQuoteContext}>
            <Switch>
                <Route path={`${url}/:id?/:action?`}>
                    <div
                        data-testid="LaneGroupsAssignPricing"
                        className={classes.root}
                    >
                        <Card
                            fullHeight
                            contentClass={classes.cardContent}
                            data-testid="LanesGroups-card"
                        >
                            <CollapsibleTable
                                title="Lane Groups"
                                configName="AssignPricingLaneGroups"
                                domainName={requests.domainName}
                                columns={columns}
                                customUrl={null}
                                requestHandlers={requestHandlers}
                                actionComponents={actionComponents}
                                createEnabled={false}
                                tableControls={[]}
                                switchableFormTable
                                backToTableText="Back To Lane Groups"
                            />
                        </Card>
                    </div>
                </Route>
            </Switch>
        </AccountQuoteContext.Provider>

    )
}

export default AssignPricing
