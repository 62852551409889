import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../serviceCenters.request'
import {
    ServiceCenterFormType,
} from '../serviceCenters.types'

const useGetServiceCenterById = (id: number) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<ServiceCenterFormType>({
        key: RequestKeys.getServiceCenterById,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetServiceCenterById),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetServiceCenterById
