import axios from 'axios'
import bearerToken from 'app/utils/api/bearerToken'
import {
    DataRequest,
} from 'app/types/request.types'
import DOMAIN_SUBDIRECTORY from 'app/utils/api/constants'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const requestBusinessObjects = ({
    token,
    version = 'v1',
    headers = {},
    ...rest
}: DataRequest): Promise<{data: any}> => {
    return bearerToken(axios.request)({
        token,
        headers,
        ...rest,
        baseURL: `${apiUrl}/${DOMAIN_SUBDIRECTORY}/${version}/`,
    })
}

export default requestBusinessObjects
