import React from 'react'

import {
    NotificationIconProps,
} from '../NotificationIcon/notificationIcon.types'
import Validated from '../Validated'
import {
    ValidationResult,
} from '../../types/form.types'
import NotificationIcon from '../NotificationIcon'

const defaultProps = {
    validated: false,
    notificationIconProps: undefined,
    errors: undefined,
}

const LabelPanel = ({
    validated,
    notificationIconProps,
    children,
    errors,
}:{
    validated?: boolean,
    notificationIconProps?: NotificationIconProps,
    children: JSX.Element,
    errors?: ValidationResult,
}) => {
    if (validated) {
        return (
            <Validated
                validated={validated}
                errors={errors}
            >
                {children}
            </Validated>
        )
    }

    if (notificationIconProps) {
        return (
            <NotificationIcon
                text={notificationIconProps.text}
                type={notificationIconProps.type}
            >
                {children}
            </NotificationIcon>
        )
    }

    return children
}

LabelPanel.defaultProps = defaultProps

export default LabelPanel
