type amountCoolingGelOption = {
    defaultAmountCoolingGelValue: number,
    options: [number, string][],
}
const amountCoolingGelOptionsMap: Record<string, amountCoolingGelOption> = {
    VAL_1500F: {
        defaultAmountCoolingGelValue: 110,
        options: [
            [
                80,
                '80kg',
            ],
            [
                90,
                '90kg',
            ],
            [
                100,
                '100kg',
            ],
            [
                110,
                '110kg',
            ],
            [
                120,
                '120kg',
            ],
        ],
    },
    VAL_1500DF: {
        defaultAmountCoolingGelValue: 110,
        options: [
            [
                70,
                '70kg pellets',
            ],
            [
                110,
                '110kg blocks',
            ],
        ],
    },
    VAL_1500DF_EUROMAX: {
        defaultAmountCoolingGelValue: 200,
        options: [
            [
                200,
                '200kg pellets',
            ],
            [
                330,
                '330kg blocks',
            ],
        ],
    },
}

export default amountCoolingGelOptionsMap
