import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import useFilter from 'app/hooks/useFilter/useFilter'
import {
    getEditedItems, useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    useSkyNetPagination, SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import usePreBookingsBulkEdit from './hooks/usePreBookingsBulkEdit'
import {
    PreBooking,
    PreBookingEntity,
} from '../PreBooking.types'
import PreBookingSpreadsheet from '../PreBookingSpreadsheet'
import {
    convertToPrebookingEntity,
    convertToPrebookingEntitySpreadsheetData,
} from '../PreBookingSpreadsheet/PrebookingSpreadsheet.utils'
import requests from '../PreBookings.requests'
import {
    PreBookingEntitySpreadsheetData,
} from '../PreBookingSpreadsheet/PreBookingSpreadsheet.config'

const PreBookingBulkEdit = ({
    backUrl,
}: Readonly<{backUrl: string}>) => {
    const filterOptions = useFilter()
    const paginationOptions = useSkyNetPagination(25)

    const {
        isError,
        loaded,
        error,
    } = useGetRequestSpreadSheetAdapter<PreBooking, PreBookingEntitySpreadsheetData>({
        filterOptions,
        paginationOptions,
        dataAdapter: convertToPrebookingEntitySpreadsheetData,
        config: requests.filterRequestForBulkEdit,
        requestKey: RequestKeys.getPreBookings,
        enabled: false,
    })

    const history = useHistory()
    const prebookingBulkEdit = usePreBookingsBulkEdit()
    const onSuccess = useCallback(() => {
        history.push(backUrl)
    }, [
        history,
        backUrl,
    ])

    const onSave = useCallback((
        prebookings: PreBookingEntity[],
        options?: AsyncMutationOptions,
    ) => {
        const editedItems = getEditedItems({
            allItems: prebookings,
            initData: filterOptions.filteredData,
        })

        prebookingBulkEdit(convertToPrebookingEntity(editedItems), {
            ...options,
            onSuccess: options?.onSuccess || onSuccess,
        })
    }, [
        filterOptions?.filteredData,
        onSuccess,
        prebookingBulkEdit,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <PreBookingSpreadsheet
                    onSave={onSave}
                    onCancel={onSuccess}
                    filterOptions={filterOptions}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>
    )
}

export default PreBookingBulkEdit
