import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    GeneralFreightFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const columns: RichTableColumns<GeneralFreightFields>[] = [
    {
        id: 'material',
        headerProps: {
            children: 'Material',
        },
        mapCellProps: 'material',
    },
    {
        id: 'quantity',
        headerProps: {
            children: 'Quantity',
        },
        mapCellProps: 'quantity',
    },
]

export default columns
