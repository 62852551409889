import {
    useMemo,
} from 'react'

import useGetBillingCompany from 'app/Apps/ContactManagement/BillingCompanies/hooks/useGetBillingCompany'

export default (id, getLabelConfig) => {
    const {
        data,
    } = useGetBillingCompany(id)

    const labelData = useMemo(() => {
        if (data) {
            return getLabelConfig(data)
        }

        return null
    }, [
        data,
        getLabelConfig,
    ])

    return {
        billingCompany: data,
        labelData,
    }
}
