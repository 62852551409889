import React from 'react'
import TableCell from '@mui/material/TableCell'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'

import InputBase from 'app/shared-components/InputBase'

import useStyles from './Percentage.styles'

const propTypes = {
    value: PropTypes.shape({
        feeValue: PropTypes.number,
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

const defaultProps = {
    value: undefined,
    onChange: noop,
    disabled: false,
}

const Percentage = (props) => {
    const {
        value,
        onChange,
        disabled,
    } = props
    const {
        classes,
    } = useStyles()

    return (
        <TableCell
            align="center"
            classes={{
                root: classes.tableCell,
            }}
        >
            <div className={classes.fieldWrapper}>
                <InputBase
                    inputProps={{
                        className: classes.input,
                    }}
                    value={value?.feeValue}
                    name="feeValue"
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className={classes.percentageSign}>%</span>
            </div>
        </TableCell>
    )
}

Percentage.defaultProps = defaultProps
Percentage.propTypes = propTypes

export default Percentage
