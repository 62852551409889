import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    useNavigate,
    useLocation,
} from 'react-router-dom-v5-compat'
import omit from 'lodash/omit'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    RequestMethods,
} from 'app/types/common.enums'
import Button from 'app/shared-components/Button'
import SharedContextProvider from 'app/shared-components/SharedContext'

import getFilteredSteps from './utils/getFilteredSteps'
import getDataState from './utils/getDataState'
import fields from './simulation.fields'
import requestConfig from './simulation.request'

import useStyles from './Simulation.styles'
import getDataRequest from './utils/getDataRequest'
import useGetTemperatureSimulation from './hooks/useGetTemperatureSimulation'
import {
    SimulationData, SimulationForm,
} from './simulation.types'

type Props = {
    data?: SimulationData,
    transportPlannerPath?: string,
}

const defaultProps = {
    transportPlannerPath: '',
    data: {},
}

const Simulation = (props: Props) => {
    const navigate = useNavigate()
    const {
        pathname,
    } = useLocation()
    const {
        classes,
    } = useStyles()

    const {
        transportPlannerPath,
        data: savedSimulation,
    } = props

    const [
        state,
        setState,
    ] = useState<SimulationForm>(getDataState(savedSimulation))

    useEffect(() => {
        document.querySelector('#mainScroll').scrollTop = 0
    }, [])

    const fieldsWithClassName = useFieldsWithClassName(fields, classes)

    const {
        data: simulationResults,
    } = useGetTemperatureSimulation(state)

    const modifyDataBeforeSend = (requestData: SimulationForm) => {
        const steps = getFilteredSteps(requestData.simulationSteps)
            .map((item) => {
                return {
                    ...omit(item, 'id'),
                }
            })

        const dataRequest = getDataRequest({
            requestData, simulationSteps: steps,
        })

        if (!requestData.selectedContainer?.productType || !steps.length) {
            return {
                ...dataRequest,
            }
        }

        return {
            ...dataRequest,
        }
    }

    const onSuccess = useCallback(({
        action,
        id,
    }: {
        action: RequestMethods,
        id: number,
    }): void => {
        if (action === RequestMethods.CREATE) {
            navigate(`${pathname}/${id}`)
        }
        if (action === RequestMethods.DELETE) {
            navigate(`${transportPlannerPath}/simulation`)
        }
    }, [
        navigate,
        pathname,
        transportPlannerPath,
    ])

    const duplicate = useCallback((): void => {
        const data = omit(savedSimulation, [
            'id',
            'simulationResults',
            'simulationNumber',
            'selectedContainer',
        ])

        navigate(`${transportPlannerPath}/simulation`, {
            state: data,
        })
    }, [
        navigate,
        savedSimulation,
        transportPlannerPath,
    ])

    const customButtons: JSX.Element[] = useMemo(() => {
        if (!state.id) {
            return []
        }

        return [<Button
            label="Duplicate"
            name="Duplicate"
            secondary
            onClick={duplicate}
        />]
    }, [
        duplicate,
        state.id,
    ])

    return (
        <SharedContextProvider
            value={{
                selectedContainer: state.selectedContainer,
                simulationResults,
                simulationSteps: getFilteredSteps(state.simulationSteps),
            }}
        >
            <FormWithControls
                className={classes.rowFormContainer}
                requestParams={requestConfig.Simulation}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                    title: classes.headerTitle,
                }}
                value={state}
                setExternalValue={setState}
                name={requestConfig.name}
                fields={fieldsWithClassName}
                modifyDataBeforeSend={modifyDataBeforeSend}
                customButtons={customButtons}
                onSuccess={onSuccess}
                exists={Boolean(state.id)}
            />
        </SharedContextProvider>
    )
}

Simulation.defaultProps = defaultProps

export default Simulation
