import {
    AttitudeToSkyCell as AttitudeToSkyCellEnum,
} from '../../engagement.types'

export default {
    [AttitudeToSkyCellEnum.DISLIKES_US]: 'red',
    [AttitudeToSkyCellEnum.DOES_NOT_KNOW_US]: 'grey',
    [AttitudeToSkyCellEnum.KNOWS_US]: 'green',
    [AttitudeToSkyCellEnum.LIKES_US]: 'blue',
}
