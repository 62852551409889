/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {
    useHistory,
} from 'react-router-dom'

import DomainObjectPrint from './DomainObjectPrint'

const propTypes = {
    backUrl: PropTypes.string.isRequired,
}

const DomainObjectPrintContainer = (props) => {
    const {
        backUrl,
    } = props

    const history = useHistory()
    const onClose = useCallback(() => {
        history.push(backUrl)
    }, [
        backUrl,
        history,
    ])

    return (
        <DomainObjectPrint
            {...props}
            onClose={onClose}
        />
    )
}

DomainObjectPrintContainer.propTypes = propTypes

export default DomainObjectPrintContainer
