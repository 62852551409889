import React from 'react'
import useSkyNetSpreadSheetContext from '../SkyNetSpreadSheetContext'
import useStyles from './SearchNotification.styles'

const SearchNotification = ({
    msg,
}: {
    msg: string
}) => {
    const {
        rowHeight,
    } = useSkyNetSpreadSheetContext()

    const {
        cx, classes,
    } = useStyles({
        top: rowHeight,
    })

    return (
        <div
            data-testid="searchNotification"
            className={cx(classes.searchNotification)}
        >
            {msg}
        </div>
    )
}

export default SearchNotification
